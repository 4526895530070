/* ======================================== */
/* .section_header-ingredients
/* ======================================== */
.section_header-ingredients{
	width:100%;
	margin-bottom:var(--aox-55px);
	}
.section_header-ingredients .str_container{
	max-width:var(--aox-1300px);
	}
/**/
.section_header-ingredients .ctn_header-ingredients{
	display:flex;
	flex-flow:row wrap;
	}
.section_header-ingredients .ctn_header-ingredients > div{
	width:calc(25% - var(--aox-20px));
	margin:0 var(--aox-10px);
	}
.section_header-ingredients .ctn_header-ingredients figure{
	position:relative;
	}
.section_header-ingredients .ctn_header-ingredients figure img{
	width:100%;
	}
.section_header-ingredients .ctn_header-ingredients figure figcaption{
	position:absolute;
	left:0; top:50%;
	width:100%;
	padding:0 var(--aox-20px);
	color:#fff;
	font-size:var(--aox-fs-22px); line-height:1.2;
	font-weight:500;
	text-align:center;
	text-transform:uppercase;
	transform:translateY(-50%);
	}
/**/
.section_header-ingredients .ctn_expand-ingredients{
	width:100%; max-width:var(--aox-980px);
	padding:0 var(--aox-20px);
	margin:0 auto;
	text-align:center;
	}
.section_header-ingredients .ctn_expand-ingredients h2{
	padding:var(--aox-80px) 0 var(--aox-40px) 0;
	font-size:var(--aox-fs-18px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_header-ingredients .ctn_expand-ingredients .ctn_text{
	overflow:hidden;
	height:0;
	transition:all .5s ease;
	}
.section_header-ingredients .ctn_expand-ingredients p{
	padding-bottom:var(--aox-40px);
	font-size:var(--aox-fs-16px); line-height:1.875;
	}

@media screen and (max-width:1300px){
	.section_header-ingredients .ctn_header-ingredients figure figcaption{
		font-size:var(--aox-fs-22px);
		}
}
@media screen and (max-width:767px){
	.section_header-ingredients{
		margin-bottom:var(--aox-25px);
		}
	.section_header-ingredients .ctn_header-ingredients{
		padding:0 var(--aox-10px);
		}
	.section_header-ingredients .ctn_header-ingredients > div{
		width:calc(50% - var(--aox-20px));
		margin:var(--aox-10px);
		}
	.section_header-ingredients .ctn_header-ingredients figure figcaption{
		font-size:var(--aox-fs-16px);
		}
	.section_header-ingredients .ctn_expand-ingredients h2{
		padding-top: var(--aox-60px);
		font-size:var(--aox-fs-16px);
		}
}
@media screen and (max-width:375px){
	.section_header-ingredients .ctn_header-ingredients figure figcaption{
		font-size:var(--aox-fs-14px);
		}
}
@media screen and (max-width:320px){
	.section_header-ingredients .ctn_header-ingredients{
		padding:0;
		}
	.section_header-ingredients .ctn_header-ingredients figure figcaption{
		font-size:var(--aox-fs-12px);
		}
}