/* ======================================== */
/* .obj_banner-product
/* ======================================== */
.obj_banner-routine{
	margin-top:var(--aox-35px);
	text-align:center;
	}
.obj_banner-routine h1{
	margin-bottom:var(--aox-15px);
	font-size:var(--aox-fs-32px); line-height:1.2;
	font-weight:500;
	text-transform:uppercase;
	}
.obj_banner-routine h2{
	padding:var(--aox-15px) var(--aox-20px);
	font-size:var(--aox-fs-26px); line-height:1.2;
	font-weight:300;
	text-transform:uppercase;
	background:#f2f2f2;
	}

/**/
@media screen and (max-width:767px){
	.obj_banner-routine h1{
		font-size:var(--aox-fs-30px);
		}
	.obj_banner-routine h2{
		font-size:var(--aox-fs-22px);
		}
}