/* ======================================== */
/* .main_footer
/* ======================================== */
.main_footer{
	padding:var(--aox-90px) 0;
	background:#f2f2f2;
	}
.main_footer ul li{
	font-size:var(--aox-fs-15px); line-height:1;
	}
.main_footer ul li a{
	display:inline-block;
	padding:var(--aox-10px) var(--aox-20px) var(--aox-10px) 0;
	color:#777;
	}
.main_footer ul li a:hover{
	text-decoration: underline;
	color:#000;
	}
.main_footer ul li.var_bold a,
.main_footer ul li strong{
	display:flex;
	align-items:center;
	padding:var(--aox-20px) var(--aox-20px) var(--aox-20px) 0;
	font-weight:700;
	letter-spacing:0.1em;
	text-transform:uppercase;
	color:#000;
	}
.main_footer ul li.var_bold a .i_facebook-circle{
	margin-left:var(--aox-10px);
	width:var(--aox-24px); height:var(--aox-24px);
	}
.main_footer .ctn_top{
	display:flex;
	flex-flow:row wrap;
	padding-bottom:var(--aox-120px);
	border-bottom:1px solid #c3c3c3;
	}
.main_footer .ctn_top .logo-caf{
	width:var(--aox-260px);
	}
.main_footer .ctn_top .logo-caf img{
	width:100%;
	}
.main_footer .ctn_top .main_nav-footer{
	display: flex;
    padding-left: var(--aox-70px);
    width: var(--aox-950px);
	}
.main_footer .ctn_top .main_nav-footer ul{
	width:33.333%;
	}
.main_footer .ctn_bottom{
	padding-top:var(--aox-60px);
	}
.main_footer .ctn_bottom .second_nav-footer{
	margin-bottom:var(--aox-80px);
	}
.main_footer .ctn_bottom .second_nav-footer ul{
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	width:90%;
	margin:0 auto;
	}
.main_footer .ctn_bottom .second_nav-footer ul li a,
.main_footer .ctn_bottom .second_nav-footer ul li button{
	padding:var(--aox-20px);
	text-align:center;
	}
.main_footer .t_mentions{
	font-size:var(--aox-fs-15px); line-height:1;
	text-align:center;
	color:#777;
	}
.main_footer .optanon-show-settings-popup-wrapper{
	padding:var(--aox-10px) var(--aox-20px);
	}
.main_footer .optanon-show-settings-popup-wrapper .optanon-show-settings-button{
	display:flex;
	justify-content:center;
	}

.main_footer .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.ot-sdk-show-settings,
.main_footer .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.optanon-show-settings{
    display:block;
	width:100%;
    padding:var(--aox-20px);
    font-size:var(--aox-fs-15px); line-height:1;
    font-weight:700;
    letter-spacing:.1em;
    text-transform:uppercase;
    text-align:center;
    color:#000;
    border:none;
	}
.main_footer .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.ot-sdk-show-settings:hover,
.main_footer .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.optanon-show-settings:hover{
    background:transparent;
	}

@media screen and (max-width:1280px){
	.main_footer .ctn_top .logo-caf{
		padding-left:var(--aox-20px);
		}
}
@media screen and (max-width:767px){
	.main_footer{
		padding:var(--aox-60px) var(--aox-20px);
		text-align:center;
		}
	.menu-mobile_is-opened .main_footer{
		display:none;
		}
	.main_footer .ctn_top .logo-caf{
		display:flex;
		justify-content:center;
		width:100%;
		padding:0 var(--aox-20px) var(--aox-30px) var(--aox-20px);
		}
	.main_footer .ctn_top .logo-caf img{
		width:var(--aox-250px);
		}
	.main_footer ul li a{
		padding:var(--aox-10px) 0;
		}
	.main_footer ul li.var_bold a,
	.main_footer ul li strong{
		justify-content:center;
		padding-right:0;
		}
	.main_footer .ctn_top{
		padding-bottom:var(--aox-30px);
		}
	.main_footer .ctn_top .main_nav-footer{
		flex-flow:row wrap;
		width:100%;
		padding:0;
		}
	.main_footer .ctn_top .main_nav-footer ul{
		width:100%;
		}
	.main_footer .ctn_bottom{
		padding-top:var(--aox-20px);
		}
	.main_footer .ctn_bottom .second_nav-footer{
		margin-bottom:var(--aox-50px);
		}
	.main_footer .ctn_bottom .second_nav-footer ul{
		max-width:unset;
		width:100%;
		}
	.main_footer .ctn_bottom .second_nav-footer ul li{
		width:100%;
		}
	.main_footer .optanon-show-settings-popup-wrapper{
		padding:var(--aox-15px) 0;
		}
}