/* ======================================== */
/* .obj_star-score
/* ======================================== */
.obj_star-score{
	text-align:center;
	}
.obj_star-score svg{
	position:relative;
	z-index:1;
	}
.obj_star-score.var_bronze svg{
	fill:#cf9c67;
	}
.obj_star-score.var_argent svg{
	fill:#b2b2b2;
	}
.obj_star-score.var_or svg{
	fill:#eac911;
	}
.obj_star-score.var_experte svg{
	fill:url(#gradient-starscore);
	stroke:#949494;
	}
.obj_star-score .t_year{
	position:relative;
	z-index:2;
	top:-35px;
	font-size:var(--aox-fs-20px); line-height:1;
	color:#fff;
	}
.obj_star-score.var_experte .t_year{
	color:#000;
	}
.obj_star-score .t_status{
	font-size:var(--aox-fs-20px); line-height:1;
	text-transform:uppercase;
	}
