/* ======================================== */
/* .obj_item_expertise
/* ======================================== */

.obj_item_expertise{
	position: relative;
    width: var(--aox-100px);
    margin-right: var(--aox-15px);
	margin-top: var(--aox-20px);
    text-align: center;
	}
.obj_item_expertise .ctn_image{
	width: var(--aox-100px);
	height: var(--aox-100px);
	}
.obj_item_expertise .ctn_image img{
	width: 100%;
	}
.obj_item_expertise .ctn_text{
	margin-top: var(--aox-7px);
	}
.obj_item_expertise .ctn_text .t_tag{
	font-weight: 700;
    font-size: var(--aox-fs-10px);
    line-height: var(--aox-lh-100);
	}
.obj_item_expertise .ctn_text .t_tag span{
	display: inline-block;
    text-transform: uppercase;
    padding: var(--aox-5px) var(--aox-10px) var(--aox-3px);
    border-radius: var(--aox-15px);
	}

/***/
/* GENERATOR */
.obj_item_expertise .ctn_text .t_tag span{
	background-color: rgba(0, 172, 194, 0.15);
	}

/***/


@media screen and (max-width:767px){

	.obj_item_expertise {
		width: calc(100% / 3);
		padding: 0 var(--aox-10px);
		margin-right: unset;
		}
	.obj_item_expertise + .obj_item_expertise{
		margin-left: 0;
		}
	.obj_bloc_expertise .obj_recompences_exp .lst_items_exp {
		width: 100%;
		overflow: auto;
		height: var(--aox-300px);
		}

}
