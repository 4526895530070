/* ======================================== */
/* .obj_section_badge_expertise_expertise
/* ======================================== */
.obj_section_badge_expertise{
	position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
	}
.obj_section_badge_expertise .obj_badge_expertise{
	position: relative;
	width: var(--aox-480px);
	height: var(--aox-480px);
	margin-bottom: var(--aox-5px);
	}
.obj_section_badge_expertise .obj_badge_expertise #canvas {
	z-index: 2;
	width: 100%;
    height: 60vh
	}
.obj_section_badge_expertise .obj_badge_expertise .t_badge_collecter {
	position: absolute;
	z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	}
.obj_section_badge_expertise .obj_badge_expertise .t_badge_collecter span {
	display: inline-block;
    font-weight: 700;
    font-size: var(--aox-fs-22px);
    line-height: var(--aox-lh-100);
    width: var(--aox-330px);
    text-transform: uppercase;
    padding: var(--aox-17px) var(--aox-30px) var(--aox-17px) var(--aox-30px);
    border-radius: var(--aox-35px);
    background-color: rgba(255, 255, 255, 0.9);
    letter-spacing: normal;
	}
.obj_section_badge_expertise .ctn_text{
	position: relative;
	width: var(--aox-col-6);
	}
.obj_section_badge_expertise .ctn_text .t_title {
	margin-bottom: var(--aox-15px);
	padding-right: 0;
	font-size: var(--aox-fs-20px);
	line-height: 1.2;
	font-weight: 700;
	text-transform: uppercase;
	color: white;
	}
.obj_section_badge_expertise .ctn_text .t_desc {
	font-size: var(--aox-fs-16px);
	line-height: 1.2;
	color: white;
	}
.obj_section_badge_expertise .ctn_text .t_tag {
	margin-top: var(--aox-25px);
	font-weight: 700;
    font-size: var(--aox-fs-40px);
    line-height: var(--aox-lh-100);
	}
.obj_section_badge_expertise .ctn_text .t_tag span {
	display: inline-block;
	text-transform: uppercase;
	padding: var(--aox-10px) var(--aox-30px) var(--aox-10px) var(--aox-30px);
	border-radius: var(--aox-35px);
	background-color: rgba(255, 255, 255, 0.2);
	letter-spacing: normal;
	}

.obj_section_badge_expertise .ctn_lottie{
	position:absolute;
	z-index:1;
	left:50%; top:50%;
	width:var(--aox-700px); height:var(--aox-700px);
	transform:translate(-50%,-45%);
	}

/***/

@media screen and (max-width:767px){

	.obj_popin.popin_overlay_badge_detail {
		padding: var(--aox-30px);
		}
	.obj_section_badge_expertise .obj_badge_expertise{ 
		width: var(--aox-280px);
		height: var(--aox-280px);
		}
	.obj_section_badge_expertise .obj_badge_expertise #canvas {
		width: 100%;
		height: var(--aox-280px);
		}
	.obj_section_badge_expertise .obj_badge_expertise .t_badge_collecter span {
		font-size: var(--aox-fs-18px);
		width: var(--aox-270px);
		padding: var(--aox-15px) var(--aox-20px) var(--aox-15px) var(--aox-20px);
		}
	.obj_section_badge_expertise .ctn_text {
		width: 100%;
		}
	.obj_section_badge_expertise .ctn_text .t_desc {
		font-size: var(--aox-fs-14px);
		}
	.obj_section_badge_expertise .ctn_text .t_tag span {
		padding: var(--aox-5px) var(--aox-15px);
		font-size: var(--aox-fs-22px);
		}
	.obj_popin.popin_overlay_badge_detail .bt_close {
		right: var(--aox-20px);
		top: var(--aox-20px);
		width: var(--aox-40px);
		height: var(--aox-40px);
		}
	.obj_section_badge_expertise .ctn_lottie{
		width:var(--aox-450px); height:var(--aox-450px);
		}

}