/* ======================================== */
/* .obj_tableau-beneficiaire
/* ======================================== */
.obj_tableau-beneficiaire{
	position: relative;
    display: flex;
    flex-direction: column;
    transition: all 1s ease;
    width: 100%;
    }
.obj_tableau-beneficiaire .obj_table-header{
    display: flex;
    flex-flow: row wrap;
    padding: var(--aox-16px) var(--aox-20px);
    border-bottom: 1px solid #d7d7d7;
    align-items: center;
    }

.obj_tableau-beneficiaire .header-cell {
    position: relative;
    flex: 1;
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    padding: 0 var(--aox-15px);
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell.sortable span{
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-right: var(--aox-35px);
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell.sortable span:after{
    content: '';
    position: absolute;
    left: unset;
    top: 50%;
    right: 0;
    margin-left: auto;
    width: var(--aox-30px);
    height: var(--aox-30px);
    border-radius: 0;
    transform: translate(2%,-50%);
    background: url('../../images/i_sortable.svg') center center / var(--aox-30px) auto no-repeat !important;
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell.sortable.active span:after{
    transform: translate(2%,-50%) rotate(180deg);
    }

.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(1),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(1) {
    flex: 0.2 1; padding: 0;
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(5),
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(6),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(5),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(6){
    flex: 0.75 1;
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(2),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(2){
    flex: 1.75 1;
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(3),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(3) {
    flex: 2.25 1;
    }

.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(5){ text-align: center; }
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(6){ text-align: right; }

    
.obj_tableau-beneficiaire .table-row {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding: var(--aox-16px) var(--aox-20px);
    align-items: center;
    }
.obj_tableau-beneficiaire .table-row + .table-row {
    border-top: 1px solid #ccc;
    }  
/* .obj_tableau-beneficiaire .table-row:hover {
    background-color: #f2f2f2;
    } */
    
.obj_tableau-beneficiaire .row-cell {
    flex: 1;
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-150);
    padding: 0 var(--aox-15px);
    }
.obj_tableau-beneficiaire .row-cell strong{
    font-weight: 700;
    }

/***/
.obj_tableau-beneficiaire .row-cell .tgl {
    display: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl, .obj_tableau-beneficiaire .row-cell .tgl:after, .obj_tableau-beneficiaire .row-cell .tgl:before, .obj_tableau-beneficiaire .row-cell .tgl *, .obj_tableau-beneficiaire .row-cell .tgl *:after, .obj_tableau-beneficiaire .row-cell .tgl *:before, .obj_tableau-beneficiaire .row-cell .tgl + .obj_tableau-beneficiaire .row-cell .tgl-btn {
    box-sizing: border-box;
    }
.obj_tableau-beneficiaire .row-cell .tgl::-moz-selection, .obj_tableau-beneficiaire .row-cell .tgl:after::-moz-selection, .obj_tableau-beneficiaire .row-cell .tgl:before::-moz-selection, .obj_tableau-beneficiaire .row-cell .tgl *::-moz-selection, .obj_tableau-beneficiaire .row-cell .tgl *:after::-moz-selection, .obj_tableau-beneficiaire .row-cell .tgl *:before::-moz-selection, .obj_tableau-beneficiaire .row-cell .tgl + .obj_tableau-beneficiaire .row-cell .tgl-btn::-moz-selection {
    background: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl::selection, .obj_tableau-beneficiaire .row-cell .tgl:after::selection, .obj_tableau-beneficiaire .row-cell .tgl:before::selection, .obj_tableau-beneficiaire .row-cell .tgl *::selection, .obj_tableau-beneficiaire .row-cell .tgl *:after::selection, .obj_tableau-beneficiaire .row-cell .tgl *:before::selection, .obj_tableau-beneficiaire .row-cell .tgl + .obj_tableau-beneficiaire .row-cell .tgl-btn::selection {
    background: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: var(--aox-40px);
    height: var(--aox-22px);
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn:after, .obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    }
.obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn:after {
    left: 0;
    }
.obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn:before {
    display: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl:checked + .tgl-btn:after {
    left: 50%;
    }

.obj_tableau-beneficiaire .row-cell .tgl-light + .tgl-btn {
    background: #D9D9D9;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    }
.obj_tableau-beneficiaire .row-cell .tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
    }
.obj_tableau-beneficiaire .row-cell .tgl-light:checked + .tgl-btn {
    background: #000000;
    }
.obj_tableau-beneficiaire .row-cell .tgl-light:checked:disabled + .tgl-btn {
    background: #E7D5EF;
    cursor: not-allowed;
    }

/***/


    
.obj_tableau-beneficiaire .ctn_expand {
    background-color: #F7F7F7;
    padding: var(--aox-10px) var(--aox-70px) var(--aox-10px) var(--aox-68px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* transition: all 1s ease-out; */
    animation: beneficiaire_effect 1.5s cubic-bezier(0.33,1,0.68,1) 0s both;
    flex-direction: column;
    flex-wrap: wrap;
    }
@keyframes beneficiaire_effect{
    0% { opacity:0; }
    100% { opacity:1; }
    }
    
.obj_tableau-beneficiaire .ctn_expand .ctn_expand-row {
    position: relative;
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-150);
    width: 100%;
    padding: var(--aox-10px) 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    }
.obj_tableau-beneficiaire .ctn_expand .ctn_expand-row + .ctn_expand-row {
    border-top: 1px solid #D9D9D9;
    }
.obj_tableau-beneficiaire .ctn_expand .ctn_expand-row > * + *{
    padding-left: var(--aox-50px);
    }
.obj_tableau-beneficiaire .ctn_expand .expand_date {
    width: var(--aox-90px);
    }
.obj_tableau-beneficiaire .ctn_expand .expand_marque {
    width: var(--aox-200px);
    text-transform: uppercase;
    }
.obj_tableau-beneficiaire .ctn_expand .expand_libelle {
    width: var(--aox-500px);
    }
.obj_tableau-beneficiaire .ctn_expand .expand_montant {
    margin-left: auto;
    font-size: var(--aox-fs-16px);
    }
.obj_tableau-beneficiaire .ctn_expand .expand_montant strong {
    font-weight: 700;
    }
   
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(1){ text-align: center; }

.obj_tableau-beneficiaire .table-row .row-cell:nth-child(5){ 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    }
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(6){ 
    text-align: right; 
    font-size: var(--aox-fs-16px);
    }
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(6) strong{ 
    padding-right: var(--aox-35px);
    }


.obj_tableau-beneficiaire .obj_btn_expend-cell{
    position:relative;
    z-index:999;
    width:var(--aox-24px); height:var(--aox-24px);
    background: #E5E5E5;
    border-radius: 50%;
    font-size: 0;
    cursor: pointer;
    transition:all .5s ease;
    }
.obj_tableau-beneficiaire .obj_btn_expend-cell::before{
    position: absolute;
    left: 50%; top: 55%;
    width: var(--aox-7px);
    height: var(--aox-7px);
    transform: rotate(-225deg) translate(-80%,-20%);
    transform-origin: 0 0;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    content: '';
    transition: all .3s ease;
    }
.obj_tableau-beneficiaire .obj_btn_expend-cell:hover {
    background-color: #000;
    }
.obj_tableau-beneficiaire .obj_btn_expend-cell:hover::before {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    }
.obj_tableau-beneficiaire .table-row.active .obj_btn_expend-cell::before{
    transform: rotate(-45deg) translate(-50%,-50%);
    }






/***/

@media screen and (max-width:767px){

    .obj_tableau-beneficiaire .table-row{
        flex-direction: column;
        align-items: flex-start;
        padding: var(--aox-16px) 0;
        }
    .obj_tableau-beneficiaire .table-row .table-header{
        width: 100%;
        }
    .obj_tableau-beneficiaire .table-row .table-header .row-cell{ text-align: left; }
    .obj_tableau-beneficiaire .row-cell{
        padding: 0;
        }
    .obj_tableau-beneficiaire .table-row .table-ctn-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: var(--aox-2px) 0;
        }
    .obj_tableau-beneficiaire .table-row .table-ctn-row .header-cell{
        font-weight: 400;
        padding: 0;
        width: var(--aox-110px);
        flex: unset;
        color: rgba(0,0,0,0.5);
        }
    .obj_tableau-beneficiaire .table-row .table-ctn-row .row-cell{
        flex: unset;
        }

    /***/
    .obj_tableau-beneficiaire .obj_btn_expand-detail{
        position: absolute;
        top: var(--aox-130px);
        right: 0;
        font-weight: 700;
        font-size: var(--aox-fs-11px);
        line-height: var(--aox-lh-100);
        width: var(--aox-120px);
        height: var(--aox-24px);
        text-transform: uppercase;
        text-align: left;
        }
    .obj_tableau-beneficiaire .obj_btn_expand-detail:before {
        content: '';
        position: absolute;
        right: var(--aox-6px);
        top: calc(50% - var(--aox-2px));
        width: 6px;
        height: 6px;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        transform: translateY(-50%) rotate(45deg) scale(1);
        transition: all .3s ease;
        }
    .obj_tableau-beneficiaire .obj_btn_expand-detail.is-expanded:before {
        transform: translateY(-10%) rotate(45deg) scale(-1);
        }
    .obj_tableau-beneficiaire .obj_table-gains-content {
        position: relative;
        opacity: 0;
        height: 0;
        transition: all .3s ease;
        }
    .obj_tableau-beneficiaire .table-row.is-expanded .obj_table-gains-content {
        opacity: 1;
        height: auto;
        }
    .obj_tableau-beneficiaire .table-row .obj_table-gains-content {
        position: relative;
        margin-top: var(--aox-10px);
        transition: all .3s ease;
        width: 100%;
        }
    .obj_tableau-beneficiaire .table-row .obj_table-gains-content .ctn_expand{
        position: relative;
        background-color: #F7F7F7;
        padding: var(--aox-10px) var(--aox-15px);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: all .8s ease;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        }
    .obj_tableau-beneficiaire .ctn_expand .ctn_expand-row{
        align-items: flex-start;
        }

    .obj_tableau-beneficiaire .ctn_expand .ctn_expand-row > * + * {
        padding-left: var(--aox-20px);
        }
    .obj_tableau-beneficiaire .ctn_expand .expand_date {
        width: var(--aox-90px);
        }
    .obj_tableau-beneficiaire .ctn_expand .expand_infos {
        width: var(--aox-200px);
        font-size: var(--aox-fs-14px);
        line-height: var(--aox-lh-140);
        }
    .obj_tableau-beneficiaire .ctn_expand .expand_marque {
        width: var(--aox-180px);
        }
    .obj_tableau-beneficiaire .ctn_expand .expand_libelle {
        width: var(--aox-180px);
        }

}