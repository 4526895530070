/* ======================================== */
/* .pg_contact
/* ======================================== */
.pg_contact.is-not-logged .main_container{
	padding-top:var(--aox-80px);
	}

@media screen and (max-width:767px){
	.pg_contact.is-not-logged .main_container{
		padding-top:var(--aox-60px);
		}
}