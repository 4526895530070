/* ======================================== */
/* .section_challenges
/* ======================================== */

.section_challenges{
	width: var(--aox-grid);
    margin: 0 auto;
	}
.section_challenges.obj_section .ctn_section {
	width: 100%;
	margin: 0 auto;
	}
.section_challenges.obj_section .ctn_title {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}
.section_challenges.obj_section .ctn_title .t_subtitle {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}

.section_challenges.obj_section .ctn_row {
	display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
	}


.section_challenges.obj_section .ctn_row > .ctn_filter{
	width: var(--aox-220px);
	}

.section_challenges.obj_section .ctn_row > .ctn_list_challenge{
	width: var(--aox-620px);
	margin-left: var(--aox-col-1);
	}
.section_challenges .ctn_list_challenge > .ctn_cta{
	text-align: center;
	margin-top: var(--aox-40px);
	}

.section_challenges.obj_section .ctn_row > .ctn_aside{
	width: var(--aox-330px);
	margin-left: var(--aox-40px);
	}


@media screen and (max-width:767px){

	.section_challenges.obj_section .ctn_section {
		width: var(--aox-grid);   
		}
	.section_challenges.obj_section .ctn_section .ctn_title{
		max-width: 80%;
		}
	.section_challenges.obj_section .ctn_row {
		position: relative;
		}
	.section_challenges.obj_section .ctn_row > .ctn_filter{
		width: 100%;
		order: 1;
		}
	
	.section_challenges.obj_section .ctn_row > .ctn_list_challenge{
		width: 100%;
		order: 3;
		}
	.section_challenges.obj_section .ctn_row > .ctn_list_challenge {
		margin-top: var(--aox-30px);
		margin-left: 0;
		}

}