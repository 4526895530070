/* ======================================== */
/* .section_tableau_recompenses
/* ======================================== */


@media screen and (max-width:767px){

	.section_tableau_recompenses .ctn_title .t_subtitle{
		font-size: var(--aox-fs-16px);
		text-align: center;
		}

}