/* ======================================== */
/* .pg_home-caf
/* ======================================== */
.pg_home-caf .section_push-article .str_container{
	max-width:var(--aox-1150px);
	}
.pg_home-caf .obj_push-article{
	margin:0;
	padding:var(--aox-60px) 0;
	border-top:1px solid #c3c3c3;
	}
.pg_home-caf .obj_push-article:first-of-type{
	border-top:none;
	}
.pg_home-caf .obj_push-article .ctn_push-article{
	border:none;
	}
.pg_home-caf .obj_push-article .ctn_text{
	padding:0 2% 0 8%;
	}
.pg_home-caf .obj_push-article .ctn_text h3{
	margin-top:var(--aox-15px);
	transform:translate(calc(-1 * var(--aox-30px)));
	}
.pg_home-caf .obj_push-article .ctn_text h3::before{
	position:absolute;
	left:0; top:var(--aox-35px);
	width:var(--aox-4px); height:var(--aox-44px);
	content:'';
	}

@media screen and (min-width:768px){
	.pg_home-caf .main_header.mode_mini{
		transform:translateY(0);
		}
}
@media screen and (max-width:767px){
	.pg_home-caf .main_container{
		max-width: unset;
		}
	.pg_home-caf .obj_push-article{
		/* width:calc(100% - var(--aox-30px));
		margin:0 var(--aox-15px); */
		width:100%;
		margin:0;
		padding:var(--aox-50px) 0 0 0;
		}
	.pg_home-caf .obj_push-article .ctn_text{
		padding:0 var(--aox-30px);
		}
	.pg_home-caf .obj_push-article .ctn_text h3{
		margin-top:var(--aox-30px);
		}
	.pg_home-caf .obj_push-article .ctn_text h3::before{
		top:var(--aox-30px);
		}
	.pg_home-caf .obj_push-article .ctn_text .obj_likes-comments{
		margin-bottom:var(--aox-40px);
		}
}