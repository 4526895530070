/* ======================================== */
/* .obj_push_banner
/* ======================================== */

.ctn_push_banner{
	width: 100%;
    max-width: var(--aox-1280px);
    height: 100%;
    margin: 0 auto;
	}
.obj_push_banner{
	position:relative;
	margin: 0 auto;
	height: var(--aox-540px);
	max-width: 100%;
	}
.obj_push_banner picture img {
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 50%;
	width: 100%;
	transform: translate(-50%,-50%);
	}
.obj_push_banner h2 picture img {
	position: relative;
	z-index: 1;
	left: inherit;
	top: inherit;
	width: auto;
	transform: inherit;
	}

.obj_push_banner .obj_cta {
	position: absolute;
	z-index: 2;
	bottom: var(--aox-70px);
	}
.obj_push_banner .ctn_text {
	position: absolute;
	z-index: 3;
	top: var(--aox-70px);
	max-width: var(--aox-580px);
	}
.obj_push_banner .ctn_text h2 {
	margin-bottom: var(--aox-20px);
	font-size:var(--aox-fs-60px);
	line-height: 0.9;
	text-transform: uppercase;
	}
.obj_push_banner .ctn_text h2 strong {
	font-weight: 700;
	}
.obj_push_banner .ctn_text h3 {
	margin-bottom: var(--aox-30px);
	font-size:var(--aox-fs-34px);
	line-height: 1;
	text-transform: uppercase;
	}
.obj_push_banner .ctn_text h4 {
	margin-bottom: var(--aox-20px);
	font-size:var(--aox-fs-20px);
	line-height: 1;
	text-transform: uppercase;
	font-weight: 700;
	}
.obj_push_banner .ctn_text p {
	font-size:var(--aox-fs-16px);
	line-height: 1.8;
	}
.obj_push_banner .ctn_text p strong {
	font-weight: 700;
	}
.obj_push_banner.var_left .obj_cta,
.obj_push_banner.var_left .ctn_text {
	left: var(--aox-160px);
	}
.obj_push_banner.var_right .obj_cta,
.obj_push_banner.var_right .ctn_text {
	left: 50%;
	}
.obj_push_banner.var_center .obj_cta,
.obj_push_banner.var_center .ctn_text {
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	}
.obj_push_banner.var_center .ctn_text {
	padding: 0;
	text-align: center;
	}
.obj_push_banner.var_dark .ctn_text h3,
.obj_push_banner.var_dark .ctn_text h4,
.obj_push_banner.var_dark .ctn_text p {
	color: #fff;
	}


@media screen and (max-width:1280px) {
	.obj_push_banner {
		height: 0;
		padding-bottom: 42.2%;
		}
	.obj_push_banner .obj_cta {
		bottom: 5.4vw;
		padding: 1.8vw 3.8vw;
		}
	.obj_push_banner .ctn_text {
		top: 5.4vw;
		}
	.obj_push_banner .ctn_text h2 {
		margin-bottom: 1.5vw;
		font-size:var(--aox-fs-60px);
		}
	.obj_push_banner .ctn_text h3 {
		margin-bottom: 2.3vw;
		font-size:var(--aox-fs-35px);
		}
	.obj_push_banner .ctn_text h4 {
		margin-bottom: 1.5vw;
		font-size:var(--aox-fs-15px);
		}
	.obj_push_banner .ctn_text p {
		font-size:var(--aox-fs-16px);
		}
	.obj_push_banner.var_left .obj_cta,
	.obj_push_banner.var_left .ctn_text {
		left: 12.5vw;
		}
	}

@media screen and (max-width:767px) {
	.ctn_push_banner{
		max-width: calc(var(--aox-410px) + var(--aox-4px));
		}
	.obj_push_banner {
		padding-bottom: 93%;
		}
	.obj_push_banner.var_left .obj_cta,
	.obj_push_banner.var_left .ctn_text {
		left: var(--aox-50px);
		}
	.obj_push_banner.var_center .ctn_text {
		width: 75%;
		}
	.obj_push_banner .ctn_text {
		width: 60%;
		top: var(--aox-40px);
		}
	.obj_push_banner .obj_cta {
		bottom: var(--aox-40px);
		padding: var(--aox-20px) var(--aox-30px);
		}
	.obj_push_banner .ctn_text h2 {
		margin-bottom: var(--aox-20px);
		font-size:var(--aox-fs-35px);
		}
	.obj_push_banner .ctn_text h2 picture img {
		width: 100%;
		}
	.obj_push_banner .ctn_text h3 {
		font-size:var(--aox-fs-18px);
		}
	.obj_push_banner .ctn_text h4 {
		margin-bottom: var(--aox-20px);
		font-size:var(--aox-fs-15px);
		}
	.obj_push_banner .ctn_text p {
		font-size:var(--aox-fs-14px);
		line-height: 1.6;
		padding-right: var(--aox-20px);
		}
	}

@media screen and (max-width:414px) {
	.obj_push_banner .ctn_text {
		top: 9.6vw;
		}
	.obj_push_banner .obj_cta {
		bottom: 9.6vw;
		}
	.obj_push_banner .ctn_text h2 {
		margin-bottom: 4.8vw;
		font-size: 8.4vw;
		}
	.obj_push_banner .ctn_text h3 {
		font-size: 4.3vw;
		}
	.obj_push_banner .ctn_text h4 {
		margin-bottom: 4.8vw;
		font-size: 3.6vw;
		}
	}