/* ======================================== */
/* .obj_carousel
/* ======================================== */
.obj_carousel{
	position:relative;
	overflow:hidden;
	max-width:100%;
	}
.obj_carousel .slick-list{
	overflow:hidden;
	height:100%;
	}
.obj_carousel .slick-track{
	display:flex;
	height:100%;
	margin:0 auto;
	}
.obj_carousel .slick-slide{
	position:relative;
    height:100%; width:100%;
	overflow:hidden;
	}
.obj_carousel .slick-arrow{
	position:absolute;
	z-index:2;
	top:50%;
	height:var(--aox-30px); width:var(--aox-30px);
	margin-top:calc(-1 * var(--aox-15px));
	text-indent:-9999em;
	transition:all .3s ease;
	}
.obj_carousel .slick-arrow.slick-disabled{
	cursor:default;
	}
.obj_carousel .slick-prev{
	left:3%;
	transform:rotate(-45deg) scale(1);
	border-top:solid 4px rgba(255,255,255,1);
	border-left:solid 4px rgba(255,255,255,1);
	}
.obj_carousel .slick-next{
	right:3%;
	transform:rotate(45deg) scale(1);
	border-top:solid 4px rgba(255,255,255,1);
	border-right:solid 4px rgba(255,255,255,1);
	}
.obj_carousel .slick-prev.slick-disabled{
	border-top-color:rgba(255,255,255,0.25);
	border-left-color:rgba(255,255,255,0.25);
	transform:rotate(-45deg) scale(1);
	}
.obj_carousel .slick-next.slick-disabled{
	border-top-color:rgba(255,255,255,0.25);
	border-right-color:rgba(255,255,255,0.25);
	transform:rotate(45deg) scale(1);
	}
.obj_carousel .slick-dots{
	position:absolute;
	z-index:1;
	left:0; bottom:7%;
	display:flex !important;
	width:100%;
	justify-content:center;
	}
.obj_carousel .slick-dots li{
	height:var(--aox-10px); width:var(--aox-10px);
	margin:0 5px;
	border-radius:50%;
	background-color:none;
	border:1px solid rgba(255,255,255,1);
	transition:all .3s ease;
	}
.obj_carousel .slick-dots li.slick-active{
	background-color:rgba(255,255,255,1);
	}
.obj_carousel .slick-dots li button{
	display:block;
	height:100%; width:100%;
	text-indent:-9999em;
	}

@media screen and (min-width:1025px){
	.obj_carousel .slick-prev:hover{
		left:2.5%;
		transform:rotate(-45deg) scale(1);
		}
	.obj_carousel .slick-next:hover{
		right:2.5%;
		transform:rotate(45deg) scale(1);
		}
	.obj_carousel .slick-prev.slick-disabled:hover{
		left:3%;
		border-top-color:rgba(255,255,255,0.25);
		border-left-color:rgba(255,255,255,0.25);
		transform:rotate(-45deg) scale(1);
		}
	.obj_carousel .slick-next.slick-disabled:hover{
		right:3%;
		border-top-color:rgba(255,255,255,0.25);
		border-right-color:rgba(255,255,255,0.25);
		transform:rotate(45deg) scale(1);
		}
	.obj_carousel .slick-dots li:hover{
		background-color:rgba(255,255,255,0.5);
		}
	.obj_carousel .slick-dots li.slick-active:hover{
		background-color:rgba(255,255,255,1);
		}
}
@media screen and (max-width:767px){
	.obj_carousel .slick-prev,
	.obj_carousel .slick-prev.slick-disabled{
		left:var(--aox-25px);
		transform:rotate(-45deg) scale(0.6);
		}
	.obj_carousel .slick-next,
	.obj_carousel .slick-next.slick-disabled{
		right:var(--aox-25px);
		transform:rotate(45deg) scale(0.6);
		}
	.obj_carousel .slick-dots{
		display:none !important;
		}
}