/* ======================================== */
/* .pg_page-liste
/* ======================================== */
.pg_ingredients-liste .obj_title{
	font-size:var(--aox-fs-42px);
	text-transform:uppercase;
	}
.pg_ingredients-liste .obj_push-custom{
	margin-top:0;
	margin-bottom:var(--aox-60px);
	}
.pg_ingredients-liste .obj_push-custom.var_full.var_dark .ctn_text h2{
	color:#fff !important;
	}

@media screen and (max-width:767px){
	.pg_ingredients-liste .obj_title{
		font-size:var(--aox-fs-38px);
		}
	.pg_ingredients-liste .obj_push-custom{
		margin-bottom:var(--aox-40px);
		}
	.pg_ingredients-liste .obj_push-custom.var_full .ctn_text,
	.pg_ingredients-liste .obj_push-custom.var_full.var_100 .ctn_text{
		max-width:100%;
		}
	.pg_ingredients-liste .obj_push-custom.var_full .ctn_text h2,
	.pg_ingredients-liste .obj_push-custom.var_full .ctn_text h2 p{
		font-size:var(--aox-fs-20px);
		}
}