/* ======================================== */
/* .obj_push-article
/* ======================================== */
.obj_push-article{
	width:100%; max-width:var(--aox-1280px);
	margin:var(--aox-60px) auto 0 auto;
	}
.obj_push-article .ctn_push-article{
	display:flex;
	flex-flow:row wrap;
	border:1px solid #c7c7c7;
	}
.obj_push-article.var_comment .ctn_push-article{
	border-bottom:none;
	}
.obj_push-article .ctn_img{
	width:36.35%; max-width:calc(var(--aox-460px) + var(--aox-5px));
	overflow:hidden;
	}
.obj_push-article .ctn_img a{
	display:block;
	position:relative;
	height:100%;
	}
.obj_push-article.var_video .ctn_img a::before,
.obj_push-article.var_video .ctn_img a::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	}
.obj_push-article.var_video .ctn_img a::before{
	width:var(--aox-88px); height:var(--aox-88px);
	border:4px solid #fff;
	border-radius:50%;
	transform:translate(-50%,-50%);
	}
.obj_push-article.var_video .ctn_img a::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-16px) 0 var(--aox-16px) var(--aox-28px);
	border-color:transparent transparent transparent #ffffff;
	transform:translate(-40%,-50%);
	}
.obj_push-article .ctn_img img{
	position:relative;
	z-index:1;
	width:100%;
	transition:all .3s ease;
	}
.obj_push-article .ctn_text{
	position:relative;
	width:63.65%;
	padding:0 6% 0 7%;
	}
.obj_push-article .ctn_text h3{
	position:relative;
	margin-top:var(--aox-80px); 
	font-size:var(--aox-fs-16px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.obj_push-article .ctn_text h2{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-32px); line-height:1.5;
	font-weight:700;
	}
.obj_push-article .ctn_text .t_author-date{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-13px); line-height:1;
	}
.obj_push-article .ctn_text .t_author-date strong{
	font-weight:700;
	}
.obj_push-article .ctn_text .t_main{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-16px); line-height:1.875;
	}
.obj_push-article .ctn_text .ctn_cta{
	margin-top:var(--aox-10px);
	}

@media screen and (min-width:1025px){
	.obj_push-article .ctn_img a:hover img{
		transform:scale(1.05);
		}
}
@media screen and (max-width:1340px){
	.obj_push-article .ctn_text h2{
		font-size:var(--aox-fs-32px);
		}
}
@media screen and (max-width:767px){
	.obj_push-article{
		margin: var(--aox-40px) 0 0 0;
		}
	.obj_push-article .ctn_img{
		width:100%;
		}
	.obj_push-article .ctn_img .lazyload-placeholder{
		height:0;
		padding-bottom:100%;
		}
	.obj_push-article .ctn_text{
		width:100%;
		padding:0 var(--aox-30px);
		border-top:none;
		}
	.obj_push-article .ctn_text h3{
		margin-top:var(--aox-30px);
		font-size:var(--aox-fs-12px);
		}
	.obj_push-article .ctn_text h2{
		margin-top:var(--aox-20px);
		font-size:var(--aox-fs-24px);
		}
	.obj_push-article .ctn_text .ctn_cta{
		margin-top:var(--aox-25px);
		}
}