/* ======================================== */
/* .section_range-liste
/* ======================================== */
.section_range-liste{
	max-width:var(--aox-1150px);
	margin:var(--aox-60px) auto 0 auto;
	}
.section_range-liste + .section_range-liste{
	margin-top:var(--aox-80px);
	}
.section_range-liste .ctn_range-liste > h3{
	width:100%;
	padding:0 var(--aox-30px) var(--aox-20px) var(--aox-30px);
	font-size:var(--aox-fs-32px); line-height:1.2;
	border-bottom:1px solid #c3c3c3;
	}
.section_range-liste .ctn_range-liste > h3 strong{
	text-transform:uppercase;
	font-weight:700;
	}
.section_range-liste .ctn_range-liste{
	display:flex;
	flex-flow:row wrap;
	}
.section_range-liste .ctn_range-liste + .ctn_range-liste{
	margin-top:var(--aox-80px);
	}