/* ======================================== */
/* .obj_back-page
/* ======================================== */
.obj_loader{
	box-sizing:content-box;
	margin-top:var(--aox-20px);
	width:var(--aox-50px); height:var(--aox-50px);
	border:5px solid #b3b3b3;
	border-top-color:#666;
	border-bottom-color:#666;
	border-radius:var(--aox-50px);
	animation:rotationLoop 1s ease infinite;
	}