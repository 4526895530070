/* ======================================== */
/* .obj-push-custom-text
/* ======================================== */
.obj-push-custom-text{
	position:relative;
	margin:var(--aox-100px) var(--aox-30px) var(--aox-60px) var(--aox-30px);
	}
.obj-push-custom-text.var_100 {
	width: 100%;
	}
.obj-push-custom-text .ctn_text{
	text-align: center;
	}
.obj-push-custom-text .ctn_text p{
	font-size:var(--aox-fs-20px); line-height:1;
	padding:0 10%;
	}
.obj-push-custom-text .ctn_text p strong{
	font-weight:700;
	}

@media screen and (max-width:767px){
	.obj-push-custom-text{
		width:100% !important;
		margin:var(--aox-60px) var(--aox-20px);
		}
	.obj-push-custom-text .ctn_text p{
		font-size:var(--aox-fs-16px);
		}
}
@media screen and (max-width:320px){
	.obj-push-custom-text .ctn_text{
		max-width:100%;
		}
}