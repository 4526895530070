/* ======================================== */
/* .obj_title
/* ======================================== */
.obj_title{
	width:100%;
	padding:var(--aox-60px) var(--aox-20px);
	font-weight:700;
	font-size:var(--aox-fs-20px); line-height:1;
	text-align:center;
	text-transform:uppercase;
	}
.obj_title-big{
	width:100%;
	padding:var(--aox-60px) var(--aox-20px);
	font-weight:700;
	font-size:var(--aox-fs-46px); line-height:1;
	text-align:center;
	}
@media screen and (max-width:1340px){
	.obj_title,
	.obj_title-big{
		padding:4.5vw 2.2vw;
		}
}
@media screen and (max-width:767px){
	.obj_title{
		font-size:var(--aox-fs-14px);
		padding:var(--aox-40px) var(--aox-20px);
		}
	.obj_title-big{
		font-size:9vw;
		padding:var(--aox-40px) var(--aox-20px);
		}
}