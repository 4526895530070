/* ======================================== */
/* .section_push-article
/* ======================================== */
.section_wide-carousel + .section_push-article{
	margin:var(--aox-60px) 0;
	}
.section_push-article{
	width:100%;
	}
.section_push-article .str_container > .ctn_cta{
	padding-top:var(--aox-60px);
	text-align:center;
	border-top:1px solid #c3c3c3;
	}

@media screen and (max-width:767px){
	.section_wide-carousel + .section_push-article{
		margin:0;
		}
	.section_push-article .str_container > .ctn_cta{
		padding-bottom:var(--aox-60px);
		}
}