/* ======================================== */
/* .section_mini_parcours
/* ======================================== */

.section_mini_parcours{
	max-width: var(--aox-1050px);
    margin: 0 auto;
	}
.section_mini_parcours.obj_section .ctn_section {
	width: 100%;
	margin: 0 auto;
	}
.section_mini_parcours.obj_section .ctn_title {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}
.section_mini_parcours.obj_section .ctn_title .t_subtitle {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}

@media screen and (max-width:767px){

	.section_mini_parcours.obj_section{
		padding: var(--aox-25px) 0 var(--aox-30px) 0;
		}
	.section_mini_parcours.obj_section .ctn_section {
		width: var(--aox-grid);
		}
	.section_mini_parcours.obj_section .ctn_section .ctn_title{
		max-width: 80%;
		}
	.obj_section.section_mini_parcours.obj_section .ctn_title {
		margin-bottom: var(--aox-15px);
		}


}