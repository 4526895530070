/* ======================================== */
/* .obj_popin
/* ======================================== */
.obj_popin{
    width:var(--aox-900px); max-width:90%;
	padding:var(--aox-60px) 0 var(--aox-60px) var(--aox-60px);
	background:#fff;
	}
.obj_popin .ctn_popin{
	max-height:75vh;
	padding-right:var(--aox-60px);
	overflow-y:auto;
	}
/**/
.obj_popin .bt_close{
	display:block;
	position:absolute;
	z-index:9999;
	right:0; top:0;
	width:var(--aox-60px); height:var(--aox-60px);
	background:#000;
	}
.obj_popin .bt_close::before,
.obj_popin .bt_close::after{
	position:absolute;
	left:50%; top:50%;
	background-color:#fff;
	transition:all .3s ease;
	content:'';
	}
.obj_popin .bt_close::before{
	width:2px; height:var(--aox-30px);
	transform:translate(-50%,-50%) rotate(45deg);
	}
.obj_popin .bt_close::after{
	width:var(--aox-30px); height:2px;
	transform:translate(-50%,-50%) rotate(45deg);
	}
/**/
.obj_popin .t_title{
	margin-bottom:var(--aox-50px);
	padding-right:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.obj_popin .t_main{
	font-size:var(--aox-fs-16px); line-height:1.4;
	}
.obj_popin .t_main + .t_main{
	margin-top:var(--aox-20px);
	}
.obj_popin img{
	max-width:100%;
	}
.obj_popin .ctn_cta{
	display: flex;
	margin-top:var(--aox-30px);
	}
.obj_popin .ctn_cta .obj_cta + .obj_cta{
	margin-left:var(--aox-10px);
	}
.obj_popin .ctn_cta .obj_btn + .obj_btn{
	margin-left:var(--aox-10px);
	}

.obj_popin .ctn_input{
	position: relative;
	display: flex;
	margin-top:var(--aox-30px);
	}
.obj_popin .ctn_input input[type="text"]{
	width: 100%;
	height: var(--aox-60px);
	padding: var(--aox-10px) var(--aox-50px) var(--aox-10px) var(--aox-25px);
	font-size: var(--aox-fs-14px);
	line-height: 1;
	color: #777;
	font-weight: 500;
	border: 1px solid #777;
	}
.obj_popin .ctn_checkbox input[type=checkbox] {
	display: none;
	}
.obj_popin .ctn_checkbox label {
	display: block;
	position: relative;
	padding: 0 0 0 var(--aox-30px);
	min-height: var(--aox-20px);
	font-size: var(--aox-fs-12px);
	line-height: 1.2;
	color: #777;
	font-weight: 500;
	cursor: pointer;
	}
.obj_popin .ctn_checkbox label::before {
	position: absolute;
	left: 0;
	top: 0;
	width: var(--aox-16px);
	height: var(--aox-16px);
	border: 1px solid #777;
	background: #fff;
	transition: all .3s ease;
	content: '';
	}
.obj_popin .ctn_checkbox label::after {
	position: absolute;
	left: var(--aox-4px);
	top: var(--aox-4px);
	width: var(--aox-10px);
	height: var(--aox-10px);
	transform: scale(0.3);
	opacity: 0;
	background: #000;
	content: '';
	transition: all .3s ease;
	}
.obj_popin .ctn_checkbox input[type=checkbox]:checked + label::after {
	transform: scale(1);
	opacity: 1;
	}
.obj_popin .obj_infobulle .info_text {
	width: var(--aox-300px);
	}

/**/
.obj_popin.popin_video{
    width:100%; height:100%;
	max-width:unset;
	padding:0;
	}
.obj_popin.popin_video iframe{
	position:absolute;
	left:0; top:0;
    width:100%; height:100%;
	}
/**/
.obj_popin.popin_video-nofs .t_title{
	padding-left:var(--aox-20px);
	text-align:center;
	}
.obj_popin.popin_video-nofs .ctn_iframe{
	position:relative;
	height:0;
	padding-bottom:56.25%;
	}
.obj_popin.popin_video-nofs .ctn_iframe > div,
.obj_popin.popin_video-nofs .ctn_iframe iframe{
    position:absolute;
	left:0; top:0;
    width:100%; height:100%;
	}
/**/
.obj_popin.popin_expert .ctn_row{
	display:flex;
	flex-flow:row wrap;
	align-items:center;
	}
.obj_popin.popin_expert .ctn_row.var_star{
	padding:0 var(--aox-50px);
	}
.obj_popin.popin_expert .ctn_row .t_main{
	width:72%;
	padding-left:var(--aox-50px);
	}



.obj_popin.popin_expert .obj_circle-progress-expertise{
	max-width:var(--aox-220px);
	width:28%;
	}
.obj_popin.popin_expert .obj_circle-progress-expertise .ctn_txt{
	font-size:var(--aox-fs-40px);
	}
.obj_popin.popin_expert .obj_circle-progress-expertise .ctn_txt .t_title{
	margin-bottom:0;
	padding-right:0;
	}
.obj_popin.popin_expert .obj_circle-progress-expertise .ctn_avatar{
	width: var(--aox-64px);
    height: var(--aox-64px);
	}


.obj_popin.popin_expert .ctn_popin > .t_title{
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-40px);
	font-weight:300;
	text-transform:none;
	}
.obj_popin.popin_expert .ctn_grey{
	margin-top:var(--aox-40px);
	padding:var(--aox-30px);
	background:#f2f2f2;
	}
.obj_popin.popin_expert .obj_star-score{
	width:25%;
	margin-top:var(--aox-40px);
	}
.obj_popin.popin_expert .obj_star-score svg{
	width:var(--aox-75px);
	transform:scale(1.35);
	}
.obj_popin.popin_expert .obj_circle-progress.var_experte .CircularProgressbar .CircularProgressbar-path{
	stroke:url(#gradient-circleprogress-popin);
	}
.obj_popin.popin_expert .obj_star-score.var_experte svg{
	fill:url(#gradient-starscore-popin);
	}
/**/
.obj_popin.popin_galerie{
	width:var(--aox-1024px);
	}
.obj_popin.popin_galerie .ctn_popin{
	margin-top:var(--aox-75px);
	}
.obj_popin.popin_galerie .t_title{
	position:fixed;
	z-index:1;
	left:0; top:var(--aox-60px);
	width:100%;
	margin:0;
	padding:0;
	text-align:center;
	background:#fff;
	}
.obj_popin.popin_galerie .t_mention{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-10px);
	text-align:center;
	}
.obj_popin.popin_galerie .obj_form{
	display:flex;
	flex-wrap:wrap;
	}
.obj_popin.popin_galerie .obj_form .col_left{
	width:60%;
	}
.obj_popin.popin_galerie .obj_form .col_right{
	width:40%;
	padding-left:5%;
	padding-bottom:var(--aox-100px);
	}
.obj_popin.popin_galerie .obj_form .ctn_row{
	justify-content:center;
	}
.obj_popin.popin_galerie .obj_form .t_subtitle{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-18px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	text-align:center;
	}
.obj_popin.popin_galerie .t_main{
	text-align:center;
	}
.obj_popin.popin_galerie .ctn_label{
	max-height:var(--aox-125px);
	padding-right:var(--aox-10px);
	overflow-y:auto;
	}
.obj_popin.popin_galerie .ctn_label .t_label + .t_label{
	margin-top:var(--aox-20px);
	}
.obj_popin.popin_galerie .obj_form .col_right .ctn_cta{
	position:fixed;
    right:var(--aox-10px); bottom:0;
    width:36%;
    padding:var(--aox-10px) 0 var(--aox-60px) 0;
	margin:0;
    background:#fff;
	}
.obj_popin.popin_galerie .obj_form .col_right .ctn_cta .obj_cta{
	margin:0;
	}
.obj_popin.popin_galerie .obj_form .col_right .t_error{
	position:absolute;
	left:0; bottom:var(--aox-20px);
	padding-right:var(--aox-10px);
	}
/**/
.obj_popin.popin_galerie-comment{
	width:var(--aox-1024px);
	padding:0;
	}
.obj_popin.popin_galerie-comment .ctn_popin{
	display:flex;
	flex-wrap:wrap;
	padding:0;
	max-height:var(--aox-560px);
	}
.obj_popin.popin_galerie-comment .ctn_image{
	position:relative;
	width:54.7%;
	}
.obj_popin.popin_galerie-comment .ctn_image .ctn_solo-img,
.obj_popin.popin_galerie-comment .obj_ba-slider{
	height:0;
	padding-bottom:100%;
	overflow:hidden;
	}
.obj_popin.popin_galerie-comment .ctn_image .ctn_solo-img img{
	position:absolute;
	left:50%; top:0;
	transform:translateX(-50%);
	}
.obj_popin.popin_galerie-comment .obj_ba-slider .ctn_after-img{
	position:absolute;
	left:0; top:0;
	}
.obj_popin.popin_galerie-comment .ctn_text{
	position:relative;
	width:45.3%;
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_scroll{
	height:75%;
	overflow-y:auto;
	background:#f2f2f2;
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_top{
	padding:var(--aox-30px) var(--aox-60px) var(--aox-30px) var(--aox-30px);
	background:#fff;
	}
.obj_popin.popin_galerie-comment .ctn_avatar{
	display:flex;
	align-items:center;
	}
.obj_popin.popin_galerie-comment .ctn_avatar .ctn_img{
	margin-right:var(--aox-10px);
	width:var(--aox-40px); height:var(--aox-40px);
	border-radius:50%;
	overflow:hidden;
	}
.obj_popin.popin_galerie-comment .ctn_avatar .ctn_txt .t_author{
	font-size:var(--aox-fs-14px);
	font-weight:700;
	}
.obj_popin.popin_galerie-comment .ctn_avatar .ctn_txt .t_date{
	margin-top:var(--aox-5px);
	font-size:var(--aox-fs-12px);
	color:#666;
	}
.obj_popin.popin_galerie-comment .obj_likes-comments{
	display:flex;
	justify-content:flex-start;
	min-height:var(--aox-20px);
	margin:var(--aox-20px) 0 0 0;
	}
.obj_popin.popin_galerie-comment .obj_likes-comments li{
	margin-right:var(--aox-25px);
	line-height:var(--aox-20px);
	}
.obj_popin.popin_galerie-comment .obj_likes-comments .bt_like-article[disabled]{
	opacity:0.5;
	cursor:default;
	}
.obj_popin.popin_galerie-comment .obj_likes-comments .bt_like-article svg{
	transition:all .3s ease;
	}
.obj_popin.popin_galerie-comment .obj_likes-comments .bt_like-article.is-checked svg{
	fill:#000;
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_comments{
	padding:var(--aox-30px) var(--aox-60px) var(--aox-30px) var(--aox-30px);
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_comments .ctn_comment + .ctn_comment{
	margin-top:var(--aox-30px);
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_comments .ctn_comment .t_comment{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-12px); line-height:1.2;
	}
.obj_popin.popin_galerie-comment .ctn_text .t_submitted{
	padding:var(--aox-30px) var(--aox-60px) var(--aox-30px) var(--aox-30px);
	font-size:var(--aox-fs-12px); line-height:1.2;
	}
.obj_popin.popin_galerie-comment .obj_form{
	position:absolute;
	left:0; bottom:0;
	width:100%; height:25%;
	overflow:hidden;
	background:#fff;
	transition:height .3s ease;
	}
.obj_popin.popin_galerie-comment .obj_form.is-expanded{
	height:75%;
	border-top:1px solid #f2f2f2;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_textarea textarea{
	height:var(--aox-70px);
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_cta{
	position:absolute;
	left:var(--aox-20px); bottom:var(--aox-20px);
	margin:0;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_cta .obj_cta{
	margin:0;
	padding:0;
	}
.obj_popin.popin_galerie-comment .obj_form .t_error{
	padding:0 var(--aox-20px);
	}
.obj_popin.popin_galerie-comment .obj_form .obj_cta-txt{
	position:absolute;
	padding:0 var(--aox-17px) 0 0;
	right:var(--aox-20px); bottom:var(--aox-20px);
	font-size:var(--aox-fs-10px);
	color:#b2b2b2;
	text-align:left;
	}
.obj_popin.popin_galerie-comment .obj_form .obj_cta-txt::before{
	content:'';
	position:absolute;
	right:2px; top:0;
	width:6px; height:6px;
	border:1px solid #b2b2b2;
	border-left:none;
	border-bottom:none;
	transform:scaleY(1) rotate(135deg);
	transition:all .3s ease;
	}
.obj_popin.popin_galerie-comment .obj_form.obj_form.is-expanded .obj_cta-txt::before{
	top:3px;
	transform:scaleY(-1) rotate(135deg);
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions{
	position:absolute;
	left:0; top:var(--aox-100px);
	padding:var(--aox-20px);
	height:63%;
	overflow-y:auto;
	transform:translateX(-100%);
	opacity:0;
	transition:opacity .3s ease .3s;
	}
.obj_popin.popin_galerie-comment .obj_form.is-expanded .ctn_txt-mentions{
	transform:translateX(0);
	opacity:1;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions .t_title-mention{
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions .t_title-desc{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-10px); line-height:1.2;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions .t_title-desc a{
	text-decoration:underline;
	}
/**/
.ReactModal__Overlay{
    opacity:0;
    transition:opacity .3s ease;
	}
.ReactModal__Overlay--after-open{
    opacity:1;
	}
.ReactModal__Overlay--before-close{
    opacity:0;
	}



/**/
.obj_popin.popin_post-fb{
	width:var(--aox-1024px);
	padding:0;
	}
.obj_popin.popin_post-fb .ctn_popin{
	display:flex;
	flex-wrap:wrap;
	padding:0;
	max-height:var(--aox-700px);
	}
.obj_popin.popin_post-fb .ctn_image{
	position:relative;
	width:54.7%;
	}
.obj_popin.popin_post-fb .ctn_image .ctn_solo-img,
.obj_popin.popin_post-fb .obj_ba-slider{
	position:relative;
	height:0;
	padding-bottom:125%;
	overflow:hidden;
	background:#000;
	}
.obj_popin.popin_post-fb .ctn_image .ctn_solo-img img{
	position:absolute;
	left:50%; top:0;
	width:100%; height:100%;
	object-fit:contain;
	transform:translateX(-50%);
	}
.obj_popin.popin_post-fb .obj_ba-slider .ctn_after-img{
	position:absolute;
	left:0; top:0;
	}
.obj_popin.popin_post-fb .ctn_text{
	position:relative;
	width:45.3%;
	}
.obj_popin.popin_post-fb .ctn_text .ctn_scroll{
	height:60%;
	overflow-y:auto;
	background:#ffffff;
	}
.obj_popin.popin_post-fb .ctn_text .ctn_post-fb{
	padding:var(--aox-40px);
	}
.obj_popin.popin_post-fb .ctn_top{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
	}
.obj_popin.popin_post-fb .ctn_top .t_title{
	position: relative;
	min-height: var(--aox-40px);
	font-family: 'Montserrat', sans-serif;
    font-weight: 700;
	padding-left: var(--aox-50px);
    font-size:var(--aox-fs-20px);
    line-height: 1.1;
	margin: 0;
	}
.obj_popin.popin_post-fb .ctn_top .t_title:before {
	content: "";
	position: absolute;
	left: 0; top: 0;
	height: var(--aox-40px);
	width: var(--aox-40px);
	background: url(../../images/i_facebook-picto.svg) left top / var(--aox-40px) auto no-repeat;
	}	
.obj_popin.popin_post-fb .ctn_top .obj_likes-fb {
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	margin: var(--aox-20px) 0 0 0;
	}
.obj_popin.popin_post-fb .ctn_top .obj_likes-fb li {
	padding: 3px 0 0 var(--aox-25px);
	margin-right: var(--aox-20px);
	font-size:var(--aox-fs-11px);
	}
.obj_popin.popin_post-fb .ctn_post-fb .ctn_txt .t_desc{
	margin-top:var(--aox-15px);
    font-size:var(--aox-fs-14px); line-height: 1.57143;
    font-weight: 300;
	}
.obj_popin.popin_post-fb .ctn_bottom{
	position: absolute;
    display: flex;
    left: 0; bottom: 0;
    width: 100%; height: 40%;
    overflow: hidden;
    background: #fff;
    padding: var(--aox-40px);
    transition: height .3s ease;
    flex-direction: column;
    justify-content: flex-end;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_cta{
	position: relative;
    display: flex;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_cta .obj_cta{
	margin:0;
	color: #000;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_cta .obj_cta + .obj_cta{
	margin-left: var(--aox-10px);
	}
.obj_popin.popin_post-fb .ctn_bottom .t_error{
	padding:0 var(--aox-20px);
	}
.obj_popin.popin_post-fb .ctn_bottom .obj_cta-txt{
	position:absolute;
	padding:0 var(--aox-17px) 0 0;
	right:var(--aox-20px); bottom:var(--aox-23px);
	font-size:var(--aox-fs-10px);
	color:#b2b2b2;
	text-align:left;
	}
.obj_popin.popin_post-fb .ctn_bottom .obj_cta-txt::before{
	content:'';
	position:absolute;
	right:2px; top:0;
	width:6px; height:6px;
	border:1px solid #b2b2b2;
	border-left:none;
	border-bottom:none;
	transform:scaleY(1) rotate(135deg);
	transition:all .3s ease;
	}
.obj_popin.popin_post-fb .ctn_bottom.obj_form.is-expanded .obj_cta-txt::before{
	top:3px;
	transform:scaleY(-1) rotate(135deg);
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage{
	position:relative;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ul,
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ol{
	font-size:var(--aox-fs-14px); line-height: 1.57143;
	list-style-type: none;
    counter-reset: counterRules;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ul li,
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ol li{
	position: relative;
    margin-top: var(--aox-10px);
    font-size:var(--aox-fs-14px); line-height: 1.57143;
    font-weight: 300;
    padding-left: var(--aox-35px);
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ul li strong,
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ol li strong{
	font-weight: 700;
	}	
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ul li:before,
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ol li:before{
	position: absolute;
    left: 0;
    width: var(--aox-24px); height: var(--aox-24px);
    background-color: #f7c74d;
    color: #000;
    font-weight: 700;
    border-radius: 50%;
    text-align: center;
    counter-increment: counterRules 1;
    content: counter(counterRules);
	}


/***/


/**/
.obj_popin.popin_produits-concernes .ctn_popin{
	max-height:var(--aox-700px);
	}
.obj_popin.popin_produits-concernes{
	width:var(--aox-1024px);
	}
.obj_popin.popin_produits-concernes .ctn_title .t_title{
	font-size: var(--aox-fs-40px);
	line-height: var(--aox-lh-100);
	font-weight: 300;
	text-transform: none;
	margin-bottom: var(--aox-25px);
	}




/**/
@media screen and (min-width:1025px){
	.obj_popin .bt_close:hover{
		background:#777;
		}
	.obj_popin.popin_galerie-comment .obj_likes-comments .bt_like-article:hover svg{
		fill:#000;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_cta .obj_cta:hover{
		color:#000;
		background:none;
		}
}
@media screen and (max-width:1024px){
	.obj_popin{
		padding:6vw 0 6vw 6vw;
		}
	.obj_popin .ctn_popin{
		padding-right:6vw;
		}
	.obj_popin .bt_close{
		width:6vw; height:6vw;
		}
	.obj_popin .bt_close::before{
		height:3vw;
		}
	.obj_popin .bt_close::after{
		width:3vw;
		}
}
@media screen and (max-height:800px) and (min-width:1024px){
	.obj_popin{
		padding:7.5vh 0 7.5vh 7.5vh;
		}
	.obj_popin .ctn_popin{
		padding-right:7.5vh;
		}
	.obj_popin .bt_close{
		width:7.5vh; height:7.5vh;
		}
	.obj_popin .bt_close::before{
		height:3.75vh;
		}
	.obj_popin .bt_close::after{
		width:3.75vh;
		}
	.obj_popin.popin_galerie .t_title{
		top:7.5vh;
		}
	.obj_popin.popin_galerie .obj_form .col_right .ctn_cta{
		padding-bottom:7.5vh;
		}
	.obj_popin.popin_galerie .obj_form .col_right .t_error{
		bottom:2.5vh;
		}
}
@media screen and (max-width:768px){
	.obj_popin.popin_expert .ctn_popin > .t_title{
		font-size:var(--aox-fs-36px);
		}
	.obj_popin.popin_expert .obj_circle-progress{
		width:var(--aox-205px);
		}
	.obj_popin.popin_expert .ctn_row .t_main{
		width:100%;
		padding-left:0;
		}
	.obj_popin.popin_expert .ctn_row + .t_title{
		margin-top:var(--aox-40px);
		}
	.obj_popin.popin_galerie-comment .ctn_image,
	.obj_popin.popin_galerie-comment .ctn_text{
		width:100%;
		}
	.obj_popin.popin_post-fb .ctn_image,
	.obj_popin.popin_post-fb .ctn_text{
		width:100%;
		}	
	.obj_popin.popin_post-fb .ctn_text{
		height:auto;
		}	

	.obj_popin.popin_post-fb .ctn_text .ctn_scroll{
		height:auto;
		}
	.obj_popin.popin_post-fb .ctn_bottom{
		position:relative;
		left:auto; bottom:auto;
		height:auto;
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_post-fb{
		padding-bottom:0;
		}
}
@media screen and (min-width:768px) and (max-width:768px){
	.obj_popin.popin_galerie-comment .ctn_image img{
		max-width:calc(var(--aox-420px) + var(--aox-5px));
		margin:0 auto;
		}
	.obj_popin.popin_galerie-comment .ctn_image .ctn_solo-img{
		padding-bottom:62%;
		}
	.obj_popin.popin_galerie-comment .obj_ba-slider{
		padding-bottom:50%;
		}
	.obj_popin.popin_post-fb .ctn_image img{
		max-width:calc(var(--aox-420px) + var(--aox-5px));
		margin:0 auto;
		}
	.obj_popin.popin_post-fb .ctn_image .ctn_solo-img{
		padding-bottom:62%;
		}
	.obj_popin.popin_post-fb .obj_ba-slider{
		padding-bottom:50%;
		}	
}
@media screen and (max-width:767px){
	.ReactModal__Body--open .main_header,
	.ReactModal__Body--open .main_footer,
	.ReactModal__Body--open .main_footer-login,
	.ReactModal__Body--open .main_container{
		display:none;
		}
	.ReactModal__Body--open .ReactModal__Overlay{
		position:relative !important;
		left:auto !important; right:auto !important;
		top:auto !important; bottom:auto !important;
		background:none !important;
		}
	.ReactModal__Body--open .obj_popin{
		position:relative !important;
		left:auto !important; right:auto !important;
		top:auto !important; bottom:auto !important;
		max-width:100%;
		padding:var(--aox-40px) 0 var(--aox-40px) var(--aox-40px);
		transform:none !important;
		}
	.ReactModal__Body--open .obj_popin.popin_video{
		padding:0 0 56% 0;
		}
	.ReactModal__Body--open .obj_popin::before{
		position:fixed;
		left:0; top:0;
		width:100%; height:var(--aox-40px);
		background:#fff;
		content:'';
		}
	.ReactModal__Body--open .obj_popin .ctn_popin{
		margin-top:0;
		max-height:unset;
		padding-right:var(--aox-40px);
		overflow-y:unset;
		}
	.obj_popin .bt_close,
	.ReactModal__Body--open .obj_popin .bt_close{
		position:fixed;
		width:var(--aox-40px); height:var(--aox-40px);
		}
	.obj_popin .bt_close::before,
	.ReactModal__Body--open .obj_popin .bt_close::before{
		height:var(--aox-20px);
		}
	.obj_popin .bt_close::after,
	.ReactModal__Body--open .obj_popin .bt_close::after{
		width:var(--aox-20px);
		}
	.ReactModal__Body--open .obj_popin .t_title{
		position:relative;
		left:auto; top:auto;
		margin-bottom:var(--aox-30px);
		font-size:var(--aox-fs-16px);
		}
	.ReactModal__Body--open .obj_popin .t_main + .t_main{
		margin-top:var(--aox-15px);
		}
	.obj_popin.popin_expert .ctn_row.var_star{
		padding:0;
		}
	.obj_popin.popin_expert .obj_star-score .t_status{
		margin-top:calc(-1 * var(--aox-15px));
		font-size:var(--aox-fs-13px);
		}
	.obj_popin.popin_expert .obj_star-score svg{
		transform:scale(1);
		}
	.obj_popin.popin_galerie .obj_form .col_left{
		width:100%;
		}
	.obj_popin.popin_galerie .obj_form .col_right{
		width:100%;
		padding-left:0;
		padding-top:var(--aox-30px);
		padding-bottom:0;
		}
	.obj_popin.popin_galerie .obj_form .ctn_single-file-upload .t_name{
		font-size:var(--aox-fs-12px);
		}
	.obj_popin.popin_galerie .obj_form .ctn_single-file-upload .t_weight{
		font-size:var(--aox-fs-10px);
		}
	
	.obj_popin.popin_galerie .obj_form .col_right .ctn_cta{
		position:relative;
		right:auto; bottom:auto;
		width:100%;
		padding:0;
		margin-top:var(--aox-30px);
		}
	.obj_popin.popin_galerie .obj_form .col_right .t_error{
		position:relative;
		left:auto; bottom:auto;
		margin-top:var(--aox-20px);
		}

	.obj_popin.popin_galerie-comment .ctn_text .ctn_top,
	.obj_popin.popin_galerie-comment .ctn_text .ctn_comments,
	.obj_popin.popin_galerie-comment .ctn_text .t_submitted{
		padding:var(--aox-20px);
		}
	.obj_popin.popin_galerie-comment .obj_likes-comments{
		flex-direction:column;
		}
	.obj_popin.popin_galerie-comment .obj_likes-comments li + li{
		margin-top:var(--aox-15px);
		}
	.obj_popin.popin_galerie-comment .ctn_text .ctn_scroll{
		height:auto;
		}
	.obj_popin.popin_galerie-comment .obj_form,
	.obj_popin.popin_galerie-comment .obj_form.is-expanded{
		position:relative;
		left:auto; top:auto;
		height:auto;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_cta,
	.obj_popin.popin_galerie-comment .obj_form .obj_cta-txt,
	.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions{
		position:relative;
		left:auto; right:auto;
		top:auto; bottom:auto;
		height:auto;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_cta{
		padding:var(--aox-20px);
		}
	.obj_popin.popin_galerie-comment .obj_form .obj_cta-txt{
		padding:0 var(--aox-17px) 0 var(--aox-20px);
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions,
	.obj_popin.popin_galerie-comment .obj_form.is-expanded .ctn_txt-mentions{
		transform:translateX(0);
		opacity:1;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions{
		display:none;
		}
	.obj_popin.popin_galerie-comment .obj_form.is-expanded .ctn_txt-mentions{
		display:block;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_textarea{
		margin-top:var(--aox-10px);
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_textarea textarea{
		height:var(--aox-100px);
		}
	
	.obj_popin.popin_video-nofs{
		top:auto !important;
		bottom:5px !important;
		padding:0;
		max-width:calc(100% - var(--aox-10px));
		transform:translate(-50%,0) !important;
		}
	.obj_popin.popin_video-nofs .ctn_popin{
		padding-right:0;
		}
	.obj_popin.popin_video-nofs .t_title{
		padding:var(--aox-30px) var(--aox-50px) 0 var(--aox-50px);
		margin-bottom:var(--aox-20px);
		font-size:var(--aox-fs-16px);
		}

	.obj_popin.popin_post-fb .ctn_image .ctn_solo-img{
		height: unset;
		padding-bottom: unset;
		overflow: unset;
		}
	.obj_popin.popin_post-fb .ctn_image .ctn_solo-img img{
		position: relative;
		}	
	.obj_popin.popin_post-fb .ctn_text .ctn_top{
		padding:0;
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_top{
		padding-right: 0;
		}
	.obj_popin.popin_post-fb .obj_likes-comments{
		flex-direction:column;
		}
	.obj_popin.popin_post-fb .obj_likes-comments li + li{
		margin-top:var(--aox-15px);
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_scroll{
		height:auto;
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_post-fb{
		padding:var(--aox-20px) 0px;
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_bottom{
		position: relative;
		height: auto;
		padding: var(--aox-20px) 0 0;
		}

	.obj_popin.popin_expert .obj_circle-progress-expertise {
		width: var(--aox-220px);
		margin: 0 auto var(--aox-20px) auto;
		}

	.ReactModal__Body--open .obj_popin.popin_produits-concernes{
		padding: var(--aox-25px) var(--aox-25px) var(--aox-25px) var(--aox-30px);
		}
	.ReactModal__Body--open .obj_popin.popin_produits-concernes .ctn_popin {
		padding-right: 0;
		}
	.obj_popin.popin_produits-concernes .ctn_title {
		max-width: 90%;
		}
	.obj_popin.popin_produits-concernes .ctn_title .t_title{
		font-size: var(--aox-fs-36px);
		line-height: var(--aox-lh-100);
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row {
		flex-direction: column;
		align-items: flex-start;
		padding: var(--aox-10px) 0;
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row .ctn_row-title {
		font-weight: 500;
		width: 100%;
		margin-bottom: var(--aox-8px);
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row > .ctn_row {
		flex-direction: row;
		padding: var(--aox-3px) 0;
		width: 100%;
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row > .ctn_row + .ctn_row {
		border-top: none;
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row > .ctn_row .t_title{
		font-size: var(--aox-fs-12px);
		color: #7F7F7F;
		margin-bottom: 0;
		width: 40%;
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row > .ctn_row p:nth-child(2) {
		font-weight: 500;
		}



}
@media screen and (max-width:360px){
	.obj_popin.popin_expert .obj_star-score{
		width:100%;
		margin-top:var(--aox-20px);
		}
}



/* ======================================== */
/* .popin_overlay_badge_detail
/* ======================================== */

.ReactModal__Body--open .main_header,
.ReactModal__Body--open .main_container,
.ReactModal__Body--open .main_footer,
.ReactModal__Body--open_blur .main_header,
.ReactModal__Body--open_blur .main_container,
.ReactModal__Body--open_blur .main_footer{
	filter: blur(5px);
	}
.obj_popin.popin_overlay_badge_detail{
	width: 100vw; height: 100vh;
    max-width: 100%;
    padding: var(--aox-60px);
	background: rgba(0,0,0,0);
	}
.obj_popin.popin_overlay_badge_detail .overlay-1,
.obj_popin.popin_overlay_badge_detail .overlay-2{
	content:'';
	position:absolute;
	z-index:1;
	left:0; top:0;
	background:#9FD6E3;
	width:100%; height:0;
	opacity:0.6;
	transition:all 0.6s ease;
	}
.obj_popin.popin_overlay_badge_detail .overlay-2{
	z-index:2;
	background:#00ACC2;
	opacity:0.4;
	}
.obj_popin.popin_overlay_badge_detail.ReactModal__Content--after-open .overlay-1,
.obj_popin.popin_overlay_badge_detail.ReactModal__Content--after-open .overlay-2{
	height:100%;
	}
.obj_popin.popin_overlay_badge_detail .bt_close {
	right: var(--aox-60px);
	top: var(--aox-60px);
	width: var(--aox-80px);
	height: var(--aox-80px);
	background: transparent;
	}
.obj_popin.popin_overlay_badge_detail .bt_close::before {
	height: var(--aox-36px);
	}
.obj_popin.popin_overlay_badge_detail .bt_close::after {
	width: var(--aox-36px);
	}
.obj_popin.popin_overlay_badge_detail .ctn_popin {
	position: relative;
	z-index: 3;
	height: calc(100vh - var(--aox-120px));
	max-height: unset;
	padding-right: unset;
	overflow-y: unset;
	width: var(--aox-grid);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	text-align: center;
	}



/***/

@media screen and (max-width:767px){

	.obj_popin.popin_overlay_badge_detail .ctn_popin {
		width: 100%;
		height: 90vh;
		}
	.three-is-ready.ReactModal__Body--open .obj_popin::before{
		position:relative;
		}

}


/* popin_small */

.obj_popin.popin_small{
    width: var(--aox-500px);
	}
.obj_popin.popin_small .ctn_checkbox{
	margin-top: var(--aox-20px);
	}


/***/

@media screen and (max-width:767px){

	
}



/* popin_txt_medium */

.obj_popin.popin_txt_medium{
    width: var(--aox-740px);
	}
.obj_popin.popin_txt_medium .t_title{
	font-size: var(--aox-fs-16px);
	margin-bottom: var(--aox-10px);
	}
.obj_popin.popin_txt_medium .t_main{
	font-size: var(--aox-fs-15px);
    line-height: var(--aox-lh-160);
	margin-bottom: var(--aox-40px);
	}
.obj_popin.popin_txt_medium .t_subtitle{
	font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-170);
    font-weight: 700;
    text-transform: uppercase;
	}
.obj_popin.popin_txt_medium ul{
	padding: 0 0 0 var(--aox-20px)
	}
.obj_popin.popin_txt_medium ul li{
	position: relative;
    margin-top: var(--aox-4px);
    font-size: var(--aox-fs-15px);
    line-height: var(--aox-lh-130);
    font-weight: 300;
	list-style-type: disc;
	}
.obj_popin.popin_txt_medium ul + .t_subtitle {
	margin-top: var(--aox-16px);
	}