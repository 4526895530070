/* ======================================== */
/* .section_product-detail
/* ======================================== */
.section_product-detail{
	width:100%;
	max-width:var(--aox-970px);
	margin:var(--aox-60px) auto var(--aox-100px) auto;
	padding:0 var(--aox-30px);
	}
.section_product-detail .ctn_top-detail{
	display:grid;
	grid-template-columns:27.5% 59%;
	column-gap:13.5%;
	}
.section_product-detail .ctn_top-detail .col_image{
	grid-row-end:span 2;
	}
.section_product-detail .ctn_top-detail .col_image figure{
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	}
.section_product-detail .ctn_top-detail .col_image figure img{
	max-width:100%;
	}
.section_product-detail .ctn_top-detail .col_image figure figcaption{
	display:flex;
	align-items:center;
	position:relative;
	margin:0 var(--aox-20px);
	padding-left:var(--aox-35px);
	min-height:var(--aox-36px);
	font-size:var(--aox-fs-16px); line-height:1.125;
	}
.section_product-detail .ctn_top-detail .col_image figure figcaption svg{
	position:absolute;
	left:0; top:0;
	}
.section_product-detail .ctn_top-detail .col_image ul{
	display:flex;
	flex-flow:row wrap;
	max-width:var(--aox-250px);
	margin:var(--aox-30px) auto 0 auto;
	}
.section_product-detail .ctn_top-detail .col_image ul li{
	margin-top:var(--aox-10px);
	width:48%;
	max-width:var(--aox-120px);
	}
.section_product-detail .ctn_top-detail .col_image ul li a,
.section_product-detail .ctn_top-detail .col_image ul li button{
	width:100%;
	}
.section_product-detail .ctn_top-detail .col_image ul li .ctn_img{
	position:relative;
	}
.section_product-detail .ctn_top-detail .col_image ul li img{
	width:100%;
	}
.section_product-detail .ctn_top-detail .col_image ul li:nth-child(even){
	margin-left:auto;
	}
.section_product-detail .ctn_top-detail .col_image ul li span{
	display:block;
	padding:var(--aox-10px) var(--aox-5px) 0 var(--aox-5px);
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:700;
	text-align:left;
	transition:all .3s ease;
	}
.section_product-detail .ctn_top-detail .col_image ul li.var_video .ctn_img::before,
.section_product-detail .ctn_top-detail .col_image ul li.var_video .ctn_img::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	transition:all .3s ease;
	}
.section_product-detail .ctn_top-detail .col_image ul li.var_video .ctn_img::before{
	width:var(--aox-36px); height:var(--aox-36px);
	border:2px solid #fff;
	border-radius:50%;
	transform:translate(-50%,-50%);
	}
.section_product-detail .ctn_top-detail .col_image ul li.var_video .ctn_img::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-8px) 0 var(--aox-8px) var(--aox-14px);
	border-color:transparent transparent transparent #fff;
	transform:translate(-40%,-50%);
	}
.section_product-detail .ctn_top-detail .col_title{
	padding-top:var(--aox-40px);
	}
.section_product-detail .ctn_top-detail h1{
	font-size:var(--aox-fs-50px); line-height:1.2;
	text-transform:uppercase;
	}
.section_product-detail .ctn_top-detail h1 strong{
	font-weight:700;
	}
.section_product-detail .ctn_top-detail h2{
	margin-top:var(--aox-20px);
	font-weight:700;
	font-size:var(--aox-fs-16px); line-height:1.8;
	text-transform:uppercase;
	}
.section_product-detail .ctn_top-detail .ctn_desc-product{
	margin-top:var(--aox-50px);
	}
/**/
.section_product-detail .ctn_desc-product{
	position:relative;
	}
.section_product-detail .ctn_desc-product h3{
	font-weight:700;
	font-size:var(--aox-fs-20px); line-height:1.2;
	text-transform:uppercase;
	}
.section_product-detail .ctn_desc-product p,
.section_product-detail .ctn_desc-product ul li{
	font-size:var(--aox-fs-16px); line-height:1.6;
	word-break:break-word;
	}
.section_product-detail .ctn_desc-product p strong,
.section_product-detail .ctn_desc-product ul li strong{
	font-weight:700;
	}
.section_product-detail .ctn_desc-product p,
.section_product-detail .ctn_desc-product ul{
	margin-top:var(--aox-15px);
	}
.section_product-detail .ctn_desc-product p + ul,
.section_product-detail .ctn_desc-product ul + p,
.section_product-detail .ctn_desc-product p + p{
	margin-top:var(--aox-30px);
	}
.section_product-detail .ctn_desc-product ul li + li{
	margin-top:var(--aox-10px);
	}
.section_product-detail .ctn_desc-product ul li{
	position:relative;
	padding-left:var(--aox-25px);
	}
.section_product-detail .ctn_desc-product ul li::before{
	content:'';
	position:absolute;
	left:0; top:var(--aox-10px);
	width:3px; height:3px;
	background:#000;
	border-radius:50%;
	}
.section_product-detail .ctn_desc-product.var_composition ul{
	margin-top:var(--aox-30px);
	}
.section_product-detail .ctn_desc-product.var_composition ul li{
	padding:0;
	margin:0;
	}
.section_product-detail .ctn_desc-product.var_composition ul li::before{
	display:none;
	}
.section_product-detail .ctn_desc-product.var_plus{
	padding:var(--aox-30px);
	background:#000;
	}
.section_product-detail .ctn_desc-product.var_plus h3{
	color:#fff !important;
	}
.section_product-detail .ctn_desc-product.var_plus p{
	color:#fff;
	}
/**/
.section_product-detail .ctn_detail{
	margin-top:var(--aox-80px);
	}
.section_product-detail .ctn_detail .ctn_desc-product{
	padding:0 10%;
	}
.section_product-detail .ctn_detail .ctn_desc-product.var_grey{
	padding-top:var(--aox-40px);
	padding-bottom:var(--aox-40px);
	background:#f2f2f2;
	}
.section_product-detail .ctn_detail .ctn_desc-product.var_texture{
	padding-right:18%;
	}
.section_product-detail .ctn_detail .ctn_desc-product.var_texture > *{
	position:relative;
	z-index:2;
	}
.section_product-detail .ctn_detail .ctn_desc-product.var_texture > img{
	position:absolute;
	z-index:1;
	right:0; bottom:0;
	max-width:var(--aox-285px);
	transform:translateX(50%);
	}
/**/
@media screen and (max-width:1280px){
	.section_product-detail .ctn_detail .ctn_desc-product.var_texture > img{
		transform:translateX(0);
		}
}
@media screen and (max-width:767px){
	.section_product-detail{
		margin-top:var(--aox-20px);
		margin-bottom:var(--aox-50px);
		padding:0 var(--aox-20px);
		}
	.section_product-detail .ctn_top-detail{
		grid-template-columns:100%;
		column-gap:0;
		}
	.section_product-detail .ctn_top-detail .col_title{
		grid-row-start:1;
		padding-top:0;
		}
	.section_product-detail .ctn_top-detail h1{
		font-size:var(--aox-fs-40px);
		}
	.section_product-detail .ctn_top-detail h2{
		margin-top:var(--aox-15px);
		font-size:var(--aox-fs-14px);
		}
	.section_product-detail .ctn_top-detail .col_image figure{
		margin-top:var(--aox-20px);
		}
	.section_product-detail .ctn_top-detail .col_image figure img{
		max-height:var(--aox-315px);
		}
	.section_product-detail .ctn_top-detail .ctn_desc-product,
	.section_product-detail .ctn_detail{
		margin-top:var(--aox-40px);
		}
	.section_product-detail .ctn_detail .ctn_desc-product{
		padding:0;
		}
	.section_product-detail .ctn_detail .ctn_desc-product.var_grey{
		padding-right:var(--aox-20px);
		padding-left:var(--aox-20px);
		}
	.section_product-detail .ctn_detail .ctn_desc-product.var_texture{
		padding-right:0;
		}
	.section_product-detail .ctn_detail .ctn_desc-product.var_texture > img{
		display:none;
		}
}