/* ======================================== */
/* .obj_accordion
/* ======================================== */

.section_faq .ctn_accordion{
	position: relative;
	padding-top: var(--aox-60px);
	margin: 0 auto var(--aox-30px);
	width: var(--aox-920px);
	}
.section_faq .ctn_accordion .t_title{
	font-size: var(--aox-fs-24px);
	line-height: var(--aox-lh-100);
    font-weight: 700;
    text-transform: uppercase;
	text-align: left;
	width: 100%;
	max-width: var(--aox-730px);
	margin-bottom: var(--aox-25px);
	}
.section_faq .ctn_accordion .ctn_title,
.section_faq .ctn_accordion .ctn_title .t_title{
	margin-bottom: var(--aox-15px);
	}


.ctn_accordion .obj_accordion{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
	background-color: #ffffff;
	border-top: 1px solid #BBBBBE;
	border-right: 1px solid #BBBBBE;
	border-left: 1px solid #BBBBBE;
	}
.ctn_accordion > .obj_accordion:last-child{ border-bottom: 1px solid #BBBBBE; }

.obj_accordion .obj_accordion-header{
	position: relative;
    display: flex;
    background-color: #F8F8F9;
    width: 100%;
	cursor: pointer;
    flex-wrap: wrap;
    align-items: flex-start;
	justify-content: space-between;
    padding: var(--aox-18px) var(--aox-18px) var(--aox-18px) var(--aox-32px);
    flex-direction: row;
	}
.obj_accordion .obj_accordion-header > .t_title{
	position: relative;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    font-weight: 700;
    text-align: left;
    display: flex;
    width: calc(100% - var(--aox-57px));
    max-width: calc(var(--aox-800px) + var(--aox-5px));
    margin-bottom: 0;
    min-height: var(--aox-40px);
    flex-direction: column;
    justify-content: center;
    text-transform: unset;
	}
.obj_accordion .obj_accordion-header .obj_accordion_toggle-icon{
	position: relative;
	width: var(--aox-40px); height: var(--aox-40px);
	transition:all .3s ease;
	}
.obj_accordion .obj_accordion-header .obj_accordion_toggle-icon::before{
    content: "";
	position: absolute;
    display: inline-block;
    width: var(--aox-14px); height: var(--aox-14px);
    top: 50%; left: 50%;
    border-bottom: 2px solid; border-right: 2px solid;
    transform: translate(-50%, -70%) rotateZ(45deg);
	}

.obj_accordion .obj_accordion-content{
	height: 0;
    position: relative;
    width: 100%;
    transition: all 0.8s ease;
	}
.obj_accordion.is_expanded .obj_accordion-header{
	background-color: #FFFFFF;
	}
.obj_accordion.is_expanded .obj_accordion-header .obj_accordion_toggle-icon{
	transform: rotateZ(180deg);
	}
.obj_accordion.is_expanded .obj_accordion-content{
	display: block;
    height: auto;
	}
.obj_accordion.is_expanded .obj_accordion-content .ctn_text{
	opacity: 1;
	}

.obj_accordion .obj_accordion-content .ctn_text{
	opacity: 0;
    padding: 0 var(--aox-80px) var(--aox-18px) var(--aox-30px);
    transition: all 0.2s ease;
	}
.obj_accordion .obj_accordion-content .ctn_text p a{
	font-weight: 500;
	text-decoration: underline;
	}


	
@media screen and (max-width: 767px){

	.section_faq .ctn_accordion {
		padding-top: var(--aox-30px);
		margin: 0 auto var(--aox-20px);
		width: 100%;
		}
	.section_faq .ctn_accordion .ctn_title {
		margin-bottom: var(--aox-20px);
		}
	.section_faq .ctn_accordion .ctn_title .t_title {
		font-size: var(--aox-fs-16px);
		line-height: var(--aox-lh-120);
		max-width: unset;
		margin-bottom: var(--aox-20px);
		}
	.obj_accordion .obj_accordion-header {
		padding: var(--aox-18px) var(--aox-12px) var(--aox-18px) var(--aox-20px);
		}
	.obj_accordion .obj_accordion-header > .t_title {
		font-size: var(--aox-fs-16px);
		line-height: var(--aox-lh-140);
		width: calc(100% - var(--aox-48px));
		max-width: unset;
		/* min-height: var(--aox-40px); */
		}
	.obj_accordion .obj_accordion-content {
		display: none;
		position: relative;
		width: 100%;
		transition: all .8s ease;
		}

	.obj_accordion.is_expanded .obj_accordion-header {
		padding: var(--aox-18px) var(--aox-12px) var(--aox-15px) var(--aox-20px);
		}
	.obj_accordion.is_expanded .obj_accordion-content .ctn_text {
		padding: 0 var(--aox-30px) var(--aox-18px) var(--aox-20px);
		}
}