/* ======================================== */
/* .section_push-contact
/* ======================================== */
.section_push-contact{
	display:flex;
	flex-flow:row wrap;
	position:relative;
	margin:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	width:calc(100% - var(--aox-60px)); height:var(--aox-450px);
	border:1px solid #c7c7c7;
	}
.section_push-contact .ctn_text{
	display:flex;
	align-items:center;
	width:42%;
	padding-left:11%;
	}
.section_push-contact .ctn_text h2{
	font-size:var(--aox-fs-32px); line-height:1;
	font-weight:700;
	}
.section_push-contact .ctn_text h3{
	position:relative;
	font-size:var(--aox-fs-26px); line-height:1;
	padding-left:var(--aox-40px);
	margin:var(--aox-20px) 0 var(--aox-10px) 0;
	}
.section_push-contact .ctn_text h3 svg{
	position:absolute;
	left:0; top:0;
	}
.section_push-contact .ctn_text p{
	font-size:var(--aox-fs-13px); line-height:1.4;
	}
.section_push-contact figure{
	position:relative;
	width:58%;
	overflow:hidden;
	}
.section_push-contact figure img{
	position:absolute;
	right:0; top:0;
	height:100%;
	}
.section_push-contact .ctn_cta{
	margin-top:var(--aox-30px);
	}

@media screen and (max-width:1340px){
	.section_push-contact{
		height:33.6vw;
		}
	.section_push-contact .ctn_text,
	.section_push-contact figure{
		width:50%;
		}
	.section_push-contact .ctn_text h2{
		font-size:var(--aox-fs-32px)
		}
	.section_push-contact .ctn_text h3{
		margin:1.5vw 0 0.75vw 0;
		font-size:var(--aox-fs-28px)
		}
	.section_push-contact .ctn_cta{
		margin-top:2.2vw;
		}
}
@media screen and (max-width:767px){
	.section_push-contact{
		margin:var(--aox-40px) var(--aox-15px) 0 var(--aox-15px);
		width:calc(100% - var(--aox-15px)); height:auto;
		}
	.section_push-contact .ctn_text{
		padding:var(--aox-30px) var(--aox-20px);
		}
	.section_push-contact .ctn_text > div{
		width:100%;
		}
	.section_push-contact .ctn_text,
	.section_push-contact figure{
		width:100%;
		}
	.section_push-contact .ctn_text h2,
	.section_push-contact .ctn_text h3{
		font-size:var(--aox-fs-24px);
		}
	.section_push-contact .ctn_text h3{
		display:inline-block;
		margin:var(--aox-20px) 0 var(--aox-10px) 0;
		padding-left:var(--aox-30px);
		min-height:var(--aox-30px);
		text-align:left;
		}
	.section_push-contact .ctn_text{
		text-align:center;
		order:1;
		}
	.section_push-contact figure img{
		position:relative;
		right:auto; top:auto;
		width:100%; height:auto;
		}
	.section_push-contact figure .lazyload-placeholder{
		height:0;
		padding-bottom:60%;
		}
	.section_push-contact .ctn_cta{
		margin-top:var(--aox-30px);
		}
	.section_push-contact .ctn_text p br{
		display:none;
		}
}