/* ======================================== */
/* .obj_brand_highlighted_hero
/* ======================================== */
.obj_brand_highlighted_hero{
	width: var(--aox-650px);
    height: var(--aox-160px);
    display: block;
    background-color: rgba(255, 255, 255, .8);
	backdrop-filter: blur(10px);
	animation:effectHeroHighlightedBrands 1s ease 1600ms forwards;
	opacity: 0;
	}
.obj_brand_highlighted_hero a{
	display: block;
	width: var(--aox-650px);
    height: var(--aox-160px);
	}
.obj_brand_highlighted_hero .ctn_row{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	width: var(--aox-650px);
	height: var(--aox-160px);
	}
.obj_brand_highlighted_hero .ctn_logo{
	position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: var(--aox-240px);
    height: 100%;
    transition-delay: .05s;
    transition-duration: .2s;
    transition-property: transform,opacity;
    transition-timing-function: cubic-bezier(.645,.045,.355,1);
    z-index: 2;
	}
.obj_brand_highlighted_hero .ctn_text{
	width: var(--aox-190px);
	}

.obj_brand_highlighted_hero .ctn_logo .t_title {
	position: absolute;
    bottom: 35%;
    width: 100%;
    text-align: center;
    font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-100);
    font-weight: 700;
	letter-spacing: 1.5px;
    text-transform: uppercase;
    opacity: 0;
    transform: translateY(var(--aox-25px));
    transition-duration: .25s;
    transition-property: transform,opacity;
    transition-timing-function: cubic-bezier(.645,.045,.355,1);
    z-index: 2;
	}
.obj_brand_highlighted_hero .ctn_logo img{
	transition: all .3s ease;
	width: 100%;
	}

/***/
.obj_brand_highlighted_hero a:hover {
	background-color: rgba(255, 255, 255, 1);
	}
.obj_brand_highlighted_hero a:hover .ctn_logo img{
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
	transition-delay: 0s;
	transition-duration: .25s;
	opacity: 0;
	}
.obj_brand_highlighted_hero a:hover .t_title {
	opacity: 1;
	transform: translateY(-100%);
	transition-delay: .05s;
	transition-duration: .2s;
	}



@media screen and (max-width: 767px){

	.obj_brand_highlighted_hero {
		width: 100%;
		height: var(--aox-90px);
		animation: effectHeroHighlightedBrands 1s ease 500ms forwards;
		}
	.obj_brand_highlighted_hero a {
		width: 100%;
		height: var(--aox-90px);
		}
	.obj_brand_highlighted_hero .ctn_row{
		width: 100%;
		height: var(--aox-90px);
		}
	.obj_brand_highlighted_hero .ctn_logo,
	.obj_brand_highlighted_hero .ctn_logo img {
		width: var(--aox-200px);
		}
	.obj_brand_highlighted_hero .ctn_text{
		display: none;
		}
	.obj_brand_highlighted_hero .ctn_logo .t_title{
		bottom: 25%;
    	font-size: var(--aox-fs-13px);
		}

	}