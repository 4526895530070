/* ======================================== */
/* .obj_aside_challenge
/* ======================================== */
.obj_aside_challenge{
	position: relative;
    transition: all 1s ease;
    width: var(--aox-440px);
}
.obj_aside_challenge .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    width: var(--aox-280px);
    margin-bottom: var(--aox-20px);
    }


/***/
.obj_aside_challenge .ctn_header{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: var(--aox-40px);
    }


.obj_aside_challenge .ctn_header .obj_temps_restant{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .tag_title{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    text-transform: uppercase;
    padding: var(--aox-8px) var(--aox-15px);
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .tag_title span{
    color: #ffffff;
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_jours-restant{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    margin-top: var(--aox-10px);
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_jours-restant span{
    font-weight: 700;
    font-size: var(--aox-fs-42px);
    line-height: var(--aox-lh-90);
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_jours-restant p{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: var(--aox-fs-12px);
    margin-left: var(--aox-10px);
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_jours-restant p strong{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    }

/***/
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_progressbar-challenge{
    margin-top: var(--aox-10px);
    }



/***/
.obj_aside_challenge.declarer .obj_temps_restant .tag_title{ background-color: #7FD5E0; }
.obj_aside_challenge.declarer .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.declarer .obj_temps_restant .obj_jours-restant p strong{ color: #3aaac1; }
.obj_aside_challenge.declarer .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #3aaac1; }

.obj_aside_challenge.repartir .obj_temps_restant .tag_title{ background-color: #CA93DE; }
.obj_aside_challenge.repartir .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.repartir .obj_temps_restant .obj_jours-restant p strong{ color: #892bad; }
.obj_aside_challenge.repartir .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #892bad; }

.obj_aside_challenge.en_attente .obj_temps_restant .tag_title{ background-color: #F7B15E; }
.obj_aside_challenge.en_attente .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.en_attente .obj_temps_restant .obj_jours-restant p strong{ color: #F7B15E; }
.obj_aside_challenge.en_attente .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #F7B15E; }

.obj_aside_challenge.remporte .obj_temps_restant .tag_title{ background-color: #ACDE93; }
.obj_aside_challenge.remporte .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.remporte .obj_temps_restant .obj_jours-restant p strong{ color: #ACDE93; }
.obj_aside_challenge.remporte .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #ACDE93; }

.obj_aside_challenge.echoue .obj_temps_restant .tag_title{ background-color: #C4C4C4; } 
.obj_aside_challenge.echoue .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.echoue .obj_temps_restant .obj_jours-restant p strong{ color: #C4C4C4; }   
.obj_aside_challenge.echoue .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #C4C4C4; } 


/***/

.obj_aside_challenge .obj_saisie-vente-item{
    position: relative;
    margin-bottom: var(--aox-30px);
    }
.obj_aside_challenge .obj_saisie-vente-item .t_desc{
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-150);
    padding-right: var(--aox-col-1);
    margin-bottom: var(--aox-10px);
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input{
    position: relative;
    padding: var(--aox-20px) var(--aox-30px);
    background-color: #F2F2F2;
    margin-top: var(--aox-10px);
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input{
    position: relative;
    display: flex;
    margin: 0;
    width: var(--aox-180px);
    flex-direction: row;
    align-items: center;
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input .t_label{
    position: relative;
    margin: 0;
    font-weight: 700;
    color: #000;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    text-transform: uppercase;
    margin-right: var(--aox-10px);
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input:nth-child(2) {
    justify-content: flex-end
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input:nth-child(2) .t_label{
    text-align: right;
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input input[type="text"]{
    font-weight: 700;
    color: #000;
    width: var(--aox-90px);
    padding: var(--aox-20px);
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    }
.obj_aside_challenge .obj_saisie-vente-item + .t_title{
    margin-top: var(--aox-50px);
    }
.obj_aside_challenge .ctn_file-upload .t_desc{
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-150);
    margin-bottom: var(--aox-30px);
    }

.obj_aside_challenge .obj_saisie-vente .ctn_file-upload{
    position: relative;
    margin-top: var(--aox-30px);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload + .ctn_file-upload{
    margin-top: var(--aox-10px);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .ctn_cta {
	margin-top: 0;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .obj_cta {
	padding:0;
    margin:0;
    width:100%;
	height:var(--aox-110px);
    font-size:var(--aox-fs-13px); line-height:var(--aox-lh-100);
    font-weight:700;
    color:#000;
    text-transform:uppercase;
	background: rgba(0,0,0,0.05);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .error label{
	border:1px solid #e80000;
	}
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload.hover .obj_cta {
    background: rgba(0,0,0,0.1);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload {
    z-index: 2;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .i_add {
    display:flex;
    flex-direction:column;
    align-items:center;
    position:absolute;
    z-index:1;
    left:var(--aox-25px); top:50%;
	width:var(--aox-85px);
    text-align:center;
	transform:translateY(-50%);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .i_add span{
	margin-top:var(--aox-5px);
    font-size:var(--aox-fs-10px); line-height:var(--aox-lh-100);
    font-weight:700;
    color:#000;
    text-transform:uppercase;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .i_add svg{
	width:var(--aox-27px); height:var(--aox-20px);
    }
.obj_aside_challenge .obj_saisie-vente .obj_form .t_mention {
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    font-weight: 400;
    color: #919191;
    margin-top: var(--aox-15px);
    }
.obj_aside_challenge .obj_saisie-vente .obj_form .ctn_cta {
    margin-top: var(--aox-30px);
    }
.obj_aside_challenge .obj_saisie-vente .obj_form .ctn_cta + .t_error {
    margin-top: var(--aox-30px);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .bt_cancel{
	left: auto;
	right: var(--aox-10px);
	top: 50%;
	transform: translate(0,-50%);
	background: #000;
	}
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .canvas_thumb{
    display: none;
    }

.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .ctn_file-thumb{
	position:absolute;
	right:var(--aox-20px); top:var(--aox-20px);
	margin:0;
	width:var(--aox-290px);
	}

.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .ctn_infos {
	display: flex;
	flex-direction: column;
	justify-content: center;
    position: relative;
    padding: var(--aox-5px) var(--aox-40px);
    width: 100%; height: var(--aox-70px);
    background: url('../../images/i_file.svg') var(--aox-10px) center / var(--aox-20px) auto no-repeat #fff !important;
	border:1px dashed #A5A5A5;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .t_name {
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-140);
    font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .t_weight {
    margin-top: 0;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    color: #919191;
    }

/***/
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains{
    position: relative;
    margin-bottom: var(--aox-20px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains .ctn_graph_infos_gains{
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%,-75%);
    display: flex;
    flex-direction: column;
    align-items: center;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains .ctn_graph_infos_gains .t_desc{
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-140);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains .ctn_graph_infos_gains .t_gain{
    font-weight: 700;
    color: #000000;
    font-size: var(--aox-fs-40px);
    line-height: var(--aox-lh-100);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains .ctn_graph_infos_gains .t_total{
    font-weight: 400;
    color: #000000;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }


.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains{
    margin-top: var(--aox-10px);
    margin-bottom: var(--aox-20px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains{
    position: relative;
    display: flex;
    background-color: #F2F2F2;
    padding: var(--aox-10px);
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains + .item_participants_gains{
    margin-top: var(--aox-10px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains > * + *{
    margin-left: var(--aox-10px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_gain{
    margin-left: auto;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_picture{
    position: relative;
    width: var(--aox-50px);
    height: var(--aox-50px);
    border-radius: 100%;
    overflow: hidden;
    border: 3px solid #000;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_picture img{
    width: 100%;
    height: 100%;
    }

.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_infos{
    display: flex;
    flex-direction: column;
    width: var(--aox-160px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_infos .t_name{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_infos .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_gain input[type="text"]{
    width: var(--aox-80px);
    font-weight: 700;
    text-align: center;
    padding: var(--aox-10px) var(--aox-25px) var(--aox-10px) var(--aox-15px);
    background-image: url(../../images/v_euro.svg);
    background-size: 10px;
    background-position-x: var(--aox-60px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_gain input[type="text"].error{
    background-image: url(../../images/v_euro_red.svg);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete{
    position: relative;
    width: var(--aox-40px);
    height: var(--aox-40px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete {
    display: block;
    position: absolute;
    z-index: 9999;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    width: var(--aox-20px);
    height: var(--aox-20px);
    border-radius: 100%;
    background: #000;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete::before, 
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete::after {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: #fff;
    transition: all .3s ease;
    content: '';
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete::before {
    width: 2px;
    height: var(--aox-15px);
    border-radius: 2px;
    transform: translate(-50%,-50%) rotate(45deg);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete::after {
    width: var(--aox-15px);
    height: 2px;
    border-radius: 2px;
    transform: translate(-50%,-50%) rotate(45deg);
    }

.obj_aside_challenge .obj_repartition-gains .obj_form .t_mention {
    margin-top: var(--aox-30px);
    margin-bottom: var(--aox-15px);
    }

/***/

.obj_aside_challenge .obj_saisie-vente .ctn_add-file{
	margin-top:var(--aox-20px);
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file{
	position:relative;
	display:flex;
	align-items:center;
	width:100%;
	padding-left:var(--aox-20px);
	padding-right:var(--aox-60px);
	height:var(--aox-65px);
	font-size:var(--aox-fs-12px);
	text-transform:uppercase;
	font-weight:700;
	border:1px solid #000;
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file:hover{
	background:#F2F2F2;
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus{
	position:absolute;
	right:var(--aox-20px); top: 50%;
	width:var(--aox-16px); height:var(--aox-16px);
	transform:translateY(-50%);
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus::before,
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#000;
	transform:translate(-50%,-50%);
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus::before{
	width:var(--aox-16px); height:var(--aox-3px);
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus::after{
	width:var(--aox-3px); height:var(--aox-16px);
	}

@media screen and (max-width:767px){
    .obj_aside_challenge {
        margin-top: var(--aox-20px);
        width: 100%;
        }
    .obj_aside_challenge .t_title {
        font-size: var(--aox-fs-20px);
        width: var(--aox-col-5);
        margin-bottom: var(--aox-15px);
        }
    .obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input{
        width: auto;
        }
	.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .i_add {
		left:var(--aox-15px);
		}
	.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .ctn_file-thumb{
		width:var(--aox-250px);
		}
}