/* ======================================== */
/* .section_product-carousel
/* ======================================== */
.section_product-carousel{
	width:100%; max-width:var(--aox-1150px);
	margin:0 auto;
	}
.section_product-carousel .str_container{
	padding:var(--aox-100px) 0;
	border-top:1px solid #c8c8c8;
	}
.section_product-carousel h2{
	padding:0 var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	text-align:center;
	text-transform:uppercase;
	}
.section_product-carousel .obj_push-product{
	width:100%;
	}

@media screen and (max-width:1150px){
	.section_product-carousel{
		margin:0 var(--aox-20px);
		width:calc(100% - var(--aox-40px));
		}
}
@media screen and (max-width:767px){
	.section_product-carousel .str_container{
		padding:var(--aox-50px) 0;
		}
}