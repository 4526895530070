/* ======================================== */
/* .obj_cta-formation
/* ======================================== */
.obj_cta-formation{
	position: absolute;
    bottom: 0;
    width: 100%; height: var(--aox-53px);
    padding: var(--aox-15px) var(--aox-60px) var(--aox-15px) var(--aox-30px);
    color: #ffffff;
    font-size:var(--aox-fs-14px); line-height: 1; font-weight: 700;
    text-transform: uppercase;
    background-color: rgba(0,0,0,0.2);
    z-index: 1;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}


.ctn_page-liste:not(.is-progress, .is-done, .is-not-available) .obj_cta-formation .obj_cta-formation-picto::before{
	content: '';
    position: absolute;
    left: 50%; top: var(--aox-20px);
    border: 1px solid transparent;
    border-width: 8px 10px;
    border-left-color: #fff;
    background: transparent;
    transform: translate(-20%, 0%);
	}

.is-not-available .obj_cta-formation::before{
	display:none;
	}

.is-done .obj_cta-formation{
	background-color: rgba(0, 0, 0, 0.2);
	}

.obj_cta-formation .obj_cta-formation-picto{
	position: absolute;
	right: 0;
	width: var(--aox-53px); height: var(--aox-53px);
	}

	
.is-progress .obj_cta-formation .obj_cta-formation-picto::before{
	content: "";
    position: absolute;
    width: 4px; height: var(--aox-14px);
    border: 4px solid #fff;
    border-width: 0 4px;
    background: transparent;
    top: calc(50% - var(--aox-12px)); left: calc(50% - var(--aox-10px));
    transform: translate(50%, 50%);
	}
.section_list_formation .is-progress .obj_cta-formation .obj_cta-formation-picto::before{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }
.is-progress .obj_cta-formation .obj_cta-formation-picto::after{
	content: "";
    position: absolute;
    top: 50%;
    left: 0;
	margin: calc(-1 * var(--aox-8px)) 0 0;
    background: #fff;
	}

.is-done .obj_cta-formation .obj_cta-formation-picto::before{
	content: "";
    position: absolute;
    width: var(--aox-10px);
    height: var(--aox-10px);
    border: 3px solid #fff;
    border-radius: 50%;
    border-right-color: transparent;
	top: 50%; left: 50%;
    transform: rotate(180deg) translate(50%, 50%);
	}
.is-done .obj_cta-formation .obj_cta-formation-picto::after{
	content: "";
    position: absolute;
    border: 8px solid #fff;
    border-bottom-color: #fff;
    border-left-color: transparent;
    border-top-width: 0;
    border-right-width: 0;
    top: var(--aox-32px);
    left: var(--aox-22px);
    transform: rotate(180deg) translate(50%, 50%);
	}