/* ======================================== */
/* webfonts
/* ======================================== */

/*** CAF ***/
/*
	https://fonts.googleapis.com/css?family=Montserrat:300,500,700&display=swap
	font-family: 'Montserrat', sans-serif;
	Light: 300
	Medium: 500
	Bold: 700
*/


/*** LRP ***/
@font-face{
	font-family:'LocatorWeb-Light';
	src:url('../../webfonts/LocatorWeb/LocatorWebLight.woff2') format('woff2'), url('../../webfonts/LocatorWeb/LocatorWebLight.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'LocatorWeb-Regular';
	src:url('../../webfonts/LocatorWeb/LocatorWebRegular.woff2') format('woff2'), url('../../webfonts/LocatorWeb/LocatorWebRegular.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'LocatorWeb-Bold';
	src:url('../../webfonts/LocatorWeb/LocatorWebBold.woff2') format('woff2'), url('../../webfonts/LocatorWeb/LocatorWebBold.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/*** VICHY ***/
@font-face{
	font-family:'CalibreWeb-Thin';
	src:url('../../webfonts/CalibreWeb/CalibreWebThin.woff2') format('woff2'), url('../../webfonts/CalibreWeb/CalibreWebThin.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'CalibreWeb-Regular';
	src:url('../../webfonts/CalibreWeb/CalibreWebRegular.woff2') format('woff2'), url('../../webfonts/CalibreWeb/CalibreWebRegular.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'CalibreWeb-Bold';
	src:url('../../webfonts/CalibreWeb/CalibreWebBold.woff2') format('woff2'), url('../../webfonts/CalibreWeb/CalibreWebBold.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/*** VICHY - 11/02/2021 ***/
/* @import url("//hello.myfonts.net/count/3c8cfd"); */

@font-face{
	font-family:"FuturaPT-Light";
	src:url("../../webfonts/FuturaPT/FuturaPT-Light.woff2") format("woff2"), url("../../webfonts/FuturaPT/FuturaPT-Light.woff") format("woff");
	font-weight:100;
	font-display:swap;
	}
@font-face {
	font-family:"FuturaPT-Book";
	src:url("../../webfonts/FuturaPT/FuturaPT-Book.woff2") format("woff2"), url("../../webfonts/FuturaPT/FuturaPT-Book.woff") format("woff");
	font-weight:400;
	font-display:swap;
	}
@font-face {
	font-family:"FuturaPT-Bold";
	src:url("../../webfonts/FuturaPT/FuturaPT-Bold.woff2") format("woff2"), url("../../webfonts/FuturaPT/FuturaPT-Bold.woff") format("woff");
	font-weight:700;
	font-display:swap;
	}

/*** SANOFLORE ***/
@font-face{
	font-family:"GillSans-Light";
	src:url("../../webfonts/GillSans/GillSansLight.woff2") format("woff2"), url("../../webfonts/GillSans/GillSansLight.woff") format("woff");
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:"GillSans-Regular";
	src:url("../../webfonts/GillSans/GillSansRegular.woff2") format("woff2"), url("../../webfonts/GillSans/GillSansRegular.woff") format("woff");
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:"GillSans-Bold";
	src:url("../../webfonts/GillSans/GillSansBold.woff2") format("woff2"), url("../../webfonts/GillSans/GillSansBold.woff") format("woff");
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/*** CERAVE ***/
@font-face{
	font-family:'DinPro-Light';
	src:url('../../webfonts/DinPro/DinProLight.woff2') format('woff2'), url('../../webfonts/DinPro/DinProLight.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'DinPro-Regular';
	src:url('../../webfonts/DinPro/DinProRegular.woff2') format('woff2'), url('../../webfonts/DinPro/DinProRegular.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'DinPro-Bold';
	src:url('../../webfonts/DinPro/DinProBold.woff2') format('woff2'), url('../../webfonts/DinPro/DinProBold.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/*** SKINC ***/
@font-face{
	font-family:'FuturaBT-Light';
	src:url('../../webfonts/FuturaBT/FuturaBTLight.woff2') format('woff2'), url('../../webfonts/FuturaBT/FuturaBTLight.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'FuturaBT-Book';
	src:url('../../webfonts/FuturaBT/FuturaBTBook.woff2') format('woff2'), url('../../webfonts/FuturaBT/FuturaBTBook.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'FuturaBT-Medium';
	src:url('../../webfonts/FuturaBT/FuturaBTMedium.woff2') format('woff2'), url('../../webfonts/FuturaBT/FuturaBTMedium.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}


/*** DERM EXPERTISE ***/
@font-face{
	font-family:'Montserrat-Light';
	src:url('../../webfonts/Montserrat/Montserrat-Light.woff2') format('woff2'), url('../../webfonts/Montserrat/Montserrat-Light.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'Montserrat-Regular';
	src:url('../../webfonts/Montserrat/Montserrat-Regular.woff2') format('woff2'), url('../../webfonts/Montserrat/Montserrat-Regular.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'Montserrat-Bold';
	src:url('../../webfonts/Montserrat/Montserrat-Bold.woff2') format('woff2'), url('../../webfonts/Montserrat/Montserrat-Bold.woff') format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
