/* ======================================== */
/* .pg_concours
/* ======================================== */
.pg_concours{
	overflow-x:hidden;
	}
.pg_concours .main_container{
	position:relative;
	padding-bottom:var(--aox-150px);
	}
.pg_concours .obj_title{
	padding-bottom:0;
	}
.pg_concours .obj_back-page{
	max-width:unset;
	margin-left:var(--aox-30px);
	}
@media screen and (max-width:767px){
	.pg_concours .main_container{
		padding-bottom:var(--aox-60px);
		}
}
@media screen and (max-width:1280px){
	.pg_concours .obj_back-page{
		margin-left:var(--aox-20px);
		padding:0;
		}
}
/**/
.pg_concours_home .obj_breadcrumb{
	position:relative;
	width:100%;
	padding:var(--aox-20px) var(--aox-30px) 0 var(--aox-30px);
	}
.pg_concours_home.var_privilege .obj_breadcrumb li{
	position:relative;
	z-index:2;
	color:#fff;
	}
.pg_concours_home.var_privilege .obj_breadcrumb li a::before{
	border-color:#fff;
	}
.pg_concours_home.var_privilege .obj_title-big{
	position:relative;
	color:#fff;
	}
.pg_concours_home .obj_title-big span{
	position:relative;
	z-index:2;
	}
.pg_concours_home .obj_push-article{
	position:relative;
	margin-top:0;
	padding-bottom:var(--aox-60px);
	}
.pg_concours_home .obj_push-article .ctn_push-article{
	position:relative;
	z-index:2;
	align-items:center;
	border:none;
	background:#fff;
	}
.pg_concours_home .obj_push-article .ctn_text{
	padding-bottom:var(--aox-60px);
	}
.pg_concours_home .obj_push-article .ctn_text h3{
	margin-top:var(--aox-60px);
	}
.pg_concours_home .obj_push-article .ctn_text .ctn_cta{
	margin-top:var(--aox-20px);
	}
.pg_concours_home.var_privilege .obj_breadcrumb::before,
.pg_concours_home.var_privilege .obj_title-big::before,
.pg_concours_home.var_privilege .obj_push-article::before{
	content:'';
	position:absolute;
	z-index:1;
	left:50%; top:0;
	width:100vw; height:100%;
	transform:translateX(-50%);
	}
.pg_concours_home .section_page-liste{
	margin-top:var(--aox-60px);
	}
.pg_concours_home .section_page-liste .str_container{
	max-width:var(--aox-1280px);
	}
.pg_concours_home .obj_tabs-list .ctn_nav-tabs{
	max-width:var(--aox-1150px);
	margin-left:auto;
	margin-right:auto;
	}
.pg_concours_home .section_page-liste .ctn_row{
	margin-bottom:0;
	}
.pg_concours_home .section_page-liste .ctn_sub-row{
	padding:0;
	}
.pg_concours_home .obj_push-custom.var_100{
	margin:0;
	width:100%;
	}
.pg_concours_home .obj_push-custom + .obj_push-custom,
.pg_concours_home .section_page-liste .obj_push-custom + .obj_push-article,
.pg_concours_home .section_page-liste .obj_push-article + .obj_push-custom,
.pg_concours_home .section_page-liste .obj_push-article + .obj_push-article{
	margin-top:var(--aox-120px);
	}
.pg_concours_home .obj_push-custom + .obj_push-custom::before,
.pg_concours_home .section_page-liste .obj_push-custom + .obj_push-article::after,
.pg_concours_home .section_page-liste .obj_push-article + .obj_push-custom::after,
.pg_concours_home .section_page-liste .obj_push-article + .obj_push-article::after{
	content:'';
	position:absolute;
	left:0; top:calc(-1 * var(--aox-60px));
	width:100%; height:1px;
	background:#c3c3c3;
	}
.pg_concours_home .obj_push-custom.var_full .ctn_text h2,
.pg_concours_home .obj_push-custom.var_full .ctn_text h2 p{
	color:#fff !important;
	text-transform:uppercase;
	}
.pg_concours_home .section_page-liste .obj_push-article{
	padding-bottom:0;
	}
.pg_concours_home .section_page-liste .obj_push-article::before{
	display:none;
	}
.pg_concours_home .section_page-liste .obj_push-article .ctn_text h3{
	margin-top:0;
	}
.pg_concours_home .section_page-liste .obj_push-article .ctn_text{
	padding-bottom:0;
	}

@media screen and (min-width:768px){
	.pg_concours_home .obj_title-big{
		padding-left:12vw;
		padding-right:12vw;
		}
	.pg_concours_home .section_page-liste .obj_push-article .ctn_img{
		width:32%;
		max-width:var(--aox-400px);
		}
	.pg_concours_home .section_page-liste .obj_push-article .ctn_text{
		width:68%;
		}
}
@media screen and (min-width:768px) and (max-width:1340px){
	.pg_concours_home .obj_push-custom.var_100{
		height:35.3vw;
		}
}
@media screen and (max-width:1280px){
	.pg_concours_home .obj_breadcrumb::before,
	.pg_concours_home .obj_title-big::before,
	.pg_concours_home .obj_push-article::before{
		left:0;
		width:100%;
		transform:none;
		}	
}
@media screen and (max-width:1024px){
	.pg_concours_home .obj_push-article{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
	.pg_concours_home .section_page-liste .obj_push-article{
		padding-left:0;
		padding-right:0;
		}
}
@media screen and (max-width:768px){
	.pg_concours_home .obj_push-article{
		margin-left:0;
		margin-right:0;
		}
	.pg_concours_home .obj_push-custom.var_full .ctn_text,
	.pg_concours_home .obj_push-custom.var_full.var_100 .ctn_text{
		max-width:50%;
		}
}
@media screen and (max-width:767px){
	.pg_concours_home .section_page-liste .ctn_row{
		margin-left:var(--aox-20px);
		margin-right:var(--aox-20px);
		}
	.pg_concours_home .obj_tabs-list .ctn_nav-tabs{
		margin-bottom:var(--aox-40px);
		}
	.pg_concours_home .obj_push-custom + .obj_push-custom,
	.pg_concours_home .section_page-liste .obj_push-custom + .obj_push-article,
	.pg_concours_home .section_page-liste .obj_push-article + .obj_push-custom,
	.pg_concours_home .section_page-liste .obj_push-article + .obj_push-article{
		margin-top:var(--aox-80px);
		}
	.pg_concours_home .obj_push-custom + .obj_push-custom::before,
	.pg_concours_home .section_page-liste .obj_push-custom + .obj_push-article::after,
	.pg_concours_home .section_page-liste .obj_push-article + .obj_push-custom::after,
	.pg_concours_home .section_page-liste .obj_push-article + .obj_push-article::after{
		top:calc(-1 * var(--aox-40px));
		}
	.pg_concours_home .section_page-liste .ctn_sub-row > .ctn_cta{
		margin-top:var(--aox-40px);
		padding-top:var(--aox-40px);
		}
	.pg_concours_home .obj_push-custom.var_full .ctn_text,
	.pg_concours_home .obj_push-custom.var_full.var_100 .ctn_text{
		max-width:75%;
		}
	.pg_concours_home .obj_push-article .ctn_text h3,
	.pg_concours_home .section_page-liste .obj_push-article .ctn_text h3{
		margin-top:var(--aox-30px);
		}
}
/**/
.pg_concours_conseil .main_container{
	padding-bottom:0;
	}
.pg_concours_conseil .obj_push-article{
	margin-top:0;
	}
.pg_concours_conseil .obj_push-article .ctn_push-article{
	align-items:center;
	}
.pg_concours_conseil .obj_push-article .ctn_text{
	padding-top:var(--aox-30px);
	padding-bottom:var(--aox-30px);
	}
.pg_concours_conseil .obj_push-article h2{
	margin-top:0;
	}
.pg_concours_conseil .section_article .str_container{
	padding-top:var(--aox-60px);
	}
/**/
.pg_concours_resultat .obj_push-custom{
	margin-top:0;
	}
.pg_concours_resultat .obj_push-custom.var_full .ctn_text{
	top:50%;
	transform:translateY(-50%);
	}
.pg_concours_resultat .obj_push-custom.var_full .ctn_text h2,
.pg_concours_resultat .obj_push-custom.var_full .ctn_text h2 p{
	margin-top:0;
	font-size:var(--aox-fs-44px);
	color:#fff !important;
	text-transform:uppercase;
	}
.pg_concours_resultat .obj_push-custom.var_full .ctn_text h3,
.pg_concours_resultat .obj_push-custom.var_full .ctn_text h3 p{
	font-size:var(--aox-fs-30px);
	}
@media screen and (max-width:767px){
	.pg_concours_resultat .obj_push-custom.var_full .ctn_text h2,
	.pg_concours_resultat .obj_push-custom.var_full .ctn_text h2 p{
		font-size:var(--aox-fs-34px);
		}
	.pg_concours_resultat .obj_push-custom.var_full .ctn_text h3,
	.pg_concours_resultat .obj_push-custom.var_full .ctn_text h3 p{
		font-size:var(--aox-fs-22px);
		}
}
/**/
.pg_concours_galerie .obj_push-custom{
	margin-top:0;
	}
.pg_concours_galerie .obj_push-custom.var_full .ctn_text{
	top:50%;
	transform:translate(-50%,-50%);
	}
.pg_concours_galerie .obj_push-custom.var_full .ctn_text h2,
.pg_concours_galerie .obj_push-custom.var_full .ctn_text h2 p{
	margin:0;
	font-size:var(--aox-fs-44px);
	color:#fff !important;
	text-transform:uppercase;
	}
@media screen and (max-width:767px){
	.pg_concours_galerie .obj_push-custom.var_full .ctn_text{
		max-width:100%;
		width:100%;
		padding:0 var(--aox-20px);
		}
	.pg_concours_galerie .obj_push-custom.var_full .ctn_text h2,
	.pg_concours_galerie .obj_push-custom.var_full .ctn_text h2 p{
		font-size:7vw;
		}
}