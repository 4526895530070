/* ======================================== */
/* .section_wide-carousel
/* ======================================== */
.section_wide-carousel{
	width:100%;
	margin:0 auto;
	}
.section_wide-carousel .obj_carousel{
	height:var(--aox-540px);
	margin:0 auto;
	}
.section_wide-carousel .obj_carousel .ctn_banner picture img{
	position:absolute;
	z-index:1;
	left:50%; top:50%;
	width:100%;
	transform:translate(-50%,-50%);
	}
.section_wide-carousel .obj_carousel .ctn_banner .obj_cta{
	position:absolute;
	z-index:2;
	bottom:var(--aox-70px);
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text{
	position:absolute;
	z-index:3;
	top:var(--aox-70px);
	max-width:var(--aox-580px);
	padding-right:var(--aox-20px);
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:0.9;
	text-transform:uppercase;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2 strong{
	font-weight:700;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h3{
	margin-bottom:var(--aox-30px);
	font-size:var(--aox-fs-34px); line-height:1;
	text-transform:uppercase;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h4{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text p{
	font-size:var(--aox-fs-16px); line-height:1.8;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text p strong{
	font-weight:700;
	}
.section_wide-carousel .obj_carousel .ctn_banner.var_left .obj_cta,
.section_wide-carousel .obj_carousel .ctn_banner.var_left .ctn_text{
	left:var(--aox-160px);
	}
.section_wide-carousel .obj_carousel .ctn_banner.var_right .obj_cta,
.section_wide-carousel .obj_carousel .ctn_banner.var_right .ctn_text{
	left:50%;
	}
.section_wide-carousel .obj_carousel .ctn_banner.var_center .obj_cta,
.section_wide-carousel .obj_carousel .ctn_banner.var_center .ctn_text{
	left:50%;
	transform:translateX(-50%);
	}
.section_wide-carousel .obj_carousel .ctn_banner.var_center .ctn_text{
	padding:0;
	text-align:center;
	}

.section_wide-carousel .obj_carousel .ctn_banner.var_dark .ctn_text h3,
.section_wide-carousel .obj_carousel .ctn_banner.var_dark .ctn_text h4,
.section_wide-carousel .obj_carousel .ctn_banner.var_dark .ctn_text p{
	color:#fff;
	}

@media screen and (max-width:1280px){
	.section_wide-carousel .obj_carousel{
		height:0;
		padding-bottom:42.2%;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .obj_cta{
		bottom:5.4vw;
		padding:1.8vw 3.8vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text{
		top:5.4vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2{
		margin-bottom:1.5vw;
		font-size:var(--aox-fs-60px)
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h3{
		margin-bottom:2.3vw;
		font-size:var(--aox-fs-36px)
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h4{
		margin-bottom:1.5vw;
		font-size:var(--aox-fs-15px)
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text p{
		font-size:var(--aox-fs-16px)
		}
	.section_wide-carousel .obj_carousel .ctn_banner.var_left .obj_cta,
	.section_wide-carousel .obj_carousel .ctn_banner.var_left .ctn_text{
		left:12.5vw;
		}
}
@media screen and (max-width:767px){
	.section_wide-carousel .obj_carousel{
		padding-bottom:93%;
		}
	.section_wide-carousel .obj_carousel .ctn_banner.var_left .obj_cta,
	.section_wide-carousel .obj_carousel .ctn_banner.var_left .ctn_text{
		left:var(--aox-50px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner.var_center .ctn_text{
		width:75%;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text{
		width:50%;
		top:var(--aox-40px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .obj_cta{
		bottom:var(--aox-40px);
		padding:var(--aox-20px) var(--aox-30px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2{
		margin-bottom:var(--aox-20px);
		font-size:var(--aox-fs-34px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h3{
		font-size:var(--aox-fs-18px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h4{
		margin-bottom:var(--aox-20px);
		font-size:var(--aox-fs-15px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text p{
		display:none;
		}
}
@media screen and (max-width:414px){
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text{
		top:9.6vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .obj_cta{
		bottom:9.6vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2{
		margin-bottom:4.8vw;
		font-size:8.4vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h3{
		font-size:4.3vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h4{
		margin-bottom:4.8vw;
		font-size:3.6vw;
		}
}