/* ======================================== */
/* .obj_overlay_tuto_home
/* ======================================== */

.overlay_tuto_is-opened{
    overflow: hidden;
    }

    
    
    
.obj_overlay_tuto_home{
    position: absolute;
    top: 0; left: 0;
    width: 100vw; 
    height: 0;
    z-index: 10000; 
    opacity: 0;
    /* transition: all .8s ease-in-out; */
    }
.overlay_tuto_is-opened .obj_overlay_tuto_home{
    animation: FadeIn 1s both;
    height: 100%;
    }
@keyframes FadeIn{
    0% { opacity: 0; }
    100% { opacity: 1; }
    }
.obj_overlay_tuto_home .ctn_overlay_rect{
    position: relative;
    width: 100vw; height: 100%;
    transition: all .75s ease-in-out;
    }
.obj_overlay_tuto_home .ctn_overlay_rect > *{
    position: absolute;
    background-color: rgba(0,0,0,0.7);
    transition: all .8s ease-in-out;
    }


/* step_0 */
.overlay_tuto_is-opened .obj_overlay_tuto_home.step_0 .ctn_overlay_rect{
    position: absolute;
    background-color: rgba(0,0,0,0.7);
    }


/* step_1 */

.obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0; 
    left: 0;
    width: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) +
        var(--aox-650px) +
        var(--aox-10px) -
        var(--aox-10px)
    ); 
    height: 100%;
    }
.obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_2{
    top: calc(var(--aox-105px)); 
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) +
        var(--aox-650px) +
        var(--aox-10px) -
        var(--aox-10px)
    );
    width: calc(
        var(--aox-480px) +
        var(--aox-20px)
    ); 
    height: calc(
        var(--aox-120px) -
        var(--aox-10px)
    );
    }
.obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc( 
        var(--aox-external-gutter) + 
        var(--aox-1140px) +        
        var(--aox-20px) +
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    width: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    height: 100%;
    }
.obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc(
        var(--aox-150px) +
        var(--aox-420px) +
        var(--aox-4px) +
        var(--aox-10px) +
        var(--aox-15px)
    );
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) +
        var(--aox-650px) +
        var(--aox-10px) -
        var(--aox-10px)
    );
    width: calc(
        var(--aox-480px) +
        var(--aox-20px)
    ); 
    height: calc(
        100% -
        (
            var(--aox-420px) +
            var(--aox-4px) +
            var(--aox-160px)
        )
    );
    }


/* step_2 */

.obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0; 
    left: 0;
    width: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) -
        var(--aox-10px)
    ); 
    height: 100%;
    }
.obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_2{
    top: var(--aox-40px); 
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) -
        var(--aox-10px)
    );
    width: calc(
        var(--aox-650px) +
        var(--aox-20px)
    ); 
    height: calc(
        var(--aox-240px) +
        var(--aox-4px) +
        var(--aox-150px) -
        var(--aox-10px)
    );
    }
.obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) +
        var(--aox-650px) +
        var(--aox-20px) -
        var(--aox-10px)
    );
    width: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) +
        var(--aox-480px) + 
        var(--aox-10px) 
    );
    height: 100%;
    }
.obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc( 
        var(--aox-150px) + 
        var(--aox-420px) + 
        var(--aox-4px) + 
        var(--aox-10px) + var(--aox-10px)
    );
    left: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    width: calc( 
        var(--aox-650px) + 
        var(--aox-20px) 
    );
    height: calc( 
        100% - 
        (
            var(--aox-420px) + 
            var(--aox-4px) + 
            var(--aox-160px) 
        )
    );
    }


/* step_3 */

.obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0; 
    left: 0;
    width: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) -
        var(--aox-10px)
    ); 
    height: 100%;
    }
.obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_2{
    top: var(--aox-20px); 
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) -
        var(--aox-10px)
    );
    width: calc(        
        var(--aox-1140px) +        
        var(--aox-20px)    
    ); 
    height: calc(         
        var(--aox-150px) +         
        var(--aox-420px) +         
        var(--aox-4px)     
    );
    }
.obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc( 
        var(--aox-external-gutter) + 
        var(--aox-1140px) +        
        var(--aox-20px) +
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    width: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    height: 100%;
    }
.obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc(
        var(--aox-150px) +
        var(--aox-280px) +
        var(--aox-420px) +
        var(--aox-4px) +
        var(--aox-10px)
    );
    left: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    width: calc(        
        var(--aox-1140px) +        
        var(--aox-20px)    
    ); 
    height: calc( 
        100% - 
        (
            var(--aox-420px) + 
            var(--aox-280px) +
            var(--aox-4px) + 
            var(--aox-170px)
        ) 
    );
    }


/* step_4 */

.obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0; 
    left: 0;
    width: calc(
        var(--aox-external-gutter) +
        var(--aox-col-9) -
        var(--aox-10px)
    ); 
    height: 100%;
    }
.obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_2{
    top: 0;
    height: 0;
    }
.obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc( var(--aox-external-gutter) + var(--aox-1140px) + var(--aox-col-1) + var(--aox-col-1) + var(--aox-10px) );
    width: calc( 
        var(--aox-external-gutter) - 
        var(--aox-10px) 
    );
    height: 100%;
    }
.obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc( var(--aox-50px) + var(--aox-10px) );
    left: calc( var(--aox-external-gutter) + var(--aox-col-9) - var(--aox-10px) );
    width: calc( var(--aox-330px) + var(--aox-20px) );
    height: calc( 100% - ( var(--aox-50px) + var(--aox-10px) ) );
    }


/* step_5 */

.obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0;
    left: 0;
    width: calc( var(--aox-external-gutter) + var(--aox-col-6) - var(--aox-5px) );
    height: 100%;
    }
.obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_2{
    top: var(--aox-15px);
    left: calc( var(--aox-external-gutter) + var(--aox-col-6) - var(--aox-5px) );
    width: calc( var(--aox-105px) );
    height: calc( var(--aox-1350px) + var(--aox-5px) );
    }
.obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc( var(--aox-external-gutter) + var(--aox-col-6) + var(--aox-95px) + var(--aox-5px) );
    width: calc( var(--aox-external-gutter) + var(--aox-col-6) - var(--aox-60px) );
    height: 100%;
    }
.obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc( var(--aox-1000px) + var(--aox-310px) + var(--aox-105px));
    left: calc( var(--aox-external-gutter) + var(--aox-col-6) - var(--aox-5px) );
    width: calc( var(--aox-105px) );
    height: calc( 100% - ( var(--aox-1500px) ) );
    }


/***/

.obj_overlay_tuto_home .ctn_popin_tuto_home {
    position: fixed;
    z-index: 9999;
    width: auto; height: 0;
    top: calc(100vh - var(--aox-250px)); left: 50%;
    /* transform: translateX(-50%); */
    /* transform: translate(-50%, 50px); */
    opacity: 0;
    /* transition: all .3s ease-in-out; */
}
.overlay_tuto_is-opened .obj_overlay_tuto_home .ctn_popin_tuto_home{
    animation: didac_popin_effect 1.5s both;
    height: auto;
    }
@keyframes didac_popin_effect{
    0% { opacity:0; transform: translate(-50%, 50px); }
    100% { opacity:1; transform: translate(-50%, 0px); }
    }

/* .obj_overlay_tuto_home.step_1 .ctn_popin_tuto_home,
.obj_overlay_tuto_home.step_2 .ctn_popin_tuto_home,
.obj_overlay_tuto_home.step_3 .ctn_popin_tuto_home,
.obj_overlay_tuto_home.step_4 .ctn_popin_tuto_home {
    top: 0%;
    } */
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home{
    position: relative;
    z-index: 2;
    padding: var(--aox-40px) var(--aox-35px) var(--aox-50px);
    width: var(--aox-920px); height: var(--aox-250px);
    border-radius: var(--aox-20px) var(--aox-20px) 0 0;
    background: #fff;
    filter: drop-shadow(0 30px 60px rgba(0, 0, 0, .16));
    transition: all .3s ease-in-out;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family:'Montserrat', sans-serif;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home > .ctn_text{
    display: none;
    transition:all .6s ease-in-out;
    animation: textEffectSmooth 2s cubic-bezier(0.33,1,0.68,1) 0s both;
    }

/***/
.obj_overlay_tuto_home.step_0 .obj_popin_tuto_home .ctn_text.step_0,
.obj_overlay_tuto_home.step_1 .obj_popin_tuto_home .ctn_text.step_1,
.obj_overlay_tuto_home.step_2 .obj_popin_tuto_home .ctn_text.step_2,
.obj_overlay_tuto_home.step_3 .obj_popin_tuto_home .ctn_text.step_3,
.obj_overlay_tuto_home.step_4 .obj_popin_tuto_home .ctn_text.step_4,
.obj_overlay_tuto_home.step_5 .obj_popin_tuto_home .ctn_text.step_5{
    display: block;
    }





.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_step{
    font-size: var(--aox-fs-14px);
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_title{
    font-size: var(--aox-fs-22px);
    line-height: var(--aox-lh-130);
    text-align: center;
    font-weight: 400;
    margin: 0 auto;
    max-width: var(--aox-660px);
    letter-spacing: 0;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_title strong,
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_desc strong{
    font-weight: 700;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_desc{
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-140);
    text-align: center;
    font-weight: 400;
    margin-top: var(--aox-10px);
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta {
    display: flex;
    margin-top: var(--aox-30px);
    justify-content: center;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta .obj_btn + .obj_btn{
    margin-left: var(--aox-10px);
    }

.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close{
    position:absolute;
    z-index:3;
    right:var(--aox-20px); top:var(--aox-20px);
    width:var(--aox-40px); height:var(--aox-40px);
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close::before,
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close::after{
    position:absolute;
    left:50%; top:50%;
    background-color:#000;
    transition:all .3s ease;
    content:'';
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close:hover{
    background:#000;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close:hover::before,
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close:hover::after{
    background:#fff;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close::before{
    width:2px; height:var(--aox-20px);
    transform:translate(-50%,-50%) rotate(45deg);
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close::after{
    width:var(--aox-20px); height:2px;
    transform:translate(-50%,-50%) rotate(45deg);
    }


/***/

@media screen and (max-width: 767px){

    .obj_overlay_tuto_home .ctn_popin_tuto_home {
        width: 90vw; height: 100%;
        margin: 0 auto;
        right: 0;
        top: calc(100vh - var(--aox-300px));
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home{
        width: 100%;
        padding: var(--aox-40px) var(--aox-20px) var(--aox-80px);
        height: var(--aox-300px);
        }
    .obj_overlay_tuto_home.step_1 .ctn_popin_tuto_home .wrapper_popin_tuto_home,
    .obj_overlay_tuto_home.step_2 .ctn_popin_tuto_home .wrapper_popin_tuto_home,
    .obj_overlay_tuto_home.step_3 .ctn_popin_tuto_home .wrapper_popin_tuto_home,
    .obj_overlay_tuto_home.step_4 .ctn_popin_tuto_home .wrapper_popin_tuto_home,
    .obj_overlay_tuto_home.step_5 .ctn_popin_tuto_home .wrapper_popin_tuto_home{
        height: var(--aox-250px);
        }

    .obj_overlay_tuto_home.step_1 .ctn_popin_tuto_home, 
    .obj_overlay_tuto_home.step_2 .ctn_popin_tuto_home, 
    .obj_overlay_tuto_home.step_3 .ctn_popin_tuto_home, 
    .obj_overlay_tuto_home.step_4 .ctn_popin_tuto_home,
    .obj_overlay_tuto_home.step_5 .ctn_popin_tuto_home{
        top: calc(100vh - var(--aox-250px));
        }

    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close {
        right: var(--aox-10px);
        top: var(--aox-10px);
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_title {
        font-size: var(--aox-fs-18px);
        line-height: var(--aox-lh-120);
        margin-top: var(--aox-10px);
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_desc {
        font-size: var(--aox-fs-14px);
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta{
        flex-wrap: wrap;
        flex-direction: row;
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta .obj_btn + .obj_btn {
        margin-left: var(--aox-10px); margin-top: 0;
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .step_0 .ctn_cta .obj_btn:nth-child(2){
        margin-left: 0;
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta .obj_btn{
        justify-content: center;
        font-size: var(--aox-fs-10px);
        line-height: var(--aox-lh-120);
        padding: var(--aox-20px) var(--aox-15px);
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .step_0 .ctn_cta > button:first-child{
        display: none;
        }

    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home > .ctn_text{
        text-align: center;
        }


    /* step_1 */
    .obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0; left: 0;
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_2{
        top: var(--aox-100px);
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( var(--aox-240px) - var(--aox-7px) );
        }
    .obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-5px) );
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-490px) + var(--aox-15px) + var(--aox-80px));
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( 100% - ( var(--aox-420px) + var(--aox-4px) + var(--aox-160px) ) );
        }

    /* step_2 */
    .obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0; left: 0;
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_2{
        top: var(--aox-90px);
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( var(--aox-170px) - var(--aox-18px) );
        }
    .obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-5px) );
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-250px) + var(--aox-5px) + var(--aox-85px));
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( 100% - ( var(--aox-420px) + var(--aox-4px) + var(--aox-160px) ) );
        }

    /* step_3 */
    .obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0;
        left: 0;
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_2{
        top: var(--aox-80px);
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( var(--aox-520px) - var(--aox-8px) );
        }
    .obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-5px) );
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-670px) + var(--aox-5px) + var(--aox-80px));
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( 100% - ( var(--aox-420px) + var(--aox-4px) + var(--aox-160px) ) );
        }

    /* step_4 */
    .obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0;
        left: 0;
        width: calc( var(--aox-external-gutter) + var(--aox-grid) - var(--aox-40px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_2{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) - var(--aox-40px) );
        width: var(--aox-50px);
        height: var(--aox-10px);
        }
    .obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-10px) );
        width: var(--aox-10px);
        height: 100%;
        }
    .obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-70px) - var(--aox-10px) );
        left: calc( var(--aox-external-gutter) + var(--aox-grid) - var(--aox-40px) );
        width: var(--aox-50px);
        height: 100%;
        }

    /* step_5 */
    .obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0;
        left: 0;
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_2{
        top: var(--aox-70px);
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( var(--aox-1300px) );
        }
    .obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-5px) );
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-1330px) + var(--aox-5px) + var(--aox-70px));
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( 100% - ( var(--aox-420px) + var(--aox-4px) + var(--aox-160px) ) );
        }
    

}