/* ======================================== */
/* .obj_formation_detail
/* ======================================== */
.obj_formation_detail{
    /* position: relative; */
    transition: all 1s ease;
    width: var(--aox-col-9);
    }

.obj_formation_detail.var_lrp .ctn_text .t_tag{ background-color: #009EE2; color: #fff; }
.obj_formation_detail.var_cerave .ctn_text .t_tag{ background-color: #2570B8; color: #fff; }
.obj_formation_detail.var_vichy .ctn_text .t_tag{ background-color: #C8102E; color: #fff; }
.obj_formation_detail.var_skinc .ctn_text .t_tag{ background-color: #000000; color: #fff; }


.obj_formation_detail > .ctn_row{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    border: unset;
    padding: unset;
    }
        
.obj_formation_detail .ctn_image{
    width: var(--aox-col-3);
    }
.obj_formation_detail .ctn_image .ctn_visuel{
    width: var(--aox-col-3);
    height: var(--aox-290px);
    overflow: hidden;
    }
.obj_formation_detail .ctn_image .ctn_visuel img{
    width: 100%;
    }
.obj_formation_detail .ctn_image .ctn_logo img{
    width:100%;
    }

.obj_formation_detail .ctn_text{
    display: flex;
    width: var(--aox-col-6);
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    }

.obj_formation_detail .obj_jours-restant {
    font-weight: 300;
    font-size: var(--aox-fs-30px);
    line-height: var(--aox-lh-120);
    position: relative;
    margin: var(--aox-10px) 0 0;
    }

.obj_formation_detail .ctn_text .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-100);
    margin-bottom: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .t_tag{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-90);
    text-transform: uppercase;
    padding: var(--aox-10px) var(--aox-10px) var(--aox-9px) var(--aox-10px);
    }
.obj_formation_detail .ctn_text .t_date{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_formation_detail .ctn_text .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-140);
    margin-bottom: var(--aox-30px);
    }

/***/

.obj_formation_detail .ctn_text .list_infos{
    position: relative;
    margin: var(--aox-10px) 0 var(--aox-20px);
    }
.obj_formation_detail .ctn_text .list_infos li{
    position: relative;
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-24px);
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: var(--aox-30px);
    }
.obj_formation_detail .ctn_text .list_infos li:before {
    content: "";
    position: absolute;
    left: 0; top: 0;
    height: var(--aox-24px);
    width: var(--aox-24px);
    }
.obj_formation_detail .ctn_text .list_infos li:first-child:before {
    background: url(../../images/i_calendar.svg) center center no-repeat;
    }
.obj_formation_detail.webinaire .ctn_text .list_infos li:last-child:before {
    background: url(../../images/i_clock.svg) center center no-repeat;
    }
.obj_formation_detail .ctn_text .list_infos li:last-child:before {
    background: url(../../images/i_pin.svg) center center no-repeat;
    }


/***/
.obj_formation_detail .ctn_text .obj_formation_detail_form{
    width: var(--aox-col-4);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .t_label{
    display: block;
    font-weight: 400;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    margin-bottom: var(--aox-10px);
    text-transform: uppercase;
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select{
    margin-bottom: var(--aox-30px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select .react-select__placeholder,
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select .react-select__value-container{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-160);
    color: #000;
    text-transform: uppercase;
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select .react-select__value-container > .react-select__single-value{
    color: #000;
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select .error .react-select__placeholder {
    color: #ff4847;
    }

.obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_phone{
	margin-bottom: var(--aox-30px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_phone .t_desc{
	margin-bottom: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_phone .ctn_row{
	flex-wrap: nowrap;
	align-items: center;
	gap: var(--aox-20px);
	margin-bottom: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_phone .ctn_row > div{
	margin: 0;
    }

.obj_formation_detail .ctn_text .obj_formation_detail_readonly{
	margin-bottom: var(--aox-30px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_readonly .t_desc{
	margin-bottom: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_readonly .obj_form{
	width: var(--aox-250px);
    }

/***/

.obj_formation_detail.is_registered .ctn_text .list_infos li{
    font-weight: 700;
    font-size: var(--aox-fs-18px);
    line-height: var(--aox-32px);
    padding-left: var(--aox-35px);
    }
.obj_formation_detail.is_registered .ctn_text .list_infos li:before {
    height: var(--aox-32px);
    width: var(--aox-32px);
    }
.obj_formation_detail.is_registered .ctn_text .list_infos li:first-child:before {
    background: url(../../images/i_calendar.svg) center center / var(--aox-32px) auto no-repeat;
    }
.obj_formation_detail.is_registered.webinaire .ctn_text .list_infos li:last-child:before {
    background: url(../../images/i_clock.svg) center center / var(--aox-32px) auto no-repeat;
    }
.obj_formation_detail.is_registered .ctn_text .list_infos li:last-child:before {
    background: url(../../images/i_pin.svg) center center / var(--aox-32px) auto no-repeat;
    }

.obj_formation_detail.is_registered .ctn_text .t_inscription {
    position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-24px);
    margin-bottom: var(--aox-20px);
    padding-left: var(--aox-30px);
    text-transform: uppercase;
    color: #8CC76F;
    }
.obj_formation_detail.is_registered .ctn_text .t_inscription:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: var(--aox-24px);
    width: var(--aox-24px);
    background: url(../../images/i_check_green.svg) center center / var(--aox-24px) auto no-repeat;
    }

.obj_formation_detail .ctn_text .ctn_cta{
    display: flex;
    flex-direction: row;
    align-items: center;
    }
.obj_formation_detail .ctn_text .ctn_cta .obj_cta{
    margin: 0;
    }
.obj_formation_detail .ctn_text .ctn_cta .obj_btn + .obj_btn{
    margin-left: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .ctn_visuel_map{
    position: relative;
    margin-top: var(--aox-40px);
    }


/***/

@media screen and (max-width:767px){

    .obj_formation_detail {
        width: 100%;
        }
    
    .obj_formation_detail .ctn_image {
        width: var(--aox-col-2);
        position: absolute;
        right: 0; top: var(--aox-20px);
        }
    .obj_formation_detail .ctn_text {
        margin-top: var(--aox-10px);
        }
    .obj_formation_detail .ctn_text .list_infos{
        margin: var(--aox-10px) 0 var(--aox-10px);
        }
    .obj_formation_detail.is_registered .ctn_text .list_infos{
        margin: var(--aox-5px) 0 var(--aox-15px);
        }
    .obj_formation_detail.is_registered .ctn_text .list_infos li {
        font-size: var(--aox-fs-16px);
        }

    .obj_formation_detail .ctn_text .obj_formation_detail_form {
        width: 100%;
        }
    .obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_native-select{
        margin-bottom: var(--aox-30px);
        }
        
    .obj_formation_detail .ctn_text .ctn_cta {
        flex-direction: column;
        align-items: flex-start;
        }
    .obj_formation_detail .ctn_text .ctn_cta .obj_btn + .obj_btn {
        margin-left: 0;
        margin-top: var(--aox-10px);
        }
    .obj_formation_detail .ctn_text .ctn_visuel_map img{
        width: 100%;
        height: auto;
        }
    .obj_formation_detail .ctn_text .ctn_visuel_map {
        margin-top: var(--aox-30px);
        }

    .obj_formation_detail .ctn_image .ctn_visuel {
        width: var(--aox-col-2);
        height: var(--aox-col-2);
        }
        

}