/* ======================================== */
/* .main_container
/* ======================================== */
.main_container{
	display:flex;
	flex-flow:row wrap;
	width:100%;
	max-width:var(--aox-grid);
	margin:0 auto;
	margin-top: var(--aox-50px);
	padding-top:0;
	transition:all .3s ease;
	}


@media screen and (max-width:767px){
	.main_container{
		max-width:unset;
		margin-top: 0;
		width: var(--aox-grid);
		}
	.menu-mobile_is-opened{
		background:#f2f2f2;
		}
	.menu-mobile_is-opened .main_container{
		display:none;
		}
}