/* ======================================== */
/* .section_push_brand_home
/* ======================================== */

.section_push_brand_home.obj_section {
	padding: var(--aox-40px);
    /* background: #F2F2F2; */
	background: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
	}
.section_hero.obj_section + .section_push_brand_home.obj_section {
	padding: var(--aox-10px) 0 var(--aox-70px) 0;
	}
.section_push_brand_home.obj_section .ctn_section{
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: var(--aox-1140px);
    animation: effectHeroEntry 1s ease 2500ms forwards;
    opacity: 0;
	}
.section_push_brand_home.obj_section .t_title {
	font-size: var(--aox-fs-16px);
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	margin: var(--aox-50px) 0 var(--aox-30px);
	color: white;
	padding: 0 var(--aox-30px);
	}

.section_push_brand_home.obj_section .ctn_background img{
	width: 100%;
	}

@media screen and (max-width:767px){
	
	.section_push_brand_home.obj_section {
		left: calc(-1 * var(--aox-external-gutter));
		width: 100vw;
		}
	.section_push_brand_home.obj_section .ctn_section{
		width: 100vw;
    	height: auto;
		}
	.section_push_brand_home.obj_section .t_title {
		font-size: var(--aox-fs-14px);
    	width: var(--aox-grid);
		margin: var(--aox-30px) 0 var(--aox-20px);
		}
	.section_hero.obj_section + .section_push_brand_home.obj_section {
		padding: var(--aox-10px) 0 0 0;
		}

}