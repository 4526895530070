/* ======================================== */
/* .obj_tabs-list-attestation
/* ======================================== */

.obj_tabs-list-attestation{
	position: relative;
    margin-top: var(--aox-30px);
    width: 100%;
    }
.obj_tabs-list-attestation .ctn_nav-tabs {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #c3c3c3;
    flex-direction: row;
    align-items: center;
    }
.obj_tabs-list-attestation .ctn_nav-tabs li {
    position: relative;
    width: 100%;
    }
.obj_tabs-list-attestation .ctn_nav-tabs li button {
    position: relative;
    width: 100%;
    padding: var(--aox-23px) 0 var(--aox-21px);
    font-size: var(--aox-fs-18px);
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 4px solid transparent;
    transition: all .3s ease;
    }
.obj_tabs-list-attestation .ctn_nav-tabs li.active button {
    border-bottom-color: #000;
    }

.obj_tabs-list-attestation .ctn_content-tabs{ display: none; }
.obj_tabs-list-attestation .ctn_content-tabs.active { display: block; }

/***/
.obj_tabs-list-attestation .obj_accordion{
    position: relative;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row{
    position: relative;
    border-bottom: 1px solid #E1E1E1;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row-header {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: var(--aox-12px) 0 var(--aox-12px) var(--aox-20px);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    cursor: pointer;
    width: 50%;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title:hover .obj_accordion_toggle-icon{
    background-color: #000;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title:hover .obj_accordion_toggle-icon::before {
    border-color: #fff;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title > .t_title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-transform: uppercase;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    margin-left: var(--aox-20px);
    height: var(--aox-24px);
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    font-weight: 700;
    width: var(--aox-240px);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title .obj_accordion_toggle-icon{
    position: relative;
    z-index: 999;
    width: var(--aox-24px);
    height: var(--aox-24px);
    background: #E5E5E5;
    border-radius: 50%;
    font-size: 0;
    cursor: pointer;
    transition: all .3s ease;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title .obj_accordion_toggle-icon::before{
    content: "";
    position: absolute;
    display: inline-block;
    width: var(--aox-6px); height: var(--aox-6px);
    top: 50%; left: 50%;
    border-bottom: 2px solid; border-right: 2px solid;
    transform: translate(-50%, -70%) rotateZ(45deg);
    }


.obj_tabs-list-attestation .obj_accordion .obj_accordion-row .ctn_accordion-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 500ms;
    width: 100%;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row.is_expanded .ctn_accordion-content {
    grid-template-rows: 1fr;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row .ctn_accordion-content .obj_accordion-content{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #f7f7f7;
    overflow: hidden;
    height: unset;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row .ctn_accordion-content .obj_accordion-content > div{
    padding: var(--aox-20px) var(--aox-90px) var(--aox-20px) var(--aox-64px);
    width: 100%;
    }


.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header{
    position: relative;
    font-size: var(--aox-fs-12px);
    font-weight: 500;
    line-height: var(--aox-lh-140);
    width: 100%;
    padding: var(--aox-10px) 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header > * + *,
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows > * + *{
    margin-left: var(--aox-20px);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(1),
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_date_hour{
    width: 20%;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(2),
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_event{
    width: 60%;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(3),
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_location{
    width: 20%;
    }

.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows{
    position: relative;
    font-size: var(--aox-fs-12px);
    font-weight: 500;
    line-height: var(--aox-lh-140);
    width: 100%;
    padding: var(--aox-10px) 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #E1E1E1;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_date_hour{
    font-size: var(--aox-fs-14px);
    font-weight: 700;
    line-height: var(--aox-lh-140);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_event{
    font-size: var(--aox-fs-14px);
    font-weight: 700;
    line-height: var(--aox-lh-140);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_location{
    font-size: var(--aox-fs-14px);
    font-weight: 400;
    line-height: var(--aox-lh-140);
    }







/***/
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row.is_expanded .obj_accordion_toggle-icon{
	transform: rotateZ(180deg);
	}



@media screen and (max-width: 768px) {

    .section_challenges-detail .ctn_table_attestation{
        margin-left: calc(-1* var(--aox-gutter));
        width: calc(100% + var(--aox-gutter) + var(--aox-gutter));
        }
    .obj_tabs-list-attestation .ctn_nav-tabs li button {
        padding: var(--aox-18px) var(--aox-5px) var(--aox-15px);
        font-size: var(--aox-fs-15px);
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-row-header {
        padding: var(--aox-20px);
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-title,
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-cta{
        width: calc(50% - var(--aox-5px));
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-title > .t_title{
        margin-left: var(--aox-10px);
        width: calc(100% - var(--aox-34px));
        height: auto;
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-row-header .obj_accordion-cta .obj_cta {
        font-size: var(--aox-fs-10px);
        padding: var(--aox-12px) var(--aox-14px);
        }

    .obj_tabs-list-attestation .obj_accordion .obj_accordion-row .ctn_accordion-content .obj_accordion-content > div{
        padding: var(--aox-20px);
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header > * + *, .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows > * + * {
        margin-left: var(--aox-10px);
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header {
        flex-wrap: wrap;
        padding: 0 0 var(--aox-6px) 0;
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(1),
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_date_hour{
        width: calc(37% - var(--aox-5px));
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(2),
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_event{
        width: calc(63% - var(--aox-5px));
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(3),
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_location{
        width: 100%;
        margin-left: 0;
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows {
        flex-wrap: wrap;
        }

    }