.obj_list_participants_select .i_plus{
	position:absolute;
	right:var(--aox-20px); top: 50%;
	width:var(--aox-16px); height:var(--aox-16px);
	transform:translateY(-50%);
	}
.obj_list_participants_select .i_plus::before,
.obj_list_participants_select .i_plus::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#000;
	transform:translate(-50%,-50%);
	}
.obj_list_participants_select .i_plus::before{
	width:var(--aox-16px); height:var(--aox-3px);
	}
.obj_list_participants_select .i_plus::after{
	width:var(--aox-3px); height:var(--aox-16px);
	}
.obj_list_participants_select .bt_list_participants_select{
	position:relative;
	display:flex;
	align-items:center;
	width:100%;
	padding-left:var(--aox-20px);
	padding-right:var(--aox-60px);
	height:var(--aox-65px);
	font-size:var(--aox-fs-12px);
	text-transform:uppercase;
	font-weight:700;
	border:1px solid #000;
	}
.obj_list_participants_select .bt_list_participants_select:hover{
	background:#F2F2F2;
	}
.obj_list_participants_select .ctn_list_participants_select{
	display:flex;
	justify-content:center;
	align-items:center;
	position:fixed;
	left:0; top:0;
	z-index:9999;
	width:100%; height:100%;
	animation-duration:.3s;
	}
.obj_list_participants_select .wrapper_list_participants_select{
	position:relative;
	z-index:2;
	padding:var(--aox-60px) var(--aox-50px) var(--aox-60px) var(--aox-60px);
	width:var(--aox-500px);
	background:#fff;
	}
.obj_list_participants_select .wrapper_list_participants_select p{
	margin-bottom:var(--aox-20px);
	text-transform:uppercase;
	font-weight:700;
	text-align:center;
	}
.obj_list_participants_select .wrapper_list_participants_select ul{
	border-bottom:1px solid #E1E1E1;
	overflow-y: auto;
    max-height: 60vh;
    padding-right: var(--aox-10px);
	}
.obj_list_participants_select .wrapper_list_participants_select ul li{
	border-top:1px solid #E1E1E1;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button{
	position:relative;
	width:100%;
	padding:var(--aox-15px) var(--aox-105px) var(--aox-15px) var(--aox-7px);
	font-size:var(--aox-fs-14px);
	text-align:left;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:disabled{
	opacity:0.3;
	cursor:default;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:not(:disabled):hover{
	background:rgba(0,0,0,0.05);
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button .t_error{
	font-weight: 500;
	margin-bottom: 0;
	text-transform:unset;
	text-align:left;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button .t_add{
	display:flex;
	align-items:center;
	position:absolute;
	right:0; top:0;
	height:100%;
	padding:0 var(--aox-30px) 0 var(--aox-15px);
	font-size:var(--aox-fs-12px);
	text-transform:uppercase;
	font-weight:700;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:disabled.is_error{
	visibility:visible;
	opacity: 1;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:disabled.is_error .t_add{
	visibility:visible;
	opacity: 0.3;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:disabled .t_add{
	visibility:hidden;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button .i_plus{
	right:var(--aox-7px);
	}
.obj_list_participants_select .ctn_list_participants_overlay{
	position:absolute;
	z-index:1;
	left:0; top:0;
	width:100%; height:100%;
	background:rgba(0,0,0,0.6);
	}
.obj_list_participants_select .bt_list_participants_close{
	position:absolute;
	z-index:3;
	right:0; top:0;
	width:var(--aox-60px); height:var(--aox-60px);
	background:#000;
	}
.obj_list_participants_select .bt_list_participants_close:hover{
	background:#777;
	}
.obj_list_participants_select .bt_list_participants_close::before,
.obj_list_participants_select .bt_list_participants_close::after{
	position:absolute;
	left:50%; top:50%;
	background-color:#fff;
	transition:all .3s ease;
	content:'';
	}
.obj_list_participants_select .bt_list_participants_close::before{
	width:2px; height:var(--aox-30px);
	transform:translate(-50%,-50%) rotate(45deg);
	}
.obj_list_participants_select .bt_list_participants_close::after{
	width:var(--aox-30px); height:2px;
	transform:translate(-50%,-50%) rotate(45deg);
	}


	



/***/

@media screen and (max-width:767px){

	.obj_list_participants_select .wrapper_list_participants_select{
		padding:var(--aox-60px) var(--aox-40px) var(--aox-60px) var(--aox-50px);
		}
	.obj_list_participants_select .wrapper_list_participants_select ul{
		padding-right: var(--aox-5px);
		}

	}