/* ======================================== */
/* .obj_subtitle
/* ======================================== */
.obj_subtitle{
	width: 75%;
    margin: 0 auto;
    padding: 0 var(--aox-20px) var(--aox-60px);
    font-weight: 300;
    font-size:var(--aox-fs-16px);
    font-family: 'Montserrat', sans-serif;
    line-height: 2.9rem;
    text-align: center;
	}
.obj_subtitle strong{
	font-weight:700;
	}
.obj_title + .obj_subtitle{	
	padding: 0px var(--aox-20px) var(--aox-15px) var(--aox-20px);
	}
@media screen and (max-width:767px){
	.obj_subtitle{
		width: 100%;
		font-size:var(--aox-fs-14px); line-height: 2.2rem;
		padding: 0 var(--aox-20px) var(--aox-30px);
		}
}