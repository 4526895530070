/* ======================================== */
/* .section_formation_detail
/* ======================================== */

.section_formation_detail.obj_section {
	position: relative;
	}

@media screen and (max-width:767px){
	
	.section_formation_detail.obj_section .ctn_title .t_title {
		width: var(--aox-col-4);
		}


}