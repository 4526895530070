/* ======================================== */
/* .section_form
/* ======================================== */
.section_mentions{
	width:100%;
	}
.section_mentions .str_container{
	max-width:var(--aox-960px);
	}
/**/
.section_mentions .ctn_top{
	padding-top:var(--aox-60px);
	}
.section_mentions .ctn_top h1{
	padding-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:1;
	word-wrap:break-word;
	border-bottom:1px solid #cbcbcb;
	}
/**/
.section_mentions .ctn_main{
	padding-bottom:var(--aox-180px);
	}
/**/
.section_mentions .ctn_paragraph{
	padding:var(--aox-80px) var(--aox-20px) 0 var(--aox-20px);
	margin:calc(-1 * var(--aox-20px)) auto 0 auto;
	max-width:calc(var(--aox-810px) + var(--aox-5px));
	}
.section_mentions .ctn_paragraph.mt_40{
	padding-top:0;
	margin-top:var(--aox-40px);
	}
.section_mentions .ctn_paragraph h2{
	font-weight:700;
	font-size:var(--aox-fs-22px); line-height:1.2;
	text-transform:uppercase;
	}
.section_mentions .ctn_paragraph h3{
	margin-top:var(--aox-30px);
	font-weight:700;
	font-size:var(--aox-fs-18px); line-height:1.2;
	}
.section_mentions h4{
	padding-top:var(--aox-80px);
	margin-top:calc(-1 * var(--aox-50px));
	font-size:var(--aox-fs-24px); line-height:1.2;
	}
.section_mentions .ctn_paragraph p{
	font-size:var(--aox-fs-14px); line-height:2;
	}
.section_mentions .ctn_paragraph p.var_align-center{
	text-align:center;
	}
.section_mentions .ctn_paragraph h2 + p,
.section_mentions .ctn_paragraph h3 + p,
.section_mentions .ctn_paragraph h4 + p,
.section_mentions .ctn_paragraph ul + p,
.section_mentions .ctn_paragraph ol + p,
.section_mentions .ctn_paragraph p + p{
	margin-top:var(--aox-30px);
	}
.section_mentions .ctn_paragraph p strong{
	font-weight:700;
	}
.section_mentions .ctn_paragraph p a{
	font-weight:700;
	text-decoration:underline;
	}
.section_mentions .ctn_paragraph ul li{
	position:relative;
	margin-top:var(--aox-30px);
	padding-left:var(--aox-60px);
	font-size:var(--aox-fs-14px); line-height:2;
	}
.section_mentions .ctn_paragraph ul li::before{
	content:'';
	position:absolute;
	left:var(--aox-30px); top:var(--aox-10px);
	width:var(--aox-6px); height:var(--aox-6px);
	background:#000;
	border-radius:50%;
	}
.section_mentions .ctn_paragraph ul li strong,
.section_mentions .ctn_paragraph ol li strong{
	font-weight:700;
	}
.section_mentions .ctn_paragraph ul li a,
.section_mentions .ctn_paragraph ol li a{
	font-weight:700;
	text-decoration:underline;
	}
.section_mentions .ctn_paragraph ul ul{
	margin-top:var(--aox-15px);
	}
.section_mentions .ctn_paragraph ul ul li{
	margin-top:var(--aox-5px);
	padding-left:var(--aox-45px);
	}
.section_mentions .ctn_paragraph ul ul li::before{
	top:var(--aox-13px);
	width:5px; height:2px;
	border-radius:0;
	}
.section_mentions .ctn_paragraph ol{
	counter-reset:mentions-list-item;
	}
.section_mentions .ctn_paragraph ol > li{
	position:relative;
	margin-top:var(--aox-30px);
	padding-left:var(--aox-60px);
	font-size:var(--aox-fs-14px); line-height:2;
	}
.section_mentions .ctn_paragraph ol > li::before{
	content:'1.';
	position:absolute;
	left:var(--aox-25px); top:-5px;
	font-size:var(--aox-fs-13px);
	font-weight:700;
	counter-increment:mentions-list-item;
	content:counter(mentions-list-item) '.';
	}
/**/
.section_mentions .ctn_frame{
	padding:var(--aox-40px);
	margin:var(--aox-60px) auto 0 auto;
	max-width:calc(var(--aox-810px) + var(--aox-5px));
	border:1px solid #d7d7d7;
	}
.section_mentions .ctn_frame h4{
	margin-top:0;
	padding-top:0;
	}
.section_mentions .ctn_frame ul li{
	margin-top:var(--aox-30px);
	font-size:var(--aox-fs-14px); line-height:2;
	}
.section_mentions .ctn_frame ul li strong{
	font-weight:700;
	}
.section_mentions .ctn_frame ul li a{
	font-weight:700;
	text-decoration:underline;
	}
/**/
.section_mentions .ctn_sommaire{
	padding:0 var(--aox-20px);
	margin:var(--aox-60px) auto 0 auto;
	max-width:calc(var(--aox-810px) + var(--aox-5px));
	}
.section_mentions .ctn_sommaire h2{
	font-weight:700;
	font-size:var(--aox-fs-22px); line-height:1.2;
	text-transform:uppercase;
	}
.section_mentions .ctn_sommaire ul{
	padding-left:var(--aox-60px);
	margin-top:var(--aox-45px);
	}
.section_mentions .ctn_sommaire ul li a{
	font-weight:700;
	font-size:var(--aox-fs-14px); line-height:2;
	text-decoration:underline;
	}
.section_mentions .ctn_sommaire ul ul{
	padding-left:var(--aox-30px);
	margin:var(--aox-10px) 0;
	}
/**/
.ctn_table{
	margin-top:var(--aox-60px);
	padding:0 var(--aox-30px);
	overflow-x:auto;
	}
.ctn_table strong{
	font-weight:700;
	}
.ctn_table ul{
	padding-left:var(--aox-15px);
	}
.ctn_table ul li + li{
	margin-top:var(--aox-15px);
	}
.ctn_table ul ul{
	margin-top:var(--aox-15px);
	}
.ctn_table ul ul li{
	margin:0;
	}
.ctn_table.var_4-col table th{
	width:25%;
	}
.ctn_table.var_2-col table th:first-child{
	width:30%;
	}
.ctn_table.var_2-col table th:last-child{
	width:70%;
	}
.ctn_table table th{
	font-weight:700;
	background:#f2f2f2;
	}
.ctn_table table th,
.ctn_table table td{
	padding:var(--aox-15px);
	font-size:var(--aox-fs-14px); line-height:1.3;
	text-align:left;
	border:1px solid #c7c7c7;
	}
/**/
@media screen and (max-width:960px){
	.section_mentions .ctn_top{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
}
@media screen and (max-width:768px){
	.ctn_table{
		padding:0;
		}
}
@media screen and (max-width:767px){
	.section_mentions .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_mentions .ctn_top{
		padding-top:var(--aox-40px);
		}
	.section_mentions .ctn_top h1{
		font-size:var(--aox-fs-40px);
		}
	.section_mentions .ctn_main{
		padding-bottom:var(--aox-70px);
		}
	.section_mentions .ctn_paragraph,
	.section_mentions h4{
		padding-top:var(--aox-120px);
		margin-top:calc(-1 * var(--aox-80px));
		}
	.section_mentions .ctn_frame{
		margin-top:var(--aox-40px);
		padding:var(--aox-30px) var(--aox-20px);
		}
	.section_mentions .ctn_sommaire{
		margin-top:var(--aox-40px);
		}
	.section_mentions .ctn_table{
		margin-top:var(--aox-40px);
		}
	.section_mentions .ctn_paragraph h3,
	.section_mentions .ctn_paragraph h2 + p,
	.section_mentions .ctn_paragraph h3 + p,
	.section_mentions .ctn_paragraph h4 + p,
	.section_mentions .ctn_paragraph ul + p,
	.section_mentions .ctn_paragraph ol + p,
	.section_mentions .ctn_paragraph p + p,
	.section_mentions .ctn_paragraph ul li,
	.section_mentions .ctn_frame ul li,
	.section_mentions .ctn_paragraph ol > li{
		margin-top:var(--aox-20px);
		}
	.section_mentions .ctn_paragraph ul li,
	.section_mentions .ctn_paragraph ol > li{
		padding-left:var(--aox-30px);
		}
	.section_mentions .ctn_paragraph ul li::before{
		left:var(--aox-10px);
		}
	.section_mentions .ctn_paragraph ol > li::before{
		left:5px;
		}
	.section_mentions .ctn_paragraph ul ul li{
		padding-left:var(--aox-20px);
		}
	.section_mentions .ctn_paragraph ul ul li::before{
		left:5px;
		}
	.section_mentions .ctn_sommaire ul{
		padding-left:var(--aox-30px);
		margin-top:var(--aox-25px);
		}
	.section_mentions .ctn_sommaire ul ul{
		padding-left:var(--aox-20px);
		}
	.ctn_table{
		margin-top:var(--aox-40px);
		}
	.ctn_table ul{
		padding-left:5px;
		}
	.ctn_table ul li + li{
		margin-top:5px;
		}
	.ctn_table table th,
	.ctn_table table td{
		padding:5px;
		font-size:2vw;
		}
}