/* ======================================== */
/* .obj_tableau_recompences_exp
/* ======================================== */

.obj_tableau_recompences_exp{
	position: relative;
	}
.obj_tableau_recompences_exp .ctn_row{
	position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
	}
.obj_tableau_recompences_exp .ctn_row + .ctn_row{
	margin-top: var(--aox-35px);
	}
.obj_tableau_recompences_exp .bloc_items_exp{
	position: relative;
    display: flex;
    flex-direction: column;
    margin-right: calc(var(--aox-col-1) - var(--aox-15px));
    margin-top: var(--aox-35px);
	}
.obj_tableau_recompences_exp .bloc_items_exp .lst_items_exp{
	position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
	}






/***/


@media screen and (max-width:767px){

	.obj_tableau_recompences_exp .ctn_row {
		flex-direction: column;
		}
	.obj_tableau_recompences_exp .bloc_items_exp {
		margin-right: 0;
		}
	.obj_tableau_recompences_exp .bloc_items_exp .t_desc {
		text-align: center;
		}
	.obj_tableau_recompences_exp .bloc_items_exp + .bloc_items_exp {
		margin-left: 0;
		margin-top: var(--aox-40px);
		}
	.obj_tableau_recompences_exp .bloc_items_exp .lst_items_exp{
		flex-wrap: wrap;
		}
}
