/* ======================================== */
/* .pg_infos-perso
/* ======================================== */
@media screen and (max-width:767px){
	.pg_infos-perso .section_form .ctn_form .ctn_contact{
		display:block;
		margin-bottom:var(--aox-20px);
		}

	.pg_infos-perso .section_form .ctn_form {
		padding: var(--aox-40px) var(--aox-20px);
		}
	.pg_infos-perso .section_form .ctn_form .ctn_contact {
		padding: var(--aox-50px) var(--aox-20px);
		}
	.pg_infos-perso .obj_form input[type="password"]{
		padding: var(--aox-10px) var(--aox-50px) var(--aox-10px) var(--aox-20px);
		font-size:var(--aox-fs-9px);
		}
}