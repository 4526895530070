/* ======================================== */
/* .obj_links_hero_home
/* ======================================== */
.obj_links_hero_home{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
	}

.obj_links_hero_home .ctn_left_hero_home{
	width: var(--aox-650px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
	}	
.obj_links_hero_home .ctn_left_hero_home .ctn_title{
	margin: 0;
    padding: 0 var(--aox-30px) var(--aox-30px) var(--aox-290px);
	}	
.obj_links_hero_home .ctn_left_hero_home .ctn_title .t_title{
	font-size: var(--aox-fs-40px);
	line-height: var(--aox-lh-100);
    font-weight: 300;
    text-transform: none;
	text-align: right;
	width: 100%;
	margin-bottom: 0;
	color: white;
	opacity:0;
	animation: 0.5s forwards effectHeroTitle 0s;
    transition: opacity 0.5s,transform 0.5s;
	}

.obj_links_hero_home .ctn_right_hero_home{
	width: var(--aox-480px);
	padding-top: var(--aox-85px);
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
	}	


	
@media screen and (max-width: 767px){

	.obj_links_hero_home {
		align-items: stretch;
		justify-content: flex-start;
		}
	.obj_links_hero_home .ctn_left_hero_home {
		width: 100%;
		}
	.obj_links_hero_home .ctn_right_hero_home {
		width: 100%;
		padding-top: var(--aox-2px);
		}
	.obj_links_hero_home .ctn_left_hero_home .ctn_title {
		margin: 0 0 var(--aox-10px) 0;
		padding: 0 var(--aox-60px);
		height: auto;
		}
	.obj_links_hero_home .ctn_left_hero_home .ctn_title .t_title {
		font-size: var(--aox-fs-20px);
		line-height: var(--aox-lh-120);
		font-weight: 400;
		text-align: center;
		}


	}