@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap');

/* ======================================== */
/* reset
/* ======================================== */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video{
	margin:0;
	padding:0;
	border:0;
	font-size:100%;
	font:inherit;
	vertical-align:baseline;
	}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section{
	display:block;
	}
body{
	line-height:1;
	letter-spacing:0.05em;
	}
ol, ul{
	list-style:none;
	}
blockquote, q{
	quotes:none;
	}
blockquote:before, blockquote:after,
q:before, q:after{
	content:"";
	content:none;
	}
table{
	border-collapse:collapse;
	border-spacing:0;
	}
*{
	box-sizing:border-box;
	}
html{
	/* font-size:62.5%; */
	font-family:'Montserrat', sans-serif;
	font-weight:300;
	}
a{
	color:inherit;
	text-decoration:none;
	outline:none;
	cursor:pointer;
	}
img{
	display:block;
	width:auto; height:auto;
	}
input{
	outline:none;
	font-family:'Montserrat', sans-serif;
	font-weight:300;
	letter-spacing:0.05em;
	}
button{
	margin:0;
    padding:0;
    border:none;
	outline:none;
    background:none;
	cursor:pointer;
	font-family:'Montserrat', sans-serif;
	font-weight:300;
	}
::-webkit-scrollbar{
	-webkit-appearance:none;
	}
::-webkit-scrollbar:vertical{
	width:10px;
	}
::-webkit-scrollbar:horizontal{
	height:10px;
	}
::-webkit-scrollbar-thumb{
	background-color:rgba(0,0,0,0.2);
	border-radius:10px;
	border:2px solid #fff;
	}
::-webkit-scrollbar-track{
	border-radius:10px;
	background-color:#fff;
	}
sup{
	position:relative;
	top:-0.7em;
	font-size:0.5em;
	}
input::-ms-reveal,
input::-ms-clear{
	display: none;
	}
a,
button{
	color:#000;
	}

@media screen and (min-width:1025px){
	a,
	button{
		transition:all .3s ease;
		}
}

body {
	min-width: 100vw;
	position: relative;
	overflow-x: hidden;
	}