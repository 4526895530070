/* ======================================== */
/* .main_footer-login
/* ======================================== */
.main_footer-login{
	padding:var(--aox-90px) 0;
	background:#f2f2f2;
	}
.main_footer-login ul li{
	font-size:var(--aox-fs-15px); line-height:1;
	}
.main_footer-login ul li a{
	display:inline-block;
	padding:var(--aox-10px) var(--aox-20px) var(--aox-10px) 0;
	color:#777;
	}
.main_footer-login ul li.var_bold a,
.main_footer-login ul li strong{
	display:block;
	padding:var(--aox-20px) var(--aox-20px) var(--aox-20px) 0;
	font-weight:700;
	letter-spacing:0.1em;
	text-transform:uppercase;
	color:#000;
	}
.main_footer-login .ctn_bottom .second_nav-footer{
	margin-bottom:var(--aox-80px);
	}
.main_footer-login .ctn_bottom .second_nav-footer ul{
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	width:90%;
	margin:0 auto;
	}
.main_footer-login .ctn_bottom .second_nav-footer ul li a,
.main_footer-login .ctn_bottom .second_nav-footer ul li button{
	padding:var(--aox-20px);
	text-align:center;
	}
.main_footer-login .t_mentions{
	font-size:var(--aox-fs-15px); line-height:1;
	text-align:center;
	color:#777;
	}
.main_footer-login .optanon-show-settings-popup-wrapper{
	padding:var(--aox-10px) var(--aox-20px);
	}
.main_footer-login .optanon-show-settings-popup-wrapper .optanon-show-settings-button{
	display:flex;
	justify-content:center;
	}
.main_footer-login .t_mentions{
	font-size:var(--aox-fs-15px); line-height:1;
	text-align:center;
	color:#777;
	}

.main_footer-login .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.ot-sdk-show-settings,
.main_footer-login .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.optanon-show-settings{
    display:block;
	width:100%;
    padding:var(--aox-20px);
    font-size:var(--aox-fs-15px); line-height:1;
    font-weight:700;
    letter-spacing:.1em;
    text-transform:uppercase;
    text-align:center;
    color:#000;
    border:none;
	}
.main_footer-login .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.ot-sdk-show-settings:hover,
.main_footer-login .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.optanon-show-settings:hover{
    background:transparent;
	}

@media screen and (max-width:767px){
	.main_footer-login{
		padding:var(--aox-60px) var(--aox-20px);
		text-align:center;
		}
	.main_footer-login ul li a{
		padding:var(--aox-10px) 0;
		}
	.main_footer-login ul li.var_bold a,
	.main_footer-login ul li strong{
		padding-right:0;
		}
	.main_footer-login .ctn_bottom .second_nav-footer{
		margin-bottom:var(--aox-50px);
		}
	.main_footer-login .ctn_bottom .second_nav-footer ul{
		max-width:unset;
		width:100%;
		}
	.main_footer-login .ctn_bottom .second_nav-footer ul li{
		width:100%;
		}
	.main_footer-login .optanon-show-settings-popup-wrapper{
		padding:var(--aox-15px) 0;
		}
}