/* ======================================== */
/* .obj_filter-challenge
/* ======================================== */
.obj_filter-challenge-menu{
    display: none;
    }
.obj_filter-challenge{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
	}

/***/
.obj_filter-challenge .obj_filters_header{
    position: relative;
    padding-bottom: var(--aox-12px);
    display: flex;
    margin-bottom: var(--aox-20px);
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    border-bottom: 1px solid #D3D3D3;
    }
.obj_filter-challenge .obj_filters_header .t_title {
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    text-transform: uppercase;
    }
.obj_filter-challenge .obj_filters_header .obj_remove-filter button{
    font-weight: 300;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-100);
    text-decoration: underline;
    }
.obj_filter-challenge .obj_filters_header .obj_remove-filter button:hover{
    text-decoration: none;
    }


/***/

.obj_filter-challenge .obj_filter-list-item{
    width: 100%;
    }
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container{
    width: 100%;
    }
.obj_filter-challenge .obj_filter-list-item .t_title {
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    margin-bottom: var(--aox-10px);
    }
.obj_filter-challenge .obj_filter-list-item + .obj_filter-list-item{
    margin-top: var(--aox-20px);
    }

.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element input[type=checkbox] {
	display: none;
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element label {
	display: block;
    position: relative;
    padding: var(--aox-5px) 0 0 var(--aox-30px);
    min-height: var(--aox-26px);
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    cursor: pointer;
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element label::before {
	position: absolute;
    left: 0;
    top: 3px;
    width: var(--aox-18px);
    height: var(--aox-18px);
    border: 1px solid rgba(0,0,0,0.2);
    background: #fff;
    transition: all .3s ease;
    content: '';
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element label:hover::before {
	background: rgba(0,0,0,0.05);
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element label::after {
	content: '';
    position: absolute;
    left: 4px;
    top: var(--aox-9px);
    width: var(--aox-12px);
    height: var(--aox-9px);
    opacity: 0;
    transition: all .3s ease;
    background-image: url(../../images/check-mark.svg);
    background-size: cover;
    background-repeat: no-repeat;
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element input[type=checkbox]:checked + label::after {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	}
.obj_filter-challenge .obj_filter-list-item.var_date .ctn_form-element{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    }
.obj_filter-challenge .obj_filter-list-item.var_date .ctn_form-element span{
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    margin-right: var(--aox-10px);
    }
.obj_filter-challenge .obj_filter-list-item.var_date .ctn_form-element input{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    border: 1px solid rgba(0,0,0,0.2);
    text-transform: uppercase;
    letter-spacing: 0;
    padding: var(--aox-8px) var(--aox-10px) var(--aox-7px) var(--aox-15px);
    width: var(--aox-180px);
    color: rgba(0,0,0,0.5);
    }
.obj_filter-challenge .obj_filter-list-item.var_date .ctn_form-element i.calendar_icon {
    content: "";
    position: absolute;
    right: 5px;
    top: 50%;
    height: var(--aox-22px);
    width: var(--aox-22px);
    transform: translateY(-50%);
    background: url(../../images/i_calendar_empty.svg) center center no-repeat;
    }
.obj_filter-challenge .obj_filter-list-item.var_date li + li{
    margin-top: var(--aox-10px);
    }    

/***/

@media screen and (max-width:767px){

    .obj_filter-challenge {
        flex-wrap: nowrap;
        opacity: 0;
        height: 0;
        padding: 0;
        transition: all .3s ease;
        width: var(--aox-grid);
        margin: 0 auto;
        } 
    .obj_filter-challenge.is-expanded{
        opacity: 1;
        height: auto;
        }


    /***/
    .obj_filter-challenge-menu{
        display: block;
        position: absolute;
        right: 0;
        top: calc(-1 * var(--aox-60px));
        }
    .section_challenges .obj_filter-challenge-menu{
        right: var(--aox-20px);
        }
    .obj_btn_filter-challenge{
        height: var(--aox-40px); width: var(--aox-40px);
        }
    .obj_btn_filter-challenge:before{
        content: "";
        position: absolute;
        left: 0; top: 0;
        height: var(--aox-40px); width: var(--aox-40px);
        background: url(../../images/i_filter-btn.svg) left top / var(--aox-40px) auto no-repeat;
        }
    .obj_filter-challenge .obj_filters_header .ctn_row{
        width: 100%;
        }
    .obj_filter-challenge > .ctn_row{
        padding: 0 0 var(--aox-20px);
        }
    .obj_filter-challenge .obj_filter-list-item {
        width: var(--aox-col-3);
        }
    .obj_filter-challenge .obj_filter-list-item + .obj_filter-list-item {
        margin-top: 0;
        }
    .obj_filter-challenge .obj_filter-list-item.var_date{
        margin-top: var(--aox-20px);
        width: var(--aox-col-4);
        }

    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge{
        width:var(--aox-40px); height:var(--aox-40px);
        }
    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge::before,
    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge::after{
        position:absolute;
        left:50%; top:50%;
        background-color:#000;
        content:'';
        }
    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge::before{
        width:2px; height:var(--aox-20px);
        transform:translate(-50%,-50%) rotate(45deg);
        }
    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge::after{
        width:var(--aox-20px); height:2px;
        transform:translate(-50%,-50%) rotate(45deg);
        }

}