/* ======================================== */
/* .str_container
/* ======================================== */
.str_container{
	width:100%; max-width:var(--aox-1280px);
	height:100%;
	margin:0 auto;
	}

@media screen and (max-width:767px){
	.str_container{
		max-width:unset;
		/* max-width:calc(var(--aox-410px) + var(--aox-4px)); */
		}
}