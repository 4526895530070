/* ======================================== */
/* .section_login
/* ======================================== */
.section_login{
	width:100%;
	padding-top:var(--aox-10px);
	}
.section_login header .logo-caf{
	width:var(--aox-390px);
	margin:0 auto;
	}
.section_login header h1{
	padding:var(--aox-55px) 22% var(--aox-70px) 22%;
	font-size:var(--aox-fs-38px); line-height:1.2;
	text-align:center;
	}
.section_login .ctn_login{
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-40px) var(--aox-10px);
	background:#f2f2f2;
	}
.section_login .ctn_login .ctn_col{
	width:50%;
	padding:0 6.3%;
	}
.section_login .ctn_login .ctn_col.col_right{
	border-left:1px solid #cbcbcb;
	}
.section_login .ctn_login .ctn_col.col_100{
	width:100%;
	}
.section_login .ctn_login .ctn_col.col_100 .ctn_input{
	max-width:var(--aox-470px);
	}
.section_login .ctn_login h2{
	padding:var(--aox-15px) 0 var(--aox-40px) 0;
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_login .ctn_logos{
	display: flex;
    justify-content: center;
	}
.section_login .ctn_logos li{
	position:relative;
	display:flex;
	align-items:center;
	flex-grow:1;
	padding:var(--aox-70px) 0;
	}
.section_login .ctn_logos li img{
	position:absolute;
	left:50%; top:50%;
	transform:translate(-50%,-50%);
	}
.section_login .ctn_logos li.var_lrp img{
	max-width:var(--aox-120px);
	}
.section_login .ctn_logos li.var_vichy img{
	max-width:var(--aox-85px);
	}
.section_login .ctn_logos li.var_sanoflore img{
	max-width:var(--aox-90px);
	}
.section_login .ctn_logos li.var_cerave img{
	max-width:var(--aox-90px);
	}
.section_login .ctn_logos li.var_skinc img{
	max-width:var(--aox-120px);
	}
.section_login .ctn_logos li.var_biotherm img{
	max-width:var(--aox-95px);
	}
.section_login .ctn_logos li.var_sgmb img{
	max-width:var(--aox-85px);
	}
.section_login .ctn_logos li.var_sciencemd img{
	max-width:var(--aox-100px);
	}
.section_login .ctn_logos li.var_derm-expertise img{
	max-width:var(--aox-120px);
	}

@media screen and (max-width:1280px){
	.section_login .ctn_login{
		padding:var(--aox-40px) 0;
		}
	.section_login header .logo-caf{
		width:calc(var(--aox-280px) + (350 - 280) * ((100vw - calc(var(--aox-760px) + var(--aox-7px))) / (1280 - 767)));
		}
	.section_login header .logo-caf img{
		width:100%;
		}
	.section_login header h1{
		font-size:calc(var(--aox-32px) + (39 - 32) * ((100vw - calc(var(--aox-760px) + var(--aox-7px))) / (1280 - 767)));
		}
	.section_login .ctn_logos li{
		padding:7vw 0;
		}
}
@media screen and (max-width:1024px){
	.section_login .ctn_logos li img{
		transform:translate(-50%,-50%) scale(0.8);
		}
}
@media screen and (max-width:768px){
	.section_login .ctn_logos li img{
		transform:translate(-50%,-50%) scale(0.6);
		}
}
@media screen and (max-width:767px){
	.section_login header .logo-caf{
		width:var(--aox-160px);
		}
	.section_login header h1{
		padding:var(--aox-30px) var(--aox-20px);
		font-size:var(--aox-fs-20px);
		}
	.section_login .ctn_login{
		margin:0 var(--aox-15px);
		padding:0 var(--aox-30px) var(--aox-40px) var(--aox-30px);
		}
	.section_login .ctn_login .ctn_col{
		width:100%;
		padding:0;
		}
	.section_login .ctn_login .ctn_col.col_left{
		padding-bottom:var(--aox-30px);
		}
	.section_login .ctn_login .ctn_col.col_right{
		border-top:1px solid #cbcbcb;
		border-left:none;
		}
	.section_login .ctn_login h2{
		padding:var(--aox-30px) 0;
		font-size:var(--aox-fs-17px);
		}
	.section_login .ctn_logos{
		flex-flow:row wrap;
		justify-content:center;
		padding:var(--aox-15px) 0;
		}
	.section_login .ctn_logos li{
		flex-flow:row wrap;
		flex-grow:unset;
		width:33.333%;
		padding:var(--aox-30px) 0;
		}
}