/* ======================================== */
/* .pg_maintenance
/* ======================================== */

.pg_maintenance{
	background-color: #F2F2F2;
	}
.pg_maintenance .main_header, .pg_maintenance .main_header-login,
.pg_maintenance .main_footer, .pg_maintenance .main_footer-login{
	display:none;
	}
