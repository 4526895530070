.obj_circle-progress-gains{
	position:relative;
	width:var(--aox-300px);
	height:var(--aox-150px);
	margin:0 auto;
	overflow:hidden;
	}
.obj_circle-progress-gains .CircularProgressbar{
	position:absolute;
	left:0; top:0;
	transform:rotate(-90deg);
	}
.obj_circle-progress-gains .CircularProgressbar .CircularProgressbar-trail{
	display:none;
	}
.obj_circle-progress-gains .CircularProgressbar .CircularProgressbar-path{
	transition:stroke-dashoffset 1s ease 0.5s;
	}