/* ======================================== */
/* .obj_table-produit-concernes
/* ======================================== */
.obj_table-produit-concernes .ctn_row-header{
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-16px) var(--aox-20px);
    border-bottom:1px solid #d7d7d7;
	}
.obj_table-produit-concernes .ctn_row{
    display:flex;
    flex-flow:row wrap;
    padding:var(--aox-16px) var(--aox-20px);
    }
.obj_table-produit-concernes .ctn_row + .ctn_row{
	border-top:1px solid #d7d7d7;
	}

.obj_table-produit-concernes .ctn_row-header p{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    }

.obj_table-produit-concernes .ctn_row-header p:nth-child(1){
	width:20%;
	}
.obj_table-produit-concernes .ctn_row-header p:nth-child(2){
    width:60%;
    }
.obj_table-produit-concernes .ctn_row-header p:nth-child(3){
    width:20%;
    text-align: right;
    }

.obj_table-produit-concernes .ctn_row p{
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    }
.obj_table-produit-concernes .ctn_row p:nth-child(1){
	width:20%;
	}
.obj_table-produit-concernes .ctn_row p:nth-child(2){
    width:60%;
    }
.obj_table-produit-concernes .ctn_row p:nth-child(3){
    width:20%;
    text-align: right;
    }


/***/

@media screen and (max-width:767px){



    

}