/* ======================================== */
/* .section_detail-ingredients
/* ======================================== */
.section_detail-ingredients{
	width:100%;
	}
.section_detail-ingredients .str_container{
	max-width:var(--aox-1150px);
	}
.section_detail-ingredients .str_container > div{
	margin-bottom:var(--aox-80px);
	}
/**/
.section_detail-ingredients h1{
	font-size:var(--aox-fs-50px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	}
.section_detail-ingredients h2{
	font-size:var(--aox-fs-24px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	}
.section_detail-ingredients h3{
	font-size:var(--aox-fs-18px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	}
.section_detail-ingredients p{
	font-size:var(--aox-fs-16px); line-height:1.875;
	}
/**/
.section_detail-ingredients .ctn_header-ingredients{
	display:flex;
	flex-flow:row wrap;
	padding-top:var(--aox-75px);
	}
.section_detail-ingredients .ctn_header-ingredients .ctn_image{
	width:40%;
	}
.section_detail-ingredients .ctn_header-ingredients .ctn_image img{
	width:100%;
	}
.section_detail-ingredients .ctn_header-ingredients .ctn_text{
	width:60%;
	padding:var(--aox-70px) 8%;
	}
.section_detail-ingredients .ctn_header-ingredients .ctn_text p{
	margin-top:var(--aox-25px);
	}
/**/
.section_detail-ingredients .ctn_proprietes-ingredients{
	padding:var(--aox-50px) var(--aox-30px);
	text-align:center;
	background:#f2f2f2;
	}
.section_detail-ingredients .ctn_proprietes-ingredients h2{
	margin-bottom:var(--aox-5px);
	}
.section_detail-ingredients .ctn_proprietes-ingredients p + h3,
.section_detail-ingredients .ctn_proprietes-ingredients h3 + p,
.section_detail-ingredients .ctn_proprietes-ingredients p + p{
	margin-top:var(--aox-35px);
	}
/**/
.section_detail-ingredients .ctn_identite-ingredients{
	padding:var(--aox-70px) var(--aox-90px) var(--aox-50px) var(--aox-90px);
	border:2px solid #d7d7d7;
	}
.section_detail-ingredients .ctn_identite-ingredients h2{
	margin-bottom:var(--aox-25px);
	}
/**/
.section_detail-ingredients .ctn_table-ingredients .ctn_row{
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-20px) var(--aox-30px);
	}
.section_detail-ingredients .ctn_table-ingredients .ctn_row + .ctn_row{
	border-top:1px solid #d7d7d7;
	}
.section_detail-ingredients .ctn_table-ingredients .ctn_row p{
	width:50%;
	}
/**/
.section_detail-ingredients .str_container > .ctn_bienfaits-ingredients{
	display:flex;
	flex-flow:row wrap;
	align-items:flex-start;
	margin-bottom:var(--aox-120px);
	}
.section_detail-ingredients .ctn_bienfaits-ingredients .ctn_col{
	width:60%;
	}
.section_detail-ingredients .ctn_bienfaits-ingredients .col_left{
	padding-right:var(--aox-90px);
	}
.section_detail-ingredients .ctn_bienfaits-ingredients .col_right{
	padding:var(--aox-70px) var(--aox-40px);
	width:40%;
	background:#000;
	}
.section_detail-ingredients .ctn_bienfaits-ingredients .col_right h2,
.section_detail-ingredients .ctn_bienfaits-ingredients .col_right h3,
.section_detail-ingredients .ctn_bienfaits-ingredients .col_right p{
	color:#fff;
	}
.section_detail-ingredients .ctn_bienfaits-ingredients h3,
.section_detail-ingredients .ctn_bienfaits-ingredients p{
	margin-top:var(--aox-35px);
	}
/**/
@media screen and (max-width:1150px){
	.section_detail-ingredients .str_container{
		padding:0 var(--aox-30px);
		}
	.section_detail-ingredients h1{
		font-size:var(--aox-fs-50px);
		}
}
@media screen and (max-width:768px){
	.section_detail-ingredients .str_container{
		padding:0 var(--aox-15px);
		}
}
@media screen and (max-width:767px){
	.section_detail-ingredients .str_container > div,
	.section_detail-ingredients .str_container > .ctn_bienfaits-ingredients{
		margin-bottom:var(--aox-50px);
		}
	.section_detail-ingredients h1{
		font-size:var(--aox-fs-32px);
		}
	.section_detail-ingredients .ctn_header-ingredients{
		padding:var(--aox-30px) 0 0 0;
		}
	.section_detail-ingredients .ctn_header-ingredients .ctn_image{
		width:100%;
		}
	.section_detail-ingredients .ctn_header-ingredients .ctn_text{
		width:100%;
		padding:var(--aox-30px) 0 0 0;
		}
	.section_detail-ingredients .ctn_identite-ingredients{
		padding:var(--aox-50px) var(--aox-25px) var(--aox-30px) var(--aox-25px);
		}
	.section_detail-ingredients .ctn_identite-ingredients h2{
		margin-bottom:var(--aox-20px);
		}
	.section_detail-ingredients .ctn_table-ingredients .ctn_row{
		padding:var(--aox-20px) 0;
		}
	.section_detail-ingredients .ctn_table-ingredients .ctn_row p{
		width:100%;
		}
	.section_detail-ingredients .ctn_bienfaits-ingredients{
		margin:var(--aox-50px) 0;
		}
	.section_detail-ingredients .ctn_bienfaits-ingredients .col_left{
		order:2;
		width:100%;
		padding-right:0;
		margin-top:var(--aox-50px);
		}
	.section_detail-ingredients .ctn_bienfaits-ingredients .col_right{
		width:100%;
		padding:var(--aox-40px) var(--aox-25px);
		}
}