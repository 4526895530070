/* ======================================== */
/* .pg_home2024
/* ======================================== */

.pg_home2024{
	background-color: #F2F2F2;
	}
.pg_home2024 .main_container{
	max-width:100%;
	margin-top: 0;
	transition:none;
	}
.pg_home2024 .main_footer {
	position: relative;
	z-index: 1;
	}

@media screen and (min-width:768px){
	.pg_home2024 .main_header.mode_mini{
		transform:translateY(0);
		}
}
@media screen and (max-width:767px){
	.pg_home2024 .main_container{
		max-width: unset;
		}
		
}