/* ======================================== */
/* .obj_push-carousel
/* ======================================== */
.obj_carousel.obj_push-carousel{
	padding:0 5.8%;
	}
.obj_carousel.obj_push-carousel .slick-arrow{
	top:40%;
	border-color:rgba(0,0,0,1);
	border-width:3px;
	}
.obj_carousel.obj_push-carousel .slick-arrow.slick-disabled{
	border-color:rgba(0,0,0,0.25);
	}
.obj_carousel.obj_push-carousel .slick-prev,
.obj_carousel.obj_push-carousel .slick-prev.slick-disabled{
	left:1.5%;
	}
.obj_carousel.obj_push-carousel .slick-next,
.obj_carousel.obj_push-carousel .slick-next.slick-disabled{
	right:1.5%;
	}

@media screen and (min-width:1281px){
	.obj_carousel.obj_push-carousel .slick-arrow{
		width:var(--aox-50px); height:var(--aox-50px);
		}
}
@media screen and (min-width:1025px){
	.obj_carousel.obj_push-carousel .slick-prev:hover{
		left:1%;
		}
	.obj_carousel.obj_push-carousel .slick-next:hover{
		right:1%;
		}
	.obj_carousel.obj_push-carousel .slick-prev.slick-disabled:hover{
		left:1.5%;
		}
	.obj_carousel.obj_push-carousel .slick-next.slick-disabled:hover{
		right:1.5%;
		}
	.obj_carousel.obj_push-carousel .slick-arrow.slick-disabled:hover{
		border-color:rgba(0,0,0,0.25);
		}
}
@media screen and (max-width:767px){
	.obj_carousel.obj_push-carousel{
		padding:0 var(--aox-20px);
		}
}