/* ======================================== */
/* .section_formation-dashboard
/* ======================================== */
.section_formation-dashboard{
	width:100%;
	overflow:hidden;
	}
.section_formation-dashboard .str_container{
	max-width:var(--aox-1150px);
	}
/**/
.section_formation-dashboard .ctn_formation-dashboard{
	padding:var(--aox-30px) var(--aox-55px);
	background:#f2f2f2;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil{
	position: relative;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_avatar {
	position: relative;
	width: var(--aox-80px);
	height: var(--aox-80px);
	}
.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_avatar figure {
	margin: 0 auto;
	width: 100%;
	max-width: 100%;
	border-radius: 50%;
	overflow: hidden;
	}
.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_avatar figure img {
	max-width: 100%;
	}

.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_formation-dashboard-expertise {
	position: relative;
    margin-left: var(--aox-30px);
    width: var(--aox-460px);
	}

.section_formation-dashboard .ctn_formation-dashboard h2{
	position: relative;
    z-index: 2;
    font-size:var(--aox-fs-12px);
    line-height: 1.2;
    text-align: left;
    text-transform: uppercase;
	}
.section_formation-dashboard .ctn_formation-dashboard .t_status{
	font-size:var(--aox-fs-28px);
    line-height: 1.2;
    text-align: left;
    text-transform: uppercase;
	margin-bottom: var(--aox-8px);
	}
/**/
.section_formation-dashboard .ctn_formation-popin{
	text-align:center;
	}
/**/
@media screen and (max-width:320px){
	.section_formation-dashboard .ctn_formation-dashboard .obj_circle-progress .obj_star-score{
		transform:translate(-50%,-50%) scale(0.75);
		}
}

@media screen and (max-width:768px){
	.section_formation-dashboard{
		margin-left: calc(-1 * var(--aox-gutter));
		width: calc(100% + var(--aox-gutter) + var(--aox-gutter));
		}
	.section_formation-dashboard .ctn_formation-dashboard{
		padding: var(--aox-20px) var(--aox-20px);
		flex-direction: column;
		}
	.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_avatar {
		width: var(--aox-60px);
		height: var(--aox-60px);
		}
	.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_formation-dashboard-expertise {
		margin-left: var(--aox-20px);
		width: calc(100% - var(--aox-80px));
		}
	.section_formation-dashboard .ctn_formation-dashboard .t_status {
		font-size:var(--aox-fs-20px);
		}
}