/* ======================================== */
/* .section_form
/* ======================================== */
.section_form{
	width:100%;
	}
.section_form .str_container{
	max-width:var(--aox-960px);
	}
/**/
.section_form .ctn_top{
	padding-top:var(--aox-60px);
	}
.section_form .ctn_top h1{
	padding-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:1;
	border-bottom:1px solid #cbcbcb;
	}
.section_form .ctn_top h2{
	margin-top:var(--aox-70px);
	margin-bottom:var(--aox-30px);
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.section_form .ctn_top h3{
	margin-bottom:var(--aox-25px);
	font-size:var(--aox-fs-28px); line-height:1;
	font-weight:700;
	color:#777;
	text-transform:uppercase;
	}
.section_form .ctn_top p{
	font-size:var(--aox-fs-12px); line-height:1.6;
	}
/**/
.section_form .ctn_form{
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-70px) 0;
	}
.section_form .ctn_form .col_left{
	width:41.6%;
	}
.section_form .ctn_form .col_right{
	width:58.4%;
	padding-left:7.2%;
	}
.section_form .ctn_form .col_center{
	width:100%;
	padding:0 9.3%;
	}
.section_form .ctn_form .col_right h2,
.section_form .ctn_form .col_center h2,
.section_form .ctn_form .grid_item h2{
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_form .ctn_form .grid_item > h2,
.section_form .ctn_form .grid_item > form > h2{
	margin-top:var(--aox-70px);
	padding-top:var(--aox-70px);
	border-top:1px solid #cbcbcb;
	}
.section_form .ctn_form .ctn_contact{
	padding:var(--aox-50px) var(--aox-30px);
	background:#f2f2f2;
	}
.section_form .ctn_form .ctn_contact h3{
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-15px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.section_form .ctn_form .ctn_contact h4{
	font-size:var(--aox-fs-15px); line-height:1;
	}
.section_form .ctn_form .ctn_contact .ctn_cta{
	margin:var(--aox-30px) 0 var(--aox-40px) 0;
	}
.section_form .ctn_form .ctn_contact .obj_form .ctn_cta{
	margin-bottom:0;
	}
.section_form .ctn_form .ctn_contact .t_tel{
	margin:var(--aox-30px) 0 var(--aox-15px) 0;
	font-size:var(--aox-fs-28px); line-height:1;
	font-weight:700;
	}
.section_form .ctn_form .ctn_contact .t_mention{
	font-size:var(--aox-fs-12px); line-height:1.2;
	}
.section_form .ctn_form .ctn_contact .obj_infobulle .info_text{
	width:var(--aox-280px);
	}
/**/
.section_form .ctn_form .grid_container{
	display:grid;
	grid-template-columns:41.6% 51.2%;
	grid-template-rows:auto 1fr auto auto;
	column-gap:7.2%;
	grid-template-areas:
		"upload perso"
		"mdp perso"
		". pro"
		". suppr";
	}
.section_form .ctn_form .grid_container .grid_img-upload{
	grid-area:upload;
	}
.section_form .ctn_form .grid_container .grid_mdp{
	grid-area:mdp;
	}
.section_form .ctn_form .grid_container .grid_form-perso{
	grid-area:perso;
	}
.section_form .ctn_form .grid_container .grid_form-pro{
	grid-area:pro;
	}
.section_form .ctn_form .grid_container .grid_suppr{
	grid-area:suppr;
	}
.section_form .ctn_form .grid_item.grid_form-perso > h2,
.section_form .ctn_form .grid_item.grid_form-perso > form > h2{
	margin-top:0;
	padding-top:0;
	border-top:none;
	}
/**/
@media screen and (max-width:960px){
	.section_form .ctn_top{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
	.section_form .ctn_form.var_confirm{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
	.section_form .ctn_form .col_left{
		padding-left:var(--aox-20px);
		}
	.section_form .ctn_form .col_right{
		padding-right:var(--aox-20px);
		}
	.section_form .ctn_form .grid_container{
		padding:0 var(--aox-20px);
		}
}
@media screen and (max-width:768px){
	.section_form .ctn_form .ctn_contact .obj_infobulle .info_text{
		width:var(--aox-240px);
		}
}
@media screen and (max-width:767px){
	.section_form .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_form .ctn_top{
		padding-top:var(--aox-40px);
		}
	.section_form .ctn_top h1{
		font-size:var(--aox-fs-40px);
		}
	.section_form .ctn_top h2{
		padding:0 10%;
		margin-top:var(--aox-40px);
		margin-bottom:var(--aox-15px);
		font-size:var(--aox-fs-17px);
		}
	.section_form .ctn_top h3{
		padding:0 10%;
		margin-bottom:var(--aox-25px);
		font-size:var(--aox-fs-26px);
		}
	.section_form .ctn_top p{
		padding:0 10%;
		}
	.section_form .ctn_form{
		padding:var(--aox-40px) 12%;
		}
	.section_form .ctn_form .col_left,
	.section_form .ctn_form .col_right,
	.section_form .ctn_form .col_center{
		width:100%;
		padding:0;
		}
	.section_form .ctn_form .col_right{
		padding-top:var(--aox-20px);
		}
	.section_form .ctn_form .ctn_contact{
		display:none;
		}
	.section_form .ctn_form .col_right h2,
	.section_form .ctn_form .col_center h2,
	.section_form .ctn_form .grid_item h2{
		margin-bottom:var(--aox-30px);
		font-size:var(--aox-fs-17px);
		}
	.section_form .ctn_form .grid_item > h2,
	.section_form .ctn_form .grid_item > form > h2{
		margin-top:var(--aox-40px);
		padding-top:var(--aox-40px);
		}
	.section_form .ctn_form .grid_container{
		padding:0;
		}
	.section_form .ctn_form .grid_container{
		grid-template-columns:100%;
		grid-template-rows:auto;
		column-gap:0;
		grid-template-areas:
			"upload"
			"perso"
			"pro"
			"mdp"
			"suppr";
		}
	.section_form .ctn_form .grid_item.grid_mdp{
		margin-top:var(--aox-40px);
		}
	.section_form .ctn_form .grid_item.grid_suppr > h2{
		padding-top:0;
		border-top:none;
		}
}
@media screen and (max-width:320px){
	.section_form .ctn_form .ctn_contact .obj_infobulle .info_text{
		width:var(--aox-200px);
		}
}