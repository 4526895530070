/* ======================================== */
/* .obj_bloc_expertise
/* ======================================== */

.obj_bloc_expertise{
	position: relative;
	width: 100%;
	letter-spacing: normal;
	border-radius: var(--aox-27px);
	background-color: #F2F2F2;
	overflow: hidden;
	}
.obj_bloc_expertise .t_title {
	font-weight: 700;
	font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-110);
	margin-bottom: var(--aox-20px);
	text-transform: uppercase;
	text-align: center;
	}
.obj_bloc_expertise .ctn_bloc_exp{
	position: relative;
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
	padding: 0 var(--aox-col-1) var(--aox-45px) var(--aox-col-1);
	}
.obj_bloc_expertise.var_global .ctn_bloc_exp{
	padding: var(--aox-57px) var(--aox-col-1) var(--aox-45px) var(--aox-col-1);
	}
.obj_bloc_expertise .ctn_bloc_exp > * {
	width: var(--aox-330px);
	}


/***/
.obj_bloc_expertise .obj_recompences_exp .t_desc{
	text-align: center;
	font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-130);
	font-weight: 400;
	}



/***/
.obj_bloc_expertise .obj_recompences_exp .lst_items_exp{
	position: relative;
    width: calc(100% + var(--aox-15px));
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
	}


/***/
.obj_bloc_expertise + .obj_bloc_expertise{
	margin-top: var(--aox-10px);
	}


.obj_bloc_expertise .obj_tabs > input[type="radio"],
.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp {
	display: none;
	}

.obj_bloc_expertise .ctn_classement_header{
	display: flex;
	flex-direction: row;
	align-items: center;
	}
.obj_bloc_expertise .ctn_classement_header > * + *{
	margin-left: var(--aox-10px);
	}


/***/

.obj_bloc_expertise .obj_header_exp{
	display: flex;
    padding: var(--aox-20px) var(--aox-50px) var(--aox-20px) var(--aox-65px);
    height: var(--aox-70px);
    width: 100%;
    flex-direction: row;
    align-items: center;
    letter-spacing: normal;
    cursor: pointer;
    transition: all var(--duration-500ms) ease;
    justify-content: flex-start;
	}
.obj_bloc_expertise .obj_header_exp:before {
	content: "";
    position: absolute;
    left: var(--aox-32px);
    top: var(--aox-30px);
    height: var(--aox-6px);
    width: var(--aox-6px);
    border-top: solid 2px #000000;
    transform: rotate(135deg);
    transition:all var(--duration-300ms) ease;
	}	
.obj_bloc_expertise .obj_header_exp > *{
	transition:all var(--duration-500ms) ease;
	}
.obj_bloc_expertise .obj_header_exp .t_title{
	margin-bottom: 0;
    text-align: left;
    width: var(--aox-310px);
	}
.obj_bloc_expertise .obj_header_exp .t_tag{
	font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-100);
    text-align: right;
    width: var(--aox-140px);
	}
.obj_bloc_expertise .obj_header_exp .t_tag span{
	display: inline-block;
    text-transform: uppercase;
    padding: var(--aox-8px) var(--aox-16px) var(--aox-6px) var(--aox-16px);
    border-radius: var(--aox-15px);
	/* background-color: rgba(0, 172, 194, 0.15); */
	letter-spacing: normal;
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header{
	width: var(--aox-250px);
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header p{
	font-weight: 500;
    font-size: var(--aox-fs-15px);
	width: var(--aox-105px);
    text-align: right;
    margin-right: var(--aox-20px);
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp {
	position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-32px);
    line-height: var(--aox-lh-90);
    color: #000000;
    margin: 0;
	width: var(--aox-105px);
    text-align: left;
    margin-left: var(--aox-20px);
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp sup {
	font-size: var(--aox-fs-20px);
	line-height: var(--aox-lh-100);
	top: -0.45em;
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp:before {
	content: "";
    position: absolute;
    left: calc(-1 * var(--aox-20px));
    top: 50%;
    height: var(--aox-20px);
    width: var(--aox-20px);
    transform: translate(-50%, -50%);
    background: url(../../images/i_arrow_classement.svg) center center / var(--aox-20px) auto no-repeat;
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header.var_up .t_place_exp:before {
	background: url(../../images/i_arrow_up_classement.svg) center center / var(--aox-20px) auto no-repeat; 
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header.var_down .t_place_exp:before {
	background: url(../../images/i_arrow_down_classement.svg) center center / var(--aox-20px) auto no-repeat; 
	}

.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise{
	display: flex;
	flex-direction: row;
    align-items: center;
	width: calc(var(--aox-400px) + var(--aox-65px));
	justify-content: flex-end;
	}
.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise span:last-child{
	width: var(--aox-65px);
    text-align: left;
	}
.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise span{
	font-weight: 500;
    font-size: var(--aox-fs-15px);
    width: var(--aox-180px);
    text-align: left;
	}
.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise .obj_progress-bar{
	margin-left: 0;
	}
.obj_bloc_expertise .obj_progress-bar-expertise .obj_progress-bar{
	width: var(--aox-210px);
	margin-left: var(--aox-16px);
	}
.obj_bloc_expertise .obj_progress-bar-expertise .obj_progress-bar + span{
	margin-left: var(--aox-10px);
	}
.obj_bloc_expertise .obj_progress-bar-expertise span strong{
	font-weight: 700;
	}

.obj_bloc_expertise .obj_tabs {
	transition: all var(--duration-500ms) ease;
	}
.obj_bloc_expertise:not(.var_global) .obj_tabs{
	/* display: none; */
	max-height: 0;
	opacity: 0;
	}

/***/

.obj_bloc_expertise .obj_points_exp{
	text-align: center;
	}
.obj_bloc_expertise .obj_points_exp .ctn_nbre_points_exp{
	margin-top: var(--aox-30px);
	}
.obj_bloc_expertise .obj_points_exp .ctn_nbre_points_exp span{
	display: flex;
    font-size: var(--aox-fs-50px);
    line-height: var(--aox-lh-110);
    font-weight: 700;
    text-transform: uppercase;
    flex-direction: column;
    flex-wrap: wrap;
	}
.obj_bloc_expertise .obj_points_exp .ctn_nbre_points_exp span em{
	font-size: var(--aox-fs-24px);
    line-height: var(--aox-lh-80);
	}

.obj_bloc_expertise .obj_points_exp .obj_progress-bar-expertise {
	margin-top: var(--aox-23px);
	}
.obj_bloc_expertise .obj_points_exp .obj_progress-bar-expertise span{
	font-size: var(--aox-fs-16px);
	letter-spacing: normal;
	font-weight: 400;
	}
.obj_bloc_expertise .obj_points_exp .obj_progress-bar-expertise span em{
	font-weight: 700;
	}
.obj_bloc_expertise .obj_points_exp .obj_progress-bar-expertise .obj_progress-bar{
	margin: var(--aox-10px) auto;
    width: var(--aox-220px);
	}

.obj_bloc_expertise .obj_points_exp .ctn_cta{
	margin-top: var(--aox-23px);
	}
.obj_bloc_expertise .obj_points_exp .ctn_cta .obj_cta {
	padding: var(--aox-24px);
	line-height: var(--aox-lh-100);
	width:100%;
	}


/***/
.obj_bloc_expertise .t_legal{
	position: relative;
    letter-spacing: initial;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-130);
    color: rgba(0, 0, 0, 0.5);
    margin-top: var(--aox-10px);
	text-align: left;
    font-weight: 400;
	}
.obj_bloc_expertise .t_legal.svg_info{
	padding-left: var(--aox-20px);
    margin-left: var(--aox-20px);
	}
.obj_bloc_expertise .t_legal.svg_info:before {
	content: "";
	position: absolute;
	left: 0; top: 1px;
	height: var(--aox-12px);
	width: var(--aox-12px);
	background: url(../../images/i_info.svg) center center / var(--aox-12px) auto no-repeat;
	}
	
	
/***/

.obj_bloc_expertise.is-expanded .obj_tabs{
	/*max-height: var(--aox-450px);*/
    max-height: 200vh;
	opacity: 1;
	}
.obj_bloc_expertise.is-expanded .obj_header_exp{
	padding: var(--aox-30px) var(--aox-70px);
	height: var(--aox-60px);
	}
.obj_bloc_expertise.is-expanded .obj_header_exp > *{
	/* display: none; */
	opacity: 0;
	}
.obj_bloc_expertise.is-expanded .obj_header_exp:before {
	transform: rotate(315deg);
	}


/***/

@media screen and (max-width:767px){


	/***/

	.obj_bloc_expertise.var_global{
		padding: var(--aox-18px) var(--aox-7px) var(--aox-13px);
		}	
	.obj_bloc_expertise:not(.var_global) .obj_tabs{
		display: none;
		}
	.obj_bloc_expertise.var_global .obj_tabs{
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		}
	.obj_bloc_expertise.is-expanded .obj_tabs{
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		}
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list{
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: center;
		letter-spacing: normal;
		}
	.obj_bloc_expertise .obj_tabs > input[type="radio"] {
		position: absolute;
		left: -200vw;
		}
	.obj_bloc_expertise .obj_tabs.is-ready .tab-panel {
		display: none;
		height: var(--aox-365px);
		}
	.obj_bloc_expertise .obj_tabs .tab-panels > .ctn_bloc_exp .tab-panel.active{
		display: block;
		}
	.obj_bloc_expertise .obj_tabs.is-ready .tab-panel .ctn_recompences_exp{
		overflow-y: auto;
		}
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp {
		position: relative;
		display: inline-block;
		padding: var(--aox-12px) var(--aox-15px);
		font-size: var(--aox-fs-11px);
		line-height: var(--aox-lh-100);
		border: 2px solid rgba(0, 0, 0, 0.1);
		border-radius: var(--aox-21px);
		cursor: pointer;
		font-weight: 700;
		text-transform: uppercase;
		}
	.obj_bloc_expertise .obj_tabs input:focus-visible + label {
		outline: 2px solid rgba(0, 172, 194, 0.15);
		border-radius: 3px;
		}
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp.active {
		border-color: rgba(0, 172, 194, 1);
		background-color: rgba(0, 172, 194, 1);
		color: #fff;
		}
	.obj_bloc_expertise .tab-panels { order: 1; width: 100%; margin-bottom: var(--aox-10px); }
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li:nth-child(1){ order: 2; }
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li:nth-child(2){ order: 3; margin-left: var(--aox-5px); margin-right: var(--aox-5px); }
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li:nth-child(3){ order: 4; }

	.obj_bloc_expertise .ctn_bloc_exp > * {
		width: 100%;
		}
	.obj_bloc_expertise.var_global .ctn_bloc_exp,
	.obj_bloc_expertise .ctn_bloc_exp {
		padding: 0;
		}

	.obj_bloc_expertise .obj_header_exp {
		height: var(--aox-70px);
		justify-content: space-between;
		padding: var(--aox-15px) var(--aox-20px) var(--aox-13px);
		}
	.obj_bloc_expertise .obj_header_exp:before {
		top: var(--aox-45px);
		left: calc(50% - var(--aox-6px));
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header {
		width: var(--aox-250px);
		}
	.obj_bloc_expertise .ctn_classement_header{ order: 1;  margin-left: 0; }
	.obj_bloc_expertise .ctn_classement_header p{ display: none; }
	.obj_bloc_expertise .ctn_classement_header > * + * { margin-left: 0; }
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp {
		padding-left: var(--aox-40px);
		margin-left: 0;
		width: auto;
		font-size: var(--aox-fs-28px);
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp:before{
		left: var(--aox-14px);
		height: var(--aox-28px);
		width: var(--aox-28px);
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp:before {
		background: url(../../images/i_arrow_classement.svg) center center / var(--aox-28px) auto no-repeat; 
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header.var_up .t_place_exp:before {
		background: url(../../images/i_arrow_up_classement.svg) center center / var(--aox-28px) auto no-repeat; 
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header.var_down .t_place_exp:before {
		background: url(../../images/i_arrow_down_classement.svg) center center / var(--aox-28px) auto no-repeat; 
		}
	.obj_bloc_expertise .obj_header_exp .t_title{ 
		order: 2; 
		height: var(--aox-40px);
		padding: 0 var(--aox-15px); 
		font-size: var(--aox-fs-12px); 
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(100% - var(--aox-220px));
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		}
	.obj_bloc_expertise .obj_header_exp .t_tag{ 
		order: 3; 
		margin-left: 0;
		font-size: var(--aox-fs-20px);
		}
	.obj_bloc_expertise .obj_header_exp .t_tag span{
		padding: 0;
		background-color: unset !important;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
		}
	.obj_bloc_expertise .obj_header_exp .t_tag span em{ 
		font-size: var(--aox-fs-10px);
		margin-left: var(--aox-3px);
		margin-bottom: var(--aox-3px);
		}
	.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise{ display: none; }


	/***/
	.obj_bloc_expertise.is-expanded .t_title {
		font-size: var(--aox-fs-12px);
		line-height: var(--aox-lh-100);
		}
	.obj_bloc_expertise.is-expanded {
		padding: var(--aox-18px) var(--aox-7px) var(--aox-13px);
		}
	.obj_bloc_expertise.is-expanded .obj_header_exp{
		padding: 0;
		height: var(--aox-15px);
		}
	.obj_bloc_expertise.is-expanded .obj_header_exp:before {
		top: var(--aox-20px);
		}

	.obj_bloc_expertise .obj_points_exp .ctn_cta .obj_cta {
		width: auto;
		}
	
}

@media screen and (max-width:375px){

	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp{
		text-align: center;
		width: 100%;
		margin-top: var(--aox-5px);
		}

}


