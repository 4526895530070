/* ======================================== */
/* .obj_news_carousel_hero_home
/* ======================================== */
.obj_news_carousel_hero_home{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
	margin-bottom: var(--aox-10px);
	animation: effectNewsCarouselHeroHome 1s ease 1400ms forwards;
    opacity: 0;
	}
.obj_news_carousel_hero_home > *{
	width: 100%;
	}

.obj_news_carousel_hero_home .obj_item{
	width: var(--aox-650px);
	height: var(--aox-150px);
	display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
	overflow: hidden;
	}
.obj_news_carousel_hero_home .obj_item a {
	display: block;
	}

.obj_news_carousel_hero_home .obj_item .ctn_image{
	position: relative;
    display: block;
    width: 100%;
	}
.obj_news_carousel_hero_home .obj_item .ctn_image img{
	display: block;
	height: 100%;
    width: 100%;
    object-fit: cover;
	}

.obj_news_carousel_hero_home .obj_carousel .slick-dots {
	left: auto;
	right: var(--aox-10px);
	bottom: var(--aox-10px);
	justify-content: flex-end;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-dots li {
	height: var(--aox-8px);
	width: var(--aox-8px);
	margin: 0 0 0 var(--aox-4px);
	border-radius: var(--aox-4px);
	background-color: rgba(255, 255, 255, .5);
	border: 1px solid transparent;
	transition: all .3s ease;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-dots li.slick-active {
	background-color: rgba(255, 255, 255, 1);
	width: var(--aox-32px);
	}

.obj_news_carousel_hero_home .obj_carousel .slick-arrow {
	top: 50%;
    transform: translateY(-50%);
    height: var(--aox-40px);
    width: var(--aox-40px);
    margin-top: unset;
	text-indent: unset;
	font-size: 0;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-next:before,
.obj_news_carousel_hero_home .obj_carousel .slick-prev:before {
	content: "";
	opacity: .75;
	display: block;
	height: var(--aox-40px);
    width: var(--aox-40px);
	background-size:var(--aox-40px) var(--aox-40px);
	background-repeat: no-repeat;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-next:hover:before,
.obj_news_carousel_hero_home .obj_carousel .slick-prev:hover:before {
	opacity: 1;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-prev {
	left: var(--aox-10px);
	border-top: none;
    border-left: none;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-next {
	right: var(--aox-10px);
	border-top: none;
    border-right: none;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-prev:before{
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBNSVQuIE1hZGUgYnkgbWljaGFlbGFtcHI6IGh0dHBzOi8vZ2l0aHViLmNvbS9taWNoYWVsYW1wci9qYW0gLS0+CjxzdmcgZmlsbD0iI2ZmZmZmZiIgd2lkdGg9IjgwMHB4IiBoZWlnaHQ9IjgwMHB4IiB2aWV3Qm94PSItOCAtNSAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiIgY2xhc3M9ImphbSBqYW0tY2hldnJvbi1sZWZ0Ij48cGF0aCBkPSdNMi43NTcgN2w0Ljk1IDQuOTVhMSAxIDAgMSAxLTEuNDE0IDEuNDE0TC42MzYgNy43MDdhMSAxIDAgMCAxIDAtMS40MTRMNi4yOTMuNjM2QTEgMSAwIDAgMSA3LjcwNyAyLjA1TDIuNzU3IDd6JyAvPjwvc3ZnPg==');
	}

.obj_news_carousel_hero_home .obj_carousel .slick-next:before{
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBNSVQuIE1hZGUgYnkgbWljaGFlbGFtcHI6IGh0dHBzOi8vZ2l0aHViLmNvbS9taWNoYWVsYW1wci9qYW0gLS0+CjxzdmcgZmlsbD0iI2ZmZmZmZiIgd2lkdGg9IjgwMHB4IiBoZWlnaHQ9IjgwMHB4IiB2aWV3Qm94PSItOCAtNSAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiIgY2xhc3M9ImphbSBqYW0tY2hldnJvbi1yaWdodCI+PHBhdGggZD0nTTUuMzE0IDcuMDcxbC00Ljk1LTQuOTVBMSAxIDAgMCAxIDEuNzc4LjcwN2w1LjY1NyA1LjY1N2ExIDEgMCAwIDEgMCAxLjQxNGwtNS42NTcgNS42NTdhMSAxIDAgMCAxLTEuNDE0LTEuNDE0bDQuOTUtNC45NXonIC8+PC9zdmc+');
	}
.obj_news_carousel_hero_home .obj_carousel .slick-disabled{
    opacity: .2;
	}

.obj_news_carousel_hero_home .obj_carousel .slick-prev.slick-disabled:hover{
	transform: translateY(-50%); left: var(--aox-10px) !important;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-next.slick-disabled:hover{
	transform: translateY(-50%); right: var(--aox-10px) !important;
	}

@media screen and (max-width: 767px){

	.obj_news_carousel_hero_home { 
		height: calc( (100vw - var(--aox-external-gutter) - var(--aox-external-gutter)) * (150/650) );
		margin-bottom: var(--aox-2px);
		animation: effectNewsCarouselHeroHome 1s ease 400ms forwards;
	}
	.obj_news_carousel_hero_home > *{
		overflow: visible;
		}
	.obj_news_carousel_hero_home .obj_item {
		height: calc( (100vw - var(--aox-external-gutter) - var(--aox-external-gutter)) * (150/650) );
		}
	.obj_news_carousel_hero_home .obj_item .ctn_image {
		width: 100%;
		position: static !important;
    	max-width: none !important;
		}
	.obj_news_carousel_hero_home .obj_item .ctn_image img{
		position: absolute;
        top: 50%;
        left: 50%;
        height: auto;
        /* max-height: 100px; */
        width: 100%;
        max-width: none;
        transform: translate(-50%, -50%);
		}
		
	.obj_news_carousel_hero_home .obj_carousel .slick-prev, .obj_carousel .slick-prev.slick-disabled {
		left: calc(-1* var(--aox-12px)); 
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-next, .obj_carousel .slick-next.slick-disabled {
		right: calc(-1* var(--aox-12px));
		}

	.obj_news_carousel_hero_home .obj_carousel .slick-dots {
		display: flex !important;
        left: 50%;
        bottom: var(--aox-5px);
        width: calc(100% - var(--aox-10px));
        transform: translateX(-50%);
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-dots li.slick-active {
		width: var(--aox-20px);
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-next,
	.obj_news_carousel_hero_home .obj_carousel .slick-prev {
		width: var(--aox-40px) !important; height: var(--aox-40px) !important;
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-next:before,
	.obj_news_carousel_hero_home .obj_carousel .slick-prev:before {
		background-size: var(--aox-30px);
        width: var(--aox-40px) !important;
        height: var(--aox-40px) !important;
        background-position: center;
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-prev,
	.obj_news_carousel_hero_home .obj_carousel .slick-prev.slick-disabled:hover { left: calc(-1* var(--aox-10px)) !important; }
	.obj_news_carousel_hero_home .obj_carousel .slick-next,
	.obj_news_carousel_hero_home .obj_carousel .slick-next.slick-disabled:hover {right: calc(-1* var(--aox-10px)) !important;}

	}