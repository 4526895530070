/* ======================================== */
/* .obj_lst_classement_exp
/* ======================================== */

.obj_lst_classement_exp .item_classement_exp{
	position: relative;
    display: flex;
    width: var(--aox-315px);
    height: var(--aox-46px);
    margin: 0 auto;
	border-radius: var(--aox-35px);
	padding: var(--aox-6px) var(--aox-14px) var(--aox-6px) var(--aox-6px);
    text-align: left;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
	background-color: #FFFFFF;
	}
.obj_lst_classement_exp .item_classement_exp + .item_classement_exp{
	margin-top: var(--aox-2px);
	}

.obj_lst_classement_exp .ctn_image{
	position: relative;
	display: block;
	width: var(--aox-36px);
	height: var(--aox-36px);
	}
.obj_lst_classement_exp .item_classement_exp:first-child .ctn_image,
.obj_lst_classement_exp .item_classement_exp:nth-child(2) .ctn_image,
.obj_lst_classement_exp .item_classement_exp:nth-child(3) .ctn_image{
	width: var(--aox-32px); height: var(--aox-32px);
	}

.obj_lst_classement_exp .item_classement_exp.current.var_vip {
	padding: var(--aox-10px) var(--aox-14px) var(--aox-10px) var(--aox-10px);
	}
.obj_lst_classement_exp .item_classement_exp:first-child.current .ctn_image,
.obj_lst_classement_exp .item_classement_exp:nth-child(2).current .ctn_image,
.obj_lst_classement_exp .item_classement_exp:nth-child(3).current .ctn_image{
	width: var(--aox-48px); height: var(--aox-48px);
	}
.obj_lst_classement_exp .item_classement_exp:first-child.current .ctn_image:after,
.obj_lst_classement_exp .item_classement_exp:nth-child(2).current .ctn_image:after,
.obj_lst_classement_exp .item_classement_exp:nth-child(3).current .ctn_image:after{
	right: calc(-1 * var(--aox-6px));
	top: calc(-1 * var(--aox-6px));
	}

.obj_lst_classement_exp .ctn_image img{
	border-radius: 50%;
	width: 100%;
    height: 100%;
    object-fit: cover;
	}
.obj_lst_classement_exp .ctn_text{
	display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: calc(100% - var(--aox-50px));
	}
.obj_lst_classement_exp .ctn_text > * + *{
	margin-left: var(--aox-10px);
	}
.obj_lst_classement_exp .ctn_text .t_place_exp{
	font-weight: 700;
    font-size: var(--aox-fs-20px);
    line-height: var(--aox-lh-100);
	color: currentColor;
	}
.obj_lst_classement_exp .ctn_text .t_place_exp sup{
	font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-100);
    top: -0.45em;
	}

.obj_lst_classement_exp .item_classement_exp:first-child .t_place_exp{ color: #CEAB65; }
.obj_lst_classement_exp .item_classement_exp:nth-child(2) .t_place_exp{ color: #949A9C; }
.obj_lst_classement_exp .item_classement_exp:nth-child(3) .t_place_exp{ color: #986E5F; }

.obj_lst_classement_exp .item_classement_exp:first-child .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
    transform: translate(-50%, -50%);
	height: var(--aox-42px);
	width: var(--aox-42px);
	background: url(../../images/i_medal_gold.svg) center center / var(--aox-42px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(2) .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
    transform: translate(-50%, -50%);
	height: var(--aox-42px);
	width: var(--aox-42px);
	background: url(../../images/i_medal_silver.svg) center center / var(--aox-42px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(3) .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
    transform: translate(-50%, -50%);
	height: var(--aox-42px);
	width: var(--aox-42px);
	background: url(../../images/i_medal_bronze.svg) center center / var(--aox-42px) auto no-repeat;
	}

.obj_lst_classement_exp .item_classement_exp.current .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
    transform: translate(-50%, -50%);
	height: var(--aox-66px); width: var(--aox-66px);
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(1).current .ctn_image:before {
	background: url(../../images/i_medal_gold.svg) center center / var(--aox-66px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(2).current .ctn_image:before {
	background: url(../../images/i_medal_silver.svg) center center / var(--aox-66px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(3).current .ctn_image:before {
	background: url(../../images/i_medal_bronze.svg) center center / var(--aox-66px) auto no-repeat;
	}

.obj_lst_classement_exp .item_classement_exp.is_vip .ctn_image:before {
	background: url(../../images/i_medal_vip.svg) center center / var(--aox-66px) auto no-repeat;
	}


.obj_lst_classement_exp .ctn_text .t_name_exp{
	font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
	padding-right: var(--aox-10px);
	font-weight: 400;
	}
.obj_lst_classement_exp .ctn_text .t_nbre_points_exp{
	font-size: var(--aox-fs-14px);
	line-height: var(--aox-lh-120);
	font-weight: 700;
    text-transform: uppercase;
	margin-left: auto;
	}
.obj_lst_classement_exp .ctn_text .t_nbre_points_exp em{
	font-size: var(--aox-fs-8px);
	margin-left: var(--aox-2px);
	}


/***/

.obj_lst_classement_exp .item_classement_exp.current{
	width: var(--aox-320px);
	height: var(--aox-68px);
	padding: var(--aox-6px) var(--aox-14px) var(--aox-6px) var(--aox-6px);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_image {
	width: var(--aox-54px);
	height: var(--aox-54px);
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_image:after {
	content: "";
	position: absolute;
	right: calc(-1 * var(--aox-2px)); top: calc(-1 * var(--aox-2px));
	height: var(--aox-20px);
	width: var(--aox-20px);
	}
.obj_lst_classement_exp .item_classement_exp.current.var_up .ctn_image:after {
	background: url(../../images/i_arrow_up_classement.svg) center center / var(--aox-20px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp.current.var_down .ctn_image:after {
	background: url(../../images/i_arrow_down_classement.svg) center center / var(--aox-20px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_text {
	width: calc(100% - var(--aox-64px));
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_text .t_place_exp {
	font-size: var(--aox-fs-32px);
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_text .t_place_exp sup {
	font-size: var(--aox-fs-20px);
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_text .t_nbre_points_exp {
	font-size: var(--aox-fs-20px);
	}

/***/
.obj_lst_classement_exp .item_classement_exp.current.var_vip {
    padding: var(--aox-10px) var(--aox-14px) var(--aox-10px) var(--aox-10px);
	}
.obj_lst_classement_exp .item_classement_exp.current.var_vip .ctn_image {
    width: var(--aox-48px);
    height: var(--aox-48px);
	}
.obj_lst_classement_exp .item_classement_exp.current.var_vip .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
	transform: translate(-50%, -50%);
	height: var(--aox-66px);
	width: var(--aox-66px);
	background: url(../../images/i_vip_lrp.svg) center center / var(--aox-66px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp.current.var_vip .ctn_image:after {
	right: calc(-1 * var(--aox-4px)); top: calc(-1 * var(--aox-4px));
	transform: none; left: unset;
	}
.obj_lst_classement_exp .item_classement_exp.current.var_vip .ctn_text {
	width: calc(100% - var(--aox-72px));
	}





/***/
/* GENERATOR */
.obj_lst_classement_exp .ctn_text .t_tag span{
	background-color: rgba(0, 172, 194, 0.15);
	}

/***/


@media screen and (max-width:767px){

	.obj_lst_classement_exp .item_classement_exp{
		width: calc(100% - var(--aox-8px));
		}
	.obj_lst_classement_exp .item_classement_exp.current{
		width: 100%;
		}

}
