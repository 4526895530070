/* -------------------------------------------------- */

/*   $ > AOX Font-size (v2.0.1) - 04/04/23

/* -------------------------------------------------- */

/*** Calculations ***/

:root {
	--aox-fs-resize: calc(
		(var(--aox-fs-viewport-width) - (var(--aox-fs-min-width) * 1px)) /
		(var(--aox-fs-max-width) - var(--aox-fs-min-width))
		);
	}

/*** Sizes ***/

:root {

	--aox-fs-8px: calc(0.4375rem + (8 - 7) * var(--aox-fs-resize));
	--aox-fs-9px: calc(0.5rem + (9 - 8) * var(--aox-fs-resize));
	--aox-fs-10px: calc(0.5625rem + (10 - 9) * var(--aox-fs-resize));
	--aox-fs-11px: calc(0.5625rem + (11 - 9) * var(--aox-fs-resize));
	--aox-fs-12px: calc(0.625rem + (12 - 10) * var(--aox-fs-resize));
	--aox-fs-13px: calc(0.6875rem + (13 - 11) * var(--aox-fs-resize));
	--aox-fs-14px: calc(0.75rem + (14 - 12) * var(--aox-fs-resize));
	--aox-fs-15px: calc(0.8125rem + (15 - 13) * var(--aox-fs-resize));
	--aox-fs-16px: calc(0.875rem + (16 - 14) * var(--aox-fs-resize));
	--aox-fs-17px: calc(0.875rem + (17 - 14) * var(--aox-fs-resize));
	--aox-fs-18px: calc(0.9375rem + (18 - 15) * var(--aox-fs-resize));
	--aox-fs-19px: calc(1rem + (19 - 16) * var(--aox-fs-resize));
	--aox-fs-20px: calc(1.0625rem + (20 - 17) * var(--aox-fs-resize));

	--aox-fs-22px: calc(1.125rem + (22 - 18) * var(--aox-fs-resize));
	--aox-fs-24px: calc(1.1875rem + (24 - 19) * var(--aox-fs-resize));
	--aox-fs-26px: calc(1.3125rem + (26 - 21) * var(--aox-fs-resize));
	--aox-fs-28px: calc(1.375rem + (28 - 22) * var(--aox-fs-resize));
	--aox-fs-30px: calc(1.5rem + (30 - 24) * var(--aox-fs-resize));

	--aox-fs-32px: calc(1.5rem + (32 - 24) * var(--aox-fs-resize));
	--aox-fs-34px: calc(1.625rem + (34 - 26) * var(--aox-fs-resize));
	--aox-fs-36px: calc(1.6875rem + (36 - 27) * var(--aox-fs-resize));
	--aox-fs-38px: calc(1.8125rem + (38 - 29) * var(--aox-fs-resize));
	--aox-fs-40px: calc(1.875rem + (40 - 30) * var(--aox-fs-resize));
	--aox-fs-42px: calc(2rem + (42 - 32) * var(--aox-fs-resize));
	--aox-fs-44px: calc(2.0625rem + (44 - 33) * var(--aox-fs-resize));
	--aox-fs-46px: calc(2.1875rem + (46 - 35) * var(--aox-fs-resize));
	--aox-fs-48px: calc(2.25rem + (48 - 36) * var(--aox-fs-resize));
	--aox-fs-50px: calc(2.3725rem + (50 - 38) * var(--aox-fs-resize));
	
	--aox-fs-60px: calc(2.625rem + (60 - 42) * var(--aox-fs-resize));
	--aox-fs-70px: calc(3.0625rem + (70 - 49) * var(--aox-fs-resize));
	--aox-fs-80px: calc(3.3rem + (80 - 56) * var(--aox-fs-resize));
	--aox-fs-90px: calc(3.9375rem + (90 - 63) * var(--aox-fs-resize));
	--aox-fs-100px: calc(4.375rem + (100 - 70) * var(--aox-fs-resize));

	--aox-lh-50: 0.5;
	--aox-lh-60: 0.6;
	--aox-lh-70: 0.7;
	--aox-lh-80: 0.8;
	--aox-lh-90: 0.9;
	--aox-lh-100: 1;
	--aox-lh-110: 1.1;
	--aox-lh-120: 1.2;
	--aox-lh-130: 1.3;
	--aox-lh-140: 1.4;
	--aox-lh-150: 1.5;
	--aox-lh-160: 1.6;
	--aox-lh-170: 1.7;
	--aox-lh-180: 1.8;
	--aox-lh-190: 1.9;
	--aox-lh-200: 2;

	}

/*** Utilities ***/

:root .has_accents {
	--aox-lh-50: 1;
	--aox-lh-60: 1;
	--aox-lh-70: 1;
	--aox-lh-80: 1;
	--aox-lh-90: 1;
	}

small {
	font-size: 0.85em;
	}

[data-aox-lc] {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	}
[data-aox-lc="1"] { -webkit-line-clamp: 1; }
[data-aox-lc="2"] { -webkit-line-clamp: 2; }
[data-aox-lc="3"] { -webkit-line-clamp: 3; }
[data-aox-lc="4"] { -webkit-line-clamp: 4; }
[data-aox-lc="5"] { -webkit-line-clamp: 5; }
[data-aox-lc="6"] { -webkit-line-clamp: 6; }
[data-aox-lc="7"] { -webkit-line-clamp: 7; }
[data-aox-lc="8"] { -webkit-line-clamp: 8; }
[data-aox-lc="9"] { -webkit-line-clamp: 9; }
[data-aox-lc="10"] { -webkit-line-clamp: 10; }