/* ======================================== */
/* .obj_push-event
/* ======================================== */
.obj_push-event{
	position:relative;
	margin:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	width:calc(100% - var(--aox-60px)); height:var(--aox-360px);
	border:1px solid #c7c7c7;
	background:#e6e6e6;
	}
.obj_push-event.var_dark{
	background:#000;
	}
.obj_push-event figure{
	position:absolute;
	top:50%; left:50%;
	width:100%; height:100%;
	transform:translate(-50%,-50%);
	overflow:hidden;
	}
.obj_push-event figure img{
	width:var(--aox-1280px); height:var(--aox-360px);
	}
.obj_push-event .ctn_text{
	position:relative;
	z-index:3;
	padding:var(--aox-60px) 12% var(--aox-45px) 12%;
	}
.obj_push-event.var_left .ctn_text{
	text-align:left;
	}
.obj_push-event.var_center .ctn_text{
	text-align:center;
	}
.obj_push-event.var_right .ctn_text{
	text-align:right;
	}
.obj_push-event .ctn_text h2{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-30px); line-height:1;
	font-weight:700;
	}
.obj_push-event .ctn_text h3{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-18px); line-height:1.2;
	text-transform:uppercase;
	}
.obj_push-event .ctn_text p{
	margin-bottom:var(--aox-15px);
	font-size:var(--aox-fs-18px); line-height:1.8;
	}
.obj_push-event .ctn_text h2 strong,
.obj_push-event .ctn_text h3 strong,
.obj_push-event .ctn_text p strong{
	font-weight:700;
	}
.obj_push-event.var_dark .ctn_text h2,
.obj_push-event.var_dark .ctn_text h3,
.obj_push-event.var_dark .ctn_text p{
	color:#fff;
	}
/**/
@media screen and (max-width:1340px){
	.obj_push-event{
		width:calc(100vw - 4.4vw);
		margin:4.5vw 2.2vw 0 2.2vw;
		}
	.obj_push-event .ctn_text h2{
		font-size:var(--aox-fs-30px);
		}
	.obj_push-event .ctn_text h3,
	.obj_push-event .ctn_text p{
		font-size:var(--aox-fs-18px);
		}
}
@media screen and (max-width:767px){
	.obj_push-event{
		width:100% !important; height:auto;
		margin:var(--aox-40px) 0 0 0;
		}
	.obj_push-event .ctn_text{
		padding:var(--aox-40px) var(--aox-20px) var(--aox-25px) var(--aox-20px);
		}
	.obj_push-event .ctn_text h2{
		font-size:var(--aox-fs-24px);
		}
	.obj_push-event .ctn_text h3{
		font-size:var(--aox-fs-16px);
		}
	.obj_push-event figure img{
		width:calc(var(--aox-410px) + var(--aox-4px)); height:var(--aox-370px);
		}
}