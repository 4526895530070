/* ======================================== */
/* .pg_404
/* ======================================== */
.pg_404.is-not-logged .main_container{
	padding-top:var(--aox-80px);
	}
.pg_404 .ctn_cta{
	width:100%;
	padding-bottom:var(--aox-60px);
	text-align:center;
	}

@media screen and (min-width:768px){
	.pg_404 .main_header.mode_mini{
		transform:translateY(0);
		}
}