/* ======================================== */
/* .section_page-liste
/* ======================================== */
.section_page-liste{
	width:100%;
	}
.section_page-liste .str_container{
	max-width:var(--aox-1150px);
	}
.section_page-liste .ctn_row{
	margin-bottom:var(--aox-40px);
	}
.section_page-liste .ctn_row > h2{
	margin-bottom:var(--aox-50px);
	padding:0 var(--aox-50px) var(--aox-20px) var(--aox-50px);
	font-size:var(--aox-fs-32px); line-height:1.2;
	border-bottom:1px solid #c3c3c3;
	}
.section_page-liste .ctn_row > h2 strong{
	font-weight:700;
	}
.section_page-liste .ctn_sub-row{
	display: flex;
    flex-flow: row wrap;
    margin-right: calc(-1 * var(--aox-30px));
	}
.section_page-liste .ctn_sub-row.lvl-1,
.section_page-liste .ctn_sub-row.lvl-2{
	display:none;
	}
.section_page-liste .ctn_sub-row.lvl-1.active,
.section_page-liste .ctn_sub-row.lvl-2.active{
	display:flex;
	}
.section_page-liste .ctn_sub-row > .obj_back-page{
	margin-top:0;
	max-width:var(--aox-1050px);
	}
.section_page-liste .ctn_sub-row > h3{
	padding:var(--aox-30px) 0 var(--aox-60px) 0;
	width:100%;
	font-size:var(--aox-fs-32px); line-height:1.2;
	font-weight:700;
	text-align:center;
	}
.section_page-liste .ctn_sub-row > .ctn_cta{
	margin-top:var(--aox-60px);
	padding-top:var(--aox-60px);
	width:100%;
    text-align:center;
    border-top:1px solid #c3c3c3;
	}
.section_page-liste .ctn_page-liste{
	width:33.33%;
	margin-bottom:var(--aox-50px);
	}
.section_page-liste .ctn_page-liste a,
.section_page-liste .ctn_page-liste button{
	display:block;
	width:100%;
	padding:0 var(--aox-15px);
	text-align:left;
	}
.section_page-liste .ctn_page-liste figure{
	position:relative;
	height:0;
	padding-bottom:73%;
	overflow:hidden;
	}
.section_page-liste .ctn_page-liste figure img{
	position:absolute;
	left:0; top:0;
	z-index:1;
	width:100%; height:100%;
	transition:all .3s ease;
	object-fit:cover;
	}
.section_page-liste .ctn_page-liste .ctn_page-liste-visuel{
	position:relative;
	width: 100%;
	height: auto;
	}
.section_page-liste .ctn_page-liste .lazyload-placeholder{
	height:0;
	padding-bottom:73%;
	}
.section_page-liste .ctn_page-liste h3{
	padding-top:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1.1;
	font-weight:700;
	}
.section_page-liste .ctn_page-liste.var-fb h3{
	padding-left:var(--aox-50px);
	position: relative;
	min-height: var(--aox-60px);
	}	
.section_page-liste .ctn_page-liste.var-fb h3:before{
	content: "";
    position: absolute;
    left: 0; top: var(--aox-20px);
    height: var(--aox-40px); width: var(--aox-40px);
    background: url(../../images/i_facebook-picto.svg) left top / var(--aox-40px) auto no-repeat;
	}	
.section_page-liste .ctn_page-liste .t_desc{
	padding-top: var(--aox-10px);
    font-size:var(--aox-fs-14px); line-height: 1.57143;
    font-weight: 300;
	}	
.section_page-liste .ctn_page-liste .obj_likes-comments{
	justify-content:flex-start;
	margin:var(--aox-20px) 0 0 0;
	}
.section_page-liste .ctn_page-liste .obj_likes-comments li{
	padding:var(--aox-3px) 0 0 var(--aox-25px);
	margin-right:var(--aox-20px);
	font-size:var(--aox-fs-11px);
	}
.section_page-liste .ctn_page-liste .obj_likes-fb{
	justify-content:flex-start;
	margin:var(--aox-20px) 0 0 0;
	}
.section_page-liste .ctn_page-liste .obj_likes-fb li{
	padding:var(--aox-3px) 0 0 var(--aox-25px);
	margin-right:var(--aox-20px);
	font-size:var(--aox-fs-11px);
	}	
.section_page-liste .ctn_page-liste .ctn_cta{
	margin-top:var(--aox-15px);
	}
.section_page-liste .ctn_page-liste.var_video figure::before,
.section_page-liste .ctn_page-liste.var_video figure::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	}
.section_page-liste .ctn_page-liste.var_video figure::before{
	width:var(--aox-88px); height:var(--aox-88px);
	border:4px solid #fff;
	border-radius:50%;
	transform:translate(-50%,-50%);
	}
.section_page-liste .ctn_page-liste.var_video figure::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-16px) 0 var(--aox-16px) var(--aox-28px);
	border-color:transparent transparent transparent #fff;
	transform:translate(-40%,-50%);
	}
.section_page-liste .ctn_page-liste.is-not-available button{
	cursor:default;
	}
.section_page-liste .ctn_page-liste.is-not-available figure img{
	filter:grayscale(1);
	}
.section_page-liste .obj_push-event{
	margin:var(--aox-30px) auto var(--aox-120px) auto;
	width:100%; max-width:var(--aox-1050px);
	}

.section_page-liste .obj_tabs-list .ctn_nav-tabs {
	margin-bottom: 0;
	}

/**/
@media screen and (min-width:1025px){
	.section_page-liste .ctn_page-liste a:hover figure img,
	.section_page-liste .ctn_page-liste button:hover figure img{
		transform:scale(1.05);
		}
	.section_page-liste .ctn_page-liste.is-not-available a:hover figure img,
	.section_page-liste .ctn_page-liste.is-not-available button:hover figure img{
		transform:scale(1);
		}
	.section_page-liste .ctn_page-liste a:hover .obj_cta-download,
	.section_page-liste .ctn_page-liste button:hover .obj_cta-download{
		color:#777 !important;
		}
	.section_page-liste .ctn_page-liste a:hover .obj_cta-download svg,
	.section_page-liste .ctn_page-liste button:hover .obj_cta-download svg{
		fill:#777 !important;
		}
}
@media screen and (max-width:1150px){
	.section_page-liste .ctn_row{
		padding:0 var(--aox-20px);
		}
}
@media screen and (max-width:768px){
	.section_page-liste .ctn_page-liste{
		width:50%;
		}
}
@media screen and (max-width:767px){
	.section_page-liste .ctn_row > h2{
		margin-bottom:var(--aox-40px);
		padding-left:var(--aox-30px);
		padding-right:var(--aox-30px);
		font-size:var(--aox-fs-28px);
		}
	.section_page-liste .ctn_row{
		padding:0;
		margin-bottom:var(--aox-30px);
		}
	.section_page-liste .ctn_sub-row{
		padding:0;
		margin-right:0;
		}
	.section_page-liste .ctn_page-liste{
		width:100%;
		margin-bottom:var(--aox-40px);
		}
	.section_page-liste .ctn_page-liste a,
	.section_page-liste .ctn_page-liste button{
		padding:0;
		}
	.section_page-liste .ctn_page-liste.var_video figure::before{
		width:var(--aox-78px); height:var(--aox-78px);
		}
	.section_page-liste .ctn_page-liste.var_video figure::after{
		border-width:var(--aox-14px) 0 var(--aox-14px) var(--aox-24px);
		}
	.section_page-liste .ctn_sub-row > .obj_back-page{
		padding:0;
		}
	.section_page-liste .ctn_sub-row > h3{
		padding-top:var(--aox-15px);
		}
	.section_page-liste .obj_push-event{
		margin-bottom:var(--aox-70px);
		}
}


/***/




.section_page-liste .ctn_text .bt_fav-formation {
	position: absolute;
	top: calc(-1 * var(--aox-10px));
    right: 0;
    display: flex;
    width: var(--aox-35px);
    height: var(--aox-35px);
    cursor: pointer;
    align-items: center;
    justify-content: center;
	cursor: pointer;
	}
.section_page-liste .ctn_text .bt_fav-formation svg {
	fill: #C3C3C3;
	}
.section_page-liste .ctn_text .bt_fav-formation:disabled svg {
	fill: rgb(195 195 195 / 35%);
	}
.section_page-liste .obj_formation_mini_parcours.is-not-available .ctn_text .bt_fav-formation {
	cursor: default;
	}

/***/

.section_page-liste .ctn_text .t_tag {
	position: relative;
	display: flex;
	margin-bottom: var(--aox-5px);

	animation-duration: 3s;
	animation-name: tag_clignotement;
	animation-iteration-count: infinite;
	transition: none;
	}
@keyframes tag_clignotement {
	0%   { opacity:0; }
	60%   {opacity:1; }
	100% { opacity:0; }
	}
.section_page-liste .ctn_text .t_tag span{
	display: inline-block;
    font-weight: 500;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-110);
    text-transform: uppercase;
    padding: var(--aox-3px) var(--aox-9px);
    background-color: #f2f2f2;
	color: #ffffff;
	}
.section_page-liste .ctn_text .obj_nb_view {
	position: relative;
	display: flex;
	margin-top: var(--aox-5px);
	}
.section_page-liste .ctn_text .obj_nb_view svg{
	fill: #c3c3c3;
	margin-right: var(--aox-5px);
	}
.section_page-liste .ctn_text .obj_nb_view span{
	display: block;
	font-weight: 400;
	font-size: var(--aox-fs-11px);
	line-height: var(--aox-lh-110);
	color: #777777;
	}


/***/

.section_page-liste .section_mini_parcours{
	padding: var(--aox-60px) 0 0;
	}


/** .section_list_formation */

.section_page-liste .obj_tabs-list .section_list_formation{
	padding: 0;
    max-width: var(--aox-1050px);
    margin: 0 auto;
	padding-top: var(--aox-60px);
	}
.section_page-liste .section_list_formation .ctn_sub-row .ctn_page-liste{
	position: relative;
	width: var(--aox-330px);
	margin: 0 var(--aox-30px) var(--aox-50px) 0;
	}
.section_page-liste .section_list_formation .ctn_sub-row .ctn_page-liste button {
	padding: 0;
	}
.section_page-liste .section_list_formation .ctn_page-liste .ctn_text {
	position: relative;
    min-height: var(--aox-35px);
    padding-right: var(--aox-53px);
    margin-top: var(--aox-15px);
	}
.section_page-liste .section_list_formation .ctn_page-liste .ctn_text h3 {
	padding-top: 0;
	text-transform: uppercase;
	}
.section_page-liste .section_list_formation .ctn_page-liste .ctn_text .bt_fav-formation {
	width: var(--aox-53px);
	height: var(--aox-53px);
	}



/***/

@media screen and (max-width:767px){

	.section_page-liste .section_list_formation .ctn_sub-row .ctn_page-liste {
		width: 100%;
		margin: 0 0 var(--aox-30px);
		}


	}

/***/

/* section_favoris */
.obj_section.section_favoris .ctn_section .ctn_title,
.obj_section.section_favoris .ctn_section .ctn_title .t_title{
	margin-bottom: var(--aox-15px);
	}
.section_favoris .section_list_formation{
	position: relative;
    width: 100%;
    padding: 0 var(--aox-110px);
    border-top: 1px solid rgba(0,0,0,0.5);
	}
.section_favoris .section_list_formation .ctn_brand{
	margin-top: var(--aox-60px);
	}
.section_favoris .section_list_formation .ctn_brand .ctn_title{
	margin-bottom: var(--aox-40px);
	}
.section_favoris .section_list_formation .ctn_brand .ctn_title .t_title{
	font-weight: 700;
	font-family :Montserrat, sans-serif;
    font-size: var(--aox-fs-32px);
    line-height: var(--aox-lh-100);
	width: var(--aox-550px);
	text-transform: uppercase;
	}
.section_favoris .section_list_formation .ctn_brand .ctn_sub-row{
	padding: 0;
	width: calc(100% + var(--aox-30px));
	}



/***/

@media screen and (max-width:767px){

	.section_favoris .section_list_formation{
		padding: 0;
		}
	.section_favoris .section_list_formation .ctn_brand {
		margin-top: var(--aox-30px);
		}
	.section_favoris .section_list_formation .ctn_brand .ctn_title {
		margin-bottom: var(--aox-20px);
		}
	.section_favoris .section_list_formation .ctn_brand .ctn_sub-row {
		width: 100%;
		}
	.section_favoris .section_list_formation .ctn_brand .ctn_title .t_title{
		font-size: var(--aox-fs-26px);
		line-height: var(--aox-lh-100);
		width: 100%;
		}

	}
