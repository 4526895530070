/* ======================================== */
/* .obj_brand_hero_home{
/* ======================================== */

.obj_brand_hero_home{
	position: relative;
	width: var(--aox-480px);
	}
.obj_brand_hero_home .ctn_brand_hero_home{
	position: relative;
	}
.obj_brand_hero_home .ctn_brand_hero_home ul{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	}
.obj_brand_hero_home .obj_item_brand_hero{
	position: relative;
	width: calc(var(--aox-230px) + var(--aox-9px));
	height: var(--aox-140px);
	overflow: hidden;
	margin-bottom: var(--aox-2px);
	display: block;
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(10px);
    opacity: 0;
	}
	
.obj_brand_hero_home .obj_item_brand_other_hero{
	width: var(--aox-480px);
    height: var(--aox-80px);
	transition: all .3s ease;
	opacity: 0;
	display: block;
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(10px);
	}
.obj_brand_hero_home .obj_item_brand_other_hero:hover{
	background-color: rgba(255, 255, 255, 1);
	}
.obj_brand_hero_home .obj_item_brand_hero.var_lrp{ animation:effectHeroCaseBrands 1s ease 700ms forwards; }
.obj_brand_hero_home .obj_item_brand_hero.var_vichy{ animation:effectHeroCaseBrands 1s ease 800ms forwards; }
.obj_brand_hero_home .obj_item_brand_hero.var_cerave{ animation:effectHeroCaseBrands 1s ease 900ms forwards; }
.obj_brand_hero_home .obj_item_brand_hero.var_skinc{ animation:effectHeroCaseBrands 1s ease 1000ms forwards; }
.obj_brand_hero_home .obj_item_brand_other_hero{ animation:effectHeroCaseBrands 1s ease 1100ms forwards; }
	
.obj_brand_hero_home .obj_item_brand_hero:nth-child(even){
	margin-left: var(--aox-2px);
	}
.obj_brand_hero_home .obj_item_brand_hero a{
	display: block;
	}
.obj_brand_hero_home .obj_item_brand_hero .ctn_text{
	position: relative;
    width: calc(var(--aox-230px) + var(--aox-9px));
    height: var(--aox-140px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
	}

/* .obj_brand_hero_home .obj_item_brand_hero.var_lrp .ctn_logo{ padding-top: var(--aox-30px); }	 */
.obj_brand_hero_home .obj_item_brand_hero.var_lrp .ctn_logo img{ height: var(--aox-40px); }

/* .obj_brand_hero_home .obj_item_brand_hero.var_vichy .ctn_logo{ padding-top: var(--aox-35px); } */
.obj_brand_hero_home .obj_item_brand_hero.var_vichy .ctn_logo img{ height: var(--aox-32px); }

/* .obj_brand_hero_home .obj_item_brand_hero.var_cerave .ctn_logo{ padding-top: var(--aox-25px); } */
.obj_brand_hero_home .obj_item_brand_hero.var_cerave .ctn_logo img{ height: var(--aox-43px); }

/* .obj_brand_hero_home .obj_item_brand_hero.var_skinc .ctn_logo{ padding-top: var(--aox-36px); } */
.obj_brand_hero_home .obj_item_brand_hero.var_skinc .ctn_logo img{ height: var(--aox-25px); }

.obj_brand_hero_home .obj_item_brand_hero .ctn_logo{
	position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition-delay: .05s;
    transition-duration: .2s;
    transition-property: transform,opacity;
    transition-timing-function: cubic-bezier(.645,.045,.355,1);
    z-index: 2;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
	}


.obj_brand_hero_home .obj_item_brand_hero .t_title {
	position: absolute;
	bottom: 35%;
	font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-100);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
	opacity: 0;
	transform: translateY(var(--aox-25px));
	transition-duration: .25s;
	transition-property: transform,opacity;
	transition-timing-function: cubic-bezier(.645,.045,.355,1);
	z-index: 2;
	}


/***/
.obj_brand_hero_home .obj_item_brand_hero a:hover {
	background-color: rgba(255, 255, 255, 1);
	}
.obj_brand_hero_home .obj_item_brand_hero a:hover .ctn_logo {
	transform: translateY(-10px);
	transition-delay: 0s;
	transition-duration: .25s;
	opacity: 0;
	}
.obj_brand_hero_home .obj_item_brand_hero a:hover .t_title {
	opacity: 1;
	transform: translateY(-100%);
	transition-delay: .05s;
	transition-duration: .2s;
	}



@media screen and (max-width:767px){

	.obj_brand_hero_home {
		width: 100%;
		}
	.obj_brand_hero_home .obj_item_brand_hero {
		width: calc((50%) - 1px);
		height: var(--aox-90px);
		}
	.obj_brand_hero_home .obj_item_brand_hero:nth-child(even){
		margin-left: 2px;
		}
	.obj_brand_hero_home .obj_item_brand_hero .ctn_text{
		width: 100%;
		height: var(--aox-90px);
		justify-content: center;
		}
	/* .obj_brand_hero_home .obj_item_brand_hero .ctn_logo{
		padding-top: 0;
		width:auto;
		height: auto;
		top: unset;
		} */
	.obj_brand_hero_home .obj_item_brand_hero.var_lrp .ctn_logo,
	.obj_brand_hero_home .obj_item_brand_hero.var_skinc .ctn_logo,
	.obj_brand_hero_home .obj_item_brand_hero.var_vichy .ctn_logo,
	.obj_brand_hero_home .obj_item_brand_hero.var_cerave .ctn_logo{ 
		padding-top: 0;
	}	

	.obj_brand_hero_home .obj_item_brand_hero.var_lrp .ctn_logo img{ height: var(--aox-30px); }
	.obj_brand_hero_home .obj_item_brand_hero.var_vichy .ctn_logo img{ height: var(--aox-24px); }
	.obj_brand_hero_home .obj_item_brand_hero.var_cerave .ctn_logo img{ height: var(--aox-32px); }
	.obj_brand_hero_home .obj_item_brand_hero.var_skinc .ctn_logo img{ height: var(--aox-20px); }


	.obj_brand_hero_home .obj_item_brand_hero .t_title {
		bottom: 25%;
    	font-size: var(--aox-fs-13px);
		}

	.obj_brand_hero_home .obj_item_brand_other_hero {
		width: 100%;
		height: var(--aox-55px);
		background-color: transparent;
		}

}