/* ======================================== */
/* .section_brand-selection
/* ======================================== */
.section_brand-selection{
	position:relative;
	width:100%;
	}
.section_brand-selection .str_container{
	border-bottom:1px solid #d4d4d4;
	}
.section_brand-selection ul{
	position:absolute;
	left:0; top:0;
	display:flex;
	}
.section_brand-selection ul li{
	flex-grow:1;
	}
.section_brand-selection ul li a{
	display:block;
	}
.section_brand-selection figure{
	position:relative;
	overflow:hidden;
	height:0;
	padding-bottom:164%;
	}
.section_brand-selection figure img{
	position:absolute;
	left:50%; top:50%;
	width:101%;
	transform:translate(-50%,-50%) scale(1);
	transition:all .3s ease;
	}
.section_brand-selection figure .lazyload-placeholder{
	height:28vw;
	}
.section_brand-selection .ctn_logo{
	position:relative;
	display:flex;
	justify-content:center;
	align-items:center;
	height:7.7vw;
	overflow:hidden;
	}
.section_brand-selection .ctn_logo img{
	position:absolute;
	left:50%; top:50%;
	transform:translate(-50%,-50%) scale(0.4);
	}
.section_brand-selection .ctn_cta{
	position:absolute;
	left:0; bottom:0;
	width:100%;
	padding-bottom:2.5vw;
	text-align:center;
	}

.section_brand-selection .ctn_cta-others{
	position:relative;
	display:flex;
	justify-content:center;
	align-items:center;
	height:7.7vw;
	overflow:hidden;
	}
.section_brand-selection .ctn_cta-others div{
	position:absolute;
	left:50%; top:50%;
	padding-top:2.2vw;
	font-size:0.65vw; line-height:1;
	text-align:center;
	text-transform:uppercase;
	font-weight:700;
	transform:translate(-50%,-50%);
	}
.section_brand-selection .ctn_cta-others span{
	position:absolute;
	left:50%; top:0;
	width:1.7vw; height:1.7vw;
	border:1px solid #000;
	transform:translateX(-50%);
	border-radius:100vw;
	}
.section_brand-selection .ctn_cta-others span::before,
.section_brand-selection .ctn_cta-others span::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#000;
	transform:translate(-50%,-50%);
	}
.section_brand-selection .ctn_cta-others span::before{
	width:0.1vw; height:0.75vw;
	}
.section_brand-selection .ctn_cta-others span::after{
	width:0.75vw; height:0.1vw;
	}

/***/
.section_brand-selection .var_others{
	max-width: calc(100% / 8);
	}
.section_brand-selection .var_others .brands_carousel_container{
	position: relative;
	overflow: hidden;
	}
.section_brand-selection .var_others .brands_carousel_container .obj_brand-carousel .slick-arrow{
	display: none !important;
	}

/***/
.section_brand-selection .var_derm-expertise .ctn_cta-others{
	display: none;
	}



@media screen and (min-width:1025px){
	.section_brand-selection ul li a:hover{
		background:rgba(0,0,0,0.05);
		}
	.section_brand-selection ul li a:hover figure img{
		transform:translate(-50%,-50%) scale(1.05);
		}
}
@media screen and (max-width:1440px){
	.section_brand-selection .ctn_logo img{
		transform:translate(-50%,-50%) scale(0.35);
		}
}
@media screen and (max-width:1280px){
	.section_brand-selection ul{
		position:relative;
		left:auto; top:auto;
		}
	.section_brand-selection ul li{
		max-width:33.333%;
		}
	.section_brand-selection .ctn_logo img{
		transform:translate(-50%,-50%) scale(0.3);
		}
	.section_brand-selection .var_others .brands_carousel_container .obj_carousel li{
		max-width: 100%;
		}
}
@media screen and (max-width:1279px){
	.section_brand-selection .ctn_cta{
		position:relative;
		left:auto; bottom:auto;
		padding:var(--aox-30px) 0 var(--aox-50px) 0;
		}
}
@media screen and (max-width:1024px){
	.section_brand-selection .ctn_logo img{
		transform:translate(-50%,-50%) scale(0.25);
		}
}	
@media screen and (max-width:768px){
	.section_brand-selection ul{
		flex-flow:row wrap;
		}
	.section_brand-selection ul li{
		max-width:33.333%;
		width:33.333%;
		}
	.section_brand-selection .ctn_logo img{
		transform:translate(-50%,-50%) scale(0.25);
		}
}
@media screen and (max-width:767px){
	.section_brand-selection {
		margin-left: calc(-1 * var(--aox-gutter));
		width: calc(100% + var(--aox-gutter) + var(--aox-gutter));
		}
	.section_brand-selection figure{
		height:auto;
		padding-bottom:0;
		}
	.section_brand-selection figure > picture{
		height: var(--aox-200px);
		width: 100%;
		display: block;
		}
	.section_brand-selection figure img{
		width:100%;
		height: var(--aox-200px);
		object-fit: cover;
		}
	.section_brand-selection .ctn_logo{
		height:var(--aox-64px);
		}
	.section_brand-selection .ctn_cta{
		padding:var(--aox-20px) 0 var(--aox-40px) 0;
		}
	
	.section_brand-selection ul li.var_others{
		position:relative;
		max-width:100%;
		border-top:1px solid #c7c7c7;
		border-bottom:1px solid #c7c7c7;
		background:#f4f4f4;
		}
	.section_brand-selection .ctn_cta-others{
		position:absolute;
		right:0; top:0;
		width:33.333%; height:100%;
		}
	.section_brand-selection .ctn_cta-others div{
		left:0;
		padding:0 11% 0 33%;
		font-size:var(--aox-fs-10px); line-height:1.2;
		text-align:left;
		transform:translateY(-50%);
		}
	.section_brand-selection .ctn_cta-others span{
		left:var(--aox-8px); top:50%;
		width:var(--aox-24px); height:var(--aox-24px);
		transform:translateY(-50%);
		}
	.section_brand-selection .ctn_cta-others span::before{
		width:2px; height:var(--aox-12px);
		}
	.section_brand-selection .ctn_cta-others span::after{
		width:var(--aox-12px); height:2px;
		}

	.section_brand-selection .var_others .brands_carousel_container .obj_carousel{
		width: 100%;
		}
	.section_brand-selection .var_others .brands_carousel_container .obj_carousel li{
		max-width: 100%;
		}

	/***/
	.section_brand-selection ul li.var_derm-expertise{
		position: relative;
		max-width: 100%;
		width: 100%;
		border-top: 1px solid #c7c7c7;
		border-bottom: 1px solid #c7c7c7;
		background: #f4f4f4;
		}
	.section_brand-selection ul li.var_derm-expertise .ctn_logo{
		display: none;
		}
	.section_brand-selection .var_derm-expertise .ctn_cta-others {
		display: block;
		width: 40%;
		}
	.section_brand-selection .var_derm-expertise .ctn_cta-others div{
		padding:0 11% 0 25%;
		}

	.var_2023.section_brand-selection .var_derm-expertise figure,
	.var_2023.section_brand-selection .var_derm-expertise figure > picture,
	.var_2023.section_brand-selection .var_derm-expertise figure > picture img,
	.var_2023.section_brand-selection .var_others figure,
	.var_2023.section_brand-selection .var_others figure > picture,
	.var_2023.section_brand-selection .var_others figure > picture img{
		height: var(--aox-76px);
		}
	
}
@media screen and (max-width:375px){
	.section_brand-selection .ctn_cta-others span{
		left:5px;
		}
}
@media screen and (max-width:320px){
	.section_brand-selection .ctn_cta-others span{
		left:3px;
		}
}



.var_2023.section_brand-selection figure{
	padding-bottom: 0;
	height: var(--aox-450px);
	}
.var_2023.section_brand-selection .ctn_logo{
	height: var(--aox-150px);
	}
.var_2023.section_brand-selection .var_others {
	max-width: calc(100% / 6);
	}
.var_2023.section_brand-selection .var_others .brands_carousel_container .obj_brand-carousel{
	height: var(--aox-450px);
	}
.var_2023.section_brand-selection .ctn_cta-others{
	height: var(--aox-150px);
	}



@media screen and (max-width: 767px){
	.var_2023.section_brand-selection figure{
		height: var(--aox-225px);
		}
	.var_2023.section_brand-selection .ctn_logo {
		height: var(--aox-64px);
		}
	.var_2023.section_brand-selection .var_derm-expertise figure{
    	height: var(--aox-90px);
		}
	.var_2023.section_brand-selection figure img {
		height: var(--aox-220px);
		}
	.var_2023.section_brand-selection ul li {
		max-width: 50%;
		width: 50%;
		}
	.var_2023.section_brand-selection ul li.var_derm-expertise{
		max-width: 100%;
		width: 100%;
		order: 1;
		border-bottom: none;
		}
	.var_2023.section_brand-selection ul li.var_lrp{ order: 2; }
	.var_2023.section_brand-selection ul li.var_vichy{ order: 4; }
	.var_2023.section_brand-selection ul li.var_cerave{ order: 3; }
	.var_2023.section_brand-selection ul li.var_skinc{ order: 5; }
	.var_2023.section_brand-selection ul li.var_others{ order: 6; }

	.var_2023.section_brand-selection .var_others {
		max-width: 100%;
		}
	.var_2023.section_brand-selection .var_others figure > picture{
		height: var(--aox-90px);
		}

	.var_2023.section_brand-selection .var_derm-expertise figure,
	.var_2023.section_brand-selection .var_derm-expertise figure > picture,
	.var_2023.section_brand-selection .var_derm-expertise figure > picture img,
	.var_2023.section_brand-selection .var_others figure,
	.var_2023.section_brand-selection .var_others figure > picture,
	.var_2023.section_brand-selection .var_others figure > picture img{
		height: var(--aox-90px);
		}
	.var_2023.section_brand-selection ul li.var_others{
		border-top: none; border-bottom: none;
		}
	.var_2023.section_brand-selection .ctn_cta-others {
		height: var(--aox-90px);
		}
	.var_2023.section_brand-selection .var_others .brands_carousel_container .obj_brand-carousel{
		height: var(--aox-90px);
		}

	}