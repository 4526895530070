/* ======================================== */
/* .pg_page-liste
/* ======================================== */
.pg_page-liste .obj_title{
	font-size:var(--aox-fs-46px);
	text-transform:none;
	}

@media screen and (max-width:767px){
	.pg_page-liste .obj_title{
		font-size:var(--aox-fs-42px);
		}
}