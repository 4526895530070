/* ======================================== */
/* .obj_section
/* ======================================== */
.obj_section {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: var(--aox-60px) 0;
	color: #000;
	font-weight: 400;
	letter-spacing: normal;
	background-color: #fff;
	}
.obj_section + .obj_section {
	padding: var(--aox-20px) 0 var(--aox-60px) 0;
	}
.obj_section .ctn_section {
	width: var(--aox-grid);
	margin: 0 auto;
	}
.obj_section .ctn_section .ctn_title{
	margin-bottom: var(--aox-25px);
	}
.obj_section h1,
.obj_section h2,
.obj_section h3,
.obj_section h4,
.obj_section h5,
.obj_section h6 {
	margin: 0;
	font-weight: 700;
	line-height: 1;
	letter-spacing: 0;
	}
.obj_section .t_title {
	font-weight: 300;
	font-size: var(--aox-fs-60px);
    line-height: var(--aox-lh-100);
	}
.obj_section .t_title.title_small {
	font-size: var(--aox-fs-30px);
	}
.obj_section .t_subtitle {
	font-weight: 300;
	font-size: var(--aox-fs-24px);
	line-height: var(--aox-lh-100);
	text-transform: uppercase;
	}
.obj_section .t_subtitle.subtitle_small {
	font-size: var(--aox-fs-20px);
	}
.obj_section .t_title strong,
.obj_section .t_subtitle strong {
	font-weight: 700;
	}
.obj_section .ctn_title .t_title {
	margin-bottom: var(--aox-30px);
	width: var(--aox-col-9);
	}
.obj_section .ctn_title .t_subtitle {
	margin-bottom: var(--aox-30px);
	}
.obj_section p {
	font-size: var(--aox-fs-16px);
	line-height: 1.4;
	}
.obj_section .t_desc {
	font-size: var(--aox-fs-16px);
	line-height: 1.4;
	}
.obj_section .t_desc strong{
	font-weight: 700;
	}
.obj_section p a {
	text-decoration: underline;
	}
.editorial .obj_section .t_legal {
	font-size: var(--aox-fs-11px);
	line-height: 1.4;
	color:#808080;
	}
.obj_section .t_disclaimer {
	position: absolute;
	z-index: 2;
	left: 50%;
	bottom: var(--aox-20px);
	width: var(--aox-grid);
	font-size: var(--aox-fs-12px);
	color:#808080;
	transform: translateX(-50%);
	}



@media screen and (max-width:767px){

	.obj_section {
		overflow: unset;
		padding: var(--aox-25px) 0 var(--aox-60px) 0;
		}
	.obj_section .t_subtitle {
		font-size: var(--aox-fs-16px);
		}
	.obj_section .t_title {
		font-size: var(--aox-fs-40px);
		line-height: var(--aox-lh-110);
		}
	.obj_section .ctn_title > .t_title {
		margin-bottom: 0;
		}

}
@media screen and (max-width:320px){

}