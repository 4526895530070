/* ======================================== */
/* .obj_table-gains
/* ======================================== */
.obj_table-gains{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: #F2F2F2;
    transition: all .8s ease;
	}
.obj_table-gains .obj_table-gains-header{
    position: relative;
    padding: var(--aox-15px) var(--aox-20px) var(--aox-10px) var(--aox-20px);
    width: 100%;
    }
.obj_table-gains .obj_table-gains-header .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-17px);
    line-height: var(--aox-lh-100);
    width: var(--aox-175px);
    text-transform: uppercase;
    }

.obj_table-gains .obj_table-gains-header .obj_table-gains-detail{
    position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-100);
    width: var(--aox-185px);
    height: var(--aox-24px);
    text-transform: uppercase;
    text-align: left;
    margin-top:  var(--aox-5px);
    }
.obj_table-gains .obj_table-gains-header .obj_table-gains-detail:before{
    content: '';
    position: absolute;
    right: var(--aox-6px);
    top: calc(50% - var(--aox-2px));
    width: 6px;
    height: 6px;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    transform: translateY(-50%) rotate(45deg) scale(1);
    transition: all .3s ease;
    }
.obj_table-gains .obj_table-gains-header .obj_table-gains-detail.is-expanded:before{
    transform: translateY(-10%) rotate(45deg) scale(-1);
    }
.obj_table-gains .obj_table-gains-header .obj_table-gains-total{
    position: absolute;
    right: var(--aox-20px);
    top: var(--aox-20px);
    font-weight: 700;
    font-size: var(--aox-fs-34px);
    line-height: var(--aox-lh-100);
    }



/***/
.obj_table-gains .obj_table-gains-content{
    position: relative;
    opacity: 0;
    height: 0;
    width: 100%;
    transition:all .3s ease;
	}

.obj_table-gains.is-expanded .obj_table-gains-content{
    opacity: 1;
    height: auto;
    }

.obj_table-gains .obj_tabs-list-gains{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: var(--aox-5px) var(--aox-20px) var(--aox-14px) var(--aox-20px);
    }
.obj_table-gains .obj_tabs-list-gains li .btn_tab_year_gain{
    position: relative;
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-24px);
    padding-left: var(--aox-34px);
    }
.obj_table-gains .obj_tabs-list-gains li .btn_tab_year_gain:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: var(--aox-22px);
    height: var(--aox-22px);
    border: 1px solid rgba(0,0,0,0.2);
    background: #fff;
    transition: all .3s ease;
    }
.obj_table-gains .obj_tabs-list-gains li .btn_tab_year_gain:after {
    content: '';
    position: absolute;
    left: var(--aox-5px); top: var(--aox-7px);
    width: var(--aox-14px);
    height: var(--aox-10px);
    opacity: 0;
    transition: all .3s ease;
    background-image: url(../../images/check-mark.svg);
    background-size: cover;
    }
.obj_table-gains .obj_tabs-list-gains li .btn_tab_year_gain.active:after {
    transform: scale(1);
    opacity: 1;
    }

.obj_table-gains .obj_tabs-list-gains li + li{
    margin-left: var(--aox-16px);
    }


.obj_table-gains .obj_table-list-gains{
    border-top: 1px solid #D3D3D3;
    width: 100%;
    padding: var(--aox-16px) var(--aox-20px) var(--aox-10px) var(--aox-20px);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains{
    position: relative;
    margin-bottom: var(--aox-10px);
    width: var(--aox-290px);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains > *{
    width: var(--aox-220px);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains .t_date{
    font-weight: 400;
    font-size: var(--aox-fs-10px);
    line-height: var(--aox-lh-120);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains .t_title{
    font-weight: 500;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains .t_desc{
    font-weight: 700;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains .t_total{
    position: absolute;
    right: 0;
    top: var(--aox-30px);
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-100);
    width: auto;
    }

.obj_table-gains .obj_table-list-gains .obj_table-item-gains.is_empty > * {
    width: 100%;
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains.is_empty{
    margin-top: var(--aox-10px);
    margin-bottom: var(--aox-10px);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains.is_empty .t_title{
    font-weight: 500;
    font-size: var(--aox-fs-20px);
    line-height: var(--aox-lh-110);
    text-transform: uppercase;
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains.is_empty .t_desc{
    font-weight: 500;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-140);
    text-transform: none;
    margin-top: var(--aox-13px);
    }

.obj_table-gains .obj_table-list-gains .obj_table-item-gains + .obj_table-item-gains{
    border-top: 1px solid #D3D3D3;
    padding-top: var(--aox-10px);
    }


.obj_table-gains .obj_table-gains-content.is-ready .obj_table-list-gains .tab-panel{
    display: none;
    }
.obj_table-gains .obj_table-gains-content.is-ready .obj_table-list-gains .tab-panel.active {
    display: block;
    }


/***/

@media screen and (max-width:767px){

    .obj_table-gains .obj_table-gains-header .t_title {
        width: var(--aox-col-4);
        }
    .obj_table-gains .obj_table-gains-header .obj_table-gains-total {
        top: var(--aox-15px);
        }
    .obj_table-gains .obj_table-list-gains .obj_table-item-gains {
        width: var(--aox-grid);
        }

    .obj_table-gains.is-expanded .obj_table-gains-content {
        margin: 0 auto;
        }

}