/* ======================================== */
/* .pg_inscription
/* ======================================== */
.pg_inscription .main_container{
	padding-top:var(--aox-80px);
	}
	
@media screen and (max-width:767px){
	.pg_inscription .main_container{
		padding-top:var(--aox-60px);
		}
}