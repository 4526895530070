/* ======================================== */
/* Animations
/* ======================================== */
.fade-in{
	animation:fadeIn 1s ease;
	}
@keyframes fadeIn{
	from{opacity:0}
	to{opacity:1}
	}
.rotation-loop{
	animation:rotationLoop 1s ease infinite;
	}
@keyframes rotationLoop{
	from{transform:rotate(0deg)}
	to{transform:rotate(360deg)}
	}

	
@keyframes effectHeroTitle {
	from {
		opacity:0;
		transform: translateX(-50px);
	}
	to {
		opacity:1;
		transform: translateX(0px);
	}
}
@keyframes effectHeroCaseBrands {
	from {
		opacity: 0;
		transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		transform: translateY(0) rotateX(0deg);
	}
}
@keyframes effectHeroHighlightedBrands {
	from {
		opacity: 0;
		transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		transform: translateY(0) rotateX(0deg);
	}
}
@keyframes effectHeroEntry {
	from {
		opacity: 0;
		transform: translateY(-50px)
	}
	to {
		opacity: 1;
		transform: translateY(0)
	}
}
@keyframes effectHeroOtherBrands {
	from {
		opacity: 0;
		transform: translateY(-20px)
	}
	to {
		opacity: 1;
		transform: translateY(0)
	}
}
@keyframes textEffectSmooth{
    0% { opacity:0; }
    100% { opacity:1; }
    }
@keyframes effectNewsCarouselHeroHome {
	from {
		opacity: 0;
		transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		transform: translateY(0) rotateX(0deg);
	}
}


:root{
	--duration-300ms:300ms;
	--duration-500ms:500ms;
	--duration-800ms:800ms;
	--duration-1000ms:1000ms;
	}
