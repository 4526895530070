/* ======================================== */
/* .obj_push-product
/* ======================================== */
.ctn_range-liste{
	counter-reset:push-product-list;
	}
.obj_push-product{
	width:25%;
	padding:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	text-align:center;
	counter-increment:push-product-list;
	}
.obj_push-product figure{
	position:relative;
	}
.obj_push-product figure img{
	display:inline-block;
	}
.obj_push-product .lazyload-placeholder{
	height:0;
	padding-bottom:calc(var(--aox-175px) + var(--aox-1px));
	}
.obj_push-product .t_title{
	margin-top:var(--aox-10px);
	font-size:var(--aox-fs-18px); line-height:1.2;
	}
.obj_push-product .t_title strong{
	font-weight:700;
	}
.obj_push-product .t_desc{
	margin-top:var(--aox-5px);
	font-size:var(--aox-fs-14px); line-height:1.2;
	}
.obj_push-product .t_title-routine{
	font-size:var(--aox-fs-22px); line-height:1.1;
	text-transform:uppercase;
	}
.obj_push-product .t_title-routine::before{
	margin-right:var(--aox-10px);
	font-size:var(--aox-fs-14px);
	font-weight:700;
	content:counter(push-product-list) ".";
	}
.obj_push-product .t_desc-routine{
	position:relative;
	margin-top:var(--aox-35px);
	padding-bottom:var(--aox-80px);
	font-size:var(--aox-fs-16px); line-height:1;
	font-weight:500;
	text-transform:uppercase;
	}
.obj_push-product .t_desc-routine svg{
	position:absolute;
	left:50%; top:var(--aox-35px);
	transform:translateX(-50%);
	}
.obj_push-product .fake_cta{
	position:absolute;
	right:0; bottom:var(--aox-20px);
	width:var(--aox-45px); height:var(--aox-45px);
	background:#000;
	transition:all .3s ease;
	}
.obj_push-product .fake_cta::before,
.obj_push-product .fake_cta::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#fff;
	transform:translate(-50%,-50%);
	}
.obj_push-product .fake_cta::before{
	width:var(--aox-15px); height:2px;
	}
.obj_push-product .fake_cta::after{
	width:2px; height:var(--aox-15px);
	}
/**/
@media screen and (min-width:1025px){
	.obj_push-product a:hover .fake_cta{
		background:#777;
		}
}
@media screen and (max-width:768px){
	.obj_push-product{
		width:33.33%;
		}
}
@media screen and (max-width:767px){
	.obj_push-product{
		width:100%;
		}
	.obj_push-product .fake_cta{
		right:17vw;
		}
}