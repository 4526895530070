/* ======================================== */
/* .lazyload-placeholder
/* ======================================== */
.lazyload-placeholder{
	position:relative;
	width:100%; height:100%;
	background:#e6e6e6;
	}
.lazyload-placeholder::before{
	position:absolute;
	z-index:1;
	left:50%; top:50%;
	width:var(--aox-50px); height:var(--aox-50px);
	margin:calc(-1 * var(--aox-25px)) 0 0 calc(-1 * var(--aox-25px));
	border:5px solid rgba(255,255,255,1);
	border-top-color:rgba(255,255,255,0.5);
	border-bottom-color:rgba(255,255,255,0.5);
	border-radius:var(--aox-50px);
	animation:rotationLoop 1s ease infinite;
	content:'';
	}

@media screen and (max-width:767px){
	.lazyload-placeholder::before{
		width:var(--aox-40px); height:var(--aox-40px);
		margin:calc(-1 * var(--aox-20px)) 0 0 calc(-1 * var(--aox-20px));
		border-width:4px;
		border-radius:var(--aox-40px);
		}
}