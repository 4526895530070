/* ======================================== */
/* .pg_product-liste
/* ======================================== */
.pg_product-liste .main_container{
	position:relative;
	}
.pg_product-liste .obj_breadcrumb{
	position:absolute;
	left:var(--aox-60px); top:var(--aox-160px);
	padding-left:0;
	}
.pg_product-liste.var_banner-white .obj_banner-product .ctn_text h1,
.pg_product-liste.var_banner-white .obj_banner-product .ctn_text h2{
	color:#fff;
	}
.pg_product-liste.var_banner-white .obj_breadcrumb li{
	color:#fff;
	}
.pg_product-liste.var_banner-white .obj_breadcrumb li a::before{
	border-top-color:#fff;
	border-right-color:#fff;
	}

@media screen and (max-width:1280px){
	.pg_product-liste .obj_breadcrumb{
		left:var(--aox-30px); top:var(--aox-110px);
		}
}
@media screen and (max-width:767px){
	.pg_product-liste .obj_breadcrumb{
		left:var(--aox-20px); top:var(--aox-100px);
		}
}