/* ======================================== */
/* .obj_brands_carousel_hero
/* ======================================== */
.obj_brands_carousel_hero{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
	}
.obj_brands_carousel_hero > *{
	width: 50%;
	}
.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero{
	height: var(--aox-80px);
	}
.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero a{
	display: flex;
    height: var(--aox-80px);
    align-items: center;
    padding: 0 0 0 var(--aox-40px);
	}
.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero .t_title {
	font-size: var(--aox-fs-12px);
	}
.obj_brands_carousel_hero .carousel_other_hero .slick-arrow{
	display: none !important;
	}

.obj_brands_carousel_hero .carousel_other_hero{
	width: var(--aox-230px);
	height: var(--aox-80px);
	}
.obj_brands_carousel_hero .carousel_other_hero .ctn_logo{
	display: flex;
    width: var(--aox-230px);
    height: var(--aox-80px);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
	}
.obj_brands_carousel_hero .carousel_other_hero .ctn_logo img{
	max-width: var(--aox-230px);
	}


@media screen and (max-width: 767px){

	.obj_brands_carousel_hero { background-color: rgba(255, 255, 255, .7); }

	.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero {
		height: var(--aox-55px);
		}
	.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero a {
		height: var(--aox-55px);
		padding: 0 0 0 var(--aox-22px);
		}
	.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero .t_title {
		font-size: var(--aox-fs-12px);
		line-height: var(--aox-lh-90);
		}
	.obj_brands_carousel_hero .carousel_other_hero {
		width: 50%;
		height: var(--aox-55px);
		}
	.obj_brands_carousel_hero .carousel_other_hero .ctn_logo {
		width: 100%;
		height: var(--aox-55px);
		}
	.obj_brands_carousel_hero .carousel_other_hero .ctn_logo img{
		max-width: var(--aox-115px);
		}
		

	}