/* ======================================== */
/* .hide-for-mobile-app
/* ======================================== */
.hide-for-mobile-app .main_header,
.hide-for-mobile-app .main_header-login,
.hide-for-mobile-app .main_footer,
.hide-for-mobile-app .main_footer-login,
.hide-for-mobile-app .obj_breadcrumb,
.hide-for-mobile-app .section_compte .ctn_dashboard .ctn_col.col_info-perso .ctn_cta{
	display:none !important;
	}
.hide-for-mobile-app .main_container{
	padding-top:0 !important;
	}
.hide-for-mobile-app .section_login{
	padding-top:var(--aox-50px) !important;
	}