/* ======================================== */
/* .obj_formation_mini_parcours
/* ======================================== */
.obj_carrousel_mini_parcours {
    display: flex;
    flex-flow: row wrap;
	margin-bottom: var(--aox-10px);
	}
.obj_carrousel_mini_parcours .item + .item {
	margin-left: var(--aox-10px);
	}
.obj_formation_mini_parcours{
	width:var(--aox-240px);
	}
.obj_formation_mini_parcours a,
.obj_formation_mini_parcours button{
	display:block;
	width:100%;
	text-align:left;
	}
.obj_formation_mini_parcours figure{
	position:relative;
	height:calc(var(--aox-170px) + var(--aox-4px));
	overflow:hidden;
	}
.obj_formation_mini_parcours figure img{
	position:absolute;
	left:0; top:0;
	z-index:1;
	width:100%; height:100%;
	transition:all .3s ease;
	object-fit:cover;
	}
.obj_formation_mini_parcours .ctn_image{
	position:relative;
	width: 100%;
	height: auto;
	}
.obj_formation_mini_parcours .lazyload-placeholder{
	height:0;
	padding-bottom:73%;
	}
.obj_formation_mini_parcours .ctn_text{
	position: relative;
	min-height: var(--aox-35px);
	padding-right:var(--aox-35px);
	margin-top: var(--aox-10px);
	}
.obj_formation_mini_parcours h3{
	font-size:var(--aox-fs-14px); line-height:1.1;
	font-weight:700;
	text-transform: uppercase;
	}


/***/
.obj_formation_mini_parcours .obj_cta-formation {
	height: var(--aox-35px);
	padding: var(--aox-7px) var(--aox-45px) var(--aox-5px) var(--aox-15px);
	font-size: var(--aox-fs-11px);
	}
.obj_formation_mini_parcours .obj_cta-formation .obj_cta-formation-picto {
    width: var(--aox-35px);
    height: var(--aox-35px);
	}
.obj_formation_mini_parcours:not(.is-progress, .is-done, .is-not-available) .obj_cta-formation .obj_cta-formation-picto::before {
	content: '';
	position: absolute;
	left: 44%;
    top: var(--aox-10px);
	border: 1px solid transparent;
	border-width: 8px 10px;
	border-left-color: #fff;
	background: transparent;
	}
.obj_formation_mini_parcours.is-progress .obj_cta-formation .obj_cta-formation-picto::before {
	width: 4px;
	height: var(--aox-14px);
	border: 4px solid #fff;
	border-width: 0 4px;
	top: calc(50% - var(--aox-15px));
	left: calc(50% - var(--aox-12px));
	}
.obj_formation_mini_parcours.is-done .obj_cta-formation .obj_cta-formation-picto::after {
	top: var(--aox-23px);
	left: var(--aox-15px);
	}
.obj_formation_mini_parcours.is-not-available button{
	cursor:default;
	}
.obj_formation_mini_parcours.is-not-available figure img{
	filter:grayscale(1);
	}

/***/
.obj_formation_mini_parcours a:hover figure img,
.obj_formation_mini_parcours button:hover figure img{
	transform:scale(1.05);
	}
.obj_formation_mini_parcours.is-not-available a:hover figure img,
.obj_formation_mini_parcours.is-not-available button:hover figure img{
	transform:scale(1);
	}
.obj_formation_mini_parcours a:hover .obj_cta-download,
.obj_formation_mini_parcours button:hover .obj_cta-download{
	color:#777 !important;
	}
.obj_formation_mini_parcours a:hover .obj_cta-download svg,
.obj_formation_mini_parcours button:hover .obj_cta-download svg{
	fill:#777 !important;
	}

.obj_carrousel_mini_parcours{
	overflow: unset;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-arrow {
	top: calc(-1 * var(--aox-40px));
    height: var(--aox-30px);
    width: var(--aox-30px);
	margin-top: 0;
	border: 0;
	transform: none;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-next::before {
	content: "";
	position: absolute;
	left: 20%;
	top: 60%;
	width: var(--aox-12px);
	height: var(--aox-12px);
	-webkit-transform: rotate(-45deg) translate(-50%,-50%);
	transform: rotate(45deg) translate(-50%,-50%);
	border-top: solid 2px rgba(0,0,0,1);
	border-right: solid 2px rgba(0,0,0,1);
	transition: all .3s ease;
	}
/* .obj_carousel.obj_carrousel_mini_parcours .slick-next:hover{
    right: 3%;
	} */
.obj_carousel.obj_carrousel_mini_parcours .slick-prev {
	left: auto !important;
	right: calc(3% + var(--aox-25px));
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-prev::before {
	content: "";
	position: absolute;
	left: 30%;
	top: 60%;
	width: var(--aox-12px);
	height: var(--aox-12px);
	-webkit-transform: rotate(-45deg) translate(-50%,-50%);
	transform: rotate(45deg) translate(-50%,-50%);
	border-bottom: solid 2px rgba(0,0,0,1);
	border-left: solid 2px rgba(0,0,0,1);
	transition: all .3s ease;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-next.slick-disabled::before{
	border-top-color: rgba(0,0,0,0.25);
	border-right-color: rgba(0,0,0,0.25);
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-prev.slick-disabled::before{
	border-bottom-color: rgba(0,0,0,0.25);
	border-left-color: rgba(0,0,0,0.25);
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-next.slick-disabled:hover,
.obj_carousel.obj_carrousel_mini_parcours .slick-prev.slick-disabled:hover{
	transform: none;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-prev:not(.slick-disabled):hover::before{
	left: 7.5%;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-next:not(.slick-disabled):hover::before{
	right: 2.5%;
	}

/***/

@media screen and (max-width:767px){
	.obj_carousel.obj_carrousel_mini_parcours{
		margin-bottom:0;
		}
	.obj_carousel.obj_carrousel_mini_parcours .slick-arrow {
		top: calc(-1 * var(--aox-50px));
		}
	.obj_carousel.obj_carrousel_mini_parcours .slick-next {
		right: 3%;
		}
	.obj_carousel.obj_carrousel_mini_parcours .slick-prev {
		right: 12%;
		}
	.obj_carousel.obj_carrousel_mini_parcours .slick-prev:hover::before {
		left: 30%;
		}
	.obj_formation_mini_parcours{
		width:var(--aox-180px);
		margin-bottom:0;
		}
	.obj_formation_mini_parcours a,
	.obj_formation_mini_parcours button{
		padding:0;
		}
	.obj_formation_mini_parcours figure {
		height: var(--aox-130px);
		}

}