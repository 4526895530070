/* ======================================== */
/* .obj_comment-list
/* ======================================== */
.obj_comment-list{
	width:100%;
	padding:var(--aox-60px) 7.5% var(--aox-80px) 5%;
	background:#f2f2f2;
	}
.obj_comment-list h4{
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	}
.obj_comment-list .ctn_comment-row{
	display:flex;
	flex-flow:row wrap;
	margin-left:var(--aox-30px);
	padding:var(--aox-20px) 0 var(--aox-35px) var(--aox-15px);
	border-bottom:1px solid #c3c3c3;
	}
.obj_comment-list .ctn_comment-row .ctn_avatar{
	width:var(--aox-40px); height:var(--aox-40px);
	border-radius:50%;
	overflow:hidden;
	}
.obj_comment-list .ctn_comment-row .ctn_comment{
	width:calc(100% - var(--aox-40px));
	padding:var(--aox-5px) 0 0 var(--aox-15px);
	}
.obj_comment-list .ctn_comment-row .ctn_comment .t_name-time{
	font-size:var(--aox-fs-13px); line-height:1;
	color:#777;
	}
.obj_comment-list .ctn_comment-row .ctn_comment .t_name-time strong{
	color:#000;
	font-weight:700;
	}
.obj_comment-list .ctn_comment-row .ctn_comment .t_main{
	margin-top:var(--aox-10px);
	font-size:var(--aox-fs-13px); line-height:1.2;
	font-weight:500;
	}
.obj_comment-list .ctn_cta{
	padding-top:var(--aox-60px);
	text-align:center;
	}

@media screen and (max-width:767px){
	.obj_comment-list{
		padding:var(--aox-40px) var(--aox-30px) var(--aox-30px) var(--aox-30px);
		}
	.obj_comment-list h4{
		margin-bottom:var(--aox-15px);
		}
	.obj_comment-list .ctn_comment-row{
		margin-left:0;
		padding:var(--aox-10px) 0 var(--aox-20px) var(--aox-10px);
		}
	.obj_comment-list .ctn_cta{
		padding-top:var(--aox-30px);
		}
}