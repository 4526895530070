/* ======================================== */
/* .obj_likes-comments
/* ======================================== */
.obj_likes-comments{
	min-height:var(--aox-16px);
	margin:var(--aox-35px) 0;
	display:flex;
	justify-content:flex-end;
	}
.obj_likes-comments li{
	position:relative;
	padding-left:var(--aox-35px);
	margin-right:var(--aox-35px);
	font-size:var(--aox-fs-13px); line-height:1;
	color:#777;
	}
.obj_likes-comments li + li{
	margin-right:var(--aox-20px);
	}
.obj_likes-comments li svg{
	position:absolute;
	left:0; top:50%;
	fill:#c3c3c3;
	transform:translateY(-50%);
	}
.obj_likes-comments .ctn_like-article,
.obj_likes-comments .ctn_comment-article{
	padding-left:0;
	}
.obj_likes-comments .ctn_like-article .bt_like-article{
	position:relative;
	display:block;
	padding-left:var(--aox-35px);
	padding-top:var(--aox-5px);
	padding-bottom:var(--aox-5px);
	font-size:var(--aox-fs-13px); line-height:1;
	color:#777;
	}
.obj_likes-comments .ctn_like-article .bt_like-article[disabled]{
	opacity:0.5;
	cursor:default;
	}
.obj_likes-comments .ctn_comment-article .bt_comment-article{
	position:relative;
	display:block;
	padding-left:var(--aox-35px);
	padding-top: var(--aox-5px);
	padding-bottom: var(--aox-5px);
	}

@media screen and (min-width:1025px){
	.obj_likes-comments .ctn_comment-article .bt_comment-article:hover{
		color:#000;
		}
}
@media screen and (max-width:767px){
	.obj_likes-comments{
		margin-top:var(--aox-20px);
		margin-bottom:var(--aox-30px);
		}
	.obj_likes-comments li{
		padding-left:var(--aox-30px);
		margin-right:var(--aox-20px);
		}
	.obj_likes-comments .ctn_like-article .bt_like-article{
		padding-left:var(--aox-30px);
		}
	.obj_likes-comments li + li{
		margin-right:0;
		}
	.obj_likes-comments li span{
		display:none;
		}
}