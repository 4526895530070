/* ======================================== */
/* .section_formation_list
/* ======================================== */

.section_formation_list .ctn_row{
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
	}
.section_formation_list.obj_section .obj_formation {
	width: var(--aox-col-6);
	}
.section_formation_list.obj_section .ctn_title {
	margin-bottom: var(--aox-15px);
	}


@media screen and (max-width:767px){

	.section_formation_list.obj_section .obj_formation,
	.section_formation_list.obj_section .obj_formation + .obj_formation {
		margin-top: var(--aox-30px);
		margin-bottom: 0;
		}
	.section_formation_list.obj_section .obj_formation:first-child{
		margin-top: var(--aox-15px);
		margin-bottom: 0;
		}

}