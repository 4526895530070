/* ======================================== */
/* .obj_back-page
/* ======================================== */
.obj_back-page{
	width:100%;
	max-width:calc(var(--aox-1090px) + var(--aox-5px));
	margin:var(--aox-30px) auto 0 auto;
	}
.obj_back-page .bt_back-page{
	display:inline-block;
	position:relative;
	padding:var(--aox-5px) var(--aox-5px) var(--aox-5px) var(--aox-30px);
	font-size:var(--aox-fs-14px); line-height:1;
	}
.obj_back-page .bt_back-page::before{
	content:'';
	position:absolute;
	left:0; top:50%;
	margin-top:-1px;
	width:var(--aox-18px); height:2px;
	background:#000;
	transition:all .3s ease;
	}
.obj_back-page .bt_back-page::after{
	content:'';
	position:absolute;
	left:0; top:50%;
	width:8px; height:8px;
	border-top:2px solid #000;
	border-left:2px solid #000;
	transform:translateY(-50%) rotate(-45deg);
	transition:all .3s ease;
	}

@media screen and (min-width:1025px){
	.obj_back-page .bt_back-page:hover::before{
		transform:translateX(-5px);
		}
	.obj_back-page .bt_back-page:hover::after{
		transform:translateX(-5px) translateY(-50%) rotate(-45deg);
		}
}
@media screen and (max-width:1095px){
	.obj_back-page{
		padding:0 var(--aox-20px);
		}
}