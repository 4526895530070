/* ======================================== */
/* .section_maintenance
/* ======================================== */
/* .section_maintenance{
	position:absolute;
	left:50%; top:50%;
	width:100%;
	padding:0 var(--aox-20px);
    margin:0 auto;
    max-width:var(--aox-700px);
	text-align:center;
	transform:translate(-50%,-50%);
	}
.section_maintenance .v_logo{
    margin:0 auto;
    max-width:var(--aox-450px);
	}
.section_maintenance .v_picto{
	margin-top:var(--aox-60px);
	}
.section_maintenance h1{
	margin-top:var(--aox-60px);
	font-weight:700;
	font-size:var(--aox-fs-20px); line-height:1.2;
	}
.section_maintenance p{
	font-size:var(--aox-fs-20px); line-height:1.4;
	}
.section_maintenance p + p{
	margin-top:var(--aox-35px);
	}

@media screen and (max-width:767px){
	.section_maintenance .v_logo{
		max-width:var(--aox-320px);
		}
	.section_maintenance .v_picto{
		margin-top:var(--aox-30px);
		width:16.5vw; height:16vw;
		}
	.section_maintenance h1{
		margin-top:var(--aox-30px);
		font-size:var(--aox-fs-16px);
		}
	.section_maintenance p{
		font-size:var(--aox-fs-16px);
		}
	.section_maintenance p + p{
		margin-top:var(--aox-20px);
		}
} */


.obj_section.section_maintenance{
	display: flex;
    background-color: unset;
    flex-direction: column;
    align-items: center;
	padding: 0;
	margin-bottom: var(--aox-30px);
	}

.section_maintenance .obj_logo-old{
	width: var(--aox-235px);
	margin-bottom: var(--aox-30px);
	}
.obj_section.section_maintenance .t_title {
	font-weight: 400;
	font-size: var(--aox-fs-32px);
	line-height: var(--aox-lh-120);
	max-width: var(--aox-920px);
	text-align: center;
	}
.obj_section.section_maintenance .t_desc {
	font-weight: 400;
	font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-150);
	margin-top: var(--aox-50px);
	width: var(--aox-col-10);
	text-align: center;
	}
.section_maintenance .obj_logo{
	width: var(--aox-390px);
	margin-top: var(--aox-20px);
	}
.section_maintenance .obj_logo img{
	width: 100%;
	}
	
.section_maintenance .obj_bloc_edito.edito_text{
	width: var(--aox-col-8);
	margin-top: var(--aox-40px);
	}
.section_maintenance .obj_bloc_edito + .obj_bloc_edito{
	margin-top: var(--aox-20px);
	}
.section_maintenance .obj_bloc_edito.edito_text.has_background{
	background-color: #FFFFFF;
	padding: var(--aox-15px) 0 var(--aox-30px);
	}
.section_maintenance .obj_bloc_edito .ctn_text{
	display: flex;
    flex-direction: column;
    align-items: center;
	width: var(--aox-col-6);
	margin: 0 auto;
	}
.section_maintenance .obj_bloc_edito .ctn_text .ctn_icon{
	width: var(--aox-80px); height: auto;
	}
.section_maintenance .obj_bloc_edito .ctn_text .ctn_icon img{
	width: 100%;
	}
.section_maintenance .obj_bloc_edito .ctn_text .t_desc {
	max-width: 100%;
    margin-top: 0;
    width: 100%;
	}

@media screen and (max-width:767px){
	
	.obj_section.section_maintenance{
		padding: var(--aox-50px) var(--aox-10px) 0;		
		}
	.section_maintenance .obj_logo-old {
		width: var(--aox-180px);
		margin-bottom: var(--aox-20px);
		}
	.obj_section.section_maintenance .t_title {
		font-size: var(--aox-fs-20px);
    	line-height: var(--aox-lh-130);
		max-width: 100%;
		}
	.obj_section.section_maintenance .t_desc {
		font-size: var(--aox-fs-16px);
		line-height: var(--aox-lh-140);
		max-width: 100%;
		width: 100%;
		}
	.section_maintenance .obj_logo {
		width: var(--aox-280px);
		margin-top: var(--aox-20px);
		}
	.section_maintenance .obj_bloc_edito.edito_text.has_background {
		width: 100%;
		margin-top: var(--aox-30px);
		}
	.section_maintenance .obj_bloc_edito .ctn_text {
		width: 100%;
		padding: 0 var(--aox-30px);
		}
	.section_maintenance .obj_bloc_edito .ctn_text .ctn_icon {
		width: var(--aox-60px);
		}
	.section_maintenance .obj_bloc_edito .ctn_text .t_desc {
		font-size: var(--aox-fs-14px);
		line-height: var(--aox-lh-140);
		}
		
}