/* ======================================== */
/* .section_article-carousel
/* ======================================== */
.section_article-carousel{
	width:100%;
	}
.section_article-carousel .str_container{
	padding:var(--aox-60px) 0 var(--aox-100px) 0;
	}
.section_article-carousel h2{
	padding:0 7%;
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-34px); line-height:1;
	}
.section_article-carousel .ctn_banner a{
	display:block;
	padding:0 var(--aox-15px);
	}
.section_article-carousel .ctn_banner a figure{
	position:relative;
	overflow:hidden;
	}
.section_article-carousel .ctn_banner a figure img{
	width:100%;
	transition:all .3s ease;
	}

.section_article-carousel .ctn_banner.var_video a figure::before,
.section_article-carousel .ctn_banner.var_video a figure::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	}
.section_article-carousel .ctn_banner.var_video a figure::before{
	width:var(--aox-88px); height:var(--aox-88px);
	border:4px solid #fff;
	border-radius:50%;
	transform:translate(-50%,-50%);
	}
.section_article-carousel .ctn_banner.var_video a figure::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-16px) 0 var(--aox-16px) var(--aox-28px);
	border-color:transparent transparent transparent #fff;
	transform:translate(-40%,-50%);
	}

.section_article-carousel .ctn_banner h3{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	}
.section_article-carousel .obj_likes-comments{
	justify-content:flex-start;
	margin:var(--aox-20px) 0 0 0;
	}
.section_article-carousel .obj_likes-comments li{
	padding:var(--aox-3px) 0 0 var(--aox-25px);
	margin-right:var(--aox-20px);
	font-size:var(--aox-fs-11px);
	}

@media screen and (min-width:1025px){
	.section_article-carousel .ctn_banner a:hover figure img{
		transform:scale(1.05);
		}
}
@media screen and (max-width:1280px){
	.section_article-carousel h2{
		font-size:var(--aox-fs-40px);
		}
	.section_article-carousel .ctn_banner h3{
		font-size:var(--aox-fs-18px);
		}
}
@media screen and (max-width:767px){
	.section_article-carousel .str_container{
		padding:var(--aox-60px) 0;
		}
	.section_article-carousel h2{
		font-size:var(--aox-fs-24px);
		padding:0 var(--aox-20px);
		}
	.section_article-carousel .ctn_banner h3{
		font-size:var(--aox-fs-18px);
		}
	.section_article-carousel .ctn_banner a{
		padding:0 var(--aox-20px);
		}
	.section_article-carousel .ctn_banner.var_video a figure::before{
		width:var(--aox-78px); height:var(--aox-78px);
		}
	.section_article-carousel .ctn_banner.var_video a figure::after{
		border-width:var(--aox-14px) 0 var(--aox-14px) var(--aox-24px);
		}
}