/* ======================================== */
/* .obj_ba-slider
/* ======================================== */
.obj_ba-slider{
	position:relative;
	overflow:hidden;
	}
.obj_ba-slider {
	max-width:unset;
	}
.obj_ba-slider .ctn_before-{
	box-sizing:content-box;
	position:absolute;
	z-index:2;
	left:0; top:0;
	overflow:hidden;
	}
.obj_ba-slider .ba-slidebar{
	position:absolute;
	z-index:3;
	left:50%; top:0;
	width:6px; height:100%;
	background:#fff;
	transform:translateX(-50%);
	}
.obj_ba-slider .ctn_after-{
	position:relative;
	z-index:1;
	}
.obj_ba-slider input[type=range]{
	position:absolute;
	z-index:4;
	left:50%; top:0;
	width:100%; height:100%;
	margin:0;
	-webkit-appearance:none;
	-moz-appearance:none;
	background:none;
	transform:translateX(-50%);
	}
.obj_ba-slider input[type=range]:hover{
	cursor:pointer;
	}
.obj_ba-slider input[type=range]:focus{
	outline:none;
	}
.obj_ba-slider input[type=range]:active{
	outline:none;
	}
.obj_ba-slider input[type=range]::-webkit-slider-thumb{
	height:var(--aox-38px); width:var(--aox-38px);
	background:#fff;
	background:url('../../images/i_before-after.svg') center center / var(--aox-24px) auto no-repeat #fff;
	border-radius:50%;
	-webkit-appearance:none;
	}
.obj_ba-slider input[type=range]::-moz-range-thumb{
	height:var(--aox-38px); width:var(--aox-38px);
	background:url('../../images/i_before-after.svg') center center / var(--aox-24px) auto no-repeat #fff;
	border-radius:50%;
	border:none;
	-moz-appearance:none;
	}
@media screen and (max-width:1150px){
	.obj_ba-slider {
		width:50vw;
		}
}
@media screen and (min-width:768px) and (max-width:768px){
	.obj_ba-slider{
		width:var(--aox-345px);
		margin:0 auto;
		}
	.obj_ba-slider {
		width:var(--aox-345px);
		}
}
@media screen and (max-width:767px){
	.obj_ba-slider {
		width:81.2vw;
		}
}