/* ======================================== */
/* .obj_progress-bar-challenge
/* ======================================== */
:root {
	--color-progressbar-lrp: 0,159,227;
	--color-progressbar-cerave: 37,112,184;
	--color-progressbar-sanoflore: 60,100,60;
	--color-progressbar-vichy: 29,29,27;
	--color-progressbar-skinc: 0,139,176;
	--color-progressbar-biotherm: 32,97,112;
  }
.obj_progress-bar-challenge{
	position: relative;
	width: var(--aox-200px);
	margin-top: var(--aox-10px);
	}
.obj_progress-bar-challenge .t_title{
	margin-bottom: var(--aox-10px);
    font-size:var(--aox-fs-12px); line-height: 1.2;
    font-weight: 700;
	text-transform: uppercase;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.obj_progress-bar-challenge .t_title span{
	font-weight: 400;
	margin-left: var(--aox-10px);
	}
.obj_progress-bar-challenge .obj_progress-bar{
	position: relative;
    background-color: #F0F0F0;
    border-radius: var(--aox-16px);
    padding: 2px;
    height: 8px;
    width: 100%;
	}
.obj_progress-bar-challenge + .obj_progress-bar-challenge{
	margin-top: var(--aox-25px);
	}
.obj_progress-bar-challenge .obj_progress-bar .ctn_steps{
	display: flex;
    align-items: center;
    flex-direction: row;
	width: 100%;
	}
.obj_progress-bar-challenge .obj_progress-bar .step {
	position: relative;
	display: block;
	height: 4px;
    background: #000;
	border-radius: var(--aox-10px);
	}
.obj_progress-bar-challenge .obj_progress-bar .step.active ~ .step.step {
	color: #000;
	}

/***/
.obj_progress-bar-challenge .obj_progress-bar .step {
	background: rgb(240,240,240);
	}

.declarer .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(58, 170, 193); }
.repartir .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(137, 43, 173); }
.en_attente .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(246, 177, 95); }
.remporte .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(172, 222, 147); }
.echoue .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(196, 196, 196); } 

.declarer .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(58, 170, 193,0.2); }
.repartir .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(137, 43, 173,0.2); }
.en_attente .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(246, 177, 95,0.2); }
.remporte .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(172, 222, 147,0.2); }
.echoue .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(196, 196, 196,0.2); } 


/* var_lrp */
.obj_progress-bar-challenge.var_lrp .obj_progress-bar{
	background: rgba(var(--color-progressbar-lrp), 0.15);
	}
.obj_progress-bar-challenge.var_lrp .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-lrp), 1);
	}
.obj_progress-bar-challenge.var_lrp .obj_progress-bar .ctn_cta{
	background: rgba(var(--color-progressbar-lrp), 1);
	}
/* var_cerave */
.obj_progress-bar-challenge.var_cerave .obj_progress-bar{
	background: rgba(var(--color-progressbar-cerave), 0.15);
	}
.obj_progress-bar-challenge.var_cerave .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-cerave), 1);
	}
/* var_vichy */
.obj_progress-bar-challenge.var_vichy .obj_progress-bar{
	background: rgba(var(--color-progressbar-vichy), 0.15);
	}
.obj_progress-bar-challenge.var_vichy .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-vichy), 1);
	}
/* var_skinc */
.obj_progress-bar-challenge.var_skinc .obj_progress-bar{
	background: rgba(var(--color-progressbar-skinc), 0.15);
	}
.obj_progress-bar-challenge.var_skinc .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-skinc), 1);
	}
/* var_sanoflore */
.obj_progress-bar-challenge.var_sanoflore .obj_progress-bar{
	background: rgba(var(--color-progressbar-sanoflore), 0.15);
	}
.obj_progress-bar-challenge.var_sanoflore .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-sanoflore), 1);
	}	
/* var_biotherm */
.obj_progress-bar-challenge.var_biotherm .obj_progress-bar{
	background: rgba(var(--color-progressbar-biotherm), 0.15);
	}
.obj_progress-bar-challenge.var_biotherm .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-biotherm), 1);
	}


.obj_progress-bar-challenge .obj_progress-bar .active_profil{
	position: absolute;
	transform: translate(-50%, -50%);
    top: 50%; left: 0%;
    width: var(--aox-32px); height: var(--aox-32px);
    z-index: 2;
	}
.obj_progress-bar-challenge .obj_progress-bar .active_profil {
	width: var(--aox-32px); height: var(--aox-32px);
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid white;
	}


/***/
.obj_progress-bar-challenge.var_attention  {
	animation-duration: 3.5s;
	animation-name: clignoter;
	animation-iteration-count: infinite;
	transition: none;
	}
 @keyframes clignoter {
	0%   { opacity:1; }
	40%   {opacity:0; }
	100% { opacity:1; }
	}

 


@media screen and (max-width:767px){

}