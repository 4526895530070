/* ======================================== */
/* .obj_circle-progress
/* ======================================== */

/***/

.obj_circle-progress-expertise{
	position: relative;
	width: var(--aox-280px);
	margin: 0 auto;
	}
.obj_circle-progress-expertise .flex-col {
	display: flex;
	flex-direction: column;
	align-content: stretch;
	text-align: center;
	color: white;
	text-transform: uppercase;
	gap: var(--aox-24px);
	width: var(--aox-450px);
	}

.obj_circle-progress-expertise .circle-container {
	width: var(--aox-290px);
	height: var(--aox-290px);
	}

.obj_circle-progress-expertise .circle-container__background {
	fill: none;
	stroke: #F2F2F2;
	stroke-width: 4px;
	stroke-dasharray: 75 100;
	stroke-linecap: round;
	}

.obj_circle-progress-expertise .circle-container__progress {
	fill: none;
	stroke-linecap: round;
	stroke: #CF9C67;
	stroke-dasharray: 75 100;
	stroke-linecap: round;
	stroke-width: 2px;
	transition: stroke-dashoffset 1s ease-in-out;
	}

.obj_circle-progress-expertise .stop1 {
	stop-color: #CF9C67;
	}

/* .obj_circle-progress-expertise .stop2 {
	stop-color: #9198e5;
	} */
.obj_circle-progress-expertise .ctn_txt{
	position:absolute;
	left:0; top:50%;
	width:100%;
	font-size:var(--aox-fs-50px);
	text-align:center;
	transform:translateY(-50%);
	}
.obj_circle-progress-expertise .ctn_txt .t_percent{
	font-size: var(--aox-fs-50px);
    line-height: var(--aox-lh-90);
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
	text-transform: uppercase;
	}
.obj_circle-progress-expertise .ctn_txt .t_percent span{
	padding-left: 2px;
	font-size:var(--aox-fs-24px);
	line-height: 1.2em;
	}
.obj_circle-progress-expertise .ctn_txt .t_title{
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}

.obj_circle-progress-expertise .ctn_avatar{
	position: absolute;
    bottom: 0; left: 50%;
    width: var(--aox-78px); height: var(--aox-78px);
    transform: translateX(-50%);
	}
.obj_circle-progress-expertise .ctn_avatar figure {
    position: absolute;
    margin: 0 auto;
    width: 100%; height: 100%;
    max-width: 100%;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
	}
.obj_circle-progress-expertise figure img {
	width: 100%; height: 100%;
	object-fit: cover;
	}

.obj_circle-progress-expertise path {
	stroke-linecap: round;
	stroke-width: var(--aox-8px);
	}
.obj_circle-progress-expertise .CircularProgressbar-trail {
	stroke: #E8E8E8;
	}
.obj_circle-progress-expertise .CircularProgressbar-path {
	stroke: #00ACC2;
	stroke-width: var(--aox-4px);
	stroke-dasharray: 248;
	stroke-dashoffset: 124;
	/* adjust last number for variance */
	transition:stroke-dashoffset 1s ease 0.5s;
	}


/***/

@media screen and (max-width:1340px){
	.obj_circle-progress-expertise .ctn_txt {
		top: 45%;
		font-size:var(--aox-fs-50px);
		}
	.obj_circle-progress-expertise .ctn_avatar {
		width: var(--aox-96px); height: var(--aox-96px);
		}
}

@media screen and (max-width:767px){

	.obj_circle-progress-expertise .ctn_txt{
		top: 52%;
		}
	.obj_circle-progress-expertise .ctn_txt .t_percent {
		font-size: var(--aox-fs-40px);
		}
	.obj_circle-progress-expertise .ctn_avatar {
		width: var(--aox-78px);
		height: var(--aox-78px);
		bottom: calc(-1 * var(--aox-10px));
		}
	




}