/* ======================================== */
/* .obj_push-galerie
/* ======================================== */
.obj_push-galerie{
	position:relative;
	margin:0 3%;
	width:27.33%;
	overflow:hidden;
	}
.obj_push-galerie button{
	display:block;
	}
.obj_push-galerie .ctn_image{
	position:relative;
	z-index:1;
	overflow:hidden;
	}
.obj_push-galerie .ctn_image img{
	position:relative;
	z-index:1;
	max-width:100%;
	}
.obj_push-galerie .ctn_image p{
	position:absolute;
	left:0; bottom:0;
	z-index:2;
	padding:var(--aox-10px);
	font-size:var(--aox-fs-16px); line-height:1;
	font-weight:700;
	color:#000;
	text-transform:uppercase;
	background:#fff;
	}
.obj_push-galerie .ctn_before{
	position:relative;
	z-index:1;
	}
.obj_push-galerie .ctn_after{
	box-sizing:content-box;
	position:absolute;
	left:calc(50% - 2px); top:0;
	z-index:2;
	width:100%; height:100%;
	overflow:hidden;
	border-left:4px solid #fff;
	}
.obj_push-galerie .ctn_after img{
	transform:translateX(-50%);
	}
.obj_push-galerie .ctn_overlay{
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	z-index:3;
	width:100%; height:100%;
	background:rgba(0,0,0,0.5);
	opacity:0;
	transition:all .3s ease;
	}
.obj_push-galerie .obj_likes-comments li{
	padding-left:var(--aox-45px);
	font-size:var(--aox-fs-16px);
	font-weight:700;
	color:#fff;
	}
.obj_push-galerie .obj_likes-comments li svg{
	width:var(--aox-36px); height:var(--aox-31px);
	fill:#fff;
	}
@media screen and (min-width:1025px){
	.obj_push-galerie:hover .ctn_overlay{
		opacity:1;
		}
}
@media screen and (min-width:768px) and (max-width:768px){
	.obj_push-galerie .ctn_image p{
		font-size:var(--aox-fs-12px);
		}
}
@media screen and (max-width:767px){
	.obj_push-galerie{
		margin:0 auto;
		width:80%;
		}
	.obj_push-galerie + .obj_push-galerie{
		margin-top:var(--aox-40px);
		}
}