/* ======================================== */
/* .obj_challenge
/* ======================================== */
.obj_challenge{
    position: relative;
    transition: all 1s ease;
}
.obj_challenge > .ctn_header-title{
    padding: var(--aox-12px) var(--aox-20px) var(--aox-8px) var(--aox-20px);
}
.obj_challenge > .ctn_header-title span{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    color: #fff;
}

.obj_challenge.declarer > .ctn_row{ border: 2px solid #7FD5E0; }
.obj_challenge.repartir > .ctn_row{ border: 2px solid #CA93DE; }
.obj_challenge.en_attente > .ctn_row{ border: 2px solid #F6B15F; }
.obj_challenge.remporte > .ctn_row{ border: 2px solid #ACDE93; }
.obj_challenge.echoue > .ctn_row{ border: 2px solid #C4C4C4; }

.obj_challenge.declarer > .ctn_header-title{ background-color: #7FD5E0; }
.obj_challenge.repartir > .ctn_header-title{ background-color: #CA93DE; }
.obj_challenge.en_attente > .ctn_header-title{ background-color: #F6B15F; }
.obj_challenge.remporte > .ctn_header-title{ background-color: #ACDE93; }
.obj_challenge.echoue > .ctn_header-title{ background-color: #C4C4C4; }

.obj_challenge .ctn_row{
    position: relative;
    border: 1px solid rgba(112, 112, 112, 0.31);
    padding: var(--aox-20px) var(--aox-10px) var(--aox-10px) var(--aox-20px);
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    }
.obj_challenge + .obj_challenge{
    margin-top: var(--aox-30px);
    }
.obj_challenge .ctn_text{
    display: flex;
    width: var(--aox-380px);
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    }
.obj_challenge .ctn_text .obj_temps_restant{
    position: relative;
    margin-bottom: var(--aox-20px);
    }   
.obj_challenge .ctn_text .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    }
.obj_challenge .ctn_text .t_date{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    margin-top: var(--aox-5px);
    }
.obj_challenge .ctn_text .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    margin-top: var(--aox-15px);
    }
.obj_challenge .ctn_text .t_desc strong{
    font-weight: 700;
    font-size: var(--aox-fs-20px);
    line-height: var(--aox-lh-100);
    }
.obj_challenge .obj_btn_challenge{
    width: auto;
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    display: inline-block;
    outline: 0;
    border: 1px solid #000;
    background: transparent;
    color: #000;
    text-transform: uppercase;
    padding: var(--aox-20px) var(--aox-30px);
    margin-top: var(--aox-15px);
    }
.obj_challenge .obj_btn_challenge:hover{
    border: 1px solid #000;
    background: #000;
    color: #fff;
    }

.obj_challenge.repartir .obj_btn_challenge,
.obj_challenge.declarer .obj_btn_challenge{
    border: 1px solid #000;
    background: #000;
    color: #fff;
    }
.obj_challenge.repartir .obj_btn_challenge:hover,
.obj_challenge.declarer .obj_btn_challenge:hover{
    border: 1px solid #000;
    background: transparent;
    color: #000;
    }


.obj_challenge .obj_btn_condition_challenge-detail{
    position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-100);
    padding-right: var(--aox-30px);
    height: var(--aox-24px);
    text-transform: uppercase;
    text-align: left;
    margin-top: var(--aox-15px);
    }
.obj_challenge .obj_btn_condition_challenge-detail:before{
    content: '';
    position: absolute;
    right: var(--aox-6px);
    top: calc(50% - var(--aox-2px));
    width: 6px;
    height: 6px;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    transform: translateY(-50%) rotate(45deg) scale(1);
    transition: all .3s ease;
    }
.obj_challenge .obj_btn_condition_challenge-detail.is-active:before{
    transform: translateY(-10%) rotate(45deg) scale(-1);
    }

    
.obj_challenge .ctn_image{
    width: var(--aox-170px);
    }
.obj_challenge .ctn_image .ctn_visuel{
    width: var(--aox-170px);
    height: var(--aox-170px);
    overflow: hidden;
    }
.obj_challenge .ctn_image .ctn_visuel img{
    width: 100%;
    }
.obj_challenge .ctn_image .ctn_logo img{
    width: 100%;
    }


/***/

.obj_challenge .ctn_conditions-expand{
    position: relative;
    opacity: 0;
    height: 0;
    width: 100%;
    transition: all .3s ease;
    }
.obj_challenge.is-expanded .ctn_conditions-expand {
    opacity: 1;
    height: auto;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: var(--aox-15px) var(--aox-20px);
    background-color: #F2F2F2;
    border-top: 1px solid rgba(0,0,0,0.1);
    transition: all .3s ease;
    }
    
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: var(--aox-6px);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item + .obj_condition_challenge-item{
    padding-top: var(--aox-6px);
    border-top: 1px solid rgba(0,0,0,0.1);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item + .obj_condition_challenge-item.var_condition{
    background-color: #d9d9d9;
    padding: var(--aox-6px) var(--aox-10px);
    margin-bottom: 0;
    margin-top: calc(-1* var(--aox-5px));
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item:nth-child(1){
    margin-bottom: var(--aox-5px);
    padding-bottom: var(--aox-6px);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item:nth-child(2) {
    border-top: none;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item{
    position: relative;
    width: 35%;
    text-align: left;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) { width: 40%; }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(3) { width: 25%; }


.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-price{
    font-size: var(--aox-fs-22px);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-price em { position: relative; font-size: var(--aox-fs-13px); line-height: var(--aox-lh-120); top: calc(-1 * var(--aox-3px)); }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-label{
    margin-bottom: var(--aox-3px);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) .obj_condition_challenge-item-label,
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) .obj_condition_challenge-item-price{
    text-align: left;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(3){
    text-align: right;
    width: 30%;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item > .obj_condition_challenge-item-label{
    width: var(--aox-270px);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .obj_condition_challenge-item-label span{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .obj_condition_challenge-item-price{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    }



/***/

.obj_challenge.is_empty{
    position: relative;
    }
.obj_challenge.is_empty .ctn_text{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: var(--aox-40px);
    z-index: 2;
    justify-content: center;
    }
.obj_challenge.is_empty .t_title{
    font-weight: 400;
    font-size: var(--aox-fs-38px);
    line-height: var(--aox-lh-120);
    color: white;
    text-transform: none;
    margin: 0;
    }
.obj_challenge.is_empty .ctn_background{
    display: block;
    height: var(--aox-550px);
    width: var(--aox-620px);
    }
.obj_challenge.is_empty .ctn_background img{
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    }

/***/



/***/

@media screen and (max-width:767px){

    .pg_challenge .obj_challenge .ctn_row{
        padding: 0;
        flex-direction: column;
        }
    .obj_challenge .ctn_row .ctn_text{
        width: 100%;
        }
    .obj_challenge .ctn_row .ctn_text > .obj_temps_restant,
    .obj_challenge .ctn_row .ctn_text > .t_title,
    .obj_challenge .ctn_row .ctn_text > .t_date,
    .obj_challenge .ctn_row .ctn_text > .t_desc,
    .obj_challenge .ctn_row .ctn_text > .obj_btn_challenge {
        width: var(--aox-220px);
        margin-left: var(--aox-20px);
        }
    .obj_challenge .ctn_row .ctn_text {
        padding-top: var(--aox-20px);
        }   
    .obj_challenge .ctn_row .ctn_text > .obj_btn_challenge {
        width: auto;
        }
    .obj_challenge .ctn_row .ctn_text > .obj_btn_condition_challenge-detail {
        margin-left: var(--aox-20px);
        width: var(--aox-170px);
        padding-right: var(--aox-20px);
        margin-bottom: var(--aox-20px);
        }
    .obj_challenge .ctn_image {
        width: var(--aox-110px);
        position: absolute;
        top: var(--aox-5px); right: var(--aox-5px);
        }
    .obj_challenge .ctn_image .ctn_visuel{
        width: var(--aox-110px);
        height: var(--aox-110px);
        }

    .obj_challenge .ctn_text .t_title{
        font-size: var(--aox-fs-16px);
        line-height: var(--aox-lh-100);
        }

    .obj_challenge.is-expanded .ctn_row .ctn_text > .ctn_conditions-expand{
        width: 100%;
        margin-left: 0;
        }

    .obj_challenge.is_empty .ctn_background {
        display: block;
        width: var(--aox-380px);
        height: var(--aox-340px);
        }   
    .obj_challenge.is_empty .t_title{
        font-size: var(--aox-fs-20px);
        line-height: var(--aox-lh-120);
        }
    

}