/* ======================================== */
/* .obj_banner-product
/* ======================================== */
.obj_banner-product{
	position:relative;
	z-index:1;
	}
.obj_banner-product figure{
	position:relative;
	z-index:1;
	}
.obj_banner-product figure img{
	width:100%;
	}
.obj_banner-product .ctn_text{
	position:absolute;
	z-index:2;
	left:5%; top:50%;
	padding-right:var(--aox-20px);
	width:60%;
	transform:translateY(-50%);
	}
.obj_banner-product .ctn_text h1{
	font-weight:700;
	font-size:var(--aox-fs-50px); line-height:1.2;
	text-transform:uppercase;
	}
.obj_banner-product .ctn_text h2{
	font-weight:700;
	font-size:var(--aox-fs-46px); line-height:1.2;
	}

/**/
@media screen and (max-width:1280px){
	.obj_banner-product .ctn_text h1{
		font-size:var(--aox-fs-50px);
		}
	.obj_banner-product .ctn_text h2{
		font-size:var(--aox-fs-46px);
		}
}
@media screen and (max-width:767px){
	.obj_banner-product .ctn_text{
		width:auto;
		transform:translateY(-25%);
		}
	.obj_banner-product .ctn_text h1{
		font-size:var(--aox-fs-30px);
		word-break:break-all;
		}
	.obj_banner-product .ctn_text h2{
		font-size:var(--aox-fs-28px);
		}
}