/* ======================================== */
/* .section_liste-ingredients
/* ======================================== */
.section_liste-ingredients{
	width:100%;
	margin-bottom:var(--aox-150px);
	}
.section_liste-ingredients .str_container{
	position:relative;
	max-width:var(--aox-1210px);
	padding:0 var(--aox-30px);
	}
/**/
.section_liste-ingredients .ctn_liste-ingredients + .ctn_liste-ingredients{
	margin-top:var(--aox-40px);
	}
/**/
.section_liste-ingredients .t_title-letter{
	font-size:var(--aox-fs-100px); line-height:1;
	font-weight:700;
	color:#f2f2f2;
	}
/**/
.section_liste-ingredients .obj_liste-ingredients{
	display:flex;
	flex-flow:row wrap;
	margin:0 var(--aox-30px);
	padding:var(--aox-45px) 0;
	border-bottom:1px solid #c3c3c3;
	}
.section_liste-ingredients .obj_liste-ingredients:first-of-type{
	padding-top:var(--aox-35px);
	}
.section_liste-ingredients .obj_liste-ingredients figure{
	width:25.7%;
	}
.section_liste-ingredients .obj_liste-ingredients figure img{
	width:100%;
	}
.section_liste-ingredients .obj_liste-ingredients .ctn_text{
	width:74.3%;
	padding-left:8.25%;
	}
.section_liste-ingredients .obj_liste-ingredients .ctn_text h2{
	margin-bottom:var(--aox-25px);
	font-size:var(--aox-fs-40px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.section_liste-ingredients .obj_liste-ingredients .ctn_text p{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-16px); line-height:1.875;
	}
/**/
.section_liste-ingredients .obj_nav-ingredients{
	position:sticky;
	z-index:999;
	left:0; top:var(--aox-60px);
	display:flex;
	width:100%;
	max-width:var(--aox-1150px);
	margin-bottom:var(--aox-60px);
	border-bottom:1px solid #c3c3c3;
	background:#fff;
	}
.section_liste-ingredients .obj_nav-ingredients li{
	width:3.85%;
	text-align:center;
	}
.section_liste-ingredients .obj_nav-ingredients li button,
.section_liste-ingredients .obj_nav-ingredients li a{
	display:flex;
	justify-content:center;
	align-items:center;
	position:relative;
	width:100%;
	height:var(--aox-70px);
	font-size:var(--aox-fs-24px); line-height:1;
	font-weight:500;
	transition:none;
	}
.section_liste-ingredients .obj_nav-ingredients li button.current,
.section_liste-ingredients .obj_nav-ingredients li a.current{
	font-size:var(--aox-fs-34px);
	}
.section_liste-ingredients .obj_nav-ingredients li button.inactive,
.section_liste-ingredients .obj_nav-ingredients li a.inactive{
	color:#c3c3c3;
	cursor:default;
	}
.section_liste-ingredients .obj_nav-ingredients li button::before,
.section_liste-ingredients .obj_nav-ingredients li a::before{
	content:'';
	position:absolute;
	left:0; bottom:0;
	width:100%; height:3px;
	background:#000;
	opacity:0;
	transition:opacity .3s ease;
	}
.section_liste-ingredients .obj_nav-ingredients li button.current::before,
.section_liste-ingredients .obj_nav-ingredients li a.current::before{
	opacity:1;
	}
/**/
@media screen and (max-width:767px){
	.section_liste-ingredients{
		margin-bottom:var(--aox-50px);
		}
	.section_liste-ingredients .str_container{
		padding-left:12%;
		padding-right:12%;
		}
	.section_liste-ingredients .obj_liste-ingredients figure{
		width:100%;
		}
	.section_liste-ingredients .obj_liste-ingredients .ctn_text{
		width:100%;
		padding-left:0;
		}
	.section_liste-ingredients .obj_liste-ingredients{
		margin:0;
		}
	.section_liste-ingredients .t_title-letter{
		margin-left:calc(-1 * var(--aox-30px));
		font-size:var(--aox-fs-90px);
		}
	.section_liste-ingredients .obj_liste-ingredients .ctn_text h2{
		margin-top:var(--aox-30px);
		font-size:8vw;
		}
	.section_liste-ingredients .obj_liste-ingredients .ctn_text p{
		font-size:var(--aox-fs-15px);
		}
	.section_liste-ingredients .obj_nav-ingredients{
		position:relative;
		flex-flow:row wrap;
		top:0; left:calc(-1  * var(--aox-25px));
		width:calc(100% + var(--aox-50px));
		margin-bottom:var(--aox-35px);
		}
	.section_liste-ingredients .obj_nav-ingredients li{
		width:14%;
		}
	.section_liste-ingredients .obj_liste-ingredients .lazyload-placeholder{
		min-height:67.5vw;
		}
}