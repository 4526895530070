/* ======================================== */
/* margin
/* ======================================== */
.obj_form .mb_5{
	margin-bottom:var(--aox-5px);
	}
.obj_form .mb_10{
	margin-bottom:var(--aox-10px);
	}
.obj_form .mb_15{
	margin-bottom:var(--aox-15px);
	}
.obj_form .mb_20{
	margin-bottom:var(--aox-20px);
	}
.obj_form .mb_30{
	margin-bottom:var(--aox-30px);
	}
.obj_form .mb_40{
	margin-bottom:var(--aox-40px);
	}
.obj_form .mb_50{
	margin-bottom:var(--aox-50px);
	}

/* ======================================== */
/* row
/* ======================================== */
.obj_form .ctn_row{
	display:flex;
	flex-flow:row wrap;
	}
.obj_form .ctn_row > div{
	margin:0 var(--aox-20px) var(--aox-10px) 0;
	}

/* ======================================== */
/* input
/* ======================================== */
.obj_form .ctn_input{
	position:relative;
	}
.obj_form input[type="text"],
.obj_form input[type="tel"],
.obj_form input[type="email"],
.obj_form input[type="password"],
.obj_form input[type="search"],
.obj_form input[type="date"],
.obj_form input[type="url"]{
	width:100%; height:var(--aox-60px);
	padding:var(--aox-10px) var(--aox-50px) var(--aox-10px) var(--aox-25px);
	font-size:var(--aox-fs-14px); line-height:1;
	color:#777;
	font-weight:500;
	border:1px solid #777;
	}
.obj_form input[type="text"]::placeholder,
.obj_form input[type="tel"]::placeholder,
.obj_form input[type="email"]::placeholder,
.obj_form input[type="password"]::placeholder,
.obj_form input[type="search"]::placeholder,
.obj_form input[type="date"]::placeholder,
.obj_form input[type="url"]::placeholder{
	font-size:var(--aox-fs-12px);
	font-weight:700;
	text-transform:uppercase;
	}
.obj_form input[type="text"].error,
.obj_form input[type="tel"].error,
.obj_form input[type="email"].error,
.obj_form input[type="password"].error,
.obj_form input[type="search"].error,
.obj_form input[type="date"].error,
.obj_form input[type="url"].error{
	color:#ff4847;
	border:1px solid #ff4847;
	}
.obj_form input[type="text"].error::placeholder,
.obj_form input[type="tel"].error::placeholder,
.obj_form input[type="email"].error::placeholder,
.obj_form input[type="password"].error::placeholder,
.obj_form input[type="search"].error::placeholder,
.obj_form input[type="date"].error::placeholder,
.obj_form input[type="url"].error::placeholder{
	color:#ff4847;
	}
.obj_form input[type="text"]:disabled,
.obj_form input[type="tel"]:disabled,
.obj_form input[type="email"]:disabled,
.obj_form input[type="password"]:disabled,
.obj_form input[type="search"]:disabled,
.obj_form input[type="date"]:disabled,
.obj_form input[type="url"]:disabled{
	background:#f2f2f2;
	border:1px solid #f2f2f2;
	}

/* ======================================== */
/* text
/* ======================================== */
.obj_form .t_mention{
	font-size:var(--aox-fs-12px); line-height:1.4;
	font-weight:500;
	color:#777;
	}
.obj_form .t_mention a{
	text-decoration:underline;
	color:#000;
	}
.obj_form .t_mandatory{
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:500;
	color:#000;
	}
.obj_form .t_mandatory.var_grey{
	color:#777;
	}
.obj_form .t_info{
	font-size:var(--aox-fs-14px); line-height:1;
	font-weight:500;
	color:#000;
	}
.obj_form .t_info a{
	font-weight:700;
	}
.obj_form .t_notif span{
	display:inline-block;
	padding:var(--aox-15px) var(--aox-30px);
	font-size:var(--aox-fs-14px); line-height:1.2;
	font-weight:500;
	color:#000;
	background:#fff;
	}
.obj_form .t_error{
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:500;
	color:#ff4847;
	}
.obj_form .ctn_input + .t_error{
	margin-top:calc(-1 * var(--aox-15px));
	}
.obj_form .t_label{
	font-size:var(--aox-fs-12px); line-height:1.2;
	font-weight:500;
	color:#777;
	}
.obj_form .t_label a{
	text-decoration:underline;
	}

/* ======================================== */
/* .ctn_textarea
/* ======================================== */
.obj_form .ctn_textarea textarea{
	width:100%; height:var(--aox-180px);
	padding:var(--aox-20px);
	font-family:'Montserrat', sans-serif;
	font-size:var(--aox-fs-14px); line-height:1.2em;
	color:#777;
	background:#fff;
	border:1px solid #777;
	transition:all .3s ease;
	outline:none;
	resize:none;
	appearance:none;
	-webkit-appearance:none;
	border-radius:0;
	}
.obj_form .ctn_textarea.no-border textarea{
	border-color:#fff;
	}
.obj_form .ctn_textarea textarea::placeholder{
	font-size:var(--aox-fs-12px);
	font-weight:700;
	text-transform:uppercase;
	}
.obj_form .ctn_textarea textarea.error{
	color:#ff4847;
	border:1px solid #ff4847;
	}
.obj_form .ctn_textarea textarea.error::placeholder{
	color:#ff4847;
	}

/* ======================================== */
/* .ctn_checkbox
/* ======================================== */
.obj_form .ctn_checkbox input[type=checkbox]{
	display:none;
	}
.obj_form .ctn_checkbox label{
	display:block;
	position:relative;
	padding:var(--aox-4px) 0 0 var(--aox-30px);
	min-height:var(--aox-20px);
	font-size:var(--aox-fs-12px); line-height:1.2;
	color:#777;
	font-weight:500;
	cursor:pointer;
	}
.obj_form .ctn_checkbox label.error,
.obj_form .ctn_checkbox label.error a{
	color:#ff4847;
	}
.obj_form .ctn_checkbox label a{
	color:#000;
	text-decoration:underline;
	}
.obj_form .ctn_checkbox label::before{
	position:absolute;
	left:0; top:0;
	width:var(--aox-18px); height:var(--aox-18px);
	border:1px solid #777;
	background:#fff;
	transition:all .3s ease;
	content:'';
	}
.obj_form .ctn_checkbox.no-border label::before{
	border-color:#fff;
	}
.obj_form .ctn_checkbox label.error::before{
	border:1px solid #ff4847;
	}
.obj_form .ctn_checkbox label::after{
	position:absolute;
	left:4px; top:4px;
	width:var(--aox-12px); height:var(--aox-12px);
	transform:scale(0.3);
	opacity:0;
	background:#777;
	content:'';
	transition:all .3s ease;
	}
.obj_form .ctn_checkbox input[type=checkbox]:checked + label::after{
	transform:scale(1);
	opacity:1;
	}

/* ======================================== */
/* .ctn_radio
/* ======================================== */
.obj_form .ctn_radio input[type=radio]{
	display:none;
	}
.obj_form .ctn_radio label{
	display:block;
	position:relative;
	padding:4px 0 0 var(--aox-30px);
	min-height:var(--aox-20px);
	font-size:var(--aox-fs-12px); line-height:1.2;
	color:#777;
	font-weight:500;
	cursor:pointer;
	}
.obj_form .ctn_radio label.error{
	color:#ff4847;
	}
.obj_form .ctn_radio label::before{
	position:absolute;
	left:0; top:0;
	width:var(--aox-18px); height:var(--aox-18px);
	border:1px solid #777;
	background:#fff;
	transition:all .3s ease;
	border-radius:50%;
	content:'';
	}
.obj_form .ctn_radio label.error::before{
	border:1px solid #ff4847;
	}
.obj_form .ctn_radio label::after{
	position:absolute;
	left:4px; top:4px;
	width:var(--aox-12px); height:var(--aox-12px);
	transform:scale(0.3);
	opacity:0;
	background:#777;
	border-radius:50%;
	content:'';
	transition:all .3s ease;
	}
.obj_form .ctn_radio input[type=radio]:checked + label::after{
	transform:scale(1);
	opacity:1;
	}

/* ======================================== */
/* .ctn_select
/* ======================================== */
.obj_form .ctn_select{
	position:relative;
	}
.obj_form .ctn_select .react-select__control{
	position:relative;
	height:var(--aox-60px);
	font-size:var(--aox-fs-14px); line-height:1;
	font-weight:500;
	color:#777;
	border:1px solid #777;
	border-radius:0;
	box-shadow:none;
	background:#fff;
	cursor:pointer;
	}
.obj_form .ctn_select .react-select__control.react-select__control--is-disabled{
	background:#f2f2f2;
	}
.obj_form .ctn_select .react-select__control:hover{
	background:#fff;
	}
.obj_form .ctn_select .error .react-select__control,
.obj_form .ctn_select .error .react-select__control:hover{
	border:1px solid #ff4847;
	}
.obj_form .ctn_select .react-select__value-container{
	display:flex;
	align-items:center;
	padding:0 var(--aox-20px);
	height:100%;
	}
.obj_form .ctn_select .react-select__value-container > div{
	padding:0 !important;
	margin:0 !important;
	}
.obj_form .ctn_select .react-select__placeholder{
	margin:0;
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:700;
	color:#777;
	text-transform:uppercase;
	}
.obj_form .ctn_select .react-select__control--is-disabled .react-select__placeholder{
	font-size:var(--aox-fs-14px);
	font-weight:500;
	text-transform:none;
	}
.obj_form .ctn_select .error .react-select__placeholder{
	color:#ff4847;
	}
.obj_form .ctn_select .react-select__indicators{
	position:absolute;
	right:var(--aox-20px); top:var(--aox-23px);
	width:var(--aox-17px); height:var(--aox-14px);
	transition:all .3s ease;
	}
.obj_form .ctn_select .react-select__control--is-disabled .react-select__indicators{
	display:none;
	}
.obj_form .ctn_select .react-select__control--menu-is-open .react-select__indicators{
	transform:scale(-1);
	}
.obj_form .ctn_select .react-select__indicator-separator,
.obj_form .ctn_select .react-select__indicator{
	display:none;
	}
.obj_form .ctn_select .react-select__indicators::after{
	display:block;
	width:0; height:0;
	border-style:solid;
	border-width:14px 8.5px 0 8.5px;
	border-color:#000 transparent transparent transparent;
	content:'';
	}
.obj_form .ctn_select .react-select__menu{
	position:absolute;
	z-index:7777;
	left:0; top:var(--aox-59px);
	width:100%;
	border:1px solid #777;
	border-top-color:#fff;
	border-radius:0;
	box-shadow:none;
	margin:0;
	}
.obj_form .ctn_select .react-select__menu-list{
	max-height:var(--aox-150px);
	padding:0;
	}
.obj_form .ctn_select .react-select__option{
	padding:var(--aox-10px) var(--aox-20px);
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:700;
	background-color:#fff;
	text-transform:uppercase;
	transition:all .3s ease;
	cursor:pointers;
	}
.obj_form .ctn_select .react-select__option--is-selected,
.obj_form .ctn_select .react-select__option:hover{
	color:#000;
	background-color:#f2f2f2;
	}

/* Native Select */
.obj_form .ctn_native-select{
	display:none;
	position:relative;
	width:100%; height:var(--aox-60px);
	background:#fff;
    border:1px solid #777;
	overflow:hidden;
	}
.obj_form .error .ctn_native-select{
    border:1px solid #ff4847;
	}
.obj_form .ctn_native-select .native-select{
	box-sizing:border-box;
    width:100%; height:var(--aox-60px);
    padding:0 var(--aox-20px);
	font-family:'Montserrat', sans-serif;
    font-size:var(--aox-fs-12px); line-height:1;
	font-weight:700;
	color:#777;
	text-transform:uppercase;
    background:#fff;
    outline:none;
	border:none;
    appearance:none;
    -webkit-appearance:none;
	cursor:pointer;
	}
.obj_form .ctn_native-select .native-select:disabled{
    background:#f2f2f2;
	}
.obj_form .error .ctn_native-select .native-select{
	color:#ff4847;
	}
.obj_form .ctn_native-select .native-select::-ms-expand{
	display:none;
	}
.obj_form .ctn_native-select::after{
	position:absolute;
	right:var(--aox-20px); top:var(--aox-23px);
	width:0; height:0;
	border-style:solid;
	border-width:14px 8.5px 0 8.5px;
	border-color:#000 transparent transparent transparent;
	content:'';
	}
.obj_form .ctn_native-select.is-disabled::after{
	display:none;
	}

@media screen and (max-width:767px){
	.obj_form .react-select-container{
		display:none;
		}
	.obj_form .ctn_native-select{
		display:block;
		}
}

/* ======================================== */
/* .ctn_select-birthdate
/* ======================================== */
.obj_form .ctn_select-birthdate .t_label{
	display:block;
	margin-bottom:var(--aox-15px);
	font-weight:700;
	text-transform:uppercase;
	color:#000;
	}
.obj_form .ctn_select-birthdate .ctn_select-wrapper{
	display:flex;
	}
.obj_form .ctn_select-birthdate .ctn_select{
	width:33.33%;
	}
.obj_form .ctn_select-birthdate .ctn_select + .ctn_select{
	margin-left:var(--aox-10px);
	}

@media screen and (max-width:767px){
	.obj_form .ctn_select-birthdate .ctn_select-wrapper{
		flex-direction:column;
		}
	.obj_form .ctn_select-birthdate .ctn_select-wrapper > div + div{
		margin-top:var(--aox-10px);
		}
	.obj_form .ctn_select-birthdate .ctn_select{
		width:100%;
		}
	.obj_form .ctn_select-birthdate .ctn_select + .ctn_select{
		margin-left:0;
		}
}

/* ======================================== */
/* .btn_password-toggle
/* ======================================== */
.obj_form .btn_password-toggle{
	position:absolute;
	right:var(--aox-15px); top:var(--aox-22px);
	width:var(--aox-28px); height:var(--aox-16px);
	}
.obj_form .ctn_input.var_password .btn_password-toggle{
	right:var(--aox-50px);
	}
.obj_form .ctn_input.var_password input[type="password"]{
	padding-right:var(--aox-80px);
	}
.obj_form .btn_password-toggle svg{
	fill:#b4b4b4;
	transition:all .3s ease;
	}
.obj_form .btn_password-toggle::before{
	position:absolute;
	left:-3px; top:calc(50% - 1px);
	width:120%; height:2px;
	background:#b4b4b4;
	transform:rotate(-135deg);
	opacity:1;
	transition:all .3s ease;
	content:'';
	}
.obj_form .btn_password-toggle[aria-pressed=true]::before{
	opacity:0;
	}

@media screen and (min-width:1025px){
	.obj_form .btn_password-toggle:hover svg{
		fill:#000;
		}
	.obj_form .btn_password-toggle:hover::before{
		background:#000;
		}
}

/* ======================================== */
/* obj_recaptcha
/* ======================================== */

.obj_form .obj_recaptcha{
	display:block;
	width:calc(var(--aox-310px) + var(--aox-6px)); height:var(--aox-90px);
	overflow:hidden;
	}
.obj_form .obj_recaptcha > div{
	padding:var(--aox-5px);
	}
.obj_form .obj_recaptcha .error{
	border:1px solid #e80000;
	}

@media screen and (max-width:414px){
	.obj_form .obj_recaptcha,
	.obj_form .obj_recaptcha > div{
		width:calc(var(--aox-240px) + var(--aox-3px)); height:var(--aox-70px);
		}
	.obj_form .obj_recaptcha > div > div{
		transform:scale(0.76);
		transform-origin:left top;
		}
}

/* ======================================== */
/* .ctn_file-upload
/* ======================================== */
.obj_form .ctn_single-file-upload{
	position:relative;
	}
.obj_form .ctn_single-file-upload label{
	cursor:pointer;
	}
.obj_form .ctn_single-file-upload .error label{
	color:#e80000;
	}
.obj_form .ctn_single-file-upload input[type="file"]{
	position:absolute;
	z-index:2;
	width:1px; height:1px;
	left:0; top:0;
	opacity:0;
	}
.obj_form .ctn_single-file-upload .t_error{
	margin-top:var(--aox-20px);
	}
.obj_form .ctn_single-file-upload .ctn_file-thumb{
	position:relative;
	display:flex;
	align-items:center;
	width:100%;
	padding-right:0;
	margin-top:var(--aox-20px);
	}
.obj_form .ctn_single-file-upload .canvas_thumb{
	cursor:pointer;
	background:#000;
	}
.obj_form .ctn_single-file-upload .canvas_thumb.no_cursor{
	cursor:default;
	}
.obj_form .ctn_single-file-upload .ctn_infos{
	padding:var(--aox-10px) 0 var(--aox-10px) var(--aox-20px);
	}
.obj_form .ctn_single-file-upload .t_name{
	font-size:var(--aox-fs-13px); line-height:1.2;
	font-weight:700;
	word-break:break-all;
	}
.obj_form .ctn_single-file-upload .t_weight{
	margin-top:var(--aox-5px);
	font-size:var(--aox-fs-13px); line-height:1.2;
	color:#b0b0b0;
	}
.obj_form .ctn_single-file-upload .bt_cancel{
	position:absolute;
	left:var(--aox-90px); top:0;
	width:var(--aox-20px); height:var(--aox-20px);
	font-size:0;
	transform:translate(-50%,-50%);
	background:#777;
	border-radius:50%;
	}
.obj_form .ctn_single-file-upload .bt_cancel::before,
.obj_form .ctn_single-file-upload .bt_cancel::after{
	position:absolute;
	left:var(--aox-9px); top:var(--aox-3px);
	width:var(--aox-2px); height:var(--aox-14px);
	background:#fff;
	content:'';
	transition:all .3s ease;
	}
.obj_form .ctn_single-file-upload .bt_cancel::before{
	transform:rotate(45deg);
	}
.obj_form .ctn_single-file-upload .bt_cancel::after{
	transform:rotate(-45deg);
	}
/* var_avatar */
.obj_form .ctn_file-upload.var_avatar .ctn_single-file-upload .obj_cta{
	display:flex;
	align-items:flex-end;
	justify-content:center;
	padding:0 0 var(--aox-40px) 0;
	margin:0;
	width:100%; height:var(--aox-400px);
	color:#000;
	font-weight:300;
	text-transform:none;
	text-decoration:underline;
	background:url('../../images/i_avatar-grey.svg') center center / calc(var(--aox-120px) + var(--aox-8px)) auto no-repeat #c3c3c3;
	}
/* var_avatar var_edit */
.obj_form .ctn_file-upload.var_avatar.var_edit{
	position:relative;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta{
	position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: var(--aox-400px);
    overflow: hidden;
    width: 100%;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta img{
	width: 100%;
    height: 100%;
    object-fit: cover;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta{
	display:flex;
	justify-content:center;
	align-items:center;
	position:absolute;
	z-index:2;
	left:0; top:0;
	width:100%; height:100%;
	opacity:0;
	background:rgba(0,0,0,0.6);
	transition:opacity .3s ease;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta svg{
	display:block;
	margin:0 auto var(--aox-10px) auto;
	fill:#fff;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta .t_edit{
	display:block;
	text-align:center;
	font-size:var(--aox-fs-14px);
	color:#fff;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload{
	z-index:2;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload .obj_cta,
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload .obj_cta:hover{
	background:transparent;
	}
/* var_galerie */
.obj_form .ctn_file-upload.var_galerie{
	width:44%;
	margin:0 2%;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .ctn_cta{
	margin:0;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .obj_cta{
	margin:0;
	width:100%; height:0;
	padding:0 0 100% 0;
	font-size:0;
	background:url('../../images/i_galerie-upload.svg') center center / 42% auto no-repeat #c3c3c3;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .ctn_file-thumb{
	position:unset;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .canvas_thumb{
	position:absolute;
	left:0; top:0;
	width:100%;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .bt_cancel,
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .bt_cancel:hover{
	position:relative;
	left:auto; top:auto;
	margin-left:auto;
	width:var(--aox-12px); height:var(--aox-16px);
	border-radius:0;
	transform:none;
	background:url('../../images/i_trash.svg') center center / 100% auto no-repeat !important;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .bt_cancel::before,
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .bt_cancel::after{
	display:none;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .ctn_infos{
	display:flex;
	align-items:flex-end;
	width:100%;
	padding:10px;
	background:#f0f0f0;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .t_name{
	width:50%;
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .t_weight{
	padding-left:5px;
	margin:0;
	}
/**/
@media screen and (min-width:1025px){
	.obj_form .ctn_file-upload.var_avatar .ctn_single-file-upload .obj_cta:hover{
		color:#fff;
		background-color:#777;
		}
	.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .obj_cta:hover{
		background-color:#777;
		}
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload.hover + .ctn_edit-cta .bt_edit-cta{
		opacity:1;
		}
	.obj_form .ctn_single-file-upload .bt_cancel:hover{
		color:#000;
		background:#777 !important;
		}
}
@media screen and (min-width:768px) and (max-width:960px){
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload .obj_cta{
		height:40vw;
		}
}
@media screen and (max-width:768px){
	.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .t_weight{
		padding-left:0;
		}
}
@media screen and (max-width:767px){
	.obj_form .ctn_file-upload.var_avatar .ctn_single-file-upload .obj_cta{
		margin:0 auto;
		padding-bottom:25px;
		width:190px; height:190px;
		background-size:60px auto;
		}
	.obj_form .ctn_file-upload.var_avatar .ctn_single-file-upload .t_error{
		text-align:center;
		}
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta{
		left:50%;
    	width:190px; height:190px;
		padding-bottom:0;
		transform:translateX(-50%);
		}
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta{
		opacity:1;
		background:transparent;
		}
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta svg{
		width:50px;
		margin-bottom:5px;
		}
}

/* ======================================== */
/* obj_strength-level
/* ======================================== */
.obj_form .obj_strength-level{
	position:relative;
	width:100%; height:6px;
	background:#f2f2f2;
	}
.obj_form .obj_strength-level::before{
	position:absolute;
	left:0; top:0;
	width:0; height:100%;
	transition:all .3s ease;
	content:'';
	}
.obj_form .obj_strength-level[data-level="1"]::before,
.obj_form .obj_strength-level[data-level="2"]::before{
	width:33%;
	background:red;
	}
.obj_form .obj_strength-level[data-level="3"]::before,
.obj_form .obj_strength-level[data-level="4"]::before{
	width:66%;
	background:orange;
	}
.obj_form .obj_strength-level[data-level="5"]::before{
	width:100%;
	background:#0ABF04;
	}

/* ======================================== */
/* ctn_cta
/* ======================================== */
.obj_form .ctn_cta{
	display:flex;
	flex-flow:row wrap;
	}
.obj_form .ctn_cta .obj_cta{
	margin:0 10px 10px 0;
	}

/* ======================================== */
/* recaptcha v3
/* ======================================== */
.grecaptcha-badge{
	display:none !important;
	}


/* ======================================== */
/* ctn_checkbox_background 
/* ======================================== */
.obj_form .ctn_checkbox_background {
	position: relative;
	padding: var(--aox-30px) var(--aox-20px);
	background: #f2f2f2;
	}
.obj_form .ctn_checkbox_background .t_title{
	margin-bottom: var(--aox-10px);
    font-size: var(--aox-fs-15px);
    line-height: var(--aox-lh-120);
    letter-spacing: 0;
    font-weight: 700;
	}
.obj_form .ctn_checkbox_background .t_desc{
	font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    margin-top: var(--aox-20px);
    letter-spacing: 0;
	}
.obj_form .ctn_checkbox_background .ctn_line{
	margin-top: var(--aox-10px);
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox{
	display: none;
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox + .switch_checkbox_label > span{
	outline: 0;
    display: block;
    width: var(--aox-32px);
    height: var(--aox-18px);
    position: relative;
    cursor: pointer;
    user-select: none;
    background: #D9D9D9;
    border-radius: 2em;
    padding: 2px;
    margin-right: var(--aox-10px);
    transition: all 0.4s ease;
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox + .switch_checkbox_label > span:after{
	position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
	left: 0;
	border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox:checked + .switch_checkbox_label > span{
	background: #000000;
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox:checked + .switch_checkbox_label > span:after{
	left: 50%;
	}

.obj_form .ctn_checkbox_background .ctn_line label{
	display: flex;
    margin-bottom: var(--aox-10px);
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    letter-spacing: 0;
    font-weight: 700;
    flex-direction: row;
    align-items: center;
	}

/* ======================================== */
/* obj_filter-input
/* ======================================== */

.obj_form .obj_filter-input{
	display:flex;
	margin-bottom:var(--aox-10px);
	padding-right:var(--aox-10px);
	}
.obj_form .obj_filter-input .t_filter-input{
	display:flex;
	align-items:center;
	font-size:var(--aox-fs-14px);
	width:var(--aox-100px);
	}
.obj_form .obj_filter-input .ctn_input{
	flex-grow:1;
	}
.obj_form .obj_filter-input .ctn_input input{
	position:relative;
	z-index:1;
	height:var(--aox-40px);
	padding-left:var(--aox-15px);
	letter-spacing:normal;
	color:#000;
	}
.obj_form .obj_filter-input .ctn_input input::placeholder{
	font-size:var(--aox-fs-14px);
	text-transform:none;
	font-weight:400;
	}
.obj_form .obj_filter-input .bt_reset-input{
	position:absolute;
	z-index:2;
	right:var(--aox-10px); top:50%;
	width:var(--aox-20px); height:var(--aox-20px);
	border-radius:100vw;
	transform:translateY(-50%);
	background:#000;
	}
.obj_form .obj_filter-input .bt_reset-input:hover{
	background:#777;
	}
.obj_form .obj_filter-input .bt_reset-input::before,
.obj_form .obj_filter-input .bt_reset-input::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	width:var(--aox-2px); height:var(--aox-14px);
	transform:translate(-50%,-50%) rotate(45deg);
	border-radius:100vw;
	background:#fff;
	}
.obj_form .obj_filter-input .bt_reset-input::after{
	transform:translate(-50%,-50%) rotate(-45deg);
	}

@media screen and (max-width:767px){
	.obj_form .obj_filter-input{
		padding-right:var(--aox-5px);
		}
	.obj_form .obj_filter-input .ctn_input input::placeholder{
		font-size:var(--aox-fs-12px);
		}
	.obj_form .obj_filter-input .t_filter-input{
		width:var(--aox-120px);
		}
}