/* ======================================== */
/* .pg_brand
/* ======================================== */
.pg_brand .main_container{
	padding-top:var(--aox-165px);
	}
.pg_brand .main_header .ctn_nav-marque{
	display:block;
	}
/***/
.pg_brand.pg_dermexpertise .main_container > .var_100.var_full.var_derm-expertise{
	margin-top: 0;
	}
.pg_brand.pg_dermexpertise .main_container > *:last-child{
	margin-bottom: var(--aox-90px);
	}
@media screen and (max-width:1280px){
	.pg_brand .main_container{
		padding-top:var(--aox-110px);
		}
	.pg_brand.menu-mobile_is-opened .main_header .ctn_nav-marque {
		display: none;
		}
}