/* ======================================== */
/* .section_compte
/* ======================================== */
.section_compte{
	width:100%;
	}
/**/
.section_compte .ctn_top{
	padding-top:var(--aox-60px);
	}
.section_compte .ctn_top h1{
	padding-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:1.2;
	border-bottom:1px solid #cbcbcb;
	}
.section_compte .ctn_top h2{
	margin-top:var(--aox-70px);
	margin-bottom:var(--aox-5px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_compte .ctn_top p{
	font-size:var(--aox-fs-16px); line-height:1.6;
	}
/**/
.section_compte .ctn_dashboard{
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;
	padding:var(--aox-100px) 0 var(--aox-200px) 0;
	}
.section_compte .ctn_dashboard .ctn_col{
	position:relative;
	width: 35%;
    max-width: var(--aox-440px);
	/* padding:20px 20px 50px 20px; */
	}
.section_compte .ctn_dashboard .ctn_col.col_percent{
	border-right:1px solid rgba(112,112,112,0.3);
	}
.section_compte .ctn_dashboard .col_info-perso figure{
	margin:0 auto;
	width:100%; max-width:var(--aox-255px);
	border-radius:50%;
	overflow:hidden;
	}
.section_compte .ctn_dashboard .col_info-perso img{
	max-width:100%;
	}
.section_compte .ctn_dashboard .col_percent{
	width: 65%;
    max-width: var(--aox-840px);
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.section_compte .ctn_dashboard .col_expert{
	text-align:center;
	padding: 0 var(--aox-20px);
	}
.section_compte .ctn_dashboard .col_expert h3{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-24px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_compte .ctn_dashboard .col_expert h4{
	margin-bottom:var(--aox-25px);
	font-size:var(--aox-fs-16px); line-height:1.2;
	font-weight:700;
	}
.section_compte .ctn_dashboard .col_expert ul li{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-16px); line-height:1.2;
	text-decoration:underline;
	}
.section_compte .ctn_dashboard .ctn_col .ctn_cta{
	position:absolute;
	z-index:3;
	left: 50%; top: 50%;
    width: 106%;
    transform: translate(-50%,-50%);
	}
.section_compte .ctn_dashboard .ctn_col .ctn_cta .obj_cta{
	display:flex;
	justify-content:center;
	align-items:center;
	padding: var(--aox-10px) var(--aox-10px);
	height:var(--aox-60px);
	margin:0 auto;
	text-align:center;
	}


/***/

.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise .ctn_cta{
	opacity: 0;
	}
.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise .ctn_cta .obj_cta{
	font-size:var(--aox-fs-12px);
    line-height: 1;
	}
.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise.hover .ctn_cta,
.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise:hover .ctn_cta{
	opacity: 1;
	}




/**/
.section_compte .ctn_list-vip-expert{
	position:relative;
	display: flex;
	max-width: var(--aox-400px);
	margin: 0 0 0 auto;
	flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
	}
.section_compte .ctn_list-vip-expert .item_vip-expert{
	position:relative;
	width: 30%;
    max-width: var(--aox-120px);
	margin-top: var(--aox-20px);
	}
.section_compte .ctn_list-vip-expert .item_vip-expert .ctn_logo{
	position:relative;
	}
.section_compte .ctn_list-vip-expert .item_vip-expert .ctn_logo img{
	position:relative;
	width: 100%;
	}
.section_compte .ctn_list-vip-expert .item_vip-expert .t_title{
	margin-top: var(--aox-10px);
    font-size:var(--aox-fs-12px);
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
	}



/**/
.section_compte .obj_star-score{
	position:absolute;
	left:0; top:var(--aox-120px);
	width:100%;
	}
/**/
.section_compte .ctn_progress-bar-expertise{
	position: relative;
    width: var(--aox-390px);
    padding: 0 var(--aox-50px);
	}

/**/

@media screen and (min-width:768px) and (max-width:1280px){

	.section_compte .obj_circle-progress-expertise {
		width: 50%;
		padding: 0 var(--aox-30px);
		}
	.section_compte .ctn_progress-bar-expertise {
		width: 50%;
		padding: 0 var(--aox-30px);
		}
	.section_compte .obj_circle-progress-expertise .ctn_avatar {
		width: var(--aox-72px);
		height: var(--aox-72px);
		}
}

@media screen and (max-width:1280px){
	/* .section_compte .ctn_top{
		padding-left:20px;
		padding-right:20px;
		} */
	.section_compte .ctn_expert-score .bg_expert-score{
		width:0 !important;
		}
}
@media screen and (max-width:1024px){
	.section_compte .obj_circle-progress .ctn_txt{
		font-size:var(--aox-fs-60px);
		}
	.section_compte .ctn_expert-score .v_logo{
		transform:translate(-50%,-50%) scale(0.2);
		}
	.section_compte .ctn_list-vip-expert .item_vip-expert .t_title{
		font-size:var(--aox-fs-10px);
		}
}
@media screen and (max-width:768px){
	.section_compte .ctn_dashboard{
		padding:0;
		}
	.section_compte .ctn_dashboard .ctn_col{
		width:100%;
		padding:var(--aox-50px) 0;
		margin:0 var(--aox-20px);
		flex-direction: column;
		justify-content: flex-start;
		}
	.section_compte .ctn_dashboard .ctn_col + .ctn_col{
		border-left:none;
		border-top:2px solid #dcdcdc;
		}
	.section_compte .ctn_dashboard .ctn_col .ctn_cta{
		position:relative;
		left:auto; bottom:auto;
		transform:translateY(0);
		}
	.section_compte .ctn_dashboard .ctn_col.col_info-perso .ctn_cta{
		margin-top:var(--aox-50px);
		}
	.section_compte .ctn_expert-score{
		margin-bottom:var(--aox-120px);
		padding-bottom:0;
		}
	.section_compte .ctn_expert-score .list_expert-score{
		flex-direction:column;
		padding-top:0;
		}
	.section_compte .ctn_expert-score .item_expert-score{
		margin:0 var(--aox-65px);
		padding:var(--aox-40px) 0;
		width:calc(100% - var(--aox-130px));
		}
	.section_compte .ctn_expert-score .v_logo{
		transform:translate(-50%,-50%) scale(0.5);
		}
	.section_compte .ctn_expert-score .item_expert-score + .item_expert-score{
		border-top:1px solid #c3c3c3;
		}
	.section_compte .ctn_expert-score .item_expert-score + .item_expert-score::before{
		display:none;
		}
	.section_compte .ctn_expert-score .t_percent{
		position:relative;
		left:auto; top:auto;
		font-size:var(--aox-fs-32px);
		margin-top:var(--aox-20px);
		}
	.section_compte .obj_star-score{
		position:relative;
		left:auto; top:auto;
		margin-top:var(--aox-20px);
		}
	.section_compte .obj_star-score .t_status{
		font-size:var(--aox-fs-18px);
		margin-top:calc(-1 * var(--aox-10px));
		}
	.section_compte .ctn_dashboard .ctn_col.col_percent {
		border-right: none;
		}
	.section_compte .obj_circle-progress-expertise {
		width: var(--aox-320px);
		height: var(--aox-320px);
		}
	.section_compte .ctn_progress-bar-expertise {
		width: 100%;
		padding: 0;
		margin-top: var(--aox-30px);
		}
	.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise .ctn_cta {
		opacity: 1;
		width: 100%;
		transform: none;
		margin-top: var(--aox-10px);
		}
	.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise .ctn_cta .obj_cta {
		font-size:var(--aox-fs-10px); line-height: 1;
		padding: var(--aox-10px) var(--aox-10px);
		height: var(--aox-40px);
		}
}
@media screen and (max-width:767px){
	.section_compte .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_compte .ctn_top{
		padding-top:var(--aox-40px);
		}
	.section_compte .ctn_top h1{
		font-size:var(--aox-fs-40px);
		}
	.section_compte .ctn_top h2{
		margin-top:var(--aox-40px);
		font-size:var(--aox-fs-17px);
		}
	.section_compte .ctn_dashboard .ctn_col .ctn_cta .obj_cta{
		max-width:80%;
		}
	.section_compte .ctn_expert-score .item_expert-score{
		margin:0 15.7%;
		width:68.6%;
		}
}
