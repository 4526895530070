/* ======================================== */
/* .pg_challenge
/* ======================================== */
@media screen and (max-width:767px){
	.pg_challenge .main_container {
		max-width: unset;
		width: 100%;
		}
	.pg_challenge .obj_breadcrumb{
		width: var(--aox-grid);
   		margin: 0 auto;
		}
	.pg_challenge .section_challenges.obj_section .ctn_section {
		width: 100%;
		}
	.pg_challenge .section_challenges.obj_section .ctn_section .ctn_title {
		max-width: unset;
		margin: 0px auto var(--aox-20px);
		width: var(--aox-grid);
		}
	.pg_challenge .section_challenges.obj_section .ctn_section .ctn_title .t_title{
		width: var(--aox-col-5);
		margin-bottom: 0;
		}
	.pg_challenge .section_challenges.obj_section .ctn_row > .ctn_filter {
		margin: 0 auto;
		}
	.pg_challenge .section_challenges.obj_section .ctn_row > .ctn_list_challenge {
		width: var(--aox-grid);
    	margin: var(--aox-30px) auto 0;
		}
	.section_challenges.obj_section .ctn_row > .ctn_aside{
		width: 100%;
		order: 2;
		margin: 0 auto;
		}
	
}