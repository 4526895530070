/* ======================================== */
/* .obj_badge-elite
/* ======================================== */
.obj_badge-elite{
	position:absolute;
	z-index:3;
	right:var(--aox-30px); top:var(--aox-210px);
	width:var(--aox-95px);
	}
.obj_badge-elite img{
	max-width:100%;
	}

@media screen and (max-width:1280px){
	.obj_badge-elite{
		top:var(--aox-155px);
		right:var(--aox-20px);
		}
}
@media screen and (max-width:768px){
	.obj_badge-elite{
		top:var(--aox-130px);
		}
}
@media screen and (max-width:767px){
	.obj_badge-elite{
		width:var(--aox-50px);
		}
}