/* ======================================== */
/* .pg_challenge-detail
/* ======================================== */

.pg_challenge-detail .obj_back-page {
    width: 100%;
    max-width: var(--aox-grid);
    margin: var(--aox-30px) auto 0 auto;
	}
.pg_challenge-detail .obj_back-page .bt_back-page{
	font-weight: 700;
    text-transform: uppercase;
	}

.pg_challenge-detail .obj_back-page + .obj_section{
	padding: var(--aox-20px) 0 var(--aox-90px) 0;
	}




@media screen and (max-width:767px){
	
}