/* ======================================== */
/* .obj_back-to-top
/* ======================================== */
.obj_back-to-top{
	position:fixed;
	z-index:999;
	right:0; bottom:25%;
	width:var(--aox-60px); height:var(--aox-60px);
	background:#000;
	transform:translateX(100%);
	transition:transform .3s ease;
	}
.obj_back-to-top.visible{
	transform:translateX(0);
	}
.obj_back-to-top::before{
	position:absolute;
	left:50%; top:60%;
	width:var(--aox-15px); height:var(--aox-15px);
	transform:rotate(-45deg) translate(-50%,-50%);
	transform-origin:0 0;
	border-top:3px solid #fff;
	border-right:3px solid #fff;
	content:'';
	transition:all .3s ease;
	}

@media screen and (min-width:1025px){
	.obj_back-to-top:hover::before{
		top:50%;
		}
}
@media screen and (max-width:767px){
	.obj_back-to-top{
		bottom:0;
		width:var(--aox-50px); height:var(--aox-50px);
		}
}