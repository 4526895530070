/* ======================================== */
/* .obj_bloc_formation
/* ======================================== */

.obj_bloc_formation{
	position: relative;
	width: 100%;
	letter-spacing: normal;
	background-color: #F2F2F2;
	overflow: hidden;
	}
.obj_bloc_formation .t_title {
	font-weight: 700;
	font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-110);
	margin-bottom: var(--aox-20px);
	text-transform: uppercase;
	text-align: center;
	}

/***/

.obj_bloc_formation .obj_header_formation{
	display: flex;
	padding: var(--aox-20px) var(--aox-50px);
	height: var(--aox-70px);
	flex-direction: row;
	align-items: center;
	letter-spacing: normal;
	transition:all var(--duration-500ms) ease;
	}
.obj_bloc_formation .obj_header_formation > *{
	transition:all var(--duration-500ms) ease;
	}
.obj_bloc_formation .obj_header_formation .t_title{
	margin-bottom: 0;
	}
.obj_bloc_formation .obj_header_formation .t_tag{
	font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-100);
	margin-left: var(--aox-20px);
	}
.obj_bloc_formation .obj_header_formation .t_tag span{
	display: inline-block;
    text-transform: uppercase;
    padding: var(--aox-7px) var(--aox-17px) var(--aox-6px) var(--aox-20px);
    border-radius: var(--aox-15px);
	/* background-color: rgba(0, 172, 194, 0.15); */
	letter-spacing: normal;
	}

.obj_bloc_formation .obj_header_formation .obj_progress-bar-expertise{
	display: flex;
	flex-direction: row;
    align-items: center;
	margin-left: auto;
	font-weight: 400;
	}
.obj_bloc_formation .obj_progress-bar-expertise .obj_progress-bar{
	width: var(--aox-210px);
	margin-left: var(--aox-16px);
	}
.obj_bloc_formation .obj_progress-bar-expertise .obj_progress-bar + span{
	margin-left: var(--aox-10px);
	}
.obj_bloc_formation .obj_progress-bar-expertise span strong{
	font-weight: 700;
	}


/***/
.obj_bloc_formation .t_legal{
	position: relative;
    letter-spacing: initial;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-130);
    color: rgba(0, 0, 0, 0.5);
    margin-top: var(--aox-10px);
	}
.obj_bloc_formation .t_legal.svg_info{
	padding-left: var(--aox-20px);
    margin-left: var(--aox-20px);
	}
.obj_bloc_formation .t_legal.svg_info:before {
	content: "";
	position: absolute;
	left: 0; top: 1px;
	height: var(--aox-12px);
	width: var(--aox-12px);
	background: url(../../images/i_info.svg) center center / var(--aox-12px) auto no-repeat;
	}
	
	
/***/

.obj_bloc_formation.is-expanded .obj_header_formation{
	padding: var(--aox-30px) var(--aox-70px);
	height: var(--aox-60px);
	}
.obj_bloc_formation.is-expanded .obj_header_formation > *{
	/* display: none; */
	opacity: 0;
	}
.obj_bloc_formation.is-expanded .obj_header_formation:before {
	transform: rotate(315deg);
	}


/***/

@media screen and (max-width:767px){

	/***/

	.obj_bloc_formation .ctn_bloc_exp > * {
		width: 100%;
		}
	.obj_bloc_formation .ctn_bloc_exp {
		padding: 0;
		}
	.obj_bloc_formation .obj_header_formation {
		flex-direction: column;
		align-items: flex-end;
		height: var(--aox-80px);
		justify-content: space-between;
		padding: var(--aox-15px) var(--aox-20px) var(--aox-13px);
		}
	.obj_bloc_formation .obj_header_formation:before {
		top: var(--aox-40px);
		left: calc(50% - var(--aox-8px));
		}

	.obj_bloc_formation .obj_header_formation .t_title{ 
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		order: 2;
		padding: 0 var(--aox-15px);
		font-size: var(--aox-fs-12px);
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		}
	.obj_bloc_formation .obj_header_formation .t_tag{ 
		order: 2;
		margin-left: 0;
		font-size: var(--aox-fs-20px);
		}
	.obj_bloc_formation .obj_header_formation .t_tag span{
		padding: 0;
		background-color: unset !important;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		}
	.obj_bloc_formation .obj_header_formation .t_tag span em{ 
		font-size: var(--aox-fs-10px);
		margin-left: var(--aox-3px);
		margin-bottom: var(--aox-3px);
		}
	.obj_bloc_formation .obj_header_formation .obj_progress-bar-expertise{
		flex-direction: column;
		align-items: flex-end;
		width: 60%;
		order: 3;
		margin-left: unset;
		}
	.obj_bloc_formation .obj_header_formation .obj_progress-bar-expertise span:nth-child(1){
		font-size: var(--aox-fs-11px);
		padding-right: var(--aox-50px);
		position: unset;
		}
	.obj_bloc_formation .obj_header_formation .obj_progress-bar-expertise > span{ 
		margin-top: 0;
		position: absolute;
		font-size: var(--aox-fs-11px);
		}
	.obj_bloc_formation .obj_progress-bar-expertise .obj_progress-bar{
		margin-left: 0;
		order: 3;
		margin-top: var(--aox-5px);
		}
	
}



