/* ======================================== */
/* .main_header
/* ======================================== */
.main_header{
	position:fixed;
	left:0; top:0;
	width:100%;
	z-index:9999;
	transition:transform .3s ease;
	}
/**/
.main_header .ctn_nav-caf{
	position:relative;
	z-index:10;
	height:var(--aox-50px);
	background:#f2f2f2;
	}
.main_header .ctn_nav-caf .str_container{
	display:flex;
	flex-wrap:wrap;
	}
.main_header .logo-caf{
	display:flex;
	align-items:center;
	width:var(--aox-130px);
	}
.main_header .logo-caf a{
	width:100%;
	}
.main_header .logo-caf img{
	max-width:var(--aox-130px);
	width:100%;
	}
/**/
.main_header .main_nav-caf ul.lvl_01{
	display:flex;
	padding-left:var(--aox-30px);
	height:100%;
	}
.main_header .main_nav-caf ul.lvl_01 > li > span,
.main_header .main_nav-caf ul.lvl_01 > li > a{
	position:relative;
	display:flex;
	align-items:center;
	height:100%;
	padding:0 var(--aox-15px);
	font-size:var(--aox-fs-10px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	letter-spacing:0.1em;
	}
.main_header .main_nav-caf ul.lvl_01 > li > span::before,
.main_header .main_nav-caf ul.lvl_01 > li > a::before{
	position:absolute;
	left:var(--aox-20px); bottom:0;
	width:calc(100% - var(--aox-40px)); height:var(--aox-5px);
	opacity:0;
	background:#000;
	transition:all .3s ease;
	content:'';
	}
/**/
.main_header .main_nav-caf .sub_nav-caf{
	position:absolute;
	left:0; top:var(--aox-50px);
	width:100%; max-height:0;
	background:#fff;
	transition:all .5s ease;
	overflow:hidden;
	}
.main_header .main_nav-caf .sub_nav-caf .str_container{
	padding:var(--aox-20px) 0;
	}
.main_header .main_nav-caf .sub_nav-caf ul{
	padding:var(--aox-20px) 0;
	width:16.66%;
	}
.main_header .main_nav-caf .sub_nav-caf ul li a{
	display:block;
	padding:var(--aox-10px);
	font-size:var(--aox-fs-15px); line-height:1;
	color:#777;
	}
.main_header .main_nav-caf .sub_nav-caf ul li a:hover{
	text-decoration: underline;
	color:#000;
	}
.main_header .main_nav-caf .sub_nav-caf ul li:first-child a{
	font-size:var(--aox-fs-14px);
	font-weight:700;
	text-transform:uppercase;
	color:#000;
	}
/**/
.main_header .main_nav-caf .sub_nav-caf.var_small{
	position:absolute;
	left: auto; top:var(--aox-50px);
	width: var(--aox-250px);
    max-height:0;
	background:#fff;
	transition:all .5s ease;
	overflow:hidden;
	}
.main_header .main_nav-caf .sub_nav-caf.var_small .str_container{
	padding: var(--aox-40px) var(--aox-20px) var(--aox-40px);
	}
.main_header .main_nav-caf .sub_nav-caf.var_small ul{
	padding:0 0;
	width:100%;
	}
.main_header .main_nav-caf .sub_nav-caf.var_small ul li a{
	display: block;
    padding: var(--aox-10px);
    font-size: var(--aox-fs-15px);
    line-height: 1;
    color: #777;
	}
.main_header .main_nav-caf .sub_nav-caf.var_small ul li:first-child a{
	font-weight: 300;
    text-transform: none;
	}
/* .main_header .main_nav-caf .sub_nav-caf.var_small ul li + li{
	border-top: 1px solid #b2b2b2;
	} */
/**/
.main_header .second_nav-caf{
	margin-left:auto;
	}
.main_header .second_nav-caf ul.lvl_01{
	display:flex;
	height:100%;
	}
.main_header .second_nav-caf ul.lvl_01 > li{
	display:flex;
	align-items:center;
	font-size:var(--aox-fs-11px); line-height:1;
	}
.main_header .second_nav-caf ul.lvl_01 > li > span{
	font-weight: 500;
	text-transform: uppercase;
	}
.main_header .second_nav-caf ul.lvl_01 > li > strong,
.main_header .second_nav-caf ul.lvl_01 > li > span > strong{
	font-weight:700;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte{
	position: relative;
    width: var(--aox-250px);
    padding: 0 var(--aox-37px) 0 0;
    margin: 0 var(--aox-15px) 0 0px;
	justify-content: flex-end;
    transition: all .3s ease;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte:hover{
	cursor: pointer;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte svg{
	position:absolute;
	left:var(--aox-25px); top:50%;
	transform:translateY(-50%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte::before{
	position: absolute;
    right: var(--aox-13px); top: calc(50% - var(--aox-4px));
    width: var(--aox-8px); height: var(--aox-8px);
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    transform: translateY(-50%) rotate(45deg) scale(1);
    transition: all .3s ease;
    content: '';
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte ul.lvl_02{
	position:absolute;
	left:0; top:var(--aox-50px);
	width:100%; max-height:0;
	padding:0 var(--aox-20px);
	background:#fff;
	overflow:hidden;
	opacity:0;
	transition:all .3s ease;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte ul.lvl_02 li a{
	display: block;
    padding: var(--aox-10px);
    font-size: var(--aox-fs-15px);
    line-height: 1;
    color: #000;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte ul.lvl_02 li a:hover{
	text-decoration: underline;
	color:#000;
	}
.main_header .second_nav-caf .li_expert{
	padding:0 var(--aox-15px);
	}
.main_header .second_nav-caf .li_expert strong{
	padding-left:var(--aox-5px);
	}
.main_header .second_nav-caf .li_contact a{
	display:flex;
	align-items:center;
	padding:0 var(--aox-15px);
	height:100%;
	}

/**/

.main_header .second_nav-caf ul.lvl_01 > li.li_expertise{
	position: relative;
    width: var(--aox-92px); height: var(--aox-36px);
	margin-top: var(--aox-7px);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise > .border_expertise{
	position: relative;
	width:100%; height:100%;
    border-radius: var(--aox-19px);
	overflow: hidden;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise > .border_expertise::before{
	position: absolute;
	left: 0; top: 50%;
	content:'';
	width:var(--aox-94px); height:var(--aox-94px);
	margin-top: calc(var(--aox-47px) * -1);
    border-radius: var(--aox-19px);
	background: #0052A2;
	/* background: linear-gradient(90deg, rgba(243,204,99,1) 0%, rgba(159,200,176,1) 33%, rgba(74,139,184,1) 66%, rgba(56,56,56,1) 100%); */
	background-size: 100% 100%;
	animation: animatedgradient 8s ease-in-out infinite;
	}

.main_header .second_nav-caf ul.lvl_01 > li.li_expertise > .border_expertise::before{
	background: linear-gradient(120deg, #D9D9D9 0%, #2570B8 25%, #009BD9 50%, #008BB0 75%, #206170 100%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.is_gold > .border_expertise::before{
	background: linear-gradient(120deg, #FBC900 0%, #D29D30 30%, #A56B13 33%, #F8D984 43%, #F8C13F 60%, #E4AC2B 75%, #F9E3B0 100%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.is_silver > .border_expertise::before{
	background: linear-gradient(120deg, #DDE0E1 0%, #606263 30%, #DEDFE1 33%, #CDD5D8 43%, #969EA2 60%, #939798 75%, #CFCFCB 100%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.is_bronze > .border_expertise::before{
	background: linear-gradient(120deg, #EED7C6 0%, #743C1B 30%, #F3C2A7 33%, #E5B092 43%, #A16C5E 60%, #956E5F 75%, #E19D87 100%);
	}




.main_header .second_nav-caf ul.lvl_01 > li.li_expertise > .border_expertise::after{
	position: absolute;
	left:var(--aox-2px); top:var(--aox-2px);
	content:'';
	width:var(--aox-88px); height:var(--aox-32px);
    border-radius: var(--aox-19px);
	background: rgba(255,255,255,0.8);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_expertise{
	position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: var(--aox-88px);
    height: var(--aox-32px);
    transform: translate(-50%, -50%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_visuel{
	position: relative;
	width:var(--aox-28px); height: var(--aox-28px);
	margin: var(--aox-2px) 0 var(--aox-2px) var(--aox-2px);
	border-radius: 100%;
	overflow: hidden;
	background: #2FACC2;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_visuel img{
	width:100%; height: 100%;
	object-fit: cover;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_pourcent {
	position: relative;
    display: flex;
    text-align: right;
    width: calc(100% - var(--aox-30px));
    padding-right: var(--aox-2px);
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_pourcent span{
	display: block;
    font-weight: 700;
    font-size: var(--aox-fs-10px);
    line-height: var(--aox-fs-14px);
    letter-spacing: -0.25px;
	margin-top: var(--aox-2px);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_pourcent span strong{
	font-size:var(--aox-fs-13px);
	}

.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_badge{
	display:flex;
	justify-content:center;
	align-items:center;
	position:absolute;
	z-index:2;
	left:0; bottom:0;
	width:100%; height:0;
	background:rgba(47,172,194,0.3);
	border-radius:var(--aox-19px);
	opacity:0;
	overflow:hidden;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_badge::before{
	content:'';
	position:absolute;
	z-index:1;
	left:0; bottom:0;
	width:100%; height:0;
	background:rgba(47,172,194,0.3);
	opacity:0;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_badge img{
	position:relative;
	z-index:2;
	width:var(--aox-25px); height:var(--aox-25px);
	opacity:0;
	}

@keyframes animatedgradient {
	0% {
		transform: rotate(0deg);
	}
	/* 50% {
		transform: rotate(180deg);
	} */
	100% {
		transform: rotate(360deg);
	}
}

.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_visuel{
	animation:expFlipY1 1.7s ease-out both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_visuel img{
	animation:expHide1 1.7s ease-out both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge > .border_expertise{
	animation:expFlipY2 1.8s ease-out both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_pourcent{
	animation:expHide2 0.3s ease-out both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_badge{
	animation:expFlipY3 1.5s ease-out 1.5s both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_badge::before{
	animation:expFlipY3 1.5s ease-out 1.6s both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_badge img{
	animation:expFlipY4 1.5s ease-out 1.7s both;
	}

@keyframes expHide1{
	0%{opacity:1;}
	60%{opacity:1;}
	65%{opacity:0;}
	100%{opacity:0;}
}
@keyframes expHide2{
	0%{opacity:1;}
	100%{opacity:0;}
}
@keyframes expFlipY1{
	0%{transform:scaleY(1) translateY(0);}
	40%{transform:scaleY(1) translateY(calc(var(--aox-5px) * -1));}
	60%{transform:scaleY(0) translateY(0);}
	80%{transform:scaleY(1) translateY(0);}
	100%{transform:scaleY(0) translateY(0);}
}
@keyframes expFlipY2{
	0%{transform:scaleY(1);}
	40%{transform:scaleY(1);}
	60%{transform:scaleY(0);}
	80%{transform:scaleY(1);}
	100%{transform:scaleY(0);}
}
@keyframes expFlipY3{
	0%{transform:translateY(0); opacity:0; height:0;}
	20%{transform:translateY(0); opacity:1; height:100%;}
	80%{transform:translateY(calc(var(--aox-5px) * -1)); opacity:1; height:100%;}
	100%{transform:translateY(calc(var(--aox-5px))); opacity:0; height:0;}
}
@keyframes expFlipY4{
	0%{transform:scaleY(0) translateY(0); opacity:0;}
	20%{transform:scaleY(1) translateY(0); opacity:1;}
	80%{transform:scaleY(1) translateY(calc(var(--aox-5px) * -1)); opacity:1;}
	100%{transform:scaleY(0) translateY(calc(var(--aox-5px))); opacity:0;}
}

/**/

.main_header .ctn_nav-marque{
	display:none;
	position:relative;
	z-index:1;
	height:var(--aox-115px);
	padding-top:var(--aox-45px);
	background:#fff;
	transition:all .3s ease;
	}
.main_header.mode_mini .ctn_nav-marque{
	height:var(--aox-60px);
	padding-top:0;
	}
.main_header.mode_mini .ctn_nav-marque .logo-marque{
	display:flex;
	align-items:center;
	}
.main_header .ctn_nav-marque .str_container{
	display:flex;
	border-bottom:1px solid #d7d7d7;
	}
.main_header .ctn_nav-marque .logo-marque img{
	width:100%; height:auto;
	transition:all .3s ease;
	}
/**/
.var_lrp .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-175px);
	}
.var_vichy .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-125px);
	}
.var_sanoflore .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-125px);
	}
.var_cerave .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-140px);
	}
.var_skinc .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-220px);
	}
.var_biotherm .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-125px);
	}
.var_sciencemd .main_header .ctn_nav-marque .logo-marque img{
	max-width: var(--aox-165px);
    margin-top: var(--aox-15px);
	}
.var_derm-expertise .main_header .ctn_nav-marque .logo-marque img{
	max-width: var(--aox-170px);
	}

.var_lrp .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-130px);
	}
.var_vichy .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-95px);
	}
.var_sanoflore .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-95px);
	}
.var_cerave .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-100px);
	}
.var_skinc .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-165px);
	}
.var_biotherm .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-95px);
	}
.var_sciencemd .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-120px);
	margin-top: 0; 
	}
.var_derm-expertise .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-140px); 
	}


/**/
.main_header .main_nav-marque ul.lvl_01{
	display:flex;
	padding-left:var(--aox-25px);
	height:100%;
	}
.main_header .main_nav-marque ul.lvl_01 > li > a{
	position:relative;
	display:flex;
	align-items:center;
	height:100%;
	padding:0 var(--aox-15px);
	font-size:var(--aox-fs-13px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	letter-spacing:0.1em;
	}
.main_header .main_nav-marque ul.lvl_01 > li > a::before{
	position:absolute;
	left:var(--aox-15px); bottom:-1px;
	width:calc(100% - var(--aox-30px)); height:5px;
	opacity:0;
	transition:all .3s ease;
	content:'';
	}

@media screen and (min-width:768px){
	.main_header.mode_mini{
		transform:translateY(calc(-1 * var(--aox-50px)));
		}
	.main_header .main_nav-caf li:hover .sub_nav-caf{
		max-height:90vh;
		overflow-y:auto;
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte:hover{
		background:#fff;
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte:hover ul.lvl_02{
		opacity:1;
		max-height:var(--aox-270px);
		padding: var(--aox-40px) var(--aox-20px) var(--aox-40px);
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte:hover::before{
		transform:translateY(-10%) rotate(45deg) scale(-1);
		}
	.main_header .main_nav-marque ul.lvl_01 > li:hover > a::before,
	.main_header .main_nav-marque ul.lvl_01 > li > a.active::before{
		opacity:1;
		}
	.main_header .main_nav-caf ul.lvl_01 > li:hover > span::before,
	.main_header .main_nav-caf ul.lvl_01 > li:hover > a::before{
		opacity:1;
		}
	.main_header .bt_burger,
	.main_header .ctn_nav-mobile,
	.main_header .obj_avatar-expertise{
		display:none;
		}
}
@media screen and (min-width:768px) and (max-width:1280px){
	.main_header .main_nav-marque ul.lvl_01 > li > a::before,
	.main_header .main_nav-caf ul.lvl_01 > li > span::before,
	.main_header .main_nav-caf ul.lvl_01 > li > a::before{
		left:1.2vw;
		width:calc(100% - 2.4vw);
		}
}
@media screen and (max-width:1280px){
	.main_header .ctn_nav-marque{
		padding-top:0;
		height:var(--aox-60px);
		}
	.main_header .ctn_nav-marque .logo-marque{
		display:flex;
		align-items:center;
		width:15vw;
		padding-left:1.5vw;
		}
	.main_header .main_nav-caf ul.lvl_01,
	.main_header .main_nav-marque ul.lvl_01{
		padding-left:1.5vw;
		}
	.main_header .main_nav-caf ul.lvl_01 > li > span,
	.main_header .main_nav-caf ul.lvl_01 > li > a,
	.main_header .main_nav-marque ul.lvl_01 > li > a{
		padding:0 1.2vw;
		}
	.main_header .main_nav-caf ul.lvl_01 > li > span,
	.main_header .main_nav-caf ul.lvl_01 > li > a{
		font-size:0.75vw;
		}
	.main_header .main_nav-marque ul.lvl_01 > li > a{
		font-size:0.9vw;
		}
	.main_header .second_nav-caf{
		/* width: 30vw; */
		/* padding-right: 1.5vw; */
		}
	.main_header .second_nav-caf ul.lvl_01 {
		justify-content: flex-end;
		}
	.main_header .second_nav-caf ul.lvl_01 > li{
		font-size:0.85vw;
		}
	.main_header .second_nav-caf .li_expert,
	.main_header .second_nav-caf .li_contact a{
		padding:0 1.2vw;
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte{
		width:18vw;
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte ul.lvl_02 li a{
		font-size:1vw;
		}
	.main_header .main_nav-caf .sub_nav-caf .str_container{
		padding:var(--aox-20px);
		}
	.main_header .main_nav-caf .sub_nav-caf ul li a{
		font-size:1.1vw;
		}
	.main_header .main_nav-caf .sub_nav-caf ul li a strong{
		font-size:1vw;
		}
}
@media screen and (max-width:768px){
	.main_header .main_nav-caf .sub_nav-caf ul li:first-child a{
		font-size:var(--aox-fs-10px)
		}
}
@media screen and (max-width:767px){
	.main_header{
		position: relative;
		}
	.main_header .ctn_nav-caf{
		background:none;
		height: var(--aox-70px);
		}
	.main_header .ctn_nav-caf .str_container{
		position:relative;
		background:#f2f2f2;
		}
	.menu-mobile_is-opened .main_header{
		position:relative;
		left:auto; top:auto;
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		margin:0 auto;
		overflow:hidden;
		}
	.menu-mobile_is-opened .main_header .ctn_nav-caf{
		position:fixed;
		left:0; top:0;
		width:100%;
		}
	.main_header .ctn_nav-marque .logo-marque{
		width:100vw;
		padding-left:0;
		justify-content:center;
		}
	.menu-mobile_is-opened .main_header .ctn_nav-marque{
		display:none;
		}
	.main_header .main_nav-marque,
	.main_header .main_nav-caf,
	.main_header .second_nav-caf{
		display:none;
		}
	/**/
	.main_header .bt_burger{
		position: absolute;
		left: var(--aox-20px); top: 50%;
		width: 40px; height: 40px;
		padding: 12px 10px;
		transform: translateY(-50%);
		z-index: 3;
		}
	.main_header .bt_burger span{
		position:relative;
		display: block;
		width:20px; height:2px;
		background:#000;
		transition-timing-function: cubic-bezier(.55,.055,.675,.19);
		transition-duration: .22s;
		}
	.main_header .bt_burger span::before,
	.main_header .bt_burger span::after{
		position:absolute;
		width:20px; height:2px;
		left: 0;
		background:#000;
		content:'';
		border-radius: 4px;
		/* transition:all .3s ease; */
		}
	.main_header .bt_burger span::before{
		top: -7px;
		transition: top .1s ease-in .25s,opacity .1s ease-in;
		}
	.main_header .bt_burger span::after{
		bottom: -7px;
		transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
		}
	.menu-mobile_is-opened .main_header .bt_burger span{
		transition-delay: .12s;
    	transition-timing-function: cubic-bezier(.215,.61,.355,1);
    	transform: rotate(225deg);
		}
	.menu-mobile_is-opened .main_header .bt_burger span::before{
		top: 0;
    	transition: top .1s ease-out,opacity .1s ease-out .12s;
    	opacity: 0;
		}
	.menu-mobile_is-opened .main_header .bt_burger span::after{
		bottom: 0;
    	transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
    	transform: rotate(-90deg);
		}
	/**/
	.main_header .logo-caf{
		width:var(--aox-150px);
		margin:0 auto;
		}
	.menu-mobile_is-opened .main_header .logo-caf{
		display:none;
		}
	.main_header .logo-caf img{
		max-width:var(--aox-150px);
		width:100%;
		}
	/**/
	.var_lrp .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-130px);
		}
	.var_vichy .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-95px);
		}
	.var_sanoflore .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-95px);
		}
	.var_cerave .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-100px);
		}
	.var_skinc .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-165px);
		}
	.var_biotherm .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-95px);
		}
	.var_sciencemd .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-120px);
		margin-top: 0;
		}
	/**/
	.main_header .ctn_nav-mobile{
		display:none;
		padding-top:var(--aox-85px);
		}
	.main_header .ctn_nav-mobile .ctn_panels{
		display:flex;
		width:10000vw;
		transition:transform .5s ease;
		}
	.main_header .ctn_nav-mobile .ctn_panel{
		width:100vw;
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.menu-mobile_is-opened .main_header .ctn_nav-mobile{
		display:block;
		}
	.main_header .ctn_nav-mobile .bt_title{
		position:fixed;
		z-index:10;
		left:50%; top:var(--aox-50px);
		width:100%; height:var(--aox-35px);
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		padding:0 var(--aox-50px) var(--aox-20px) var(--aox-50px);
		font-size:var(--aox-fs-16px); line-height:1;
		font-weight:700;
		text-align:left;
		text-transform:uppercase;
		/* background:#f2f2f2; */
		transform:translateX(-50%);
		}
	.main_header .ctn_nav-mobile .bt_title::before{
		position:absolute;
		left:var(--aox-20px); top:2px;
		width:8px; height:8px;
		border-bottom:2px solid #000;
		border-left:2px solid #000;
		transform:rotate(45deg);
		transition:opacity .3s ease;
		content:'';
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile{
		background:#fff;
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li{
		position:relative;
		border-bottom:1px solid #c3c3c3;
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li a,
	.main_header .ctn_nav-mobile ul.main_nav-mobile li button{
		display:block;
		padding:var(--aox-25px) var(--aox-50px);
		width:100%;
		font-size:var(--aox-fs-12px); line-height:1;
		font-weight:700;
		text-transform:uppercase;
		text-align:left;
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li button::before{
		position:absolute;
		right:var(--aox-30px); top:50%;
		width:8px; height:8px;
		border-top:2px solid #000;
		border-right:2px solid #000;
		transform:translateY(-50%) rotate(45deg);
		content:'';
		}
	.main_header .ctn_nav-mobile ul.second_nav-mobile{
		padding:var(--aox-20px) 0;
		background:#fff;
		}
	.main_header .ctn_nav-mobile ul.second_nav-mobile li a{
		display:block;
		padding:var(--aox-10px) var(--aox-50px);
		font-size:var(--aox-fs-11px); line-height:1;
		font-weight:700;
		text-transform:uppercase;
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li.li_compte button,
	.main_header .ctn_nav-mobile ul.main_nav-mobile li.li_contact a{
		padding-left:var(--aox-85px);
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li.li_compte svg,
	.main_header .ctn_nav-mobile ul.main_nav-mobile li.li_contact svg{
		position:absolute;
		left:var(--aox-50px); top:50%;
		transform:translateY(-50%);
		}
	/**/
	.main_header .ctn_nav-mobile[data-level="1"] .ctn_panels{
		transform:translateX(0);
		}
	.main_header .ctn_nav-mobile[data-level="1"] .bt_title::before{
		opacity:0;
		}
	.main_header .ctn_nav-mobile[data-level="2"] .ctn_panels{
		transform:translateX(calc(-1 * var(--aox-410px) + var(--aox-4px)));
		}
	.main_header .ctn_nav-mobile[data-level="3"] .ctn_panels{
		transform:translateX(calc(-1 * var(--aox-820px) + var(--aox-8px)));
		}
	
	/**/

	.main_header .obj_avatar-expertise{
		position: absolute;
		right: var(--aox-17px); top: 50%;
		width: 36px; height: 36px;
		transform: translateY(-50%);
		}
	.main_header .obj_avatar-expertise .border_expertise{
		position:relative;
		width:100%; height:100%;
		border-radius:100vw;
		overflow:hidden;
		}
	.main_header .obj_avatar-expertise .border_expertise::before{
		content:'';
		position:absolute;
		left:0; top: 50%;
		width:36px; height:36px;
		margin-top:-18px;
		border-radius:100vw;
		background-size:100% 100%;
		animation:animatedgradient 8s ease-in-out infinite;
		}
	.main_header .obj_avatar-expertise .border_expertise::before{
		background: linear-gradient(115deg, #D9D9D9 0%, #2570B8 25%, #009BD9 50%, #008BB0 75%, #206170 100%);
		}
	.main_header .obj_avatar-expertise.is_gold .border_expertise::before{
		background: linear-gradient(120deg, #FBC900 0%, #D29D30 30%, #A56B13 33%, #F8D984 43%, #F8C13F 60%, #E4AC2B 75%, #F9E3B0 100%);
		}
	.main_header .obj_avatar-expertise.is_silver .border_expertise::before{
		background: linear-gradient(120deg, #DDE0E1 0%, #606263 30%, #DEDFE1 33%, #CDD5D8 43%, #969EA2 60%, #939798 75%, #CFCFCB 100%);
		}
	.main_header .obj_avatar-expertise.is_bronze .border_expertise::before{
		background: linear-gradient(120deg, #EED7C6 0%, #743C1B 30%, #F3C2A7 33%, #E5B092 43%, #A16C5E 60%, #956E5F 75%, #E19D87 100%);
		}


	.main_header .obj_avatar-expertise .border_expertise::after{
		position: absolute;
		left: 50%; top: 50%;
		content: '';
		width: 32px; height: 32px;
		border-radius: 100vw;
		transform: translate(-50%,-50%);
		background: rgba(255,255,255,0.8);
		}
	.main_header .obj_avatar-expertise .ctn_expertise{
		position: absolute;
		padding: 2px;
		z-index: 1;
		left: 50%; top: 50%;
		border-radius: 100vw;
		width: 32px; height: 32px;
		transform: translate(-50%,-50%);
		}
	.main_header .obj_avatar-expertise .ctn_visuel{
		position:relative;
		width: 28px; height: 28px;
		border-radius:100%;
		overflow:hidden;
		}
	.main_header .obj_avatar-expertise .ctn_visuel img{
		width:100%; height:100%;
		}
	.main_header .obj_avatar-expertise .ctn_badge{
		display:flex;
		justify-content:center;
		align-items:center;
		position:absolute;
		z-index:2;
		left:0; bottom:0;
		width:100%; height:0;
		background:rgba(47,172,194,0.3);
		border-radius:var(--aox-19px);
		opacity:0;
		overflow:hidden;
		}
	.main_header .obj_avatar-expertise .ctn_badge::before{
		content:'';
		position:absolute;
		z-index:1;
		left:0; bottom:0;
		width:100%; height:0;
		background:rgba(47,172,194,0.3);
		opacity:0;
		}
	.main_header .obj_avatar-expertise .ctn_badge img{
		position:relative;
		z-index:2;
		width:var(--aox-28px); height:var(--aox-28px);
		opacity:0;
		}
	
	.main_header .obj_avatar-expertise.new_badge .ctn_visuel{
		animation:expFlipY1 1.7s ease-out both;
		}
	.main_header .obj_avatar-expertise.new_badge .ctn_visuel img{
		animation:expHide1 1.7s ease-out both;
		}
	.main_header .obj_avatar-expertise.new_badge .border_expertise{
		animation:expFlipY2 1.8s ease-out both;
		}
	.main_header .obj_avatar-expertise.new_badge .ctn_badge{
		animation:expFlipY3 1.5s ease-out 1.5s both;
		}
	.main_header .obj_avatar-expertise.new_badge .ctn_badge::before{
		animation:expFlipY3 1.5s ease-out 1.6s both;
		}
	.main_header .obj_avatar-expertise.new_badge .ctn_badge img{
		animation:expFlipY4 1.5s ease-out 1.7s both;
		}


}
@media screen and (max-width:414px){
	.main_header .ctn_nav-mobile[data-level="2"] .ctn_panels{
		transform:translateX(-100vw);
		}
	.main_header .ctn_nav-mobile[data-level="3"] .ctn_panels{
		transform:translateX(-200vw);
		}
}