/* ======================================== */
/* .obj_temps_restant
/* ======================================== */
.obj_temps_restant{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    }
.obj_temps_restant .tag_title{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    text-transform: uppercase;
    padding: var(--aox-8px) var(--aox-15px);
    }
.obj_temps_restant .tag_title span{
    color: #ffffff;
    }
.obj_temps_restant .obj_jours-restant{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_temps_restant .obj_jours-restant span{
    font-weight: 700;
    font-size: var(--aox-fs-42px);
    line-height: var(--aox-lh-90);
    }
.obj_temps_restant .obj_jours-restant p{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: var(--aox-fs-12px);
    margin-left: var(--aox-10px);
    }
.obj_temps_restant .obj_jours-restant p strong{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    }

/***/
.obj_temps_restant .obj_progressbar-challenge{
    margin-top: var(--aox-10px);
    }

/***/
.obj_temps_restant .obj_jours-restant-info{
    margin-top: var(--aox-6px);
    max-width: var(--aox-370px);
    }
.obj_temps_restant .obj_jours-restant-info p{
	font-size: var(--aox-fs-12px);
    line-height: 1;
    font-weight: 500;
    color: #ff4847;
    }

.en_attente .obj_temps_restant .obj_jours-restant-info{ margin-top: var(--aox-10px); }
.en_attente .obj_temps_restant .obj_jours-restant-info p{ font-size: var(--aox-fs-13px); line-height: var(--aox-lh-120); }

/***/
.declarer .obj_temps_restant .obj_jours-restant span,
.declarer .obj_temps_restant .obj_jours-restant p strong{ color: #3aaac1; }

.repartir .obj_temps_restant .obj_jours-restant span,
.repartir .obj_temps_restant .obj_jours-restant p strong{ color: #892bad; }

.en_attente .obj_temps_restant .obj_jours-restant span,
.en_attente .obj_temps_restant .obj_jours-restant p strong{ color: #F7B15E; }

.en_attente .obj_temps_restant .obj_jours-restant-info p{ color: #F8792D; }

.remporte .obj_temps_restant .obj_jours-restant span,
.remporte .obj_temps_restant .obj_jours-restant p strong{ color: #ACDE93; }

.echoue .obj_temps_restant .obj_jours-restant span,
.echoue .obj_temps_restant .obj_jours-restant p strong{ color: #C4C4C4; }   


@media screen and (max-width:767px){

}