/* -------------------------------------------------- */

/*   $ > AOX Init (v2.0.1) - 04/04/23

/* -------------------------------------------------- */

/* ======================================== */
/*  Init
/* ======================================== */

/*

1920 > 1366px(*) => Fixed
1365px > 768px => Fluid
767px > 520px => Fixed
519px > 320px => Fluid

(*) 1366px = Content = margin left (45px) + content width (1276px) + margin right (45px)

*/

:root {

	--aox-1px: 1px;

    --aox-grid: var(--aox-1280px);
	--aox-gutter: var(--aox-40px);
	--aox-columns: 12;
	
	--aox-fs-viewport-width: 1366px;
	--aox-fs-max-width: 1366;
	--aox-fs-min-width: calc(var(--aox-fs-max-width) * 0.75);

	}

/***/

@media screen and (max-width: 1366px) {

	:root {

		--aox-1px: calc(100vw / 1366);

		--aox-fs-viewport-width: 100vw;
		
		}
	
	}

/***/

@media screen and (max-width: 767px) {

	:root {

		--aox-1px: calc(520px / 420);
		
		--aox-grid: var(--aox-380px);
		--aox-gutter: var(--aox-20px);

		--aox-fs-max-width: 420;
		--aox-fs-min-width: 320;
		--aox-fs-viewport-width: 520px;
		
		}
	
	}

/***/

@media screen and (max-width: 520px) {
	
	:root {
	
		--aox-1px: calc(100vw / 420);
	
		--aox-fs-viewport-width: 100vw;
	
		}
	
	}