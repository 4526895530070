/* ======================================== */
/* .obj_entry_hero_home{
/* ======================================== */

.obj_entry_hero_home{
	position: relative;
	padding: var(--aox-10px) var(--aox-90px) var(--aox-50px);
	background-color: #fff;
	margin-top: var(--aox-10px);
	min-height: var(--aox-240px);
	animation:effectHeroEntry 1s ease 2000ms forwards;
	opacity: 0;
	}
.obj_entry_hero_home .ctn_entry_hero_home{
	position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry{
	display: flex;
    position: relative;
    width: var(--aox-320px);
    text-align: center;
    flex-direction: column;
    align-items: center;
	opacity: 0;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry:first-child{
	animation:effectHeroEntry .8s ease 2150ms forwards;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry:nth-child(2){
	animation:effectHeroEntry .8s ease 2250ms forwards;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry:last-child{
	animation:effectHeroEntry .8s ease 2350ms forwards;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry a{
	display: flex;
	position: relative;
	width: var(--aox-320px);
	text-align: center;
	flex-direction: column;
	align-items: center;
	}

.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_picto{
	position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: var(--aox-64px);
    width: var(--aox-64px);
    margin: var(--aox-40px) var(--aox-10px) var(--aox-10px);
	transition: all .3s ease;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_picto span{
	position: absolute;
    display: flex;
    right: calc(-1 * var(--aox-5px));
    top: calc(-1 * var(--aox-7px));
    color: white;
    font-size: var(--aox-fs-12px);
    font-weight: 700;
    background-color: #00ACC2;
    height: var(--aox-24px);
    width: var(--aox-24px);
    border-radius: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_picto img{
	filter: drop-shadow(0 13px 21px rgba(10, 118, 132, .8));
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry a:hover .obj_entry_picto{
	transform: translateY(var(--aox-5px));
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry a:hover .obj_entry_picto img{
	filter: drop-shadow(0 0px 7px rgba(10, 118, 132, .3));
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .t_title{
	font-size: var(--aox-fs-20px);
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	margin:var(--aox-10px);	
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info{
	position: relative;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info li{
	position: relative;
	padding: var(--aox-3px) var(--aox-16px) var(--aox-5px);
	border-radius: var(--aox-16px);
	background-color: rgba(0, 172, 194, .2);
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info li.is_empty{
	background-color: rgba(242, 242, 242, 1);
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info li span{
	display: block;
	font-size: var(--aox-fs-13px);
	font-weight: 500;
	color: rgba(0, 0, 0, .5);
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info li + li{
	margin-top:var(--aox-2px);	
	}


@media screen and (max-width:767px){

	.obj_entry_hero_home{
		padding: var(--aox-10px) var(--aox-20px) var(--aox-40px) var(--aox-20px);
		left: calc(-1 * var(--aox-external-gutter));
		width: 100vw;
		margin-top: var(--aox-20px);
		min-height: unset;
		}
	.obj_entry_hero_home .ctn_entry_hero_home {
		justify-content: space-between;
		}
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry,
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry a {
		width: var(--aox-120px);
		}
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .t_title {
		font-size: var(--aox-fs-10px);
		margin: 0;
		}
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info {
		display: none;
		}
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_picto {
		height: var(--aox-56px);
		width: var(--aox-56px);
		margin: var(--aox-20px) 0 var(--aox-15px);
		}


	}