/* ======================================== */
/* .section_galerie-photo_liste
/* ======================================== */
.section_galerie-photo_liste{
	width:100%;
	}
.section_galerie-photo_liste .str_container{
	position:relative;
	max-width:var(--aox-1220px);
	margin-top:var(--aox-40px);
	padding-top:var(--aox-80px);
	}
.section_galerie-photo_liste .str_container::before{
	content:'';
	position:absolute;
	left:50%; top:0;
	width:100%; max-width:var(--aox-1150px);
	height:1px;
	background:#c3c3c3;
	transform:translateX(-50%);
	}
.section_galerie-photo_liste .ctn_galerie-photo_liste{
	display:flex;
	flex-wrap:wrap;
	}
@media screen and (max-width:767px){
	.section_galerie-photo_liste .str_container{
		padding-top:var(--aox-60px);
		}
}