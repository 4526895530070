/* ======================================== */
/* .section_galerie-photo_intro
/* ======================================== */
.section_galerie-photo_intro{
	width:100%;
	}
.section_galerie-photo_intro .str_container{
	max-width:var(--aox-1150px);
	}
/**/
.section_galerie-photo_intro .ctn_galerie-photo{
	padding:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	}
.section_galerie-photo_intro .ctn_galerie-photo h2{
	font-size:var(--aox-fs-20px); line-height:1.2;
	text-align:center;
	}
.section_galerie-photo_intro .ctn_galerie-photo h2 strong{
	font-weight:700;
	}
.section_galerie-photo_intro .ctn_galerie-photo p img{
	display:inline-block;
	margin:var(--aox-40px) 0;
	}
.section_galerie-photo_intro .ctn_galerie-photo figure{
	display:flex;
	justify-content:center;
	padding:var(--aox-40px) 0;
	}
.section_galerie-photo_intro .ctn_galerie-photo figure figcaption{
	padding-top:var(--aox-50px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	text-align:right;
	}
.section_galerie-photo_intro .ctn_galerie-photo figure figcaption strong{
	font-weight:700;
	}
.section_galerie-photo_intro .ctn_galerie-photo .ctn_cta{
	text-align:center;
	}
.section_galerie-photo_intro .ctn_galerie-photo h2 + .ctn_cta,
.section_galerie-photo_intro .ctn_galerie-photo .ctn_cta + .ctn_cta{
	margin-top:var(--aox-30px);
	}
@media screen and (max-width:767px){
	.section_galerie-photo_intro .ctn_galerie-photo h2 br{
		display:none;
		}
	.section_galerie-photo_intro .ctn_galerie-photo figure figcaption{
		font-size:4vw;
		}
	.section_galerie-photo_intro .ctn_galerie-photo figure img{
		width:58vw;
		}
	.section_galerie-photo_intro .ctn_galerie-photo p img{
		width:80vw !important; height:auto !important;
		}
}