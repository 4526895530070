/* ======================================== */
/* .obj_push-custom
/* ======================================== */
.obj_push-custom{
	position:relative;
	margin:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	height:var(--aox-450px);
	border:1px solid #c7c7c7;
	background:#f2f2f2;
	}
.obj_push-custom.var_100{
	width:calc(100% - var(--aox-60px));
	}
.obj_push-custom.var_1-2{
	width:calc(50% - var(--aox-60px));
	}
.obj_push-custom.var_1-3{
	width:calc(33.333% - var(--aox-60px));
	}
.obj_push-custom.var_2-3{
	width:calc(66.666% - var(--aox-60px));
	}
.obj_push-custom.var_2-5{
	width:calc(40% - var(--aox-60px));
	}
.obj_push-custom.var_3-5{
	width:calc(60% - var(--aox-60px));
	}
.obj_push-custom .t-last-br{
	display:none;
	}
.obj_push-custom.var_thumb{
	background:#fff;
	}
.obj_push-custom.var_thumb.is-not-available{
	opacity: 0.3;
	}
.obj_push-custom.var_thumb figure{
	position:relative;
	height:calc(var(--aox-220px) + var(--aox-3px));
	background:#f2f2f2;
	overflow:hidden;
	}
.obj_push-custom.var_thumb figure img{
	position:absolute;
	top:0; left:50%;
	width:calc(var(--aox-470px) + var(--aox-5px)); height:var(--aox-225px);
	transform:translateX(-50%) scale(1);
	transition:all .3s ease;
	}
.obj_push-custom.var_thumb.var_3-5 figure img{
	width:calc(var(--aox-740px) + var(--aox-5px));
	}
.obj_push-custom.var_thumb.var_1-2 figure img{
	width:calc(var(--aox-600px) + var(--aox-8px)); height: calc(var(--aox-220px) + var(--aox-3px));
	}
.obj_push-custom.var_thumb h2,
.obj_push-custom.var_thumb h2 p{
	display:flex;
	align-items:center;
	justify-content:center;
	height:var(--aox-120px);
	padding:0 10%;
	font-size:var(--aox-fs-32px); line-height:1;
	font-weight:700;
	}
.obj_push-custom.var_thumb.var_left h2{
	justify-content:flex-start;
	text-align:left;
	}
.obj_push-custom.var_thumb.var_center h2{
	justify-content:center;
	text-align:center;
	}
.obj_push-custom.var_thumb.var_right h2{
	justify-content:flex-end;
	text-align:right;
	}
.obj_push-custom.var_thumb > p{
	font-size:var(--aox-fs-16px); line-height:1.2;
	padding:0 10%;
	}
.obj_push-custom.var_thumb.var_left > p{
	text-align:left;
	}
.obj_push-custom.var_thumb.var_center > p{
	text-align:center;
	}
.obj_push-custom.var_thumb.var_right > p{
	text-align:right;
	}
.obj_push-custom.var_thumb .ctn_cta{
	position:absolute;
	left:0; bottom:9%;
	width:100%;
	padding:0 10%;
	text-align:center;
	}
.obj_push-custom.var_thumb.var_left .ctn_cta{
	text-align:left;
	}
.obj_push-custom.var_thumb.var_center .ctn_cta{
	text-align:center;
	}
.obj_push-custom.var_thumb.var_right .ctn_cta{
	text-align:right;
	}
.obj_push-custom.var_thumb .ctn_cta p{
	font-size:var(--aox-fs-16px);
	font-weight: 400;
	}
.obj_push-custom.var_full figure{
	position:relative;
	width:100%; height:100%;
	background:#f2f2f2;
	overflow:hidden;
	}
.obj_push-custom.var_full figure img{
	position:absolute;
	top:0; left:0;
	width:calc(var(--aox-740px) + var(--aox-5px)); height:var(--aox-450px);
	transition:all .3s ease;
	}
.obj_push-custom.var_full.var_100 figure img{
	width:var(--aox-1280px);
	}
.obj_push-custom.var_full.var_2-5 figure img{
	width:calc(var(--aox-470px) + var(--aox-5px));
	}
.obj_push-custom.var_full .ctn_cta{
	position:absolute;
	left:12%; bottom:9%;
	}
.obj_push-custom.var_full .ctn_text{
	position:absolute;
	z-index:3;
	left:12%; top:9%;
	max-width:45%;
	padding-right:var(--aox-20px);
	}
.obj_push-custom.var_full.var_center .ctn_text,
.obj_push-custom.var_full.var_center .ctn_cta{
	left:50%;
	padding:0;
	text-align:center;
	transform:translateX(-50%);
	}
.obj_push-custom.var_full.var_right .ctn_text,
.obj_push-custom.var_full.var_right .ctn_cta{
	left:50%;
	}
.obj_push-custom.var_full.var_100 .ctn_text{
	max-width:var(--aox-580px);
	}
.obj_push-custom.var_full .ctn_text h2{
	margin-bottom:var(--aox-15px);
	}
.obj_push-custom.var_full .ctn_text h4 + h2{
	margin-top:var(--aox-40px);
	}
.obj_push-custom.var_full .ctn_text h3 + h2{
	margin-top:0;
	}
.obj_push-custom.var_full .ctn_text h2,
.obj_push-custom.var_full .ctn_text h2 p{
	font-size:var(--aox-fs-32px); line-height:1;
	font-weight:700;
	}
.obj_push-custom.var_full .ctn_text h3{
	margin-bottom:var(--aox-20px);
	}
.obj_push-custom.var_full .ctn_text h3,
.obj_push-custom.var_full .ctn_text h3 p{
	font-size:var(--aox-fs-20px); line-height:1;
	text-transform:uppercase;
	}
.obj_push-custom.var_full .ctn_text h4{
	margin-bottom:var(--aox-15px);
	}
.obj_push-custom.var_full .ctn_text h4,
.obj_push-custom.var_full .ctn_text h4 p{
	font-size:var(--aox-fs-16px); line-height:1;
	text-transform:uppercase;
	}
.obj_push-custom.var_full .ctn_text p{
	font-size:var(--aox-fs-16px); line-height:1.8;
	}
.obj_push-custom.var_full .ctn_text p strong{
	font-weight:700;
	}
.obj_push-custom.var_full.var_dark .ctn_text h3,
.obj_push-custom.var_full.var_dark .ctn_text h3 p,
.obj_push-custom.var_full.var_dark .ctn_text h4,
.obj_push-custom.var_full.var_dark .ctn_text h4 p,
.obj_push-custom.var_full.var_dark .ctn_text p{
	color:#fff;
	}
.section_page-liste + .obj_push-custom{
	margin-top:0;
	margin-bottom:var(--aox-120px);
	}
.obj_breadcrumb + .obj_push-custom{
	margin-top:var(--aox-20px);
	}

@media screen and (min-width:1025px){
	.obj_push-custom.var_thumb figure a:hover img{
		transform:translateX(-50%) scale(1.05);
		}
	.obj_push-custom.var_full a:hover figure img{
		transform:scale(1.05);
		}
}
@media screen and (max-width:1340px){
	.obj_push-custom{
		height:33.6vw;
		margin:4.5vw 2.2vw 0 2.2vw;
		}
	.obj_push-custom.var_100{
		width:calc(100% - 4.4vw);
		}
	.obj_push-custom.var_1-2{
		width:calc(50% - 4.4vw);
		}
	.obj_push-custom.var_1-3{
		width:calc(32.8% - 4.4vw);
		}
	.obj_push-custom.var_2-3{
		width:calc(65.8% - 4.4vw);
		}
	.obj_push-custom.var_2-5{
		width:calc(39.3% - 4.4vw);
		}
	.obj_push-custom.var_3-5{
		width:calc(59.3% - 4.4vw);
		}
	.obj_push-custom .obj_cta{
		padding:1.8vw 3.8vw;
		}
	.obj_push-custom.var_thumb figure{
		height:16.6vw;
		}
	.obj_push-custom.var_thumb figure img{
		width:calc(var(--aox-470px) + var(--aox-5px)); height:var(--aox-225px);
		}
	.obj_push-custom.var_thumb.var_3-5 figure img{
		width:calc(var(--aox-740px) + var(--aox-5px));
		}
	.obj_push-custom.var_thumb h2,
	.obj_push-custom.var_thumb h2 p{
		height:9vw;
		font-size:var(--aox-fs-32px);
		}
	.obj_push-custom.var_thumb > p{
		font-size:var(--aox-fs-16px);
		}
	.obj_push-custom.var_full figure img,
	.obj_push-custom.var_full.var_100 figure img,
	.obj_push-custom.var_full.var_2-5 figure img{
		width:auto; height:100%;
		}
	.obj_push-custom.var_full .ctn_text h2{
		margin-bottom:1.1vw;
		}
	.obj_push-custom.var_full .ctn_text h4 + h2{
		margin-top:3vw;
		}
	.obj_push-custom.var_full .ctn_text h2,
	.obj_push-custom.var_full .ctn_text h2 p{
		font-size:var(--aox-fs-32px);
		}
	.obj_push-custom.var_full .ctn_text h3{
		margin-bottom:1.5vw;
		}
	.obj_push-custom.var_full .ctn_text h3,
	.obj_push-custom.var_full .ctn_text h3 p{
		font-size:var(--aox-fs-20px);
		}
	.obj_push-custom.var_full .ctn_text h4{
		margin-bottom:1.1vw;
		}
	.obj_push-custom.var_full .ctn_text h4,
	.obj_push-custom.var_full .ctn_text h4 p{
		font-size:var(--aox-fs-16px);
		}
	.obj_push-custom.var_full .ctn_text p{
		font-size:var(--aox-fs-16px);
		}
}
@media screen and (max-width:767px){
	.obj_push-custom{
		width:100% !important; height:calc(var(--aox-250px) + var(--aox-2px));
		margin:var(--aox-40px) 0 0 0;
		}
	.obj_push-custom.var_derm-expertise{
		height:var(--aox-385px);
		}
	.obj_push-custom.var_thumb{
		margin-left:var(--aox-15px);
		margin-right:var(--aox-15px);
		height:auto;
		}
	.obj_push-custom.var_thumb .ctn_cta{
		position:relative;
		left:auto; bottom:auto;
		padding-bottom:var(--aox-40px);
		}
	.obj_push-custom.var_thumb figure{
		height:auto;
		}
	.obj_push-custom.var_thumb figure img,
	.obj_push-custom.var_thumb.var_3-5 figure img,
	.obj_push-custom.var_thumb.var_1-2 figure img{
		position:relative;
		top:auto; left:auto;
		width:100%; height:auto;
		transform:none;
		}
	.obj_push-custom.var_thumb figure .lazyload-placeholder{
		height:0;
		padding-bottom:47%;
		}
	.obj_push-custom.var_thumb h2,
	.obj_push-custom.var_thumb h2 p{
		height:auto;
		padding:var(--aox-25px) var(--aox-30px);
		font-size:var(--aox-fs-24px);
		}
	.obj_push-custom.var_thumb .ctn_cta{
		padding:0 var(--aox-30px) var(--aox-30px) var(--aox-30px);
		}
	.obj_push-custom.var_thumb > p{
		font-size:var(--aox-fs-12px);
		padding:0 var(--aox-30px) var(--aox-30px) var(--aox-30px);
		}
	.obj_push-custom.var_full figure img{
		position:relative;
		top:auto; left:auto;
		width:100%; height:auto;
		}
	.obj_push-custom.var_full figure .lazyload-placeholder{
		height:0;
		padding-bottom:60%;
		}
	.obj_push-custom.var_full .ctn_text,
	.obj_push-custom.var_full.var_100 .ctn_text{
		max-width:50%;
		}
	.obj_push-custom.var_full.var_100.var_derm-expertise .ctn_text{
		max-width:75%;
		}
	.obj_push-custom.var_full .ctn_text h2{
		margin-bottom:var(--aox-10px);
		}
	.obj_push-custom.var_full .ctn_text h2 picture{
		margin-bottom:var(--aox-10px);
		}
	.obj_push-custom.var_full .ctn_text h4 + h2{
		margin-top:var(--aox-30px);
		}
	.obj_push-custom.var_full .ctn_text h2,
	.obj_push-custom.var_full .ctn_text h2 p{
		font-size:var(--aox-fs-24px);
		}
	.obj_push-custom.var_full .ctn_text h3,
	.obj_push-custom.var_full .ctn_text h3 p{
		font-size:var(--aox-fs-18px);
		}
	.obj_push-custom.var_full .ctn_text h4{
		margin-bottom:var(--aox-10px);
		}
	.obj_push-custom.var_full .ctn_text h4,
	.obj_push-custom.var_full .ctn_text h4 p{
		font-size:var(--aox-fs-15px);
		}
	.obj_push-custom.var_full .ctn_text p{
		display:none;
		}
	.obj_push-custom.var_full.var_derm-expertise .ctn_text p{
		display:block;
		font-size:var(--aox-fs-14px);
		}
	.obj_push-custom.var_full .ctn_text h2 p,
	.obj_push-custom.var_full .ctn_text h3 p,
	.obj_push-custom.var_full .ctn_text h4 p{
		display:block;
		}
	.obj_push-custom .obj_cta{
		padding:var(--aox-20px) var(--aox-30px);
		}
	.section_page-liste + .obj_push-custom{
		margin-bottom:var(--aox-70px);
		}
}
@media screen and (max-width:320px){
	.obj_push-custom.var_full .ctn_text{
		max-width:100%;
		}
}