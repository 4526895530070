/* ======================================== */
/* .obj_tabs-list
/* ======================================== */
.section_page-liste .obj_tabs-list .ctn_page-liste h3{
	text-transform:uppercase;
	}
/**/
.obj_tabs-list .ctn_nav-tabs{
	display:flex;
	justify-content:center;
	margin-bottom:var(--aox-60px);
	border-bottom:1px solid #c3c3c3;
	}
.obj_tabs-list .ctn_nav-tabs li{
	width:26%;
	}
.obj_tabs-list .ctn_nav-tabs li:first-of-type{
	margin-right:8%;
	}
.obj_tabs-list .ctn_nav-tabs li:last-of-type{
	margin-left:8%;
	}
.obj_tabs-list .ctn_nav-tabs li button{
	width:100%;
	padding:var(--aox-20px) 0;
	margin-top:calc(-1 * var(--aox-20px));
	font-size:var(--aox-fs-18px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	text-align:center;
	border-bottom:5px solid transparent;
	transition:all .3s ease;
	}
.obj_tabs-list .ctn_nav-tabs li.active button{
	border-bottom-color:#000;
	}
.obj_tabs-list .ctn_row{
	display:none;
	}
.obj_tabs-list .ctn_row.active{
	display:block;
	}
/**/
@media screen and (max-width:767px){
	.obj_tabs-list .ctn_nav-tabs{
		margin-left: calc(-1 * var(--aox-gutter));
		width: calc(100% + var(--aox-gutter) + var(--aox-gutter));
		}
	.obj_tabs-list .ctn_nav-tabs li{
		width:50%;
		}
	.obj_tabs-list .ctn_nav-tabs li button{
		padding-left:var(--aox-5px);
		padding-right:var(--aox-5px);
		}
	.obj_tabs-list .ctn_nav-tabs li:first-of-type{
		margin-right:0;
		}
	.obj_tabs-list .ctn_nav-tabs li:last-of-type{
		margin-left:0;
		}
}