/* ======================================== */
/* .obj_formation
/* ======================================== */
.obj_formation{
    position: relative;
    transition: all 1s ease;
    margin-bottom: var(--aox-30px);
}
.obj_formation > .ctn_header-title{
    padding: var(--aox-12px) var(--aox-20px) var(--aox-8px) var(--aox-20px);
}
.obj_formation > .ctn_header-title span{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    color: #fff;
}

.obj_formation.var_lrp > .ctn_row{ border: 2px solid #009EE2; }
.obj_formation.var_cerave > .ctn_row{ border: 2px solid #2570B8; }
.obj_formation.var_vichy > .ctn_row{ border: 2px solid #C8102E; }
.obj_formation.var_skinc > .ctn_row{ border: 2px solid #000000; }
.obj_formation.var_derm-expertise > .ctn_row{ border: 2px solid #02ACC2; }
.obj_formation.var_comptoir-dermato > .ctn_row{ border: 2px solid #81C9D3; }

.obj_formation.var_lrp > .ctn_header-title{ background-color: #009EE2; }
.obj_formation.var_cerave > .ctn_header-title{ background-color: #2570B8; }
.obj_formation.var_vichy > .ctn_header-title{ background-color: #C8102E; }
.obj_formation.var_skinc > .ctn_header-title{ background-color: #000000; }
.obj_formation.var_derm-expertise > .ctn_header-title{ background-color: #02ACC2; }
.obj_formation.var_comptoir-dermato > .ctn_header-title{ background-color: #81C9D3; }

.obj_formation .ctn_text, 
.obj_formation > .ctn_row {
    display: flex;
    align-items: flex-start;
    }
.obj_formation > .ctn_row{
    position: relative;
    border: 1px solid rgba(112, 112, 112, 0.31);
    padding: var(--aox-10px) var(--aox-10px) 0 var(--aox-20px);
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    }
.obj_formation .ctn_text{
    width: var(--aox-380px);
    flex-direction: column;
    padding: var(--aox-10px) 0 0;
    min-height: var(--aox-300px);
    }
.obj_formation .ctn_text .obj_jours-restant {
    font-weight: 300;
    font-size: var(--aox-fs-30px);
    line-height: var(--aox-lh-120);
    position: relative;
    margin: 0 0 var(--aox-5px);
    }
.obj_formation .ctn_text .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    }
.obj_formation .ctn_text .list_infos{
    position: relative;
    margin: var(--aox-10px) 0 var(--aox-20px);
    }
.obj_formation .ctn_text .list_infos li{
    position: relative;
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-24px);
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: var(--aox-30px);
    }
.obj_formation .ctn_text .list_infos li:before {
    content: "";
    position: absolute;
    left: 0; top: 0;
    height: var(--aox-24px);
    width: var(--aox-24px);
    }
.obj_formation .ctn_text .list_infos li:first-child:before {
    background: url(../../images/i_calendar.svg) center center no-repeat;
    }
.obj_formation.webinaire .ctn_text .list_infos li:last-child:before {
    background: url(../../images/i_clock.svg) center center no-repeat;
    }
.obj_formation .ctn_text .list_infos li:last-child:before {
    background: url(../../images/i_pin.svg) center center no-repeat;
    }
.obj_formation .ctn_text .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    margin-top: var(--aox-15px);
    }
.obj_formation .ctn_text .t_desc strong{
    font-weight: 700;
    font-size: var(--aox-fs-20px);
    line-height: var(--aox-lh-100);
    }

.obj_formation.is_registered .obj_btn_formation{
    border: 1px solid #000;
    background: transparent;
    color: #000;
    }
.obj_formation.is_registered .obj_btn_formation:hover{
    border: 1px solid #000;
    background: #000;
    color: #fff;
    }

.obj_formation.is_registered .ctn_text .t_statut{
    position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-24px);
    margin-top: var(--aox-20px);
    padding-left: var(--aox-30px);
    text-transform: uppercase;
    color: #8cc76f;
    }
.obj_formation.is_registered .ctn_text .t_statut:before {
    content: "";
    position: absolute;
    left: 0; top: 0;
    height: var(--aox-24px);
    width: var(--aox-24px);
    background: url(../../images/i_check_green.svg) center center / var(--aox-24px) auto no-repeat;
    }

.obj_formation.is_canceled .ctn_text .t_statut{ color: #FF4847; }
.obj_formation.is_canceled .ctn_text .t_statut:before {
    background: url(../../images/i_check_red.svg) center center / var(--aox-24px) auto no-repeat;
    }

.obj_formation.is_finished .ctn_text .t_statut{ color: #D1D1D0; }
.obj_formation.is_finished .ctn_text .t_statut:before {
    background: url(../../images/i_check_grey.svg) center center / var(--aox-24px) auto no-repeat;
    }
    
.obj_formation .ctn_image{
    width: var(--aox-170px);
    }
.obj_formation .ctn_image .ctn_visuel{
    width: var(--aox-170px);
    height: var(--aox-170px);
    overflow: hidden;
    }
.obj_formation .ctn_image .ctn_visuel img{
    width: 100%;
    }
.obj_formation .ctn_image .ctn_logo img{
    width: 100%;
    }


/***/

.obj_formation.is_empty{
    position: relative;
    }
.obj_formation.is_empty .ctn_text{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: var(--aox-40px);
    z-index: 2;
    justify-content: center;
    }
.obj_formation.is_empty .t_title{
    font-weight: 400;
    font-size: var(--aox-fs-38px);
    line-height: var(--aox-lh-120);
    color: white;
    text-transform: none;
    margin: 0;
    }
.obj_formation.is_empty .ctn_background{
    display: block;
    height: var(--aox-550px);
    width: var(--aox-620px);
    }
.obj_formation.is_empty .ctn_background img{
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    }

/***/

@media screen and (max-width:767px){

    .obj_formation .ctn_row {
        padding: var(--aox-5px);
        }

    .obj_formation .ctn_row .ctn_text{
        width: calc(100% - var(--aox-120px));
        }
    .obj_formation .ctn_row .ctn_text > .obj_temps_restant,
    .obj_formation .ctn_row .ctn_text > .t_title,
    .obj_formation .ctn_row .ctn_text > .t_date,
    .obj_formation .ctn_row .ctn_text > .t_desc,
    .obj_formation .ctn_row .ctn_text > .obj_btn_formation {
        width: 100%;
        }
    .obj_formation .ctn_row .ctn_text {
        padding: var(--aox-15px) 0 var(--aox-15px) var(--aox-15px);
        }   
    .obj_formation .ctn_row .ctn_text > .obj_btn_formation {
        width: auto;
        padding: var(--aox-20px);
        }
    .obj_formation .ctn_image {
        width: var(--aox-110px);
        }
    .obj_formation .ctn_image .ctn_visuel{
        width: var(--aox-110px);
        height: var(--aox-110px);
        }

    .obj_formation .ctn_text .t_title{
        font-size: var(--aox-fs-17px);
        line-height: var(--aox-lh-100);
        }


    .obj_formation.is_empty .ctn_background {
        display: block;
        width: var(--aox-380px);
        height: var(--aox-340px);
        }   
    .obj_formation.is_empty .t_title{
        font-size: var(--aox-fs-20px);
        line-height: var(--aox-lh-120);
        }
    


}