/* -------------------------------------------------- */

/*   $ > AOX Fluid (v2.0.1) - 04/04/23

/* -------------------------------------------------- */

/* ======================================== */
/*  Fluid
/* ======================================== */

/*** Sizes ***/

:root {

	--aox-2px: calc(var(--aox-1px) * 2);
	--aox-3px: calc(var(--aox-1px) * 3);
	--aox-4px: calc(var(--aox-1px) * 4);
	--aox-5px: calc(var(--aox-1px) * 5);
	--aox-6px: calc(var(--aox-1px) * 6);
	--aox-7px: calc(var(--aox-1px) * 7);
	--aox-8px: calc(var(--aox-1px) * 8);
	--aox-9px: calc(var(--aox-1px) * 9);
	--aox-10px: calc(var(--aox-1px) * 10);
	--aox-11px: calc(var(--aox-1px) * 11);
	--aox-12px: calc(var(--aox-1px) * 12);
	--aox-13px: calc(var(--aox-1px) * 13);
	--aox-14px: calc(var(--aox-1px) * 14);
	--aox-15px: calc(var(--aox-1px) * 15);
	--aox-16px: calc(var(--aox-1px) * 16);
	--aox-17px: calc(var(--aox-1px) * 17);
	--aox-18px: calc(var(--aox-1px) * 18);
	--aox-19px: calc(var(--aox-1px) * 19);
	--aox-20px: calc(var(--aox-1px) * 20);
	--aox-21px: calc(var(--aox-1px) * 21);
	--aox-22px: calc(var(--aox-1px) * 22);
	--aox-23px: calc(var(--aox-1px) * 23);
	--aox-24px: calc(var(--aox-1px) * 24);
	--aox-25px: calc(var(--aox-1px) * 25);
	--aox-26px: calc(var(--aox-1px) * 26);
	--aox-27px: calc(var(--aox-1px) * 27);
	--aox-28px: calc(var(--aox-1px) * 28);
	--aox-29px: calc(var(--aox-1px) * 29);
	--aox-30px: calc(var(--aox-1px) * 30);
	--aox-31px: calc(var(--aox-1px) * 31);
	--aox-32px: calc(var(--aox-1px) * 32);
	--aox-33px: calc(var(--aox-1px) * 33);
	--aox-34px: calc(var(--aox-1px) * 34);
	--aox-35px: calc(var(--aox-1px) * 35);
	--aox-36px: calc(var(--aox-1px) * 36);
	--aox-37px: calc(var(--aox-1px) * 37);
	--aox-38px: calc(var(--aox-1px) * 38);
	--aox-39px: calc(var(--aox-1px) * 39);
	--aox-40px: calc(var(--aox-1px) * 40);
	--aox-41px: calc(var(--aox-1px) * 41);
	--aox-42px: calc(var(--aox-1px) * 42);
	--aox-43px: calc(var(--aox-1px) * 43);
	--aox-44px: calc(var(--aox-1px) * 44);
	--aox-45px: calc(var(--aox-1px) * 45);
	--aox-46px: calc(var(--aox-1px) * 46);
	--aox-47px: calc(var(--aox-1px) * 47);
	--aox-48px: calc(var(--aox-1px) * 48);
	--aox-49px: calc(var(--aox-1px) * 49);
	--aox-50px: calc(var(--aox-1px) * 50);
	--aox-51px: calc(var(--aox-1px) * 51);
	--aox-52px: calc(var(--aox-1px) * 52);
	--aox-53px: calc(var(--aox-1px) * 53);
	--aox-54px: calc(var(--aox-1px) * 54);
	--aox-55px: calc(var(--aox-1px) * 55);
	--aox-56px: calc(var(--aox-1px) * 56);
	--aox-57px: calc(var(--aox-1px) * 57);
	--aox-58px: calc(var(--aox-1px) * 58);
	--aox-59px: calc(var(--aox-1px) * 59);
	--aox-60px: calc(var(--aox-1px) * 60);
	--aox-61px: calc(var(--aox-1px) * 61);
	--aox-62px: calc(var(--aox-1px) * 62);
	--aox-63px: calc(var(--aox-1px) * 63);
	--aox-64px: calc(var(--aox-1px) * 64);
	--aox-65px: calc(var(--aox-1px) * 65);
	--aox-66px: calc(var(--aox-1px) * 66);
	--aox-67px: calc(var(--aox-1px) * 67);
	--aox-68px: calc(var(--aox-1px) * 68);
	--aox-69px: calc(var(--aox-1px) * 69);
	--aox-70px: calc(var(--aox-1px) * 70);
	--aox-71px: calc(var(--aox-1px) * 71);
	--aox-72px: calc(var(--aox-1px) * 72);
	--aox-73px: calc(var(--aox-1px) * 73);
	--aox-74px: calc(var(--aox-1px) * 74);
	--aox-75px: calc(var(--aox-1px) * 75);
	--aox-76px: calc(var(--aox-1px) * 76);
	--aox-77px: calc(var(--aox-1px) * 77);
	--aox-78px: calc(var(--aox-1px) * 78);
	--aox-79px: calc(var(--aox-1px) * 79);
	--aox-80px: calc(var(--aox-1px) * 80);
	--aox-81px: calc(var(--aox-1px) * 81);
	--aox-82px: calc(var(--aox-1px) * 82);
	--aox-83px: calc(var(--aox-1px) * 83);
	--aox-84px: calc(var(--aox-1px) * 84);
	--aox-85px: calc(var(--aox-1px) * 85);
	--aox-86px: calc(var(--aox-1px) * 86);
	--aox-87px: calc(var(--aox-1px) * 87);
	--aox-88px: calc(var(--aox-1px) * 88);
	--aox-89px: calc(var(--aox-1px) * 89);
	--aox-90px: calc(var(--aox-1px) * 90);
	--aox-91px: calc(var(--aox-1px) * 91);
	--aox-92px: calc(var(--aox-1px) * 92);
	--aox-93px: calc(var(--aox-1px) * 93);
	--aox-94px: calc(var(--aox-1px) * 94);
	--aox-95px: calc(var(--aox-1px) * 95);
	--aox-96px: calc(var(--aox-1px) * 96);
	--aox-97px: calc(var(--aox-1px) * 97);
	--aox-98px: calc(var(--aox-1px) * 98);
	--aox-99px: calc(var(--aox-1px) * 99);
	--aox-100px: calc(var(--aox-1px) * 100);

	--aox-105px: calc(var(--aox-1px) * 105);
	--aox-110px: calc(var(--aox-1px) * 110);
	--aox-115px: calc(var(--aox-1px) * 115);
	--aox-120px: calc(var(--aox-1px) * 120);
	--aox-125px: calc(var(--aox-1px) * 125);
	--aox-130px: calc(var(--aox-1px) * 130);
	--aox-135px: calc(var(--aox-1px) * 135);
	--aox-140px: calc(var(--aox-1px) * 140);
	--aox-145px: calc(var(--aox-1px) * 145);
	--aox-150px: calc(var(--aox-1px) * 150);
	--aox-155px: calc(var(--aox-1px) * 155);
	--aox-160px: calc(var(--aox-1px) * 160);
	--aox-165px: calc(var(--aox-1px) * 165);
	--aox-170px: calc(var(--aox-1px) * 170);
	--aox-175px: calc(var(--aox-1px) * 175);
	--aox-180px: calc(var(--aox-1px) * 180);
	--aox-185px: calc(var(--aox-1px) * 185);
	--aox-190px: calc(var(--aox-1px) * 190);
	--aox-195px: calc(var(--aox-1px) * 195);
	--aox-200px: calc(var(--aox-1px) * 200);
	--aox-205px: calc(var(--aox-1px) * 205);
	--aox-210px: calc(var(--aox-1px) * 210);
	--aox-215px: calc(var(--aox-1px) * 215);
	--aox-220px: calc(var(--aox-1px) * 220);
	--aox-225px: calc(var(--aox-1px) * 225);
	--aox-230px: calc(var(--aox-1px) * 230);
	--aox-235px: calc(var(--aox-1px) * 235);
	--aox-240px: calc(var(--aox-1px) * 240);
	--aox-245px: calc(var(--aox-1px) * 245);
	--aox-250px: calc(var(--aox-1px) * 250);
	--aox-255px: calc(var(--aox-1px) * 255);
	--aox-260px: calc(var(--aox-1px) * 260);
	--aox-265px: calc(var(--aox-1px) * 265);
	--aox-270px: calc(var(--aox-1px) * 270);
	--aox-275px: calc(var(--aox-1px) * 275);
	--aox-280px: calc(var(--aox-1px) * 280);
	--aox-285px: calc(var(--aox-1px) * 285);
	--aox-290px: calc(var(--aox-1px) * 290);
	--aox-295px: calc(var(--aox-1px) * 295);
	--aox-300px: calc(var(--aox-1px) * 300);
	--aox-305px: calc(var(--aox-1px) * 305);
	--aox-310px: calc(var(--aox-1px) * 310);
	--aox-315px: calc(var(--aox-1px) * 315);
	--aox-320px: calc(var(--aox-1px) * 320);
	--aox-325px: calc(var(--aox-1px) * 325);
	--aox-330px: calc(var(--aox-1px) * 330);
	--aox-335px: calc(var(--aox-1px) * 335);
	--aox-340px: calc(var(--aox-1px) * 340);
	--aox-345px: calc(var(--aox-1px) * 345);
	--aox-350px: calc(var(--aox-1px) * 350);
	--aox-355px: calc(var(--aox-1px) * 355);
	--aox-360px: calc(var(--aox-1px) * 360);
	--aox-365px: calc(var(--aox-1px) * 365);
	--aox-370px: calc(var(--aox-1px) * 370);
	--aox-375px: calc(var(--aox-1px) * 375);
	--aox-380px: calc(var(--aox-1px) * 380);
	--aox-385px: calc(var(--aox-1px) * 385);
	--aox-390px: calc(var(--aox-1px) * 390);
	--aox-395px: calc(var(--aox-1px) * 395);
	--aox-400px: calc(var(--aox-1px) * 400);
	--aox-405px: calc(var(--aox-1px) * 405);
	--aox-410px: calc(var(--aox-1px) * 410);
	--aox-415px: calc(var(--aox-1px) * 415);
	--aox-420px: calc(var(--aox-1px) * 420);

	
	--aox-430px: calc(var(--aox-1px) * 430);
	--aox-440px: calc(var(--aox-1px) * 440);
	--aox-450px: calc(var(--aox-1px) * 450);
	--aox-460px: calc(var(--aox-1px) * 460);
	--aox-470px: calc(var(--aox-1px) * 470);
	--aox-480px: calc(var(--aox-1px) * 480);
	--aox-490px: calc(var(--aox-1px) * 490);
	--aox-500px: calc(var(--aox-1px) * 500);
	--aox-510px: calc(var(--aox-1px) * 510);
	--aox-520px: calc(var(--aox-1px) * 520);
	--aox-530px: calc(var(--aox-1px) * 530);
	--aox-540px: calc(var(--aox-1px) * 540);
	--aox-550px: calc(var(--aox-1px) * 550);
	--aox-560px: calc(var(--aox-1px) * 560);
	--aox-570px: calc(var(--aox-1px) * 570);
	--aox-580px: calc(var(--aox-1px) * 580);
	--aox-590px: calc(var(--aox-1px) * 590);
	--aox-600px: calc(var(--aox-1px) * 600);
	--aox-610px: calc(var(--aox-1px) * 610);
	--aox-620px: calc(var(--aox-1px) * 620);
	--aox-630px: calc(var(--aox-1px) * 630);
	--aox-640px: calc(var(--aox-1px) * 640);
	--aox-650px: calc(var(--aox-1px) * 650);
	--aox-660px: calc(var(--aox-1px) * 660);
	--aox-670px: calc(var(--aox-1px) * 670);
	--aox-680px: calc(var(--aox-1px) * 680);
	--aox-690px: calc(var(--aox-1px) * 690);
	--aox-700px: calc(var(--aox-1px) * 700);
	--aox-710px: calc(var(--aox-1px) * 710);
	--aox-720px: calc(var(--aox-1px) * 720);
	--aox-730px: calc(var(--aox-1px) * 730);
	--aox-740px: calc(var(--aox-1px) * 740);
	--aox-750px: calc(var(--aox-1px) * 750);
	--aox-760px: calc(var(--aox-1px) * 760);
	--aox-770px: calc(var(--aox-1px) * 770);
	--aox-780px: calc(var(--aox-1px) * 780);
	--aox-790px: calc(var(--aox-1px) * 790);
	--aox-800px: calc(var(--aox-1px) * 800);
	--aox-810px: calc(var(--aox-1px) * 810);
	--aox-820px: calc(var(--aox-1px) * 820);
	--aox-830px: calc(var(--aox-1px) * 830);
	--aox-840px: calc(var(--aox-1px) * 840);
	--aox-850px: calc(var(--aox-1px) * 850);
	--aox-860px: calc(var(--aox-1px) * 860);
	--aox-870px: calc(var(--aox-1px) * 870);
	--aox-880px: calc(var(--aox-1px) * 880);
	--aox-890px: calc(var(--aox-1px) * 890);
	--aox-900px: calc(var(--aox-1px) * 900);
	--aox-910px: calc(var(--aox-1px) * 910);
	--aox-920px: calc(var(--aox-1px) * 920);
	--aox-930px: calc(var(--aox-1px) * 930);
	--aox-940px: calc(var(--aox-1px) * 940);
	--aox-950px: calc(var(--aox-1px) * 950);
	--aox-960px: calc(var(--aox-1px) * 960);
	--aox-970px: calc(var(--aox-1px) * 970);
	--aox-980px: calc(var(--aox-1px) * 980);
	--aox-990px: calc(var(--aox-1px) * 990);
	--aox-1000px: calc(var(--aox-1px) * 1000);
	--aox-1010px: calc(var(--aox-1px) * 1010);
	--aox-1020px: calc(var(--aox-1px) * 1020);
	--aox-1030px: calc(var(--aox-1px) * 1030);
	--aox-1040px: calc(var(--aox-1px) * 1040);
	--aox-1050px: calc(var(--aox-1px) * 1050);
	--aox-1060px: calc(var(--aox-1px) * 1060);
	--aox-1070px: calc(var(--aox-1px) * 1070);
	--aox-1080px: calc(var(--aox-1px) * 1080);
	--aox-1090px: calc(var(--aox-1px) * 1090);
	--aox-1100px: calc(var(--aox-1px) * 1100);
	--aox-1110px: calc(var(--aox-1px) * 1110);
	--aox-1120px: calc(var(--aox-1px) * 1120);
	--aox-1130px: calc(var(--aox-1px) * 1130);
	--aox-1140px: calc(var(--aox-1px) * 1140);
	--aox-1150px: calc(var(--aox-1px) * 1150);
	--aox-1160px: calc(var(--aox-1px) * 1160);
	--aox-1170px: calc(var(--aox-1px) * 1170);
	--aox-1180px: calc(var(--aox-1px) * 1180);
	--aox-1190px: calc(var(--aox-1px) * 1190);
	--aox-1200px: calc(var(--aox-1px) * 1200);
	--aox-1210px: calc(var(--aox-1px) * 1210);
	--aox-1220px: calc(var(--aox-1px) * 1220);
	--aox-1230px: calc(var(--aox-1px) * 1230);
	--aox-1240px: calc(var(--aox-1px) * 1240);
	--aox-1250px: calc(var(--aox-1px) * 1250);
	--aox-1260px: calc(var(--aox-1px) * 1260);
	--aox-1270px: calc(var(--aox-1px) * 1270);
	--aox-1280px: calc(var(--aox-1px) * 1280);
	--aox-1290px: calc(var(--aox-1px) * 1290);
	--aox-1300px: calc(var(--aox-1px) * 1300);
	--aox-1310px: calc(var(--aox-1px) * 1310);
	--aox-1320px: calc(var(--aox-1px) * 1320);
	--aox-1330px: calc(var(--aox-1px) * 1330);
	--aox-1340px: calc(var(--aox-1px) * 1340);
	--aox-1350px: calc(var(--aox-1px) * 1350);
	--aox-1360px: calc(var(--aox-1px) * 1360);
	--aox-1370px: calc(var(--aox-1px) * 1370);
	--aox-1380px: calc(var(--aox-1px) * 1380);
	--aox-1390px: calc(var(--aox-1px) * 1390);
	--aox-1400px: calc(var(--aox-1px) * 1400);
	--aox-1410px: calc(var(--aox-1px) * 1410);
	--aox-1420px: calc(var(--aox-1px) * 1420);
	--aox-1430px: calc(var(--aox-1px) * 1430);
	--aox-1440px: calc(var(--aox-1px) * 1440);
	--aox-1450px: calc(var(--aox-1px) * 1450);
	--aox-1460px: calc(var(--aox-1px) * 1460);
	--aox-1470px: calc(var(--aox-1px) * 1470);
	--aox-1480px: calc(var(--aox-1px) * 1480);
	--aox-1490px: calc(var(--aox-1px) * 1490);
	--aox-1500px: calc(var(--aox-1px) * 1500);
	--aox-1510px: calc(var(--aox-1px) * 1510);
	--aox-1520px: calc(var(--aox-1px) * 1520);
	--aox-1530px: calc(var(--aox-1px) * 1530);
	--aox-1540px: calc(var(--aox-1px) * 1540);
	--aox-1550px: calc(var(--aox-1px) * 1550);
	--aox-1560px: calc(var(--aox-1px) * 1560);
	--aox-1570px: calc(var(--aox-1px) * 1570);
	--aox-1580px: calc(var(--aox-1px) * 1580);
	--aox-1590px: calc(var(--aox-1px) * 1590);
	--aox-1600px: calc(var(--aox-1px) * 1600);
	--aox-1610px: calc(var(--aox-1px) * 1610);
	--aox-1620px: calc(var(--aox-1px) * 1620);
	--aox-1630px: calc(var(--aox-1px) * 1630);
	--aox-1640px: calc(var(--aox-1px) * 1640);
	--aox-1650px: calc(var(--aox-1px) * 1650);
	--aox-1660px: calc(var(--aox-1px) * 1660);
	--aox-1670px: calc(var(--aox-1px) * 1670);
	--aox-1680px: calc(var(--aox-1px) * 1680);
	--aox-1690px: calc(var(--aox-1px) * 1690);
	--aox-1700px: calc(var(--aox-1px) * 1700);
	--aox-1710px: calc(var(--aox-1px) * 1710);
	--aox-1720px: calc(var(--aox-1px) * 1720);
	--aox-1730px: calc(var(--aox-1px) * 1730);
	--aox-1740px: calc(var(--aox-1px) * 1740);
	--aox-1750px: calc(var(--aox-1px) * 1750);
	--aox-1760px: calc(var(--aox-1px) * 1760);
	--aox-1770px: calc(var(--aox-1px) * 1770);
	--aox-1780px: calc(var(--aox-1px) * 1780);
	--aox-1790px: calc(var(--aox-1px) * 1790);
	--aox-1800px: calc(var(--aox-1px) * 1800);
	--aox-1810px: calc(var(--aox-1px) * 1810);
	--aox-1820px: calc(var(--aox-1px) * 1820);
	--aox-1830px: calc(var(--aox-1px) * 1830);
	--aox-1840px: calc(var(--aox-1px) * 1840);
	--aox-1850px: calc(var(--aox-1px) * 1850);
	--aox-1860px: calc(var(--aox-1px) * 1860);
	--aox-1870px: calc(var(--aox-1px) * 1870);
	--aox-1890px: calc(var(--aox-1px) * 1890);
	--aox-1900px: calc(var(--aox-1px) * 1900);
	--aox-1920px: calc(var(--aox-1px) * 1910);

	--aox-1024px: calc(var(--aox-1px) * 1024);
	--aox-1276px: calc(var(--aox-1px) * 1276);
	--aox-1366px: calc(var(--aox-1px) * 1366);
	--aox-1536px: calc(var(--aox-1px) * 1536);
	
	}

/* ======================================== */
/*  Grid
/* ======================================== */

/*** Calculations ***/

:root {
	--aox-external-gutter: calc((100vw - var(--aox-grid)) / 2);
    --aox-col: calc((var(--aox-grid) - (calc(var(--aox-columns) - 1) * var(--aox-gutter))) / var(--aox-columns));
	--aox-col-gutter: calc(var(--aox-col) + var(--aox-gutter));
	}

/*** Sizes ***/

:root {
    --aox-col-1: calc(var(--aox-col) * 1);
    --aox-col-2: calc((var(--aox-col) * 2) + (var(--aox-gutter) * 1));
    --aox-col-3: calc((var(--aox-col) * 3) + (var(--aox-gutter) * 2));
    --aox-col-4: calc((var(--aox-col) * 4) + (var(--aox-gutter) * 3));
    --aox-col-5: calc((var(--aox-col) * 5) + (var(--aox-gutter) * 4));
    --aox-col-6: calc((var(--aox-col) * 6) + (var(--aox-gutter) * 5));
    --aox-col-7: calc((var(--aox-col) * 7) + (var(--aox-gutter) * 6));
    --aox-col-8: calc((var(--aox-col) * 8) + (var(--aox-gutter) * 7));
    --aox-col-9: calc((var(--aox-col) * 9) + (var(--aox-gutter) * 8));
    --aox-col-10: calc((var(--aox-col) * 10) + (var(--aox-gutter) * 9));
    --aox-col-11: calc((var(--aox-col) * 11) + (var(--aox-gutter) * 10));
    --aox-col-12: calc((var(--aox-col) * 12) + (var(--aox-gutter) * 11));
	--aox-col-13: calc((var(--aox-col) * 13) + (var(--aox-gutter) * 12));
	--aox-col-14: calc((var(--aox-col) * 14) + (var(--aox-gutter) * 13));
	--aox-col-15: calc((var(--aox-col) * 15) + (var(--aox-gutter) * 14));
	--aox-col-16: calc((var(--aox-col) * 16) + (var(--aox-gutter) * 15));
	}

:root {
    --aox-col-1-gutter: calc((var(--aox-col) * 1) + (var(--aox-gutter) * 1));
    --aox-col-2-gutter: calc((var(--aox-col) * 2) + (var(--aox-gutter) * 2));
    --aox-col-3-gutter: calc((var(--aox-col) * 3) + (var(--aox-gutter) * 3));
    --aox-col-4-gutter: calc((var(--aox-col) * 4) + (var(--aox-gutter) * 4));
    --aox-col-5-gutter: calc((var(--aox-col) * 5) + (var(--aox-gutter) * 5));
    --aox-col-6-gutter: calc((var(--aox-col) * 6) + (var(--aox-gutter) * 6));
    --aox-col-7-gutter: calc((var(--aox-col) * 7) + (var(--aox-gutter) * 7));
    --aox-col-8-gutter: calc((var(--aox-col) * 8) + (var(--aox-gutter) * 8));
    --aox-col-9-gutter: calc((var(--aox-col) * 9) + (var(--aox-gutter) * 9));
    --aox-col-10-gutter: calc((var(--aox-col) * 10) + (var(--aox-gutter) * 10));
    --aox-col-11-gutter: calc((var(--aox-col) * 11) + (var(--aox-gutter) * 11));
	--aox-col-12-gutter: calc((var(--aox-col) * 12) + (var(--aox-gutter) * 12));
	--aox-col-13-gutter: calc((var(--aox-col) * 13) + (var(--aox-gutter) * 13));
	--aox-col-14-gutter: calc((var(--aox-col) * 14) + (var(--aox-gutter) * 14));
	--aox-col-15-gutter: calc((var(--aox-col) * 15) + (var(--aox-gutter) * 15));
	}

@media screen and (max-width: 767px) {

	/*** Calculations ***/

	:root {
		--aox-external-gutter: calc((100vw - var(--aox-grid)) / 2);
		--aox-col: calc((var(--aox-grid) - (5 * var(--aox-gutter))) / 6);
		--aox-col-gutter: calc(var(--aox-col) + var(--aox-gutter));
		}

	/*** Sizes ***/

	:root {
		--aox-col-1: calc(var(--aox-col) * 1);
		--aox-col-2: calc((var(--aox-col) * 2) + (var(--aox-gutter) * 1));
		--aox-col-3: calc((var(--aox-col) * 3) + (var(--aox-gutter) * 2));
		--aox-col-4: calc((var(--aox-col) * 4) + (var(--aox-gutter) * 3));
		--aox-col-5: calc((var(--aox-col) * 5) + (var(--aox-gutter) * 4));
		--aox-col-6: calc((var(--aox-col) * 6) + (var(--aox-gutter) * 5));
		--aox-col-7: 100%;
		--aox-col-8: 100%;
		--aox-col-9: 100%;
		--aox-col-10: 100%;
		--aox-col-11: 100%;
		--aox-col-12: 100%;
		--aox-col-13: 100%;
		--aox-col-14: 100%;
		--aox-col-15: 100%;
		--aox-col-16: 100%;
		}

	}

/* ======================================== */
/*  Debug
/* ======================================== */

.obj_grid_debug {
	position: fixed;
	top: 0;
	left: var(--aox-external-gutter);
	z-index: 999;
    display: flex;
    flex-direction: row;
    height: 100vh;
	width: 100%;
    max-width: var(--aox-grid);
	}
.obj_grid_debug > * {
	position: relative;
	width: var(--aox-col);
	border-left: solid 1px rgba(0, 255, 255, 0.3);
	border-right: solid 1px rgba(0, 255, 255, 0.3);
	background: rgba(0, 255, 255, 0.15);
	}
.obj_grid_debug > * + * {
	margin-left: var(--aox-gutter);
	}

@media screen and (max-width: 767px) {

	.obj_grid_debug > * {
		display: none;
		}
	.obj_grid_debug > *:nth-child(-n+6) {
		display: block;
		}

	}