/* ======================================== */
/* .section_article
/* ======================================== */
.section_article{
	width:100%;
	}
.section_article .str_container{
	padding-top:var(--aox-45px);
	max-width:calc(var(--aox-1090px) + var(--aox-5px));
	}

@media screen and (max-width:1095px){
	.section_article .str_container{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
}
@media screen and (max-width:767px){
	.section_article .str_container{
		padding-top:var(--aox-30px);
		}
}

/* ctn_article */
.section_article .ctn_article h3{
	font-size:var(--aox-fs-16px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.section_article .ctn_article h1{
	margin-top:var(--aox-5px);
	font-size:var(--aox-fs-50px); line-height:1.5;
	font-weight:700;
	}
.section_article .ctn_article .ctn_bottom{
	display:flex;
	flex-flow:row wrap;
	align-items:center;
	margin:var(--aox-20px) 0 var(--aox-30px) 0;
	}
.section_article .ctn_article .t_author-date{
	display:flex;
	align-items:center;
	}
.section_article .ctn_article .t_author-date figure{
	margin-right:var(--aox-20px);
	width:var(--aox-40px); height:var(--aox-40px);
	border-radius:50%;
	overflow:hidden;
	}
.section_article .ctn_article .t_author-date p{
	font-size:var(--aox-fs-13px); line-height:1;
	}
.section_article .ctn_article .t_author-date p strong{
	font-weight:700;
	}
.section_article .ctn_article .obj_likes-comments{
	margin:0 0 0 auto;
	}
.section_article .ctn_article .obj_likes-comments li + li{
	margin-right:var(--aox-5px);
	}
.section_article .ctn_article .ctn_img{
	position:relative;
	}
.section_article .ctn_article.var_video .ctn_img{
	cursor:pointer;
	}
.section_article .ctn_article.var_video .ctn_img::before,
.section_article .ctn_article.var_video .ctn_img::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	transition:all .3s ease;
	}
.section_article .ctn_article.var_video .ctn_img::before{
	width:var(--aox-88px); height:var(--aox-88px);
	border:4px solid #fff;
	border-radius:50%;
	transform:translate(-50%,-50%);
	}
.section_article .ctn_article.var_video .ctn_img::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-16px) 0 var(--aox-16px) var(--aox-28px);
	border-color:transparent transparent transparent #fff;
	transform:translate(-40%,-50%);
	}
.section_article .ctn_article .ctn_img img{
	position:relative;
	z-index:1;
	width:100%;
	}
.section_article .ctn_article .ctn_text{
	max-width:calc(var(--aox-760px) + var(--aox-8px));
	margin:0 auto;
	padding:var(--aox-25px) 0 var(--aox-90px) 0;
	}
.section_article .ctn_article .ctn_text h4,
.section_article .ctn_article .ctn_text p,
.section_article .ctn_article .ctn_text li{
	font-size:var(--aox-fs-16px); line-height:1.8;
	}
.section_article .ctn_article .ctn_text h4,
.section_article .ctn_article .ctn_text p,
.section_article .ctn_article .ctn_text ul,
.section_article .ctn_article .ctn_text ol,
.section_article .ctn_article .ctn_text img{
	margin-top:var(--aox-35px);
	}
.section_article .ctn_article .ctn_text img{
	max-width:100%;
	width:auto !important; height:auto !important;
	}
.section_article .ctn_article .ctn_text h4{
	font-weight:700;
	text-transform:uppercase;
	}
.section_article .ctn_article .ctn_text p a{
	text-decoration:underline;
	}
.section_article .ctn_article .ctn_text p strong{
	font-weight:700;
	}
.section_article .ctn_article .ctn_text p em{
	font-style:italic;
	}
.section_article .ctn_article .ctn_text p.t_mention{
	margin-top:var(--aox-90px);
	font-size:var(--aox-fs-12px);
	color:#a9a9a9;
	}
.section_article .ctn_article .ctn_text ul,
.section_article .ctn_article .ctn_text ol{
	counter-reset:list;
	}
.section_article .ctn_article .ctn_text ul li,
.section_article .ctn_article .ctn_text ol li{
	position:relative;
	padding-left:var(--aox-20px);
	counter-increment:list;
	}
.section_article .ctn_article .ctn_text ul li::before{
	content:'';
	position:absolute;
	left:0; top:var(--aox-10px);
	width:6px; height:6px;
	background:#000;
	border-radius:50%;
	}
.section_article .ctn_article .ctn_text ol li::before{
	content:counter(list) ".";
	position:absolute;
	left:0; top:0;
	font-weight:700;
	}
.section_article .ctn_article .ctn_text .ctn_like-article{
	margin-top:var(--aox-90px);
	padding-top:var(--aox-25px);
	border-top:1px solid #b7b7b7;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article{
	position:relative;
	display:flex;
	align-items:center;
	min-height:var(--aox-20px);
	padding-left:var(--aox-35px);
	font-size:var(--aox-fs-15px); line-height:1;
	color:#777;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article[disabled]{
	opacity:0.5;
	cursor:default;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article svg{
	position:absolute;
	left:0; top:50%;
	transform:translateY(-50%);
	transition:all .3s ease;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article .i_heart-hover{
	opacity:0;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article.is-checked .i_heart-hover{
	opacity:1;
	}

@media screen and (min-width:1025px){
	.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article:hover{
		color:#000;
		}
	.section_article .ctn_article.var_video .ctn_img:hover::before{
		transform:translate(-50%,-50%) scale(1.1);
		}
	.section_article .ctn_article.var_video .ctn_img:hover::after{
		transform:translate(-40%,-50%) scale(1.1);
		}
}
@media screen and (max-width:1095px){
	.section_article .ctn_article h1{
		font-size:var(--aox-fs-50px);
		}
}
@media screen and (max-width:767px){
	.section_article .ctn_article h3{
		font-size:var(--aox-fs-12px);
		}
	.section_article .ctn_article h1{
		margin-top:var(--aox-20px);
		font-size:var(--aox-fs-24px);
		}
	.section_article .ctn_article .ctn_bottom{
		margin:var(--aox-20px) 0 var(--aox-30px) 0;
		}
	.section_article .ctn_article .t_author-date{
		width:100%;
		}
	.section_article .ctn_article .obj_likes-comments{
		margin:var(--aox-20px) 0 0 auto;
		}
	.section_article .ctn_article .ctn_text{
		padding:var(--aox-10px) 0 var(--aox-60px) 0;
		}
	.section_article .ctn_article .ctn_text h4,
	.section_article .ctn_article .ctn_text p,
	.section_article .ctn_article .ctn_text ul,
	.section_article .ctn_article .ctn_text ol,
	.section_article .ctn_article .ctn_text img{
		margin-top:var(--aox-20px);
		}
	.section_article .ctn_article .ctn_text .ctn_like-article,
	.section_article .ctn_article .ctn_text p.t_mention{
		margin-top:var(--aox-40px);
		}
	.section_article .ctn_article.var_video .ctn_img::before{
		width:var(--aox-78px); height:var(--aox-78px);
		}
	.section_article .ctn_article.var_video .ctn_img::after{
		border-width:var(--aox-14px) 0 var(--aox-14px) var(--aox-24px);
		}
}

/* ctn_comments */
.section_article .ctn_comment{
	position:relative;
	max-width:calc(var(--aox-760px) + var(--aox-8px));
	margin:0 auto;
	padding:var(--aox-50px) 0 var(--aox-80px) 0;
	background:#f2f2f2;
	}
.section_article .ctn_comment .bg_comment{
	position:absolute;
	z-index:1;
	left:0; top:0;
	width:0; height:100%;
	background:#f2f2f2;
	}
.section_article .ctn_comments{
	position:relative;
	z-index:2;
	}
.section_article .ctn_comments .ctn_top{
	padding-bottom:var(--aox-40px);
	border-bottom:1px solid #b0b0b0;
	}
.section_article .ctn_comments h3{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	}
.section_article .ctn_comments .obj_form .ctn_single-file-upload{
	position:relative;
	z-index:2;
	}
.section_article .ctn_comments .obj_form .ctn_single-file-upload .obj_cta{
	min-height:var(--aox-30px);
	margin:0;
	padding:var(--aox-7px) var(--aox-5px) var(--aox-7px) var(--aox-50px);
	font-size:var(--aox-fs-16px);
	font-weight:300;
	color:#000;
	text-transform:none;
	background:none;
	}
.section_article .ctn_comments .obj_form .ctn_file-upload{
	position:relative;
	}
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus{
	position:absolute;
	z-index:1;
	left:0; top:0;
	width:var(--aox-30px); height:var(--aox-30px);
	border:2px solid #000;
	}
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::before,
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#000;
	transform:translate(-50%,-50%);
	}
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::before{
	width:var(--aox-15px); height:2px;
	}
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::after{
	width:2px; height:var(--aox-15px);
	}
.section_article .ctn_comments .ctn_row{
	padding:var(--aox-50px) 0;
	border-bottom:1px solid #b0b0b0;
	}
.section_article .ctn_comments .ctn_row .obj_form{
	padding:var(--aox-30px) 0 0 var(--aox-55px);
	}
.section_article .ctn_comments .ctn_row .row_level-2{
	margin:var(--aox-40px) 0 0 var(--aox-55px);
	padding-left:var(--aox-30px);
	border-left:1px solid #b0b0b0;
	}
.section_article .ctn_comments .ctn_message{
	display:flex;
	flex-flow:row wrap;
	align-items:flex-start;
	}
.section_article .ctn_comments .row_level-2 .ctn_message + .ctn_message{
	margin-top:var(--aox-40px);
	}
.section_article .ctn_comments .ctn_message figure{
	width:var(--aox-40px); height:var(--aox-40px);
	border-radius:50%;
	overflow:hidden;
	}
.section_article .ctn_comments .ctn_message figure img{
	width:100%;
	}
.section_article .ctn_comments .row_level-2 .ctn_message figure{
	width:var(--aox-30px); height:var(--aox-30px);
	}
.section_article .ctn_comments .ctn_message .ctn_text{
	width:calc(100% - var(--aox-40px));
	padding:var(--aox-5px) var(--aox-15px) 0 var(--aox-15px);
	}
.section_article .ctn_comments .row_level-2 .ctn_message .ctn_text{
	width:calc(100% - var(--aox-30px));
	}
.section_article .ctn_comments .ctn_message .ctn_text h4{
	font-size:var(--aox-fs-12px); line-height:1;
	color:#777;
	}
.section_article .ctn_comments .ctn_message .ctn_text h4 strong{
	font-size:var(--aox-fs-12px);
	color:#000;
	font-weight:700;
	}
.section_article .ctn_comments .ctn_message .ctn_text p{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-14px); line-height:1.6;
	}
.section_article .ctn_comments .ctn_message .ctn_text p.t_error{
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:500;
	color:#ff4847;
	}
.section_article .ctn_comments .ctn_message .ctn_text img{
	margin-top:var(--aox-25px);
	max-width:var(--aox-240px);
	max-height:var(--aox-240px);
	}
.section_article .ctn_comments .bt_reply{
	position:relative;
	display:inline-block;
	margin:var(--aox-25px) 0 0 var(--aox-55px);
	padding:var(--aox-5px) var(--aox-5px) var(--aox-5px) var(--aox-25px);
	font-size:var(--aox-fs-13px); line-height:1;
	font-weight:500;
	}
.section_article .ctn_comments .bt_reply::before,
.section_article .ctn_comments .bt_reply::after{
	content:'';
	position:absolute;
	transition:all .3s ease;
	}
.section_article .ctn_comments .bt_reply::before{
	left:0; top:0;
	width:var(--aox-15px); height:var(--aox-10px);
	border-left:2px solid #000;
	border-bottom:2px solid #000;
	}
.section_article .ctn_comments .bt_reply::after{
	left:var(--aox-8px); top:var(--aox-7px);
	width:var(--aox-6px); height:var(--aox-6px);
	border-top:solid 2px #000;
	border-right:solid 2px #000;
	transform:rotate(45deg);
	}
.section_article .ctn_comments .bt_more{
	display:inline-block;
	margin-top:var(--aox-40px);
	padding:var(--aox-5px) var(--aox-5px) 0 0;
	font-size:var(--aox-fs-13px); line-height:1;
	font-weight:500;
	text-align:left;
	}
.section_article .ctn_comments .ctn_bt .bt_more{
	padding-left:var(--aox-30px);
	}

.section_article .ctn_comments .row_level-1 .t_notif{
	margin:var(--aox-20px) 0 0 var(--aox-55px);
	}
.section_article .ctn_comments .row_level-1 .t_notif span{
	display:inline-block;
	padding:var(--aox-15px) var(--aox-30px);
	font-size:var(--aox-fs-14px); line-height:1.2;
	font-weight:500;
	color:#000;
	background:#fff;
	}

@media screen and (min-width:1025px){
	.section_article .ctn_comments .obj_form .ctn_single-file-upload .obj_cta:hover{
		color:#000 !important;
		}
	.section_article .ctn_comments .bt_reply:hover{
		color:#000 !important;
		}
	.section_article .ctn_comments .bt_reply:hover::before,
	.section_article .ctn_comments .bt_reply:hover::after{
		border-color:#000 !important;
		}
	.section_article .ctn_comments .bt_more:hover{
		color:#000 !important;
		}
}
@media screen and (max-width:768px){
	.section_article .ctn_comment .bg_comment{
		width:0 !important;
		}
	.section_article .ctn_comment{
		padding:var(--aox-50px) var(--aox-20px) var(--aox-80px) var(--aox-20px);
		}
	.section_article .ctn_comments .ctn_message{
		flex-direction:column;
		}
	.section_article .ctn_comments .ctn_message .ctn_text{
		width:100%;
		padding:var(--aox-15px) 0 0 0;
		}
	.section_article .ctn_comments .ctn_row .obj_form{
		padding-left:0;
		}
	.section_article .ctn_comments .ctn_row .row_level-2,
	.section_article .ctn_comments .bt_reply{
		margin-left:var(--aox-30px);
		}
}