/* ======================================== */
/* .section_social-network
/* ======================================== */
.section_social-network{
	width:100%;
	margin:var(--aox-90px) 0;
	}
.section_social-network h2{
	margin-bottom:var(--aox-55px);
	font-size:var(--aox-fs-44px); line-height:1;
	text-align:center;
	}
.section_social-network ul{
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	align-items:center;
	}
.section_social-network ul li{
	margin:0 var(--aox-30px);
	}
.section_social-network ul li a,
.section_social-network ul li a svg{
	display:block;
	transition:all .3s ease;
	}

@media screen and (max-width:1340px){
	.section_social-network h2{
		font-size:var(--aox-fs-44px)
		}
}
@media screen and (max-width:767px){
	.section_social-network{
		margin:var(--aox-60px) 0;
		}
	.section_social-network h2{
		font-size:var(--aox-fs-34px);
		margin-bottom:var(--aox-40px);
		}
	.section_social-network ul li{
		margin:0 var(--aox-15px);
		}
}