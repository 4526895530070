/* ======================================== */
/* .section_marques
/* ======================================== */
.section_marques{
	width:100%;
	}
/**/
.section_marques .ctn_top{
	padding-top:var(--aox-60px);
	}
.section_marques .ctn_top h1{
	padding-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:1.2;
	border-bottom:1px solid #cbcbcb;
	}
/**/
.section_marques .ctn_list-marques{
	display:flex;
	flex-wrap:wrap;
	width:100%;
	max-width:calc(var(--aox-1120px) + var(--aox-5px));
	margin:var(--aox-100px) auto;
	}
.section_marques .ctn_item-marques{
	margin:0 1.3%;
	width:30.7%;
	}
.section_marques .ctn_item-marques strong{
	display:block;
	padding:var(--aox-25px) 0 var(--aox-50px) 0;
	font-size:var(--aox-fs-22px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	text-align:center;
	}
.section_marques .ctn_item-marques figure{
	overflow:hidden;
	}
.section_marques .ctn_item-marques figure img{
	width:100%;
	transition:all .3s ease;
	}
.section_marques .ctn_item-marques a:hover figure img{
	transform:scale(1.1);
	}

@media screen and (max-width:1280px){
	.section_marques .ctn_top{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
}
@media screen and (max-width:767px){
	.section_marques .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_marques .ctn_top{
		padding-top:var(--aox-40px);
		}
	.section_marques .ctn_top h1{
		font-size:var(--aox-fs-40px);
		}
	.section_marques .ctn_list-marques{
		margin:var(--aox-50px) auto;
		}
	.section_marques .ctn_item-marques{
		margin:0 5%;
		width:90%;
		}
	.section_marques .ctn_item-marques strong{
		font-size:var(--aox-fs-18px);
		}
}