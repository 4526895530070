/* ======================================== */
/* .section_resultat
/* ======================================== */
.section_resultat{
	width:100%;
	}
.section_resultat .str_container{
	max-width:var(--aox-960px);
	}
/**/
.section_resultat .ctn_resultat{
	margin-top:var(--aox-60px);
	}
.section_resultat .ctn_resultat .ctn_top{
	padding:0 var(--aox-30px);
	text-align:center;
	}
.section_resultat .ctn_resultat .ctn_top h2{
	font-size:var(--aox-fs-20px); line-height:1.2;
	text-transform:uppercase;
	}
.section_resultat .ctn_resultat .ctn_top h2 strong{
	font-weight:700;
	}
.section_resultat .ctn_resultat .ctn_top p{
	margin-top:var(--aox-10px);
	font-size:var(--aox-fs-16px); line-height:1.2;
	}
.section_resultat .ctn_resultat.var_gift{
	margin-top:var(--aox-10px);
	}
.section_resultat .ctn_resultat.var_gift .ctn_top{
	display:flex;
	align-items:center;
	padding:0;
	text-align:left;
	}
.section_resultat .ctn_resultat.var_gift .ctn_top figure{
	position:relative;
	z-index:1;
	width:30%;
	}
.section_resultat .ctn_resultat.var_gift .ctn_top figure img{
	max-width:100%;
	}
.section_resultat .ctn_resultat.var_gift .ctn_top > div{
	position:relative;
	z-index:2;
	width:70%;
	padding-right:var(--aox-30px);
	}
.section_resultat .ctn_resultat.var_gift .ctn_table{
	margin-top:0;
	}
.section_resultat .ctn_resultat .ctn_table{
	padding:0;
	}
.section_resultat .ctn_resultat .ctn_table table{
	width:100%;
	counter-reset:result-row -1;
	}
.section_resultat .ctn_resultat .ctn_table table tr:nth-child(odd){
	background:#f2f2f2;
	}
.section_resultat .ctn_resultat .ctn_table table tr:first-of-type td:first-of-type::before{
	opacity:0;
	}
.section_resultat .ctn_resultat .ctn_table table td{
	position:relative;
	padding:var(--aox-15px);
	font-size:var(--aox-fs-16px);
	line-height:1.2;
	border:none;
	}
.section_resultat .ctn_resultat .ctn_table table td:nth-child(1){
	width:27%;
	padding-left:10%;
	}
.section_resultat .ctn_resultat .ctn_table table td:nth-child(1)::before{
	counter-increment:result-row;
	content:counter(result-row);
	position:absolute;
	left:11.5%; top:var(--aox-15px);
	font-weight:700;
	}
.section_resultat .ctn_resultat .ctn_table table td:nth-child(2){
	width:42%;
	}
.section_resultat .ctn_resultat .ctn_table table td:nth-child(3){
	width:31%;
	}
@media screen and (max-width:767px){
	.section_resultat .ctn_resultat{
		margin-top:var(--aox-40px);
		}
	.section_resultat .ctn_resultat.var_gift{
		margin-top:var(--aox-30px);
		}
	.section_resultat .ctn_resultat .ctn_table table td{
		padding:var(--aox-15px) var(--aox-10px);
		font-size:var(--aox-fs-13px);
		}
	.section_resultat .ctn_resultat.var_gift .ctn_top{
		padding:var(--aox-10px) 0 var(--aox-40px) 0;
		}
	.section_resultat .ctn_resultat.var_gift .ctn_top figure img{
		transform:scale(1.6);
		}
}