/* ======================================== */
/* .section_no-acces
/* ======================================== */
.section_no-acces{
	width:100%;
	}
.section_no-acces .str_container{
	max-width:var(--aox-960px);
	}
/**/
.section_no-acces .ctn_main{
	padding:var(--aox-100px) 0 var(--aox-150px);
	}
/**/
.section_no-acces .ctn_paragraph{
	padding: var(--aox-80px) var(--aox-20px) 0 var(--aox-20px);
    margin: calc(-1 * var(--aox-20px)) auto 0 auto;
    max-width: var(--aox-560px);
	}
.section_no-acces .ctn_paragraph h2{
	font-weight:700;
	font-size:var(--aox-fs-20px); line-height:1.2;
	}
.section_no-acces .ctn_paragraph p{
	font-size:var(--aox-fs-14px); line-height:1.429;
	}
.section_no-acces .ctn_paragraph.var_align-center{
	text-align:center;
	}
.section_no-acces .ctn_paragraph h2 + p{
	margin-top:var(--aox-10px);
	}
.section_no-acces .ctn_paragraph p + .ctn_cta{
	margin-top:var(--aox-30px);
	}
.section_no-acces .ctn_paragraph p strong{
	font-weight:700;
	}
.section_no-acces .ctn_paragraph p a{
	font-weight:700;
	text-decoration:underline;
	}

/**/

@media screen and (max-width:767px){
	.section_no-acces .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_no-acces .ctn_main{
		padding: var(--aox-70px) 0;
		}
	.section_no-acces .ctn_paragraph{
		padding: var(--aox-50px) var(--aox-20px) 0 var(--aox-20px);
		margin: 0 auto;
		}
}