/* ======================================== */
/* .obj_progress-bar-expertise
/* ======================================== */
:root {
	--color-progressbar-lrp: 0,159,227;
	--color-progressbar-cerave: 37,112,184;
	--color-progressbar-sanoflore: 60,100,60;
	--color-progressbar-vichy: 29,29,27;
	--color-progressbar-skinc: 0,139,176;
	--color-progressbar-biotherm: 32,97,112;
  }
.obj_progress-bar-expertise{
	position: relative;
	}
.obj_progress-bar-expertise .t_title{
	margin-bottom: var(--aox-10px);
    font-size:var(--aox-fs-12px); line-height: 1.2;
    font-weight: 700;
	text-transform: uppercase;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.obj_progress-bar-expertise .t_title span{
	font-weight: 400;
	margin-left: var(--aox-10px);
	}
.obj_progress-bar-expertise .obj_progress-bar{
	position: relative;
	background-color: #F0F0F0;
    border-radius: var(--aox-10px);
	padding: var(--aox-5px) var(--aox-7px);
	height: var(--aox-16px);
	width: 100%;
	}
.obj_progress-bar-expertise + .obj_progress-bar-expertise{
	margin-top: var(--aox-25px);
	}
.obj_progress-bar-expertise .obj_progress-bar .ctn_steps{
	display: flex;
    align-items: center;
    flex-direction: row;
	width: 100%;
	}
.obj_progress-bar-expertise .obj_progress-bar .step {
	position: relative;
	display: block;
	opacity: 0;
	flex: 1;
	height: var(--aox-6px);
    background: #000;
	border-radius: var(--aox-10px);
	}
.obj_progress-bar-expertise .obj_progress-bar .step + .step {
	margin-left: 2px;
	}
.obj_progress-bar-expertise .obj_progress-bar .step.is-complete {
	opacity: 1;
	}
.obj_progress-bar-expertise .obj_progress-bar .step.active ~ .step.step {
	color: #000;
	}

/***/
.obj_progress-bar-expertise .obj_progress-bar .step:not(.is-complete) {
	background: rgb(240,240,240);
	}

/* var_lrp */
.obj_progress-bar-expertise.var_lrp .obj_progress-bar{
	background: rgba(var(--color-progressbar-lrp), 0.15);
	}
.obj_progress-bar-expertise.var_lrp .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-lrp), 1);
	}
.obj_progress-bar-expertise.var_lrp .obj_progress-bar .ctn_cta{
	background: rgba(var(--color-progressbar-lrp), 1);
	}
/* var_cerave */
.obj_progress-bar-expertise.var_cerave .obj_progress-bar{
	background: rgba(var(--color-progressbar-cerave), 0.15);
	}
.obj_progress-bar-expertise.var_cerave .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-cerave), 1);
	}
/* var_vichy */
.obj_progress-bar-expertise.var_vichy .obj_progress-bar{
	background: rgba(var(--color-progressbar-vichy), 0.15);
	}
.obj_progress-bar-expertise.var_vichy .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-vichy), 1);
	}
/* var_skinc */
.obj_progress-bar-expertise.var_skinc .obj_progress-bar{
	background: rgba(var(--color-progressbar-skinc), 0.15);
	}
.obj_progress-bar-expertise.var_skinc .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-skinc), 1);
	}
/* var_sanoflore */
.obj_progress-bar-expertise.var_sanoflore .obj_progress-bar{
	background: rgba(var(--color-progressbar-sanoflore), 0.15);
	}
.obj_progress-bar-expertise.var_sanoflore .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-sanoflore), 1);
	}	
/* var_biotherm */
.obj_progress-bar-expertise.var_biotherm .obj_progress-bar{
	background: rgba(var(--color-progressbar-biotherm), 0.15);
	}
.obj_progress-bar-expertise.var_biotherm .obj_progress-bar .step.is-complete {
	background: rgba(var(--color-progressbar-biotherm), 1);
	}


.obj_progress-bar-expertise .obj_progress-bar .active_profil{
	position: absolute;
	transform: translate(-50%, -50%);
    top: 50%; left: 0%;
    width: var(--aox-32px); height: var(--aox-32px);
    z-index: 2;
	}
.obj_progress-bar-expertise .obj_progress-bar .active_profil {
	width: var(--aox-32px); height: var(--aox-32px);
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid white;
	}




@media screen and (max-width:1150px){

}
@media screen and (min-width:768px) and (max-width:768px){

}
@media screen and (max-width:767px){

}