/* ======================================== */
/* .main_header-login
/* ======================================== */
.main_header-login{
	position:fixed;
	left:0; top:0;
	width:100%; height:var(--aox-80px);
	z-index:9999;
	background:#f2f2f2;
	}
.main_header-login .str_container{
	display:flex;
	}
.main_header-login .logo-caf{
	display:flex;
	align-items:center;
	padding-left:var(--aox-20px);
	}
.main_header-login .logo-caf img{
	width:var(--aox-200px);
	}
/**/
.main_header-login .second_nav-caf{
	margin-left:auto;
	}
.main_header-login .second_nav-caf ul.lvl_01{
	display:flex;
	height:100%;
	}
.main_header-login .second_nav-caf .li_contact a{
	display:flex;
	align-items:center;
	padding:0 var(--aox-20px);
	height:100%;
	}

@media screen and (max-width:767px){
	.main_header-login{
		height:var(--aox-60px);
		}
	.main_header-login .logo-caf img{
		width:var(--aox-160px);
		}
}