/* ======================================== */
/* .section_hero
/* ======================================== */

.section_hero.obj_section {
	opacity: 1;
    padding: 0;
    /* height: var(--aox-910px); */
    overflow: visible;
    background: rgb(235,235,235);
	}


.section_hero .ctn_background {
	position: fixed;
    z-index: 0;
    opacity: 1;
    top: -20px; left: 0;
    height: 104%; width: 100vw;
    transition: all 0.2s ease-in-out;
	}
.section_hero .ctn_background img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: top center;
	}
.section_hero.obj_section .ctn_section{
	position: relative;
    padding: var(--aox-120px) 0 var(--aox-40px);
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: var(--aox-1140px);
	}
.section_hero.obj_section .t_title {
	font-size: var(--aox-fs-16px);
	font-weight: 700;
	text-transform: uppercase;
	}


/* is_blur */
.section_hero.is_blur .ctn_background{
	/* Add the blur effect */
	filter: blur(10px);
	-webkit-filter: blur(10px);
	transition:all 1.2s ease-in-out;
	}

@media screen and (max-width:767px){
	
	.section_hero.obj_section {
		height: auto;
		}
	.section_hero.obj_section .ctn_section{
		padding: var(--aox-30px) 0 0;
		width: 100%;
		height: auto;
		}
	.section_hero .ctn_background{
		/* height: var(--aox-520px); */
		height: auto;
		}


}