@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap);
/* ======================================== */
/* reset
/* ======================================== */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video{
	margin:0;
	padding:0;
	border:0;
	font-size:100%;
	font:inherit;
	vertical-align:baseline;
	}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section{
	display:block;
	}
body{
	line-height:1;
	letter-spacing:0.05em;
	}
ol, ul{
	list-style:none;
	}
blockquote, q{
	quotes:none;
	}
blockquote:before, blockquote:after,
q:before, q:after{
	content:"";
	content:none;
	}
table{
	border-collapse:collapse;
	border-spacing:0;
	}
*{
	box-sizing:border-box;
	}
html{
	/* font-size:62.5%; */
	font-family:'Montserrat', sans-serif;
	font-weight:300;
	}
a{
	color:inherit;
	text-decoration:none;
	outline:none;
	cursor:pointer;
	}
img{
	display:block;
	width:auto; height:auto;
	}
input{
	outline:none;
	font-family:'Montserrat', sans-serif;
	font-weight:300;
	letter-spacing:0.05em;
	}
button{
	margin:0;
    padding:0;
    border:none;
	outline:none;
    background:none;
	cursor:pointer;
	font-family:'Montserrat', sans-serif;
	font-weight:300;
	}
::-webkit-scrollbar{
	-webkit-appearance:none;
	}
::-webkit-scrollbar:vertical{
	width:10px;
	}
::-webkit-scrollbar:horizontal{
	height:10px;
	}
::-webkit-scrollbar-thumb{
	background-color:rgba(0,0,0,0.2);
	border-radius:10px;
	border:2px solid #fff;
	}
::-webkit-scrollbar-track{
	border-radius:10px;
	background-color:#fff;
	}
sup{
	position:relative;
	top:-0.7em;
	font-size:0.5em;
	}
input::-ms-reveal,
input::-ms-clear{
	display: none;
	}
a,
button{
	color:#000;
	}

@media screen and (min-width:1025px){
	a,
	button{
		transition:all .3s ease;
		}
}

body {
	min-width: 100vw;
	position: relative;
	overflow-x: hidden;
	}
/* -------------------------------------------------- */

/*   $ > AOX Init (v2.0.1) - 04/04/23

/* -------------------------------------------------- */

/* ======================================== */
/*  Init
/* ======================================== */

/*

1920 > 1366px(*) => Fixed
1365px > 768px => Fluid
767px > 520px => Fixed
519px > 320px => Fluid

(*) 1366px = Content = margin left (45px) + content width (1276px) + margin right (45px)

*/

:root {

	--aox-1px: 1px;

    --aox-grid: var(--aox-1280px);
	--aox-gutter: var(--aox-40px);
	--aox-columns: 12;
	
	--aox-fs-viewport-width: 1366px;
	--aox-fs-max-width: 1366;
	--aox-fs-min-width: calc(var(--aox-fs-max-width) * 0.75);

	}

/***/

@media screen and (max-width: 1366px) {

	:root {

		--aox-1px: calc(100vw / 1366);

		--aox-fs-viewport-width: 100vw;
		
		}
	
	}

/***/

@media screen and (max-width: 767px) {

	:root {

		--aox-1px: calc(520px / 420);
		
		--aox-grid: var(--aox-380px);
		--aox-gutter: var(--aox-20px);

		--aox-fs-max-width: 420;
		--aox-fs-min-width: 320;
		--aox-fs-viewport-width: 520px;
		
		}
	
	}

/***/

@media screen and (max-width: 520px) {
	
	:root {
	
		--aox-1px: calc(100vw / 420);
	
		--aox-fs-viewport-width: 100vw;
	
		}
	
	}
/* -------------------------------------------------- */

/*   $ > AOX Fluid (v2.0.1) - 04/04/23

/* -------------------------------------------------- */

/* ======================================== */
/*  Fluid
/* ======================================== */

/*** Sizes ***/

:root {

	--aox-2px: calc(var(--aox-1px) * 2);
	--aox-3px: calc(var(--aox-1px) * 3);
	--aox-4px: calc(var(--aox-1px) * 4);
	--aox-5px: calc(var(--aox-1px) * 5);
	--aox-6px: calc(var(--aox-1px) * 6);
	--aox-7px: calc(var(--aox-1px) * 7);
	--aox-8px: calc(var(--aox-1px) * 8);
	--aox-9px: calc(var(--aox-1px) * 9);
	--aox-10px: calc(var(--aox-1px) * 10);
	--aox-11px: calc(var(--aox-1px) * 11);
	--aox-12px: calc(var(--aox-1px) * 12);
	--aox-13px: calc(var(--aox-1px) * 13);
	--aox-14px: calc(var(--aox-1px) * 14);
	--aox-15px: calc(var(--aox-1px) * 15);
	--aox-16px: calc(var(--aox-1px) * 16);
	--aox-17px: calc(var(--aox-1px) * 17);
	--aox-18px: calc(var(--aox-1px) * 18);
	--aox-19px: calc(var(--aox-1px) * 19);
	--aox-20px: calc(var(--aox-1px) * 20);
	--aox-21px: calc(var(--aox-1px) * 21);
	--aox-22px: calc(var(--aox-1px) * 22);
	--aox-23px: calc(var(--aox-1px) * 23);
	--aox-24px: calc(var(--aox-1px) * 24);
	--aox-25px: calc(var(--aox-1px) * 25);
	--aox-26px: calc(var(--aox-1px) * 26);
	--aox-27px: calc(var(--aox-1px) * 27);
	--aox-28px: calc(var(--aox-1px) * 28);
	--aox-29px: calc(var(--aox-1px) * 29);
	--aox-30px: calc(var(--aox-1px) * 30);
	--aox-31px: calc(var(--aox-1px) * 31);
	--aox-32px: calc(var(--aox-1px) * 32);
	--aox-33px: calc(var(--aox-1px) * 33);
	--aox-34px: calc(var(--aox-1px) * 34);
	--aox-35px: calc(var(--aox-1px) * 35);
	--aox-36px: calc(var(--aox-1px) * 36);
	--aox-37px: calc(var(--aox-1px) * 37);
	--aox-38px: calc(var(--aox-1px) * 38);
	--aox-39px: calc(var(--aox-1px) * 39);
	--aox-40px: calc(var(--aox-1px) * 40);
	--aox-41px: calc(var(--aox-1px) * 41);
	--aox-42px: calc(var(--aox-1px) * 42);
	--aox-43px: calc(var(--aox-1px) * 43);
	--aox-44px: calc(var(--aox-1px) * 44);
	--aox-45px: calc(var(--aox-1px) * 45);
	--aox-46px: calc(var(--aox-1px) * 46);
	--aox-47px: calc(var(--aox-1px) * 47);
	--aox-48px: calc(var(--aox-1px) * 48);
	--aox-49px: calc(var(--aox-1px) * 49);
	--aox-50px: calc(var(--aox-1px) * 50);
	--aox-51px: calc(var(--aox-1px) * 51);
	--aox-52px: calc(var(--aox-1px) * 52);
	--aox-53px: calc(var(--aox-1px) * 53);
	--aox-54px: calc(var(--aox-1px) * 54);
	--aox-55px: calc(var(--aox-1px) * 55);
	--aox-56px: calc(var(--aox-1px) * 56);
	--aox-57px: calc(var(--aox-1px) * 57);
	--aox-58px: calc(var(--aox-1px) * 58);
	--aox-59px: calc(var(--aox-1px) * 59);
	--aox-60px: calc(var(--aox-1px) * 60);
	--aox-61px: calc(var(--aox-1px) * 61);
	--aox-62px: calc(var(--aox-1px) * 62);
	--aox-63px: calc(var(--aox-1px) * 63);
	--aox-64px: calc(var(--aox-1px) * 64);
	--aox-65px: calc(var(--aox-1px) * 65);
	--aox-66px: calc(var(--aox-1px) * 66);
	--aox-67px: calc(var(--aox-1px) * 67);
	--aox-68px: calc(var(--aox-1px) * 68);
	--aox-69px: calc(var(--aox-1px) * 69);
	--aox-70px: calc(var(--aox-1px) * 70);
	--aox-71px: calc(var(--aox-1px) * 71);
	--aox-72px: calc(var(--aox-1px) * 72);
	--aox-73px: calc(var(--aox-1px) * 73);
	--aox-74px: calc(var(--aox-1px) * 74);
	--aox-75px: calc(var(--aox-1px) * 75);
	--aox-76px: calc(var(--aox-1px) * 76);
	--aox-77px: calc(var(--aox-1px) * 77);
	--aox-78px: calc(var(--aox-1px) * 78);
	--aox-79px: calc(var(--aox-1px) * 79);
	--aox-80px: calc(var(--aox-1px) * 80);
	--aox-81px: calc(var(--aox-1px) * 81);
	--aox-82px: calc(var(--aox-1px) * 82);
	--aox-83px: calc(var(--aox-1px) * 83);
	--aox-84px: calc(var(--aox-1px) * 84);
	--aox-85px: calc(var(--aox-1px) * 85);
	--aox-86px: calc(var(--aox-1px) * 86);
	--aox-87px: calc(var(--aox-1px) * 87);
	--aox-88px: calc(var(--aox-1px) * 88);
	--aox-89px: calc(var(--aox-1px) * 89);
	--aox-90px: calc(var(--aox-1px) * 90);
	--aox-91px: calc(var(--aox-1px) * 91);
	--aox-92px: calc(var(--aox-1px) * 92);
	--aox-93px: calc(var(--aox-1px) * 93);
	--aox-94px: calc(var(--aox-1px) * 94);
	--aox-95px: calc(var(--aox-1px) * 95);
	--aox-96px: calc(var(--aox-1px) * 96);
	--aox-97px: calc(var(--aox-1px) * 97);
	--aox-98px: calc(var(--aox-1px) * 98);
	--aox-99px: calc(var(--aox-1px) * 99);
	--aox-100px: calc(var(--aox-1px) * 100);

	--aox-105px: calc(var(--aox-1px) * 105);
	--aox-110px: calc(var(--aox-1px) * 110);
	--aox-115px: calc(var(--aox-1px) * 115);
	--aox-120px: calc(var(--aox-1px) * 120);
	--aox-125px: calc(var(--aox-1px) * 125);
	--aox-130px: calc(var(--aox-1px) * 130);
	--aox-135px: calc(var(--aox-1px) * 135);
	--aox-140px: calc(var(--aox-1px) * 140);
	--aox-145px: calc(var(--aox-1px) * 145);
	--aox-150px: calc(var(--aox-1px) * 150);
	--aox-155px: calc(var(--aox-1px) * 155);
	--aox-160px: calc(var(--aox-1px) * 160);
	--aox-165px: calc(var(--aox-1px) * 165);
	--aox-170px: calc(var(--aox-1px) * 170);
	--aox-175px: calc(var(--aox-1px) * 175);
	--aox-180px: calc(var(--aox-1px) * 180);
	--aox-185px: calc(var(--aox-1px) * 185);
	--aox-190px: calc(var(--aox-1px) * 190);
	--aox-195px: calc(var(--aox-1px) * 195);
	--aox-200px: calc(var(--aox-1px) * 200);
	--aox-205px: calc(var(--aox-1px) * 205);
	--aox-210px: calc(var(--aox-1px) * 210);
	--aox-215px: calc(var(--aox-1px) * 215);
	--aox-220px: calc(var(--aox-1px) * 220);
	--aox-225px: calc(var(--aox-1px) * 225);
	--aox-230px: calc(var(--aox-1px) * 230);
	--aox-235px: calc(var(--aox-1px) * 235);
	--aox-240px: calc(var(--aox-1px) * 240);
	--aox-245px: calc(var(--aox-1px) * 245);
	--aox-250px: calc(var(--aox-1px) * 250);
	--aox-255px: calc(var(--aox-1px) * 255);
	--aox-260px: calc(var(--aox-1px) * 260);
	--aox-265px: calc(var(--aox-1px) * 265);
	--aox-270px: calc(var(--aox-1px) * 270);
	--aox-275px: calc(var(--aox-1px) * 275);
	--aox-280px: calc(var(--aox-1px) * 280);
	--aox-285px: calc(var(--aox-1px) * 285);
	--aox-290px: calc(var(--aox-1px) * 290);
	--aox-295px: calc(var(--aox-1px) * 295);
	--aox-300px: calc(var(--aox-1px) * 300);
	--aox-305px: calc(var(--aox-1px) * 305);
	--aox-310px: calc(var(--aox-1px) * 310);
	--aox-315px: calc(var(--aox-1px) * 315);
	--aox-320px: calc(var(--aox-1px) * 320);
	--aox-325px: calc(var(--aox-1px) * 325);
	--aox-330px: calc(var(--aox-1px) * 330);
	--aox-335px: calc(var(--aox-1px) * 335);
	--aox-340px: calc(var(--aox-1px) * 340);
	--aox-345px: calc(var(--aox-1px) * 345);
	--aox-350px: calc(var(--aox-1px) * 350);
	--aox-355px: calc(var(--aox-1px) * 355);
	--aox-360px: calc(var(--aox-1px) * 360);
	--aox-365px: calc(var(--aox-1px) * 365);
	--aox-370px: calc(var(--aox-1px) * 370);
	--aox-375px: calc(var(--aox-1px) * 375);
	--aox-380px: calc(var(--aox-1px) * 380);
	--aox-385px: calc(var(--aox-1px) * 385);
	--aox-390px: calc(var(--aox-1px) * 390);
	--aox-395px: calc(var(--aox-1px) * 395);
	--aox-400px: calc(var(--aox-1px) * 400);
	--aox-405px: calc(var(--aox-1px) * 405);
	--aox-410px: calc(var(--aox-1px) * 410);
	--aox-415px: calc(var(--aox-1px) * 415);
	--aox-420px: calc(var(--aox-1px) * 420);

	
	--aox-430px: calc(var(--aox-1px) * 430);
	--aox-440px: calc(var(--aox-1px) * 440);
	--aox-450px: calc(var(--aox-1px) * 450);
	--aox-460px: calc(var(--aox-1px) * 460);
	--aox-470px: calc(var(--aox-1px) * 470);
	--aox-480px: calc(var(--aox-1px) * 480);
	--aox-490px: calc(var(--aox-1px) * 490);
	--aox-500px: calc(var(--aox-1px) * 500);
	--aox-510px: calc(var(--aox-1px) * 510);
	--aox-520px: calc(var(--aox-1px) * 520);
	--aox-530px: calc(var(--aox-1px) * 530);
	--aox-540px: calc(var(--aox-1px) * 540);
	--aox-550px: calc(var(--aox-1px) * 550);
	--aox-560px: calc(var(--aox-1px) * 560);
	--aox-570px: calc(var(--aox-1px) * 570);
	--aox-580px: calc(var(--aox-1px) * 580);
	--aox-590px: calc(var(--aox-1px) * 590);
	--aox-600px: calc(var(--aox-1px) * 600);
	--aox-610px: calc(var(--aox-1px) * 610);
	--aox-620px: calc(var(--aox-1px) * 620);
	--aox-630px: calc(var(--aox-1px) * 630);
	--aox-640px: calc(var(--aox-1px) * 640);
	--aox-650px: calc(var(--aox-1px) * 650);
	--aox-660px: calc(var(--aox-1px) * 660);
	--aox-670px: calc(var(--aox-1px) * 670);
	--aox-680px: calc(var(--aox-1px) * 680);
	--aox-690px: calc(var(--aox-1px) * 690);
	--aox-700px: calc(var(--aox-1px) * 700);
	--aox-710px: calc(var(--aox-1px) * 710);
	--aox-720px: calc(var(--aox-1px) * 720);
	--aox-730px: calc(var(--aox-1px) * 730);
	--aox-740px: calc(var(--aox-1px) * 740);
	--aox-750px: calc(var(--aox-1px) * 750);
	--aox-760px: calc(var(--aox-1px) * 760);
	--aox-770px: calc(var(--aox-1px) * 770);
	--aox-780px: calc(var(--aox-1px) * 780);
	--aox-790px: calc(var(--aox-1px) * 790);
	--aox-800px: calc(var(--aox-1px) * 800);
	--aox-810px: calc(var(--aox-1px) * 810);
	--aox-820px: calc(var(--aox-1px) * 820);
	--aox-830px: calc(var(--aox-1px) * 830);
	--aox-840px: calc(var(--aox-1px) * 840);
	--aox-850px: calc(var(--aox-1px) * 850);
	--aox-860px: calc(var(--aox-1px) * 860);
	--aox-870px: calc(var(--aox-1px) * 870);
	--aox-880px: calc(var(--aox-1px) * 880);
	--aox-890px: calc(var(--aox-1px) * 890);
	--aox-900px: calc(var(--aox-1px) * 900);
	--aox-910px: calc(var(--aox-1px) * 910);
	--aox-920px: calc(var(--aox-1px) * 920);
	--aox-930px: calc(var(--aox-1px) * 930);
	--aox-940px: calc(var(--aox-1px) * 940);
	--aox-950px: calc(var(--aox-1px) * 950);
	--aox-960px: calc(var(--aox-1px) * 960);
	--aox-970px: calc(var(--aox-1px) * 970);
	--aox-980px: calc(var(--aox-1px) * 980);
	--aox-990px: calc(var(--aox-1px) * 990);
	--aox-1000px: calc(var(--aox-1px) * 1000);
	--aox-1010px: calc(var(--aox-1px) * 1010);
	--aox-1020px: calc(var(--aox-1px) * 1020);
	--aox-1030px: calc(var(--aox-1px) * 1030);
	--aox-1040px: calc(var(--aox-1px) * 1040);
	--aox-1050px: calc(var(--aox-1px) * 1050);
	--aox-1060px: calc(var(--aox-1px) * 1060);
	--aox-1070px: calc(var(--aox-1px) * 1070);
	--aox-1080px: calc(var(--aox-1px) * 1080);
	--aox-1090px: calc(var(--aox-1px) * 1090);
	--aox-1100px: calc(var(--aox-1px) * 1100);
	--aox-1110px: calc(var(--aox-1px) * 1110);
	--aox-1120px: calc(var(--aox-1px) * 1120);
	--aox-1130px: calc(var(--aox-1px) * 1130);
	--aox-1140px: calc(var(--aox-1px) * 1140);
	--aox-1150px: calc(var(--aox-1px) * 1150);
	--aox-1160px: calc(var(--aox-1px) * 1160);
	--aox-1170px: calc(var(--aox-1px) * 1170);
	--aox-1180px: calc(var(--aox-1px) * 1180);
	--aox-1190px: calc(var(--aox-1px) * 1190);
	--aox-1200px: calc(var(--aox-1px) * 1200);
	--aox-1210px: calc(var(--aox-1px) * 1210);
	--aox-1220px: calc(var(--aox-1px) * 1220);
	--aox-1230px: calc(var(--aox-1px) * 1230);
	--aox-1240px: calc(var(--aox-1px) * 1240);
	--aox-1250px: calc(var(--aox-1px) * 1250);
	--aox-1260px: calc(var(--aox-1px) * 1260);
	--aox-1270px: calc(var(--aox-1px) * 1270);
	--aox-1280px: calc(var(--aox-1px) * 1280);
	--aox-1290px: calc(var(--aox-1px) * 1290);
	--aox-1300px: calc(var(--aox-1px) * 1300);
	--aox-1310px: calc(var(--aox-1px) * 1310);
	--aox-1320px: calc(var(--aox-1px) * 1320);
	--aox-1330px: calc(var(--aox-1px) * 1330);
	--aox-1340px: calc(var(--aox-1px) * 1340);
	--aox-1350px: calc(var(--aox-1px) * 1350);
	--aox-1360px: calc(var(--aox-1px) * 1360);
	--aox-1370px: calc(var(--aox-1px) * 1370);
	--aox-1380px: calc(var(--aox-1px) * 1380);
	--aox-1390px: calc(var(--aox-1px) * 1390);
	--aox-1400px: calc(var(--aox-1px) * 1400);
	--aox-1410px: calc(var(--aox-1px) * 1410);
	--aox-1420px: calc(var(--aox-1px) * 1420);
	--aox-1430px: calc(var(--aox-1px) * 1430);
	--aox-1440px: calc(var(--aox-1px) * 1440);
	--aox-1450px: calc(var(--aox-1px) * 1450);
	--aox-1460px: calc(var(--aox-1px) * 1460);
	--aox-1470px: calc(var(--aox-1px) * 1470);
	--aox-1480px: calc(var(--aox-1px) * 1480);
	--aox-1490px: calc(var(--aox-1px) * 1490);
	--aox-1500px: calc(var(--aox-1px) * 1500);
	--aox-1510px: calc(var(--aox-1px) * 1510);
	--aox-1520px: calc(var(--aox-1px) * 1520);
	--aox-1530px: calc(var(--aox-1px) * 1530);
	--aox-1540px: calc(var(--aox-1px) * 1540);
	--aox-1550px: calc(var(--aox-1px) * 1550);
	--aox-1560px: calc(var(--aox-1px) * 1560);
	--aox-1570px: calc(var(--aox-1px) * 1570);
	--aox-1580px: calc(var(--aox-1px) * 1580);
	--aox-1590px: calc(var(--aox-1px) * 1590);
	--aox-1600px: calc(var(--aox-1px) * 1600);
	--aox-1610px: calc(var(--aox-1px) * 1610);
	--aox-1620px: calc(var(--aox-1px) * 1620);
	--aox-1630px: calc(var(--aox-1px) * 1630);
	--aox-1640px: calc(var(--aox-1px) * 1640);
	--aox-1650px: calc(var(--aox-1px) * 1650);
	--aox-1660px: calc(var(--aox-1px) * 1660);
	--aox-1670px: calc(var(--aox-1px) * 1670);
	--aox-1680px: calc(var(--aox-1px) * 1680);
	--aox-1690px: calc(var(--aox-1px) * 1690);
	--aox-1700px: calc(var(--aox-1px) * 1700);
	--aox-1710px: calc(var(--aox-1px) * 1710);
	--aox-1720px: calc(var(--aox-1px) * 1720);
	--aox-1730px: calc(var(--aox-1px) * 1730);
	--aox-1740px: calc(var(--aox-1px) * 1740);
	--aox-1750px: calc(var(--aox-1px) * 1750);
	--aox-1760px: calc(var(--aox-1px) * 1760);
	--aox-1770px: calc(var(--aox-1px) * 1770);
	--aox-1780px: calc(var(--aox-1px) * 1780);
	--aox-1790px: calc(var(--aox-1px) * 1790);
	--aox-1800px: calc(var(--aox-1px) * 1800);
	--aox-1810px: calc(var(--aox-1px) * 1810);
	--aox-1820px: calc(var(--aox-1px) * 1820);
	--aox-1830px: calc(var(--aox-1px) * 1830);
	--aox-1840px: calc(var(--aox-1px) * 1840);
	--aox-1850px: calc(var(--aox-1px) * 1850);
	--aox-1860px: calc(var(--aox-1px) * 1860);
	--aox-1870px: calc(var(--aox-1px) * 1870);
	--aox-1890px: calc(var(--aox-1px) * 1890);
	--aox-1900px: calc(var(--aox-1px) * 1900);
	--aox-1920px: calc(var(--aox-1px) * 1910);

	--aox-1024px: calc(var(--aox-1px) * 1024);
	--aox-1276px: calc(var(--aox-1px) * 1276);
	--aox-1366px: calc(var(--aox-1px) * 1366);
	--aox-1536px: calc(var(--aox-1px) * 1536);
	
	}

/* ======================================== */
/*  Grid
/* ======================================== */

/*** Calculations ***/

:root {
	--aox-external-gutter: calc((100vw - var(--aox-grid)) / 2);
    --aox-col: calc((var(--aox-grid) - (calc(var(--aox-columns) - 1) * var(--aox-gutter))) / var(--aox-columns));
	--aox-col-gutter: calc(var(--aox-col) + var(--aox-gutter));
	}

/*** Sizes ***/

:root {
    --aox-col-1: calc(var(--aox-col) * 1);
    --aox-col-2: calc((var(--aox-col) * 2) + (var(--aox-gutter) * 1));
    --aox-col-3: calc((var(--aox-col) * 3) + (var(--aox-gutter) * 2));
    --aox-col-4: calc((var(--aox-col) * 4) + (var(--aox-gutter) * 3));
    --aox-col-5: calc((var(--aox-col) * 5) + (var(--aox-gutter) * 4));
    --aox-col-6: calc((var(--aox-col) * 6) + (var(--aox-gutter) * 5));
    --aox-col-7: calc((var(--aox-col) * 7) + (var(--aox-gutter) * 6));
    --aox-col-8: calc((var(--aox-col) * 8) + (var(--aox-gutter) * 7));
    --aox-col-9: calc((var(--aox-col) * 9) + (var(--aox-gutter) * 8));
    --aox-col-10: calc((var(--aox-col) * 10) + (var(--aox-gutter) * 9));
    --aox-col-11: calc((var(--aox-col) * 11) + (var(--aox-gutter) * 10));
    --aox-col-12: calc((var(--aox-col) * 12) + (var(--aox-gutter) * 11));
	--aox-col-13: calc((var(--aox-col) * 13) + (var(--aox-gutter) * 12));
	--aox-col-14: calc((var(--aox-col) * 14) + (var(--aox-gutter) * 13));
	--aox-col-15: calc((var(--aox-col) * 15) + (var(--aox-gutter) * 14));
	--aox-col-16: calc((var(--aox-col) * 16) + (var(--aox-gutter) * 15));
	}

:root {
    --aox-col-1-gutter: calc((var(--aox-col) * 1) + (var(--aox-gutter) * 1));
    --aox-col-2-gutter: calc((var(--aox-col) * 2) + (var(--aox-gutter) * 2));
    --aox-col-3-gutter: calc((var(--aox-col) * 3) + (var(--aox-gutter) * 3));
    --aox-col-4-gutter: calc((var(--aox-col) * 4) + (var(--aox-gutter) * 4));
    --aox-col-5-gutter: calc((var(--aox-col) * 5) + (var(--aox-gutter) * 5));
    --aox-col-6-gutter: calc((var(--aox-col) * 6) + (var(--aox-gutter) * 6));
    --aox-col-7-gutter: calc((var(--aox-col) * 7) + (var(--aox-gutter) * 7));
    --aox-col-8-gutter: calc((var(--aox-col) * 8) + (var(--aox-gutter) * 8));
    --aox-col-9-gutter: calc((var(--aox-col) * 9) + (var(--aox-gutter) * 9));
    --aox-col-10-gutter: calc((var(--aox-col) * 10) + (var(--aox-gutter) * 10));
    --aox-col-11-gutter: calc((var(--aox-col) * 11) + (var(--aox-gutter) * 11));
	--aox-col-12-gutter: calc((var(--aox-col) * 12) + (var(--aox-gutter) * 12));
	--aox-col-13-gutter: calc((var(--aox-col) * 13) + (var(--aox-gutter) * 13));
	--aox-col-14-gutter: calc((var(--aox-col) * 14) + (var(--aox-gutter) * 14));
	--aox-col-15-gutter: calc((var(--aox-col) * 15) + (var(--aox-gutter) * 15));
	}

@media screen and (max-width: 767px) {

	/*** Calculations ***/

	:root {
		--aox-external-gutter: calc((100vw - var(--aox-grid)) / 2);
		--aox-col: calc((var(--aox-grid) - (5 * var(--aox-gutter))) / 6);
		--aox-col-gutter: calc(var(--aox-col) + var(--aox-gutter));
		}

	/*** Sizes ***/

	:root {
		--aox-col-1: calc(var(--aox-col) * 1);
		--aox-col-2: calc((var(--aox-col) * 2) + (var(--aox-gutter) * 1));
		--aox-col-3: calc((var(--aox-col) * 3) + (var(--aox-gutter) * 2));
		--aox-col-4: calc((var(--aox-col) * 4) + (var(--aox-gutter) * 3));
		--aox-col-5: calc((var(--aox-col) * 5) + (var(--aox-gutter) * 4));
		--aox-col-6: calc((var(--aox-col) * 6) + (var(--aox-gutter) * 5));
		--aox-col-7: 100%;
		--aox-col-8: 100%;
		--aox-col-9: 100%;
		--aox-col-10: 100%;
		--aox-col-11: 100%;
		--aox-col-12: 100%;
		--aox-col-13: 100%;
		--aox-col-14: 100%;
		--aox-col-15: 100%;
		--aox-col-16: 100%;
		}

	}

/* ======================================== */
/*  Debug
/* ======================================== */

.obj_grid_debug {
	position: fixed;
	top: 0;
	left: calc((100vw - var(--aox-grid)) / 2);
	left: var(--aox-external-gutter);
	z-index: 999;
    display: flex;
    flex-direction: row;
    height: 100vh;
	width: 100%;
    max-width: var(--aox-grid);
	}
.obj_grid_debug > * {
	position: relative;
	width: calc((var(--aox-grid) - (calc(var(--aox-columns) - 1) * var(--aox-gutter))) / var(--aox-columns));
	width: var(--aox-col);
	border-left: solid 1px rgba(0, 255, 255, 0.3);
	border-right: solid 1px rgba(0, 255, 255, 0.3);
	background: rgba(0, 255, 255, 0.15);
	}
.obj_grid_debug > * + * {
	margin-left: var(--aox-gutter);
	}

@media screen and (max-width: 767px) {

	.obj_grid_debug > * {
		display: none;
		}
	.obj_grid_debug > *:nth-child(-n+6) {
		display: block;
		}

	}
/* -------------------------------------------------- */

/*   $ > AOX Font-size (v2.0.1) - 04/04/23

/* -------------------------------------------------- */

/*** Calculations ***/

:root {
	--aox-fs-resize: calc(
		(var(--aox-fs-viewport-width) - (var(--aox-fs-min-width) * 1px)) /
		(var(--aox-fs-max-width) - var(--aox-fs-min-width))
		);
	}

/*** Sizes ***/

:root {

	--aox-fs-8px: calc(0.4375rem + (8 - 7) * var(--aox-fs-resize));
	--aox-fs-9px: calc(0.5rem + (9 - 8) * var(--aox-fs-resize));
	--aox-fs-10px: calc(0.5625rem + (10 - 9) * var(--aox-fs-resize));
	--aox-fs-11px: calc(0.5625rem + (11 - 9) * var(--aox-fs-resize));
	--aox-fs-12px: calc(0.625rem + (12 - 10) * var(--aox-fs-resize));
	--aox-fs-13px: calc(0.6875rem + (13 - 11) * var(--aox-fs-resize));
	--aox-fs-14px: calc(0.75rem + (14 - 12) * var(--aox-fs-resize));
	--aox-fs-15px: calc(0.8125rem + (15 - 13) * var(--aox-fs-resize));
	--aox-fs-16px: calc(0.875rem + (16 - 14) * var(--aox-fs-resize));
	--aox-fs-17px: calc(0.875rem + (17 - 14) * var(--aox-fs-resize));
	--aox-fs-18px: calc(0.9375rem + (18 - 15) * var(--aox-fs-resize));
	--aox-fs-19px: calc(1rem + (19 - 16) * var(--aox-fs-resize));
	--aox-fs-20px: calc(1.0625rem + (20 - 17) * var(--aox-fs-resize));

	--aox-fs-22px: calc(1.125rem + (22 - 18) * var(--aox-fs-resize));
	--aox-fs-24px: calc(1.1875rem + (24 - 19) * var(--aox-fs-resize));
	--aox-fs-26px: calc(1.3125rem + (26 - 21) * var(--aox-fs-resize));
	--aox-fs-28px: calc(1.375rem + (28 - 22) * var(--aox-fs-resize));
	--aox-fs-30px: calc(1.5rem + (30 - 24) * var(--aox-fs-resize));

	--aox-fs-32px: calc(1.5rem + (32 - 24) * var(--aox-fs-resize));
	--aox-fs-34px: calc(1.625rem + (34 - 26) * var(--aox-fs-resize));
	--aox-fs-36px: calc(1.6875rem + (36 - 27) * var(--aox-fs-resize));
	--aox-fs-38px: calc(1.8125rem + (38 - 29) * var(--aox-fs-resize));
	--aox-fs-40px: calc(1.875rem + (40 - 30) * var(--aox-fs-resize));
	--aox-fs-42px: calc(2rem + (42 - 32) * var(--aox-fs-resize));
	--aox-fs-44px: calc(2.0625rem + (44 - 33) * var(--aox-fs-resize));
	--aox-fs-46px: calc(2.1875rem + (46 - 35) * var(--aox-fs-resize));
	--aox-fs-48px: calc(2.25rem + (48 - 36) * var(--aox-fs-resize));
	--aox-fs-50px: calc(2.3725rem + (50 - 38) * var(--aox-fs-resize));
	
	--aox-fs-60px: calc(2.625rem + (60 - 42) * var(--aox-fs-resize));
	--aox-fs-70px: calc(3.0625rem + (70 - 49) * var(--aox-fs-resize));
	--aox-fs-80px: calc(3.3rem + (80 - 56) * var(--aox-fs-resize));
	--aox-fs-90px: calc(3.9375rem + (90 - 63) * var(--aox-fs-resize));
	--aox-fs-100px: calc(4.375rem + (100 - 70) * var(--aox-fs-resize));

	--aox-lh-50: 0.5;
	--aox-lh-60: 0.6;
	--aox-lh-70: 0.7;
	--aox-lh-80: 0.8;
	--aox-lh-90: 0.9;
	--aox-lh-100: 1;
	--aox-lh-110: 1.1;
	--aox-lh-120: 1.2;
	--aox-lh-130: 1.3;
	--aox-lh-140: 1.4;
	--aox-lh-150: 1.5;
	--aox-lh-160: 1.6;
	--aox-lh-170: 1.7;
	--aox-lh-180: 1.8;
	--aox-lh-190: 1.9;
	--aox-lh-200: 2;

	}

/*** Utilities ***/

:root .has_accents {
	--aox-lh-50: 1;
	--aox-lh-60: 1;
	--aox-lh-70: 1;
	--aox-lh-80: 1;
	--aox-lh-90: 1;
	}

small {
	font-size: 0.85em;
	}

[data-aox-lc] {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	}
[data-aox-lc="1"] { -webkit-line-clamp: 1; }
[data-aox-lc="2"] { -webkit-line-clamp: 2; }
[data-aox-lc="3"] { -webkit-line-clamp: 3; }
[data-aox-lc="4"] { -webkit-line-clamp: 4; }
[data-aox-lc="5"] { -webkit-line-clamp: 5; }
[data-aox-lc="6"] { -webkit-line-clamp: 6; }
[data-aox-lc="7"] { -webkit-line-clamp: 7; }
[data-aox-lc="8"] { -webkit-line-clamp: 8; }
[data-aox-lc="9"] { -webkit-line-clamp: 9; }
[data-aox-lc="10"] { -webkit-line-clamp: 10; }
/* ======================================== */
/* webfonts
/* ======================================== */

/*** CAF ***/
/*
	https://fonts.googleapis.com/css?family=Montserrat:300,500,700&display=swap
	font-family: 'Montserrat', sans-serif;
	Light: 300
	Medium: 500
	Bold: 700
*/


/*** LRP ***/
@font-face{
	font-family:'LocatorWeb-Light';
	src:url(/dist/formationcaf/static/media/LocatorWebLight.ccc3f136.woff2) format('woff2'), url(/dist/formationcaf/static/media/LocatorWebLight.8604f9cb.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'LocatorWeb-Regular';
	src:url(/dist/formationcaf/static/media/LocatorWebRegular.77e78752.woff2) format('woff2'), url(/dist/formationcaf/static/media/LocatorWebRegular.56c9cf9b.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'LocatorWeb-Bold';
	src:url(/dist/formationcaf/static/media/LocatorWebBold.454ef9d1.woff2) format('woff2'), url(/dist/formationcaf/static/media/LocatorWebBold.f523903a.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/*** VICHY ***/
@font-face{
	font-family:'CalibreWeb-Thin';
	src:url(/dist/formationcaf/static/media/CalibreWebThin.bb5ede06.woff2) format('woff2'), url(/dist/formationcaf/static/media/CalibreWebThin.5653b802.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'CalibreWeb-Regular';
	src:url(/dist/formationcaf/static/media/CalibreWebRegular.f854ec1a.woff2) format('woff2'), url(/dist/formationcaf/static/media/CalibreWebRegular.eb7ce320.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'CalibreWeb-Bold';
	src:url(/dist/formationcaf/static/media/CalibreWebBold.1d50d15b.woff2) format('woff2'), url(/dist/formationcaf/static/media/CalibreWebBold.26e99e9b.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/*** VICHY - 11/02/2021 ***/
/* @import url("//hello.myfonts.net/count/3c8cfd"); */

@font-face{
	font-family:"FuturaPT-Light";
	src:url(/dist/formationcaf/static/media/FuturaPT-Light.3e06a091.woff2) format("woff2"), url(/dist/formationcaf/static/media/FuturaPT-Light.e9207cf1.woff) format("woff");
	font-weight:100;
	font-display:swap;
	}
@font-face {
	font-family:"FuturaPT-Book";
	src:url(/dist/formationcaf/static/media/FuturaPT-Book.55e113d2.woff2) format("woff2"), url(/dist/formationcaf/static/media/FuturaPT-Book.e358d6b9.woff) format("woff");
	font-weight:400;
	font-display:swap;
	}
@font-face {
	font-family:"FuturaPT-Bold";
	src:url(/dist/formationcaf/static/media/FuturaPT-Bold.366e1bf0.woff2) format("woff2"), url(/dist/formationcaf/static/media/FuturaPT-Bold.67dcdf4d.woff) format("woff");
	font-weight:700;
	font-display:swap;
	}

/*** SANOFLORE ***/
@font-face{
	font-family:"GillSans-Light";
	src:url(/dist/formationcaf/static/media/GillSansLight.e8fffdf3.woff2) format("woff2"), url(/dist/formationcaf/static/media/GillSansLight.75e3f1da.woff) format("woff");
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:"GillSans-Regular";
	src:url(/dist/formationcaf/static/media/GillSansRegular.b6a41d8f.woff2) format("woff2"), url(/dist/formationcaf/static/media/GillSansRegular.dfcf8c44.woff) format("woff");
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:"GillSans-Bold";
	src:url(/dist/formationcaf/static/media/GillSansBold.64bc585c.woff2) format("woff2"), url(/dist/formationcaf/static/media/GillSansBold.cd9d1603.woff) format("woff");
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/*** CERAVE ***/
@font-face{
	font-family:'DinPro-Light';
	src:url(/dist/formationcaf/static/media/DinProLight.63b32a97.woff2) format('woff2'), url(/dist/formationcaf/static/media/DinProLight.9374e098.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'DinPro-Regular';
	src:url(/dist/formationcaf/static/media/DinProRegular.a2d45f99.woff2) format('woff2'), url(/dist/formationcaf/static/media/DinProRegular.51903b85.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'DinPro-Bold';
	src:url(/dist/formationcaf/static/media/DinProBold.e27fbcb8.woff2) format('woff2'), url(/dist/formationcaf/static/media/DinProBold.dab5a6e6.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/*** SKINC ***/
@font-face{
	font-family:'FuturaBT-Light';
	src:url(/dist/formationcaf/static/media/FuturaBTLight.c95b2153.woff2) format('woff2'), url(/dist/formationcaf/static/media/FuturaBTLight.c7fb8476.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'FuturaBT-Book';
	src:url(/dist/formationcaf/static/media/FuturaBTBook.c7f7bea5.woff2) format('woff2'), url(/dist/formationcaf/static/media/FuturaBTBook.bd623c96.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'FuturaBT-Medium';
	src:url(/dist/formationcaf/static/media/FuturaBTMedium.98e788b2.woff2) format('woff2'), url(/dist/formationcaf/static/media/FuturaBTMedium.b66bad69.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}


/*** DERM EXPERTISE ***/
@font-face{
	font-family:'Montserrat-Light';
	src:url(/dist/formationcaf/static/media/Montserrat-Light.09cccaad.woff2) format('woff2'), url(/dist/formationcaf/static/media/Montserrat-Light.86c842f1.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'Montserrat-Regular';
	src:url(/dist/formationcaf/static/media/Montserrat-Regular.e16f2473.woff2) format('woff2'), url(/dist/formationcaf/static/media/Montserrat-Regular.b75ba0cc.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}
@font-face{
	font-family:'Montserrat-Bold';
	src:url(/dist/formationcaf/static/media/Montserrat-Bold.5d01e6b5.woff2) format('woff2'), url(/dist/formationcaf/static/media/Montserrat-Bold.23816c7e.woff) format('woff');
	font-weight:normal;
	font-style:normal;
	font-display:fallback;
	}

/* ======================================== */
/* Animations
/* ======================================== */
.fade-in{
	-webkit-animation:fadeIn 1s ease;
	        animation:fadeIn 1s ease;
	}
@-webkit-keyframes fadeIn{
	from{opacity:0}
	to{opacity:1}
	}
@keyframes fadeIn{
	from{opacity:0}
	to{opacity:1}
	}
.rotation-loop{
	-webkit-animation:rotationLoop 1s ease infinite;
	        animation:rotationLoop 1s ease infinite;
	}
@-webkit-keyframes rotationLoop{
	from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}
	to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}
	}
@keyframes rotationLoop{
	from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}
	to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}
	}

	
@-webkit-keyframes effectHeroTitle {
	from {
		opacity:0;
		-webkit-transform: translateX(-50px);
		        transform: translateX(-50px);
	}
	to {
		opacity:1;
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
}

	
@keyframes effectHeroTitle {
	from {
		opacity:0;
		-webkit-transform: translateX(-50px);
		        transform: translateX(-50px);
	}
	to {
		opacity:1;
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
}
@-webkit-keyframes effectHeroCaseBrands {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30px) rotateX(-45deg);
		        transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0) rotateX(0deg);
		        transform: translateY(0) rotateX(0deg);
	}
}
@keyframes effectHeroCaseBrands {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30px) rotateX(-45deg);
		        transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0) rotateX(0deg);
		        transform: translateY(0) rotateX(0deg);
	}
}
@-webkit-keyframes effectHeroHighlightedBrands {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30px) rotateX(-45deg);
		        transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0) rotateX(0deg);
		        transform: translateY(0) rotateX(0deg);
	}
}
@keyframes effectHeroHighlightedBrands {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30px) rotateX(-45deg);
		        transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0) rotateX(0deg);
		        transform: translateY(0) rotateX(0deg);
	}
}
@-webkit-keyframes effectHeroEntry {
	from {
		opacity: 0;
		-webkit-transform: translateY(-50px);
		        transform: translateY(-50px)
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0)
	}
}
@keyframes effectHeroEntry {
	from {
		opacity: 0;
		-webkit-transform: translateY(-50px);
		        transform: translateY(-50px)
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0)
	}
}
@-webkit-keyframes effectHeroOtherBrands {
	from {
		opacity: 0;
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px)
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0)
	}
}
@keyframes effectHeroOtherBrands {
	from {
		opacity: 0;
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px)
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		        transform: translateY(0)
	}
}
@-webkit-keyframes textEffectSmooth{
    0% { opacity:0; }
    100% { opacity:1; }
    }
@keyframes textEffectSmooth{
    0% { opacity:0; }
    100% { opacity:1; }
    }
@-webkit-keyframes effectNewsCarouselHeroHome {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30px) rotateX(-45deg);
		        transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0) rotateX(0deg);
		        transform: translateY(0) rotateX(0deg);
	}
}
@keyframes effectNewsCarouselHeroHome {
	from {
		opacity: 0;
		-webkit-transform: translateY(-30px) rotateX(-45deg);
		        transform: translateY(-30px) rotateX(-45deg);
	}
	to {
		opacity: 1;
		-webkit-transform: translateY(0) rotateX(0deg);
		        transform: translateY(0) rotateX(0deg);
	}
}


:root{
	--duration-300ms:300ms;
	--duration-500ms:500ms;
	--duration-800ms:800ms;
	--duration-1000ms:1000ms;
	}

/* ======================================== */
/* .is-loading
/* ======================================== */
body.is-loading::before{
	position:fixed;
	z-index:100000;
	left:0; top:0;
	width:100vw; height:100vh;
	background:rgba(255,255,255,0.8);
	-webkit-animation:fadeIn .3s ease;
	        animation:fadeIn .3s ease;
	content:'';
	}
body.is-loading::after{
	position:fixed;
	z-index:100001;
	left:50%; top:50%;
	width:var(--aox-50px); height:var(--aox-50px);
	margin:calc(var(--aox-25px) * -1) 0 0 calc(var(--aox-25px) * -1);
	border:5px solid #b3b3b3;
	border-top-color:#666;
	border-bottom-color:#666;
	border-radius:var(--aox-50px);
	-webkit-animation:rotationLoop 1s ease infinite;
	        animation:rotationLoop 1s ease infinite;
	content:'';
	}

@media screen and (max-width:767px){
	body.is-loading::after{
		width:var(--aox-40px); height:var(--aox-40px);
		margin: calc(var(--aox-20px) * -1) 0 0 calc(var(--aox-20px) * -1);
		border-width:4px;
		border-radius:var(--aox-40px);
		}
}
/* ======================================== */
/* .hide-for-mobile-app
/* ======================================== */
.hide-for-mobile-app .main_header,
.hide-for-mobile-app .main_header-login,
.hide-for-mobile-app .main_footer,
.hide-for-mobile-app .main_footer-login,
.hide-for-mobile-app .obj_breadcrumb,
.hide-for-mobile-app .section_compte .ctn_dashboard .ctn_col.col_info-perso .ctn_cta{
	display:none !important;
	}
.hide-for-mobile-app .main_container{
	padding-top:0 !important;
	}
.hide-for-mobile-app .section_login{
	padding-top:var(--aox-50px) !important;
	}
/* ======================================== */
/* .main_container
/* ======================================== */
.main_container{
	display:flex;
	flex-flow:row wrap;
	width:100%;
	max-width:var(--aox-grid);
	margin:0 auto;
	margin-top: var(--aox-50px);
	padding-top:0;
	transition:all .3s ease;
	}


@media screen and (max-width:767px){
	.main_container{
		max-width:unset;
		margin-top: 0;
		width: var(--aox-grid);
		}
	.menu-mobile_is-opened{
		background:#f2f2f2;
		}
	.menu-mobile_is-opened .main_container{
		display:none;
		}
}
/* ======================================== */
/* .str_container
/* ======================================== */
.str_container{
	width:100%; max-width:var(--aox-1280px);
	height:100%;
	margin:0 auto;
	}

@media screen and (max-width:767px){
	.str_container{
		max-width:unset;
		/* max-width:calc(var(--aox-410px) + var(--aox-4px)); */
		}
}
/* ======================================== */
/* .main_header
/* ======================================== */
.main_header{
	position:fixed;
	left:0; top:0;
	width:100%;
	z-index:9999;
	transition:-webkit-transform .3s ease;
	transition:transform .3s ease;
	transition:transform .3s ease, -webkit-transform .3s ease;
	}
/**/
.main_header .ctn_nav-caf{
	position:relative;
	z-index:10;
	height:var(--aox-50px);
	background:#f2f2f2;
	}
.main_header .ctn_nav-caf .str_container{
	display:flex;
	flex-wrap:wrap;
	}
.main_header .logo-caf{
	display:flex;
	align-items:center;
	width:var(--aox-130px);
	}
.main_header .logo-caf a{
	width:100%;
	}
.main_header .logo-caf img{
	max-width:var(--aox-130px);
	width:100%;
	}
/**/
.main_header .main_nav-caf ul.lvl_01{
	display:flex;
	padding-left:var(--aox-30px);
	height:100%;
	}
.main_header .main_nav-caf ul.lvl_01 > li > span,
.main_header .main_nav-caf ul.lvl_01 > li > a{
	position:relative;
	display:flex;
	align-items:center;
	height:100%;
	padding:0 var(--aox-15px);
	font-size:var(--aox-fs-10px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	letter-spacing:0.1em;
	}
.main_header .main_nav-caf ul.lvl_01 > li > span::before,
.main_header .main_nav-caf ul.lvl_01 > li > a::before{
	position:absolute;
	left:var(--aox-20px); bottom:0;
	width:calc(100% - var(--aox-40px)); height:var(--aox-5px);
	opacity:0;
	background:#000;
	transition:all .3s ease;
	content:'';
	}
/**/
.main_header .main_nav-caf .sub_nav-caf{
	position:absolute;
	left:0; top:var(--aox-50px);
	width:100%; max-height:0;
	background:#fff;
	transition:all .5s ease;
	overflow:hidden;
	}
.main_header .main_nav-caf .sub_nav-caf .str_container{
	padding:var(--aox-20px) 0;
	}
.main_header .main_nav-caf .sub_nav-caf ul{
	padding:var(--aox-20px) 0;
	width:16.66%;
	}
.main_header .main_nav-caf .sub_nav-caf ul li a{
	display:block;
	padding:var(--aox-10px);
	font-size:var(--aox-fs-15px); line-height:1;
	color:#777;
	}
.main_header .main_nav-caf .sub_nav-caf ul li a:hover{
	text-decoration: underline;
	color:#000;
	}
.main_header .main_nav-caf .sub_nav-caf ul li:first-child a{
	font-size:var(--aox-fs-14px);
	font-weight:700;
	text-transform:uppercase;
	color:#000;
	}
/**/
.main_header .main_nav-caf .sub_nav-caf.var_small{
	position:absolute;
	left: auto; top:var(--aox-50px);
	width: var(--aox-250px);
    max-height:0;
	background:#fff;
	transition:all .5s ease;
	overflow:hidden;
	}
.main_header .main_nav-caf .sub_nav-caf.var_small .str_container{
	padding: var(--aox-40px) var(--aox-20px) var(--aox-40px);
	}
.main_header .main_nav-caf .sub_nav-caf.var_small ul{
	padding:0 0;
	width:100%;
	}
.main_header .main_nav-caf .sub_nav-caf.var_small ul li a{
	display: block;
    padding: var(--aox-10px);
    font-size: var(--aox-fs-15px);
    line-height: 1;
    color: #777;
	}
.main_header .main_nav-caf .sub_nav-caf.var_small ul li:first-child a{
	font-weight: 300;
    text-transform: none;
	}
/* .main_header .main_nav-caf .sub_nav-caf.var_small ul li + li{
	border-top: 1px solid #b2b2b2;
	} */
/**/
.main_header .second_nav-caf{
	margin-left:auto;
	}
.main_header .second_nav-caf ul.lvl_01{
	display:flex;
	height:100%;
	}
.main_header .second_nav-caf ul.lvl_01 > li{
	display:flex;
	align-items:center;
	font-size:var(--aox-fs-11px); line-height:1;
	}
.main_header .second_nav-caf ul.lvl_01 > li > span{
	font-weight: 500;
	text-transform: uppercase;
	}
.main_header .second_nav-caf ul.lvl_01 > li > strong,
.main_header .second_nav-caf ul.lvl_01 > li > span > strong{
	font-weight:700;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte{
	position: relative;
    width: var(--aox-250px);
    padding: 0 var(--aox-37px) 0 0;
    margin: 0 var(--aox-15px) 0 0px;
	justify-content: flex-end;
    transition: all .3s ease;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte:hover{
	cursor: pointer;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte svg{
	position:absolute;
	left:var(--aox-25px); top:50%;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte::before{
	position: absolute;
    right: var(--aox-13px); top: calc(50% - var(--aox-4px));
    width: var(--aox-8px); height: var(--aox-8px);
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
            transform: translateY(-50%) rotate(45deg) scale(1);
    transition: all .3s ease;
    content: '';
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte ul.lvl_02{
	position:absolute;
	left:0; top:var(--aox-50px);
	width:100%; max-height:0;
	padding:0 var(--aox-20px);
	background:#fff;
	overflow:hidden;
	opacity:0;
	transition:all .3s ease;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte ul.lvl_02 li a{
	display: block;
    padding: var(--aox-10px);
    font-size: var(--aox-fs-15px);
    line-height: 1;
    color: #000;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_compte ul.lvl_02 li a:hover{
	text-decoration: underline;
	color:#000;
	}
.main_header .second_nav-caf .li_expert{
	padding:0 var(--aox-15px);
	}
.main_header .second_nav-caf .li_expert strong{
	padding-left:var(--aox-5px);
	}
.main_header .second_nav-caf .li_contact a{
	display:flex;
	align-items:center;
	padding:0 var(--aox-15px);
	height:100%;
	}

/**/

.main_header .second_nav-caf ul.lvl_01 > li.li_expertise{
	position: relative;
    width: var(--aox-92px); height: var(--aox-36px);
	margin-top: var(--aox-7px);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise > .border_expertise{
	position: relative;
	width:100%; height:100%;
    border-radius: var(--aox-19px);
	overflow: hidden;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise > .border_expertise::before{
	position: absolute;
	left: 0; top: 50%;
	content:'';
	width:var(--aox-94px); height:var(--aox-94px);
	margin-top: calc(var(--aox-47px) * -1);
    border-radius: var(--aox-19px);
	background: #0052A2;
	/* background: linear-gradient(90deg, rgba(243,204,99,1) 0%, rgba(159,200,176,1) 33%, rgba(74,139,184,1) 66%, rgba(56,56,56,1) 100%); */
	background-size: 100% 100%;
	-webkit-animation: animatedgradient 8s ease-in-out infinite;
	        animation: animatedgradient 8s ease-in-out infinite;
	}

.main_header .second_nav-caf ul.lvl_01 > li.li_expertise > .border_expertise::before{
	background: linear-gradient(120deg, #D9D9D9 0%, #2570B8 25%, #009BD9 50%, #008BB0 75%, #206170 100%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.is_gold > .border_expertise::before{
	background: linear-gradient(120deg, #FBC900 0%, #D29D30 30%, #A56B13 33%, #F8D984 43%, #F8C13F 60%, #E4AC2B 75%, #F9E3B0 100%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.is_silver > .border_expertise::before{
	background: linear-gradient(120deg, #DDE0E1 0%, #606263 30%, #DEDFE1 33%, #CDD5D8 43%, #969EA2 60%, #939798 75%, #CFCFCB 100%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.is_bronze > .border_expertise::before{
	background: linear-gradient(120deg, #EED7C6 0%, #743C1B 30%, #F3C2A7 33%, #E5B092 43%, #A16C5E 60%, #956E5F 75%, #E19D87 100%);
	}




.main_header .second_nav-caf ul.lvl_01 > li.li_expertise > .border_expertise::after{
	position: absolute;
	left:var(--aox-2px); top:var(--aox-2px);
	content:'';
	width:var(--aox-88px); height:var(--aox-32px);
    border-radius: var(--aox-19px);
	background: rgba(255,255,255,0.8);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_expertise{
	position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: var(--aox-88px);
    height: var(--aox-32px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_visuel{
	position: relative;
	width:var(--aox-28px); height: var(--aox-28px);
	margin: var(--aox-2px) 0 var(--aox-2px) var(--aox-2px);
	border-radius: 100%;
	overflow: hidden;
	background: #2FACC2;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_visuel img{
	width:100%; height: 100%;
	object-fit: cover;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_pourcent {
	position: relative;
    display: flex;
    text-align: right;
    width: calc(100% - var(--aox-30px));
    padding-right: var(--aox-2px);
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_pourcent span{
	display: block;
    font-weight: 700;
    font-size: var(--aox-fs-10px);
    line-height: var(--aox-fs-14px);
    letter-spacing: -0.25px;
	margin-top: var(--aox-2px);
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_pourcent span strong{
	font-size:var(--aox-fs-13px);
	}

.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_badge{
	display:flex;
	justify-content:center;
	align-items:center;
	position:absolute;
	z-index:2;
	left:0; bottom:0;
	width:100%; height:0;
	background:rgba(47,172,194,0.3);
	border-radius:var(--aox-19px);
	opacity:0;
	overflow:hidden;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_badge::before{
	content:'';
	position:absolute;
	z-index:1;
	left:0; bottom:0;
	width:100%; height:0;
	background:rgba(47,172,194,0.3);
	opacity:0;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise .ctn_badge img{
	position:relative;
	z-index:2;
	width:var(--aox-25px); height:var(--aox-25px);
	opacity:0;
	}

@-webkit-keyframes animatedgradient {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	/* 50% {
		transform: rotate(180deg);
	} */
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes animatedgradient {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	/* 50% {
		transform: rotate(180deg);
	} */
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_visuel{
	-webkit-animation:expFlipY1 1.7s ease-out both;
	        animation:expFlipY1 1.7s ease-out both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_visuel img{
	-webkit-animation:expHide1 1.7s ease-out both;
	        animation:expHide1 1.7s ease-out both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge > .border_expertise{
	-webkit-animation:expFlipY2 1.8s ease-out both;
	        animation:expFlipY2 1.8s ease-out both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_pourcent{
	-webkit-animation:expHide2 0.3s ease-out both;
	        animation:expHide2 0.3s ease-out both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_badge{
	-webkit-animation:expFlipY3 1.5s ease-out 1.5s both;
	        animation:expFlipY3 1.5s ease-out 1.5s both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_badge::before{
	-webkit-animation:expFlipY3 1.5s ease-out 1.6s both;
	        animation:expFlipY3 1.5s ease-out 1.6s both;
	}
.main_header .second_nav-caf ul.lvl_01 > li.li_expertise.new_badge .ctn_badge img{
	-webkit-animation:expFlipY4 1.5s ease-out 1.7s both;
	        animation:expFlipY4 1.5s ease-out 1.7s both;
	}

@-webkit-keyframes expHide1{
	0%{opacity:1;}
	60%{opacity:1;}
	65%{opacity:0;}
	100%{opacity:0;}
}

@keyframes expHide1{
	0%{opacity:1;}
	60%{opacity:1;}
	65%{opacity:0;}
	100%{opacity:0;}
}
@-webkit-keyframes expHide2{
	0%{opacity:1;}
	100%{opacity:0;}
}
@keyframes expHide2{
	0%{opacity:1;}
	100%{opacity:0;}
}
@-webkit-keyframes expFlipY1{
	0%{-webkit-transform:scaleY(1) translateY(0);transform:scaleY(1) translateY(0);}
	40%{-webkit-transform:scaleY(1) translateY(calc(var(--aox-5px) * -1));transform:scaleY(1) translateY(calc(var(--aox-5px) * -1));}
	60%{-webkit-transform:scaleY(0) translateY(0);transform:scaleY(0) translateY(0);}
	80%{-webkit-transform:scaleY(1) translateY(0);transform:scaleY(1) translateY(0);}
	100%{-webkit-transform:scaleY(0) translateY(0);transform:scaleY(0) translateY(0);}
}
@keyframes expFlipY1{
	0%{-webkit-transform:scaleY(1) translateY(0);transform:scaleY(1) translateY(0);}
	40%{-webkit-transform:scaleY(1) translateY(calc(var(--aox-5px) * -1));transform:scaleY(1) translateY(calc(var(--aox-5px) * -1));}
	60%{-webkit-transform:scaleY(0) translateY(0);transform:scaleY(0) translateY(0);}
	80%{-webkit-transform:scaleY(1) translateY(0);transform:scaleY(1) translateY(0);}
	100%{-webkit-transform:scaleY(0) translateY(0);transform:scaleY(0) translateY(0);}
}
@-webkit-keyframes expFlipY2{
	0%{-webkit-transform:scaleY(1);transform:scaleY(1);}
	40%{-webkit-transform:scaleY(1);transform:scaleY(1);}
	60%{-webkit-transform:scaleY(0);transform:scaleY(0);}
	80%{-webkit-transform:scaleY(1);transform:scaleY(1);}
	100%{-webkit-transform:scaleY(0);transform:scaleY(0);}
}
@keyframes expFlipY2{
	0%{-webkit-transform:scaleY(1);transform:scaleY(1);}
	40%{-webkit-transform:scaleY(1);transform:scaleY(1);}
	60%{-webkit-transform:scaleY(0);transform:scaleY(0);}
	80%{-webkit-transform:scaleY(1);transform:scaleY(1);}
	100%{-webkit-transform:scaleY(0);transform:scaleY(0);}
}
@-webkit-keyframes expFlipY3{
	0%{-webkit-transform:translateY(0);transform:translateY(0); opacity:0; height:0;}
	20%{-webkit-transform:translateY(0);transform:translateY(0); opacity:1; height:100%;}
	80%{-webkit-transform:translateY(calc(var(--aox-5px) * -1));transform:translateY(calc(var(--aox-5px) * -1)); opacity:1; height:100%;}
	100%{-webkit-transform:translateY(calc(var(--aox-5px)));transform:translateY(calc(var(--aox-5px))); opacity:0; height:0;}
}
@keyframes expFlipY3{
	0%{-webkit-transform:translateY(0);transform:translateY(0); opacity:0; height:0;}
	20%{-webkit-transform:translateY(0);transform:translateY(0); opacity:1; height:100%;}
	80%{-webkit-transform:translateY(calc(var(--aox-5px) * -1));transform:translateY(calc(var(--aox-5px) * -1)); opacity:1; height:100%;}
	100%{-webkit-transform:translateY(calc(var(--aox-5px)));transform:translateY(calc(var(--aox-5px))); opacity:0; height:0;}
}
@-webkit-keyframes expFlipY4{
	0%{-webkit-transform:scaleY(0) translateY(0);transform:scaleY(0) translateY(0); opacity:0;}
	20%{-webkit-transform:scaleY(1) translateY(0);transform:scaleY(1) translateY(0); opacity:1;}
	80%{-webkit-transform:scaleY(1) translateY(calc(var(--aox-5px) * -1));transform:scaleY(1) translateY(calc(var(--aox-5px) * -1)); opacity:1;}
	100%{-webkit-transform:scaleY(0) translateY(calc(var(--aox-5px)));transform:scaleY(0) translateY(calc(var(--aox-5px))); opacity:0;}
}
@keyframes expFlipY4{
	0%{-webkit-transform:scaleY(0) translateY(0);transform:scaleY(0) translateY(0); opacity:0;}
	20%{-webkit-transform:scaleY(1) translateY(0);transform:scaleY(1) translateY(0); opacity:1;}
	80%{-webkit-transform:scaleY(1) translateY(calc(var(--aox-5px) * -1));transform:scaleY(1) translateY(calc(var(--aox-5px) * -1)); opacity:1;}
	100%{-webkit-transform:scaleY(0) translateY(calc(var(--aox-5px)));transform:scaleY(0) translateY(calc(var(--aox-5px))); opacity:0;}
}

/**/

.main_header .ctn_nav-marque{
	display:none;
	position:relative;
	z-index:1;
	height:var(--aox-115px);
	padding-top:var(--aox-45px);
	background:#fff;
	transition:all .3s ease;
	}
.main_header.mode_mini .ctn_nav-marque{
	height:var(--aox-60px);
	padding-top:0;
	}
.main_header.mode_mini .ctn_nav-marque .logo-marque{
	display:flex;
	align-items:center;
	}
.main_header .ctn_nav-marque .str_container{
	display:flex;
	border-bottom:1px solid #d7d7d7;
	}
.main_header .ctn_nav-marque .logo-marque img{
	width:100%; height:auto;
	transition:all .3s ease;
	}
/**/
.var_lrp .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-175px);
	}
.var_vichy .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-125px);
	}
.var_sanoflore .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-125px);
	}
.var_cerave .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-140px);
	}
.var_skinc .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-220px);
	}
.var_biotherm .main_header .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-125px);
	}
.var_sciencemd .main_header .ctn_nav-marque .logo-marque img{
	max-width: var(--aox-165px);
    margin-top: var(--aox-15px);
	}
.var_derm-expertise .main_header .ctn_nav-marque .logo-marque img{
	max-width: var(--aox-170px);
	}

.var_lrp .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-130px);
	}
.var_vichy .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-95px);
	}
.var_sanoflore .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-95px);
	}
.var_cerave .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-100px);
	}
.var_skinc .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-165px);
	}
.var_biotherm .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-95px);
	}
.var_sciencemd .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-120px);
	margin-top: 0; 
	}
.var_derm-expertise .main_header.mode_mini .ctn_nav-marque .logo-marque img{
	max-width:var(--aox-140px); 
	}


/**/
.main_header .main_nav-marque ul.lvl_01{
	display:flex;
	padding-left:var(--aox-25px);
	height:100%;
	}
.main_header .main_nav-marque ul.lvl_01 > li > a{
	position:relative;
	display:flex;
	align-items:center;
	height:100%;
	padding:0 var(--aox-15px);
	font-size:var(--aox-fs-13px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	letter-spacing:0.1em;
	}
.main_header .main_nav-marque ul.lvl_01 > li > a::before{
	position:absolute;
	left:var(--aox-15px); bottom:-1px;
	width:calc(100% - var(--aox-30px)); height:5px;
	opacity:0;
	transition:all .3s ease;
	content:'';
	}

@media screen and (min-width:768px){
	.main_header.mode_mini{
		-webkit-transform:translateY(calc(-1 * var(--aox-50px)));
		        transform:translateY(calc(-1 * var(--aox-50px)));
		}
	.main_header .main_nav-caf li:hover .sub_nav-caf{
		max-height:90vh;
		overflow-y:auto;
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte:hover{
		background:#fff;
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte:hover ul.lvl_02{
		opacity:1;
		max-height:var(--aox-270px);
		padding: var(--aox-40px) var(--aox-20px) var(--aox-40px);
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte:hover::before{
		-webkit-transform:translateY(-10%) rotate(45deg) scale(-1);
		        transform:translateY(-10%) rotate(45deg) scale(-1);
		}
	.main_header .main_nav-marque ul.lvl_01 > li:hover > a::before,
	.main_header .main_nav-marque ul.lvl_01 > li > a.active::before{
		opacity:1;
		}
	.main_header .main_nav-caf ul.lvl_01 > li:hover > span::before,
	.main_header .main_nav-caf ul.lvl_01 > li:hover > a::before{
		opacity:1;
		}
	.main_header .bt_burger,
	.main_header .ctn_nav-mobile,
	.main_header .obj_avatar-expertise{
		display:none;
		}
}
@media screen and (min-width:768px) and (max-width:1280px){
	.main_header .main_nav-marque ul.lvl_01 > li > a::before,
	.main_header .main_nav-caf ul.lvl_01 > li > span::before,
	.main_header .main_nav-caf ul.lvl_01 > li > a::before{
		left:1.2vw;
		width:calc(100% - 2.4vw);
		}
}
@media screen and (max-width:1280px){
	.main_header .ctn_nav-marque{
		padding-top:0;
		height:var(--aox-60px);
		}
	.main_header .ctn_nav-marque .logo-marque{
		display:flex;
		align-items:center;
		width:15vw;
		padding-left:1.5vw;
		}
	.main_header .main_nav-caf ul.lvl_01,
	.main_header .main_nav-marque ul.lvl_01{
		padding-left:1.5vw;
		}
	.main_header .main_nav-caf ul.lvl_01 > li > span,
	.main_header .main_nav-caf ul.lvl_01 > li > a,
	.main_header .main_nav-marque ul.lvl_01 > li > a{
		padding:0 1.2vw;
		}
	.main_header .main_nav-caf ul.lvl_01 > li > span,
	.main_header .main_nav-caf ul.lvl_01 > li > a{
		font-size:0.75vw;
		}
	.main_header .main_nav-marque ul.lvl_01 > li > a{
		font-size:0.9vw;
		}
	.main_header .second_nav-caf{
		/* width: 30vw; */
		/* padding-right: 1.5vw; */
		}
	.main_header .second_nav-caf ul.lvl_01 {
		justify-content: flex-end;
		}
	.main_header .second_nav-caf ul.lvl_01 > li{
		font-size:0.85vw;
		}
	.main_header .second_nav-caf .li_expert,
	.main_header .second_nav-caf .li_contact a{
		padding:0 1.2vw;
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte{
		width:18vw;
		}
	.main_header .second_nav-caf ul.lvl_01 > li.li_compte ul.lvl_02 li a{
		font-size:1vw;
		}
	.main_header .main_nav-caf .sub_nav-caf .str_container{
		padding:var(--aox-20px);
		}
	.main_header .main_nav-caf .sub_nav-caf ul li a{
		font-size:1.1vw;
		}
	.main_header .main_nav-caf .sub_nav-caf ul li a strong{
		font-size:1vw;
		}
}
@media screen and (max-width:768px){
	.main_header .main_nav-caf .sub_nav-caf ul li:first-child a{
		font-size:var(--aox-fs-10px)
		}
}
@media screen and (max-width:767px){
	.main_header{
		position: relative;
		}
	.main_header .ctn_nav-caf{
		background:none;
		height: var(--aox-70px);
		}
	.main_header .ctn_nav-caf .str_container{
		position:relative;
		background:#f2f2f2;
		}
	.menu-mobile_is-opened .main_header{
		position:relative;
		left:auto; top:auto;
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		margin:0 auto;
		overflow:hidden;
		}
	.menu-mobile_is-opened .main_header .ctn_nav-caf{
		position:fixed;
		left:0; top:0;
		width:100%;
		}
	.main_header .ctn_nav-marque .logo-marque{
		width:100vw;
		padding-left:0;
		justify-content:center;
		}
	.menu-mobile_is-opened .main_header .ctn_nav-marque{
		display:none;
		}
	.main_header .main_nav-marque,
	.main_header .main_nav-caf,
	.main_header .second_nav-caf{
		display:none;
		}
	/**/
	.main_header .bt_burger{
		position: absolute;
		left: var(--aox-20px); top: 50%;
		width: 40px; height: 40px;
		padding: 12px 10px;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		z-index: 3;
		}
	.main_header .bt_burger span{
		position:relative;
		display: block;
		width:20px; height:2px;
		background:#000;
		transition-timing-function: cubic-bezier(.55,.055,.675,.19);
		transition-duration: .22s;
		}
	.main_header .bt_burger span::before,
	.main_header .bt_burger span::after{
		position:absolute;
		width:20px; height:2px;
		left: 0;
		background:#000;
		content:'';
		border-radius: 4px;
		/* transition:all .3s ease; */
		}
	.main_header .bt_burger span::before{
		top: -7px;
		transition: top .1s ease-in .25s,opacity .1s ease-in;
		}
	.main_header .bt_burger span::after{
		bottom: -7px;
		transition: bottom .1s ease-in .25s,-webkit-transform .22s cubic-bezier(.55,.055,.675,.19);
		transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
		transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19),-webkit-transform .22s cubic-bezier(.55,.055,.675,.19);
		}
	.menu-mobile_is-opened .main_header .bt_burger span{
		transition-delay: .12s;
    	transition-timing-function: cubic-bezier(.215,.61,.355,1);
    	-webkit-transform: rotate(225deg);
    	        transform: rotate(225deg);
		}
	.menu-mobile_is-opened .main_header .bt_burger span::before{
		top: 0;
    	transition: top .1s ease-out,opacity .1s ease-out .12s;
    	opacity: 0;
		}
	.menu-mobile_is-opened .main_header .bt_burger span::after{
		bottom: 0;
    	transition: bottom .1s ease-out,-webkit-transform .22s cubic-bezier(.215,.61,.355,1) .12s;
    	transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s;
    	transition: bottom .1s ease-out,transform .22s cubic-bezier(.215,.61,.355,1) .12s,-webkit-transform .22s cubic-bezier(.215,.61,.355,1) .12s;
    	-webkit-transform: rotate(-90deg);
    	        transform: rotate(-90deg);
		}
	/**/
	.main_header .logo-caf{
		width:var(--aox-150px);
		margin:0 auto;
		}
	.menu-mobile_is-opened .main_header .logo-caf{
		display:none;
		}
	.main_header .logo-caf img{
		max-width:var(--aox-150px);
		width:100%;
		}
	/**/
	.var_lrp .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-130px);
		}
	.var_vichy .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-95px);
		}
	.var_sanoflore .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-95px);
		}
	.var_cerave .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-100px);
		}
	.var_skinc .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-165px);
		}
	.var_biotherm .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-95px);
		}
	.var_sciencemd .main_header .ctn_nav-marque .logo-marque img{
		max-width:var(--aox-120px);
		margin-top: 0;
		}
	/**/
	.main_header .ctn_nav-mobile{
		display:none;
		padding-top:var(--aox-85px);
		}
	.main_header .ctn_nav-mobile .ctn_panels{
		display:flex;
		width:10000vw;
		transition:-webkit-transform .5s ease;
		transition:transform .5s ease;
		transition:transform .5s ease, -webkit-transform .5s ease;
		}
	.main_header .ctn_nav-mobile .ctn_panel{
		width:100vw;
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.menu-mobile_is-opened .main_header .ctn_nav-mobile{
		display:block;
		}
	.main_header .ctn_nav-mobile .bt_title{
		position:fixed;
		z-index:10;
		left:50%; top:var(--aox-50px);
		width:100%; height:var(--aox-35px);
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		padding:0 var(--aox-50px) var(--aox-20px) var(--aox-50px);
		font-size:var(--aox-fs-16px); line-height:1;
		font-weight:700;
		text-align:left;
		text-transform:uppercase;
		/* background:#f2f2f2; */
		-webkit-transform:translateX(-50%);
		        transform:translateX(-50%);
		}
	.main_header .ctn_nav-mobile .bt_title::before{
		position:absolute;
		left:var(--aox-20px); top:2px;
		width:8px; height:8px;
		border-bottom:2px solid #000;
		border-left:2px solid #000;
		-webkit-transform:rotate(45deg);
		        transform:rotate(45deg);
		transition:opacity .3s ease;
		content:'';
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile{
		background:#fff;
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li{
		position:relative;
		border-bottom:1px solid #c3c3c3;
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li a,
	.main_header .ctn_nav-mobile ul.main_nav-mobile li button{
		display:block;
		padding:var(--aox-25px) var(--aox-50px);
		width:100%;
		font-size:var(--aox-fs-12px); line-height:1;
		font-weight:700;
		text-transform:uppercase;
		text-align:left;
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li button::before{
		position:absolute;
		right:var(--aox-30px); top:50%;
		width:8px; height:8px;
		border-top:2px solid #000;
		border-right:2px solid #000;
		-webkit-transform:translateY(-50%) rotate(45deg);
		        transform:translateY(-50%) rotate(45deg);
		content:'';
		}
	.main_header .ctn_nav-mobile ul.second_nav-mobile{
		padding:var(--aox-20px) 0;
		background:#fff;
		}
	.main_header .ctn_nav-mobile ul.second_nav-mobile li a{
		display:block;
		padding:var(--aox-10px) var(--aox-50px);
		font-size:var(--aox-fs-11px); line-height:1;
		font-weight:700;
		text-transform:uppercase;
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li.li_compte button,
	.main_header .ctn_nav-mobile ul.main_nav-mobile li.li_contact a{
		padding-left:var(--aox-85px);
		}
	.main_header .ctn_nav-mobile ul.main_nav-mobile li.li_compte svg,
	.main_header .ctn_nav-mobile ul.main_nav-mobile li.li_contact svg{
		position:absolute;
		left:var(--aox-50px); top:50%;
		-webkit-transform:translateY(-50%);
		        transform:translateY(-50%);
		}
	/**/
	.main_header .ctn_nav-mobile[data-level="1"] .ctn_panels{
		-webkit-transform:translateX(0);
		        transform:translateX(0);
		}
	.main_header .ctn_nav-mobile[data-level="1"] .bt_title::before{
		opacity:0;
		}
	.main_header .ctn_nav-mobile[data-level="2"] .ctn_panels{
		-webkit-transform:translateX(calc(-1 * var(--aox-410px) + var(--aox-4px)));
		        transform:translateX(calc(-1 * var(--aox-410px) + var(--aox-4px)));
		}
	.main_header .ctn_nav-mobile[data-level="3"] .ctn_panels{
		-webkit-transform:translateX(calc(-1 * var(--aox-820px) + var(--aox-8px)));
		        transform:translateX(calc(-1 * var(--aox-820px) + var(--aox-8px)));
		}
	
	/**/

	.main_header .obj_avatar-expertise{
		position: absolute;
		right: var(--aox-17px); top: 50%;
		width: 36px; height: 36px;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		}
	.main_header .obj_avatar-expertise .border_expertise{
		position:relative;
		width:100%; height:100%;
		border-radius:100vw;
		overflow:hidden;
		}
	.main_header .obj_avatar-expertise .border_expertise::before{
		content:'';
		position:absolute;
		left:0; top: 50%;
		width:36px; height:36px;
		margin-top:-18px;
		border-radius:100vw;
		background-size:100% 100%;
		-webkit-animation:animatedgradient 8s ease-in-out infinite;
		        animation:animatedgradient 8s ease-in-out infinite;
		}
	.main_header .obj_avatar-expertise .border_expertise::before{
		background: linear-gradient(115deg, #D9D9D9 0%, #2570B8 25%, #009BD9 50%, #008BB0 75%, #206170 100%);
		}
	.main_header .obj_avatar-expertise.is_gold .border_expertise::before{
		background: linear-gradient(120deg, #FBC900 0%, #D29D30 30%, #A56B13 33%, #F8D984 43%, #F8C13F 60%, #E4AC2B 75%, #F9E3B0 100%);
		}
	.main_header .obj_avatar-expertise.is_silver .border_expertise::before{
		background: linear-gradient(120deg, #DDE0E1 0%, #606263 30%, #DEDFE1 33%, #CDD5D8 43%, #969EA2 60%, #939798 75%, #CFCFCB 100%);
		}
	.main_header .obj_avatar-expertise.is_bronze .border_expertise::before{
		background: linear-gradient(120deg, #EED7C6 0%, #743C1B 30%, #F3C2A7 33%, #E5B092 43%, #A16C5E 60%, #956E5F 75%, #E19D87 100%);
		}


	.main_header .obj_avatar-expertise .border_expertise::after{
		position: absolute;
		left: 50%; top: 50%;
		content: '';
		width: 32px; height: 32px;
		border-radius: 100vw;
		-webkit-transform: translate(-50%,-50%);
		        transform: translate(-50%,-50%);
		background: rgba(255,255,255,0.8);
		}
	.main_header .obj_avatar-expertise .ctn_expertise{
		position: absolute;
		padding: 2px;
		z-index: 1;
		left: 50%; top: 50%;
		border-radius: 100vw;
		width: 32px; height: 32px;
		-webkit-transform: translate(-50%,-50%);
		        transform: translate(-50%,-50%);
		}
	.main_header .obj_avatar-expertise .ctn_visuel{
		position:relative;
		width: 28px; height: 28px;
		border-radius:100%;
		overflow:hidden;
		}
	.main_header .obj_avatar-expertise .ctn_visuel img{
		width:100%; height:100%;
		}
	.main_header .obj_avatar-expertise .ctn_badge{
		display:flex;
		justify-content:center;
		align-items:center;
		position:absolute;
		z-index:2;
		left:0; bottom:0;
		width:100%; height:0;
		background:rgba(47,172,194,0.3);
		border-radius:var(--aox-19px);
		opacity:0;
		overflow:hidden;
		}
	.main_header .obj_avatar-expertise .ctn_badge::before{
		content:'';
		position:absolute;
		z-index:1;
		left:0; bottom:0;
		width:100%; height:0;
		background:rgba(47,172,194,0.3);
		opacity:0;
		}
	.main_header .obj_avatar-expertise .ctn_badge img{
		position:relative;
		z-index:2;
		width:var(--aox-28px); height:var(--aox-28px);
		opacity:0;
		}
	
	.main_header .obj_avatar-expertise.new_badge .ctn_visuel{
		-webkit-animation:expFlipY1 1.7s ease-out both;
		        animation:expFlipY1 1.7s ease-out both;
		}
	.main_header .obj_avatar-expertise.new_badge .ctn_visuel img{
		-webkit-animation:expHide1 1.7s ease-out both;
		        animation:expHide1 1.7s ease-out both;
		}
	.main_header .obj_avatar-expertise.new_badge .border_expertise{
		-webkit-animation:expFlipY2 1.8s ease-out both;
		        animation:expFlipY2 1.8s ease-out both;
		}
	.main_header .obj_avatar-expertise.new_badge .ctn_badge{
		-webkit-animation:expFlipY3 1.5s ease-out 1.5s both;
		        animation:expFlipY3 1.5s ease-out 1.5s both;
		}
	.main_header .obj_avatar-expertise.new_badge .ctn_badge::before{
		-webkit-animation:expFlipY3 1.5s ease-out 1.6s both;
		        animation:expFlipY3 1.5s ease-out 1.6s both;
		}
	.main_header .obj_avatar-expertise.new_badge .ctn_badge img{
		-webkit-animation:expFlipY4 1.5s ease-out 1.7s both;
		        animation:expFlipY4 1.5s ease-out 1.7s both;
		}


}
@media screen and (max-width:414px){
	.main_header .ctn_nav-mobile[data-level="2"] .ctn_panels{
		-webkit-transform:translateX(-100vw);
		        transform:translateX(-100vw);
		}
	.main_header .ctn_nav-mobile[data-level="3"] .ctn_panels{
		-webkit-transform:translateX(-200vw);
		        transform:translateX(-200vw);
		}
}
/* ======================================== */
/* .main_header-login
/* ======================================== */
.main_header-login{
	position:fixed;
	left:0; top:0;
	width:100%; height:var(--aox-80px);
	z-index:9999;
	background:#f2f2f2;
	}
.main_header-login .str_container{
	display:flex;
	}
.main_header-login .logo-caf{
	display:flex;
	align-items:center;
	padding-left:var(--aox-20px);
	}
.main_header-login .logo-caf img{
	width:var(--aox-200px);
	}
/**/
.main_header-login .second_nav-caf{
	margin-left:auto;
	}
.main_header-login .second_nav-caf ul.lvl_01{
	display:flex;
	height:100%;
	}
.main_header-login .second_nav-caf .li_contact a{
	display:flex;
	align-items:center;
	padding:0 var(--aox-20px);
	height:100%;
	}

@media screen and (max-width:767px){
	.main_header-login{
		height:var(--aox-60px);
		}
	.main_header-login .logo-caf img{
		width:var(--aox-160px);
		}
}
/* ======================================== */
/* .main_footer
/* ======================================== */
.main_footer{
	padding:var(--aox-90px) 0;
	background:#f2f2f2;
	}
.main_footer ul li{
	font-size:var(--aox-fs-15px); line-height:1;
	}
.main_footer ul li a{
	display:inline-block;
	padding:var(--aox-10px) var(--aox-20px) var(--aox-10px) 0;
	color:#777;
	}
.main_footer ul li a:hover{
	text-decoration: underline;
	color:#000;
	}
.main_footer ul li.var_bold a,
.main_footer ul li strong{
	display:flex;
	align-items:center;
	padding:var(--aox-20px) var(--aox-20px) var(--aox-20px) 0;
	font-weight:700;
	letter-spacing:0.1em;
	text-transform:uppercase;
	color:#000;
	}
.main_footer ul li.var_bold a .i_facebook-circle{
	margin-left:var(--aox-10px);
	width:var(--aox-24px); height:var(--aox-24px);
	}
.main_footer .ctn_top{
	display:flex;
	flex-flow:row wrap;
	padding-bottom:var(--aox-120px);
	border-bottom:1px solid #c3c3c3;
	}
.main_footer .ctn_top .logo-caf{
	width:var(--aox-260px);
	}
.main_footer .ctn_top .logo-caf img{
	width:100%;
	}
.main_footer .ctn_top .main_nav-footer{
	display: flex;
    padding-left: var(--aox-70px);
    width: var(--aox-950px);
	}
.main_footer .ctn_top .main_nav-footer ul{
	width:33.333%;
	}
.main_footer .ctn_bottom{
	padding-top:var(--aox-60px);
	}
.main_footer .ctn_bottom .second_nav-footer{
	margin-bottom:var(--aox-80px);
	}
.main_footer .ctn_bottom .second_nav-footer ul{
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	width:90%;
	margin:0 auto;
	}
.main_footer .ctn_bottom .second_nav-footer ul li a,
.main_footer .ctn_bottom .second_nav-footer ul li button{
	padding:var(--aox-20px);
	text-align:center;
	}
.main_footer .t_mentions{
	font-size:var(--aox-fs-15px); line-height:1;
	text-align:center;
	color:#777;
	}
.main_footer .optanon-show-settings-popup-wrapper{
	padding:var(--aox-10px) var(--aox-20px);
	}
.main_footer .optanon-show-settings-popup-wrapper .optanon-show-settings-button{
	display:flex;
	justify-content:center;
	}

.main_footer .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.ot-sdk-show-settings,
.main_footer .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.optanon-show-settings{
    display:block;
	width:100%;
    padding:var(--aox-20px);
    font-size:var(--aox-fs-15px); line-height:1;
    font-weight:700;
    letter-spacing:.1em;
    text-transform:uppercase;
    text-align:center;
    color:#000;
    border:none;
	}
.main_footer .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.ot-sdk-show-settings:hover,
.main_footer .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.optanon-show-settings:hover{
    background:transparent;
	}

@media screen and (max-width:1280px){
	.main_footer .ctn_top .logo-caf{
		padding-left:var(--aox-20px);
		}
}
@media screen and (max-width:767px){
	.main_footer{
		padding:var(--aox-60px) var(--aox-20px);
		text-align:center;
		}
	.menu-mobile_is-opened .main_footer{
		display:none;
		}
	.main_footer .ctn_top .logo-caf{
		display:flex;
		justify-content:center;
		width:100%;
		padding:0 var(--aox-20px) var(--aox-30px) var(--aox-20px);
		}
	.main_footer .ctn_top .logo-caf img{
		width:var(--aox-250px);
		}
	.main_footer ul li a{
		padding:var(--aox-10px) 0;
		}
	.main_footer ul li.var_bold a,
	.main_footer ul li strong{
		justify-content:center;
		padding-right:0;
		}
	.main_footer .ctn_top{
		padding-bottom:var(--aox-30px);
		}
	.main_footer .ctn_top .main_nav-footer{
		flex-flow:row wrap;
		width:100%;
		padding:0;
		}
	.main_footer .ctn_top .main_nav-footer ul{
		width:100%;
		}
	.main_footer .ctn_bottom{
		padding-top:var(--aox-20px);
		}
	.main_footer .ctn_bottom .second_nav-footer{
		margin-bottom:var(--aox-50px);
		}
	.main_footer .ctn_bottom .second_nav-footer ul{
		max-width:unset;
		width:100%;
		}
	.main_footer .ctn_bottom .second_nav-footer ul li{
		width:100%;
		}
	.main_footer .optanon-show-settings-popup-wrapper{
		padding:var(--aox-15px) 0;
		}
}
/* ======================================== */
/* .main_footer-login
/* ======================================== */
.main_footer-login{
	padding:var(--aox-90px) 0;
	background:#f2f2f2;
	}
.main_footer-login ul li{
	font-size:var(--aox-fs-15px); line-height:1;
	}
.main_footer-login ul li a{
	display:inline-block;
	padding:var(--aox-10px) var(--aox-20px) var(--aox-10px) 0;
	color:#777;
	}
.main_footer-login ul li.var_bold a,
.main_footer-login ul li strong{
	display:block;
	padding:var(--aox-20px) var(--aox-20px) var(--aox-20px) 0;
	font-weight:700;
	letter-spacing:0.1em;
	text-transform:uppercase;
	color:#000;
	}
.main_footer-login .ctn_bottom .second_nav-footer{
	margin-bottom:var(--aox-80px);
	}
.main_footer-login .ctn_bottom .second_nav-footer ul{
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	width:90%;
	margin:0 auto;
	}
.main_footer-login .ctn_bottom .second_nav-footer ul li a,
.main_footer-login .ctn_bottom .second_nav-footer ul li button{
	padding:var(--aox-20px);
	text-align:center;
	}
.main_footer-login .t_mentions{
	font-size:var(--aox-fs-15px); line-height:1;
	text-align:center;
	color:#777;
	}
.main_footer-login .optanon-show-settings-popup-wrapper{
	padding:var(--aox-10px) var(--aox-20px);
	}
.main_footer-login .optanon-show-settings-popup-wrapper .optanon-show-settings-button{
	display:flex;
	justify-content:center;
	}
.main_footer-login .t_mentions{
	font-size:var(--aox-fs-15px); line-height:1;
	text-align:center;
	color:#777;
	}

.main_footer-login .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.ot-sdk-show-settings,
.main_footer-login .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.optanon-show-settings{
    display:block;
	width:100%;
    padding:var(--aox-20px);
    font-size:var(--aox-fs-15px); line-height:1;
    font-weight:700;
    letter-spacing:.1em;
    text-transform:uppercase;
    text-align:center;
    color:#000;
    border:none;
	}
.main_footer-login .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.ot-sdk-show-settings:hover,
.main_footer-login .ctn_bottom .second_nav-footer ul li #ot-sdk-btn.optanon-show-settings:hover{
    background:transparent;
	}

@media screen and (max-width:767px){
	.main_footer-login{
		padding:var(--aox-60px) var(--aox-20px);
		text-align:center;
		}
	.main_footer-login ul li a{
		padding:var(--aox-10px) 0;
		}
	.main_footer-login ul li.var_bold a,
	.main_footer-login ul li strong{
		padding-right:0;
		}
	.main_footer-login .ctn_bottom .second_nav-footer{
		margin-bottom:var(--aox-50px);
		}
	.main_footer-login .ctn_bottom .second_nav-footer ul{
		max-width:unset;
		width:100%;
		}
	.main_footer-login .ctn_bottom .second_nav-footer ul li{
		width:100%;
		}
	.main_footer-login .optanon-show-settings-popup-wrapper{
		padding:var(--aox-15px) 0;
		}
}
/* ======================================== */
/* .section_login
/* ======================================== */
.section_login{
	width:100%;
	padding-top:var(--aox-10px);
	}
.section_login header .logo-caf{
	width:var(--aox-390px);
	margin:0 auto;
	}
.section_login header h1{
	padding:var(--aox-55px) 22% var(--aox-70px) 22%;
	font-size:var(--aox-fs-38px); line-height:1.2;
	text-align:center;
	}
.section_login .ctn_login{
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-40px) var(--aox-10px);
	background:#f2f2f2;
	}
.section_login .ctn_login .ctn_col{
	width:50%;
	padding:0 6.3%;
	}
.section_login .ctn_login .ctn_col.col_right{
	border-left:1px solid #cbcbcb;
	}
.section_login .ctn_login .ctn_col.col_100{
	width:100%;
	}
.section_login .ctn_login .ctn_col.col_100 .ctn_input{
	max-width:var(--aox-470px);
	}
.section_login .ctn_login h2{
	padding:var(--aox-15px) 0 var(--aox-40px) 0;
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_login .ctn_logos{
	display: flex;
    justify-content: center;
	}
.section_login .ctn_logos li{
	position:relative;
	display:flex;
	align-items:center;
	flex-grow:1;
	padding:var(--aox-70px) 0;
	}
.section_login .ctn_logos li img{
	position:absolute;
	left:50%; top:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_login .ctn_logos li.var_lrp img{
	max-width:var(--aox-120px);
	}
.section_login .ctn_logos li.var_vichy img{
	max-width:var(--aox-85px);
	}
.section_login .ctn_logos li.var_sanoflore img{
	max-width:var(--aox-90px);
	}
.section_login .ctn_logos li.var_cerave img{
	max-width:var(--aox-90px);
	}
.section_login .ctn_logos li.var_skinc img{
	max-width:var(--aox-120px);
	}
.section_login .ctn_logos li.var_biotherm img{
	max-width:var(--aox-95px);
	}
.section_login .ctn_logos li.var_sgmb img{
	max-width:var(--aox-85px);
	}
.section_login .ctn_logos li.var_sciencemd img{
	max-width:var(--aox-100px);
	}
.section_login .ctn_logos li.var_derm-expertise img{
	max-width:var(--aox-120px);
	}

@media screen and (max-width:1280px){
	.section_login .ctn_login{
		padding:var(--aox-40px) 0;
		}
	.section_login header .logo-caf{
		width:calc(var(--aox-280px) + (350 - 280) * ((100vw - calc(var(--aox-760px) + var(--aox-7px))) / (1280 - 767)));
		}
	.section_login header .logo-caf img{
		width:100%;
		}
	.section_login header h1{
		font-size:calc(var(--aox-32px) + (39 - 32) * ((100vw - calc(var(--aox-760px) + var(--aox-7px))) / (1280 - 767)));
		}
	.section_login .ctn_logos li{
		padding:7vw 0;
		}
}
@media screen and (max-width:1024px){
	.section_login .ctn_logos li img{
		-webkit-transform:translate(-50%,-50%) scale(0.8);
		        transform:translate(-50%,-50%) scale(0.8);
		}
}
@media screen and (max-width:768px){
	.section_login .ctn_logos li img{
		-webkit-transform:translate(-50%,-50%) scale(0.6);
		        transform:translate(-50%,-50%) scale(0.6);
		}
}
@media screen and (max-width:767px){
	.section_login header .logo-caf{
		width:var(--aox-160px);
		}
	.section_login header h1{
		padding:var(--aox-30px) var(--aox-20px);
		font-size:var(--aox-fs-20px);
		}
	.section_login .ctn_login{
		margin:0 var(--aox-15px);
		padding:0 var(--aox-30px) var(--aox-40px) var(--aox-30px);
		}
	.section_login .ctn_login .ctn_col{
		width:100%;
		padding:0;
		}
	.section_login .ctn_login .ctn_col.col_left{
		padding-bottom:var(--aox-30px);
		}
	.section_login .ctn_login .ctn_col.col_right{
		border-top:1px solid #cbcbcb;
		border-left:none;
		}
	.section_login .ctn_login h2{
		padding:var(--aox-30px) 0;
		font-size:var(--aox-fs-17px);
		}
	.section_login .ctn_logos{
		flex-flow:row wrap;
		justify-content:center;
		padding:var(--aox-15px) 0;
		}
	.section_login .ctn_logos li{
		flex-flow:row wrap;
		flex-grow:unset;
		width:33.333%;
		padding:var(--aox-30px) 0;
		}
}
/* ======================================== */
/* .section_brand-selection
/* ======================================== */
.section_brand-selection{
	position:relative;
	width:100%;
	}
.section_brand-selection .str_container{
	border-bottom:1px solid #d4d4d4;
	}
.section_brand-selection ul{
	position:absolute;
	left:0; top:0;
	display:flex;
	}
.section_brand-selection ul li{
	flex-grow:1;
	}
.section_brand-selection ul li a{
	display:block;
	}
.section_brand-selection figure{
	position:relative;
	overflow:hidden;
	height:0;
	padding-bottom:164%;
	}
.section_brand-selection figure img{
	position:absolute;
	left:50%; top:50%;
	width:101%;
	-webkit-transform:translate(-50%,-50%) scale(1);
	        transform:translate(-50%,-50%) scale(1);
	transition:all .3s ease;
	}
.section_brand-selection figure .lazyload-placeholder{
	height:28vw;
	}
.section_brand-selection .ctn_logo{
	position:relative;
	display:flex;
	justify-content:center;
	align-items:center;
	height:7.7vw;
	overflow:hidden;
	}
.section_brand-selection .ctn_logo img{
	position:absolute;
	left:50%; top:50%;
	-webkit-transform:translate(-50%,-50%) scale(0.4);
	        transform:translate(-50%,-50%) scale(0.4);
	}
.section_brand-selection .ctn_cta{
	position:absolute;
	left:0; bottom:0;
	width:100%;
	padding-bottom:2.5vw;
	text-align:center;
	}

.section_brand-selection .ctn_cta-others{
	position:relative;
	display:flex;
	justify-content:center;
	align-items:center;
	height:7.7vw;
	overflow:hidden;
	}
.section_brand-selection .ctn_cta-others div{
	position:absolute;
	left:50%; top:50%;
	padding-top:2.2vw;
	font-size:0.65vw; line-height:1;
	text-align:center;
	text-transform:uppercase;
	font-weight:700;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_brand-selection .ctn_cta-others span{
	position:absolute;
	left:50%; top:0;
	width:1.7vw; height:1.7vw;
	border:1px solid #000;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	border-radius:100vw;
	}
.section_brand-selection .ctn_cta-others span::before,
.section_brand-selection .ctn_cta-others span::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#000;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_brand-selection .ctn_cta-others span::before{
	width:0.1vw; height:0.75vw;
	}
.section_brand-selection .ctn_cta-others span::after{
	width:0.75vw; height:0.1vw;
	}

/***/
.section_brand-selection .var_others{
	max-width: calc(100% / 8);
	}
.section_brand-selection .var_others .brands_carousel_container{
	position: relative;
	overflow: hidden;
	}
.section_brand-selection .var_others .brands_carousel_container .obj_brand-carousel .slick-arrow{
	display: none !important;
	}

/***/
.section_brand-selection .var_derm-expertise .ctn_cta-others{
	display: none;
	}



@media screen and (min-width:1025px){
	.section_brand-selection ul li a:hover{
		background:rgba(0,0,0,0.05);
		}
	.section_brand-selection ul li a:hover figure img{
		-webkit-transform:translate(-50%,-50%) scale(1.05);
		        transform:translate(-50%,-50%) scale(1.05);
		}
}
@media screen and (max-width:1440px){
	.section_brand-selection .ctn_logo img{
		-webkit-transform:translate(-50%,-50%) scale(0.35);
		        transform:translate(-50%,-50%) scale(0.35);
		}
}
@media screen and (max-width:1280px){
	.section_brand-selection ul{
		position:relative;
		left:auto; top:auto;
		}
	.section_brand-selection ul li{
		max-width:33.333%;
		}
	.section_brand-selection .ctn_logo img{
		-webkit-transform:translate(-50%,-50%) scale(0.3);
		        transform:translate(-50%,-50%) scale(0.3);
		}
	.section_brand-selection .var_others .brands_carousel_container .obj_carousel li{
		max-width: 100%;
		}
}
@media screen and (max-width:1279px){
	.section_brand-selection .ctn_cta{
		position:relative;
		left:auto; bottom:auto;
		padding:var(--aox-30px) 0 var(--aox-50px) 0;
		}
}
@media screen and (max-width:1024px){
	.section_brand-selection .ctn_logo img{
		-webkit-transform:translate(-50%,-50%) scale(0.25);
		        transform:translate(-50%,-50%) scale(0.25);
		}
}	
@media screen and (max-width:768px){
	.section_brand-selection ul{
		flex-flow:row wrap;
		}
	.section_brand-selection ul li{
		max-width:33.333%;
		width:33.333%;
		}
	.section_brand-selection .ctn_logo img{
		-webkit-transform:translate(-50%,-50%) scale(0.25);
		        transform:translate(-50%,-50%) scale(0.25);
		}
}
@media screen and (max-width:767px){
	.section_brand-selection {
		margin-left: calc(-1 * var(--aox-gutter));
		width: calc(100% + var(--aox-gutter) + var(--aox-gutter));
		}
	.section_brand-selection figure{
		height:auto;
		padding-bottom:0;
		}
	.section_brand-selection figure > picture{
		height: var(--aox-200px);
		width: 100%;
		display: block;
		}
	.section_brand-selection figure img{
		width:100%;
		height: var(--aox-200px);
		object-fit: cover;
		}
	.section_brand-selection .ctn_logo{
		height:var(--aox-64px);
		}
	.section_brand-selection .ctn_cta{
		padding:var(--aox-20px) 0 var(--aox-40px) 0;
		}
	
	.section_brand-selection ul li.var_others{
		position:relative;
		max-width:100%;
		border-top:1px solid #c7c7c7;
		border-bottom:1px solid #c7c7c7;
		background:#f4f4f4;
		}
	.section_brand-selection .ctn_cta-others{
		position:absolute;
		right:0; top:0;
		width:33.333%; height:100%;
		}
	.section_brand-selection .ctn_cta-others div{
		left:0;
		padding:0 11% 0 33%;
		font-size:var(--aox-fs-10px); line-height:1.2;
		text-align:left;
		-webkit-transform:translateY(-50%);
		        transform:translateY(-50%);
		}
	.section_brand-selection .ctn_cta-others span{
		left:var(--aox-8px); top:50%;
		width:var(--aox-24px); height:var(--aox-24px);
		-webkit-transform:translateY(-50%);
		        transform:translateY(-50%);
		}
	.section_brand-selection .ctn_cta-others span::before{
		width:2px; height:var(--aox-12px);
		}
	.section_brand-selection .ctn_cta-others span::after{
		width:var(--aox-12px); height:2px;
		}

	.section_brand-selection .var_others .brands_carousel_container .obj_carousel{
		width: 100%;
		}
	.section_brand-selection .var_others .brands_carousel_container .obj_carousel li{
		max-width: 100%;
		}

	/***/
	.section_brand-selection ul li.var_derm-expertise{
		position: relative;
		max-width: 100%;
		width: 100%;
		border-top: 1px solid #c7c7c7;
		border-bottom: 1px solid #c7c7c7;
		background: #f4f4f4;
		}
	.section_brand-selection ul li.var_derm-expertise .ctn_logo{
		display: none;
		}
	.section_brand-selection .var_derm-expertise .ctn_cta-others {
		display: block;
		width: 40%;
		}
	.section_brand-selection .var_derm-expertise .ctn_cta-others div{
		padding:0 11% 0 25%;
		}

	.var_2023.section_brand-selection .var_derm-expertise figure,
	.var_2023.section_brand-selection .var_derm-expertise figure > picture,
	.var_2023.section_brand-selection .var_derm-expertise figure > picture img,
	.var_2023.section_brand-selection .var_others figure,
	.var_2023.section_brand-selection .var_others figure > picture,
	.var_2023.section_brand-selection .var_others figure > picture img{
		height: var(--aox-76px);
		}
	
}
@media screen and (max-width:375px){
	.section_brand-selection .ctn_cta-others span{
		left:5px;
		}
}
@media screen and (max-width:320px){
	.section_brand-selection .ctn_cta-others span{
		left:3px;
		}
}



.var_2023.section_brand-selection figure{
	padding-bottom: 0;
	height: var(--aox-450px);
	}
.var_2023.section_brand-selection .ctn_logo{
	height: var(--aox-150px);
	}
.var_2023.section_brand-selection .var_others {
	max-width: calc(100% / 6);
	}
.var_2023.section_brand-selection .var_others .brands_carousel_container .obj_brand-carousel{
	height: var(--aox-450px);
	}
.var_2023.section_brand-selection .ctn_cta-others{
	height: var(--aox-150px);
	}



@media screen and (max-width: 767px){
	.var_2023.section_brand-selection figure{
		height: var(--aox-225px);
		}
	.var_2023.section_brand-selection .ctn_logo {
		height: var(--aox-64px);
		}
	.var_2023.section_brand-selection .var_derm-expertise figure{
    	height: var(--aox-90px);
		}
	.var_2023.section_brand-selection figure img {
		height: var(--aox-220px);
		}
	.var_2023.section_brand-selection ul li {
		max-width: 50%;
		width: 50%;
		}
	.var_2023.section_brand-selection ul li.var_derm-expertise{
		max-width: 100%;
		width: 100%;
		order: 1;
		border-bottom: none;
		}
	.var_2023.section_brand-selection ul li.var_lrp{ order: 2; }
	.var_2023.section_brand-selection ul li.var_vichy{ order: 4; }
	.var_2023.section_brand-selection ul li.var_cerave{ order: 3; }
	.var_2023.section_brand-selection ul li.var_skinc{ order: 5; }
	.var_2023.section_brand-selection ul li.var_others{ order: 6; }

	.var_2023.section_brand-selection .var_others {
		max-width: 100%;
		}
	.var_2023.section_brand-selection .var_others figure > picture{
		height: var(--aox-90px);
		}

	.var_2023.section_brand-selection .var_derm-expertise figure,
	.var_2023.section_brand-selection .var_derm-expertise figure > picture,
	.var_2023.section_brand-selection .var_derm-expertise figure > picture img,
	.var_2023.section_brand-selection .var_others figure,
	.var_2023.section_brand-selection .var_others figure > picture,
	.var_2023.section_brand-selection .var_others figure > picture img{
		height: var(--aox-90px);
		}
	.var_2023.section_brand-selection ul li.var_others{
		border-top: none; border-bottom: none;
		}
	.var_2023.section_brand-selection .ctn_cta-others {
		height: var(--aox-90px);
		}
	.var_2023.section_brand-selection .var_others .brands_carousel_container .obj_brand-carousel{
		height: var(--aox-90px);
		}

	}
/* ======================================== */
/* .section_wide-carousel
/* ======================================== */
.section_wide-carousel{
	width:100%;
	margin:0 auto;
	}
.section_wide-carousel .obj_carousel{
	height:var(--aox-540px);
	margin:0 auto;
	}
.section_wide-carousel .obj_carousel .ctn_banner picture img{
	position:absolute;
	z-index:1;
	left:50%; top:50%;
	width:100%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_wide-carousel .obj_carousel .ctn_banner .obj_cta{
	position:absolute;
	z-index:2;
	bottom:var(--aox-70px);
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text{
	position:absolute;
	z-index:3;
	top:var(--aox-70px);
	max-width:var(--aox-580px);
	padding-right:var(--aox-20px);
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:0.9;
	text-transform:uppercase;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2 strong{
	font-weight:700;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h3{
	margin-bottom:var(--aox-30px);
	font-size:var(--aox-fs-34px); line-height:1;
	text-transform:uppercase;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h4{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text p{
	font-size:var(--aox-fs-16px); line-height:1.8;
	}
.section_wide-carousel .obj_carousel .ctn_banner .ctn_text p strong{
	font-weight:700;
	}
.section_wide-carousel .obj_carousel .ctn_banner.var_left .obj_cta,
.section_wide-carousel .obj_carousel .ctn_banner.var_left .ctn_text{
	left:var(--aox-160px);
	}
.section_wide-carousel .obj_carousel .ctn_banner.var_right .obj_cta,
.section_wide-carousel .obj_carousel .ctn_banner.var_right .ctn_text{
	left:50%;
	}
.section_wide-carousel .obj_carousel .ctn_banner.var_center .obj_cta,
.section_wide-carousel .obj_carousel .ctn_banner.var_center .ctn_text{
	left:50%;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.section_wide-carousel .obj_carousel .ctn_banner.var_center .ctn_text{
	padding:0;
	text-align:center;
	}

.section_wide-carousel .obj_carousel .ctn_banner.var_dark .ctn_text h3,
.section_wide-carousel .obj_carousel .ctn_banner.var_dark .ctn_text h4,
.section_wide-carousel .obj_carousel .ctn_banner.var_dark .ctn_text p{
	color:#fff;
	}

@media screen and (max-width:1280px){
	.section_wide-carousel .obj_carousel{
		height:0;
		padding-bottom:42.2%;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .obj_cta{
		bottom:5.4vw;
		padding:1.8vw 3.8vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text{
		top:5.4vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2{
		margin-bottom:1.5vw;
		font-size:var(--aox-fs-60px)
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h3{
		margin-bottom:2.3vw;
		font-size:var(--aox-fs-36px)
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h4{
		margin-bottom:1.5vw;
		font-size:var(--aox-fs-15px)
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text p{
		font-size:var(--aox-fs-16px)
		}
	.section_wide-carousel .obj_carousel .ctn_banner.var_left .obj_cta,
	.section_wide-carousel .obj_carousel .ctn_banner.var_left .ctn_text{
		left:12.5vw;
		}
}
@media screen and (max-width:767px){
	.section_wide-carousel .obj_carousel{
		padding-bottom:93%;
		}
	.section_wide-carousel .obj_carousel .ctn_banner.var_left .obj_cta,
	.section_wide-carousel .obj_carousel .ctn_banner.var_left .ctn_text{
		left:var(--aox-50px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner.var_center .ctn_text{
		width:75%;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text{
		width:50%;
		top:var(--aox-40px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .obj_cta{
		bottom:var(--aox-40px);
		padding:var(--aox-20px) var(--aox-30px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2{
		margin-bottom:var(--aox-20px);
		font-size:var(--aox-fs-34px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h3{
		font-size:var(--aox-fs-18px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h4{
		margin-bottom:var(--aox-20px);
		font-size:var(--aox-fs-15px);
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text p{
		display:none;
		}
}
@media screen and (max-width:414px){
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text{
		top:9.6vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .obj_cta{
		bottom:9.6vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h2{
		margin-bottom:4.8vw;
		font-size:8.4vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h3{
		font-size:4.3vw;
		}
	.section_wide-carousel .obj_carousel .ctn_banner .ctn_text h4{
		margin-bottom:4.8vw;
		font-size:3.6vw;
		}
}
/* ======================================== */
/* .section_push-article
/* ======================================== */
.section_wide-carousel + .section_push-article{
	margin:var(--aox-60px) 0;
	}
.section_push-article{
	width:100%;
	}
.section_push-article .str_container > .ctn_cta{
	padding-top:var(--aox-60px);
	text-align:center;
	border-top:1px solid #c3c3c3;
	}

@media screen and (max-width:767px){
	.section_wide-carousel + .section_push-article{
		margin:0;
		}
	.section_push-article .str_container > .ctn_cta{
		padding-bottom:var(--aox-60px);
		}
}
/* ======================================== */
/* .section_push-contact
/* ======================================== */
.section_push-contact{
	display:flex;
	flex-flow:row wrap;
	position:relative;
	margin:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	width:calc(100% - var(--aox-60px)); height:var(--aox-450px);
	border:1px solid #c7c7c7;
	}
.section_push-contact .ctn_text{
	display:flex;
	align-items:center;
	width:42%;
	padding-left:11%;
	}
.section_push-contact .ctn_text h2{
	font-size:var(--aox-fs-32px); line-height:1;
	font-weight:700;
	}
.section_push-contact .ctn_text h3{
	position:relative;
	font-size:var(--aox-fs-26px); line-height:1;
	padding-left:var(--aox-40px);
	margin:var(--aox-20px) 0 var(--aox-10px) 0;
	}
.section_push-contact .ctn_text h3 svg{
	position:absolute;
	left:0; top:0;
	}
.section_push-contact .ctn_text p{
	font-size:var(--aox-fs-13px); line-height:1.4;
	}
.section_push-contact figure{
	position:relative;
	width:58%;
	overflow:hidden;
	}
.section_push-contact figure img{
	position:absolute;
	right:0; top:0;
	height:100%;
	}
.section_push-contact .ctn_cta{
	margin-top:var(--aox-30px);
	}

@media screen and (max-width:1340px){
	.section_push-contact{
		height:33.6vw;
		}
	.section_push-contact .ctn_text,
	.section_push-contact figure{
		width:50%;
		}
	.section_push-contact .ctn_text h2{
		font-size:var(--aox-fs-32px)
		}
	.section_push-contact .ctn_text h3{
		margin:1.5vw 0 0.75vw 0;
		font-size:var(--aox-fs-28px)
		}
	.section_push-contact .ctn_cta{
		margin-top:2.2vw;
		}
}
@media screen and (max-width:767px){
	.section_push-contact{
		margin:var(--aox-40px) var(--aox-15px) 0 var(--aox-15px);
		width:calc(100% - var(--aox-15px)); height:auto;
		}
	.section_push-contact .ctn_text{
		padding:var(--aox-30px) var(--aox-20px);
		}
	.section_push-contact .ctn_text > div{
		width:100%;
		}
	.section_push-contact .ctn_text,
	.section_push-contact figure{
		width:100%;
		}
	.section_push-contact .ctn_text h2,
	.section_push-contact .ctn_text h3{
		font-size:var(--aox-fs-24px);
		}
	.section_push-contact .ctn_text h3{
		display:inline-block;
		margin:var(--aox-20px) 0 var(--aox-10px) 0;
		padding-left:var(--aox-30px);
		min-height:var(--aox-30px);
		text-align:left;
		}
	.section_push-contact .ctn_text{
		text-align:center;
		order:1;
		}
	.section_push-contact figure img{
		position:relative;
		right:auto; top:auto;
		width:100%; height:auto;
		}
	.section_push-contact figure .lazyload-placeholder{
		height:0;
		padding-bottom:60%;
		}
	.section_push-contact .ctn_cta{
		margin-top:var(--aox-30px);
		}
	.section_push-contact .ctn_text p br{
		display:none;
		}
}
/* ======================================== */
/* .section_social-network
/* ======================================== */
.section_social-network{
	width:100%;
	margin:var(--aox-90px) 0;
	}
.section_social-network h2{
	margin-bottom:var(--aox-55px);
	font-size:var(--aox-fs-44px); line-height:1;
	text-align:center;
	}
.section_social-network ul{
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	align-items:center;
	}
.section_social-network ul li{
	margin:0 var(--aox-30px);
	}
.section_social-network ul li a,
.section_social-network ul li a svg{
	display:block;
	transition:all .3s ease;
	}

@media screen and (max-width:1340px){
	.section_social-network h2{
		font-size:var(--aox-fs-44px)
		}
}
@media screen and (max-width:767px){
	.section_social-network{
		margin:var(--aox-60px) 0;
		}
	.section_social-network h2{
		font-size:var(--aox-fs-34px);
		margin-bottom:var(--aox-40px);
		}
	.section_social-network ul li{
		margin:0 var(--aox-15px);
		}
}
/* ======================================== */
/* .section_form
/* ======================================== */
.section_form{
	width:100%;
	}
.section_form .str_container{
	max-width:var(--aox-960px);
	}
/**/
.section_form .ctn_top{
	padding-top:var(--aox-60px);
	}
.section_form .ctn_top h1{
	padding-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:1;
	border-bottom:1px solid #cbcbcb;
	}
.section_form .ctn_top h2{
	margin-top:var(--aox-70px);
	margin-bottom:var(--aox-30px);
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.section_form .ctn_top h3{
	margin-bottom:var(--aox-25px);
	font-size:var(--aox-fs-28px); line-height:1;
	font-weight:700;
	color:#777;
	text-transform:uppercase;
	}
.section_form .ctn_top p{
	font-size:var(--aox-fs-12px); line-height:1.6;
	}
/**/
.section_form .ctn_form{
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-70px) 0;
	}
.section_form .ctn_form .col_left{
	width:41.6%;
	}
.section_form .ctn_form .col_right{
	width:58.4%;
	padding-left:7.2%;
	}
.section_form .ctn_form .col_center{
	width:100%;
	padding:0 9.3%;
	}
.section_form .ctn_form .col_right h2,
.section_form .ctn_form .col_center h2,
.section_form .ctn_form .grid_item h2{
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_form .ctn_form .grid_item > h2,
.section_form .ctn_form .grid_item > form > h2{
	margin-top:var(--aox-70px);
	padding-top:var(--aox-70px);
	border-top:1px solid #cbcbcb;
	}
.section_form .ctn_form .ctn_contact{
	padding:var(--aox-50px) var(--aox-30px);
	background:#f2f2f2;
	}
.section_form .ctn_form .ctn_contact h3{
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-15px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.section_form .ctn_form .ctn_contact h4{
	font-size:var(--aox-fs-15px); line-height:1;
	}
.section_form .ctn_form .ctn_contact .ctn_cta{
	margin:var(--aox-30px) 0 var(--aox-40px) 0;
	}
.section_form .ctn_form .ctn_contact .obj_form .ctn_cta{
	margin-bottom:0;
	}
.section_form .ctn_form .ctn_contact .t_tel{
	margin:var(--aox-30px) 0 var(--aox-15px) 0;
	font-size:var(--aox-fs-28px); line-height:1;
	font-weight:700;
	}
.section_form .ctn_form .ctn_contact .t_mention{
	font-size:var(--aox-fs-12px); line-height:1.2;
	}
.section_form .ctn_form .ctn_contact .obj_infobulle .info_text{
	width:var(--aox-280px);
	}
/**/
.section_form .ctn_form .grid_container{
	display:grid;
	grid-template-columns:41.6% 51.2%;
	grid-template-rows:auto 1fr auto auto;
	grid-column-gap:7.2%;
	-webkit-column-gap:7.2%;
	        column-gap:7.2%;
	grid-template-areas:
		"upload perso"
		"mdp perso"
		". pro"
		". suppr";
	}
.section_form .ctn_form .grid_container .grid_img-upload{
	grid-area:upload;
	}
.section_form .ctn_form .grid_container .grid_mdp{
	grid-area:mdp;
	}
.section_form .ctn_form .grid_container .grid_form-perso{
	grid-area:perso;
	}
.section_form .ctn_form .grid_container .grid_form-pro{
	grid-area:pro;
	}
.section_form .ctn_form .grid_container .grid_suppr{
	grid-area:suppr;
	}
.section_form .ctn_form .grid_item.grid_form-perso > h2,
.section_form .ctn_form .grid_item.grid_form-perso > form > h2{
	margin-top:0;
	padding-top:0;
	border-top:none;
	}
/**/
@media screen and (max-width:960px){
	.section_form .ctn_top{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
	.section_form .ctn_form.var_confirm{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
	.section_form .ctn_form .col_left{
		padding-left:var(--aox-20px);
		}
	.section_form .ctn_form .col_right{
		padding-right:var(--aox-20px);
		}
	.section_form .ctn_form .grid_container{
		padding:0 var(--aox-20px);
		}
}
@media screen and (max-width:768px){
	.section_form .ctn_form .ctn_contact .obj_infobulle .info_text{
		width:var(--aox-240px);
		}
}
@media screen and (max-width:767px){
	.section_form .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_form .ctn_top{
		padding-top:var(--aox-40px);
		}
	.section_form .ctn_top h1{
		font-size:var(--aox-fs-40px);
		}
	.section_form .ctn_top h2{
		padding:0 10%;
		margin-top:var(--aox-40px);
		margin-bottom:var(--aox-15px);
		font-size:var(--aox-fs-17px);
		}
	.section_form .ctn_top h3{
		padding:0 10%;
		margin-bottom:var(--aox-25px);
		font-size:var(--aox-fs-26px);
		}
	.section_form .ctn_top p{
		padding:0 10%;
		}
	.section_form .ctn_form{
		padding:var(--aox-40px) 12%;
		}
	.section_form .ctn_form .col_left,
	.section_form .ctn_form .col_right,
	.section_form .ctn_form .col_center{
		width:100%;
		padding:0;
		}
	.section_form .ctn_form .col_right{
		padding-top:var(--aox-20px);
		}
	.section_form .ctn_form .ctn_contact{
		display:none;
		}
	.section_form .ctn_form .col_right h2,
	.section_form .ctn_form .col_center h2,
	.section_form .ctn_form .grid_item h2{
		margin-bottom:var(--aox-30px);
		font-size:var(--aox-fs-17px);
		}
	.section_form .ctn_form .grid_item > h2,
	.section_form .ctn_form .grid_item > form > h2{
		margin-top:var(--aox-40px);
		padding-top:var(--aox-40px);
		}
	.section_form .ctn_form .grid_container{
		padding:0;
		}
	.section_form .ctn_form .grid_container{
		grid-template-columns:100%;
		grid-template-rows:auto;
		grid-column-gap:0;
		-webkit-column-gap:0;
		        column-gap:0;
		grid-template-areas:
			"upload"
			"perso"
			"pro"
			"mdp"
			"suppr";
		}
	.section_form .ctn_form .grid_item.grid_mdp{
		margin-top:var(--aox-40px);
		}
	.section_form .ctn_form .grid_item.grid_suppr > h2{
		padding-top:0;
		border-top:none;
		}
}
@media screen and (max-width:320px){
	.section_form .ctn_form .ctn_contact .obj_infobulle .info_text{
		width:var(--aox-200px);
		}
}
/* ======================================== */
/* .section_article
/* ======================================== */
.section_article{
	width:100%;
	}
.section_article .str_container{
	padding-top:var(--aox-45px);
	max-width:calc(var(--aox-1090px) + var(--aox-5px));
	}

@media screen and (max-width:1095px){
	.section_article .str_container{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
}
@media screen and (max-width:767px){
	.section_article .str_container{
		padding-top:var(--aox-30px);
		}
}

/* ctn_article */
.section_article .ctn_article h3{
	font-size:var(--aox-fs-16px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.section_article .ctn_article h1{
	margin-top:var(--aox-5px);
	font-size:var(--aox-fs-50px); line-height:1.5;
	font-weight:700;
	}
.section_article .ctn_article .ctn_bottom{
	display:flex;
	flex-flow:row wrap;
	align-items:center;
	margin:var(--aox-20px) 0 var(--aox-30px) 0;
	}
.section_article .ctn_article .t_author-date{
	display:flex;
	align-items:center;
	}
.section_article .ctn_article .t_author-date figure{
	margin-right:var(--aox-20px);
	width:var(--aox-40px); height:var(--aox-40px);
	border-radius:50%;
	overflow:hidden;
	}
.section_article .ctn_article .t_author-date p{
	font-size:var(--aox-fs-13px); line-height:1;
	}
.section_article .ctn_article .t_author-date p strong{
	font-weight:700;
	}
.section_article .ctn_article .obj_likes-comments{
	margin:0 0 0 auto;
	}
.section_article .ctn_article .obj_likes-comments li + li{
	margin-right:var(--aox-5px);
	}
.section_article .ctn_article .ctn_img{
	position:relative;
	}
.section_article .ctn_article.var_video .ctn_img{
	cursor:pointer;
	}
.section_article .ctn_article.var_video .ctn_img::before,
.section_article .ctn_article.var_video .ctn_img::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	transition:all .3s ease;
	}
.section_article .ctn_article.var_video .ctn_img::before{
	width:var(--aox-88px); height:var(--aox-88px);
	border:4px solid #fff;
	border-radius:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_article .ctn_article.var_video .ctn_img::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-16px) 0 var(--aox-16px) var(--aox-28px);
	border-color:transparent transparent transparent #fff;
	-webkit-transform:translate(-40%,-50%);
	        transform:translate(-40%,-50%);
	}
.section_article .ctn_article .ctn_img img{
	position:relative;
	z-index:1;
	width:100%;
	}
.section_article .ctn_article .ctn_text{
	max-width:calc(var(--aox-760px) + var(--aox-8px));
	margin:0 auto;
	padding:var(--aox-25px) 0 var(--aox-90px) 0;
	}
.section_article .ctn_article .ctn_text h4,
.section_article .ctn_article .ctn_text p,
.section_article .ctn_article .ctn_text li{
	font-size:var(--aox-fs-16px); line-height:1.8;
	}
.section_article .ctn_article .ctn_text h4,
.section_article .ctn_article .ctn_text p,
.section_article .ctn_article .ctn_text ul,
.section_article .ctn_article .ctn_text ol,
.section_article .ctn_article .ctn_text img{
	margin-top:var(--aox-35px);
	}
.section_article .ctn_article .ctn_text img{
	max-width:100%;
	width:auto !important; height:auto !important;
	}
.section_article .ctn_article .ctn_text h4{
	font-weight:700;
	text-transform:uppercase;
	}
.section_article .ctn_article .ctn_text p a{
	text-decoration:underline;
	}
.section_article .ctn_article .ctn_text p strong{
	font-weight:700;
	}
.section_article .ctn_article .ctn_text p em{
	font-style:italic;
	}
.section_article .ctn_article .ctn_text p.t_mention{
	margin-top:var(--aox-90px);
	font-size:var(--aox-fs-12px);
	color:#a9a9a9;
	}
.section_article .ctn_article .ctn_text ul,
.section_article .ctn_article .ctn_text ol{
	counter-reset:list;
	}
.section_article .ctn_article .ctn_text ul li,
.section_article .ctn_article .ctn_text ol li{
	position:relative;
	padding-left:var(--aox-20px);
	counter-increment:list;
	}
.section_article .ctn_article .ctn_text ul li::before{
	content:'';
	position:absolute;
	left:0; top:var(--aox-10px);
	width:6px; height:6px;
	background:#000;
	border-radius:50%;
	}
.section_article .ctn_article .ctn_text ol li::before{
	content:counter(list) ".";
	position:absolute;
	left:0; top:0;
	font-weight:700;
	}
.section_article .ctn_article .ctn_text .ctn_like-article{
	margin-top:var(--aox-90px);
	padding-top:var(--aox-25px);
	border-top:1px solid #b7b7b7;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article{
	position:relative;
	display:flex;
	align-items:center;
	min-height:var(--aox-20px);
	padding-left:var(--aox-35px);
	font-size:var(--aox-fs-15px); line-height:1;
	color:#777;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article[disabled]{
	opacity:0.5;
	cursor:default;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article svg{
	position:absolute;
	left:0; top:50%;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	transition:all .3s ease;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article .i_heart-hover{
	opacity:0;
	}
.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article.is-checked .i_heart-hover{
	opacity:1;
	}

@media screen and (min-width:1025px){
	.section_article .ctn_article .ctn_text .ctn_like-article .bt_like-article:hover{
		color:#000;
		}
	.section_article .ctn_article.var_video .ctn_img:hover::before{
		-webkit-transform:translate(-50%,-50%) scale(1.1);
		        transform:translate(-50%,-50%) scale(1.1);
		}
	.section_article .ctn_article.var_video .ctn_img:hover::after{
		-webkit-transform:translate(-40%,-50%) scale(1.1);
		        transform:translate(-40%,-50%) scale(1.1);
		}
}
@media screen and (max-width:1095px){
	.section_article .ctn_article h1{
		font-size:var(--aox-fs-50px);
		}
}
@media screen and (max-width:767px){
	.section_article .ctn_article h3{
		font-size:var(--aox-fs-12px);
		}
	.section_article .ctn_article h1{
		margin-top:var(--aox-20px);
		font-size:var(--aox-fs-24px);
		}
	.section_article .ctn_article .ctn_bottom{
		margin:var(--aox-20px) 0 var(--aox-30px) 0;
		}
	.section_article .ctn_article .t_author-date{
		width:100%;
		}
	.section_article .ctn_article .obj_likes-comments{
		margin:var(--aox-20px) 0 0 auto;
		}
	.section_article .ctn_article .ctn_text{
		padding:var(--aox-10px) 0 var(--aox-60px) 0;
		}
	.section_article .ctn_article .ctn_text h4,
	.section_article .ctn_article .ctn_text p,
	.section_article .ctn_article .ctn_text ul,
	.section_article .ctn_article .ctn_text ol,
	.section_article .ctn_article .ctn_text img{
		margin-top:var(--aox-20px);
		}
	.section_article .ctn_article .ctn_text .ctn_like-article,
	.section_article .ctn_article .ctn_text p.t_mention{
		margin-top:var(--aox-40px);
		}
	.section_article .ctn_article.var_video .ctn_img::before{
		width:var(--aox-78px); height:var(--aox-78px);
		}
	.section_article .ctn_article.var_video .ctn_img::after{
		border-width:var(--aox-14px) 0 var(--aox-14px) var(--aox-24px);
		}
}

/* ctn_comments */
.section_article .ctn_comment{
	position:relative;
	max-width:calc(var(--aox-760px) + var(--aox-8px));
	margin:0 auto;
	padding:var(--aox-50px) 0 var(--aox-80px) 0;
	background:#f2f2f2;
	}
.section_article .ctn_comment .bg_comment{
	position:absolute;
	z-index:1;
	left:0; top:0;
	width:0; height:100%;
	background:#f2f2f2;
	}
.section_article .ctn_comments{
	position:relative;
	z-index:2;
	}
.section_article .ctn_comments .ctn_top{
	padding-bottom:var(--aox-40px);
	border-bottom:1px solid #b0b0b0;
	}
.section_article .ctn_comments h3{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	}
.section_article .ctn_comments .obj_form .ctn_single-file-upload{
	position:relative;
	z-index:2;
	}
.section_article .ctn_comments .obj_form .ctn_single-file-upload .obj_cta{
	min-height:var(--aox-30px);
	margin:0;
	padding:var(--aox-7px) var(--aox-5px) var(--aox-7px) var(--aox-50px);
	font-size:var(--aox-fs-16px);
	font-weight:300;
	color:#000;
	text-transform:none;
	background:none;
	}
.section_article .ctn_comments .obj_form .ctn_file-upload{
	position:relative;
	}
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus{
	position:absolute;
	z-index:1;
	left:0; top:0;
	width:var(--aox-30px); height:var(--aox-30px);
	border:2px solid #000;
	}
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::before,
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#000;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::before{
	width:var(--aox-15px); height:2px;
	}
.section_article .ctn_comments .obj_form .ctn_file-upload .i_add-plus::after{
	width:2px; height:var(--aox-15px);
	}
.section_article .ctn_comments .ctn_row{
	padding:var(--aox-50px) 0;
	border-bottom:1px solid #b0b0b0;
	}
.section_article .ctn_comments .ctn_row .obj_form{
	padding:var(--aox-30px) 0 0 var(--aox-55px);
	}
.section_article .ctn_comments .ctn_row .row_level-2{
	margin:var(--aox-40px) 0 0 var(--aox-55px);
	padding-left:var(--aox-30px);
	border-left:1px solid #b0b0b0;
	}
.section_article .ctn_comments .ctn_message{
	display:flex;
	flex-flow:row wrap;
	align-items:flex-start;
	}
.section_article .ctn_comments .row_level-2 .ctn_message + .ctn_message{
	margin-top:var(--aox-40px);
	}
.section_article .ctn_comments .ctn_message figure{
	width:var(--aox-40px); height:var(--aox-40px);
	border-radius:50%;
	overflow:hidden;
	}
.section_article .ctn_comments .ctn_message figure img{
	width:100%;
	}
.section_article .ctn_comments .row_level-2 .ctn_message figure{
	width:var(--aox-30px); height:var(--aox-30px);
	}
.section_article .ctn_comments .ctn_message .ctn_text{
	width:calc(100% - var(--aox-40px));
	padding:var(--aox-5px) var(--aox-15px) 0 var(--aox-15px);
	}
.section_article .ctn_comments .row_level-2 .ctn_message .ctn_text{
	width:calc(100% - var(--aox-30px));
	}
.section_article .ctn_comments .ctn_message .ctn_text h4{
	font-size:var(--aox-fs-12px); line-height:1;
	color:#777;
	}
.section_article .ctn_comments .ctn_message .ctn_text h4 strong{
	font-size:var(--aox-fs-12px);
	color:#000;
	font-weight:700;
	}
.section_article .ctn_comments .ctn_message .ctn_text p{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-14px); line-height:1.6;
	}
.section_article .ctn_comments .ctn_message .ctn_text p.t_error{
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:500;
	color:#ff4847;
	}
.section_article .ctn_comments .ctn_message .ctn_text img{
	margin-top:var(--aox-25px);
	max-width:var(--aox-240px);
	max-height:var(--aox-240px);
	}
.section_article .ctn_comments .bt_reply{
	position:relative;
	display:inline-block;
	margin:var(--aox-25px) 0 0 var(--aox-55px);
	padding:var(--aox-5px) var(--aox-5px) var(--aox-5px) var(--aox-25px);
	font-size:var(--aox-fs-13px); line-height:1;
	font-weight:500;
	}
.section_article .ctn_comments .bt_reply::before,
.section_article .ctn_comments .bt_reply::after{
	content:'';
	position:absolute;
	transition:all .3s ease;
	}
.section_article .ctn_comments .bt_reply::before{
	left:0; top:0;
	width:var(--aox-15px); height:var(--aox-10px);
	border-left:2px solid #000;
	border-bottom:2px solid #000;
	}
.section_article .ctn_comments .bt_reply::after{
	left:var(--aox-8px); top:var(--aox-7px);
	width:var(--aox-6px); height:var(--aox-6px);
	border-top:solid 2px #000;
	border-right:solid 2px #000;
	-webkit-transform:rotate(45deg);
	        transform:rotate(45deg);
	}
.section_article .ctn_comments .bt_more{
	display:inline-block;
	margin-top:var(--aox-40px);
	padding:var(--aox-5px) var(--aox-5px) 0 0;
	font-size:var(--aox-fs-13px); line-height:1;
	font-weight:500;
	text-align:left;
	}
.section_article .ctn_comments .ctn_bt .bt_more{
	padding-left:var(--aox-30px);
	}

.section_article .ctn_comments .row_level-1 .t_notif{
	margin:var(--aox-20px) 0 0 var(--aox-55px);
	}
.section_article .ctn_comments .row_level-1 .t_notif span{
	display:inline-block;
	padding:var(--aox-15px) var(--aox-30px);
	font-size:var(--aox-fs-14px); line-height:1.2;
	font-weight:500;
	color:#000;
	background:#fff;
	}

@media screen and (min-width:1025px){
	.section_article .ctn_comments .obj_form .ctn_single-file-upload .obj_cta:hover{
		color:#000 !important;
		}
	.section_article .ctn_comments .bt_reply:hover{
		color:#000 !important;
		}
	.section_article .ctn_comments .bt_reply:hover::before,
	.section_article .ctn_comments .bt_reply:hover::after{
		border-color:#000 !important;
		}
	.section_article .ctn_comments .bt_more:hover{
		color:#000 !important;
		}
}
@media screen and (max-width:768px){
	.section_article .ctn_comment .bg_comment{
		width:0 !important;
		}
	.section_article .ctn_comment{
		padding:var(--aox-50px) var(--aox-20px) var(--aox-80px) var(--aox-20px);
		}
	.section_article .ctn_comments .ctn_message{
		flex-direction:column;
		}
	.section_article .ctn_comments .ctn_message .ctn_text{
		width:100%;
		padding:var(--aox-15px) 0 0 0;
		}
	.section_article .ctn_comments .ctn_row .obj_form{
		padding-left:0;
		}
	.section_article .ctn_comments .ctn_row .row_level-2,
	.section_article .ctn_comments .bt_reply{
		margin-left:var(--aox-30px);
		}
}
/* ======================================== */
/* .section_article-carousel
/* ======================================== */
.section_article-carousel{
	width:100%;
	}
.section_article-carousel .str_container{
	padding:var(--aox-60px) 0 var(--aox-100px) 0;
	}
.section_article-carousel h2{
	padding:0 7%;
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-34px); line-height:1;
	}
.section_article-carousel .ctn_banner a{
	display:block;
	padding:0 var(--aox-15px);
	}
.section_article-carousel .ctn_banner a figure{
	position:relative;
	overflow:hidden;
	}
.section_article-carousel .ctn_banner a figure img{
	width:100%;
	transition:all .3s ease;
	}

.section_article-carousel .ctn_banner.var_video a figure::before,
.section_article-carousel .ctn_banner.var_video a figure::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	}
.section_article-carousel .ctn_banner.var_video a figure::before{
	width:var(--aox-88px); height:var(--aox-88px);
	border:4px solid #fff;
	border-radius:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_article-carousel .ctn_banner.var_video a figure::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-16px) 0 var(--aox-16px) var(--aox-28px);
	border-color:transparent transparent transparent #fff;
	-webkit-transform:translate(-40%,-50%);
	        transform:translate(-40%,-50%);
	}

.section_article-carousel .ctn_banner h3{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	}
.section_article-carousel .obj_likes-comments{
	justify-content:flex-start;
	margin:var(--aox-20px) 0 0 0;
	}
.section_article-carousel .obj_likes-comments li{
	padding:var(--aox-3px) 0 0 var(--aox-25px);
	margin-right:var(--aox-20px);
	font-size:var(--aox-fs-11px);
	}

@media screen and (min-width:1025px){
	.section_article-carousel .ctn_banner a:hover figure img{
		-webkit-transform:scale(1.05);
		        transform:scale(1.05);
		}
}
@media screen and (max-width:1280px){
	.section_article-carousel h2{
		font-size:var(--aox-fs-40px);
		}
	.section_article-carousel .ctn_banner h3{
		font-size:var(--aox-fs-18px);
		}
}
@media screen and (max-width:767px){
	.section_article-carousel .str_container{
		padding:var(--aox-60px) 0;
		}
	.section_article-carousel h2{
		font-size:var(--aox-fs-24px);
		padding:0 var(--aox-20px);
		}
	.section_article-carousel .ctn_banner h3{
		font-size:var(--aox-fs-18px);
		}
	.section_article-carousel .ctn_banner a{
		padding:0 var(--aox-20px);
		}
	.section_article-carousel .ctn_banner.var_video a figure::before{
		width:var(--aox-78px); height:var(--aox-78px);
		}
	.section_article-carousel .ctn_banner.var_video a figure::after{
		border-width:var(--aox-14px) 0 var(--aox-14px) var(--aox-24px);
		}
}
/* ======================================== */
/* .section_page-liste
/* ======================================== */
.section_page-liste{
	width:100%;
	}
.section_page-liste .str_container{
	max-width:var(--aox-1150px);
	}
.section_page-liste .ctn_row{
	margin-bottom:var(--aox-40px);
	}
.section_page-liste .ctn_row > h2{
	margin-bottom:var(--aox-50px);
	padding:0 var(--aox-50px) var(--aox-20px) var(--aox-50px);
	font-size:var(--aox-fs-32px); line-height:1.2;
	border-bottom:1px solid #c3c3c3;
	}
.section_page-liste .ctn_row > h2 strong{
	font-weight:700;
	}
.section_page-liste .ctn_sub-row{
	display: flex;
    flex-flow: row wrap;
    margin-right: calc(-1 * var(--aox-30px));
	}
.section_page-liste .ctn_sub-row.lvl-1,
.section_page-liste .ctn_sub-row.lvl-2{
	display:none;
	}
.section_page-liste .ctn_sub-row.lvl-1.active,
.section_page-liste .ctn_sub-row.lvl-2.active{
	display:flex;
	}
.section_page-liste .ctn_sub-row > .obj_back-page{
	margin-top:0;
	max-width:var(--aox-1050px);
	}
.section_page-liste .ctn_sub-row > h3{
	padding:var(--aox-30px) 0 var(--aox-60px) 0;
	width:100%;
	font-size:var(--aox-fs-32px); line-height:1.2;
	font-weight:700;
	text-align:center;
	}
.section_page-liste .ctn_sub-row > .ctn_cta{
	margin-top:var(--aox-60px);
	padding-top:var(--aox-60px);
	width:100%;
    text-align:center;
    border-top:1px solid #c3c3c3;
	}
.section_page-liste .ctn_page-liste{
	width:33.33%;
	margin-bottom:var(--aox-50px);
	}
.section_page-liste .ctn_page-liste a,
.section_page-liste .ctn_page-liste button{
	display:block;
	width:100%;
	padding:0 var(--aox-15px);
	text-align:left;
	}
.section_page-liste .ctn_page-liste figure{
	position:relative;
	height:0;
	padding-bottom:73%;
	overflow:hidden;
	}
.section_page-liste .ctn_page-liste figure img{
	position:absolute;
	left:0; top:0;
	z-index:1;
	width:100%; height:100%;
	transition:all .3s ease;
	object-fit:cover;
	}
.section_page-liste .ctn_page-liste .ctn_page-liste-visuel{
	position:relative;
	width: 100%;
	height: auto;
	}
.section_page-liste .ctn_page-liste .lazyload-placeholder{
	height:0;
	padding-bottom:73%;
	}
.section_page-liste .ctn_page-liste h3{
	padding-top:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1.1;
	font-weight:700;
	}
.section_page-liste .ctn_page-liste.var-fb h3{
	padding-left:var(--aox-50px);
	position: relative;
	min-height: var(--aox-60px);
	}	
.section_page-liste .ctn_page-liste.var-fb h3:before{
	content: "";
    position: absolute;
    left: 0; top: var(--aox-20px);
    height: var(--aox-40px); width: var(--aox-40px);
    background: url(/dist/formationcaf/static/media/i_facebook-picto.63bc9546.svg) left top / var(--aox-40px) auto no-repeat;
	}	
.section_page-liste .ctn_page-liste .t_desc{
	padding-top: var(--aox-10px);
    font-size:var(--aox-fs-14px); line-height: 1.57143;
    font-weight: 300;
	}	
.section_page-liste .ctn_page-liste .obj_likes-comments{
	justify-content:flex-start;
	margin:var(--aox-20px) 0 0 0;
	}
.section_page-liste .ctn_page-liste .obj_likes-comments li{
	padding:var(--aox-3px) 0 0 var(--aox-25px);
	margin-right:var(--aox-20px);
	font-size:var(--aox-fs-11px);
	}
.section_page-liste .ctn_page-liste .obj_likes-fb{
	justify-content:flex-start;
	margin:var(--aox-20px) 0 0 0;
	}
.section_page-liste .ctn_page-liste .obj_likes-fb li{
	padding:var(--aox-3px) 0 0 var(--aox-25px);
	margin-right:var(--aox-20px);
	font-size:var(--aox-fs-11px);
	}	
.section_page-liste .ctn_page-liste .ctn_cta{
	margin-top:var(--aox-15px);
	}
.section_page-liste .ctn_page-liste.var_video figure::before,
.section_page-liste .ctn_page-liste.var_video figure::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	}
.section_page-liste .ctn_page-liste.var_video figure::before{
	width:var(--aox-88px); height:var(--aox-88px);
	border:4px solid #fff;
	border-radius:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_page-liste .ctn_page-liste.var_video figure::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-16px) 0 var(--aox-16px) var(--aox-28px);
	border-color:transparent transparent transparent #fff;
	-webkit-transform:translate(-40%,-50%);
	        transform:translate(-40%,-50%);
	}
.section_page-liste .ctn_page-liste.is-not-available button{
	cursor:default;
	}
.section_page-liste .ctn_page-liste.is-not-available figure img{
	-webkit-filter:grayscale(1);
	        filter:grayscale(1);
	}
.section_page-liste .obj_push-event{
	margin:var(--aox-30px) auto var(--aox-120px) auto;
	width:100%; max-width:var(--aox-1050px);
	}

.section_page-liste .obj_tabs-list .ctn_nav-tabs {
	margin-bottom: 0;
	}

/**/
@media screen and (min-width:1025px){
	.section_page-liste .ctn_page-liste a:hover figure img,
	.section_page-liste .ctn_page-liste button:hover figure img{
		-webkit-transform:scale(1.05);
		        transform:scale(1.05);
		}
	.section_page-liste .ctn_page-liste.is-not-available a:hover figure img,
	.section_page-liste .ctn_page-liste.is-not-available button:hover figure img{
		-webkit-transform:scale(1);
		        transform:scale(1);
		}
	.section_page-liste .ctn_page-liste a:hover .obj_cta-download,
	.section_page-liste .ctn_page-liste button:hover .obj_cta-download{
		color:#777 !important;
		}
	.section_page-liste .ctn_page-liste a:hover .obj_cta-download svg,
	.section_page-liste .ctn_page-liste button:hover .obj_cta-download svg{
		fill:#777 !important;
		}
}
@media screen and (max-width:1150px){
	.section_page-liste .ctn_row{
		padding:0 var(--aox-20px);
		}
}
@media screen and (max-width:768px){
	.section_page-liste .ctn_page-liste{
		width:50%;
		}
}
@media screen and (max-width:767px){
	.section_page-liste .ctn_row > h2{
		margin-bottom:var(--aox-40px);
		padding-left:var(--aox-30px);
		padding-right:var(--aox-30px);
		font-size:var(--aox-fs-28px);
		}
	.section_page-liste .ctn_row{
		padding:0;
		margin-bottom:var(--aox-30px);
		}
	.section_page-liste .ctn_sub-row{
		padding:0;
		margin-right:0;
		}
	.section_page-liste .ctn_page-liste{
		width:100%;
		margin-bottom:var(--aox-40px);
		}
	.section_page-liste .ctn_page-liste a,
	.section_page-liste .ctn_page-liste button{
		padding:0;
		}
	.section_page-liste .ctn_page-liste.var_video figure::before{
		width:var(--aox-78px); height:var(--aox-78px);
		}
	.section_page-liste .ctn_page-liste.var_video figure::after{
		border-width:var(--aox-14px) 0 var(--aox-14px) var(--aox-24px);
		}
	.section_page-liste .ctn_sub-row > .obj_back-page{
		padding:0;
		}
	.section_page-liste .ctn_sub-row > h3{
		padding-top:var(--aox-15px);
		}
	.section_page-liste .obj_push-event{
		margin-bottom:var(--aox-70px);
		}
}


/***/




.section_page-liste .ctn_text .bt_fav-formation {
	position: absolute;
	top: calc(-1 * var(--aox-10px));
    right: 0;
    display: flex;
    width: var(--aox-35px);
    height: var(--aox-35px);
    cursor: pointer;
    align-items: center;
    justify-content: center;
	cursor: pointer;
	}
.section_page-liste .ctn_text .bt_fav-formation svg {
	fill: #C3C3C3;
	}
.section_page-liste .ctn_text .bt_fav-formation:disabled svg {
	fill: rgb(195 195 195 / 35%);
	}
.section_page-liste .obj_formation_mini_parcours.is-not-available .ctn_text .bt_fav-formation {
	cursor: default;
	}

/***/

.section_page-liste .ctn_text .t_tag {
	position: relative;
	display: flex;
	margin-bottom: var(--aox-5px);

	-webkit-animation-duration: 3s;

	        animation-duration: 3s;
	-webkit-animation-name: tag_clignotement;
	        animation-name: tag_clignotement;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	transition: none;
	}
@-webkit-keyframes tag_clignotement {
	0%   { opacity:0; }
	60%   {opacity:1; }
	100% { opacity:0; }
	}
@keyframes tag_clignotement {
	0%   { opacity:0; }
	60%   {opacity:1; }
	100% { opacity:0; }
	}
.section_page-liste .ctn_text .t_tag span{
	display: inline-block;
    font-weight: 500;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-110);
    text-transform: uppercase;
    padding: var(--aox-3px) var(--aox-9px);
    background-color: #f2f2f2;
	color: #ffffff;
	}
.section_page-liste .ctn_text .obj_nb_view {
	position: relative;
	display: flex;
	margin-top: var(--aox-5px);
	}
.section_page-liste .ctn_text .obj_nb_view svg{
	fill: #c3c3c3;
	margin-right: var(--aox-5px);
	}
.section_page-liste .ctn_text .obj_nb_view span{
	display: block;
	font-weight: 400;
	font-size: var(--aox-fs-11px);
	line-height: var(--aox-lh-110);
	color: #777777;
	}


/***/

.section_page-liste .section_mini_parcours{
	padding: var(--aox-60px) 0 0;
	}


/** .section_list_formation */

.section_page-liste .obj_tabs-list .section_list_formation{
	padding: 0;
    max-width: var(--aox-1050px);
    margin: 0 auto;
	padding-top: var(--aox-60px);
	}
.section_page-liste .section_list_formation .ctn_sub-row .ctn_page-liste{
	position: relative;
	width: var(--aox-330px);
	margin: 0 var(--aox-30px) var(--aox-50px) 0;
	}
.section_page-liste .section_list_formation .ctn_sub-row .ctn_page-liste button {
	padding: 0;
	}
.section_page-liste .section_list_formation .ctn_page-liste .ctn_text {
	position: relative;
    min-height: var(--aox-35px);
    padding-right: var(--aox-53px);
    margin-top: var(--aox-15px);
	}
.section_page-liste .section_list_formation .ctn_page-liste .ctn_text h3 {
	padding-top: 0;
	text-transform: uppercase;
	}
.section_page-liste .section_list_formation .ctn_page-liste .ctn_text .bt_fav-formation {
	width: var(--aox-53px);
	height: var(--aox-53px);
	}



/***/

@media screen and (max-width:767px){

	.section_page-liste .section_list_formation .ctn_sub-row .ctn_page-liste {
		width: 100%;
		margin: 0 0 var(--aox-30px);
		}


	}

/***/

/* section_favoris */
.obj_section.section_favoris .ctn_section .ctn_title,
.obj_section.section_favoris .ctn_section .ctn_title .t_title{
	margin-bottom: var(--aox-15px);
	}
.section_favoris .section_list_formation{
	position: relative;
    width: 100%;
    padding: 0 var(--aox-110px);
    border-top: 1px solid rgba(0,0,0,0.5);
	}
.section_favoris .section_list_formation .ctn_brand{
	margin-top: var(--aox-60px);
	}
.section_favoris .section_list_formation .ctn_brand .ctn_title{
	margin-bottom: var(--aox-40px);
	}
.section_favoris .section_list_formation .ctn_brand .ctn_title .t_title{
	font-weight: 700;
	font-family :Montserrat, sans-serif;
    font-size: var(--aox-fs-32px);
    line-height: var(--aox-lh-100);
	width: var(--aox-550px);
	text-transform: uppercase;
	}
.section_favoris .section_list_formation .ctn_brand .ctn_sub-row{
	padding: 0;
	width: calc(100% + var(--aox-30px));
	}



/***/

@media screen and (max-width:767px){

	.section_favoris .section_list_formation{
		padding: 0;
		}
	.section_favoris .section_list_formation .ctn_brand {
		margin-top: var(--aox-30px);
		}
	.section_favoris .section_list_formation .ctn_brand .ctn_title {
		margin-bottom: var(--aox-20px);
		}
	.section_favoris .section_list_formation .ctn_brand .ctn_sub-row {
		width: 100%;
		}
	.section_favoris .section_list_formation .ctn_brand .ctn_title .t_title{
		font-size: var(--aox-fs-26px);
		line-height: var(--aox-lh-100);
		width: 100%;
		}

	}

/* ======================================== */
/* .section_form
/* ======================================== */
.section_mentions{
	width:100%;
	}
.section_mentions .str_container{
	max-width:var(--aox-960px);
	}
/**/
.section_mentions .ctn_top{
	padding-top:var(--aox-60px);
	}
.section_mentions .ctn_top h1{
	padding-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:1;
	word-wrap:break-word;
	border-bottom:1px solid #cbcbcb;
	}
/**/
.section_mentions .ctn_main{
	padding-bottom:var(--aox-180px);
	}
/**/
.section_mentions .ctn_paragraph{
	padding:var(--aox-80px) var(--aox-20px) 0 var(--aox-20px);
	margin:calc(-1 * var(--aox-20px)) auto 0 auto;
	max-width:calc(var(--aox-810px) + var(--aox-5px));
	}
.section_mentions .ctn_paragraph.mt_40{
	padding-top:0;
	margin-top:var(--aox-40px);
	}
.section_mentions .ctn_paragraph h2{
	font-weight:700;
	font-size:var(--aox-fs-22px); line-height:1.2;
	text-transform:uppercase;
	}
.section_mentions .ctn_paragraph h3{
	margin-top:var(--aox-30px);
	font-weight:700;
	font-size:var(--aox-fs-18px); line-height:1.2;
	}
.section_mentions h4{
	padding-top:var(--aox-80px);
	margin-top:calc(-1 * var(--aox-50px));
	font-size:var(--aox-fs-24px); line-height:1.2;
	}
.section_mentions .ctn_paragraph p{
	font-size:var(--aox-fs-14px); line-height:2;
	}
.section_mentions .ctn_paragraph p.var_align-center{
	text-align:center;
	}
.section_mentions .ctn_paragraph h2 + p,
.section_mentions .ctn_paragraph h3 + p,
.section_mentions .ctn_paragraph h4 + p,
.section_mentions .ctn_paragraph ul + p,
.section_mentions .ctn_paragraph ol + p,
.section_mentions .ctn_paragraph p + p{
	margin-top:var(--aox-30px);
	}
.section_mentions .ctn_paragraph p strong{
	font-weight:700;
	}
.section_mentions .ctn_paragraph p a{
	font-weight:700;
	text-decoration:underline;
	}
.section_mentions .ctn_paragraph ul li{
	position:relative;
	margin-top:var(--aox-30px);
	padding-left:var(--aox-60px);
	font-size:var(--aox-fs-14px); line-height:2;
	}
.section_mentions .ctn_paragraph ul li::before{
	content:'';
	position:absolute;
	left:var(--aox-30px); top:var(--aox-10px);
	width:var(--aox-6px); height:var(--aox-6px);
	background:#000;
	border-radius:50%;
	}
.section_mentions .ctn_paragraph ul li strong,
.section_mentions .ctn_paragraph ol li strong{
	font-weight:700;
	}
.section_mentions .ctn_paragraph ul li a,
.section_mentions .ctn_paragraph ol li a{
	font-weight:700;
	text-decoration:underline;
	}
.section_mentions .ctn_paragraph ul ul{
	margin-top:var(--aox-15px);
	}
.section_mentions .ctn_paragraph ul ul li{
	margin-top:var(--aox-5px);
	padding-left:var(--aox-45px);
	}
.section_mentions .ctn_paragraph ul ul li::before{
	top:var(--aox-13px);
	width:5px; height:2px;
	border-radius:0;
	}
.section_mentions .ctn_paragraph ol{
	counter-reset:mentions-list-item;
	}
.section_mentions .ctn_paragraph ol > li{
	position:relative;
	margin-top:var(--aox-30px);
	padding-left:var(--aox-60px);
	font-size:var(--aox-fs-14px); line-height:2;
	}
.section_mentions .ctn_paragraph ol > li::before{
	content:'1.';
	position:absolute;
	left:var(--aox-25px); top:-5px;
	font-size:var(--aox-fs-13px);
	font-weight:700;
	counter-increment:mentions-list-item;
	content:counter(mentions-list-item) '.';
	}
/**/
.section_mentions .ctn_frame{
	padding:var(--aox-40px);
	margin:var(--aox-60px) auto 0 auto;
	max-width:calc(var(--aox-810px) + var(--aox-5px));
	border:1px solid #d7d7d7;
	}
.section_mentions .ctn_frame h4{
	margin-top:0;
	padding-top:0;
	}
.section_mentions .ctn_frame ul li{
	margin-top:var(--aox-30px);
	font-size:var(--aox-fs-14px); line-height:2;
	}
.section_mentions .ctn_frame ul li strong{
	font-weight:700;
	}
.section_mentions .ctn_frame ul li a{
	font-weight:700;
	text-decoration:underline;
	}
/**/
.section_mentions .ctn_sommaire{
	padding:0 var(--aox-20px);
	margin:var(--aox-60px) auto 0 auto;
	max-width:calc(var(--aox-810px) + var(--aox-5px));
	}
.section_mentions .ctn_sommaire h2{
	font-weight:700;
	font-size:var(--aox-fs-22px); line-height:1.2;
	text-transform:uppercase;
	}
.section_mentions .ctn_sommaire ul{
	padding-left:var(--aox-60px);
	margin-top:var(--aox-45px);
	}
.section_mentions .ctn_sommaire ul li a{
	font-weight:700;
	font-size:var(--aox-fs-14px); line-height:2;
	text-decoration:underline;
	}
.section_mentions .ctn_sommaire ul ul{
	padding-left:var(--aox-30px);
	margin:var(--aox-10px) 0;
	}
/**/
.ctn_table{
	margin-top:var(--aox-60px);
	padding:0 var(--aox-30px);
	overflow-x:auto;
	}
.ctn_table strong{
	font-weight:700;
	}
.ctn_table ul{
	padding-left:var(--aox-15px);
	}
.ctn_table ul li + li{
	margin-top:var(--aox-15px);
	}
.ctn_table ul ul{
	margin-top:var(--aox-15px);
	}
.ctn_table ul ul li{
	margin:0;
	}
.ctn_table.var_4-col table th{
	width:25%;
	}
.ctn_table.var_2-col table th:first-child{
	width:30%;
	}
.ctn_table.var_2-col table th:last-child{
	width:70%;
	}
.ctn_table table th{
	font-weight:700;
	background:#f2f2f2;
	}
.ctn_table table th,
.ctn_table table td{
	padding:var(--aox-15px);
	font-size:var(--aox-fs-14px); line-height:1.3;
	text-align:left;
	border:1px solid #c7c7c7;
	}
/**/
@media screen and (max-width:960px){
	.section_mentions .ctn_top{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
}
@media screen and (max-width:768px){
	.ctn_table{
		padding:0;
		}
}
@media screen and (max-width:767px){
	.section_mentions .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_mentions .ctn_top{
		padding-top:var(--aox-40px);
		}
	.section_mentions .ctn_top h1{
		font-size:var(--aox-fs-40px);
		}
	.section_mentions .ctn_main{
		padding-bottom:var(--aox-70px);
		}
	.section_mentions .ctn_paragraph,
	.section_mentions h4{
		padding-top:var(--aox-120px);
		margin-top:calc(-1 * var(--aox-80px));
		}
	.section_mentions .ctn_frame{
		margin-top:var(--aox-40px);
		padding:var(--aox-30px) var(--aox-20px);
		}
	.section_mentions .ctn_sommaire{
		margin-top:var(--aox-40px);
		}
	.section_mentions .ctn_table{
		margin-top:var(--aox-40px);
		}
	.section_mentions .ctn_paragraph h3,
	.section_mentions .ctn_paragraph h2 + p,
	.section_mentions .ctn_paragraph h3 + p,
	.section_mentions .ctn_paragraph h4 + p,
	.section_mentions .ctn_paragraph ul + p,
	.section_mentions .ctn_paragraph ol + p,
	.section_mentions .ctn_paragraph p + p,
	.section_mentions .ctn_paragraph ul li,
	.section_mentions .ctn_frame ul li,
	.section_mentions .ctn_paragraph ol > li{
		margin-top:var(--aox-20px);
		}
	.section_mentions .ctn_paragraph ul li,
	.section_mentions .ctn_paragraph ol > li{
		padding-left:var(--aox-30px);
		}
	.section_mentions .ctn_paragraph ul li::before{
		left:var(--aox-10px);
		}
	.section_mentions .ctn_paragraph ol > li::before{
		left:5px;
		}
	.section_mentions .ctn_paragraph ul ul li{
		padding-left:var(--aox-20px);
		}
	.section_mentions .ctn_paragraph ul ul li::before{
		left:5px;
		}
	.section_mentions .ctn_sommaire ul{
		padding-left:var(--aox-30px);
		margin-top:var(--aox-25px);
		}
	.section_mentions .ctn_sommaire ul ul{
		padding-left:var(--aox-20px);
		}
	.ctn_table{
		margin-top:var(--aox-40px);
		}
	.ctn_table ul{
		padding-left:5px;
		}
	.ctn_table ul li + li{
		margin-top:5px;
		}
	.ctn_table table th,
	.ctn_table table td{
		padding:5px;
		font-size:2vw;
		}
}
/* ======================================== */
/* .section_product-liste
/* ======================================== */
.section_product-liste{
	width:100%;
	padding-bottom:var(--aox-150px);
	}
/* ======================================== */
/* .section_range-liste
/* ======================================== */
.section_range-liste{
	max-width:var(--aox-1150px);
	margin:var(--aox-60px) auto 0 auto;
	}
.section_range-liste + .section_range-liste{
	margin-top:var(--aox-80px);
	}
.section_range-liste .ctn_range-liste > h3{
	width:100%;
	padding:0 var(--aox-30px) var(--aox-20px) var(--aox-30px);
	font-size:var(--aox-fs-32px); line-height:1.2;
	border-bottom:1px solid #c3c3c3;
	}
.section_range-liste .ctn_range-liste > h3 strong{
	text-transform:uppercase;
	font-weight:700;
	}
.section_range-liste .ctn_range-liste{
	display:flex;
	flex-flow:row wrap;
	}
.section_range-liste .ctn_range-liste + .ctn_range-liste{
	margin-top:var(--aox-80px);
	}
/* ======================================== */
/* .section_product-detail
/* ======================================== */
.section_product-detail{
	width:100%;
	max-width:var(--aox-970px);
	margin:var(--aox-60px) auto var(--aox-100px) auto;
	padding:0 var(--aox-30px);
	}
.section_product-detail .ctn_top-detail{
	display:grid;
	grid-template-columns:27.5% 59%;
	grid-column-gap:13.5%;
	-webkit-column-gap:13.5%;
	        column-gap:13.5%;
	}
.section_product-detail .ctn_top-detail .col_image{
	grid-row-end:span 2;
	}
.section_product-detail .ctn_top-detail .col_image figure{
	display:flex;
	flex-flow:row wrap;
	justify-content:center;
	}
.section_product-detail .ctn_top-detail .col_image figure img{
	max-width:100%;
	}
.section_product-detail .ctn_top-detail .col_image figure figcaption{
	display:flex;
	align-items:center;
	position:relative;
	margin:0 var(--aox-20px);
	padding-left:var(--aox-35px);
	min-height:var(--aox-36px);
	font-size:var(--aox-fs-16px); line-height:1.125;
	}
.section_product-detail .ctn_top-detail .col_image figure figcaption svg{
	position:absolute;
	left:0; top:0;
	}
.section_product-detail .ctn_top-detail .col_image ul{
	display:flex;
	flex-flow:row wrap;
	max-width:var(--aox-250px);
	margin:var(--aox-30px) auto 0 auto;
	}
.section_product-detail .ctn_top-detail .col_image ul li{
	margin-top:var(--aox-10px);
	width:48%;
	max-width:var(--aox-120px);
	}
.section_product-detail .ctn_top-detail .col_image ul li a,
.section_product-detail .ctn_top-detail .col_image ul li button{
	width:100%;
	}
.section_product-detail .ctn_top-detail .col_image ul li .ctn_img{
	position:relative;
	}
.section_product-detail .ctn_top-detail .col_image ul li img{
	width:100%;
	}
.section_product-detail .ctn_top-detail .col_image ul li:nth-child(even){
	margin-left:auto;
	}
.section_product-detail .ctn_top-detail .col_image ul li span{
	display:block;
	padding:var(--aox-10px) var(--aox-5px) 0 var(--aox-5px);
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:700;
	text-align:left;
	transition:all .3s ease;
	}
.section_product-detail .ctn_top-detail .col_image ul li.var_video .ctn_img::before,
.section_product-detail .ctn_top-detail .col_image ul li.var_video .ctn_img::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	transition:all .3s ease;
	}
.section_product-detail .ctn_top-detail .col_image ul li.var_video .ctn_img::before{
	width:var(--aox-36px); height:var(--aox-36px);
	border:2px solid #fff;
	border-radius:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.section_product-detail .ctn_top-detail .col_image ul li.var_video .ctn_img::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-8px) 0 var(--aox-8px) var(--aox-14px);
	border-color:transparent transparent transparent #fff;
	-webkit-transform:translate(-40%,-50%);
	        transform:translate(-40%,-50%);
	}
.section_product-detail .ctn_top-detail .col_title{
	padding-top:var(--aox-40px);
	}
.section_product-detail .ctn_top-detail h1{
	font-size:var(--aox-fs-50px); line-height:1.2;
	text-transform:uppercase;
	}
.section_product-detail .ctn_top-detail h1 strong{
	font-weight:700;
	}
.section_product-detail .ctn_top-detail h2{
	margin-top:var(--aox-20px);
	font-weight:700;
	font-size:var(--aox-fs-16px); line-height:1.8;
	text-transform:uppercase;
	}
.section_product-detail .ctn_top-detail .ctn_desc-product{
	margin-top:var(--aox-50px);
	}
/**/
.section_product-detail .ctn_desc-product{
	position:relative;
	}
.section_product-detail .ctn_desc-product h3{
	font-weight:700;
	font-size:var(--aox-fs-20px); line-height:1.2;
	text-transform:uppercase;
	}
.section_product-detail .ctn_desc-product p,
.section_product-detail .ctn_desc-product ul li{
	font-size:var(--aox-fs-16px); line-height:1.6;
	word-break:break-word;
	}
.section_product-detail .ctn_desc-product p strong,
.section_product-detail .ctn_desc-product ul li strong{
	font-weight:700;
	}
.section_product-detail .ctn_desc-product p,
.section_product-detail .ctn_desc-product ul{
	margin-top:var(--aox-15px);
	}
.section_product-detail .ctn_desc-product p + ul,
.section_product-detail .ctn_desc-product ul + p,
.section_product-detail .ctn_desc-product p + p{
	margin-top:var(--aox-30px);
	}
.section_product-detail .ctn_desc-product ul li + li{
	margin-top:var(--aox-10px);
	}
.section_product-detail .ctn_desc-product ul li{
	position:relative;
	padding-left:var(--aox-25px);
	}
.section_product-detail .ctn_desc-product ul li::before{
	content:'';
	position:absolute;
	left:0; top:var(--aox-10px);
	width:3px; height:3px;
	background:#000;
	border-radius:50%;
	}
.section_product-detail .ctn_desc-product.var_composition ul{
	margin-top:var(--aox-30px);
	}
.section_product-detail .ctn_desc-product.var_composition ul li{
	padding:0;
	margin:0;
	}
.section_product-detail .ctn_desc-product.var_composition ul li::before{
	display:none;
	}
.section_product-detail .ctn_desc-product.var_plus{
	padding:var(--aox-30px);
	background:#000;
	}
.section_product-detail .ctn_desc-product.var_plus h3{
	color:#fff !important;
	}
.section_product-detail .ctn_desc-product.var_plus p{
	color:#fff;
	}
/**/
.section_product-detail .ctn_detail{
	margin-top:var(--aox-80px);
	}
.section_product-detail .ctn_detail .ctn_desc-product{
	padding:0 10%;
	}
.section_product-detail .ctn_detail .ctn_desc-product.var_grey{
	padding-top:var(--aox-40px);
	padding-bottom:var(--aox-40px);
	background:#f2f2f2;
	}
.section_product-detail .ctn_detail .ctn_desc-product.var_texture{
	padding-right:18%;
	}
.section_product-detail .ctn_detail .ctn_desc-product.var_texture > *{
	position:relative;
	z-index:2;
	}
.section_product-detail .ctn_detail .ctn_desc-product.var_texture > img{
	position:absolute;
	z-index:1;
	right:0; bottom:0;
	max-width:var(--aox-285px);
	-webkit-transform:translateX(50%);
	        transform:translateX(50%);
	}
/**/
@media screen and (max-width:1280px){
	.section_product-detail .ctn_detail .ctn_desc-product.var_texture > img{
		-webkit-transform:translateX(0);
		        transform:translateX(0);
		}
}
@media screen and (max-width:767px){
	.section_product-detail{
		margin-top:var(--aox-20px);
		margin-bottom:var(--aox-50px);
		padding:0 var(--aox-20px);
		}
	.section_product-detail .ctn_top-detail{
		grid-template-columns:100%;
		grid-column-gap:0;
		-webkit-column-gap:0;
		        column-gap:0;
		}
	.section_product-detail .ctn_top-detail .col_title{
		grid-row-start:1;
		padding-top:0;
		}
	.section_product-detail .ctn_top-detail h1{
		font-size:var(--aox-fs-40px);
		}
	.section_product-detail .ctn_top-detail h2{
		margin-top:var(--aox-15px);
		font-size:var(--aox-fs-14px);
		}
	.section_product-detail .ctn_top-detail .col_image figure{
		margin-top:var(--aox-20px);
		}
	.section_product-detail .ctn_top-detail .col_image figure img{
		max-height:var(--aox-315px);
		}
	.section_product-detail .ctn_top-detail .ctn_desc-product,
	.section_product-detail .ctn_detail{
		margin-top:var(--aox-40px);
		}
	.section_product-detail .ctn_detail .ctn_desc-product{
		padding:0;
		}
	.section_product-detail .ctn_detail .ctn_desc-product.var_grey{
		padding-right:var(--aox-20px);
		padding-left:var(--aox-20px);
		}
	.section_product-detail .ctn_detail .ctn_desc-product.var_texture{
		padding-right:0;
		}
	.section_product-detail .ctn_detail .ctn_desc-product.var_texture > img{
		display:none;
		}
}
/* ======================================== */
/* .section_product-carousel
/* ======================================== */
.section_product-carousel{
	width:100%; max-width:var(--aox-1150px);
	margin:0 auto;
	}
.section_product-carousel .str_container{
	padding:var(--aox-100px) 0;
	border-top:1px solid #c8c8c8;
	}
.section_product-carousel h2{
	padding:0 var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	text-align:center;
	text-transform:uppercase;
	}
.section_product-carousel .obj_push-product{
	width:100%;
	}

@media screen and (max-width:1150px){
	.section_product-carousel{
		margin:0 var(--aox-20px);
		width:calc(100% - var(--aox-40px));
		}
}
@media screen and (max-width:767px){
	.section_product-carousel .str_container{
		padding:var(--aox-50px) 0;
		}
}
/* ======================================== */
/* .section_header-ingredients
/* ======================================== */
.section_header-ingredients{
	width:100%;
	margin-bottom:var(--aox-55px);
	}
.section_header-ingredients .str_container{
	max-width:var(--aox-1300px);
	}
/**/
.section_header-ingredients .ctn_header-ingredients{
	display:flex;
	flex-flow:row wrap;
	}
.section_header-ingredients .ctn_header-ingredients > div{
	width:calc(25% - var(--aox-20px));
	margin:0 var(--aox-10px);
	}
.section_header-ingredients .ctn_header-ingredients figure{
	position:relative;
	}
.section_header-ingredients .ctn_header-ingredients figure img{
	width:100%;
	}
.section_header-ingredients .ctn_header-ingredients figure figcaption{
	position:absolute;
	left:0; top:50%;
	width:100%;
	padding:0 var(--aox-20px);
	color:#fff;
	font-size:var(--aox-fs-22px); line-height:1.2;
	font-weight:500;
	text-align:center;
	text-transform:uppercase;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
/**/
.section_header-ingredients .ctn_expand-ingredients{
	width:100%; max-width:var(--aox-980px);
	padding:0 var(--aox-20px);
	margin:0 auto;
	text-align:center;
	}
.section_header-ingredients .ctn_expand-ingredients h2{
	padding:var(--aox-80px) 0 var(--aox-40px) 0;
	font-size:var(--aox-fs-18px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_header-ingredients .ctn_expand-ingredients .ctn_text{
	overflow:hidden;
	height:0;
	transition:all .5s ease;
	}
.section_header-ingredients .ctn_expand-ingredients p{
	padding-bottom:var(--aox-40px);
	font-size:var(--aox-fs-16px); line-height:1.875;
	}

@media screen and (max-width:1300px){
	.section_header-ingredients .ctn_header-ingredients figure figcaption{
		font-size:var(--aox-fs-22px);
		}
}
@media screen and (max-width:767px){
	.section_header-ingredients{
		margin-bottom:var(--aox-25px);
		}
	.section_header-ingredients .ctn_header-ingredients{
		padding:0 var(--aox-10px);
		}
	.section_header-ingredients .ctn_header-ingredients > div{
		width:calc(50% - var(--aox-20px));
		margin:var(--aox-10px);
		}
	.section_header-ingredients .ctn_header-ingredients figure figcaption{
		font-size:var(--aox-fs-16px);
		}
	.section_header-ingredients .ctn_expand-ingredients h2{
		padding-top: var(--aox-60px);
		font-size:var(--aox-fs-16px);
		}
}
@media screen and (max-width:375px){
	.section_header-ingredients .ctn_header-ingredients figure figcaption{
		font-size:var(--aox-fs-14px);
		}
}
@media screen and (max-width:320px){
	.section_header-ingredients .ctn_header-ingredients{
		padding:0;
		}
	.section_header-ingredients .ctn_header-ingredients figure figcaption{
		font-size:var(--aox-fs-12px);
		}
}
/* ======================================== */
/* .section_liste-ingredients
/* ======================================== */
.section_liste-ingredients{
	width:100%;
	margin-bottom:var(--aox-150px);
	}
.section_liste-ingredients .str_container{
	position:relative;
	max-width:var(--aox-1210px);
	padding:0 var(--aox-30px);
	}
/**/
.section_liste-ingredients .ctn_liste-ingredients + .ctn_liste-ingredients{
	margin-top:var(--aox-40px);
	}
/**/
.section_liste-ingredients .t_title-letter{
	font-size:var(--aox-fs-100px); line-height:1;
	font-weight:700;
	color:#f2f2f2;
	}
/**/
.section_liste-ingredients .obj_liste-ingredients{
	display:flex;
	flex-flow:row wrap;
	margin:0 var(--aox-30px);
	padding:var(--aox-45px) 0;
	border-bottom:1px solid #c3c3c3;
	}
.section_liste-ingredients .obj_liste-ingredients:first-of-type{
	padding-top:var(--aox-35px);
	}
.section_liste-ingredients .obj_liste-ingredients figure{
	width:25.7%;
	}
.section_liste-ingredients .obj_liste-ingredients figure img{
	width:100%;
	}
.section_liste-ingredients .obj_liste-ingredients .ctn_text{
	width:74.3%;
	padding-left:8.25%;
	}
.section_liste-ingredients .obj_liste-ingredients .ctn_text h2{
	margin-bottom:var(--aox-25px);
	font-size:var(--aox-fs-40px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.section_liste-ingredients .obj_liste-ingredients .ctn_text p{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-16px); line-height:1.875;
	}
/**/
.section_liste-ingredients .obj_nav-ingredients{
	position:-webkit-sticky;
	position:sticky;
	z-index:999;
	left:0; top:var(--aox-60px);
	display:flex;
	width:100%;
	max-width:var(--aox-1150px);
	margin-bottom:var(--aox-60px);
	border-bottom:1px solid #c3c3c3;
	background:#fff;
	}
.section_liste-ingredients .obj_nav-ingredients li{
	width:3.85%;
	text-align:center;
	}
.section_liste-ingredients .obj_nav-ingredients li button,
.section_liste-ingredients .obj_nav-ingredients li a{
	display:flex;
	justify-content:center;
	align-items:center;
	position:relative;
	width:100%;
	height:var(--aox-70px);
	font-size:var(--aox-fs-24px); line-height:1;
	font-weight:500;
	transition:none;
	}
.section_liste-ingredients .obj_nav-ingredients li button.current,
.section_liste-ingredients .obj_nav-ingredients li a.current{
	font-size:var(--aox-fs-34px);
	}
.section_liste-ingredients .obj_nav-ingredients li button.inactive,
.section_liste-ingredients .obj_nav-ingredients li a.inactive{
	color:#c3c3c3;
	cursor:default;
	}
.section_liste-ingredients .obj_nav-ingredients li button::before,
.section_liste-ingredients .obj_nav-ingredients li a::before{
	content:'';
	position:absolute;
	left:0; bottom:0;
	width:100%; height:3px;
	background:#000;
	opacity:0;
	transition:opacity .3s ease;
	}
.section_liste-ingredients .obj_nav-ingredients li button.current::before,
.section_liste-ingredients .obj_nav-ingredients li a.current::before{
	opacity:1;
	}
/**/
@media screen and (max-width:767px){
	.section_liste-ingredients{
		margin-bottom:var(--aox-50px);
		}
	.section_liste-ingredients .str_container{
		padding-left:12%;
		padding-right:12%;
		}
	.section_liste-ingredients .obj_liste-ingredients figure{
		width:100%;
		}
	.section_liste-ingredients .obj_liste-ingredients .ctn_text{
		width:100%;
		padding-left:0;
		}
	.section_liste-ingredients .obj_liste-ingredients{
		margin:0;
		}
	.section_liste-ingredients .t_title-letter{
		margin-left:calc(-1 * var(--aox-30px));
		font-size:var(--aox-fs-90px);
		}
	.section_liste-ingredients .obj_liste-ingredients .ctn_text h2{
		margin-top:var(--aox-30px);
		font-size:8vw;
		}
	.section_liste-ingredients .obj_liste-ingredients .ctn_text p{
		font-size:var(--aox-fs-15px);
		}
	.section_liste-ingredients .obj_nav-ingredients{
		position:relative;
		flex-flow:row wrap;
		top:0; left:calc(-1  * var(--aox-25px));
		width:calc(100% + var(--aox-50px));
		margin-bottom:var(--aox-35px);
		}
	.section_liste-ingredients .obj_nav-ingredients li{
		width:14%;
		}
	.section_liste-ingredients .obj_liste-ingredients .lazyload-placeholder{
		min-height:67.5vw;
		}
}
/* ======================================== */
/* .section_detail-ingredients
/* ======================================== */
.section_detail-ingredients{
	width:100%;
	}
.section_detail-ingredients .str_container{
	max-width:var(--aox-1150px);
	}
.section_detail-ingredients .str_container > div{
	margin-bottom:var(--aox-80px);
	}
/**/
.section_detail-ingredients h1{
	font-size:var(--aox-fs-50px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	}
.section_detail-ingredients h2{
	font-size:var(--aox-fs-24px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	}
.section_detail-ingredients h3{
	font-size:var(--aox-fs-18px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	}
.section_detail-ingredients p{
	font-size:var(--aox-fs-16px); line-height:1.875;
	}
/**/
.section_detail-ingredients .ctn_header-ingredients{
	display:flex;
	flex-flow:row wrap;
	padding-top:var(--aox-75px);
	}
.section_detail-ingredients .ctn_header-ingredients .ctn_image{
	width:40%;
	}
.section_detail-ingredients .ctn_header-ingredients .ctn_image img{
	width:100%;
	}
.section_detail-ingredients .ctn_header-ingredients .ctn_text{
	width:60%;
	padding:var(--aox-70px) 8%;
	}
.section_detail-ingredients .ctn_header-ingredients .ctn_text p{
	margin-top:var(--aox-25px);
	}
/**/
.section_detail-ingredients .ctn_proprietes-ingredients{
	padding:var(--aox-50px) var(--aox-30px);
	text-align:center;
	background:#f2f2f2;
	}
.section_detail-ingredients .ctn_proprietes-ingredients h2{
	margin-bottom:var(--aox-5px);
	}
.section_detail-ingredients .ctn_proprietes-ingredients p + h3,
.section_detail-ingredients .ctn_proprietes-ingredients h3 + p,
.section_detail-ingredients .ctn_proprietes-ingredients p + p{
	margin-top:var(--aox-35px);
	}
/**/
.section_detail-ingredients .ctn_identite-ingredients{
	padding:var(--aox-70px) var(--aox-90px) var(--aox-50px) var(--aox-90px);
	border:2px solid #d7d7d7;
	}
.section_detail-ingredients .ctn_identite-ingredients h2{
	margin-bottom:var(--aox-25px);
	}
/**/
.section_detail-ingredients .ctn_table-ingredients .ctn_row{
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-20px) var(--aox-30px);
	}
.section_detail-ingredients .ctn_table-ingredients .ctn_row + .ctn_row{
	border-top:1px solid #d7d7d7;
	}
.section_detail-ingredients .ctn_table-ingredients .ctn_row p{
	width:50%;
	}
/**/
.section_detail-ingredients .str_container > .ctn_bienfaits-ingredients{
	display:flex;
	flex-flow:row wrap;
	align-items:flex-start;
	margin-bottom:var(--aox-120px);
	}
.section_detail-ingredients .ctn_bienfaits-ingredients .ctn_col{
	width:60%;
	}
.section_detail-ingredients .ctn_bienfaits-ingredients .col_left{
	padding-right:var(--aox-90px);
	}
.section_detail-ingredients .ctn_bienfaits-ingredients .col_right{
	padding:var(--aox-70px) var(--aox-40px);
	width:40%;
	background:#000;
	}
.section_detail-ingredients .ctn_bienfaits-ingredients .col_right h2,
.section_detail-ingredients .ctn_bienfaits-ingredients .col_right h3,
.section_detail-ingredients .ctn_bienfaits-ingredients .col_right p{
	color:#fff;
	}
.section_detail-ingredients .ctn_bienfaits-ingredients h3,
.section_detail-ingredients .ctn_bienfaits-ingredients p{
	margin-top:var(--aox-35px);
	}
/**/
@media screen and (max-width:1150px){
	.section_detail-ingredients .str_container{
		padding:0 var(--aox-30px);
		}
	.section_detail-ingredients h1{
		font-size:var(--aox-fs-50px);
		}
}
@media screen and (max-width:768px){
	.section_detail-ingredients .str_container{
		padding:0 var(--aox-15px);
		}
}
@media screen and (max-width:767px){
	.section_detail-ingredients .str_container > div,
	.section_detail-ingredients .str_container > .ctn_bienfaits-ingredients{
		margin-bottom:var(--aox-50px);
		}
	.section_detail-ingredients h1{
		font-size:var(--aox-fs-32px);
		}
	.section_detail-ingredients .ctn_header-ingredients{
		padding:var(--aox-30px) 0 0 0;
		}
	.section_detail-ingredients .ctn_header-ingredients .ctn_image{
		width:100%;
		}
	.section_detail-ingredients .ctn_header-ingredients .ctn_text{
		width:100%;
		padding:var(--aox-30px) 0 0 0;
		}
	.section_detail-ingredients .ctn_identite-ingredients{
		padding:var(--aox-50px) var(--aox-25px) var(--aox-30px) var(--aox-25px);
		}
	.section_detail-ingredients .ctn_identite-ingredients h2{
		margin-bottom:var(--aox-20px);
		}
	.section_detail-ingredients .ctn_table-ingredients .ctn_row{
		padding:var(--aox-20px) 0;
		}
	.section_detail-ingredients .ctn_table-ingredients .ctn_row p{
		width:100%;
		}
	.section_detail-ingredients .ctn_bienfaits-ingredients{
		margin:var(--aox-50px) 0;
		}
	.section_detail-ingredients .ctn_bienfaits-ingredients .col_left{
		order:2;
		width:100%;
		padding-right:0;
		margin-top:var(--aox-50px);
		}
	.section_detail-ingredients .ctn_bienfaits-ingredients .col_right{
		width:100%;
		padding:var(--aox-40px) var(--aox-25px);
		}
}
/* ======================================== */
/* .section_compte
/* ======================================== */
.section_compte{
	width:100%;
	}
/**/
.section_compte .ctn_top{
	padding-top:var(--aox-60px);
	}
.section_compte .ctn_top h1{
	padding-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:1.2;
	border-bottom:1px solid #cbcbcb;
	}
.section_compte .ctn_top h2{
	margin-top:var(--aox-70px);
	margin-bottom:var(--aox-5px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_compte .ctn_top p{
	font-size:var(--aox-fs-16px); line-height:1.6;
	}
/**/
.section_compte .ctn_dashboard{
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;
	padding:var(--aox-100px) 0 var(--aox-200px) 0;
	}
.section_compte .ctn_dashboard .ctn_col{
	position:relative;
	width: 35%;
    max-width: var(--aox-440px);
	/* padding:20px 20px 50px 20px; */
	}
.section_compte .ctn_dashboard .ctn_col.col_percent{
	border-right:1px solid rgba(112,112,112,0.3);
	}
.section_compte .ctn_dashboard .col_info-perso figure{
	margin:0 auto;
	width:100%; max-width:var(--aox-255px);
	border-radius:50%;
	overflow:hidden;
	}
.section_compte .ctn_dashboard .col_info-perso img{
	max-width:100%;
	}
.section_compte .ctn_dashboard .col_percent{
	width: 65%;
    max-width: var(--aox-840px);
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.section_compte .ctn_dashboard .col_expert{
	text-align:center;
	padding: 0 var(--aox-20px);
	}
.section_compte .ctn_dashboard .col_expert h3{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-24px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.section_compte .ctn_dashboard .col_expert h4{
	margin-bottom:var(--aox-25px);
	font-size:var(--aox-fs-16px); line-height:1.2;
	font-weight:700;
	}
.section_compte .ctn_dashboard .col_expert ul li{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-16px); line-height:1.2;
	text-decoration:underline;
	}
.section_compte .ctn_dashboard .ctn_col .ctn_cta{
	position:absolute;
	z-index:3;
	left: 50%; top: 50%;
    width: 106%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
	}
.section_compte .ctn_dashboard .ctn_col .ctn_cta .obj_cta{
	display:flex;
	justify-content:center;
	align-items:center;
	padding: var(--aox-10px) var(--aox-10px);
	height:var(--aox-60px);
	margin:0 auto;
	text-align:center;
	}


/***/

.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise .ctn_cta{
	opacity: 0;
	}
.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise .ctn_cta .obj_cta{
	font-size:var(--aox-fs-12px);
    line-height: 1;
	}
.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise.hover .ctn_cta,
.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise:hover .ctn_cta{
	opacity: 1;
	}




/**/
.section_compte .ctn_list-vip-expert{
	position:relative;
	display: flex;
	max-width: var(--aox-400px);
	margin: 0 0 0 auto;
	flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
	}
.section_compte .ctn_list-vip-expert .item_vip-expert{
	position:relative;
	width: 30%;
    max-width: var(--aox-120px);
	margin-top: var(--aox-20px);
	}
.section_compte .ctn_list-vip-expert .item_vip-expert .ctn_logo{
	position:relative;
	}
.section_compte .ctn_list-vip-expert .item_vip-expert .ctn_logo img{
	position:relative;
	width: 100%;
	}
.section_compte .ctn_list-vip-expert .item_vip-expert .t_title{
	margin-top: var(--aox-10px);
    font-size:var(--aox-fs-12px);
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
	}



/**/
.section_compte .obj_star-score{
	position:absolute;
	left:0; top:var(--aox-120px);
	width:100%;
	}
/**/
.section_compte .ctn_progress-bar-expertise{
	position: relative;
    width: var(--aox-390px);
    padding: 0 var(--aox-50px);
	}

/**/

@media screen and (min-width:768px) and (max-width:1280px){

	.section_compte .obj_circle-progress-expertise {
		width: 50%;
		padding: 0 var(--aox-30px);
		}
	.section_compte .ctn_progress-bar-expertise {
		width: 50%;
		padding: 0 var(--aox-30px);
		}
	.section_compte .obj_circle-progress-expertise .ctn_avatar {
		width: var(--aox-72px);
		height: var(--aox-72px);
		}
}

@media screen and (max-width:1280px){
	/* .section_compte .ctn_top{
		padding-left:20px;
		padding-right:20px;
		} */
	.section_compte .ctn_expert-score .bg_expert-score{
		width:0 !important;
		}
}
@media screen and (max-width:1024px){
	.section_compte .obj_circle-progress .ctn_txt{
		font-size:var(--aox-fs-60px);
		}
	.section_compte .ctn_expert-score .v_logo{
		-webkit-transform:translate(-50%,-50%) scale(0.2);
		        transform:translate(-50%,-50%) scale(0.2);
		}
	.section_compte .ctn_list-vip-expert .item_vip-expert .t_title{
		font-size:var(--aox-fs-10px);
		}
}
@media screen and (max-width:768px){
	.section_compte .ctn_dashboard{
		padding:0;
		}
	.section_compte .ctn_dashboard .ctn_col{
		width:100%;
		padding:var(--aox-50px) 0;
		margin:0 var(--aox-20px);
		flex-direction: column;
		justify-content: flex-start;
		}
	.section_compte .ctn_dashboard .ctn_col + .ctn_col{
		border-left:none;
		border-top:2px solid #dcdcdc;
		}
	.section_compte .ctn_dashboard .ctn_col .ctn_cta{
		position:relative;
		left:auto; bottom:auto;
		-webkit-transform:translateY(0);
		        transform:translateY(0);
		}
	.section_compte .ctn_dashboard .ctn_col.col_info-perso .ctn_cta{
		margin-top:var(--aox-50px);
		}
	.section_compte .ctn_expert-score{
		margin-bottom:var(--aox-120px);
		padding-bottom:0;
		}
	.section_compte .ctn_expert-score .list_expert-score{
		flex-direction:column;
		padding-top:0;
		}
	.section_compte .ctn_expert-score .item_expert-score{
		margin:0 var(--aox-65px);
		padding:var(--aox-40px) 0;
		width:calc(100% - var(--aox-130px));
		}
	.section_compte .ctn_expert-score .v_logo{
		-webkit-transform:translate(-50%,-50%) scale(0.5);
		        transform:translate(-50%,-50%) scale(0.5);
		}
	.section_compte .ctn_expert-score .item_expert-score + .item_expert-score{
		border-top:1px solid #c3c3c3;
		}
	.section_compte .ctn_expert-score .item_expert-score + .item_expert-score::before{
		display:none;
		}
	.section_compte .ctn_expert-score .t_percent{
		position:relative;
		left:auto; top:auto;
		font-size:var(--aox-fs-32px);
		margin-top:var(--aox-20px);
		}
	.section_compte .obj_star-score{
		position:relative;
		left:auto; top:auto;
		margin-top:var(--aox-20px);
		}
	.section_compte .obj_star-score .t_status{
		font-size:var(--aox-fs-18px);
		margin-top:calc(-1 * var(--aox-10px));
		}
	.section_compte .ctn_dashboard .ctn_col.col_percent {
		border-right: none;
		}
	.section_compte .obj_circle-progress-expertise {
		width: var(--aox-320px);
		height: var(--aox-320px);
		}
	.section_compte .ctn_progress-bar-expertise {
		width: 100%;
		padding: 0;
		margin-top: var(--aox-30px);
		}
	.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise .ctn_cta {
		opacity: 1;
		width: 100%;
		-webkit-transform: none;
		        transform: none;
		margin-top: var(--aox-10px);
		}
	.section_compte .ctn_dashboard .ctn_col .obj_progress-bar-expertise .ctn_cta .obj_cta {
		font-size:var(--aox-fs-10px); line-height: 1;
		padding: var(--aox-10px) var(--aox-10px);
		height: var(--aox-40px);
		}
}
@media screen and (max-width:767px){
	.section_compte .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_compte .ctn_top{
		padding-top:var(--aox-40px);
		}
	.section_compte .ctn_top h1{
		font-size:var(--aox-fs-40px);
		}
	.section_compte .ctn_top h2{
		margin-top:var(--aox-40px);
		font-size:var(--aox-fs-17px);
		}
	.section_compte .ctn_dashboard .ctn_col .ctn_cta .obj_cta{
		max-width:80%;
		}
	.section_compte .ctn_expert-score .item_expert-score{
		margin:0 15.7%;
		width:68.6%;
		}
}

/* ======================================== */
/* .section_formation-dashboard
/* ======================================== */
.section_formation-dashboard{
	width:100%;
	overflow:hidden;
	}
.section_formation-dashboard .str_container{
	max-width:var(--aox-1150px);
	}
/**/
.section_formation-dashboard .ctn_formation-dashboard{
	padding:var(--aox-30px) var(--aox-55px);
	background:#f2f2f2;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil{
	position: relative;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_avatar {
	position: relative;
	width: var(--aox-80px);
	height: var(--aox-80px);
	}
.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_avatar figure {
	margin: 0 auto;
	width: 100%;
	max-width: 100%;
	border-radius: 50%;
	overflow: hidden;
	}
.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_avatar figure img {
	max-width: 100%;
	}

.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_formation-dashboard-expertise {
	position: relative;
    margin-left: var(--aox-30px);
    width: var(--aox-460px);
	}

.section_formation-dashboard .ctn_formation-dashboard h2{
	position: relative;
    z-index: 2;
    font-size:var(--aox-fs-12px);
    line-height: 1.2;
    text-align: left;
    text-transform: uppercase;
	}
.section_formation-dashboard .ctn_formation-dashboard .t_status{
	font-size:var(--aox-fs-28px);
    line-height: 1.2;
    text-align: left;
    text-transform: uppercase;
	margin-bottom: var(--aox-8px);
	}
/**/
.section_formation-dashboard .ctn_formation-popin{
	text-align:center;
	}
/**/
@media screen and (max-width:320px){
	.section_formation-dashboard .ctn_formation-dashboard .obj_circle-progress .obj_star-score{
		-webkit-transform:translate(-50%,-50%) scale(0.75);
		        transform:translate(-50%,-50%) scale(0.75);
		}
}

@media screen and (max-width:768px){
	.section_formation-dashboard{
		margin-left: calc(-1 * var(--aox-gutter));
		width: calc(100% + var(--aox-gutter) + var(--aox-gutter));
		}
	.section_formation-dashboard .ctn_formation-dashboard{
		padding: var(--aox-20px) var(--aox-20px);
		flex-direction: column;
		}
	.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_avatar {
		width: var(--aox-60px);
		height: var(--aox-60px);
		}
	.section_formation-dashboard .ctn_formation-dashboard .ctn_formation-dashboard-profil .ctn_formation-dashboard-expertise {
		margin-left: var(--aox-20px);
		width: calc(100% - var(--aox-80px));
		}
	.section_formation-dashboard .ctn_formation-dashboard .t_status {
		font-size:var(--aox-fs-20px);
		}
}
/* ======================================== */
/* .section_resultat
/* ======================================== */
.section_resultat{
	width:100%;
	}
.section_resultat .str_container{
	max-width:var(--aox-960px);
	}
/**/
.section_resultat .ctn_resultat{
	margin-top:var(--aox-60px);
	}
.section_resultat .ctn_resultat .ctn_top{
	padding:0 var(--aox-30px);
	text-align:center;
	}
.section_resultat .ctn_resultat .ctn_top h2{
	font-size:var(--aox-fs-20px); line-height:1.2;
	text-transform:uppercase;
	}
.section_resultat .ctn_resultat .ctn_top h2 strong{
	font-weight:700;
	}
.section_resultat .ctn_resultat .ctn_top p{
	margin-top:var(--aox-10px);
	font-size:var(--aox-fs-16px); line-height:1.2;
	}
.section_resultat .ctn_resultat.var_gift{
	margin-top:var(--aox-10px);
	}
.section_resultat .ctn_resultat.var_gift .ctn_top{
	display:flex;
	align-items:center;
	padding:0;
	text-align:left;
	}
.section_resultat .ctn_resultat.var_gift .ctn_top figure{
	position:relative;
	z-index:1;
	width:30%;
	}
.section_resultat .ctn_resultat.var_gift .ctn_top figure img{
	max-width:100%;
	}
.section_resultat .ctn_resultat.var_gift .ctn_top > div{
	position:relative;
	z-index:2;
	width:70%;
	padding-right:var(--aox-30px);
	}
.section_resultat .ctn_resultat.var_gift .ctn_table{
	margin-top:0;
	}
.section_resultat .ctn_resultat .ctn_table{
	padding:0;
	}
.section_resultat .ctn_resultat .ctn_table table{
	width:100%;
	counter-reset:result-row -1;
	}
.section_resultat .ctn_resultat .ctn_table table tr:nth-child(odd){
	background:#f2f2f2;
	}
.section_resultat .ctn_resultat .ctn_table table tr:first-of-type td:first-of-type::before{
	opacity:0;
	}
.section_resultat .ctn_resultat .ctn_table table td{
	position:relative;
	padding:var(--aox-15px);
	font-size:var(--aox-fs-16px);
	line-height:1.2;
	border:none;
	}
.section_resultat .ctn_resultat .ctn_table table td:nth-child(1){
	width:27%;
	padding-left:10%;
	}
.section_resultat .ctn_resultat .ctn_table table td:nth-child(1)::before{
	counter-increment:result-row;
	content:counter(result-row);
	position:absolute;
	left:11.5%; top:var(--aox-15px);
	font-weight:700;
	}
.section_resultat .ctn_resultat .ctn_table table td:nth-child(2){
	width:42%;
	}
.section_resultat .ctn_resultat .ctn_table table td:nth-child(3){
	width:31%;
	}
@media screen and (max-width:767px){
	.section_resultat .ctn_resultat{
		margin-top:var(--aox-40px);
		}
	.section_resultat .ctn_resultat.var_gift{
		margin-top:var(--aox-30px);
		}
	.section_resultat .ctn_resultat .ctn_table table td{
		padding:var(--aox-15px) var(--aox-10px);
		font-size:var(--aox-fs-13px);
		}
	.section_resultat .ctn_resultat.var_gift .ctn_top{
		padding:var(--aox-10px) 0 var(--aox-40px) 0;
		}
	.section_resultat .ctn_resultat.var_gift .ctn_top figure img{
		-webkit-transform:scale(1.6);
		        transform:scale(1.6);
		}
}
/* ======================================== */
/* .section_galerie-photo_intro
/* ======================================== */
.section_galerie-photo_intro{
	width:100%;
	}
.section_galerie-photo_intro .str_container{
	max-width:var(--aox-1150px);
	}
/**/
.section_galerie-photo_intro .ctn_galerie-photo{
	padding:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	}
.section_galerie-photo_intro .ctn_galerie-photo h2{
	font-size:var(--aox-fs-20px); line-height:1.2;
	text-align:center;
	}
.section_galerie-photo_intro .ctn_galerie-photo h2 strong{
	font-weight:700;
	}
.section_galerie-photo_intro .ctn_galerie-photo p img{
	display:inline-block;
	margin:var(--aox-40px) 0;
	}
.section_galerie-photo_intro .ctn_galerie-photo figure{
	display:flex;
	justify-content:center;
	padding:var(--aox-40px) 0;
	}
.section_galerie-photo_intro .ctn_galerie-photo figure figcaption{
	padding-top:var(--aox-50px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	text-align:right;
	}
.section_galerie-photo_intro .ctn_galerie-photo figure figcaption strong{
	font-weight:700;
	}
.section_galerie-photo_intro .ctn_galerie-photo .ctn_cta{
	text-align:center;
	}
.section_galerie-photo_intro .ctn_galerie-photo h2 + .ctn_cta,
.section_galerie-photo_intro .ctn_galerie-photo .ctn_cta + .ctn_cta{
	margin-top:var(--aox-30px);
	}
@media screen and (max-width:767px){
	.section_galerie-photo_intro .ctn_galerie-photo h2 br{
		display:none;
		}
	.section_galerie-photo_intro .ctn_galerie-photo figure figcaption{
		font-size:4vw;
		}
	.section_galerie-photo_intro .ctn_galerie-photo figure img{
		width:58vw;
		}
	.section_galerie-photo_intro .ctn_galerie-photo p img{
		width:80vw !important; height:auto !important;
		}
}
/* ======================================== */
/* .section_galerie-photo_liste
/* ======================================== */
.section_galerie-photo_liste{
	width:100%;
	}
.section_galerie-photo_liste .str_container{
	position:relative;
	max-width:var(--aox-1220px);
	margin-top:var(--aox-40px);
	padding-top:var(--aox-80px);
	}
.section_galerie-photo_liste .str_container::before{
	content:'';
	position:absolute;
	left:50%; top:0;
	width:100%; max-width:var(--aox-1150px);
	height:1px;
	background:#c3c3c3;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.section_galerie-photo_liste .ctn_galerie-photo_liste{
	display:flex;
	flex-wrap:wrap;
	}
@media screen and (max-width:767px){
	.section_galerie-photo_liste .str_container{
		padding-top:var(--aox-60px);
		}
}
/* ======================================== */
/* .section_marques
/* ======================================== */
.section_marques{
	width:100%;
	}
/**/
.section_marques .ctn_top{
	padding-top:var(--aox-60px);
	}
.section_marques .ctn_top h1{
	padding-bottom:var(--aox-20px);
	font-size:var(--aox-fs-60px); line-height:1.2;
	border-bottom:1px solid #cbcbcb;
	}
/**/
.section_marques .ctn_list-marques{
	display:flex;
	flex-wrap:wrap;
	width:100%;
	max-width:calc(var(--aox-1120px) + var(--aox-5px));
	margin:var(--aox-100px) auto;
	}
.section_marques .ctn_item-marques{
	margin:0 1.3%;
	width:30.7%;
	}
.section_marques .ctn_item-marques strong{
	display:block;
	padding:var(--aox-25px) 0 var(--aox-50px) 0;
	font-size:var(--aox-fs-22px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	text-align:center;
	}
.section_marques .ctn_item-marques figure{
	overflow:hidden;
	}
.section_marques .ctn_item-marques figure img{
	width:100%;
	transition:all .3s ease;
	}
.section_marques .ctn_item-marques a:hover figure img{
	-webkit-transform:scale(1.1);
	        transform:scale(1.1);
	}

@media screen and (max-width:1280px){
	.section_marques .ctn_top{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
}
@media screen and (max-width:767px){
	.section_marques .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_marques .ctn_top{
		padding-top:var(--aox-40px);
		}
	.section_marques .ctn_top h1{
		font-size:var(--aox-fs-40px);
		}
	.section_marques .ctn_list-marques{
		margin:var(--aox-50px) auto;
		}
	.section_marques .ctn_item-marques{
		margin:0 5%;
		width:90%;
		}
	.section_marques .ctn_item-marques strong{
		font-size:var(--aox-fs-18px);
		}
}
/* ======================================== */
/* .section_no-acces
/* ======================================== */
.section_no-acces{
	width:100%;
	}
.section_no-acces .str_container{
	max-width:var(--aox-960px);
	}
/**/
.section_no-acces .ctn_main{
	padding:var(--aox-100px) 0 var(--aox-150px);
	}
/**/
.section_no-acces .ctn_paragraph{
	padding: var(--aox-80px) var(--aox-20px) 0 var(--aox-20px);
    margin: calc(-1 * var(--aox-20px)) auto 0 auto;
    max-width: var(--aox-560px);
	}
.section_no-acces .ctn_paragraph h2{
	font-weight:700;
	font-size:var(--aox-fs-20px); line-height:1.2;
	}
.section_no-acces .ctn_paragraph p{
	font-size:var(--aox-fs-14px); line-height:1.429;
	}
.section_no-acces .ctn_paragraph.var_align-center{
	text-align:center;
	}
.section_no-acces .ctn_paragraph h2 + p{
	margin-top:var(--aox-10px);
	}
.section_no-acces .ctn_paragraph p + .ctn_cta{
	margin-top:var(--aox-30px);
	}
.section_no-acces .ctn_paragraph p strong{
	font-weight:700;
	}
.section_no-acces .ctn_paragraph p a{
	font-weight:700;
	text-decoration:underline;
	}

/**/

@media screen and (max-width:767px){
	.section_no-acces .str_container{
		max-width:calc(var(--aox-410px) + var(--aox-4px));
		}
	.section_no-acces .ctn_main{
		padding: var(--aox-70px) 0;
		}
	.section_no-acces .ctn_paragraph{
		padding: var(--aox-50px) var(--aox-20px) 0 var(--aox-20px);
		margin: 0 auto;
		}
}
/* ======================================== */
/* .section_maintenance
/* ======================================== */
/* .section_maintenance{
	position:absolute;
	left:50%; top:50%;
	width:100%;
	padding:0 var(--aox-20px);
    margin:0 auto;
    max-width:var(--aox-700px);
	text-align:center;
	transform:translate(-50%,-50%);
	}
.section_maintenance .v_logo{
    margin:0 auto;
    max-width:var(--aox-450px);
	}
.section_maintenance .v_picto{
	margin-top:var(--aox-60px);
	}
.section_maintenance h1{
	margin-top:var(--aox-60px);
	font-weight:700;
	font-size:var(--aox-fs-20px); line-height:1.2;
	}
.section_maintenance p{
	font-size:var(--aox-fs-20px); line-height:1.4;
	}
.section_maintenance p + p{
	margin-top:var(--aox-35px);
	}

@media screen and (max-width:767px){
	.section_maintenance .v_logo{
		max-width:var(--aox-320px);
		}
	.section_maintenance .v_picto{
		margin-top:var(--aox-30px);
		width:16.5vw; height:16vw;
		}
	.section_maintenance h1{
		margin-top:var(--aox-30px);
		font-size:var(--aox-fs-16px);
		}
	.section_maintenance p{
		font-size:var(--aox-fs-16px);
		}
	.section_maintenance p + p{
		margin-top:var(--aox-20px);
		}
} */


.obj_section.section_maintenance{
	display: flex;
    background-color: unset;
    flex-direction: column;
    align-items: center;
	padding: 0;
	margin-bottom: var(--aox-30px);
	}

.section_maintenance .obj_logo-old{
	width: var(--aox-235px);
	margin-bottom: var(--aox-30px);
	}
.obj_section.section_maintenance .t_title {
	font-weight: 400;
	font-size: var(--aox-fs-32px);
	line-height: var(--aox-lh-120);
	max-width: var(--aox-920px);
	text-align: center;
	}
.obj_section.section_maintenance .t_desc {
	font-weight: 400;
	font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-150);
	margin-top: var(--aox-50px);
	width: var(--aox-col-10);
	text-align: center;
	}
.section_maintenance .obj_logo{
	width: var(--aox-390px);
	margin-top: var(--aox-20px);
	}
.section_maintenance .obj_logo img{
	width: 100%;
	}
	
.section_maintenance .obj_bloc_edito.edito_text{
	width: var(--aox-col-8);
	margin-top: var(--aox-40px);
	}
.section_maintenance .obj_bloc_edito + .obj_bloc_edito{
	margin-top: var(--aox-20px);
	}
.section_maintenance .obj_bloc_edito.edito_text.has_background{
	background-color: #FFFFFF;
	padding: var(--aox-15px) 0 var(--aox-30px);
	}
.section_maintenance .obj_bloc_edito .ctn_text{
	display: flex;
    flex-direction: column;
    align-items: center;
	width: var(--aox-col-6);
	margin: 0 auto;
	}
.section_maintenance .obj_bloc_edito .ctn_text .ctn_icon{
	width: var(--aox-80px); height: auto;
	}
.section_maintenance .obj_bloc_edito .ctn_text .ctn_icon img{
	width: 100%;
	}
.section_maintenance .obj_bloc_edito .ctn_text .t_desc {
	max-width: 100%;
    margin-top: 0;
    width: 100%;
	}

@media screen and (max-width:767px){
	
	.obj_section.section_maintenance{
		padding: var(--aox-50px) var(--aox-10px) 0;		
		}
	.section_maintenance .obj_logo-old {
		width: var(--aox-180px);
		margin-bottom: var(--aox-20px);
		}
	.obj_section.section_maintenance .t_title {
		font-size: var(--aox-fs-20px);
    	line-height: var(--aox-lh-130);
		max-width: 100%;
		}
	.obj_section.section_maintenance .t_desc {
		font-size: var(--aox-fs-16px);
		line-height: var(--aox-lh-140);
		max-width: 100%;
		width: 100%;
		}
	.section_maintenance .obj_logo {
		width: var(--aox-280px);
		margin-top: var(--aox-20px);
		}
	.section_maintenance .obj_bloc_edito.edito_text.has_background {
		width: 100%;
		margin-top: var(--aox-30px);
		}
	.section_maintenance .obj_bloc_edito .ctn_text {
		width: 100%;
		padding: 0 var(--aox-30px);
		}
	.section_maintenance .obj_bloc_edito .ctn_text .ctn_icon {
		width: var(--aox-60px);
		}
	.section_maintenance .obj_bloc_edito .ctn_text .t_desc {
		font-size: var(--aox-fs-14px);
		line-height: var(--aox-lh-140);
		}
		
}
/* ======================================== */
/* .section_tableau_recompenses
/* ======================================== */


@media screen and (max-width:767px){

	.section_tableau_recompenses .ctn_title .t_subtitle{
		font-size: var(--aox-fs-16px);
		text-align: center;
		}

}
/* ======================================== */
/* .section_mini_parcours
/* ======================================== */

.section_mini_parcours{
	max-width: var(--aox-1050px);
    margin: 0 auto;
	}
.section_mini_parcours.obj_section .ctn_section {
	width: 100%;
	margin: 0 auto;
	}
.section_mini_parcours.obj_section .ctn_title {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}
.section_mini_parcours.obj_section .ctn_title .t_subtitle {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}

@media screen and (max-width:767px){

	.section_mini_parcours.obj_section{
		padding: var(--aox-25px) 0 var(--aox-30px) 0;
		}
	.section_mini_parcours.obj_section .ctn_section {
		width: var(--aox-grid);
		}
	.section_mini_parcours.obj_section .ctn_section .ctn_title{
		max-width: 80%;
		}
	.obj_section.section_mini_parcours.obj_section .ctn_title {
		margin-bottom: var(--aox-15px);
		}


}
/* ======================================== */
/* .section_challenges
/* ======================================== */

.section_challenges{
	width: var(--aox-grid);
    margin: 0 auto;
	}
.section_challenges.obj_section .ctn_section {
	width: 100%;
	margin: 0 auto;
	}
.section_challenges.obj_section .ctn_title {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}
.section_challenges.obj_section .ctn_title .t_subtitle {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}

.section_challenges.obj_section .ctn_row {
	display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
	}


.section_challenges.obj_section .ctn_row > .ctn_filter{
	width: var(--aox-220px);
	}

.section_challenges.obj_section .ctn_row > .ctn_list_challenge{
	width: var(--aox-620px);
	margin-left: var(--aox-col-1);
	}
.section_challenges .ctn_list_challenge > .ctn_cta{
	text-align: center;
	margin-top: var(--aox-40px);
	}

.section_challenges.obj_section .ctn_row > .ctn_aside{
	width: var(--aox-330px);
	margin-left: var(--aox-40px);
	}


@media screen and (max-width:767px){

	.section_challenges.obj_section .ctn_section {
		width: var(--aox-grid);   
		}
	.section_challenges.obj_section .ctn_section .ctn_title{
		max-width: 80%;
		}
	.section_challenges.obj_section .ctn_row {
		position: relative;
		}
	.section_challenges.obj_section .ctn_row > .ctn_filter{
		width: 100%;
		order: 1;
		}
	
	.section_challenges.obj_section .ctn_row > .ctn_list_challenge{
		width: 100%;
		order: 3;
		}
	.section_challenges.obj_section .ctn_row > .ctn_list_challenge {
		margin-top: var(--aox-30px);
		margin-left: 0;
		}

}
/* ======================================== */
/* .section_challenges-detail
/* ======================================== */

.section_challenges-detail{
	width: var(--aox-grid);
    margin: 0 auto;
	}
.section_challenges-detail.obj_section .ctn_section {
	width: 100%;
	margin: 0 auto;
	}
.section_challenges-detail.obj_section .ctn_title {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}
.section_challenges-detail.obj_section .ctn_title .t_subtitle {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}

.section_challenges-detail.obj_section .ctn_row {
	display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
	}


.section_challenges-detail.obj_section .ctn_row > .ctn_filter{
	width: var(--aox-220px);
	}

.section_challenges-detail.obj_section .ctn_row > .ctn_list_challenge{
	width: var(--aox-620px);
	margin-left: var(--aox-col-1);
	}

.section_challenges-detail.obj_section .ctn_row > .ctn_aside{
	width: var(--aox-330px);
	margin-left: var(--aox-40px);
	}


@media screen and (max-width:767px){

	.section_challenges-detail.obj_section .ctn_section {
		width: var(--aox-grid);   
		}
	.section_challenges-detail.obj_section .ctn_section > .ctn_title {
		margin-bottom: var(--aox-20px);
		}
	.section_challenges-detail.obj_section .ctn_row > .ctn_filter{
		width: 100%;
		order: 1;
		}
	
	.section_challenges-detail.obj_section .ctn_row > .ctn_list_challenge{
		width: 100%;
		order: 3;
		}
	
	.section_challenges-detail.obj_section .ctn_row > .ctn_aside{
		width: 100%;
		order: 2;
		margin-top: var(--aox-20px);
		margin-left: calc(-1 * var(--aox-gutter));
		width: 100vw;
		margin-right: calc(-1 * var(--aox-gutter));
		}

	.section_challenges-detail.obj_section .ctn_row > .ctn_list_challenge {
		margin-top: var(--aox-30px);
		}

}
/* ======================================== */
/* .section_formation
/* ======================================== */

.section_formation{
	width: var(--aox-grid);
    margin: 0 auto;
	}
.section_formation.obj_section .ctn_section {
	width: 100%;
	margin: 0 auto;
	}
.section_formation.obj_section .ctn_title {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}
.section_formation.obj_section .ctn_title .t_subtitle {
	margin-bottom: var(--aox-15px);
	font-size: var(--aox-fs-20px);
	}

.section_formation.obj_section .ctn_row {
	display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
	}

.section_formation.obj_section .ctn_row > .ctn_filter{
	width: var(--aox-220px);
	}

.section_formation.obj_section .ctn_row > .ctn_list_formation{
	width: var(--aox-620px);
	margin-left: var(--aox-col-1);
	margin-right: auto;
	}
.section_formation .ctn_list_formation > .ctn_cta{
	text-align: center;
	margin-top: var(--aox-40px);
	}

.section_formation.obj_section .ctn_row > .ctn_aside{
	width: var(--aox-330px);
	margin-left: var(--aox-40px);
	}


@media screen and (max-width:767px){

	.section_formation.obj_section .ctn_section {
		width: var(--aox-grid);   
		}
	.section_formation.obj_section .ctn_section .ctn_title{
		max-width: 80%;
		}
	.section_formation.obj_section .ctn_row {
		position: relative;
		max-width: var(--aox-grid);
		margin: 0 auto;
		}
	.section_formation.obj_section .ctn_row > .ctn_filter{
		width: 100%;
		order: 1;
		max-width: var(--aox-grid);
		}
	
	.section_formation.obj_section .ctn_row > .ctn_list_formation{
		width: 100%;
		order: 3;
		}
	.section_formation.obj_section .ctn_row > .ctn_list_formation {
		margin-top: var(--aox-30px);
		margin-left: 0;
		}

}
/* ======================================== */
/* .section_formation_list
/* ======================================== */

.section_formation_list .ctn_row{
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
	}
.section_formation_list.obj_section .obj_formation {
	width: var(--aox-col-6);
	}
.section_formation_list.obj_section .ctn_title {
	margin-bottom: var(--aox-15px);
	}


@media screen and (max-width:767px){

	.section_formation_list.obj_section .obj_formation,
	.section_formation_list.obj_section .obj_formation + .obj_formation {
		margin-top: var(--aox-30px);
		margin-bottom: 0;
		}
	.section_formation_list.obj_section .obj_formation:first-child{
		margin-top: var(--aox-15px);
		margin-bottom: 0;
		}

}
/* ======================================== */
/* .section_formation_detail
/* ======================================== */

.section_formation_detail.obj_section {
	position: relative;
	}

@media screen and (max-width:767px){
	
	.section_formation_detail.obj_section .ctn_title .t_title {
		width: var(--aox-col-4);
		}


}
/* ======================================== */
/* .section_push_brand_home
/* ======================================== */

.section_push_brand_home.obj_section {
	padding: var(--aox-40px);
    /* background: #F2F2F2; */
	background: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
	}
.section_hero.obj_section + .section_push_brand_home.obj_section {
	padding: var(--aox-10px) 0 var(--aox-70px) 0;
	}
.section_push_brand_home.obj_section .ctn_section{
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: var(--aox-1140px);
    -webkit-animation: effectHeroEntry 1s ease 2500ms forwards;
            animation: effectHeroEntry 1s ease 2500ms forwards;
    opacity: 0;
	}
.section_push_brand_home.obj_section .t_title {
	font-size: var(--aox-fs-16px);
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	margin: var(--aox-50px) 0 var(--aox-30px);
	color: white;
	padding: 0 var(--aox-30px);
	}

.section_push_brand_home.obj_section .ctn_background img{
	width: 100%;
	}

@media screen and (max-width:767px){
	
	.section_push_brand_home.obj_section {
		left: calc(-1 * var(--aox-external-gutter));
		width: 100vw;
		}
	.section_push_brand_home.obj_section .ctn_section{
		width: 100vw;
    	height: auto;
		}
	.section_push_brand_home.obj_section .t_title {
		font-size: var(--aox-fs-14px);
    	width: var(--aox-grid);
		margin: var(--aox-30px) 0 var(--aox-20px);
		}
	.section_hero.obj_section + .section_push_brand_home.obj_section {
		padding: var(--aox-10px) 0 0 0;
		}

}
/* ======================================== */
/* .section_hero
/* ======================================== */

.section_hero.obj_section {
	opacity: 1;
    padding: 0;
    /* height: var(--aox-910px); */
    overflow: visible;
    background: rgb(235,235,235);
	}


.section_hero .ctn_background {
	position: fixed;
    z-index: 0;
    opacity: 1;
    top: -20px; left: 0;
    height: 104%; width: 100vw;
    transition: all 0.2s ease-in-out;
	}
.section_hero .ctn_background img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: top center;
	}
.section_hero.obj_section .ctn_section{
	position: relative;
    padding: var(--aox-120px) 0 var(--aox-40px);
    z-index: 2;
    display: flex;
    flex-direction: column;
    width: var(--aox-1140px);
	}
.section_hero.obj_section .t_title {
	font-size: var(--aox-fs-16px);
	font-weight: 700;
	text-transform: uppercase;
	}


/* is_blur */
.section_hero.is_blur .ctn_background{
	/* Add the blur effect */
	filter: blur(10px);
	-webkit-filter: blur(10px);
	transition:all 1.2s ease-in-out;
	}

@media screen and (max-width:767px){
	
	.section_hero.obj_section {
		height: auto;
		}
	.section_hero.obj_section .ctn_section{
		padding: var(--aox-30px) 0 0;
		width: 100%;
		height: auto;
		}
	.section_hero .ctn_background{
		/* height: var(--aox-520px); */
		height: auto;
		}


}
/* ======================================== */
/* .obj_cta
/* ======================================== */
.obj_cta{
	position:relative;
	display:inline-block;
	padding:var(--aox-25px) var(--aox-50px);
	font-size:var(--aox-fs-14px); line-height:var(--aox-lh-120);
	font-weight:700;
	letter-spacing: 0;
	color:#fff;
	text-transform:uppercase;
	background:rgba(0,0,0,1);
	transition:all .3s ease;
	}
.obj_cta.reverse{
	color:#000;
	background:#fff;
	}
.obj_cta.grey{
	color:#777 !important;
	background:none !important;
	}
.obj_cta.light-grey{
	color:#000 !important;
	background:#f2f2f2 !important;
	}
.obj_cta-txt{
	display:inline-block;
	padding:var(--aox-10px) var(--aox-10px) var(--aox-10px) 0;
	font-size:var(--aox-fs-14px); line-height:var(--aox-lh-100);
	font-weight:500;
	text-decoration:underline;
	transition:all .3s ease;
	}
.obj_cta.var_picto svg{
	position:absolute;
	top:50%;
	fill:#fff;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	transition:all .3s ease;
	}
.obj_cta.var_email{
	padding-left:var(--aox-60px);
	}
.obj_cta.var_email svg{
	left:var(--aox-20px);
	}
.obj_cta-download{
	position:relative;
	display:inline-block;
	padding:var(--aox-10px) var(--aox-10px) var(--aox-10px) var(--aox-30px);
	font-size:var(--aox-fs-13px); line-height:var(--aox-lh-100);
	font-weight:500;
	transition:all .3s ease;
	}
.obj_cta-download svg{
	position:absolute;
	left:0; top:50%;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	transition:all .3s ease;
	}


/***/

.obj_btn-popin-detail{
    position: relative;
    display: flex;
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    height: var(--aox-24px);
    text-transform: uppercase;
    text-align: left;
    margin-top: var(--aox-5px);
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    }
.obj_btn-popin-detail:hover{
    text-decoration: underline;
    }



@media screen and (min-width:1025px){
	.obj_cta:hover{
		background-color: #777;
		}
	.obj_cta-txt:hover{
		color:#777;
		}
	.obj_cta.reverse:hover{
		color:#fff;
		background:#777;
		}
	.obj_cta.grey:hover{
		color:#000 !important;
		background:#fff !important;
		}
	.obj_cta.light-grey:hover{
		color:#f2f2f2 !important;
		background:#000 !important;
		}
	.obj_cta-download:hover{
		color:#777 !important;
		}
	.obj_cta-download:hover svg{
		fill:#777 !important;
		}
}
@media screen and (max-width:767px){
	.obj_cta{
		font-size:var(--aox-fs-12px);
		padding:var(--aox-20px) var(--aox-30px);
		}
}



/***/

.obj_btn{
	position:relative;
    display: flex;
    flex-direction: row;
    align-items: center;
	padding: var(--aox-20px) var(--aox-30px);
    height: var(--aox-55px);
	font-size:var(--aox-fs-12px); line-height:var(--aox-lh-100);
	font-weight:700;
	letter-spacing: 0;
	color:#fff;
	text-transform:uppercase;
	background: #000;
	border: 1px solid #000;
	}
.obj_btn:hover{
	color:#000;
	background:#fff;
	border: 1px solid #000;
	}
.obj_btn.light{
	color:#000;
	background:#fff;
	border: 1px solid #000;
	}
.obj_btn.light:hover{
	border: 1px solid #000;
	background: #000;
	color: #fff;
	}

.obj_btn.picto > svg{
	margin-right: var(--aox-10px);
	fill: white;
	}
.obj_btn.picto > span + svg{
	margin-left: var(--aox-10px);
	fill: white;
	}
.obj_btn.picto.light > svg{
	fill: black;
	}
.obj_btn.picto:hover > svg{
	fill: black;
	}
.obj_btn.picto.light:hover > svg{
	fill: white;
	}

/***/
.obj_btn.obj_btn-title_absolute{
	position: absolute;
    top: var(--aox-70px);
    right: 0;
    z-index: 1;
	}

@media screen and (max-width:767px){
	.obj_btn.obj_btn-title_absolute{
		padding:var(--aox-10px);
		height: auto;
		border: none;
		}
	.obj_btn.obj_btn-title_absolute span{
		display: none;
		}
	.obj_btn.obj_btn-title_absolute > svg {
		margin: 0 !important;
		}
}
	
/* ======================================== */
/* .lazyload-placeholder
/* ======================================== */
.lazyload-placeholder{
	position:relative;
	width:100%; height:100%;
	background:#e6e6e6;
	}
.lazyload-placeholder::before{
	position:absolute;
	z-index:1;
	left:50%; top:50%;
	width:var(--aox-50px); height:var(--aox-50px);
	margin:calc(-1 * var(--aox-25px)) 0 0 calc(-1 * var(--aox-25px));
	border:5px solid rgba(255,255,255,1);
	border-top-color:rgba(255,255,255,0.5);
	border-bottom-color:rgba(255,255,255,0.5);
	border-radius:var(--aox-50px);
	-webkit-animation:rotationLoop 1s ease infinite;
	        animation:rotationLoop 1s ease infinite;
	content:'';
	}

@media screen and (max-width:767px){
	.lazyload-placeholder::before{
		width:var(--aox-40px); height:var(--aox-40px);
		margin:calc(-1 * var(--aox-20px)) 0 0 calc(-1 * var(--aox-20px));
		border-width:4px;
		border-radius:var(--aox-40px);
		}
}
/* ======================================== */
/* .obj_title
/* ======================================== */
.obj_title{
	width:100%;
	padding:var(--aox-60px) var(--aox-20px);
	font-weight:700;
	font-size:var(--aox-fs-20px); line-height:1;
	text-align:center;
	text-transform:uppercase;
	}
.obj_title-big{
	width:100%;
	padding:var(--aox-60px) var(--aox-20px);
	font-weight:700;
	font-size:var(--aox-fs-46px); line-height:1;
	text-align:center;
	}
@media screen and (max-width:1340px){
	.obj_title,
	.obj_title-big{
		padding:4.5vw 2.2vw;
		}
}
@media screen and (max-width:767px){
	.obj_title{
		font-size:var(--aox-fs-14px);
		padding:var(--aox-40px) var(--aox-20px);
		}
	.obj_title-big{
		font-size:9vw;
		padding:var(--aox-40px) var(--aox-20px);
		}
}
/* ======================================== */
/* .obj_subtitle
/* ======================================== */
.obj_subtitle{
	width: 75%;
    margin: 0 auto;
    padding: 0 var(--aox-20px) var(--aox-60px);
    font-weight: 300;
    font-size:var(--aox-fs-16px);
    font-family: 'Montserrat', sans-serif;
    line-height: 2.9rem;
    text-align: center;
	}
.obj_subtitle strong{
	font-weight:700;
	}
.obj_title + .obj_subtitle{	
	padding: 0px var(--aox-20px) var(--aox-15px) var(--aox-20px);
	}
@media screen and (max-width:767px){
	.obj_subtitle{
		width: 100%;
		font-size:var(--aox-fs-14px); line-height: 2.2rem;
		padding: 0 var(--aox-20px) var(--aox-30px);
		}
}
/* ======================================== */
/* .obj_carousel
/* ======================================== */
.obj_carousel{
	position:relative;
	overflow:hidden;
	max-width:100%;
	}
.obj_carousel .slick-list{
	overflow:hidden;
	height:100%;
	}
.obj_carousel .slick-track{
	display:flex;
	height:100%;
	margin:0 auto;
	}
.obj_carousel .slick-slide{
	position:relative;
    height:100%; width:100%;
	overflow:hidden;
	}
.obj_carousel .slick-arrow{
	position:absolute;
	z-index:2;
	top:50%;
	height:var(--aox-30px); width:var(--aox-30px);
	margin-top:calc(-1 * var(--aox-15px));
	text-indent:-9999em;
	transition:all .3s ease;
	}
.obj_carousel .slick-arrow.slick-disabled{
	cursor:default;
	}
.obj_carousel .slick-prev{
	left:3%;
	-webkit-transform:rotate(-45deg) scale(1);
	        transform:rotate(-45deg) scale(1);
	border-top:solid 4px rgba(255,255,255,1);
	border-left:solid 4px rgba(255,255,255,1);
	}
.obj_carousel .slick-next{
	right:3%;
	-webkit-transform:rotate(45deg) scale(1);
	        transform:rotate(45deg) scale(1);
	border-top:solid 4px rgba(255,255,255,1);
	border-right:solid 4px rgba(255,255,255,1);
	}
.obj_carousel .slick-prev.slick-disabled{
	border-top-color:rgba(255,255,255,0.25);
	border-left-color:rgba(255,255,255,0.25);
	-webkit-transform:rotate(-45deg) scale(1);
	        transform:rotate(-45deg) scale(1);
	}
.obj_carousel .slick-next.slick-disabled{
	border-top-color:rgba(255,255,255,0.25);
	border-right-color:rgba(255,255,255,0.25);
	-webkit-transform:rotate(45deg) scale(1);
	        transform:rotate(45deg) scale(1);
	}
.obj_carousel .slick-dots{
	position:absolute;
	z-index:1;
	left:0; bottom:7%;
	display:flex !important;
	width:100%;
	justify-content:center;
	}
.obj_carousel .slick-dots li{
	height:var(--aox-10px); width:var(--aox-10px);
	margin:0 5px;
	border-radius:50%;
	background-color:none;
	border:1px solid rgba(255,255,255,1);
	transition:all .3s ease;
	}
.obj_carousel .slick-dots li.slick-active{
	background-color:rgba(255,255,255,1);
	}
.obj_carousel .slick-dots li button{
	display:block;
	height:100%; width:100%;
	text-indent:-9999em;
	}

@media screen and (min-width:1025px){
	.obj_carousel .slick-prev:hover{
		left:2.5%;
		-webkit-transform:rotate(-45deg) scale(1);
		        transform:rotate(-45deg) scale(1);
		}
	.obj_carousel .slick-next:hover{
		right:2.5%;
		-webkit-transform:rotate(45deg) scale(1);
		        transform:rotate(45deg) scale(1);
		}
	.obj_carousel .slick-prev.slick-disabled:hover{
		left:3%;
		border-top-color:rgba(255,255,255,0.25);
		border-left-color:rgba(255,255,255,0.25);
		-webkit-transform:rotate(-45deg) scale(1);
		        transform:rotate(-45deg) scale(1);
		}
	.obj_carousel .slick-next.slick-disabled:hover{
		right:3%;
		border-top-color:rgba(255,255,255,0.25);
		border-right-color:rgba(255,255,255,0.25);
		-webkit-transform:rotate(45deg) scale(1);
		        transform:rotate(45deg) scale(1);
		}
	.obj_carousel .slick-dots li:hover{
		background-color:rgba(255,255,255,0.5);
		}
	.obj_carousel .slick-dots li.slick-active:hover{
		background-color:rgba(255,255,255,1);
		}
}
@media screen and (max-width:767px){
	.obj_carousel .slick-prev,
	.obj_carousel .slick-prev.slick-disabled{
		left:var(--aox-25px);
		-webkit-transform:rotate(-45deg) scale(0.6);
		        transform:rotate(-45deg) scale(0.6);
		}
	.obj_carousel .slick-next,
	.obj_carousel .slick-next.slick-disabled{
		right:var(--aox-25px);
		-webkit-transform:rotate(45deg) scale(0.6);
		        transform:rotate(45deg) scale(0.6);
		}
	.obj_carousel .slick-dots{
		display:none !important;
		}
}
/* ======================================== */
/* .obj_push-carousel
/* ======================================== */
.obj_carousel.obj_push-carousel{
	padding:0 5.8%;
	}
.obj_carousel.obj_push-carousel .slick-arrow{
	top:40%;
	border-color:rgba(0,0,0,1);
	border-width:3px;
	}
.obj_carousel.obj_push-carousel .slick-arrow.slick-disabled{
	border-color:rgba(0,0,0,0.25);
	}
.obj_carousel.obj_push-carousel .slick-prev,
.obj_carousel.obj_push-carousel .slick-prev.slick-disabled{
	left:1.5%;
	}
.obj_carousel.obj_push-carousel .slick-next,
.obj_carousel.obj_push-carousel .slick-next.slick-disabled{
	right:1.5%;
	}

@media screen and (min-width:1281px){
	.obj_carousel.obj_push-carousel .slick-arrow{
		width:var(--aox-50px); height:var(--aox-50px);
		}
}
@media screen and (min-width:1025px){
	.obj_carousel.obj_push-carousel .slick-prev:hover{
		left:1%;
		}
	.obj_carousel.obj_push-carousel .slick-next:hover{
		right:1%;
		}
	.obj_carousel.obj_push-carousel .slick-prev.slick-disabled:hover{
		left:1.5%;
		}
	.obj_carousel.obj_push-carousel .slick-next.slick-disabled:hover{
		right:1.5%;
		}
	.obj_carousel.obj_push-carousel .slick-arrow.slick-disabled:hover{
		border-color:rgba(0,0,0,0.25);
		}
}
@media screen and (max-width:767px){
	.obj_carousel.obj_push-carousel{
		padding:0 var(--aox-20px);
		}
}
/* ======================================== */
/* .obj_push-article
/* ======================================== */
.obj_push-article{
	width:100%; max-width:var(--aox-1280px);
	margin:var(--aox-60px) auto 0 auto;
	}
.obj_push-article .ctn_push-article{
	display:flex;
	flex-flow:row wrap;
	border:1px solid #c7c7c7;
	}
.obj_push-article.var_comment .ctn_push-article{
	border-bottom:none;
	}
.obj_push-article .ctn_img{
	width:36.35%; max-width:calc(var(--aox-460px) + var(--aox-5px));
	overflow:hidden;
	}
.obj_push-article .ctn_img a{
	display:block;
	position:relative;
	height:100%;
	}
.obj_push-article.var_video .ctn_img a::before,
.obj_push-article.var_video .ctn_img a::after{
	position:absolute;
	left:50%; top:50%;
	z-index:2;
	content:'';
	}
.obj_push-article.var_video .ctn_img a::before{
	width:var(--aox-88px); height:var(--aox-88px);
	border:4px solid #fff;
	border-radius:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.obj_push-article.var_video .ctn_img a::after{
	width:0; height:0;
	border-style:solid;
	border-width:var(--aox-16px) 0 var(--aox-16px) var(--aox-28px);
	border-color:transparent transparent transparent #ffffff;
	-webkit-transform:translate(-40%,-50%);
	        transform:translate(-40%,-50%);
	}
.obj_push-article .ctn_img img{
	position:relative;
	z-index:1;
	width:100%;
	transition:all .3s ease;
	}
.obj_push-article .ctn_text{
	position:relative;
	width:63.65%;
	padding:0 6% 0 7%;
	}
.obj_push-article .ctn_text h3{
	position:relative;
	margin-top:var(--aox-80px); 
	font-size:var(--aox-fs-16px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}
.obj_push-article .ctn_text h2{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-32px); line-height:1.5;
	font-weight:700;
	}
.obj_push-article .ctn_text .t_author-date{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-13px); line-height:1;
	}
.obj_push-article .ctn_text .t_author-date strong{
	font-weight:700;
	}
.obj_push-article .ctn_text .t_main{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-16px); line-height:1.875;
	}
.obj_push-article .ctn_text .ctn_cta{
	margin-top:var(--aox-10px);
	}

@media screen and (min-width:1025px){
	.obj_push-article .ctn_img a:hover img{
		-webkit-transform:scale(1.05);
		        transform:scale(1.05);
		}
}
@media screen and (max-width:1340px){
	.obj_push-article .ctn_text h2{
		font-size:var(--aox-fs-32px);
		}
}
@media screen and (max-width:767px){
	.obj_push-article{
		margin: var(--aox-40px) 0 0 0;
		}
	.obj_push-article .ctn_img{
		width:100%;
		}
	.obj_push-article .ctn_img .lazyload-placeholder{
		height:0;
		padding-bottom:100%;
		}
	.obj_push-article .ctn_text{
		width:100%;
		padding:0 var(--aox-30px);
		border-top:none;
		}
	.obj_push-article .ctn_text h3{
		margin-top:var(--aox-30px);
		font-size:var(--aox-fs-12px);
		}
	.obj_push-article .ctn_text h2{
		margin-top:var(--aox-20px);
		font-size:var(--aox-fs-24px);
		}
	.obj_push-article .ctn_text .ctn_cta{
		margin-top:var(--aox-25px);
		}
}
/* ======================================== */
/* .obj_comment-list
/* ======================================== */
.obj_comment-list{
	width:100%;
	padding:var(--aox-60px) 7.5% var(--aox-80px) 5%;
	background:#f2f2f2;
	}
.obj_comment-list h4{
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	}
.obj_comment-list .ctn_comment-row{
	display:flex;
	flex-flow:row wrap;
	margin-left:var(--aox-30px);
	padding:var(--aox-20px) 0 var(--aox-35px) var(--aox-15px);
	border-bottom:1px solid #c3c3c3;
	}
.obj_comment-list .ctn_comment-row .ctn_avatar{
	width:var(--aox-40px); height:var(--aox-40px);
	border-radius:50%;
	overflow:hidden;
	}
.obj_comment-list .ctn_comment-row .ctn_comment{
	width:calc(100% - var(--aox-40px));
	padding:var(--aox-5px) 0 0 var(--aox-15px);
	}
.obj_comment-list .ctn_comment-row .ctn_comment .t_name-time{
	font-size:var(--aox-fs-13px); line-height:1;
	color:#777;
	}
.obj_comment-list .ctn_comment-row .ctn_comment .t_name-time strong{
	color:#000;
	font-weight:700;
	}
.obj_comment-list .ctn_comment-row .ctn_comment .t_main{
	margin-top:var(--aox-10px);
	font-size:var(--aox-fs-13px); line-height:1.2;
	font-weight:500;
	}
.obj_comment-list .ctn_cta{
	padding-top:var(--aox-60px);
	text-align:center;
	}

@media screen and (max-width:767px){
	.obj_comment-list{
		padding:var(--aox-40px) var(--aox-30px) var(--aox-30px) var(--aox-30px);
		}
	.obj_comment-list h4{
		margin-bottom:var(--aox-15px);
		}
	.obj_comment-list .ctn_comment-row{
		margin-left:0;
		padding:var(--aox-10px) 0 var(--aox-20px) var(--aox-10px);
		}
	.obj_comment-list .ctn_cta{
		padding-top:var(--aox-30px);
		}
}
/* ======================================== */
/* .obj_back-to-top
/* ======================================== */
.obj_back-to-top{
	position:fixed;
	z-index:999;
	right:0; bottom:25%;
	width:var(--aox-60px); height:var(--aox-60px);
	background:#000;
	-webkit-transform:translateX(100%);
	        transform:translateX(100%);
	transition:-webkit-transform .3s ease;
	transition:transform .3s ease;
	transition:transform .3s ease, -webkit-transform .3s ease;
	}
.obj_back-to-top.visible{
	-webkit-transform:translateX(0);
	        transform:translateX(0);
	}
.obj_back-to-top::before{
	position:absolute;
	left:50%; top:60%;
	width:var(--aox-15px); height:var(--aox-15px);
	-webkit-transform:rotate(-45deg) translate(-50%,-50%);
	        transform:rotate(-45deg) translate(-50%,-50%);
	-webkit-transform-origin:0 0;
	        transform-origin:0 0;
	border-top:3px solid #fff;
	border-right:3px solid #fff;
	content:'';
	transition:all .3s ease;
	}

@media screen and (min-width:1025px){
	.obj_back-to-top:hover::before{
		top:50%;
		}
}
@media screen and (max-width:767px){
	.obj_back-to-top{
		bottom:0;
		width:var(--aox-50px); height:var(--aox-50px);
		}
}
/* ======================================== */
/* .obj_push-custom
/* ======================================== */
.obj_push-custom{
	position:relative;
	margin:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	height:var(--aox-450px);
	border:1px solid #c7c7c7;
	background:#f2f2f2;
	}
.obj_push-custom.var_100{
	width:calc(100% - var(--aox-60px));
	}
.obj_push-custom.var_1-2{
	width:calc(50% - var(--aox-60px));
	}
.obj_push-custom.var_1-3{
	width:calc(33.333% - var(--aox-60px));
	}
.obj_push-custom.var_2-3{
	width:calc(66.666% - var(--aox-60px));
	}
.obj_push-custom.var_2-5{
	width:calc(40% - var(--aox-60px));
	}
.obj_push-custom.var_3-5{
	width:calc(60% - var(--aox-60px));
	}
.obj_push-custom .t-last-br{
	display:none;
	}
.obj_push-custom.var_thumb{
	background:#fff;
	}
.obj_push-custom.var_thumb.is-not-available{
	opacity: 0.3;
	}
.obj_push-custom.var_thumb figure{
	position:relative;
	height:calc(var(--aox-220px) + var(--aox-3px));
	background:#f2f2f2;
	overflow:hidden;
	}
.obj_push-custom.var_thumb figure img{
	position:absolute;
	top:0; left:50%;
	width:calc(var(--aox-470px) + var(--aox-5px)); height:var(--aox-225px);
	-webkit-transform:translateX(-50%) scale(1);
	        transform:translateX(-50%) scale(1);
	transition:all .3s ease;
	}
.obj_push-custom.var_thumb.var_3-5 figure img{
	width:calc(var(--aox-740px) + var(--aox-5px));
	}
.obj_push-custom.var_thumb.var_1-2 figure img{
	width:calc(var(--aox-600px) + var(--aox-8px)); height: calc(var(--aox-220px) + var(--aox-3px));
	}
.obj_push-custom.var_thumb h2,
.obj_push-custom.var_thumb h2 p{
	display:flex;
	align-items:center;
	justify-content:center;
	height:var(--aox-120px);
	padding:0 10%;
	font-size:var(--aox-fs-32px); line-height:1;
	font-weight:700;
	}
.obj_push-custom.var_thumb.var_left h2{
	justify-content:flex-start;
	text-align:left;
	}
.obj_push-custom.var_thumb.var_center h2{
	justify-content:center;
	text-align:center;
	}
.obj_push-custom.var_thumb.var_right h2{
	justify-content:flex-end;
	text-align:right;
	}
.obj_push-custom.var_thumb > p{
	font-size:var(--aox-fs-16px); line-height:1.2;
	padding:0 10%;
	}
.obj_push-custom.var_thumb.var_left > p{
	text-align:left;
	}
.obj_push-custom.var_thumb.var_center > p{
	text-align:center;
	}
.obj_push-custom.var_thumb.var_right > p{
	text-align:right;
	}
.obj_push-custom.var_thumb .ctn_cta{
	position:absolute;
	left:0; bottom:9%;
	width:100%;
	padding:0 10%;
	text-align:center;
	}
.obj_push-custom.var_thumb.var_left .ctn_cta{
	text-align:left;
	}
.obj_push-custom.var_thumb.var_center .ctn_cta{
	text-align:center;
	}
.obj_push-custom.var_thumb.var_right .ctn_cta{
	text-align:right;
	}
.obj_push-custom.var_thumb .ctn_cta p{
	font-size:var(--aox-fs-16px);
	font-weight: 400;
	}
.obj_push-custom.var_full figure{
	position:relative;
	width:100%; height:100%;
	background:#f2f2f2;
	overflow:hidden;
	}
.obj_push-custom.var_full figure img{
	position:absolute;
	top:0; left:0;
	width:calc(var(--aox-740px) + var(--aox-5px)); height:var(--aox-450px);
	transition:all .3s ease;
	}
.obj_push-custom.var_full.var_100 figure img{
	width:var(--aox-1280px);
	}
.obj_push-custom.var_full.var_2-5 figure img{
	width:calc(var(--aox-470px) + var(--aox-5px));
	}
.obj_push-custom.var_full .ctn_cta{
	position:absolute;
	left:12%; bottom:9%;
	}
.obj_push-custom.var_full .ctn_text{
	position:absolute;
	z-index:3;
	left:12%; top:9%;
	max-width:45%;
	padding-right:var(--aox-20px);
	}
.obj_push-custom.var_full.var_center .ctn_text,
.obj_push-custom.var_full.var_center .ctn_cta{
	left:50%;
	padding:0;
	text-align:center;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.obj_push-custom.var_full.var_right .ctn_text,
.obj_push-custom.var_full.var_right .ctn_cta{
	left:50%;
	}
.obj_push-custom.var_full.var_100 .ctn_text{
	max-width:var(--aox-580px);
	}
.obj_push-custom.var_full .ctn_text h2{
	margin-bottom:var(--aox-15px);
	}
.obj_push-custom.var_full .ctn_text h4 + h2{
	margin-top:var(--aox-40px);
	}
.obj_push-custom.var_full .ctn_text h3 + h2{
	margin-top:0;
	}
.obj_push-custom.var_full .ctn_text h2,
.obj_push-custom.var_full .ctn_text h2 p{
	font-size:var(--aox-fs-32px); line-height:1;
	font-weight:700;
	}
.obj_push-custom.var_full .ctn_text h3{
	margin-bottom:var(--aox-20px);
	}
.obj_push-custom.var_full .ctn_text h3,
.obj_push-custom.var_full .ctn_text h3 p{
	font-size:var(--aox-fs-20px); line-height:1;
	text-transform:uppercase;
	}
.obj_push-custom.var_full .ctn_text h4{
	margin-bottom:var(--aox-15px);
	}
.obj_push-custom.var_full .ctn_text h4,
.obj_push-custom.var_full .ctn_text h4 p{
	font-size:var(--aox-fs-16px); line-height:1;
	text-transform:uppercase;
	}
.obj_push-custom.var_full .ctn_text p{
	font-size:var(--aox-fs-16px); line-height:1.8;
	}
.obj_push-custom.var_full .ctn_text p strong{
	font-weight:700;
	}
.obj_push-custom.var_full.var_dark .ctn_text h3,
.obj_push-custom.var_full.var_dark .ctn_text h3 p,
.obj_push-custom.var_full.var_dark .ctn_text h4,
.obj_push-custom.var_full.var_dark .ctn_text h4 p,
.obj_push-custom.var_full.var_dark .ctn_text p{
	color:#fff;
	}
.section_page-liste + .obj_push-custom{
	margin-top:0;
	margin-bottom:var(--aox-120px);
	}
.obj_breadcrumb + .obj_push-custom{
	margin-top:var(--aox-20px);
	}

@media screen and (min-width:1025px){
	.obj_push-custom.var_thumb figure a:hover img{
		-webkit-transform:translateX(-50%) scale(1.05);
		        transform:translateX(-50%) scale(1.05);
		}
	.obj_push-custom.var_full a:hover figure img{
		-webkit-transform:scale(1.05);
		        transform:scale(1.05);
		}
}
@media screen and (max-width:1340px){
	.obj_push-custom{
		height:33.6vw;
		margin:4.5vw 2.2vw 0 2.2vw;
		}
	.obj_push-custom.var_100{
		width:calc(100% - 4.4vw);
		}
	.obj_push-custom.var_1-2{
		width:calc(50% - 4.4vw);
		}
	.obj_push-custom.var_1-3{
		width:calc(32.8% - 4.4vw);
		}
	.obj_push-custom.var_2-3{
		width:calc(65.8% - 4.4vw);
		}
	.obj_push-custom.var_2-5{
		width:calc(39.3% - 4.4vw);
		}
	.obj_push-custom.var_3-5{
		width:calc(59.3% - 4.4vw);
		}
	.obj_push-custom .obj_cta{
		padding:1.8vw 3.8vw;
		}
	.obj_push-custom.var_thumb figure{
		height:16.6vw;
		}
	.obj_push-custom.var_thumb figure img{
		width:calc(var(--aox-470px) + var(--aox-5px)); height:var(--aox-225px);
		}
	.obj_push-custom.var_thumb.var_3-5 figure img{
		width:calc(var(--aox-740px) + var(--aox-5px));
		}
	.obj_push-custom.var_thumb h2,
	.obj_push-custom.var_thumb h2 p{
		height:9vw;
		font-size:var(--aox-fs-32px);
		}
	.obj_push-custom.var_thumb > p{
		font-size:var(--aox-fs-16px);
		}
	.obj_push-custom.var_full figure img,
	.obj_push-custom.var_full.var_100 figure img,
	.obj_push-custom.var_full.var_2-5 figure img{
		width:auto; height:100%;
		}
	.obj_push-custom.var_full .ctn_text h2{
		margin-bottom:1.1vw;
		}
	.obj_push-custom.var_full .ctn_text h4 + h2{
		margin-top:3vw;
		}
	.obj_push-custom.var_full .ctn_text h2,
	.obj_push-custom.var_full .ctn_text h2 p{
		font-size:var(--aox-fs-32px);
		}
	.obj_push-custom.var_full .ctn_text h3{
		margin-bottom:1.5vw;
		}
	.obj_push-custom.var_full .ctn_text h3,
	.obj_push-custom.var_full .ctn_text h3 p{
		font-size:var(--aox-fs-20px);
		}
	.obj_push-custom.var_full .ctn_text h4{
		margin-bottom:1.1vw;
		}
	.obj_push-custom.var_full .ctn_text h4,
	.obj_push-custom.var_full .ctn_text h4 p{
		font-size:var(--aox-fs-16px);
		}
	.obj_push-custom.var_full .ctn_text p{
		font-size:var(--aox-fs-16px);
		}
}
@media screen and (max-width:767px){
	.obj_push-custom{
		width:100% !important; height:calc(var(--aox-250px) + var(--aox-2px));
		margin:var(--aox-40px) 0 0 0;
		}
	.obj_push-custom.var_derm-expertise{
		height:var(--aox-385px);
		}
	.obj_push-custom.var_thumb{
		margin-left:var(--aox-15px);
		margin-right:var(--aox-15px);
		height:auto;
		}
	.obj_push-custom.var_thumb .ctn_cta{
		position:relative;
		left:auto; bottom:auto;
		padding-bottom:var(--aox-40px);
		}
	.obj_push-custom.var_thumb figure{
		height:auto;
		}
	.obj_push-custom.var_thumb figure img,
	.obj_push-custom.var_thumb.var_3-5 figure img,
	.obj_push-custom.var_thumb.var_1-2 figure img{
		position:relative;
		top:auto; left:auto;
		width:100%; height:auto;
		-webkit-transform:none;
		        transform:none;
		}
	.obj_push-custom.var_thumb figure .lazyload-placeholder{
		height:0;
		padding-bottom:47%;
		}
	.obj_push-custom.var_thumb h2,
	.obj_push-custom.var_thumb h2 p{
		height:auto;
		padding:var(--aox-25px) var(--aox-30px);
		font-size:var(--aox-fs-24px);
		}
	.obj_push-custom.var_thumb .ctn_cta{
		padding:0 var(--aox-30px) var(--aox-30px) var(--aox-30px);
		}
	.obj_push-custom.var_thumb > p{
		font-size:var(--aox-fs-12px);
		padding:0 var(--aox-30px) var(--aox-30px) var(--aox-30px);
		}
	.obj_push-custom.var_full figure img{
		position:relative;
		top:auto; left:auto;
		width:100%; height:auto;
		}
	.obj_push-custom.var_full figure .lazyload-placeholder{
		height:0;
		padding-bottom:60%;
		}
	.obj_push-custom.var_full .ctn_text,
	.obj_push-custom.var_full.var_100 .ctn_text{
		max-width:50%;
		}
	.obj_push-custom.var_full.var_100.var_derm-expertise .ctn_text{
		max-width:75%;
		}
	.obj_push-custom.var_full .ctn_text h2{
		margin-bottom:var(--aox-10px);
		}
	.obj_push-custom.var_full .ctn_text h2 picture{
		margin-bottom:var(--aox-10px);
		}
	.obj_push-custom.var_full .ctn_text h4 + h2{
		margin-top:var(--aox-30px);
		}
	.obj_push-custom.var_full .ctn_text h2,
	.obj_push-custom.var_full .ctn_text h2 p{
		font-size:var(--aox-fs-24px);
		}
	.obj_push-custom.var_full .ctn_text h3,
	.obj_push-custom.var_full .ctn_text h3 p{
		font-size:var(--aox-fs-18px);
		}
	.obj_push-custom.var_full .ctn_text h4{
		margin-bottom:var(--aox-10px);
		}
	.obj_push-custom.var_full .ctn_text h4,
	.obj_push-custom.var_full .ctn_text h4 p{
		font-size:var(--aox-fs-15px);
		}
	.obj_push-custom.var_full .ctn_text p{
		display:none;
		}
	.obj_push-custom.var_full.var_derm-expertise .ctn_text p{
		display:block;
		font-size:var(--aox-fs-14px);
		}
	.obj_push-custom.var_full .ctn_text h2 p,
	.obj_push-custom.var_full .ctn_text h3 p,
	.obj_push-custom.var_full .ctn_text h4 p{
		display:block;
		}
	.obj_push-custom .obj_cta{
		padding:var(--aox-20px) var(--aox-30px);
		}
	.section_page-liste + .obj_push-custom{
		margin-bottom:var(--aox-70px);
		}
}
@media screen and (max-width:320px){
	.obj_push-custom.var_full .ctn_text{
		max-width:100%;
		}
}
/* ======================================== */
/* margin
/* ======================================== */
.obj_form .mb_5{
	margin-bottom:var(--aox-5px);
	}
.obj_form .mb_10{
	margin-bottom:var(--aox-10px);
	}
.obj_form .mb_15{
	margin-bottom:var(--aox-15px);
	}
.obj_form .mb_20{
	margin-bottom:var(--aox-20px);
	}
.obj_form .mb_30{
	margin-bottom:var(--aox-30px);
	}
.obj_form .mb_40{
	margin-bottom:var(--aox-40px);
	}
.obj_form .mb_50{
	margin-bottom:var(--aox-50px);
	}

/* ======================================== */
/* row
/* ======================================== */
.obj_form .ctn_row{
	display:flex;
	flex-flow:row wrap;
	}
.obj_form .ctn_row > div{
	margin:0 var(--aox-20px) var(--aox-10px) 0;
	}

/* ======================================== */
/* input
/* ======================================== */
.obj_form .ctn_input{
	position:relative;
	}
.obj_form input[type="text"],
.obj_form input[type="tel"],
.obj_form input[type="email"],
.obj_form input[type="password"],
.obj_form input[type="search"],
.obj_form input[type="date"],
.obj_form input[type="url"]{
	width:100%; height:var(--aox-60px);
	padding:var(--aox-10px) var(--aox-50px) var(--aox-10px) var(--aox-25px);
	font-size:var(--aox-fs-14px); line-height:1;
	color:#777;
	font-weight:500;
	border:1px solid #777;
	}
.obj_form input[type="text"]::-webkit-input-placeholder, .obj_form input[type="tel"]::-webkit-input-placeholder, .obj_form input[type="email"]::-webkit-input-placeholder, .obj_form input[type="password"]::-webkit-input-placeholder, .obj_form input[type="search"]::-webkit-input-placeholder, .obj_form input[type="date"]::-webkit-input-placeholder, .obj_form input[type="url"]::-webkit-input-placeholder{
	font-size:var(--aox-fs-12px);
	font-weight:700;
	text-transform:uppercase;
	}
.obj_form input[type="text"]::placeholder,
.obj_form input[type="tel"]::placeholder,
.obj_form input[type="email"]::placeholder,
.obj_form input[type="password"]::placeholder,
.obj_form input[type="search"]::placeholder,
.obj_form input[type="date"]::placeholder,
.obj_form input[type="url"]::placeholder{
	font-size:var(--aox-fs-12px);
	font-weight:700;
	text-transform:uppercase;
	}
.obj_form input[type="text"].error,
.obj_form input[type="tel"].error,
.obj_form input[type="email"].error,
.obj_form input[type="password"].error,
.obj_form input[type="search"].error,
.obj_form input[type="date"].error,
.obj_form input[type="url"].error{
	color:#ff4847;
	border:1px solid #ff4847;
	}
.obj_form input[type="text"].error::-webkit-input-placeholder, .obj_form input[type="tel"].error::-webkit-input-placeholder, .obj_form input[type="email"].error::-webkit-input-placeholder, .obj_form input[type="password"].error::-webkit-input-placeholder, .obj_form input[type="search"].error::-webkit-input-placeholder, .obj_form input[type="date"].error::-webkit-input-placeholder, .obj_form input[type="url"].error::-webkit-input-placeholder{
	color:#ff4847;
	}
.obj_form input[type="text"].error::placeholder,
.obj_form input[type="tel"].error::placeholder,
.obj_form input[type="email"].error::placeholder,
.obj_form input[type="password"].error::placeholder,
.obj_form input[type="search"].error::placeholder,
.obj_form input[type="date"].error::placeholder,
.obj_form input[type="url"].error::placeholder{
	color:#ff4847;
	}
.obj_form input[type="text"]:disabled,
.obj_form input[type="tel"]:disabled,
.obj_form input[type="email"]:disabled,
.obj_form input[type="password"]:disabled,
.obj_form input[type="search"]:disabled,
.obj_form input[type="date"]:disabled,
.obj_form input[type="url"]:disabled{
	background:#f2f2f2;
	border:1px solid #f2f2f2;
	}

/* ======================================== */
/* text
/* ======================================== */
.obj_form .t_mention{
	font-size:var(--aox-fs-12px); line-height:1.4;
	font-weight:500;
	color:#777;
	}
.obj_form .t_mention a{
	text-decoration:underline;
	color:#000;
	}
.obj_form .t_mandatory{
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:500;
	color:#000;
	}
.obj_form .t_mandatory.var_grey{
	color:#777;
	}
.obj_form .t_info{
	font-size:var(--aox-fs-14px); line-height:1;
	font-weight:500;
	color:#000;
	}
.obj_form .t_info a{
	font-weight:700;
	}
.obj_form .t_notif span{
	display:inline-block;
	padding:var(--aox-15px) var(--aox-30px);
	font-size:var(--aox-fs-14px); line-height:1.2;
	font-weight:500;
	color:#000;
	background:#fff;
	}
.obj_form .t_error{
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:500;
	color:#ff4847;
	}
.obj_form .ctn_input + .t_error{
	margin-top:calc(-1 * var(--aox-15px));
	}
.obj_form .t_label{
	font-size:var(--aox-fs-12px); line-height:1.2;
	font-weight:500;
	color:#777;
	}
.obj_form .t_label a{
	text-decoration:underline;
	}

/* ======================================== */
/* .ctn_textarea
/* ======================================== */
.obj_form .ctn_textarea textarea{
	width:100%; height:var(--aox-180px);
	padding:var(--aox-20px);
	font-family:'Montserrat', sans-serif;
	font-size:var(--aox-fs-14px); line-height:1.2em;
	color:#777;
	background:#fff;
	border:1px solid #777;
	transition:all .3s ease;
	outline:none;
	resize:none;
	appearance:none;
	-webkit-appearance:none;
	border-radius:0;
	}
.obj_form .ctn_textarea.no-border textarea{
	border-color:#fff;
	}
.obj_form .ctn_textarea textarea::-webkit-input-placeholder{
	font-size:var(--aox-fs-12px);
	font-weight:700;
	text-transform:uppercase;
	}
.obj_form .ctn_textarea textarea::placeholder{
	font-size:var(--aox-fs-12px);
	font-weight:700;
	text-transform:uppercase;
	}
.obj_form .ctn_textarea textarea.error{
	color:#ff4847;
	border:1px solid #ff4847;
	}
.obj_form .ctn_textarea textarea.error::-webkit-input-placeholder{
	color:#ff4847;
	}
.obj_form .ctn_textarea textarea.error::placeholder{
	color:#ff4847;
	}

/* ======================================== */
/* .ctn_checkbox
/* ======================================== */
.obj_form .ctn_checkbox input[type=checkbox]{
	display:none;
	}
.obj_form .ctn_checkbox label{
	display:block;
	position:relative;
	padding:var(--aox-4px) 0 0 var(--aox-30px);
	min-height:var(--aox-20px);
	font-size:var(--aox-fs-12px); line-height:1.2;
	color:#777;
	font-weight:500;
	cursor:pointer;
	}
.obj_form .ctn_checkbox label.error,
.obj_form .ctn_checkbox label.error a{
	color:#ff4847;
	}
.obj_form .ctn_checkbox label a{
	color:#000;
	text-decoration:underline;
	}
.obj_form .ctn_checkbox label::before{
	position:absolute;
	left:0; top:0;
	width:var(--aox-18px); height:var(--aox-18px);
	border:1px solid #777;
	background:#fff;
	transition:all .3s ease;
	content:'';
	}
.obj_form .ctn_checkbox.no-border label::before{
	border-color:#fff;
	}
.obj_form .ctn_checkbox label.error::before{
	border:1px solid #ff4847;
	}
.obj_form .ctn_checkbox label::after{
	position:absolute;
	left:4px; top:4px;
	width:var(--aox-12px); height:var(--aox-12px);
	-webkit-transform:scale(0.3);
	        transform:scale(0.3);
	opacity:0;
	background:#777;
	content:'';
	transition:all .3s ease;
	}
.obj_form .ctn_checkbox input[type=checkbox]:checked + label::after{
	-webkit-transform:scale(1);
	        transform:scale(1);
	opacity:1;
	}

/* ======================================== */
/* .ctn_radio
/* ======================================== */
.obj_form .ctn_radio input[type=radio]{
	display:none;
	}
.obj_form .ctn_radio label{
	display:block;
	position:relative;
	padding:4px 0 0 var(--aox-30px);
	min-height:var(--aox-20px);
	font-size:var(--aox-fs-12px); line-height:1.2;
	color:#777;
	font-weight:500;
	cursor:pointer;
	}
.obj_form .ctn_radio label.error{
	color:#ff4847;
	}
.obj_form .ctn_radio label::before{
	position:absolute;
	left:0; top:0;
	width:var(--aox-18px); height:var(--aox-18px);
	border:1px solid #777;
	background:#fff;
	transition:all .3s ease;
	border-radius:50%;
	content:'';
	}
.obj_form .ctn_radio label.error::before{
	border:1px solid #ff4847;
	}
.obj_form .ctn_radio label::after{
	position:absolute;
	left:4px; top:4px;
	width:var(--aox-12px); height:var(--aox-12px);
	-webkit-transform:scale(0.3);
	        transform:scale(0.3);
	opacity:0;
	background:#777;
	border-radius:50%;
	content:'';
	transition:all .3s ease;
	}
.obj_form .ctn_radio input[type=radio]:checked + label::after{
	-webkit-transform:scale(1);
	        transform:scale(1);
	opacity:1;
	}

/* ======================================== */
/* .ctn_select
/* ======================================== */
.obj_form .ctn_select{
	position:relative;
	}
.obj_form .ctn_select .react-select__control{
	position:relative;
	height:var(--aox-60px);
	font-size:var(--aox-fs-14px); line-height:1;
	font-weight:500;
	color:#777;
	border:1px solid #777;
	border-radius:0;
	box-shadow:none;
	background:#fff;
	cursor:pointer;
	}
.obj_form .ctn_select .react-select__control.react-select__control--is-disabled{
	background:#f2f2f2;
	}
.obj_form .ctn_select .react-select__control:hover{
	background:#fff;
	}
.obj_form .ctn_select .error .react-select__control,
.obj_form .ctn_select .error .react-select__control:hover{
	border:1px solid #ff4847;
	}
.obj_form .ctn_select .react-select__value-container{
	display:flex;
	align-items:center;
	padding:0 var(--aox-20px);
	height:100%;
	}
.obj_form .ctn_select .react-select__value-container > div{
	padding:0 !important;
	margin:0 !important;
	}
.obj_form .ctn_select .react-select__placeholder{
	margin:0;
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:700;
	color:#777;
	text-transform:uppercase;
	}
.obj_form .ctn_select .react-select__control--is-disabled .react-select__placeholder{
	font-size:var(--aox-fs-14px);
	font-weight:500;
	text-transform:none;
	}
.obj_form .ctn_select .error .react-select__placeholder{
	color:#ff4847;
	}
.obj_form .ctn_select .react-select__indicators{
	position:absolute;
	right:var(--aox-20px); top:var(--aox-23px);
	width:var(--aox-17px); height:var(--aox-14px);
	transition:all .3s ease;
	}
.obj_form .ctn_select .react-select__control--is-disabled .react-select__indicators{
	display:none;
	}
.obj_form .ctn_select .react-select__control--menu-is-open .react-select__indicators{
	-webkit-transform:scale(-1);
	        transform:scale(-1);
	}
.obj_form .ctn_select .react-select__indicator-separator,
.obj_form .ctn_select .react-select__indicator{
	display:none;
	}
.obj_form .ctn_select .react-select__indicators::after{
	display:block;
	width:0; height:0;
	border-style:solid;
	border-width:14px 8.5px 0 8.5px;
	border-color:#000 transparent transparent transparent;
	content:'';
	}
.obj_form .ctn_select .react-select__menu{
	position:absolute;
	z-index:7777;
	left:0; top:var(--aox-59px);
	width:100%;
	border:1px solid #777;
	border-top-color:#fff;
	border-radius:0;
	box-shadow:none;
	margin:0;
	}
.obj_form .ctn_select .react-select__menu-list{
	max-height:var(--aox-150px);
	padding:0;
	}
.obj_form .ctn_select .react-select__option{
	padding:var(--aox-10px) var(--aox-20px);
	font-size:var(--aox-fs-12px); line-height:1;
	font-weight:700;
	background-color:#fff;
	text-transform:uppercase;
	transition:all .3s ease;
	cursor:pointers;
	}
.obj_form .ctn_select .react-select__option--is-selected,
.obj_form .ctn_select .react-select__option:hover{
	color:#000;
	background-color:#f2f2f2;
	}

/* Native Select */
.obj_form .ctn_native-select{
	display:none;
	position:relative;
	width:100%; height:var(--aox-60px);
	background:#fff;
    border:1px solid #777;
	overflow:hidden;
	}
.obj_form .error .ctn_native-select{
    border:1px solid #ff4847;
	}
.obj_form .ctn_native-select .native-select{
	box-sizing:border-box;
    width:100%; height:var(--aox-60px);
    padding:0 var(--aox-20px);
	font-family:'Montserrat', sans-serif;
    font-size:var(--aox-fs-12px); line-height:1;
	font-weight:700;
	color:#777;
	text-transform:uppercase;
    background:#fff;
    outline:none;
	border:none;
    appearance:none;
    -webkit-appearance:none;
	cursor:pointer;
	}
.obj_form .ctn_native-select .native-select:disabled{
    background:#f2f2f2;
	}
.obj_form .error .ctn_native-select .native-select{
	color:#ff4847;
	}
.obj_form .ctn_native-select .native-select::-ms-expand{
	display:none;
	}
.obj_form .ctn_native-select::after{
	position:absolute;
	right:var(--aox-20px); top:var(--aox-23px);
	width:0; height:0;
	border-style:solid;
	border-width:14px 8.5px 0 8.5px;
	border-color:#000 transparent transparent transparent;
	content:'';
	}
.obj_form .ctn_native-select.is-disabled::after{
	display:none;
	}

@media screen and (max-width:767px){
	.obj_form .react-select-container{
		display:none;
		}
	.obj_form .ctn_native-select{
		display:block;
		}
}

/* ======================================== */
/* .ctn_select-birthdate
/* ======================================== */
.obj_form .ctn_select-birthdate .t_label{
	display:block;
	margin-bottom:var(--aox-15px);
	font-weight:700;
	text-transform:uppercase;
	color:#000;
	}
.obj_form .ctn_select-birthdate .ctn_select-wrapper{
	display:flex;
	}
.obj_form .ctn_select-birthdate .ctn_select{
	width:33.33%;
	}
.obj_form .ctn_select-birthdate .ctn_select + .ctn_select{
	margin-left:var(--aox-10px);
	}

@media screen and (max-width:767px){
	.obj_form .ctn_select-birthdate .ctn_select-wrapper{
		flex-direction:column;
		}
	.obj_form .ctn_select-birthdate .ctn_select-wrapper > div + div{
		margin-top:var(--aox-10px);
		}
	.obj_form .ctn_select-birthdate .ctn_select{
		width:100%;
		}
	.obj_form .ctn_select-birthdate .ctn_select + .ctn_select{
		margin-left:0;
		}
}

/* ======================================== */
/* .btn_password-toggle
/* ======================================== */
.obj_form .btn_password-toggle{
	position:absolute;
	right:var(--aox-15px); top:var(--aox-22px);
	width:var(--aox-28px); height:var(--aox-16px);
	}
.obj_form .ctn_input.var_password .btn_password-toggle{
	right:var(--aox-50px);
	}
.obj_form .ctn_input.var_password input[type="password"]{
	padding-right:var(--aox-80px);
	}
.obj_form .btn_password-toggle svg{
	fill:#b4b4b4;
	transition:all .3s ease;
	}
.obj_form .btn_password-toggle::before{
	position:absolute;
	left:-3px; top:calc(50% - 1px);
	width:120%; height:2px;
	background:#b4b4b4;
	-webkit-transform:rotate(-135deg);
	        transform:rotate(-135deg);
	opacity:1;
	transition:all .3s ease;
	content:'';
	}
.obj_form .btn_password-toggle[aria-pressed=true]::before{
	opacity:0;
	}

@media screen and (min-width:1025px){
	.obj_form .btn_password-toggle:hover svg{
		fill:#000;
		}
	.obj_form .btn_password-toggle:hover::before{
		background:#000;
		}
}

/* ======================================== */
/* obj_recaptcha
/* ======================================== */

.obj_form .obj_recaptcha{
	display:block;
	width:calc(var(--aox-310px) + var(--aox-6px)); height:var(--aox-90px);
	overflow:hidden;
	}
.obj_form .obj_recaptcha > div{
	padding:var(--aox-5px);
	}
.obj_form .obj_recaptcha .error{
	border:1px solid #e80000;
	}

@media screen and (max-width:414px){
	.obj_form .obj_recaptcha,
	.obj_form .obj_recaptcha > div{
		width:calc(var(--aox-240px) + var(--aox-3px)); height:var(--aox-70px);
		}
	.obj_form .obj_recaptcha > div > div{
		-webkit-transform:scale(0.76);
		        transform:scale(0.76);
		-webkit-transform-origin:left top;
		        transform-origin:left top;
		}
}

/* ======================================== */
/* .ctn_file-upload
/* ======================================== */
.obj_form .ctn_single-file-upload{
	position:relative;
	}
.obj_form .ctn_single-file-upload label{
	cursor:pointer;
	}
.obj_form .ctn_single-file-upload .error label{
	color:#e80000;
	}
.obj_form .ctn_single-file-upload input[type="file"]{
	position:absolute;
	z-index:2;
	width:1px; height:1px;
	left:0; top:0;
	opacity:0;
	}
.obj_form .ctn_single-file-upload .t_error{
	margin-top:var(--aox-20px);
	}
.obj_form .ctn_single-file-upload .ctn_file-thumb{
	position:relative;
	display:flex;
	align-items:center;
	width:100%;
	padding-right:0;
	margin-top:var(--aox-20px);
	}
.obj_form .ctn_single-file-upload .canvas_thumb{
	cursor:pointer;
	background:#000;
	}
.obj_form .ctn_single-file-upload .canvas_thumb.no_cursor{
	cursor:default;
	}
.obj_form .ctn_single-file-upload .ctn_infos{
	padding:var(--aox-10px) 0 var(--aox-10px) var(--aox-20px);
	}
.obj_form .ctn_single-file-upload .t_name{
	font-size:var(--aox-fs-13px); line-height:1.2;
	font-weight:700;
	word-break:break-all;
	}
.obj_form .ctn_single-file-upload .t_weight{
	margin-top:var(--aox-5px);
	font-size:var(--aox-fs-13px); line-height:1.2;
	color:#b0b0b0;
	}
.obj_form .ctn_single-file-upload .bt_cancel{
	position:absolute;
	left:var(--aox-90px); top:0;
	width:var(--aox-20px); height:var(--aox-20px);
	font-size:0;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	background:#777;
	border-radius:50%;
	}
.obj_form .ctn_single-file-upload .bt_cancel::before,
.obj_form .ctn_single-file-upload .bt_cancel::after{
	position:absolute;
	left:var(--aox-9px); top:var(--aox-3px);
	width:var(--aox-2px); height:var(--aox-14px);
	background:#fff;
	content:'';
	transition:all .3s ease;
	}
.obj_form .ctn_single-file-upload .bt_cancel::before{
	-webkit-transform:rotate(45deg);
	        transform:rotate(45deg);
	}
.obj_form .ctn_single-file-upload .bt_cancel::after{
	-webkit-transform:rotate(-45deg);
	        transform:rotate(-45deg);
	}
/* var_avatar */
.obj_form .ctn_file-upload.var_avatar .ctn_single-file-upload .obj_cta{
	display:flex;
	align-items:flex-end;
	justify-content:center;
	padding:0 0 var(--aox-40px) 0;
	margin:0;
	width:100%; height:var(--aox-400px);
	color:#000;
	font-weight:300;
	text-transform:none;
	text-decoration:underline;
	background:url(/dist/formationcaf/static/media/i_avatar-grey.8de4a37f.svg) center center / calc(var(--aox-120px) + var(--aox-8px)) auto no-repeat #c3c3c3;
	}
/* var_avatar var_edit */
.obj_form .ctn_file-upload.var_avatar.var_edit{
	position:relative;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta{
	position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: var(--aox-400px);
    overflow: hidden;
    width: 100%;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta img{
	width: 100%;
    height: 100%;
    object-fit: cover;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta{
	display:flex;
	justify-content:center;
	align-items:center;
	position:absolute;
	z-index:2;
	left:0; top:0;
	width:100%; height:100%;
	opacity:0;
	background:rgba(0,0,0,0.6);
	transition:opacity .3s ease;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta svg{
	display:block;
	margin:0 auto var(--aox-10px) auto;
	fill:#fff;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta .t_edit{
	display:block;
	text-align:center;
	font-size:var(--aox-fs-14px);
	color:#fff;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload{
	z-index:2;
	}
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload .obj_cta,
.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload .obj_cta:hover{
	background:transparent;
	}
/* var_galerie */
.obj_form .ctn_file-upload.var_galerie{
	width:44%;
	margin:0 2%;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .ctn_cta{
	margin:0;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .obj_cta{
	margin:0;
	width:100%; height:0;
	padding:0 0 100% 0;
	font-size:0;
	background:url(/dist/formationcaf/static/media/i_galerie-upload.6991ce22.svg) center center / 42% auto no-repeat #c3c3c3;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .ctn_file-thumb{
	position:unset;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .canvas_thumb{
	position:absolute;
	left:0; top:0;
	width:100%;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .bt_cancel,
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .bt_cancel:hover{
	position:relative;
	left:auto; top:auto;
	margin-left:auto;
	width:var(--aox-12px); height:var(--aox-16px);
	border-radius:0;
	-webkit-transform:none;
	        transform:none;
	background:url(/dist/formationcaf/static/media/i_trash.1be889ec.svg) center center / 100% auto no-repeat !important;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .bt_cancel::before,
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .bt_cancel::after{
	display:none;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .ctn_infos{
	display:flex;
	align-items:flex-end;
	width:100%;
	padding:10px;
	background:#f0f0f0;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .t_name{
	width:50%;
	overflow:hidden;
	white-space:nowrap;
	text-overflow:ellipsis;
	}
.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .t_weight{
	padding-left:5px;
	margin:0;
	}
/**/
@media screen and (min-width:1025px){
	.obj_form .ctn_file-upload.var_avatar .ctn_single-file-upload .obj_cta:hover{
		color:#fff;
		background-color:#777;
		}
	.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .obj_cta:hover{
		background-color:#777;
		}
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload.hover + .ctn_edit-cta .bt_edit-cta{
		opacity:1;
		}
	.obj_form .ctn_single-file-upload .bt_cancel:hover{
		color:#000;
		background:#777 !important;
		}
}
@media screen and (min-width:768px) and (max-width:960px){
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_single-file-upload .obj_cta{
		height:40vw;
		}
}
@media screen and (max-width:768px){
	.obj_form .ctn_file-upload.var_galerie .ctn_single-file-upload .t_weight{
		padding-left:0;
		}
}
@media screen and (max-width:767px){
	.obj_form .ctn_file-upload.var_avatar .ctn_single-file-upload .obj_cta{
		margin:0 auto;
		padding-bottom:25px;
		width:190px; height:190px;
		background-size:60px auto;
		}
	.obj_form .ctn_file-upload.var_avatar .ctn_single-file-upload .t_error{
		text-align:center;
		}
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta{
		left:50%;
    	width:190px; height:190px;
		padding-bottom:0;
		-webkit-transform:translateX(-50%);
		        transform:translateX(-50%);
		}
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta{
		opacity:1;
		background:transparent;
		}
	.obj_form .ctn_file-upload.var_avatar.var_edit .ctn_edit-cta .bt_edit-cta svg{
		width:50px;
		margin-bottom:5px;
		}
}

/* ======================================== */
/* obj_strength-level
/* ======================================== */
.obj_form .obj_strength-level{
	position:relative;
	width:100%; height:6px;
	background:#f2f2f2;
	}
.obj_form .obj_strength-level::before{
	position:absolute;
	left:0; top:0;
	width:0; height:100%;
	transition:all .3s ease;
	content:'';
	}
.obj_form .obj_strength-level[data-level="1"]::before,
.obj_form .obj_strength-level[data-level="2"]::before{
	width:33%;
	background:red;
	}
.obj_form .obj_strength-level[data-level="3"]::before,
.obj_form .obj_strength-level[data-level="4"]::before{
	width:66%;
	background:orange;
	}
.obj_form .obj_strength-level[data-level="5"]::before{
	width:100%;
	background:#0ABF04;
	}

/* ======================================== */
/* ctn_cta
/* ======================================== */
.obj_form .ctn_cta{
	display:flex;
	flex-flow:row wrap;
	}
.obj_form .ctn_cta .obj_cta{
	margin:0 10px 10px 0;
	}

/* ======================================== */
/* recaptcha v3
/* ======================================== */
.grecaptcha-badge{
	display:none !important;
	}


/* ======================================== */
/* ctn_checkbox_background 
/* ======================================== */
.obj_form .ctn_checkbox_background {
	position: relative;
	padding: var(--aox-30px) var(--aox-20px);
	background: #f2f2f2;
	}
.obj_form .ctn_checkbox_background .t_title{
	margin-bottom: var(--aox-10px);
    font-size: var(--aox-fs-15px);
    line-height: var(--aox-lh-120);
    letter-spacing: 0;
    font-weight: 700;
	}
.obj_form .ctn_checkbox_background .t_desc{
	font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    margin-top: var(--aox-20px);
    letter-spacing: 0;
	}
.obj_form .ctn_checkbox_background .ctn_line{
	margin-top: var(--aox-10px);
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox{
	display: none;
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox + .switch_checkbox_label > span{
	outline: 0;
    display: block;
    width: var(--aox-32px);
    height: var(--aox-18px);
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    background: #D9D9D9;
    border-radius: 2em;
    padding: 2px;
    margin-right: var(--aox-10px);
    transition: all 0.4s ease;
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox + .switch_checkbox_label > span:after{
	position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
	left: 0;
	border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox:checked + .switch_checkbox_label > span{
	background: #000000;
	}
.obj_form .ctn_checkbox_background .ctn_line .switch_checkbox:checked + .switch_checkbox_label > span:after{
	left: 50%;
	}

.obj_form .ctn_checkbox_background .ctn_line label{
	display: flex;
    margin-bottom: var(--aox-10px);
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    letter-spacing: 0;
    font-weight: 700;
    flex-direction: row;
    align-items: center;
	}

/* ======================================== */
/* obj_filter-input
/* ======================================== */

.obj_form .obj_filter-input{
	display:flex;
	margin-bottom:var(--aox-10px);
	padding-right:var(--aox-10px);
	}
.obj_form .obj_filter-input .t_filter-input{
	display:flex;
	align-items:center;
	font-size:var(--aox-fs-14px);
	width:var(--aox-100px);
	}
.obj_form .obj_filter-input .ctn_input{
	flex-grow:1;
	}
.obj_form .obj_filter-input .ctn_input input{
	position:relative;
	z-index:1;
	height:var(--aox-40px);
	padding-left:var(--aox-15px);
	letter-spacing:normal;
	color:#000;
	}
.obj_form .obj_filter-input .ctn_input input::-webkit-input-placeholder{
	font-size:var(--aox-fs-14px);
	text-transform:none;
	font-weight:400;
	}
.obj_form .obj_filter-input .ctn_input input::placeholder{
	font-size:var(--aox-fs-14px);
	text-transform:none;
	font-weight:400;
	}
.obj_form .obj_filter-input .bt_reset-input{
	position:absolute;
	z-index:2;
	right:var(--aox-10px); top:50%;
	width:var(--aox-20px); height:var(--aox-20px);
	border-radius:100vw;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	background:#000;
	}
.obj_form .obj_filter-input .bt_reset-input:hover{
	background:#777;
	}
.obj_form .obj_filter-input .bt_reset-input::before,
.obj_form .obj_filter-input .bt_reset-input::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	width:var(--aox-2px); height:var(--aox-14px);
	-webkit-transform:translate(-50%,-50%) rotate(45deg);
	        transform:translate(-50%,-50%) rotate(45deg);
	border-radius:100vw;
	background:#fff;
	}
.obj_form .obj_filter-input .bt_reset-input::after{
	-webkit-transform:translate(-50%,-50%) rotate(-45deg);
	        transform:translate(-50%,-50%) rotate(-45deg);
	}

@media screen and (max-width:767px){
	.obj_form .obj_filter-input{
		padding-right:var(--aox-5px);
		}
	.obj_form .obj_filter-input .ctn_input input::-webkit-input-placeholder{
		font-size:var(--aox-fs-12px);
		}
	.obj_form .obj_filter-input .ctn_input input::placeholder{
		font-size:var(--aox-fs-12px);
		}
	.obj_form .obj_filter-input .t_filter-input{
		width:var(--aox-120px);
		}
}
/* ======================================== */
/* .obj_popin
/* ======================================== */
.obj_popin{
    width:var(--aox-900px); max-width:90%;
	padding:var(--aox-60px) 0 var(--aox-60px) var(--aox-60px);
	background:#fff;
	}
.obj_popin .ctn_popin{
	max-height:75vh;
	padding-right:var(--aox-60px);
	overflow-y:auto;
	}
/**/
.obj_popin .bt_close{
	display:block;
	position:absolute;
	z-index:9999;
	right:0; top:0;
	width:var(--aox-60px); height:var(--aox-60px);
	background:#000;
	}
.obj_popin .bt_close::before,
.obj_popin .bt_close::after{
	position:absolute;
	left:50%; top:50%;
	background-color:#fff;
	transition:all .3s ease;
	content:'';
	}
.obj_popin .bt_close::before{
	width:2px; height:var(--aox-30px);
	-webkit-transform:translate(-50%,-50%) rotate(45deg);
	        transform:translate(-50%,-50%) rotate(45deg);
	}
.obj_popin .bt_close::after{
	width:var(--aox-30px); height:2px;
	-webkit-transform:translate(-50%,-50%) rotate(45deg);
	        transform:translate(-50%,-50%) rotate(45deg);
	}
/**/
.obj_popin .t_title{
	margin-bottom:var(--aox-50px);
	padding-right:var(--aox-20px);
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	text-transform:uppercase;
	}
.obj_popin .t_main{
	font-size:var(--aox-fs-16px); line-height:1.4;
	}
.obj_popin .t_main + .t_main{
	margin-top:var(--aox-20px);
	}
.obj_popin img{
	max-width:100%;
	}
.obj_popin .ctn_cta{
	display: flex;
	margin-top:var(--aox-30px);
	}
.obj_popin .ctn_cta .obj_cta + .obj_cta{
	margin-left:var(--aox-10px);
	}
.obj_popin .ctn_cta .obj_btn + .obj_btn{
	margin-left:var(--aox-10px);
	}

.obj_popin .ctn_input{
	position: relative;
	display: flex;
	margin-top:var(--aox-30px);
	}
.obj_popin .ctn_input input[type="text"]{
	width: 100%;
	height: var(--aox-60px);
	padding: var(--aox-10px) var(--aox-50px) var(--aox-10px) var(--aox-25px);
	font-size: var(--aox-fs-14px);
	line-height: 1;
	color: #777;
	font-weight: 500;
	border: 1px solid #777;
	}
.obj_popin .ctn_checkbox input[type=checkbox] {
	display: none;
	}
.obj_popin .ctn_checkbox label {
	display: block;
	position: relative;
	padding: 0 0 0 var(--aox-30px);
	min-height: var(--aox-20px);
	font-size: var(--aox-fs-12px);
	line-height: 1.2;
	color: #777;
	font-weight: 500;
	cursor: pointer;
	}
.obj_popin .ctn_checkbox label::before {
	position: absolute;
	left: 0;
	top: 0;
	width: var(--aox-16px);
	height: var(--aox-16px);
	border: 1px solid #777;
	background: #fff;
	transition: all .3s ease;
	content: '';
	}
.obj_popin .ctn_checkbox label::after {
	position: absolute;
	left: var(--aox-4px);
	top: var(--aox-4px);
	width: var(--aox-10px);
	height: var(--aox-10px);
	-webkit-transform: scale(0.3);
	        transform: scale(0.3);
	opacity: 0;
	background: #000;
	content: '';
	transition: all .3s ease;
	}
.obj_popin .ctn_checkbox input[type=checkbox]:checked + label::after {
	-webkit-transform: scale(1);
	        transform: scale(1);
	opacity: 1;
	}
.obj_popin .obj_infobulle .info_text {
	width: var(--aox-300px);
	}

/**/
.obj_popin.popin_video{
    width:100%; height:100%;
	max-width:unset;
	padding:0;
	}
.obj_popin.popin_video iframe{
	position:absolute;
	left:0; top:0;
    width:100%; height:100%;
	}
/**/
.obj_popin.popin_video-nofs .t_title{
	padding-left:var(--aox-20px);
	text-align:center;
	}
.obj_popin.popin_video-nofs .ctn_iframe{
	position:relative;
	height:0;
	padding-bottom:56.25%;
	}
.obj_popin.popin_video-nofs .ctn_iframe > div,
.obj_popin.popin_video-nofs .ctn_iframe iframe{
    position:absolute;
	left:0; top:0;
    width:100%; height:100%;
	}
/**/
.obj_popin.popin_expert .ctn_row{
	display:flex;
	flex-flow:row wrap;
	align-items:center;
	}
.obj_popin.popin_expert .ctn_row.var_star{
	padding:0 var(--aox-50px);
	}
.obj_popin.popin_expert .ctn_row .t_main{
	width:72%;
	padding-left:var(--aox-50px);
	}



.obj_popin.popin_expert .obj_circle-progress-expertise{
	max-width:var(--aox-220px);
	width:28%;
	}
.obj_popin.popin_expert .obj_circle-progress-expertise .ctn_txt{
	font-size:var(--aox-fs-40px);
	}
.obj_popin.popin_expert .obj_circle-progress-expertise .ctn_txt .t_title{
	margin-bottom:0;
	padding-right:0;
	}
.obj_popin.popin_expert .obj_circle-progress-expertise .ctn_avatar{
	width: var(--aox-64px);
    height: var(--aox-64px);
	}


.obj_popin.popin_expert .ctn_popin > .t_title{
	margin-bottom:var(--aox-40px);
	font-size:var(--aox-fs-40px);
	font-weight:300;
	text-transform:none;
	}
.obj_popin.popin_expert .ctn_grey{
	margin-top:var(--aox-40px);
	padding:var(--aox-30px);
	background:#f2f2f2;
	}
.obj_popin.popin_expert .obj_star-score{
	width:25%;
	margin-top:var(--aox-40px);
	}
.obj_popin.popin_expert .obj_star-score svg{
	width:var(--aox-75px);
	-webkit-transform:scale(1.35);
	        transform:scale(1.35);
	}
.obj_popin.popin_expert .obj_circle-progress.var_experte .CircularProgressbar .CircularProgressbar-path{
	stroke:url(#gradient-circleprogress-popin);
	}
.obj_popin.popin_expert .obj_star-score.var_experte svg{
	fill:url(#gradient-starscore-popin);
	}
/**/
.obj_popin.popin_galerie{
	width:var(--aox-1024px);
	}
.obj_popin.popin_galerie .ctn_popin{
	margin-top:var(--aox-75px);
	}
.obj_popin.popin_galerie .t_title{
	position:fixed;
	z-index:1;
	left:0; top:var(--aox-60px);
	width:100%;
	margin:0;
	padding:0;
	text-align:center;
	background:#fff;
	}
.obj_popin.popin_galerie .t_mention{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-10px);
	text-align:center;
	}
.obj_popin.popin_galerie .obj_form{
	display:flex;
	flex-wrap:wrap;
	}
.obj_popin.popin_galerie .obj_form .col_left{
	width:60%;
	}
.obj_popin.popin_galerie .obj_form .col_right{
	width:40%;
	padding-left:5%;
	padding-bottom:var(--aox-100px);
	}
.obj_popin.popin_galerie .obj_form .ctn_row{
	justify-content:center;
	}
.obj_popin.popin_galerie .obj_form .t_subtitle{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-18px); line-height:1;
	text-transform:uppercase;
	font-weight:700;
	text-align:center;
	}
.obj_popin.popin_galerie .t_main{
	text-align:center;
	}
.obj_popin.popin_galerie .ctn_label{
	max-height:var(--aox-125px);
	padding-right:var(--aox-10px);
	overflow-y:auto;
	}
.obj_popin.popin_galerie .ctn_label .t_label + .t_label{
	margin-top:var(--aox-20px);
	}
.obj_popin.popin_galerie .obj_form .col_right .ctn_cta{
	position:fixed;
    right:var(--aox-10px); bottom:0;
    width:36%;
    padding:var(--aox-10px) 0 var(--aox-60px) 0;
	margin:0;
    background:#fff;
	}
.obj_popin.popin_galerie .obj_form .col_right .ctn_cta .obj_cta{
	margin:0;
	}
.obj_popin.popin_galerie .obj_form .col_right .t_error{
	position:absolute;
	left:0; bottom:var(--aox-20px);
	padding-right:var(--aox-10px);
	}
/**/
.obj_popin.popin_galerie-comment{
	width:var(--aox-1024px);
	padding:0;
	}
.obj_popin.popin_galerie-comment .ctn_popin{
	display:flex;
	flex-wrap:wrap;
	padding:0;
	max-height:var(--aox-560px);
	}
.obj_popin.popin_galerie-comment .ctn_image{
	position:relative;
	width:54.7%;
	}
.obj_popin.popin_galerie-comment .ctn_image .ctn_solo-img,
.obj_popin.popin_galerie-comment .obj_ba-slider{
	height:0;
	padding-bottom:100%;
	overflow:hidden;
	}
.obj_popin.popin_galerie-comment .ctn_image .ctn_solo-img img{
	position:absolute;
	left:50%; top:0;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.obj_popin.popin_galerie-comment .obj_ba-slider .ctn_after-img{
	position:absolute;
	left:0; top:0;
	}
.obj_popin.popin_galerie-comment .ctn_text{
	position:relative;
	width:45.3%;
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_scroll{
	height:75%;
	overflow-y:auto;
	background:#f2f2f2;
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_top{
	padding:var(--aox-30px) var(--aox-60px) var(--aox-30px) var(--aox-30px);
	background:#fff;
	}
.obj_popin.popin_galerie-comment .ctn_avatar{
	display:flex;
	align-items:center;
	}
.obj_popin.popin_galerie-comment .ctn_avatar .ctn_img{
	margin-right:var(--aox-10px);
	width:var(--aox-40px); height:var(--aox-40px);
	border-radius:50%;
	overflow:hidden;
	}
.obj_popin.popin_galerie-comment .ctn_avatar .ctn_txt .t_author{
	font-size:var(--aox-fs-14px);
	font-weight:700;
	}
.obj_popin.popin_galerie-comment .ctn_avatar .ctn_txt .t_date{
	margin-top:var(--aox-5px);
	font-size:var(--aox-fs-12px);
	color:#666;
	}
.obj_popin.popin_galerie-comment .obj_likes-comments{
	display:flex;
	justify-content:flex-start;
	min-height:var(--aox-20px);
	margin:var(--aox-20px) 0 0 0;
	}
.obj_popin.popin_galerie-comment .obj_likes-comments li{
	margin-right:var(--aox-25px);
	line-height:var(--aox-20px);
	}
.obj_popin.popin_galerie-comment .obj_likes-comments .bt_like-article[disabled]{
	opacity:0.5;
	cursor:default;
	}
.obj_popin.popin_galerie-comment .obj_likes-comments .bt_like-article svg{
	transition:all .3s ease;
	}
.obj_popin.popin_galerie-comment .obj_likes-comments .bt_like-article.is-checked svg{
	fill:#000;
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_comments{
	padding:var(--aox-30px) var(--aox-60px) var(--aox-30px) var(--aox-30px);
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_comments .ctn_comment + .ctn_comment{
	margin-top:var(--aox-30px);
	}
.obj_popin.popin_galerie-comment .ctn_text .ctn_comments .ctn_comment .t_comment{
	margin-top:var(--aox-15px);
	font-size:var(--aox-fs-12px); line-height:1.2;
	}
.obj_popin.popin_galerie-comment .ctn_text .t_submitted{
	padding:var(--aox-30px) var(--aox-60px) var(--aox-30px) var(--aox-30px);
	font-size:var(--aox-fs-12px); line-height:1.2;
	}
.obj_popin.popin_galerie-comment .obj_form{
	position:absolute;
	left:0; bottom:0;
	width:100%; height:25%;
	overflow:hidden;
	background:#fff;
	transition:height .3s ease;
	}
.obj_popin.popin_galerie-comment .obj_form.is-expanded{
	height:75%;
	border-top:1px solid #f2f2f2;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_textarea textarea{
	height:var(--aox-70px);
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_cta{
	position:absolute;
	left:var(--aox-20px); bottom:var(--aox-20px);
	margin:0;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_cta .obj_cta{
	margin:0;
	padding:0;
	}
.obj_popin.popin_galerie-comment .obj_form .t_error{
	padding:0 var(--aox-20px);
	}
.obj_popin.popin_galerie-comment .obj_form .obj_cta-txt{
	position:absolute;
	padding:0 var(--aox-17px) 0 0;
	right:var(--aox-20px); bottom:var(--aox-20px);
	font-size:var(--aox-fs-10px);
	color:#b2b2b2;
	text-align:left;
	}
.obj_popin.popin_galerie-comment .obj_form .obj_cta-txt::before{
	content:'';
	position:absolute;
	right:2px; top:0;
	width:6px; height:6px;
	border:1px solid #b2b2b2;
	border-left:none;
	border-bottom:none;
	-webkit-transform:scaleY(1) rotate(135deg);
	        transform:scaleY(1) rotate(135deg);
	transition:all .3s ease;
	}
.obj_popin.popin_galerie-comment .obj_form.obj_form.is-expanded .obj_cta-txt::before{
	top:3px;
	-webkit-transform:scaleY(-1) rotate(135deg);
	        transform:scaleY(-1) rotate(135deg);
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions{
	position:absolute;
	left:0; top:var(--aox-100px);
	padding:var(--aox-20px);
	height:63%;
	overflow-y:auto;
	-webkit-transform:translateX(-100%);
	        transform:translateX(-100%);
	opacity:0;
	transition:opacity .3s ease .3s;
	}
.obj_popin.popin_galerie-comment .obj_form.is-expanded .ctn_txt-mentions{
	-webkit-transform:translateX(0);
	        transform:translateX(0);
	opacity:1;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions .t_title-mention{
	font-size:var(--aox-fs-20px); line-height:1.2;
	font-weight:700;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions .t_title-desc{
	margin-top:var(--aox-20px);
	font-size:var(--aox-fs-10px); line-height:1.2;
	}
.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions .t_title-desc a{
	text-decoration:underline;
	}
/**/
.ReactModal__Overlay{
    opacity:0;
    transition:opacity .3s ease;
	}
.ReactModal__Overlay--after-open{
    opacity:1;
	}
.ReactModal__Overlay--before-close{
    opacity:0;
	}



/**/
.obj_popin.popin_post-fb{
	width:var(--aox-1024px);
	padding:0;
	}
.obj_popin.popin_post-fb .ctn_popin{
	display:flex;
	flex-wrap:wrap;
	padding:0;
	max-height:var(--aox-700px);
	}
.obj_popin.popin_post-fb .ctn_image{
	position:relative;
	width:54.7%;
	}
.obj_popin.popin_post-fb .ctn_image .ctn_solo-img,
.obj_popin.popin_post-fb .obj_ba-slider{
	position:relative;
	height:0;
	padding-bottom:125%;
	overflow:hidden;
	background:#000;
	}
.obj_popin.popin_post-fb .ctn_image .ctn_solo-img img{
	position:absolute;
	left:50%; top:0;
	width:100%; height:100%;
	object-fit:contain;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.obj_popin.popin_post-fb .obj_ba-slider .ctn_after-img{
	position:absolute;
	left:0; top:0;
	}
.obj_popin.popin_post-fb .ctn_text{
	position:relative;
	width:45.3%;
	}
.obj_popin.popin_post-fb .ctn_text .ctn_scroll{
	height:60%;
	overflow-y:auto;
	background:#ffffff;
	}
.obj_popin.popin_post-fb .ctn_text .ctn_post-fb{
	padding:var(--aox-40px);
	}
.obj_popin.popin_post-fb .ctn_top{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
	}
.obj_popin.popin_post-fb .ctn_top .t_title{
	position: relative;
	min-height: var(--aox-40px);
	font-family: 'Montserrat', sans-serif;
    font-weight: 700;
	padding-left: var(--aox-50px);
    font-size:var(--aox-fs-20px);
    line-height: 1.1;
	margin: 0;
	}
.obj_popin.popin_post-fb .ctn_top .t_title:before {
	content: "";
	position: absolute;
	left: 0; top: 0;
	height: var(--aox-40px);
	width: var(--aox-40px);
	background: url(/dist/formationcaf/static/media/i_facebook-picto.63bc9546.svg) left top / var(--aox-40px) auto no-repeat;
	}	
.obj_popin.popin_post-fb .ctn_top .obj_likes-fb {
	justify-content: flex-start;
	margin: var(--aox-20px) 0 0 0;
	}
.obj_popin.popin_post-fb .ctn_top .obj_likes-fb li {
	padding: 3px 0 0 var(--aox-25px);
	margin-right: var(--aox-20px);
	font-size:var(--aox-fs-11px);
	}
.obj_popin.popin_post-fb .ctn_post-fb .ctn_txt .t_desc{
	margin-top:var(--aox-15px);
    font-size:var(--aox-fs-14px); line-height: 1.57143;
    font-weight: 300;
	}
.obj_popin.popin_post-fb .ctn_bottom{
	position: absolute;
    display: flex;
    left: 0; bottom: 0;
    width: 100%; height: 40%;
    overflow: hidden;
    background: #fff;
    padding: var(--aox-40px);
    transition: height .3s ease;
    flex-direction: column;
    justify-content: flex-end;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_cta{
	position: relative;
    display: flex;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_cta .obj_cta{
	margin:0;
	color: #000;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_cta .obj_cta + .obj_cta{
	margin-left: var(--aox-10px);
	}
.obj_popin.popin_post-fb .ctn_bottom .t_error{
	padding:0 var(--aox-20px);
	}
.obj_popin.popin_post-fb .ctn_bottom .obj_cta-txt{
	position:absolute;
	padding:0 var(--aox-17px) 0 0;
	right:var(--aox-20px); bottom:var(--aox-23px);
	font-size:var(--aox-fs-10px);
	color:#b2b2b2;
	text-align:left;
	}
.obj_popin.popin_post-fb .ctn_bottom .obj_cta-txt::before{
	content:'';
	position:absolute;
	right:2px; top:0;
	width:6px; height:6px;
	border:1px solid #b2b2b2;
	border-left:none;
	border-bottom:none;
	-webkit-transform:scaleY(1) rotate(135deg);
	        transform:scaleY(1) rotate(135deg);
	transition:all .3s ease;
	}
.obj_popin.popin_post-fb .ctn_bottom.obj_form.is-expanded .obj_cta-txt::before{
	top:3px;
	-webkit-transform:scaleY(-1) rotate(135deg);
	        transform:scaleY(-1) rotate(135deg);
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage{
	position:relative;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ul,
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ol{
	font-size:var(--aox-fs-14px); line-height: 1.57143;
	list-style-type: none;
    counter-reset: counterRules;
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ul li,
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ol li{
	position: relative;
    margin-top: var(--aox-10px);
    font-size:var(--aox-fs-14px); line-height: 1.57143;
    font-weight: 300;
    padding-left: var(--aox-35px);
	}
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ul li strong,
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ol li strong{
	font-weight: 700;
	}	
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ul li:before,
.obj_popin.popin_post-fb .ctn_bottom .ctn_txt-partage ol li:before{
	position: absolute;
    left: 0;
    width: var(--aox-24px); height: var(--aox-24px);
    background-color: #f7c74d;
    color: #000;
    font-weight: 700;
    border-radius: 50%;
    text-align: center;
    counter-increment: counterRules 1;
    content: counter(counterRules);
	}


/***/


/**/
.obj_popin.popin_produits-concernes .ctn_popin{
	max-height:var(--aox-700px);
	}
.obj_popin.popin_produits-concernes{
	width:var(--aox-1024px);
	}
.obj_popin.popin_produits-concernes .ctn_title .t_title{
	font-size: var(--aox-fs-40px);
	line-height: var(--aox-lh-100);
	font-weight: 300;
	text-transform: none;
	margin-bottom: var(--aox-25px);
	}




/**/
@media screen and (min-width:1025px){
	.obj_popin .bt_close:hover{
		background:#777;
		}
	.obj_popin.popin_galerie-comment .obj_likes-comments .bt_like-article:hover svg{
		fill:#000;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_cta .obj_cta:hover{
		color:#000;
		background:none;
		}
}
@media screen and (max-width:1024px){
	.obj_popin{
		padding:6vw 0 6vw 6vw;
		}
	.obj_popin .ctn_popin{
		padding-right:6vw;
		}
	.obj_popin .bt_close{
		width:6vw; height:6vw;
		}
	.obj_popin .bt_close::before{
		height:3vw;
		}
	.obj_popin .bt_close::after{
		width:3vw;
		}
}
@media screen and (max-height:800px) and (min-width:1024px){
	.obj_popin{
		padding:7.5vh 0 7.5vh 7.5vh;
		}
	.obj_popin .ctn_popin{
		padding-right:7.5vh;
		}
	.obj_popin .bt_close{
		width:7.5vh; height:7.5vh;
		}
	.obj_popin .bt_close::before{
		height:3.75vh;
		}
	.obj_popin .bt_close::after{
		width:3.75vh;
		}
	.obj_popin.popin_galerie .t_title{
		top:7.5vh;
		}
	.obj_popin.popin_galerie .obj_form .col_right .ctn_cta{
		padding-bottom:7.5vh;
		}
	.obj_popin.popin_galerie .obj_form .col_right .t_error{
		bottom:2.5vh;
		}
}
@media screen and (max-width:768px){
	.obj_popin.popin_expert .ctn_popin > .t_title{
		font-size:var(--aox-fs-36px);
		}
	.obj_popin.popin_expert .obj_circle-progress{
		width:var(--aox-205px);
		}
	.obj_popin.popin_expert .ctn_row .t_main{
		width:100%;
		padding-left:0;
		}
	.obj_popin.popin_expert .ctn_row + .t_title{
		margin-top:var(--aox-40px);
		}
	.obj_popin.popin_galerie-comment .ctn_image,
	.obj_popin.popin_galerie-comment .ctn_text{
		width:100%;
		}
	.obj_popin.popin_post-fb .ctn_image,
	.obj_popin.popin_post-fb .ctn_text{
		width:100%;
		}	
	.obj_popin.popin_post-fb .ctn_text{
		height:auto;
		}	

	.obj_popin.popin_post-fb .ctn_text .ctn_scroll{
		height:auto;
		}
	.obj_popin.popin_post-fb .ctn_bottom{
		position:relative;
		left:auto; bottom:auto;
		height:auto;
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_post-fb{
		padding-bottom:0;
		}
}
@media screen and (min-width:768px) and (max-width:768px){
	.obj_popin.popin_galerie-comment .ctn_image img{
		max-width:calc(var(--aox-420px) + var(--aox-5px));
		margin:0 auto;
		}
	.obj_popin.popin_galerie-comment .ctn_image .ctn_solo-img{
		padding-bottom:62%;
		}
	.obj_popin.popin_galerie-comment .obj_ba-slider{
		padding-bottom:50%;
		}
	.obj_popin.popin_post-fb .ctn_image img{
		max-width:calc(var(--aox-420px) + var(--aox-5px));
		margin:0 auto;
		}
	.obj_popin.popin_post-fb .ctn_image .ctn_solo-img{
		padding-bottom:62%;
		}
	.obj_popin.popin_post-fb .obj_ba-slider{
		padding-bottom:50%;
		}	
}
@media screen and (max-width:767px){
	.ReactModal__Body--open .main_header,
	.ReactModal__Body--open .main_footer,
	.ReactModal__Body--open .main_footer-login,
	.ReactModal__Body--open .main_container{
		display:none;
		}
	.ReactModal__Body--open .ReactModal__Overlay{
		position:relative !important;
		left:auto !important; right:auto !important;
		top:auto !important; bottom:auto !important;
		background:none !important;
		}
	.ReactModal__Body--open .obj_popin{
		position:relative !important;
		left:auto !important; right:auto !important;
		top:auto !important; bottom:auto !important;
		max-width:100%;
		padding:var(--aox-40px) 0 var(--aox-40px) var(--aox-40px);
		-webkit-transform:none !important;
		        transform:none !important;
		}
	.ReactModal__Body--open .obj_popin.popin_video{
		padding:0 0 56% 0;
		}
	.ReactModal__Body--open .obj_popin::before{
		position:fixed;
		left:0; top:0;
		width:100%; height:var(--aox-40px);
		background:#fff;
		content:'';
		}
	.ReactModal__Body--open .obj_popin .ctn_popin{
		margin-top:0;
		max-height:unset;
		padding-right:var(--aox-40px);
		overflow-y:unset;
		}
	.obj_popin .bt_close,
	.ReactModal__Body--open .obj_popin .bt_close{
		position:fixed;
		width:var(--aox-40px); height:var(--aox-40px);
		}
	.obj_popin .bt_close::before,
	.ReactModal__Body--open .obj_popin .bt_close::before{
		height:var(--aox-20px);
		}
	.obj_popin .bt_close::after,
	.ReactModal__Body--open .obj_popin .bt_close::after{
		width:var(--aox-20px);
		}
	.ReactModal__Body--open .obj_popin .t_title{
		position:relative;
		left:auto; top:auto;
		margin-bottom:var(--aox-30px);
		font-size:var(--aox-fs-16px);
		}
	.ReactModal__Body--open .obj_popin .t_main + .t_main{
		margin-top:var(--aox-15px);
		}
	.obj_popin.popin_expert .ctn_row.var_star{
		padding:0;
		}
	.obj_popin.popin_expert .obj_star-score .t_status{
		margin-top:calc(-1 * var(--aox-15px));
		font-size:var(--aox-fs-13px);
		}
	.obj_popin.popin_expert .obj_star-score svg{
		-webkit-transform:scale(1);
		        transform:scale(1);
		}
	.obj_popin.popin_galerie .obj_form .col_left{
		width:100%;
		}
	.obj_popin.popin_galerie .obj_form .col_right{
		width:100%;
		padding-left:0;
		padding-top:var(--aox-30px);
		padding-bottom:0;
		}
	.obj_popin.popin_galerie .obj_form .ctn_single-file-upload .t_name{
		font-size:var(--aox-fs-12px);
		}
	.obj_popin.popin_galerie .obj_form .ctn_single-file-upload .t_weight{
		font-size:var(--aox-fs-10px);
		}
	
	.obj_popin.popin_galerie .obj_form .col_right .ctn_cta{
		position:relative;
		right:auto; bottom:auto;
		width:100%;
		padding:0;
		margin-top:var(--aox-30px);
		}
	.obj_popin.popin_galerie .obj_form .col_right .t_error{
		position:relative;
		left:auto; bottom:auto;
		margin-top:var(--aox-20px);
		}

	.obj_popin.popin_galerie-comment .ctn_text .ctn_top,
	.obj_popin.popin_galerie-comment .ctn_text .ctn_comments,
	.obj_popin.popin_galerie-comment .ctn_text .t_submitted{
		padding:var(--aox-20px);
		}
	.obj_popin.popin_galerie-comment .obj_likes-comments{
		flex-direction:column;
		}
	.obj_popin.popin_galerie-comment .obj_likes-comments li + li{
		margin-top:var(--aox-15px);
		}
	.obj_popin.popin_galerie-comment .ctn_text .ctn_scroll{
		height:auto;
		}
	.obj_popin.popin_galerie-comment .obj_form,
	.obj_popin.popin_galerie-comment .obj_form.is-expanded{
		position:relative;
		left:auto; top:auto;
		height:auto;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_cta,
	.obj_popin.popin_galerie-comment .obj_form .obj_cta-txt,
	.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions{
		position:relative;
		left:auto; right:auto;
		top:auto; bottom:auto;
		height:auto;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_cta{
		padding:var(--aox-20px);
		}
	.obj_popin.popin_galerie-comment .obj_form .obj_cta-txt{
		padding:0 var(--aox-17px) 0 var(--aox-20px);
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions,
	.obj_popin.popin_galerie-comment .obj_form.is-expanded .ctn_txt-mentions{
		-webkit-transform:translateX(0);
		        transform:translateX(0);
		opacity:1;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_txt-mentions{
		display:none;
		}
	.obj_popin.popin_galerie-comment .obj_form.is-expanded .ctn_txt-mentions{
		display:block;
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_textarea{
		margin-top:var(--aox-10px);
		}
	.obj_popin.popin_galerie-comment .obj_form .ctn_textarea textarea{
		height:var(--aox-100px);
		}
	
	.obj_popin.popin_video-nofs{
		top:auto !important;
		bottom:5px !important;
		padding:0;
		max-width:calc(100% - var(--aox-10px));
		-webkit-transform:translate(-50%,0) !important;
		        transform:translate(-50%,0) !important;
		}
	.obj_popin.popin_video-nofs .ctn_popin{
		padding-right:0;
		}
	.obj_popin.popin_video-nofs .t_title{
		padding:var(--aox-30px) var(--aox-50px) 0 var(--aox-50px);
		margin-bottom:var(--aox-20px);
		font-size:var(--aox-fs-16px);
		}

	.obj_popin.popin_post-fb .ctn_image .ctn_solo-img{
		height: unset;
		padding-bottom: unset;
		overflow: unset;
		}
	.obj_popin.popin_post-fb .ctn_image .ctn_solo-img img{
		position: relative;
		}	
	.obj_popin.popin_post-fb .ctn_text .ctn_top{
		padding:0;
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_top{
		padding-right: 0;
		}
	.obj_popin.popin_post-fb .obj_likes-comments{
		flex-direction:column;
		}
	.obj_popin.popin_post-fb .obj_likes-comments li + li{
		margin-top:var(--aox-15px);
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_scroll{
		height:auto;
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_post-fb{
		padding:var(--aox-20px) 0px;
		}
	.obj_popin.popin_post-fb .ctn_text .ctn_bottom{
		position: relative;
		height: auto;
		padding: var(--aox-20px) 0 0;
		}

	.obj_popin.popin_expert .obj_circle-progress-expertise {
		width: var(--aox-220px);
		margin: 0 auto var(--aox-20px) auto;
		}

	.ReactModal__Body--open .obj_popin.popin_produits-concernes{
		padding: var(--aox-25px) var(--aox-25px) var(--aox-25px) var(--aox-30px);
		}
	.ReactModal__Body--open .obj_popin.popin_produits-concernes .ctn_popin {
		padding-right: 0;
		}
	.obj_popin.popin_produits-concernes .ctn_title {
		max-width: 90%;
		}
	.obj_popin.popin_produits-concernes .ctn_title .t_title{
		font-size: var(--aox-fs-36px);
		line-height: var(--aox-lh-100);
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row {
		flex-direction: column;
		align-items: flex-start;
		padding: var(--aox-10px) 0;
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row .ctn_row-title {
		font-weight: 500;
		width: 100%;
		margin-bottom: var(--aox-8px);
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row > .ctn_row {
		flex-direction: row;
		padding: var(--aox-3px) 0;
		width: 100%;
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row > .ctn_row + .ctn_row {
		border-top: none;
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row > .ctn_row .t_title{
		font-size: var(--aox-fs-12px);
		color: #7F7F7F;
		margin-bottom: 0;
		width: 40%;
		}
	.obj_popin.popin_produits-concernes .obj_table-produit-concernes .ctn_row > .ctn_row p:nth-child(2) {
		font-weight: 500;
		}



}
@media screen and (max-width:360px){
	.obj_popin.popin_expert .obj_star-score{
		width:100%;
		margin-top:var(--aox-20px);
		}
}



/* ======================================== */
/* .popin_overlay_badge_detail
/* ======================================== */

.ReactModal__Body--open .main_header,
.ReactModal__Body--open .main_container,
.ReactModal__Body--open .main_footer,
.ReactModal__Body--open_blur .main_header,
.ReactModal__Body--open_blur .main_container,
.ReactModal__Body--open_blur .main_footer{
	-webkit-filter: blur(5px);
	        filter: blur(5px);
	}
.obj_popin.popin_overlay_badge_detail{
	width: 100vw; height: 100vh;
    max-width: 100%;
    padding: var(--aox-60px);
	background: rgba(0,0,0,0);
	}
.obj_popin.popin_overlay_badge_detail .overlay-1,
.obj_popin.popin_overlay_badge_detail .overlay-2{
	content:'';
	position:absolute;
	z-index:1;
	left:0; top:0;
	background:#9FD6E3;
	width:100%; height:0;
	opacity:0.6;
	transition:all 0.6s ease;
	}
.obj_popin.popin_overlay_badge_detail .overlay-2{
	z-index:2;
	background:#00ACC2;
	opacity:0.4;
	}
.obj_popin.popin_overlay_badge_detail.ReactModal__Content--after-open .overlay-1,
.obj_popin.popin_overlay_badge_detail.ReactModal__Content--after-open .overlay-2{
	height:100%;
	}
.obj_popin.popin_overlay_badge_detail .bt_close {
	right: var(--aox-60px);
	top: var(--aox-60px);
	width: var(--aox-80px);
	height: var(--aox-80px);
	background: transparent;
	}
.obj_popin.popin_overlay_badge_detail .bt_close::before {
	height: var(--aox-36px);
	}
.obj_popin.popin_overlay_badge_detail .bt_close::after {
	width: var(--aox-36px);
	}
.obj_popin.popin_overlay_badge_detail .ctn_popin {
	position: relative;
	z-index: 3;
	height: calc(100vh - var(--aox-120px));
	max-height: unset;
	padding-right: unset;
	overflow-y: unset;
	width: var(--aox-grid);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	text-align: center;
	}



/***/

@media screen and (max-width:767px){

	.obj_popin.popin_overlay_badge_detail .ctn_popin {
		width: 100%;
		height: 90vh;
		}
	.three-is-ready.ReactModal__Body--open .obj_popin::before{
		position:relative;
		}

}


/* popin_small */

.obj_popin.popin_small{
    width: var(--aox-500px);
	}
.obj_popin.popin_small .ctn_checkbox{
	margin-top: var(--aox-20px);
	}


/***/

@media screen and (max-width:767px){

	
}



/* popin_txt_medium */

.obj_popin.popin_txt_medium{
    width: var(--aox-740px);
	}
.obj_popin.popin_txt_medium .t_title{
	font-size: var(--aox-fs-16px);
	margin-bottom: var(--aox-10px);
	}
.obj_popin.popin_txt_medium .t_main{
	font-size: var(--aox-fs-15px);
    line-height: var(--aox-lh-160);
	margin-bottom: var(--aox-40px);
	}
.obj_popin.popin_txt_medium .t_subtitle{
	font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-170);
    font-weight: 700;
    text-transform: uppercase;
	}
.obj_popin.popin_txt_medium ul{
	padding: 0 0 0 var(--aox-20px)
	}
.obj_popin.popin_txt_medium ul li{
	position: relative;
    margin-top: var(--aox-4px);
    font-size: var(--aox-fs-15px);
    line-height: var(--aox-lh-130);
    font-weight: 300;
	list-style-type: disc;
	}
.obj_popin.popin_txt_medium ul + .t_subtitle {
	margin-top: var(--aox-16px);
	}
/* ======================================== */
/* .obj_infobulle
/* ======================================== */
.obj_infobulle{
	position:absolute;
	right:var(--aox-20px); top:var(--aox-20px);
	}
.obj_infobulle .info_picto{
	width:var(--aox-22px); height:var(--aox-22px);
	padding-top:1px;
	padding-right:1px;
	font-size:var(--aox-fs-16px); line-height:var(--aox-22px);
	font-weight:700;
	color:#fff;
	text-align:center;
	background:#000;
	border-radius:50%;
	cursor:pointer;
	transition:all .3s ease;
	}
.obj_infobulle .info_text{
	position:absolute;
	z-index:7777;
	right:var(--aox-27px); top:-5px;
	width:var(--aox-350px); max-height:0;
	font-size:var(--aox-fs-12px); line-height:1.5;
	color:#fff;
	background:#000;
	overflow:hidden;
	opacity:0;
	transition:all .3s ease;
	}
.obj_infobulle .info_text span{
	display:block;
	padding:var(--aox-15px);
	}
.obj_infobulle .info_picto:hover + .info_text{
	max-height:var(--aox-105px);
	opacity:1;
	}

@media screen and (min-width:1025px){
	.obj_infobulle .info_picto:hover{
		background:#777;
		}
}
@media screen and (max-width:767px){
	.obj_infobulle .info_text{
		width:var(--aox-265px);
		}
}
@media screen and (max-width:320px){
	.obj_infobulle .info_text{
		width:var(--aox-230px);
		}
}
/* ======================================== */
/* .obj_likes-comments
/* ======================================== */
.obj_likes-comments{
	min-height:var(--aox-16px);
	margin:var(--aox-35px) 0;
	display:flex;
	justify-content:flex-end;
	}
.obj_likes-comments li{
	position:relative;
	padding-left:var(--aox-35px);
	margin-right:var(--aox-35px);
	font-size:var(--aox-fs-13px); line-height:1;
	color:#777;
	}
.obj_likes-comments li + li{
	margin-right:var(--aox-20px);
	}
.obj_likes-comments li svg{
	position:absolute;
	left:0; top:50%;
	fill:#c3c3c3;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
.obj_likes-comments .ctn_like-article,
.obj_likes-comments .ctn_comment-article{
	padding-left:0;
	}
.obj_likes-comments .ctn_like-article .bt_like-article{
	position:relative;
	display:block;
	padding-left:var(--aox-35px);
	padding-top:var(--aox-5px);
	padding-bottom:var(--aox-5px);
	font-size:var(--aox-fs-13px); line-height:1;
	color:#777;
	}
.obj_likes-comments .ctn_like-article .bt_like-article[disabled]{
	opacity:0.5;
	cursor:default;
	}
.obj_likes-comments .ctn_comment-article .bt_comment-article{
	position:relative;
	display:block;
	padding-left:var(--aox-35px);
	padding-top: var(--aox-5px);
	padding-bottom: var(--aox-5px);
	}

@media screen and (min-width:1025px){
	.obj_likes-comments .ctn_comment-article .bt_comment-article:hover{
		color:#000;
		}
}
@media screen and (max-width:767px){
	.obj_likes-comments{
		margin-top:var(--aox-20px);
		margin-bottom:var(--aox-30px);
		}
	.obj_likes-comments li{
		padding-left:var(--aox-30px);
		margin-right:var(--aox-20px);
		}
	.obj_likes-comments .ctn_like-article .bt_like-article{
		padding-left:var(--aox-30px);
		}
	.obj_likes-comments li + li{
		margin-right:0;
		}
	.obj_likes-comments li span{
		display:none;
		}
}
/* ======================================== */
/* .obj_likes-fb
/* ======================================== */
.obj_likes-fb{
	min-height:var(--aox-16px);
	margin:var(--aox-35px) 0;
	display:flex;
	justify-content:flex-end;
	}
.obj_likes-fb li{
	position:relative;
	padding-left:var(--aox-35px);
	margin-right:var(--aox-35px);
	font-size:var(--aox-fs-13px); line-height:1;
	color:#777;
	}
.obj_likes-fb li + li{
	margin-right:var(--aox-20px);
	}
.obj_likes-fb li svg{
	position:absolute;
	left:0; top:50%;
	fill:#c3c3c3;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
.obj_likes-fb .ctn_like-article,
.obj_likes-fb .ctn_comment-article{
	padding-left:0;
	}
.obj_likes-fb .ctn_like-article .bt_like-article{
	position:relative;
	display:block;
	padding-left:var(--aox-35px);
	padding-top:var(--aox-5px);
	padding-bottom:var(--aox-5px);
	font-size:var(--aox-fs-13px); line-height:1;
	color:#777;
	}
.obj_likes-fb .ctn_like-article .bt_like-article[disabled]{
	opacity:0.5;
	cursor:default;
	}
.obj_likes-fb .ctn_comment-article .bt_comment-article{
	position:relative;
	display:block;
	padding-left:var(--aox-35px);
	padding-top: var(--aox-5px);
	padding-bottom: var(--aox-5px);
	}

@media screen and (min-width:1025px){
	.obj_likes-fb .ctn_comment-article .bt_comment-article:hover{
		color:#000;
		}
}
@media screen and (max-width:767px){
	.obj_likes-fb{
		margin-top:var(--aox-20px);
		margin-bottom:var(--aox-30px);
		}
	.obj_likes-fb li{
		padding-left:var(--aox-30px);
		margin-right:var(--aox-20px);
		}
	.obj_likes-fb .ctn_like-article .bt_like-article{
		padding-left:var(--aox-30px);
		}
	.obj_likes-fb li + li{
		margin-right:0;
		}
	.obj_likes-fb li span{
		display:none;
		}
}
/* ======================================== */
/* .obj_breadcrumb
/* ======================================== */
.obj_breadcrumb{
	position:relative;
	z-index:2;
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-20px) 0 0 0;
	}
.obj_breadcrumb li{
	font-size:var(--aox-fs-12px); line-height:1;
	}
.obj_breadcrumb li a,
.obj_breadcrumb li span{
	display:block;
	position:relative;
	padding:var(--aox-5px) var(--aox-25px) var(--aox-5px) 0;
	}
.obj_breadcrumb li a::before{
	content:'';
	position:absolute;
	right:var(--aox-10px); top:50%;
	width:6px; height:6px;
	border-top:solid 2px #c3c3c3;
	border-right:solid 2px #c3c3c3;
	-webkit-transform:translateY(-50%) rotate(45deg);
	        transform:translateY(-50%) rotate(45deg);
  	transition:all .3s ease;
	}
.obj_breadcrumb li span{
	font-weight:700;
	}

@media screen and (max-width:1280px){
	/* .obj_breadcrumb{
		padding-left:var(--aox-20px);
		} */
}
/* ======================================== */
/* .obj_back-page
/* ======================================== */
.obj_back-page{
	width:100%;
	max-width:calc(var(--aox-1090px) + var(--aox-5px));
	margin:var(--aox-30px) auto 0 auto;
	}
.obj_back-page .bt_back-page{
	display:inline-block;
	position:relative;
	padding:var(--aox-5px) var(--aox-5px) var(--aox-5px) var(--aox-30px);
	font-size:var(--aox-fs-14px); line-height:1;
	}
.obj_back-page .bt_back-page::before{
	content:'';
	position:absolute;
	left:0; top:50%;
	margin-top:-1px;
	width:var(--aox-18px); height:2px;
	background:#000;
	transition:all .3s ease;
	}
.obj_back-page .bt_back-page::after{
	content:'';
	position:absolute;
	left:0; top:50%;
	width:8px; height:8px;
	border-top:2px solid #000;
	border-left:2px solid #000;
	-webkit-transform:translateY(-50%) rotate(-45deg);
	        transform:translateY(-50%) rotate(-45deg);
	transition:all .3s ease;
	}

@media screen and (min-width:1025px){
	.obj_back-page .bt_back-page:hover::before{
		-webkit-transform:translateX(-5px);
		        transform:translateX(-5px);
		}
	.obj_back-page .bt_back-page:hover::after{
		-webkit-transform:translateX(-5px) translateY(-50%) rotate(-45deg);
		        transform:translateX(-5px) translateY(-50%) rotate(-45deg);
		}
}
@media screen and (max-width:1095px){
	.obj_back-page{
		padding:0 var(--aox-20px);
		}
}
/* ======================================== */
/* .obj_back-page
/* ======================================== */
.obj_loader{
	box-sizing:content-box;
	margin-top:var(--aox-20px);
	width:var(--aox-50px); height:var(--aox-50px);
	border:5px solid #b3b3b3;
	border-top-color:#666;
	border-bottom-color:#666;
	border-radius:var(--aox-50px);
	-webkit-animation:rotationLoop 1s ease infinite;
	        animation:rotationLoop 1s ease infinite;
	}
/* ======================================== */
/* .obj_tabs-list
/* ======================================== */
.section_page-liste .obj_tabs-list .ctn_page-liste h3{
	text-transform:uppercase;
	}
/**/
.obj_tabs-list .ctn_nav-tabs{
	display:flex;
	justify-content:center;
	margin-bottom:var(--aox-60px);
	border-bottom:1px solid #c3c3c3;
	}
.obj_tabs-list .ctn_nav-tabs li{
	width:26%;
	}
.obj_tabs-list .ctn_nav-tabs li:first-of-type{
	margin-right:8%;
	}
.obj_tabs-list .ctn_nav-tabs li:last-of-type{
	margin-left:8%;
	}
.obj_tabs-list .ctn_nav-tabs li button{
	width:100%;
	padding:var(--aox-20px) 0;
	margin-top:calc(-1 * var(--aox-20px));
	font-size:var(--aox-fs-18px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	text-align:center;
	border-bottom:5px solid transparent;
	transition:all .3s ease;
	}
.obj_tabs-list .ctn_nav-tabs li.active button{
	border-bottom-color:#000;
	}
.obj_tabs-list .ctn_row{
	display:none;
	}
.obj_tabs-list .ctn_row.active{
	display:block;
	}
/**/
@media screen and (max-width:767px){
	.obj_tabs-list .ctn_nav-tabs{
		margin-left: calc(-1 * var(--aox-gutter));
		width: calc(100% + var(--aox-gutter) + var(--aox-gutter));
		}
	.obj_tabs-list .ctn_nav-tabs li{
		width:50%;
		}
	.obj_tabs-list .ctn_nav-tabs li button{
		padding-left:var(--aox-5px);
		padding-right:var(--aox-5px);
		}
	.obj_tabs-list .ctn_nav-tabs li:first-of-type{
		margin-right:0;
		}
	.obj_tabs-list .ctn_nav-tabs li:last-of-type{
		margin-left:0;
		}
}
/* ======================================== */
/* .obj_banner-product
/* ======================================== */
.obj_banner-product{
	position:relative;
	z-index:1;
	}
.obj_banner-product figure{
	position:relative;
	z-index:1;
	}
.obj_banner-product figure img{
	width:100%;
	}
.obj_banner-product .ctn_text{
	position:absolute;
	z-index:2;
	left:5%; top:50%;
	padding-right:var(--aox-20px);
	width:60%;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
.obj_banner-product .ctn_text h1{
	font-weight:700;
	font-size:var(--aox-fs-50px); line-height:1.2;
	text-transform:uppercase;
	}
.obj_banner-product .ctn_text h2{
	font-weight:700;
	font-size:var(--aox-fs-46px); line-height:1.2;
	}

/**/
@media screen and (max-width:1280px){
	.obj_banner-product .ctn_text h1{
		font-size:var(--aox-fs-50px);
		}
	.obj_banner-product .ctn_text h2{
		font-size:var(--aox-fs-46px);
		}
}
@media screen and (max-width:767px){
	.obj_banner-product .ctn_text{
		width:auto;
		-webkit-transform:translateY(-25%);
		        transform:translateY(-25%);
		}
	.obj_banner-product .ctn_text h1{
		font-size:var(--aox-fs-30px);
		word-break:break-all;
		}
	.obj_banner-product .ctn_text h2{
		font-size:var(--aox-fs-28px);
		}
}
/* ======================================== */
/* .obj_push-product
/* ======================================== */
.ctn_range-liste{
	counter-reset:push-product-list;
	}
.obj_push-product{
	width:25%;
	padding:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	text-align:center;
	counter-increment:push-product-list;
	}
.obj_push-product figure{
	position:relative;
	}
.obj_push-product figure img{
	display:inline-block;
	}
.obj_push-product .lazyload-placeholder{
	height:0;
	padding-bottom:calc(var(--aox-175px) + var(--aox-1px));
	}
.obj_push-product .t_title{
	margin-top:var(--aox-10px);
	font-size:var(--aox-fs-18px); line-height:1.2;
	}
.obj_push-product .t_title strong{
	font-weight:700;
	}
.obj_push-product .t_desc{
	margin-top:var(--aox-5px);
	font-size:var(--aox-fs-14px); line-height:1.2;
	}
.obj_push-product .t_title-routine{
	font-size:var(--aox-fs-22px); line-height:1.1;
	text-transform:uppercase;
	}
.obj_push-product .t_title-routine::before{
	margin-right:var(--aox-10px);
	font-size:var(--aox-fs-14px);
	font-weight:700;
	content:counter(push-product-list) ".";
	}
.obj_push-product .t_desc-routine{
	position:relative;
	margin-top:var(--aox-35px);
	padding-bottom:var(--aox-80px);
	font-size:var(--aox-fs-16px); line-height:1;
	font-weight:500;
	text-transform:uppercase;
	}
.obj_push-product .t_desc-routine svg{
	position:absolute;
	left:50%; top:var(--aox-35px);
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.obj_push-product .fake_cta{
	position:absolute;
	right:0; bottom:var(--aox-20px);
	width:var(--aox-45px); height:var(--aox-45px);
	background:#000;
	transition:all .3s ease;
	}
.obj_push-product .fake_cta::before,
.obj_push-product .fake_cta::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#fff;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.obj_push-product .fake_cta::before{
	width:var(--aox-15px); height:2px;
	}
.obj_push-product .fake_cta::after{
	width:2px; height:var(--aox-15px);
	}
/**/
@media screen and (min-width:1025px){
	.obj_push-product a:hover .fake_cta{
		background:#777;
		}
}
@media screen and (max-width:768px){
	.obj_push-product{
		width:33.33%;
		}
}
@media screen and (max-width:767px){
	.obj_push-product{
		width:100%;
		}
	.obj_push-product .fake_cta{
		right:17vw;
		}
}
/* ======================================== */
/* .obj_banner-product
/* ======================================== */
.obj_banner-routine{
	margin-top:var(--aox-35px);
	text-align:center;
	}
.obj_banner-routine h1{
	margin-bottom:var(--aox-15px);
	font-size:var(--aox-fs-32px); line-height:1.2;
	font-weight:500;
	text-transform:uppercase;
	}
.obj_banner-routine h2{
	padding:var(--aox-15px) var(--aox-20px);
	font-size:var(--aox-fs-26px); line-height:1.2;
	font-weight:300;
	text-transform:uppercase;
	background:#f2f2f2;
	}

/**/
@media screen and (max-width:767px){
	.obj_banner-routine h1{
		font-size:var(--aox-fs-30px);
		}
	.obj_banner-routine h2{
		font-size:var(--aox-fs-22px);
		}
}
/* ======================================== */
/* .obj_circle-progress
/* ======================================== */

/***/

.obj_circle-progress-expertise{
	position: relative;
	width: var(--aox-280px);
	margin: 0 auto;
	}
.obj_circle-progress-expertise .flex-col {
	display: flex;
	flex-direction: column;
	align-content: stretch;
	text-align: center;
	color: white;
	text-transform: uppercase;
	grid-gap: var(--aox-24px);
	gap: var(--aox-24px);
	width: var(--aox-450px);
	}

.obj_circle-progress-expertise .circle-container {
	width: var(--aox-290px);
	height: var(--aox-290px);
	}

.obj_circle-progress-expertise .circle-container__background {
	fill: none;
	stroke: #F2F2F2;
	stroke-width: 4px;
	stroke-dasharray: 75 100;
	stroke-linecap: round;
	}

.obj_circle-progress-expertise .circle-container__progress {
	fill: none;
	stroke-linecap: round;
	stroke: #CF9C67;
	stroke-dasharray: 75 100;
	stroke-linecap: round;
	stroke-width: 2px;
	transition: stroke-dashoffset 1s ease-in-out;
	}

.obj_circle-progress-expertise .stop1 {
	stop-color: #CF9C67;
	}

/* .obj_circle-progress-expertise .stop2 {
	stop-color: #9198e5;
	} */
.obj_circle-progress-expertise .ctn_txt{
	position:absolute;
	left:0; top:50%;
	width:100%;
	font-size:var(--aox-fs-50px);
	text-align:center;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
.obj_circle-progress-expertise .ctn_txt .t_percent{
	font-size: var(--aox-fs-50px);
    line-height: var(--aox-lh-90);
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
	text-transform: uppercase;
	}
.obj_circle-progress-expertise .ctn_txt .t_percent span{
	padding-left: 2px;
	font-size:var(--aox-fs-24px);
	line-height: 1.2em;
	}
.obj_circle-progress-expertise .ctn_txt .t_title{
	font-size:var(--aox-fs-20px); line-height:1;
	font-weight:700;
	text-transform:uppercase;
	}

.obj_circle-progress-expertise .ctn_avatar{
	position: absolute;
    bottom: 0; left: 50%;
    width: var(--aox-78px); height: var(--aox-78px);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
	}
.obj_circle-progress-expertise .ctn_avatar figure {
    position: absolute;
    margin: 0 auto;
    width: 100%; height: 100%;
    max-width: 100%;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
	}
.obj_circle-progress-expertise figure img {
	width: 100%; height: 100%;
	object-fit: cover;
	}

.obj_circle-progress-expertise path {
	stroke-linecap: round;
	stroke-width: var(--aox-8px);
	}
.obj_circle-progress-expertise .CircularProgressbar-trail {
	stroke: #E8E8E8;
	}
.obj_circle-progress-expertise .CircularProgressbar-path {
	stroke: #00ACC2;
	stroke-width: var(--aox-4px);
	stroke-dasharray: 248;
	stroke-dashoffset: 124;
	/* adjust last number for variance */
	transition:stroke-dashoffset 1s ease 0.5s;
	}


/***/

@media screen and (max-width:1340px){
	.obj_circle-progress-expertise .ctn_txt {
		top: 45%;
		font-size:var(--aox-fs-50px);
		}
	.obj_circle-progress-expertise .ctn_avatar {
		width: var(--aox-96px); height: var(--aox-96px);
		}
}

@media screen and (max-width:767px){

	.obj_circle-progress-expertise .ctn_txt{
		top: 52%;
		}
	.obj_circle-progress-expertise .ctn_txt .t_percent {
		font-size: var(--aox-fs-40px);
		}
	.obj_circle-progress-expertise .ctn_avatar {
		width: var(--aox-78px);
		height: var(--aox-78px);
		bottom: calc(-1 * var(--aox-10px));
		}
	




}
/* ======================================== */
/* .obj_star-score
/* ======================================== */
.obj_star-score{
	text-align:center;
	}
.obj_star-score svg{
	position:relative;
	z-index:1;
	}
.obj_star-score.var_bronze svg{
	fill:#cf9c67;
	}
.obj_star-score.var_argent svg{
	fill:#b2b2b2;
	}
.obj_star-score.var_or svg{
	fill:#eac911;
	}
.obj_star-score.var_experte svg{
	fill:url(#gradient-starscore);
	stroke:#949494;
	}
.obj_star-score .t_year{
	position:relative;
	z-index:2;
	top:-35px;
	font-size:var(--aox-fs-20px); line-height:1;
	color:#fff;
	}
.obj_star-score.var_experte .t_year{
	color:#000;
	}
.obj_star-score .t_status{
	font-size:var(--aox-fs-20px); line-height:1;
	text-transform:uppercase;
	}

/* ======================================== */
/* .obj_cta-formation
/* ======================================== */
.obj_cta-formation{
	position: absolute;
    bottom: 0;
    width: 100%; height: var(--aox-53px);
    padding: var(--aox-15px) var(--aox-60px) var(--aox-15px) var(--aox-30px);
    color: #ffffff;
    font-size:var(--aox-fs-14px); line-height: 1; font-weight: 700;
    text-transform: uppercase;
    background-color: rgba(0,0,0,0.2);
    z-index: 1;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}


.ctn_page-liste:not(.is-progress, .is-done, .is-not-available) .obj_cta-formation .obj_cta-formation-picto::before{
	content: '';
    position: absolute;
    left: 50%; top: var(--aox-20px);
    border: 1px solid transparent;
    border-width: 8px 10px;
    border-left-color: #fff;
    background: transparent;
    -webkit-transform: translate(-20%, 0%);
            transform: translate(-20%, 0%);
	}

.is-not-available .obj_cta-formation::before{
	display:none;
	}

.is-done .obj_cta-formation{
	background-color: rgba(0, 0, 0, 0.2);
	}

.obj_cta-formation .obj_cta-formation-picto{
	position: absolute;
	right: 0;
	width: var(--aox-53px); height: var(--aox-53px);
	}

	
.is-progress .obj_cta-formation .obj_cta-formation-picto::before{
	content: "";
    position: absolute;
    width: 4px; height: var(--aox-14px);
    border: 4px solid #fff;
    border-width: 0 4px;
    background: transparent;
    top: calc(50% - var(--aox-12px)); left: calc(50% - var(--aox-10px));
    -webkit-transform: translate(50%, 50%);
            transform: translate(50%, 50%);
	}
.section_list_formation .is-progress .obj_cta-formation .obj_cta-formation-picto::before{
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    }
.is-progress .obj_cta-formation .obj_cta-formation-picto::after{
	content: "";
    position: absolute;
    top: 50%;
    left: 0;
	margin: calc(-1 * var(--aox-8px)) 0 0;
    background: #fff;
	}

.is-done .obj_cta-formation .obj_cta-formation-picto::before{
	content: "";
    position: absolute;
    width: var(--aox-10px);
    height: var(--aox-10px);
    border: 3px solid #fff;
    border-radius: 50%;
    border-right-color: transparent;
	top: 50%; left: 50%;
    -webkit-transform: rotate(180deg) translate(50%, 50%);
            transform: rotate(180deg) translate(50%, 50%);
	}
.is-done .obj_cta-formation .obj_cta-formation-picto::after{
	content: "";
    position: absolute;
    border: 8px solid #fff;
    border-bottom-color: #fff;
    border-left-color: transparent;
    border-top-width: 0;
    border-right-width: 0;
    top: var(--aox-32px);
    left: var(--aox-22px);
    -webkit-transform: rotate(180deg) translate(50%, 50%);
            transform: rotate(180deg) translate(50%, 50%);
	}
/* ======================================== */
/* .obj_push-event
/* ======================================== */
.obj_push-event{
	position:relative;
	margin:var(--aox-60px) var(--aox-30px) 0 var(--aox-30px);
	width:calc(100% - var(--aox-60px)); height:var(--aox-360px);
	border:1px solid #c7c7c7;
	background:#e6e6e6;
	}
.obj_push-event.var_dark{
	background:#000;
	}
.obj_push-event figure{
	position:absolute;
	top:50%; left:50%;
	width:100%; height:100%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	overflow:hidden;
	}
.obj_push-event figure img{
	width:var(--aox-1280px); height:var(--aox-360px);
	}
.obj_push-event .ctn_text{
	position:relative;
	z-index:3;
	padding:var(--aox-60px) 12% var(--aox-45px) 12%;
	}
.obj_push-event.var_left .ctn_text{
	text-align:left;
	}
.obj_push-event.var_center .ctn_text{
	text-align:center;
	}
.obj_push-event.var_right .ctn_text{
	text-align:right;
	}
.obj_push-event .ctn_text h2{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-30px); line-height:1;
	font-weight:700;
	}
.obj_push-event .ctn_text h3{
	margin-bottom:var(--aox-20px);
	font-size:var(--aox-fs-18px); line-height:1.2;
	text-transform:uppercase;
	}
.obj_push-event .ctn_text p{
	margin-bottom:var(--aox-15px);
	font-size:var(--aox-fs-18px); line-height:1.8;
	}
.obj_push-event .ctn_text h2 strong,
.obj_push-event .ctn_text h3 strong,
.obj_push-event .ctn_text p strong{
	font-weight:700;
	}
.obj_push-event.var_dark .ctn_text h2,
.obj_push-event.var_dark .ctn_text h3,
.obj_push-event.var_dark .ctn_text p{
	color:#fff;
	}
/**/
@media screen and (max-width:1340px){
	.obj_push-event{
		width:calc(100vw - 4.4vw);
		margin:4.5vw 2.2vw 0 2.2vw;
		}
	.obj_push-event .ctn_text h2{
		font-size:var(--aox-fs-30px);
		}
	.obj_push-event .ctn_text h3,
	.obj_push-event .ctn_text p{
		font-size:var(--aox-fs-18px);
		}
}
@media screen and (max-width:767px){
	.obj_push-event{
		width:100% !important; height:auto;
		margin:var(--aox-40px) 0 0 0;
		}
	.obj_push-event .ctn_text{
		padding:var(--aox-40px) var(--aox-20px) var(--aox-25px) var(--aox-20px);
		}
	.obj_push-event .ctn_text h2{
		font-size:var(--aox-fs-24px);
		}
	.obj_push-event .ctn_text h3{
		font-size:var(--aox-fs-16px);
		}
	.obj_push-event figure img{
		width:calc(var(--aox-410px) + var(--aox-4px)); height:var(--aox-370px);
		}
}
/* ======================================== */
/* .obj_badge-elite
/* ======================================== */
.obj_badge-elite{
	position:absolute;
	z-index:3;
	right:var(--aox-30px); top:var(--aox-210px);
	width:var(--aox-95px);
	}
.obj_badge-elite img{
	max-width:100%;
	}

@media screen and (max-width:1280px){
	.obj_badge-elite{
		top:var(--aox-155px);
		right:var(--aox-20px);
		}
}
@media screen and (max-width:768px){
	.obj_badge-elite{
		top:var(--aox-130px);
		}
}
@media screen and (max-width:767px){
	.obj_badge-elite{
		width:var(--aox-50px);
		}
}
/* ======================================== */
/* .obj_push-galerie
/* ======================================== */
.obj_push-galerie{
	position:relative;
	margin:0 3%;
	width:27.33%;
	overflow:hidden;
	}
.obj_push-galerie button{
	display:block;
	}
.obj_push-galerie .ctn_image{
	position:relative;
	z-index:1;
	overflow:hidden;
	}
.obj_push-galerie .ctn_image img{
	position:relative;
	z-index:1;
	max-width:100%;
	}
.obj_push-galerie .ctn_image p{
	position:absolute;
	left:0; bottom:0;
	z-index:2;
	padding:var(--aox-10px);
	font-size:var(--aox-fs-16px); line-height:1;
	font-weight:700;
	color:#000;
	text-transform:uppercase;
	background:#fff;
	}
.obj_push-galerie .ctn_before{
	position:relative;
	z-index:1;
	}
.obj_push-galerie .ctn_after{
	box-sizing:content-box;
	position:absolute;
	left:calc(50% - 2px); top:0;
	z-index:2;
	width:100%; height:100%;
	overflow:hidden;
	border-left:4px solid #fff;
	}
.obj_push-galerie .ctn_after img{
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.obj_push-galerie .ctn_overlay{
	display:flex;
	align-items:center;
	justify-content:center;
	position:absolute;
	z-index:3;
	width:100%; height:100%;
	background:rgba(0,0,0,0.5);
	opacity:0;
	transition:all .3s ease;
	}
.obj_push-galerie .obj_likes-comments li{
	padding-left:var(--aox-45px);
	font-size:var(--aox-fs-16px);
	font-weight:700;
	color:#fff;
	}
.obj_push-galerie .obj_likes-comments li svg{
	width:var(--aox-36px); height:var(--aox-31px);
	fill:#fff;
	}
@media screen and (min-width:1025px){
	.obj_push-galerie:hover .ctn_overlay{
		opacity:1;
		}
}
@media screen and (min-width:768px) and (max-width:768px){
	.obj_push-galerie .ctn_image p{
		font-size:var(--aox-fs-12px);
		}
}
@media screen and (max-width:767px){
	.obj_push-galerie{
		margin:0 auto;
		width:80%;
		}
	.obj_push-galerie + .obj_push-galerie{
		margin-top:var(--aox-40px);
		}
}
/* ======================================== */
/* .obj_ba-slider
/* ======================================== */
.obj_ba-slider{
	position:relative;
	overflow:hidden;
	}
.obj_ba-slider {
	max-width:unset;
	}
.obj_ba-slider .ctn_before-{
	box-sizing:content-box;
	position:absolute;
	z-index:2;
	left:0; top:0;
	overflow:hidden;
	}
.obj_ba-slider .ba-slidebar{
	position:absolute;
	z-index:3;
	left:50%; top:0;
	width:6px; height:100%;
	background:#fff;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.obj_ba-slider .ctn_after-{
	position:relative;
	z-index:1;
	}
.obj_ba-slider input[type=range]{
	position:absolute;
	z-index:4;
	left:50%; top:0;
	width:100%; height:100%;
	margin:0;
	-webkit-appearance:none;
	-moz-appearance:none;
	background:none;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.obj_ba-slider input[type=range]:hover{
	cursor:pointer;
	}
.obj_ba-slider input[type=range]:focus{
	outline:none;
	}
.obj_ba-slider input[type=range]:active{
	outline:none;
	}
.obj_ba-slider input[type=range]::-webkit-slider-thumb{
	height:var(--aox-38px); width:var(--aox-38px);
	background:#fff;
	background:url(/dist/formationcaf/static/media/i_before-after.2b9953e1.svg) center center / var(--aox-24px) auto no-repeat #fff;
	border-radius:50%;
	-webkit-appearance:none;
	}
.obj_ba-slider input[type=range]::-moz-range-thumb{
	height:var(--aox-38px); width:var(--aox-38px);
	background:url(/dist/formationcaf/static/media/i_before-after.2b9953e1.svg) center center / var(--aox-24px) auto no-repeat #fff;
	border-radius:50%;
	border:none;
	-moz-appearance:none;
	}
@media screen and (max-width:1150px){
	.obj_ba-slider {
		width:50vw;
		}
}
@media screen and (min-width:768px) and (max-width:768px){
	.obj_ba-slider{
		width:var(--aox-345px);
		margin:0 auto;
		}
	.obj_ba-slider {
		width:var(--aox-345px);
		}
}
@media screen and (max-width:767px){
	.obj_ba-slider {
		width:81.2vw;
		}
}
/* ======================================== */
/* .obj_progress-bar-expertise
/* ======================================== */
:root {
	--color-progressbar-lrp: 0,159,227;
	--color-progressbar-cerave: 37,112,184;
	--color-progressbar-sanoflore: 60,100,60;
	--color-progressbar-vichy: 29,29,27;
	--color-progressbar-skinc: 0,139,176;
	--color-progressbar-biotherm: 32,97,112;
  }
.obj_progress-bar-expertise{
	position: relative;
	}
.obj_progress-bar-expertise .t_title{
	margin-bottom: var(--aox-10px);
    font-size:var(--aox-fs-12px); line-height: 1.2;
    font-weight: 700;
	text-transform: uppercase;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.obj_progress-bar-expertise .t_title span{
	font-weight: 400;
	margin-left: var(--aox-10px);
	}
.obj_progress-bar-expertise .obj_progress-bar{
	position: relative;
	background-color: #F0F0F0;
    border-radius: var(--aox-10px);
	padding: var(--aox-5px) var(--aox-7px);
	height: var(--aox-16px);
	width: 100%;
	}
.obj_progress-bar-expertise + .obj_progress-bar-expertise{
	margin-top: var(--aox-25px);
	}
.obj_progress-bar-expertise .obj_progress-bar .ctn_steps{
	display: flex;
    align-items: center;
    flex-direction: row;
	width: 100%;
	}
.obj_progress-bar-expertise .obj_progress-bar .step {
	position: relative;
	display: block;
	opacity: 0;
	flex: 1 1;
	height: var(--aox-6px);
    background: #000;
	border-radius: var(--aox-10px);
	}
.obj_progress-bar-expertise .obj_progress-bar .step + .step {
	margin-left: 2px;
	}
.obj_progress-bar-expertise .obj_progress-bar .step.is-complete {
	opacity: 1;
	}
.obj_progress-bar-expertise .obj_progress-bar .step.active ~ .step.step {
	color: #000;
	}

/***/
.obj_progress-bar-expertise .obj_progress-bar .step:not(.is-complete) {
	background: rgb(240,240,240);
	}

/* var_lrp */
.obj_progress-bar-expertise.var_lrp .obj_progress-bar{
	background: rgba(0,159,227, 0.15);
	background: rgba(var(--color-progressbar-lrp), 0.15);
	}
.obj_progress-bar-expertise.var_lrp .obj_progress-bar .step.is-complete {
	background: rgba(0,159,227, 1);
	background: rgba(var(--color-progressbar-lrp), 1);
	}
.obj_progress-bar-expertise.var_lrp .obj_progress-bar .ctn_cta{
	background: rgba(0,159,227, 1);
	background: rgba(var(--color-progressbar-lrp), 1);
	}
/* var_cerave */
.obj_progress-bar-expertise.var_cerave .obj_progress-bar{
	background: rgba(37,112,184, 0.15);
	background: rgba(var(--color-progressbar-cerave), 0.15);
	}
.obj_progress-bar-expertise.var_cerave .obj_progress-bar .step.is-complete {
	background: rgba(37,112,184, 1);
	background: rgba(var(--color-progressbar-cerave), 1);
	}
/* var_vichy */
.obj_progress-bar-expertise.var_vichy .obj_progress-bar{
	background: rgba(29,29,27, 0.15);
	background: rgba(var(--color-progressbar-vichy), 0.15);
	}
.obj_progress-bar-expertise.var_vichy .obj_progress-bar .step.is-complete {
	background: rgba(29,29,27, 1);
	background: rgba(var(--color-progressbar-vichy), 1);
	}
/* var_skinc */
.obj_progress-bar-expertise.var_skinc .obj_progress-bar{
	background: rgba(0,139,176, 0.15);
	background: rgba(var(--color-progressbar-skinc), 0.15);
	}
.obj_progress-bar-expertise.var_skinc .obj_progress-bar .step.is-complete {
	background: rgba(0,139,176, 1);
	background: rgba(var(--color-progressbar-skinc), 1);
	}
/* var_sanoflore */
.obj_progress-bar-expertise.var_sanoflore .obj_progress-bar{
	background: rgba(60,100,60, 0.15);
	background: rgba(var(--color-progressbar-sanoflore), 0.15);
	}
.obj_progress-bar-expertise.var_sanoflore .obj_progress-bar .step.is-complete {
	background: rgba(60,100,60, 1);
	background: rgba(var(--color-progressbar-sanoflore), 1);
	}	
/* var_biotherm */
.obj_progress-bar-expertise.var_biotherm .obj_progress-bar{
	background: rgba(32,97,112, 0.15);
	background: rgba(var(--color-progressbar-biotherm), 0.15);
	}
.obj_progress-bar-expertise.var_biotherm .obj_progress-bar .step.is-complete {
	background: rgba(32,97,112, 1);
	background: rgba(var(--color-progressbar-biotherm), 1);
	}


.obj_progress-bar-expertise .obj_progress-bar .active_profil{
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
    top: 50%; left: 0%;
    width: var(--aox-32px); height: var(--aox-32px);
    z-index: 2;
	}
.obj_progress-bar-expertise .obj_progress-bar .active_profil {
	width: var(--aox-32px); height: var(--aox-32px);
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid white;
	}




@media screen and (max-width:1150px){

}
@media screen and (min-width:768px) and (max-width:768px){

}
@media screen and (max-width:767px){

}
/* ======================================== */
/* .obj-push-custom-text
/* ======================================== */
.obj-push-custom-text{
	position:relative;
	margin:var(--aox-100px) var(--aox-30px) var(--aox-60px) var(--aox-30px);
	}
.obj-push-custom-text.var_100 {
	width: 100%;
	}
.obj-push-custom-text .ctn_text{
	text-align: center;
	}
.obj-push-custom-text .ctn_text p{
	font-size:var(--aox-fs-20px); line-height:1;
	padding:0 10%;
	}
.obj-push-custom-text .ctn_text p strong{
	font-weight:700;
	}

@media screen and (max-width:767px){
	.obj-push-custom-text{
		width:100% !important;
		margin:var(--aox-60px) var(--aox-20px);
		}
	.obj-push-custom-text .ctn_text p{
		font-size:var(--aox-fs-16px);
		}
}
@media screen and (max-width:320px){
	.obj-push-custom-text .ctn_text{
		max-width:100%;
		}
}
/* ======================================== */
/* .obj_push_banner
/* ======================================== */

.ctn_push_banner{
	width: 100%;
    max-width: var(--aox-1280px);
    height: 100%;
    margin: 0 auto;
	}
.obj_push_banner{
	position:relative;
	margin: 0 auto;
	height: var(--aox-540px);
	max-width: 100%;
	}
.obj_push_banner picture img {
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 50%;
	width: 100%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
	}
.obj_push_banner h2 picture img {
	position: relative;
	z-index: 1;
	left: inherit;
	top: inherit;
	width: auto;
	-webkit-transform: inherit;
	        transform: inherit;
	}

.obj_push_banner .obj_cta {
	position: absolute;
	z-index: 2;
	bottom: var(--aox-70px);
	}
.obj_push_banner .ctn_text {
	position: absolute;
	z-index: 3;
	top: var(--aox-70px);
	max-width: var(--aox-580px);
	}
.obj_push_banner .ctn_text h2 {
	margin-bottom: var(--aox-20px);
	font-size:var(--aox-fs-60px);
	line-height: 0.9;
	text-transform: uppercase;
	}
.obj_push_banner .ctn_text h2 strong {
	font-weight: 700;
	}
.obj_push_banner .ctn_text h3 {
	margin-bottom: var(--aox-30px);
	font-size:var(--aox-fs-34px);
	line-height: 1;
	text-transform: uppercase;
	}
.obj_push_banner .ctn_text h4 {
	margin-bottom: var(--aox-20px);
	font-size:var(--aox-fs-20px);
	line-height: 1;
	text-transform: uppercase;
	font-weight: 700;
	}
.obj_push_banner .ctn_text p {
	font-size:var(--aox-fs-16px);
	line-height: 1.8;
	}
.obj_push_banner .ctn_text p strong {
	font-weight: 700;
	}
.obj_push_banner.var_left .obj_cta,
.obj_push_banner.var_left .ctn_text {
	left: var(--aox-160px);
	}
.obj_push_banner.var_right .obj_cta,
.obj_push_banner.var_right .ctn_text {
	left: 50%;
	}
.obj_push_banner.var_center .obj_cta,
.obj_push_banner.var_center .ctn_text {
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	}
.obj_push_banner.var_center .ctn_text {
	padding: 0;
	text-align: center;
	}
.obj_push_banner.var_dark .ctn_text h3,
.obj_push_banner.var_dark .ctn_text h4,
.obj_push_banner.var_dark .ctn_text p {
	color: #fff;
	}


@media screen and (max-width:1280px) {
	.obj_push_banner {
		height: 0;
		padding-bottom: 42.2%;
		}
	.obj_push_banner .obj_cta {
		bottom: 5.4vw;
		padding: 1.8vw 3.8vw;
		}
	.obj_push_banner .ctn_text {
		top: 5.4vw;
		}
	.obj_push_banner .ctn_text h2 {
		margin-bottom: 1.5vw;
		font-size:var(--aox-fs-60px);
		}
	.obj_push_banner .ctn_text h3 {
		margin-bottom: 2.3vw;
		font-size:var(--aox-fs-35px);
		}
	.obj_push_banner .ctn_text h4 {
		margin-bottom: 1.5vw;
		font-size:var(--aox-fs-15px);
		}
	.obj_push_banner .ctn_text p {
		font-size:var(--aox-fs-16px);
		}
	.obj_push_banner.var_left .obj_cta,
	.obj_push_banner.var_left .ctn_text {
		left: 12.5vw;
		}
	}

@media screen and (max-width:767px) {
	.ctn_push_banner{
		max-width: calc(var(--aox-410px) + var(--aox-4px));
		}
	.obj_push_banner {
		padding-bottom: 93%;
		}
	.obj_push_banner.var_left .obj_cta,
	.obj_push_banner.var_left .ctn_text {
		left: var(--aox-50px);
		}
	.obj_push_banner.var_center .ctn_text {
		width: 75%;
		}
	.obj_push_banner .ctn_text {
		width: 60%;
		top: var(--aox-40px);
		}
	.obj_push_banner .obj_cta {
		bottom: var(--aox-40px);
		padding: var(--aox-20px) var(--aox-30px);
		}
	.obj_push_banner .ctn_text h2 {
		margin-bottom: var(--aox-20px);
		font-size:var(--aox-fs-35px);
		}
	.obj_push_banner .ctn_text h2 picture img {
		width: 100%;
		}
	.obj_push_banner .ctn_text h3 {
		font-size:var(--aox-fs-18px);
		}
	.obj_push_banner .ctn_text h4 {
		margin-bottom: var(--aox-20px);
		font-size:var(--aox-fs-15px);
		}
	.obj_push_banner .ctn_text p {
		font-size:var(--aox-fs-14px);
		line-height: 1.6;
		padding-right: var(--aox-20px);
		}
	}

@media screen and (max-width:414px) {
	.obj_push_banner .ctn_text {
		top: 9.6vw;
		}
	.obj_push_banner .obj_cta {
		bottom: 9.6vw;
		}
	.obj_push_banner .ctn_text h2 {
		margin-bottom: 4.8vw;
		font-size: 8.4vw;
		}
	.obj_push_banner .ctn_text h3 {
		font-size: 4.3vw;
		}
	.obj_push_banner .ctn_text h4 {
		margin-bottom: 4.8vw;
		font-size: 3.6vw;
		}
	}
/* ======================================== */
/* .obj_section
/* ======================================== */
.obj_section {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: var(--aox-60px) 0;
	color: #000;
	font-weight: 400;
	letter-spacing: normal;
	background-color: #fff;
	}
.obj_section + .obj_section {
	padding: var(--aox-20px) 0 var(--aox-60px) 0;
	}
.obj_section .ctn_section {
	width: var(--aox-grid);
	margin: 0 auto;
	}
.obj_section .ctn_section .ctn_title{
	margin-bottom: var(--aox-25px);
	}
.obj_section h1,
.obj_section h2,
.obj_section h3,
.obj_section h4,
.obj_section h5,
.obj_section h6 {
	margin: 0;
	font-weight: 700;
	line-height: 1;
	letter-spacing: 0;
	}
.obj_section .t_title {
	font-weight: 300;
	font-size: var(--aox-fs-60px);
    line-height: var(--aox-lh-100);
	}
.obj_section .t_title.title_small {
	font-size: var(--aox-fs-30px);
	}
.obj_section .t_subtitle {
	font-weight: 300;
	font-size: var(--aox-fs-24px);
	line-height: var(--aox-lh-100);
	text-transform: uppercase;
	}
.obj_section .t_subtitle.subtitle_small {
	font-size: var(--aox-fs-20px);
	}
.obj_section .t_title strong,
.obj_section .t_subtitle strong {
	font-weight: 700;
	}
.obj_section .ctn_title .t_title {
	margin-bottom: var(--aox-30px);
	width: var(--aox-col-9);
	}
.obj_section .ctn_title .t_subtitle {
	margin-bottom: var(--aox-30px);
	}
.obj_section p {
	font-size: var(--aox-fs-16px);
	line-height: 1.4;
	}
.obj_section .t_desc {
	font-size: var(--aox-fs-16px);
	line-height: 1.4;
	}
.obj_section .t_desc strong{
	font-weight: 700;
	}
.obj_section p a {
	text-decoration: underline;
	}
.editorial .obj_section .t_legal {
	font-size: var(--aox-fs-11px);
	line-height: 1.4;
	color:#808080;
	}
.obj_section .t_disclaimer {
	position: absolute;
	z-index: 2;
	left: 50%;
	bottom: var(--aox-20px);
	width: var(--aox-grid);
	font-size: var(--aox-fs-12px);
	color:#808080;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	}



@media screen and (max-width:767px){

	.obj_section {
		overflow: unset;
		padding: var(--aox-25px) 0 var(--aox-60px) 0;
		}
	.obj_section .t_subtitle {
		font-size: var(--aox-fs-16px);
		}
	.obj_section .t_title {
		font-size: var(--aox-fs-40px);
		line-height: var(--aox-lh-110);
		}
	.obj_section .ctn_title > .t_title {
		margin-bottom: 0;
		}

}
@media screen and (max-width:320px){

}
/* ======================================== */
/* .obj_bloc_expertise
/* ======================================== */

.obj_bloc_expertise{
	position: relative;
	width: 100%;
	letter-spacing: normal;
	border-radius: var(--aox-27px);
	background-color: #F2F2F2;
	overflow: hidden;
	}
.obj_bloc_expertise .t_title {
	font-weight: 700;
	font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-110);
	margin-bottom: var(--aox-20px);
	text-transform: uppercase;
	text-align: center;
	}
.obj_bloc_expertise .ctn_bloc_exp{
	position: relative;
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
	padding: 0 var(--aox-col-1) var(--aox-45px) var(--aox-col-1);
	}
.obj_bloc_expertise.var_global .ctn_bloc_exp{
	padding: var(--aox-57px) var(--aox-col-1) var(--aox-45px) var(--aox-col-1);
	}
.obj_bloc_expertise .ctn_bloc_exp > * {
	width: var(--aox-330px);
	}


/***/
.obj_bloc_expertise .obj_recompences_exp .t_desc{
	text-align: center;
	font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-130);
	font-weight: 400;
	}



/***/
.obj_bloc_expertise .obj_recompences_exp .lst_items_exp{
	position: relative;
    width: calc(100% + var(--aox-15px));
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
	}


/***/
.obj_bloc_expertise + .obj_bloc_expertise{
	margin-top: var(--aox-10px);
	}


.obj_bloc_expertise .obj_tabs > input[type="radio"],
.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp {
	display: none;
	}

.obj_bloc_expertise .ctn_classement_header{
	display: flex;
	flex-direction: row;
	align-items: center;
	}
.obj_bloc_expertise .ctn_classement_header > * + *{
	margin-left: var(--aox-10px);
	}


/***/

.obj_bloc_expertise .obj_header_exp{
	display: flex;
    padding: var(--aox-20px) var(--aox-50px) var(--aox-20px) var(--aox-65px);
    height: var(--aox-70px);
    width: 100%;
    flex-direction: row;
    align-items: center;
    letter-spacing: normal;
    cursor: pointer;
    transition: all var(--duration-500ms) ease;
    justify-content: flex-start;
	}
.obj_bloc_expertise .obj_header_exp:before {
	content: "";
    position: absolute;
    left: var(--aox-32px);
    top: var(--aox-30px);
    height: var(--aox-6px);
    width: var(--aox-6px);
    border-top: solid 2px #000000;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    transition:all var(--duration-300ms) ease;
	}	
.obj_bloc_expertise .obj_header_exp > *{
	transition:all var(--duration-500ms) ease;
	}
.obj_bloc_expertise .obj_header_exp .t_title{
	margin-bottom: 0;
    text-align: left;
    width: var(--aox-310px);
	}
.obj_bloc_expertise .obj_header_exp .t_tag{
	font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-100);
    text-align: right;
    width: var(--aox-140px);
	}
.obj_bloc_expertise .obj_header_exp .t_tag span{
	display: inline-block;
    text-transform: uppercase;
    padding: var(--aox-8px) var(--aox-16px) var(--aox-6px) var(--aox-16px);
    border-radius: var(--aox-15px);
	/* background-color: rgba(0, 172, 194, 0.15); */
	letter-spacing: normal;
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header{
	width: var(--aox-250px);
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header p{
	font-weight: 500;
    font-size: var(--aox-fs-15px);
	width: var(--aox-105px);
    text-align: right;
    margin-right: var(--aox-20px);
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp {
	position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-32px);
    line-height: var(--aox-lh-90);
    color: #000000;
    margin: 0;
	width: var(--aox-105px);
    text-align: left;
    margin-left: var(--aox-20px);
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp sup {
	font-size: var(--aox-fs-20px);
	line-height: var(--aox-lh-100);
	top: -0.45em;
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp:before {
	content: "";
    position: absolute;
    left: calc(-1 * var(--aox-20px));
    top: 50%;
    height: var(--aox-20px);
    width: var(--aox-20px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: url(/dist/formationcaf/static/media/i_arrow_classement.e68f8d9f.svg) center center / var(--aox-20px) auto no-repeat;
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header.var_up .t_place_exp:before {
	background: url(/dist/formationcaf/static/media/i_arrow_up_classement.c737083d.svg) center center / var(--aox-20px) auto no-repeat; 
	}
.obj_bloc_expertise .obj_header_exp .ctn_classement_header.var_down .t_place_exp:before {
	background: url(/dist/formationcaf/static/media/i_arrow_down_classement.ff286af5.svg) center center / var(--aox-20px) auto no-repeat; 
	}

.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise{
	display: flex;
	flex-direction: row;
    align-items: center;
	width: calc(var(--aox-400px) + var(--aox-65px));
	justify-content: flex-end;
	}
.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise span:last-child{
	width: var(--aox-65px);
    text-align: left;
	}
.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise span{
	font-weight: 500;
    font-size: var(--aox-fs-15px);
    width: var(--aox-180px);
    text-align: left;
	}
.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise .obj_progress-bar{
	margin-left: 0;
	}
.obj_bloc_expertise .obj_progress-bar-expertise .obj_progress-bar{
	width: var(--aox-210px);
	margin-left: var(--aox-16px);
	}
.obj_bloc_expertise .obj_progress-bar-expertise .obj_progress-bar + span{
	margin-left: var(--aox-10px);
	}
.obj_bloc_expertise .obj_progress-bar-expertise span strong{
	font-weight: 700;
	}

.obj_bloc_expertise .obj_tabs {
	transition: all var(--duration-500ms) ease;
	}
.obj_bloc_expertise:not(.var_global) .obj_tabs{
	/* display: none; */
	max-height: 0;
	opacity: 0;
	}

/***/

.obj_bloc_expertise .obj_points_exp{
	text-align: center;
	}
.obj_bloc_expertise .obj_points_exp .ctn_nbre_points_exp{
	margin-top: var(--aox-30px);
	}
.obj_bloc_expertise .obj_points_exp .ctn_nbre_points_exp span{
	display: flex;
    font-size: var(--aox-fs-50px);
    line-height: var(--aox-lh-110);
    font-weight: 700;
    text-transform: uppercase;
    flex-direction: column;
    flex-wrap: wrap;
	}
.obj_bloc_expertise .obj_points_exp .ctn_nbre_points_exp span em{
	font-size: var(--aox-fs-24px);
    line-height: var(--aox-lh-80);
	}

.obj_bloc_expertise .obj_points_exp .obj_progress-bar-expertise {
	margin-top: var(--aox-23px);
	}
.obj_bloc_expertise .obj_points_exp .obj_progress-bar-expertise span{
	font-size: var(--aox-fs-16px);
	letter-spacing: normal;
	font-weight: 400;
	}
.obj_bloc_expertise .obj_points_exp .obj_progress-bar-expertise span em{
	font-weight: 700;
	}
.obj_bloc_expertise .obj_points_exp .obj_progress-bar-expertise .obj_progress-bar{
	margin: var(--aox-10px) auto;
    width: var(--aox-220px);
	}

.obj_bloc_expertise .obj_points_exp .ctn_cta{
	margin-top: var(--aox-23px);
	}
.obj_bloc_expertise .obj_points_exp .ctn_cta .obj_cta {
	padding: var(--aox-24px);
	line-height: var(--aox-lh-100);
	width:100%;
	}


/***/
.obj_bloc_expertise .t_legal{
	position: relative;
    letter-spacing: initial;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-130);
    color: rgba(0, 0, 0, 0.5);
    margin-top: var(--aox-10px);
	text-align: left;
    font-weight: 400;
	}
.obj_bloc_expertise .t_legal.svg_info{
	padding-left: var(--aox-20px);
    margin-left: var(--aox-20px);
	}
.obj_bloc_expertise .t_legal.svg_info:before {
	content: "";
	position: absolute;
	left: 0; top: 1px;
	height: var(--aox-12px);
	width: var(--aox-12px);
	background: url(/dist/formationcaf/static/media/i_info.0e7f1ada.svg) center center / var(--aox-12px) auto no-repeat;
	}
	
	
/***/

.obj_bloc_expertise.is-expanded .obj_tabs{
	/*max-height: var(--aox-450px);*/
    max-height: 200vh;
	opacity: 1;
	}
.obj_bloc_expertise.is-expanded .obj_header_exp{
	padding: var(--aox-30px) var(--aox-70px);
	height: var(--aox-60px);
	}
.obj_bloc_expertise.is-expanded .obj_header_exp > *{
	/* display: none; */
	opacity: 0;
	}
.obj_bloc_expertise.is-expanded .obj_header_exp:before {
	-webkit-transform: rotate(315deg);
	        transform: rotate(315deg);
	}


/***/

@media screen and (max-width:767px){


	/***/

	.obj_bloc_expertise.var_global{
		padding: var(--aox-18px) var(--aox-7px) var(--aox-13px);
		}	
	.obj_bloc_expertise:not(.var_global) .obj_tabs{
		display: none;
		}
	.obj_bloc_expertise.var_global .obj_tabs{
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		}
	.obj_bloc_expertise.is-expanded .obj_tabs{
		display: flex;
		flex-direction: column-reverse;
		width: 100%;
		}
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list{
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: center;
		letter-spacing: normal;
		}
	.obj_bloc_expertise .obj_tabs > input[type="radio"] {
		position: absolute;
		left: -200vw;
		}
	.obj_bloc_expertise .obj_tabs.is-ready .tab-panel {
		display: none;
		height: var(--aox-365px);
		}
	.obj_bloc_expertise .obj_tabs .tab-panels > .ctn_bloc_exp .tab-panel.active{
		display: block;
		}
	.obj_bloc_expertise .obj_tabs.is-ready .tab-panel .ctn_recompences_exp{
		overflow-y: auto;
		}
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp {
		position: relative;
		display: inline-block;
		padding: var(--aox-12px) var(--aox-15px);
		font-size: var(--aox-fs-11px);
		line-height: var(--aox-lh-100);
		border: 2px solid rgba(0, 0, 0, 0.1);
		border-radius: var(--aox-21px);
		cursor: pointer;
		font-weight: 700;
		text-transform: uppercase;
		}
	.obj_bloc_expertise .obj_tabs input:focus-visible + label {
		outline: 2px solid rgba(0, 172, 194, 0.15);
		border-radius: 3px;
		}
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp.active {
		border-color: rgba(0, 172, 194, 1);
		background-color: rgba(0, 172, 194, 1);
		color: #fff;
		}
	.obj_bloc_expertise .tab-panels { order: 1; width: 100%; margin-bottom: var(--aox-10px); }
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li:nth-child(1){ order: 2; }
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li:nth-child(2){ order: 3; margin-left: var(--aox-5px); margin-right: var(--aox-5px); }
	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li:nth-child(3){ order: 4; }

	.obj_bloc_expertise .ctn_bloc_exp > * {
		width: 100%;
		}
	.obj_bloc_expertise.var_global .ctn_bloc_exp,
	.obj_bloc_expertise .ctn_bloc_exp {
		padding: 0;
		}

	.obj_bloc_expertise .obj_header_exp {
		height: var(--aox-70px);
		justify-content: space-between;
		padding: var(--aox-15px) var(--aox-20px) var(--aox-13px);
		}
	.obj_bloc_expertise .obj_header_exp:before {
		top: var(--aox-45px);
		left: calc(50% - var(--aox-6px));
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header {
		width: var(--aox-250px);
		}
	.obj_bloc_expertise .ctn_classement_header{ order: 1;  margin-left: 0; }
	.obj_bloc_expertise .ctn_classement_header p{ display: none; }
	.obj_bloc_expertise .ctn_classement_header > * + * { margin-left: 0; }
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp {
		padding-left: var(--aox-40px);
		margin-left: 0;
		width: auto;
		font-size: var(--aox-fs-28px);
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp:before{
		left: var(--aox-14px);
		height: var(--aox-28px);
		width: var(--aox-28px);
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header .t_place_exp:before {
		background: url(/dist/formationcaf/static/media/i_arrow_classement.e68f8d9f.svg) center center / var(--aox-28px) auto no-repeat; 
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header.var_up .t_place_exp:before {
		background: url(/dist/formationcaf/static/media/i_arrow_up_classement.c737083d.svg) center center / var(--aox-28px) auto no-repeat; 
		}
	.obj_bloc_expertise .obj_header_exp .ctn_classement_header.var_down .t_place_exp:before {
		background: url(/dist/formationcaf/static/media/i_arrow_down_classement.ff286af5.svg) center center / var(--aox-28px) auto no-repeat; 
		}
	.obj_bloc_expertise .obj_header_exp .t_title{ 
		order: 2; 
		height: var(--aox-40px);
		padding: 0 var(--aox-15px); 
		font-size: var(--aox-fs-12px); 
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(100% - var(--aox-220px));
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
		}
	.obj_bloc_expertise .obj_header_exp .t_tag{ 
		order: 3; 
		margin-left: 0;
		font-size: var(--aox-fs-20px);
		}
	.obj_bloc_expertise .obj_header_exp .t_tag span{
		padding: 0;
		background-color: unset !important;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;
		}
	.obj_bloc_expertise .obj_header_exp .t_tag span em{ 
		font-size: var(--aox-fs-10px);
		margin-left: var(--aox-3px);
		margin-bottom: var(--aox-3px);
		}
	.obj_bloc_expertise .obj_header_exp .obj_progress-bar-expertise{ display: none; }


	/***/
	.obj_bloc_expertise.is-expanded .t_title {
		font-size: var(--aox-fs-12px);
		line-height: var(--aox-lh-100);
		}
	.obj_bloc_expertise.is-expanded {
		padding: var(--aox-18px) var(--aox-7px) var(--aox-13px);
		}
	.obj_bloc_expertise.is-expanded .obj_header_exp{
		padding: 0;
		height: var(--aox-15px);
		}
	.obj_bloc_expertise.is-expanded .obj_header_exp:before {
		top: var(--aox-20px);
		}

	.obj_bloc_expertise .obj_points_exp .ctn_cta .obj_cta {
		width: auto;
		}
	
}

@media screen and (max-width:375px){

	.obj_bloc_expertise .obj_tabs ul.obj_tabs_list li .btn_tab_exp{
		text-align: center;
		width: 100%;
		margin-top: var(--aox-5px);
		}

}



/* ======================================== */
/* .obj_item_expertise
/* ======================================== */

.obj_item_expertise{
	position: relative;
    width: var(--aox-100px);
    margin-right: var(--aox-15px);
	margin-top: var(--aox-20px);
    text-align: center;
	}
.obj_item_expertise .ctn_image{
	width: var(--aox-100px);
	height: var(--aox-100px);
	}
.obj_item_expertise .ctn_image img{
	width: 100%;
	}
.obj_item_expertise .ctn_text{
	margin-top: var(--aox-7px);
	}
.obj_item_expertise .ctn_text .t_tag{
	font-weight: 700;
    font-size: var(--aox-fs-10px);
    line-height: var(--aox-lh-100);
	}
.obj_item_expertise .ctn_text .t_tag span{
	display: inline-block;
    text-transform: uppercase;
    padding: var(--aox-5px) var(--aox-10px) var(--aox-3px);
    border-radius: var(--aox-15px);
	}

/***/
/* GENERATOR */
.obj_item_expertise .ctn_text .t_tag span{
	background-color: rgba(0, 172, 194, 0.15);
	}

/***/


@media screen and (max-width:767px){

	.obj_item_expertise {
		width: calc(100% / 3);
		padding: 0 var(--aox-10px);
		margin-right: unset;
		}
	.obj_item_expertise + .obj_item_expertise{
		margin-left: 0;
		}
	.obj_bloc_expertise .obj_recompences_exp .lst_items_exp {
		width: 100%;
		overflow: auto;
		height: var(--aox-300px);
		}

}

/* ======================================== */
/* .obj_lst_classement_exp
/* ======================================== */

.obj_lst_classement_exp .item_classement_exp{
	position: relative;
    display: flex;
    width: var(--aox-315px);
    height: var(--aox-46px);
    margin: 0 auto;
	border-radius: var(--aox-35px);
	padding: var(--aox-6px) var(--aox-14px) var(--aox-6px) var(--aox-6px);
    text-align: left;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
	background-color: #FFFFFF;
	}
.obj_lst_classement_exp .item_classement_exp + .item_classement_exp{
	margin-top: var(--aox-2px);
	}

.obj_lst_classement_exp .ctn_image{
	position: relative;
	display: block;
	width: var(--aox-36px);
	height: var(--aox-36px);
	}
.obj_lst_classement_exp .item_classement_exp:first-child .ctn_image,
.obj_lst_classement_exp .item_classement_exp:nth-child(2) .ctn_image,
.obj_lst_classement_exp .item_classement_exp:nth-child(3) .ctn_image{
	width: var(--aox-32px); height: var(--aox-32px);
	}

.obj_lst_classement_exp .item_classement_exp.current.var_vip {
	padding: var(--aox-10px) var(--aox-14px) var(--aox-10px) var(--aox-10px);
	}
.obj_lst_classement_exp .item_classement_exp:first-child.current .ctn_image,
.obj_lst_classement_exp .item_classement_exp:nth-child(2).current .ctn_image,
.obj_lst_classement_exp .item_classement_exp:nth-child(3).current .ctn_image{
	width: var(--aox-48px); height: var(--aox-48px);
	}
.obj_lst_classement_exp .item_classement_exp:first-child.current .ctn_image:after,
.obj_lst_classement_exp .item_classement_exp:nth-child(2).current .ctn_image:after,
.obj_lst_classement_exp .item_classement_exp:nth-child(3).current .ctn_image:after{
	right: calc(-1 * var(--aox-6px));
	top: calc(-1 * var(--aox-6px));
	}

.obj_lst_classement_exp .ctn_image img{
	border-radius: 50%;
	width: 100%;
    height: 100%;
    object-fit: cover;
	}
.obj_lst_classement_exp .ctn_text{
	display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: calc(100% - var(--aox-50px));
	}
.obj_lst_classement_exp .ctn_text > * + *{
	margin-left: var(--aox-10px);
	}
.obj_lst_classement_exp .ctn_text .t_place_exp{
	font-weight: 700;
    font-size: var(--aox-fs-20px);
    line-height: var(--aox-lh-100);
	color: currentColor;
	}
.obj_lst_classement_exp .ctn_text .t_place_exp sup{
	font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-100);
    top: -0.45em;
	}

.obj_lst_classement_exp .item_classement_exp:first-child .t_place_exp{ color: #CEAB65; }
.obj_lst_classement_exp .item_classement_exp:nth-child(2) .t_place_exp{ color: #949A9C; }
.obj_lst_classement_exp .item_classement_exp:nth-child(3) .t_place_exp{ color: #986E5F; }

.obj_lst_classement_exp .item_classement_exp:first-child .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	height: var(--aox-42px);
	width: var(--aox-42px);
	background: url(/dist/formationcaf/static/media/i_medal_gold.645ade53.svg) center center / var(--aox-42px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(2) .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	height: var(--aox-42px);
	width: var(--aox-42px);
	background: url(/dist/formationcaf/static/media/i_medal_silver.f90adbf6.svg) center center / var(--aox-42px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(3) .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	height: var(--aox-42px);
	width: var(--aox-42px);
	background: url(/dist/formationcaf/static/media/i_medal_bronze.6e54d052.svg) center center / var(--aox-42px) auto no-repeat;
	}

.obj_lst_classement_exp .item_classement_exp.current .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	height: var(--aox-66px); width: var(--aox-66px);
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(1).current .ctn_image:before {
	background: url(/dist/formationcaf/static/media/i_medal_gold.645ade53.svg) center center / var(--aox-66px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(2).current .ctn_image:before {
	background: url(/dist/formationcaf/static/media/i_medal_silver.f90adbf6.svg) center center / var(--aox-66px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp:nth-child(3).current .ctn_image:before {
	background: url(/dist/formationcaf/static/media/i_medal_bronze.6e54d052.svg) center center / var(--aox-66px) auto no-repeat;
	}

.obj_lst_classement_exp .item_classement_exp.is_vip .ctn_image:before {
	background: url(/dist/formationcaf/static/media/i_medal_vip.002abce0.svg) center center / var(--aox-66px) auto no-repeat;
	}


.obj_lst_classement_exp .ctn_text .t_name_exp{
	font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
	padding-right: var(--aox-10px);
	font-weight: 400;
	}
.obj_lst_classement_exp .ctn_text .t_nbre_points_exp{
	font-size: var(--aox-fs-14px);
	line-height: var(--aox-lh-120);
	font-weight: 700;
    text-transform: uppercase;
	margin-left: auto;
	}
.obj_lst_classement_exp .ctn_text .t_nbre_points_exp em{
	font-size: var(--aox-fs-8px);
	margin-left: var(--aox-2px);
	}


/***/

.obj_lst_classement_exp .item_classement_exp.current{
	width: var(--aox-320px);
	height: var(--aox-68px);
	padding: var(--aox-6px) var(--aox-14px) var(--aox-6px) var(--aox-6px);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_image {
	width: var(--aox-54px);
	height: var(--aox-54px);
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_image:after {
	content: "";
	position: absolute;
	right: calc(-1 * var(--aox-2px)); top: calc(-1 * var(--aox-2px));
	height: var(--aox-20px);
	width: var(--aox-20px);
	}
.obj_lst_classement_exp .item_classement_exp.current.var_up .ctn_image:after {
	background: url(/dist/formationcaf/static/media/i_arrow_up_classement.c737083d.svg) center center / var(--aox-20px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp.current.var_down .ctn_image:after {
	background: url(/dist/formationcaf/static/media/i_arrow_down_classement.ff286af5.svg) center center / var(--aox-20px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_text {
	width: calc(100% - var(--aox-64px));
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_text .t_place_exp {
	font-size: var(--aox-fs-32px);
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_text .t_place_exp sup {
	font-size: var(--aox-fs-20px);
	}
.obj_lst_classement_exp .item_classement_exp.current .ctn_text .t_nbre_points_exp {
	font-size: var(--aox-fs-20px);
	}

/***/
.obj_lst_classement_exp .item_classement_exp.current.var_vip {
    padding: var(--aox-10px) var(--aox-14px) var(--aox-10px) var(--aox-10px);
	}
.obj_lst_classement_exp .item_classement_exp.current.var_vip .ctn_image {
    width: var(--aox-48px);
    height: var(--aox-48px);
	}
.obj_lst_classement_exp .item_classement_exp.current.var_vip .ctn_image:before {
	content: "";
	position: absolute;
	left: 50%; top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	height: var(--aox-66px);
	width: var(--aox-66px);
	background: url(/dist/formationcaf/static/media/i_vip_lrp.cf5e56b2.svg) center center / var(--aox-66px) auto no-repeat;
	}
.obj_lst_classement_exp .item_classement_exp.current.var_vip .ctn_image:after {
	right: calc(-1 * var(--aox-4px)); top: calc(-1 * var(--aox-4px));
	-webkit-transform: none;
	        transform: none; left: unset;
	}
.obj_lst_classement_exp .item_classement_exp.current.var_vip .ctn_text {
	width: calc(100% - var(--aox-72px));
	}





/***/
/* GENERATOR */
.obj_lst_classement_exp .ctn_text .t_tag span{
	background-color: rgba(0, 172, 194, 0.15);
	}

/***/


@media screen and (max-width:767px){

	.obj_lst_classement_exp .item_classement_exp{
		width: calc(100% - var(--aox-8px));
		}
	.obj_lst_classement_exp .item_classement_exp.current{
		width: 100%;
		}

}

/* ======================================== */
/* .obj_bloc_formation
/* ======================================== */

.obj_bloc_formation{
	position: relative;
	width: 100%;
	letter-spacing: normal;
	background-color: #F2F2F2;
	overflow: hidden;
	}
.obj_bloc_formation .t_title {
	font-weight: 700;
	font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-110);
	margin-bottom: var(--aox-20px);
	text-transform: uppercase;
	text-align: center;
	}

/***/

.obj_bloc_formation .obj_header_formation{
	display: flex;
	padding: var(--aox-20px) var(--aox-50px);
	height: var(--aox-70px);
	flex-direction: row;
	align-items: center;
	letter-spacing: normal;
	transition:all var(--duration-500ms) ease;
	}
.obj_bloc_formation .obj_header_formation > *{
	transition:all var(--duration-500ms) ease;
	}
.obj_bloc_formation .obj_header_formation .t_title{
	margin-bottom: 0;
	}
.obj_bloc_formation .obj_header_formation .t_tag{
	font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-100);
	margin-left: var(--aox-20px);
	}
.obj_bloc_formation .obj_header_formation .t_tag span{
	display: inline-block;
    text-transform: uppercase;
    padding: var(--aox-7px) var(--aox-17px) var(--aox-6px) var(--aox-20px);
    border-radius: var(--aox-15px);
	/* background-color: rgba(0, 172, 194, 0.15); */
	letter-spacing: normal;
	}

.obj_bloc_formation .obj_header_formation .obj_progress-bar-expertise{
	display: flex;
	flex-direction: row;
    align-items: center;
	margin-left: auto;
	font-weight: 400;
	}
.obj_bloc_formation .obj_progress-bar-expertise .obj_progress-bar{
	width: var(--aox-210px);
	margin-left: var(--aox-16px);
	}
.obj_bloc_formation .obj_progress-bar-expertise .obj_progress-bar + span{
	margin-left: var(--aox-10px);
	}
.obj_bloc_formation .obj_progress-bar-expertise span strong{
	font-weight: 700;
	}


/***/
.obj_bloc_formation .t_legal{
	position: relative;
    letter-spacing: initial;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-130);
    color: rgba(0, 0, 0, 0.5);
    margin-top: var(--aox-10px);
	}
.obj_bloc_formation .t_legal.svg_info{
	padding-left: var(--aox-20px);
    margin-left: var(--aox-20px);
	}
.obj_bloc_formation .t_legal.svg_info:before {
	content: "";
	position: absolute;
	left: 0; top: 1px;
	height: var(--aox-12px);
	width: var(--aox-12px);
	background: url(/dist/formationcaf/static/media/i_info.0e7f1ada.svg) center center / var(--aox-12px) auto no-repeat;
	}
	
	
/***/

.obj_bloc_formation.is-expanded .obj_header_formation{
	padding: var(--aox-30px) var(--aox-70px);
	height: var(--aox-60px);
	}
.obj_bloc_formation.is-expanded .obj_header_formation > *{
	/* display: none; */
	opacity: 0;
	}
.obj_bloc_formation.is-expanded .obj_header_formation:before {
	-webkit-transform: rotate(315deg);
	        transform: rotate(315deg);
	}


/***/

@media screen and (max-width:767px){

	/***/

	.obj_bloc_formation .ctn_bloc_exp > * {
		width: 100%;
		}
	.obj_bloc_formation .ctn_bloc_exp {
		padding: 0;
		}
	.obj_bloc_formation .obj_header_formation {
		flex-direction: column;
		align-items: flex-end;
		height: var(--aox-80px);
		justify-content: space-between;
		padding: var(--aox-15px) var(--aox-20px) var(--aox-13px);
		}
	.obj_bloc_formation .obj_header_formation:before {
		top: var(--aox-40px);
		left: calc(50% - var(--aox-8px));
		}

	.obj_bloc_formation .obj_header_formation .t_title{ 
		position: absolute;
		left: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		order: 2;
		padding: 0 var(--aox-15px);
		font-size: var(--aox-fs-12px);
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		}
	.obj_bloc_formation .obj_header_formation .t_tag{ 
		order: 2;
		margin-left: 0;
		font-size: var(--aox-fs-20px);
		}
	.obj_bloc_formation .obj_header_formation .t_tag span{
		padding: 0;
		background-color: unset !important;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		}
	.obj_bloc_formation .obj_header_formation .t_tag span em{ 
		font-size: var(--aox-fs-10px);
		margin-left: var(--aox-3px);
		margin-bottom: var(--aox-3px);
		}
	.obj_bloc_formation .obj_header_formation .obj_progress-bar-expertise{
		flex-direction: column;
		align-items: flex-end;
		width: 60%;
		order: 3;
		margin-left: unset;
		}
	.obj_bloc_formation .obj_header_formation .obj_progress-bar-expertise span:nth-child(1){
		font-size: var(--aox-fs-11px);
		padding-right: var(--aox-50px);
		position: unset;
		}
	.obj_bloc_formation .obj_header_formation .obj_progress-bar-expertise > span{ 
		margin-top: 0;
		position: absolute;
		font-size: var(--aox-fs-11px);
		}
	.obj_bloc_formation .obj_progress-bar-expertise .obj_progress-bar{
		margin-left: 0;
		order: 3;
		margin-top: var(--aox-5px);
		}
	
}




/* ======================================== */
/* .obj_tableau_recompences_exp
/* ======================================== */

.obj_tableau_recompences_exp{
	position: relative;
	}
.obj_tableau_recompences_exp .ctn_row{
	position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
	}
.obj_tableau_recompences_exp .ctn_row + .ctn_row{
	margin-top: var(--aox-35px);
	}
.obj_tableau_recompences_exp .bloc_items_exp{
	position: relative;
    display: flex;
    flex-direction: column;
    margin-right: calc(var(--aox-col-1) - var(--aox-15px));
    margin-top: var(--aox-35px);
	}
.obj_tableau_recompences_exp .bloc_items_exp .lst_items_exp{
	position: relative;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
	}






/***/


@media screen and (max-width:767px){

	.obj_tableau_recompences_exp .ctn_row {
		flex-direction: column;
		}
	.obj_tableau_recompences_exp .bloc_items_exp {
		margin-right: 0;
		}
	.obj_tableau_recompences_exp .bloc_items_exp .t_desc {
		text-align: center;
		}
	.obj_tableau_recompences_exp .bloc_items_exp + .bloc_items_exp {
		margin-left: 0;
		margin-top: var(--aox-40px);
		}
	.obj_tableau_recompences_exp .bloc_items_exp .lst_items_exp{
		flex-wrap: wrap;
		}
}

/* ======================================== */
/* .obj_formation_mini_parcours
/* ======================================== */
.obj_carrousel_mini_parcours {
    display: flex;
    flex-flow: row wrap;
	margin-bottom: var(--aox-10px);
	}
.obj_carrousel_mini_parcours .item + .item {
	margin-left: var(--aox-10px);
	}
.obj_formation_mini_parcours{
	width:var(--aox-240px);
	}
.obj_formation_mini_parcours a,
.obj_formation_mini_parcours button{
	display:block;
	width:100%;
	text-align:left;
	}
.obj_formation_mini_parcours figure{
	position:relative;
	height:calc(var(--aox-170px) + var(--aox-4px));
	overflow:hidden;
	}
.obj_formation_mini_parcours figure img{
	position:absolute;
	left:0; top:0;
	z-index:1;
	width:100%; height:100%;
	transition:all .3s ease;
	object-fit:cover;
	}
.obj_formation_mini_parcours .ctn_image{
	position:relative;
	width: 100%;
	height: auto;
	}
.obj_formation_mini_parcours .lazyload-placeholder{
	height:0;
	padding-bottom:73%;
	}
.obj_formation_mini_parcours .ctn_text{
	position: relative;
	min-height: var(--aox-35px);
	padding-right:var(--aox-35px);
	margin-top: var(--aox-10px);
	}
.obj_formation_mini_parcours h3{
	font-size:var(--aox-fs-14px); line-height:1.1;
	font-weight:700;
	text-transform: uppercase;
	}


/***/
.obj_formation_mini_parcours .obj_cta-formation {
	height: var(--aox-35px);
	padding: var(--aox-7px) var(--aox-45px) var(--aox-5px) var(--aox-15px);
	font-size: var(--aox-fs-11px);
	}
.obj_formation_mini_parcours .obj_cta-formation .obj_cta-formation-picto {
    width: var(--aox-35px);
    height: var(--aox-35px);
	}
.obj_formation_mini_parcours:not(.is-progress, .is-done, .is-not-available) .obj_cta-formation .obj_cta-formation-picto::before {
	content: '';
	position: absolute;
	left: 44%;
    top: var(--aox-10px);
	border: 1px solid transparent;
	border-width: 8px 10px;
	border-left-color: #fff;
	background: transparent;
	}
.obj_formation_mini_parcours.is-progress .obj_cta-formation .obj_cta-formation-picto::before {
	width: 4px;
	height: var(--aox-14px);
	border: 4px solid #fff;
	border-width: 0 4px;
	top: calc(50% - var(--aox-15px));
	left: calc(50% - var(--aox-12px));
	}
.obj_formation_mini_parcours.is-done .obj_cta-formation .obj_cta-formation-picto::after {
	top: var(--aox-23px);
	left: var(--aox-15px);
	}
.obj_formation_mini_parcours.is-not-available button{
	cursor:default;
	}
.obj_formation_mini_parcours.is-not-available figure img{
	-webkit-filter:grayscale(1);
	        filter:grayscale(1);
	}

/***/
.obj_formation_mini_parcours a:hover figure img,
.obj_formation_mini_parcours button:hover figure img{
	-webkit-transform:scale(1.05);
	        transform:scale(1.05);
	}
.obj_formation_mini_parcours.is-not-available a:hover figure img,
.obj_formation_mini_parcours.is-not-available button:hover figure img{
	-webkit-transform:scale(1);
	        transform:scale(1);
	}
.obj_formation_mini_parcours a:hover .obj_cta-download,
.obj_formation_mini_parcours button:hover .obj_cta-download{
	color:#777 !important;
	}
.obj_formation_mini_parcours a:hover .obj_cta-download svg,
.obj_formation_mini_parcours button:hover .obj_cta-download svg{
	fill:#777 !important;
	}

.obj_carrousel_mini_parcours{
	overflow: unset;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-arrow {
	top: calc(-1 * var(--aox-40px));
    height: var(--aox-30px);
    width: var(--aox-30px);
	margin-top: 0;
	border: 0;
	-webkit-transform: none;
	        transform: none;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-next::before {
	content: "";
	position: absolute;
	left: 20%;
	top: 60%;
	width: var(--aox-12px);
	height: var(--aox-12px);
	-webkit-transform: rotate(-45deg) translate(-50%,-50%);
	transform: rotate(45deg) translate(-50%,-50%);
	border-top: solid 2px rgba(0,0,0,1);
	border-right: solid 2px rgba(0,0,0,1);
	transition: all .3s ease;
	}
/* .obj_carousel.obj_carrousel_mini_parcours .slick-next:hover{
    right: 3%;
	} */
.obj_carousel.obj_carrousel_mini_parcours .slick-prev {
	left: auto !important;
	right: calc(3% + var(--aox-25px));
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-prev::before {
	content: "";
	position: absolute;
	left: 30%;
	top: 60%;
	width: var(--aox-12px);
	height: var(--aox-12px);
	-webkit-transform: rotate(-45deg) translate(-50%,-50%);
	transform: rotate(45deg) translate(-50%,-50%);
	border-bottom: solid 2px rgba(0,0,0,1);
	border-left: solid 2px rgba(0,0,0,1);
	transition: all .3s ease;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-next.slick-disabled::before{
	border-top-color: rgba(0,0,0,0.25);
	border-right-color: rgba(0,0,0,0.25);
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-prev.slick-disabled::before{
	border-bottom-color: rgba(0,0,0,0.25);
	border-left-color: rgba(0,0,0,0.25);
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-next.slick-disabled:hover,
.obj_carousel.obj_carrousel_mini_parcours .slick-prev.slick-disabled:hover{
	-webkit-transform: none;
	        transform: none;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-prev:not(.slick-disabled):hover::before{
	left: 7.5%;
	}
.obj_carousel.obj_carrousel_mini_parcours .slick-next:not(.slick-disabled):hover::before{
	right: 2.5%;
	}

/***/

@media screen and (max-width:767px){
	.obj_carousel.obj_carrousel_mini_parcours{
		margin-bottom:0;
		}
	.obj_carousel.obj_carrousel_mini_parcours .slick-arrow {
		top: calc(-1 * var(--aox-50px));
		}
	.obj_carousel.obj_carrousel_mini_parcours .slick-next {
		right: 3%;
		}
	.obj_carousel.obj_carrousel_mini_parcours .slick-prev {
		right: 12%;
		}
	.obj_carousel.obj_carrousel_mini_parcours .slick-prev:hover::before {
		left: 30%;
		}
	.obj_formation_mini_parcours{
		width:var(--aox-180px);
		margin-bottom:0;
		}
	.obj_formation_mini_parcours a,
	.obj_formation_mini_parcours button{
		padding:0;
		}
	.obj_formation_mini_parcours figure {
		height: var(--aox-130px);
		}

}
/* ======================================== */
/* .obj_section_badge_expertise_expertise
/* ======================================== */
.obj_section_badge_expertise{
	position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
	}
.obj_section_badge_expertise .obj_badge_expertise{
	position: relative;
	width: var(--aox-480px);
	height: var(--aox-480px);
	margin-bottom: var(--aox-5px);
	}
.obj_section_badge_expertise .obj_badge_expertise #canvas {
	z-index: 2;
	width: 100%;
    height: 60vh
	}
.obj_section_badge_expertise .obj_badge_expertise .t_badge_collecter {
	position: absolute;
	z-index: 3;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
	}
.obj_section_badge_expertise .obj_badge_expertise .t_badge_collecter span {
	display: inline-block;
    font-weight: 700;
    font-size: var(--aox-fs-22px);
    line-height: var(--aox-lh-100);
    width: var(--aox-330px);
    text-transform: uppercase;
    padding: var(--aox-17px) var(--aox-30px) var(--aox-17px) var(--aox-30px);
    border-radius: var(--aox-35px);
    background-color: rgba(255, 255, 255, 0.9);
    letter-spacing: normal;
	}
.obj_section_badge_expertise .ctn_text{
	position: relative;
	width: var(--aox-col-6);
	}
.obj_section_badge_expertise .ctn_text .t_title {
	margin-bottom: var(--aox-15px);
	padding-right: 0;
	font-size: var(--aox-fs-20px);
	line-height: 1.2;
	font-weight: 700;
	text-transform: uppercase;
	color: white;
	}
.obj_section_badge_expertise .ctn_text .t_desc {
	font-size: var(--aox-fs-16px);
	line-height: 1.2;
	color: white;
	}
.obj_section_badge_expertise .ctn_text .t_tag {
	margin-top: var(--aox-25px);
	font-weight: 700;
    font-size: var(--aox-fs-40px);
    line-height: var(--aox-lh-100);
	}
.obj_section_badge_expertise .ctn_text .t_tag span {
	display: inline-block;
	text-transform: uppercase;
	padding: var(--aox-10px) var(--aox-30px) var(--aox-10px) var(--aox-30px);
	border-radius: var(--aox-35px);
	background-color: rgba(255, 255, 255, 0.2);
	letter-spacing: normal;
	}

.obj_section_badge_expertise .ctn_lottie{
	position:absolute;
	z-index:1;
	left:50%; top:50%;
	width:var(--aox-700px); height:var(--aox-700px);
	-webkit-transform:translate(-50%,-45%);
	        transform:translate(-50%,-45%);
	}

/***/

@media screen and (max-width:767px){

	.obj_popin.popin_overlay_badge_detail {
		padding: var(--aox-30px);
		}
	.obj_section_badge_expertise .obj_badge_expertise{ 
		width: var(--aox-280px);
		height: var(--aox-280px);
		}
	.obj_section_badge_expertise .obj_badge_expertise #canvas {
		width: 100%;
		height: var(--aox-280px);
		}
	.obj_section_badge_expertise .obj_badge_expertise .t_badge_collecter span {
		font-size: var(--aox-fs-18px);
		width: var(--aox-270px);
		padding: var(--aox-15px) var(--aox-20px) var(--aox-15px) var(--aox-20px);
		}
	.obj_section_badge_expertise .ctn_text {
		width: 100%;
		}
	.obj_section_badge_expertise .ctn_text .t_desc {
		font-size: var(--aox-fs-14px);
		}
	.obj_section_badge_expertise .ctn_text .t_tag span {
		padding: var(--aox-5px) var(--aox-15px);
		font-size: var(--aox-fs-22px);
		}
	.obj_popin.popin_overlay_badge_detail .bt_close {
		right: var(--aox-20px);
		top: var(--aox-20px);
		width: var(--aox-40px);
		height: var(--aox-40px);
		}
	.obj_section_badge_expertise .ctn_lottie{
		width:var(--aox-450px); height:var(--aox-450px);
		}

}
/* ======================================== */
/* .obj_filter-challenge
/* ======================================== */
.obj_filter-challenge-menu{
    display: none;
    }
.obj_filter-challenge{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
	}

/***/
.obj_filter-challenge .obj_filters_header{
    position: relative;
    padding-bottom: var(--aox-12px);
    display: flex;
    margin-bottom: var(--aox-20px);
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    border-bottom: 1px solid #D3D3D3;
    }
.obj_filter-challenge .obj_filters_header .t_title {
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    text-transform: uppercase;
    }
.obj_filter-challenge .obj_filters_header .obj_remove-filter button{
    font-weight: 300;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-100);
    text-decoration: underline;
    }
.obj_filter-challenge .obj_filters_header .obj_remove-filter button:hover{
    text-decoration: none;
    }


/***/

.obj_filter-challenge .obj_filter-list-item{
    width: 100%;
    }
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container{
    width: 100%;
    }
.obj_filter-challenge .obj_filter-list-item .t_title {
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    margin-bottom: var(--aox-10px);
    }
.obj_filter-challenge .obj_filter-list-item + .obj_filter-list-item{
    margin-top: var(--aox-20px);
    }

.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element input[type=checkbox] {
	display: none;
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element label {
	display: block;
    position: relative;
    padding: var(--aox-5px) 0 0 var(--aox-30px);
    min-height: var(--aox-26px);
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    cursor: pointer;
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element label::before {
	position: absolute;
    left: 0;
    top: 3px;
    width: var(--aox-18px);
    height: var(--aox-18px);
    border: 1px solid rgba(0,0,0,0.2);
    background: #fff;
    transition: all .3s ease;
    content: '';
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element label:hover::before {
	background: rgba(0,0,0,0.05);
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element label::after {
	content: '';
    position: absolute;
    left: 4px;
    top: var(--aox-9px);
    width: var(--aox-12px);
    height: var(--aox-9px);
    opacity: 0;
    transition: all .3s ease;
    background-image: url(/dist/formationcaf/static/media/check-mark.04aada52.svg);
    background-size: cover;
    background-repeat: no-repeat;
	}
.obj_filter-challenge .obj_filter-list-item .obj_filter-list-container .ctn_form-element input[type=checkbox]:checked + label::after {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1;
	}
.obj_filter-challenge .obj_filter-list-item.var_date .ctn_form-element{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    }
.obj_filter-challenge .obj_filter-list-item.var_date .ctn_form-element span{
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    margin-right: var(--aox-10px);
    }
.obj_filter-challenge .obj_filter-list-item.var_date .ctn_form-element input{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    border: 1px solid rgba(0,0,0,0.2);
    text-transform: uppercase;
    letter-spacing: 0;
    padding: var(--aox-8px) var(--aox-10px) var(--aox-7px) var(--aox-15px);
    width: var(--aox-180px);
    color: rgba(0,0,0,0.5);
    }
.obj_filter-challenge .obj_filter-list-item.var_date .ctn_form-element i.calendar_icon {
    content: "";
    position: absolute;
    right: 5px;
    top: 50%;
    height: var(--aox-22px);
    width: var(--aox-22px);
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background: url(/dist/formationcaf/static/media/i_calendar_empty.fe1f6b77.svg) center center no-repeat;
    }
.obj_filter-challenge .obj_filter-list-item.var_date li + li{
    margin-top: var(--aox-10px);
    }    

/***/

@media screen and (max-width:767px){

    .obj_filter-challenge {
        flex-wrap: nowrap;
        opacity: 0;
        height: 0;
        padding: 0;
        transition: all .3s ease;
        width: var(--aox-grid);
        margin: 0 auto;
        } 
    .obj_filter-challenge.is-expanded{
        opacity: 1;
        height: auto;
        }


    /***/
    .obj_filter-challenge-menu{
        display: block;
        position: absolute;
        right: 0;
        top: calc(-1 * var(--aox-60px));
        }
    .section_challenges .obj_filter-challenge-menu{
        right: var(--aox-20px);
        }
    .obj_btn_filter-challenge{
        height: var(--aox-40px); width: var(--aox-40px);
        }
    .obj_btn_filter-challenge:before{
        content: "";
        position: absolute;
        left: 0; top: 0;
        height: var(--aox-40px); width: var(--aox-40px);
        background: url(/dist/formationcaf/static/media/i_filter-btn.d6d82962.svg) left top / var(--aox-40px) auto no-repeat;
        }
    .obj_filter-challenge .obj_filters_header .ctn_row{
        width: 100%;
        }
    .obj_filter-challenge > .ctn_row{
        padding: 0 0 var(--aox-20px);
        }
    .obj_filter-challenge .obj_filter-list-item {
        width: var(--aox-col-3);
        }
    .obj_filter-challenge .obj_filter-list-item + .obj_filter-list-item {
        margin-top: 0;
        }
    .obj_filter-challenge .obj_filter-list-item.var_date{
        margin-top: var(--aox-20px);
        width: var(--aox-col-4);
        }

    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge{
        width:var(--aox-40px); height:var(--aox-40px);
        }
    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge::before,
    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge::after{
        position:absolute;
        left:50%; top:50%;
        background-color:#000;
        content:'';
        }
    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge::before{
        width:2px; height:var(--aox-20px);
        -webkit-transform:translate(-50%,-50%) rotate(45deg);
                transform:translate(-50%,-50%) rotate(45deg);
        }
    .obj_filter-challenge-menu.is-clicked .obj_btn_filter-challenge::after{
        width:var(--aox-20px); height:2px;
        -webkit-transform:translate(-50%,-50%) rotate(45deg);
                transform:translate(-50%,-50%) rotate(45deg);
        }

}
/* ======================================== */
/* .obj_table-gains
/* ======================================== */
.obj_table-gains{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    background-color: #F2F2F2;
    transition: all .8s ease;
	}
.obj_table-gains .obj_table-gains-header{
    position: relative;
    padding: var(--aox-15px) var(--aox-20px) var(--aox-10px) var(--aox-20px);
    width: 100%;
    }
.obj_table-gains .obj_table-gains-header .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-17px);
    line-height: var(--aox-lh-100);
    width: var(--aox-175px);
    text-transform: uppercase;
    }

.obj_table-gains .obj_table-gains-header .obj_table-gains-detail{
    position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-100);
    width: var(--aox-185px);
    height: var(--aox-24px);
    text-transform: uppercase;
    text-align: left;
    margin-top:  var(--aox-5px);
    }
.obj_table-gains .obj_table-gains-header .obj_table-gains-detail:before{
    content: '';
    position: absolute;
    right: var(--aox-6px);
    top: calc(50% - var(--aox-2px));
    width: 6px;
    height: 6px;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
            transform: translateY(-50%) rotate(45deg) scale(1);
    transition: all .3s ease;
    }
.obj_table-gains .obj_table-gains-header .obj_table-gains-detail.is-expanded:before{
    -webkit-transform: translateY(-10%) rotate(45deg) scale(-1);
            transform: translateY(-10%) rotate(45deg) scale(-1);
    }
.obj_table-gains .obj_table-gains-header .obj_table-gains-total{
    position: absolute;
    right: var(--aox-20px);
    top: var(--aox-20px);
    font-weight: 700;
    font-size: var(--aox-fs-34px);
    line-height: var(--aox-lh-100);
    }



/***/
.obj_table-gains .obj_table-gains-content{
    position: relative;
    opacity: 0;
    height: 0;
    width: 100%;
    transition:all .3s ease;
	}

.obj_table-gains.is-expanded .obj_table-gains-content{
    opacity: 1;
    height: auto;
    }

.obj_table-gains .obj_tabs-list-gains{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: var(--aox-5px) var(--aox-20px) var(--aox-14px) var(--aox-20px);
    }
.obj_table-gains .obj_tabs-list-gains li .btn_tab_year_gain{
    position: relative;
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-24px);
    padding-left: var(--aox-34px);
    }
.obj_table-gains .obj_tabs-list-gains li .btn_tab_year_gain:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: var(--aox-22px);
    height: var(--aox-22px);
    border: 1px solid rgba(0,0,0,0.2);
    background: #fff;
    transition: all .3s ease;
    }
.obj_table-gains .obj_tabs-list-gains li .btn_tab_year_gain:after {
    content: '';
    position: absolute;
    left: var(--aox-5px); top: var(--aox-7px);
    width: var(--aox-14px);
    height: var(--aox-10px);
    opacity: 0;
    transition: all .3s ease;
    background-image: url(/dist/formationcaf/static/media/check-mark.04aada52.svg);
    background-size: cover;
    }
.obj_table-gains .obj_tabs-list-gains li .btn_tab_year_gain.active:after {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    }

.obj_table-gains .obj_tabs-list-gains li + li{
    margin-left: var(--aox-16px);
    }


.obj_table-gains .obj_table-list-gains{
    border-top: 1px solid #D3D3D3;
    width: 100%;
    padding: var(--aox-16px) var(--aox-20px) var(--aox-10px) var(--aox-20px);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains{
    position: relative;
    margin-bottom: var(--aox-10px);
    width: var(--aox-290px);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains > *{
    width: var(--aox-220px);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains .t_date{
    font-weight: 400;
    font-size: var(--aox-fs-10px);
    line-height: var(--aox-lh-120);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains .t_title{
    font-weight: 500;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains .t_desc{
    font-weight: 700;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains .t_total{
    position: absolute;
    right: 0;
    top: var(--aox-30px);
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-100);
    width: auto;
    }

.obj_table-gains .obj_table-list-gains .obj_table-item-gains.is_empty > * {
    width: 100%;
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains.is_empty{
    margin-top: var(--aox-10px);
    margin-bottom: var(--aox-10px);
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains.is_empty .t_title{
    font-weight: 500;
    font-size: var(--aox-fs-20px);
    line-height: var(--aox-lh-110);
    text-transform: uppercase;
    }
.obj_table-gains .obj_table-list-gains .obj_table-item-gains.is_empty .t_desc{
    font-weight: 500;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-140);
    text-transform: none;
    margin-top: var(--aox-13px);
    }

.obj_table-gains .obj_table-list-gains .obj_table-item-gains + .obj_table-item-gains{
    border-top: 1px solid #D3D3D3;
    padding-top: var(--aox-10px);
    }


.obj_table-gains .obj_table-gains-content.is-ready .obj_table-list-gains .tab-panel{
    display: none;
    }
.obj_table-gains .obj_table-gains-content.is-ready .obj_table-list-gains .tab-panel.active {
    display: block;
    }


/***/

@media screen and (max-width:767px){

    .obj_table-gains .obj_table-gains-header .t_title {
        width: var(--aox-col-4);
        }
    .obj_table-gains .obj_table-gains-header .obj_table-gains-total {
        top: var(--aox-15px);
        }
    .obj_table-gains .obj_table-list-gains .obj_table-item-gains {
        width: var(--aox-grid);
        }

    .obj_table-gains.is-expanded .obj_table-gains-content {
        margin: 0 auto;
        }

}
/* ======================================== */
/* .obj_challenge
/* ======================================== */
.obj_challenge{
    position: relative;
    transition: all 1s ease;
}
.obj_challenge > .ctn_header-title{
    padding: var(--aox-12px) var(--aox-20px) var(--aox-8px) var(--aox-20px);
}
.obj_challenge > .ctn_header-title span{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    color: #fff;
}

.obj_challenge.declarer > .ctn_row{ border: 2px solid #7FD5E0; }
.obj_challenge.repartir > .ctn_row{ border: 2px solid #CA93DE; }
.obj_challenge.en_attente > .ctn_row{ border: 2px solid #F6B15F; }
.obj_challenge.remporte > .ctn_row{ border: 2px solid #ACDE93; }
.obj_challenge.echoue > .ctn_row{ border: 2px solid #C4C4C4; }

.obj_challenge.declarer > .ctn_header-title{ background-color: #7FD5E0; }
.obj_challenge.repartir > .ctn_header-title{ background-color: #CA93DE; }
.obj_challenge.en_attente > .ctn_header-title{ background-color: #F6B15F; }
.obj_challenge.remporte > .ctn_header-title{ background-color: #ACDE93; }
.obj_challenge.echoue > .ctn_header-title{ background-color: #C4C4C4; }

.obj_challenge .ctn_row{
    position: relative;
    border: 1px solid rgba(112, 112, 112, 0.31);
    padding: var(--aox-20px) var(--aox-10px) var(--aox-10px) var(--aox-20px);
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    }
.obj_challenge + .obj_challenge{
    margin-top: var(--aox-30px);
    }
.obj_challenge .ctn_text{
    display: flex;
    width: var(--aox-380px);
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    }
.obj_challenge .ctn_text .obj_temps_restant{
    position: relative;
    margin-bottom: var(--aox-20px);
    }   
.obj_challenge .ctn_text .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    }
.obj_challenge .ctn_text .t_date{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    margin-top: var(--aox-5px);
    }
.obj_challenge .ctn_text .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    margin-top: var(--aox-15px);
    }
.obj_challenge .ctn_text .t_desc strong{
    font-weight: 700;
    font-size: var(--aox-fs-20px);
    line-height: var(--aox-lh-100);
    }
.obj_challenge .obj_btn_challenge{
    width: auto;
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    display: inline-block;
    outline: 0;
    border: 1px solid #000;
    background: transparent;
    color: #000;
    text-transform: uppercase;
    padding: var(--aox-20px) var(--aox-30px);
    margin-top: var(--aox-15px);
    }
.obj_challenge .obj_btn_challenge:hover{
    border: 1px solid #000;
    background: #000;
    color: #fff;
    }

.obj_challenge.repartir .obj_btn_challenge,
.obj_challenge.declarer .obj_btn_challenge{
    border: 1px solid #000;
    background: #000;
    color: #fff;
    }
.obj_challenge.repartir .obj_btn_challenge:hover,
.obj_challenge.declarer .obj_btn_challenge:hover{
    border: 1px solid #000;
    background: transparent;
    color: #000;
    }


.obj_challenge .obj_btn_condition_challenge-detail{
    position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-11px);
    line-height: var(--aox-lh-100);
    padding-right: var(--aox-30px);
    height: var(--aox-24px);
    text-transform: uppercase;
    text-align: left;
    margin-top: var(--aox-15px);
    }
.obj_challenge .obj_btn_condition_challenge-detail:before{
    content: '';
    position: absolute;
    right: var(--aox-6px);
    top: calc(50% - var(--aox-2px));
    width: 6px;
    height: 6px;
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
            transform: translateY(-50%) rotate(45deg) scale(1);
    transition: all .3s ease;
    }
.obj_challenge .obj_btn_condition_challenge-detail.is-active:before{
    -webkit-transform: translateY(-10%) rotate(45deg) scale(-1);
            transform: translateY(-10%) rotate(45deg) scale(-1);
    }

    
.obj_challenge .ctn_image{
    width: var(--aox-170px);
    }
.obj_challenge .ctn_image .ctn_visuel{
    width: var(--aox-170px);
    height: var(--aox-170px);
    overflow: hidden;
    }
.obj_challenge .ctn_image .ctn_visuel img{
    width: 100%;
    }
.obj_challenge .ctn_image .ctn_logo img{
    width: 100%;
    }


/***/

.obj_challenge .ctn_conditions-expand{
    position: relative;
    opacity: 0;
    height: 0;
    width: 100%;
    transition: all .3s ease;
    }
.obj_challenge.is-expanded .ctn_conditions-expand {
    opacity: 1;
    height: auto;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: var(--aox-15px) var(--aox-20px);
    background-color: #F2F2F2;
    border-top: 1px solid rgba(0,0,0,0.1);
    transition: all .3s ease;
    }
    
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: var(--aox-6px);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item + .obj_condition_challenge-item{
    padding-top: var(--aox-6px);
    border-top: 1px solid rgba(0,0,0,0.1);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item + .obj_condition_challenge-item.var_condition{
    background-color: #d9d9d9;
    padding: var(--aox-6px) var(--aox-10px);
    margin-bottom: 0;
    margin-top: calc(-1* var(--aox-5px));
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item:nth-child(1){
    margin-bottom: var(--aox-5px);
    padding-bottom: var(--aox-6px);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item:nth-child(2) {
    border-top: none;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item{
    position: relative;
    width: 35%;
    text-align: left;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) { width: 40%; }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(3) { width: 25%; }


.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-price{
    font-size: var(--aox-fs-22px);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-price em { position: relative; font-size: var(--aox-fs-13px); line-height: var(--aox-lh-120); top: calc(-1 * var(--aox-3px)); }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-label{
    margin-bottom: var(--aox-3px);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) .obj_condition_challenge-item-label,
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) .obj_condition_challenge-item-price{
    text-align: left;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(3){
    text-align: right;
    width: 30%;
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item > .obj_condition_challenge-item-label{
    width: var(--aox-270px);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .obj_condition_challenge-item-label span{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_challenge .ctn_conditions-expand .obj_condition_challenge .obj_condition_challenge-item .obj_condition_challenge-item-price{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    }



/***/

.obj_challenge.is_empty{
    position: relative;
    }
.obj_challenge.is_empty .ctn_text{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: var(--aox-40px);
    z-index: 2;
    justify-content: center;
    }
.obj_challenge.is_empty .t_title{
    font-weight: 400;
    font-size: var(--aox-fs-38px);
    line-height: var(--aox-lh-120);
    color: white;
    text-transform: none;
    margin: 0;
    }
.obj_challenge.is_empty .ctn_background{
    display: block;
    height: var(--aox-550px);
    width: var(--aox-620px);
    }
.obj_challenge.is_empty .ctn_background img{
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    }

/***/



/***/

@media screen and (max-width:767px){

    .pg_challenge .obj_challenge .ctn_row{
        padding: 0;
        flex-direction: column;
        }
    .obj_challenge .ctn_row .ctn_text{
        width: 100%;
        }
    .obj_challenge .ctn_row .ctn_text > .obj_temps_restant,
    .obj_challenge .ctn_row .ctn_text > .t_title,
    .obj_challenge .ctn_row .ctn_text > .t_date,
    .obj_challenge .ctn_row .ctn_text > .t_desc,
    .obj_challenge .ctn_row .ctn_text > .obj_btn_challenge {
        width: var(--aox-220px);
        margin-left: var(--aox-20px);
        }
    .obj_challenge .ctn_row .ctn_text {
        padding-top: var(--aox-20px);
        }   
    .obj_challenge .ctn_row .ctn_text > .obj_btn_challenge {
        width: auto;
        }
    .obj_challenge .ctn_row .ctn_text > .obj_btn_condition_challenge-detail {
        margin-left: var(--aox-20px);
        width: var(--aox-170px);
        padding-right: var(--aox-20px);
        margin-bottom: var(--aox-20px);
        }
    .obj_challenge .ctn_image {
        width: var(--aox-110px);
        position: absolute;
        top: var(--aox-5px); right: var(--aox-5px);
        }
    .obj_challenge .ctn_image .ctn_visuel{
        width: var(--aox-110px);
        height: var(--aox-110px);
        }

    .obj_challenge .ctn_text .t_title{
        font-size: var(--aox-fs-16px);
        line-height: var(--aox-lh-100);
        }

    .obj_challenge.is-expanded .ctn_row .ctn_text > .ctn_conditions-expand{
        width: 100%;
        margin-left: 0;
        }

    .obj_challenge.is_empty .ctn_background {
        display: block;
        width: var(--aox-380px);
        height: var(--aox-340px);
        }   
    .obj_challenge.is_empty .t_title{
        font-size: var(--aox-fs-20px);
        line-height: var(--aox-lh-120);
        }
    

}
/* ======================================== */
/* .obj_challenge-detail
/* ======================================== */
.obj_challenge-detail{
    position: relative;
    transition: all 1s ease;
    width: var(--aox-770px);
    }

/* .obj_challenge-detail.declarer > .ctn_row{ border: 2px solid #7FD5E0; }
.obj_challenge-detail.repartir > .ctn_row{ border: 2px solid #CA93DE; }
.obj_challenge-detail.en_attente > .ctn_row{ border: 2px solid #F6B15F; }
.obj_challenge-detail.remporte > .ctn_row{ border: 2px solid #ACDE93; }
.obj_challenge-detail.echoue > .ctn_row{ border: 2px solid #C4C4C4; }

.obj_challenge-detail.declarer > .ctn_header-title{ background-color: #7FD5E0; }
.obj_challenge-detail.repartir > .ctn_header-title{ background-color: #CA93DE; }
.obj_challenge-detail.en_attente > .ctn_header-title{ background-color: #F6B15F; }
.obj_challenge-detail.remporte > .ctn_header-title{ background-color: #ACDE93; }
.obj_challenge-detail.echoue > .ctn_header-title{ background-color: #C4C4C4; } */

.obj_challenge-detail > .ctn_row{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    border: unset;
    padding: unset;
    }
        
.obj_challenge-detail .ctn_image{
    width: var(--aox-col-3);
    }
.obj_challenge-detail .ctn_image .ctn_visuel{
    width: var(--aox-col-3);
    height: var(--aox-290px);
    overflow: hidden;
    }
.obj_challenge-detail .ctn_image .ctn_visuel img{
    width: 100%;
    }
.obj_challenge-detail .ctn_image .ctn_logo img{
    width:100%;
    }

.obj_challenge-detail .ctn_text{
    display: flex;
    width: var(--aox-440px);
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    }
.obj_challenge-detail .ctn_text .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    margin-bottom: var(--aox-20px);
    }
.obj_challenge-detail .ctn_text .t_date{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_challenge-detail .ctn_text .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-150);
    margin-top: var(--aox-10px);
    width: var(--aox-330px);
    }


/***/

.obj_challenge-detail .obj_condition_challenge{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: var(--aox-25px) var(--aox-30px);
    background-color: #F2F2F2;
    margin-top: var(--aox-40px);
    width: 100%;
    transition: all .3s ease;
    }
.obj_challenge-detail .obj_condition_challenge > div{
    width: 100%;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: var(--aox-6px);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item + .obj_condition_challenge-item{
    padding-top: var(--aox-10px);
    border-top: 1px solid rgba(0,0,0,0.1);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item + .obj_condition_challenge-item.var_condition{
    background-color: #d9d9d9;
    padding: var(--aox-6px) var(--aox-10px);
    margin-bottom: 0;
    margin-top: calc(-1* var(--aox-5px));
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item:nth-child(1){
    margin-bottom: var(--aox-5px);
    padding-bottom: var(--aox-6px);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item:nth-child(2){
    border-top: none;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item{
    position: relative;
    width: 35%;
    text-align: center;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1){
    width: 40%;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-price{
    font-size: var(--aox-fs-22px);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-label{
    margin-bottom: var(--aox-6px);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) .obj_condition_challenge-item-label,
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) .obj_condition_challenge-item-price{
    text-align: left;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(2) .obj_condition_challenge-item-label,
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(2) .obj_condition_challenge-item-price{
    text-align: left;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(2) .obj_condition_challenge-item-price em{
    position: relative;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    top: calc(-1 * var(--aox-3px));
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(3){
    text-align: right;
    width: 25%;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item > .obj_condition_challenge-item-label{
    width: var(--aox-280px);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .obj_condition_challenge-item-label > span{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-140);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .obj_condition_challenge-item-price{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    }
    


/***/

@media screen and (max-width:767px){

    .obj_challenge-detail {
        width: 100%;
        }
    
    .obj_challenge-detail .ctn_challenge-detail {
        position: relative;
        width: 100vw;
        margin-left: calc(-1 * var(--aox-gutter));
        padding: var(--aox-15px) var(--aox-20px);
        background-color: #F2F2F2;
        transition: all .8s ease;
        }
    .obj_challenge-detail .ctn_challenge-detail .obj_btn_challenge-detail{
        position: relative;
        font-weight: 700;
        font-size: var(--aox-fs-11px);
        line-height: var(--aox-lh-100);
        width: auto;
        height: var(--aox-24px);
        text-transform: uppercase;
        padding-right: var(--aox-30px);
        text-align: left;
        }
    .obj_challenge-detail .ctn_challenge-detail .obj_btn_challenge-detail:before{
        content: '';
        position: absolute;
        right: var(--aox-6px);
        top: calc(50% - var(--aox-2px));
        width: 6px;
        height: 6px;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
                transform: translateY(-50%) rotate(45deg) scale(1);
        transition: all .3s ease;
        }
    .obj_challenge-detail .ctn_challenge-detail.is-expanded .obj_btn_challenge-detail:before{
        -webkit-transform: translateY(-10%) rotate(45deg) scale(-1);
                transform: translateY(-10%) rotate(45deg) scale(-1);
        }

    .obj_challenge-detail .ctn_challenge-detail .expend_challenge-detail{
        position: relative;
        opacity: 0;
        height: 0;
        transition: all .3s ease;
        }
    .obj_challenge-detail .ctn_challenge-detail.is-expanded .expend_challenge-detail{
        opacity: 1;
        height: auto;
        }

    .obj_challenge-detail .ctn_challenge-detail .expend_challenge-detail .ctn_row{
        flex-direction: row-reverse;
        padding-top: var(--aox-20px);
        }
    .obj_challenge-detail .expend_challenge-detail .ctn_image {
        width: var(--aox-120px);
        }
    .obj_challenge-detail .expend_challenge-detail .ctn_text {
        width: var(--aox-230px);
        }
    .obj_challenge-detail .expend_challenge-detail .ctn_text > .t_title{
        display: none;
        }
    .obj_challenge-detail .ctn_text .t_date {
        font-size: var(--aox-fs-14px);
        }
    .obj_challenge-detail .expend_challenge-detail .ctn_text .t_desc {
        width: 100%;
        }

    .obj_challenge-detail .obj_condition_challenge{
        padding: 0;
        margin-top: var(--aox-10px);
        }

    .obj_challenge-detail .ctn_image .ctn_visuel {
        width: var(--aox-col-2);
        height: var(--aox-col-2);
        }

}
/* ======================================== */
/* .obj_table-produit-concernes
/* ======================================== */
.obj_table-produit-concernes .ctn_row-header{
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-16px) var(--aox-20px);
    border-bottom:1px solid #d7d7d7;
	}
.obj_table-produit-concernes .ctn_row{
    display:flex;
    flex-flow:row wrap;
    padding:var(--aox-16px) var(--aox-20px);
    }
.obj_table-produit-concernes .ctn_row + .ctn_row{
	border-top:1px solid #d7d7d7;
	}

.obj_table-produit-concernes .ctn_row-header p{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    }

.obj_table-produit-concernes .ctn_row-header p:nth-child(1){
	width:20%;
	}
.obj_table-produit-concernes .ctn_row-header p:nth-child(2){
    width:60%;
    }
.obj_table-produit-concernes .ctn_row-header p:nth-child(3){
    width:20%;
    text-align: right;
    }

.obj_table-produit-concernes .ctn_row p{
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    }
.obj_table-produit-concernes .ctn_row p:nth-child(1){
	width:20%;
	}
.obj_table-produit-concernes .ctn_row p:nth-child(2){
    width:60%;
    }
.obj_table-produit-concernes .ctn_row p:nth-child(3){
    width:20%;
    text-align: right;
    }


/***/

@media screen and (max-width:767px){



    

}
/* ======================================== */
/* .obj_aside_challenge
/* ======================================== */
.obj_aside_challenge{
	position: relative;
    transition: all 1s ease;
    width: var(--aox-440px);
}
.obj_aside_challenge .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    width: var(--aox-280px);
    margin-bottom: var(--aox-20px);
    }


/***/
.obj_aside_challenge .ctn_header{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: var(--aox-40px);
    }


.obj_aside_challenge .ctn_header .obj_temps_restant{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .tag_title{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    text-transform: uppercase;
    padding: var(--aox-8px) var(--aox-15px);
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .tag_title span{
    color: #ffffff;
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_jours-restant{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    margin-top: var(--aox-10px);
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_jours-restant span{
    font-weight: 700;
    font-size: var(--aox-fs-42px);
    line-height: var(--aox-lh-90);
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_jours-restant p{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: var(--aox-fs-12px);
    margin-left: var(--aox-10px);
    }
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_jours-restant p strong{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    }

/***/
.obj_aside_challenge .ctn_header .obj_temps_restant .obj_progressbar-challenge{
    margin-top: var(--aox-10px);
    }



/***/
.obj_aside_challenge.declarer .obj_temps_restant .tag_title{ background-color: #7FD5E0; }
.obj_aside_challenge.declarer .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.declarer .obj_temps_restant .obj_jours-restant p strong{ color: #3aaac1; }
.obj_aside_challenge.declarer .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #3aaac1; }

.obj_aside_challenge.repartir .obj_temps_restant .tag_title{ background-color: #CA93DE; }
.obj_aside_challenge.repartir .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.repartir .obj_temps_restant .obj_jours-restant p strong{ color: #892bad; }
.obj_aside_challenge.repartir .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #892bad; }

.obj_aside_challenge.en_attente .obj_temps_restant .tag_title{ background-color: #F7B15E; }
.obj_aside_challenge.en_attente .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.en_attente .obj_temps_restant .obj_jours-restant p strong{ color: #F7B15E; }
.obj_aside_challenge.en_attente .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #F7B15E; }

.obj_aside_challenge.remporte .obj_temps_restant .tag_title{ background-color: #ACDE93; }
.obj_aside_challenge.remporte .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.remporte .obj_temps_restant .obj_jours-restant p strong{ color: #ACDE93; }
.obj_aside_challenge.remporte .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #ACDE93; }

.obj_aside_challenge.echoue .obj_temps_restant .tag_title{ background-color: #C4C4C4; } 
.obj_aside_challenge.echoue .obj_temps_restant .obj_jours-restant span,
.obj_aside_challenge.echoue .obj_temps_restant .obj_jours-restant p strong{ color: #C4C4C4; }   
.obj_aside_challenge.echoue .obj_temps_restant .obj_progress-bar-challenge step{ background-color: #C4C4C4; } 


/***/

.obj_aside_challenge .obj_saisie-vente-item{
    position: relative;
    margin-bottom: var(--aox-30px);
    }
.obj_aside_challenge .obj_saisie-vente-item .t_desc{
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-150);
    padding-right: var(--aox-col-1);
    margin-bottom: var(--aox-10px);
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input{
    position: relative;
    padding: var(--aox-20px) var(--aox-30px);
    background-color: #F2F2F2;
    margin-top: var(--aox-10px);
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input{
    position: relative;
    display: flex;
    margin: 0;
    width: var(--aox-180px);
    flex-direction: row;
    align-items: center;
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input .t_label{
    position: relative;
    margin: 0;
    font-weight: 700;
    color: #000;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    text-transform: uppercase;
    margin-right: var(--aox-10px);
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input:nth-child(2) {
    justify-content: flex-end
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input:nth-child(2) .t_label{
    text-align: right;
    }
.obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input input[type="text"]{
    font-weight: 700;
    color: #000;
    width: var(--aox-90px);
    padding: var(--aox-20px);
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    }
.obj_aside_challenge .obj_saisie-vente-item + .t_title{
    margin-top: var(--aox-50px);
    }
.obj_aside_challenge .ctn_file-upload .t_desc{
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-150);
    margin-bottom: var(--aox-30px);
    }

.obj_aside_challenge .obj_saisie-vente .ctn_file-upload{
    position: relative;
    margin-top: var(--aox-30px);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload + .ctn_file-upload{
    margin-top: var(--aox-10px);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .ctn_cta {
	margin-top: 0;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .obj_cta {
	padding:0;
    margin:0;
    width:100%;
	height:var(--aox-110px);
    font-size:var(--aox-fs-13px); line-height:var(--aox-lh-100);
    font-weight:700;
    color:#000;
    text-transform:uppercase;
	background: rgba(0,0,0,0.05);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .error label{
	border:1px solid #e80000;
	}
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload.hover .obj_cta {
    background: rgba(0,0,0,0.1);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload {
    z-index: 2;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .i_add {
    display:flex;
    flex-direction:column;
    align-items:center;
    position:absolute;
    z-index:1;
    left:var(--aox-25px); top:50%;
	width:var(--aox-85px);
    text-align:center;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .i_add span{
	margin-top:var(--aox-5px);
    font-size:var(--aox-fs-10px); line-height:var(--aox-lh-100);
    font-weight:700;
    color:#000;
    text-transform:uppercase;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .i_add svg{
	width:var(--aox-27px); height:var(--aox-20px);
    }
.obj_aside_challenge .obj_saisie-vente .obj_form .t_mention {
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    font-weight: 400;
    color: #919191;
    margin-top: var(--aox-15px);
    }
.obj_aside_challenge .obj_saisie-vente .obj_form .ctn_cta {
    margin-top: var(--aox-30px);
    }
.obj_aside_challenge .obj_saisie-vente .obj_form .ctn_cta + .t_error {
    margin-top: var(--aox-30px);
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .bt_cancel{
	left: auto;
	right: var(--aox-10px);
	top: 50%;
	-webkit-transform: translate(0,-50%);
	        transform: translate(0,-50%);
	background: #000;
	}
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .canvas_thumb{
    display: none;
    }

.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .ctn_file-thumb{
	position:absolute;
	right:var(--aox-20px); top:var(--aox-20px);
	margin:0;
	width:var(--aox-290px);
	}

.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .ctn_infos {
	display: flex;
	flex-direction: column;
	justify-content: center;
    position: relative;
    padding: var(--aox-5px) var(--aox-40px);
    width: 100%; height: var(--aox-70px);
    background: url(/dist/formationcaf/static/media/i_file.a1fa46c7.svg) var(--aox-10px) center / var(--aox-20px) auto no-repeat #fff !important;
	border:1px dashed #A5A5A5;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .t_name {
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-140);
    font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
    }
.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .t_weight {
    margin-top: 0;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    color: #919191;
    }

/***/
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains{
    position: relative;
    margin-bottom: var(--aox-20px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains .ctn_graph_infos_gains{
    position: absolute;
    left: 50%;
    top: 75%;
    -webkit-transform: translate(-50%,-75%);
            transform: translate(-50%,-75%);
    display: flex;
    flex-direction: column;
    align-items: center;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains .ctn_graph_infos_gains .t_desc{
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-140);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains .ctn_graph_infos_gains .t_gain{
    font-weight: 700;
    color: #000000;
    font-size: var(--aox-fs-40px);
    line-height: var(--aox-lh-100);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_graph_gains .ctn_graph_infos_gains .t_total{
    font-weight: 400;
    color: #000000;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }


.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains{
    margin-top: var(--aox-10px);
    margin-bottom: var(--aox-20px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains{
    position: relative;
    display: flex;
    background-color: #F2F2F2;
    padding: var(--aox-10px);
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains + .item_participants_gains{
    margin-top: var(--aox-10px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains > * + *{
    margin-left: var(--aox-10px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_gain{
    margin-left: auto;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_picture{
    position: relative;
    width: var(--aox-50px);
    height: var(--aox-50px);
    border-radius: 100%;
    overflow: hidden;
    border: 3px solid #000;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_picture img{
    width: 100%;
    height: 100%;
    }

.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_infos{
    display: flex;
    flex-direction: column;
    width: var(--aox-160px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_infos .t_name{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-120);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_infos .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_gain input[type="text"]{
    width: var(--aox-80px);
    font-weight: 700;
    text-align: center;
    padding: var(--aox-10px) var(--aox-25px) var(--aox-10px) var(--aox-15px);
    background-image: url(/dist/formationcaf/static/media/v_euro.ab29766a.svg);
    background-size: 10px;
    background-position-x: var(--aox-60px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_gain input[type="text"].error{
    background-image: url(/dist/formationcaf/static/media/v_euro_red.4970ee79.svg);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete{
    position: relative;
    width: var(--aox-40px);
    height: var(--aox-40px);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete {
    display: block;
    position: absolute;
    z-index: 9999;
    top: 50%; left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    width: var(--aox-20px);
    height: var(--aox-20px);
    border-radius: 100%;
    background: #000;
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete::before, 
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete::after {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: #fff;
    transition: all .3s ease;
    content: '';
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete::before {
    width: 2px;
    height: var(--aox-15px);
    border-radius: 2px;
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
            transform: translate(-50%,-50%) rotate(45deg);
    }
.obj_aside_challenge .obj_repartition-gains .ctn_list_participants_gains .item_participants_gains .participant_delete .btn_delete::after {
    width: var(--aox-15px);
    height: 2px;
    border-radius: 2px;
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
            transform: translate(-50%,-50%) rotate(45deg);
    }

.obj_aside_challenge .obj_repartition-gains .obj_form .t_mention {
    margin-top: var(--aox-30px);
    margin-bottom: var(--aox-15px);
    }

/***/

.obj_aside_challenge .obj_saisie-vente .ctn_add-file{
	margin-top:var(--aox-20px);
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file{
	position:relative;
	display:flex;
	align-items:center;
	width:100%;
	padding-left:var(--aox-20px);
	padding-right:var(--aox-60px);
	height:var(--aox-65px);
	font-size:var(--aox-fs-12px);
	text-transform:uppercase;
	font-weight:700;
	border:1px solid #000;
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file:hover{
	background:#F2F2F2;
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus{
	position:absolute;
	right:var(--aox-20px); top: 50%;
	width:var(--aox-16px); height:var(--aox-16px);
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus::before,
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#000;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus::before{
	width:var(--aox-16px); height:var(--aox-3px);
	}
.obj_aside_challenge .obj_saisie-vente .bt_add-file .i_plus::after{
	width:var(--aox-3px); height:var(--aox-16px);
	}

@media screen and (max-width:767px){
    .obj_aside_challenge {
        margin-top: var(--aox-20px);
        width: 100%;
        }
    .obj_aside_challenge .t_title {
        font-size: var(--aox-fs-20px);
        width: var(--aox-col-5);
        margin-bottom: var(--aox-15px);
        }
    .obj_aside_challenge .obj_saisie-vente-item .obj_saisie-vente-ctn_input .ctn_input{
        width: auto;
        }
	.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .i_add {
		left:var(--aox-15px);
		}
	.obj_aside_challenge .obj_saisie-vente .ctn_file-upload .ctn_single-file-upload .ctn_file-thumb{
		width:var(--aox-250px);
		}
}
/* ======================================== */
/* .obj_tableau-beneficiaire
/* ======================================== */
.obj_tableau-beneficiaire{
	position: relative;
    display: flex;
    flex-direction: column;
    transition: all 1s ease;
    width: 100%;
    }
.obj_tableau-beneficiaire .obj_table-header{
    display: flex;
    flex-flow: row wrap;
    padding: var(--aox-16px) var(--aox-20px);
    border-bottom: 1px solid #d7d7d7;
    align-items: center;
    }

.obj_tableau-beneficiaire .header-cell {
    position: relative;
    flex: 1 1;
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    padding: 0 var(--aox-15px);
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell.sortable span{
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-right: var(--aox-35px);
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell.sortable span:after{
    content: '';
    position: absolute;
    left: unset;
    top: 50%;
    right: 0;
    margin-left: auto;
    width: var(--aox-30px);
    height: var(--aox-30px);
    border-radius: 0;
    -webkit-transform: translate(2%,-50%);
            transform: translate(2%,-50%);
    background: url(/dist/formationcaf/static/media/i_sortable.31cd69ed.svg) center center / var(--aox-30px) auto no-repeat !important;
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell.sortable.active span:after{
    -webkit-transform: translate(2%,-50%) rotate(180deg);
            transform: translate(2%,-50%) rotate(180deg);
    }

.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(1),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(1) {
    flex: 0.2 1; padding: 0;
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(5),
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(6),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(5),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(6){
    flex: 0.75 1;
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(2),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(2){
    flex: 1.75 1;
    }
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(3),
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(3) {
    flex: 2.25 1;
    }

.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(5){ text-align: center; }
.obj_tableau-beneficiaire .obj_table-header .header-cell:nth-child(6){ text-align: right; }

    
.obj_tableau-beneficiaire .table-row {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding: var(--aox-16px) var(--aox-20px);
    align-items: center;
    }
.obj_tableau-beneficiaire .table-row + .table-row {
    border-top: 1px solid #ccc;
    }  
/* .obj_tableau-beneficiaire .table-row:hover {
    background-color: #f2f2f2;
    } */
    
.obj_tableau-beneficiaire .row-cell {
    flex: 1 1;
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-150);
    padding: 0 var(--aox-15px);
    }
.obj_tableau-beneficiaire .row-cell strong{
    font-weight: 700;
    }

/***/
.obj_tableau-beneficiaire .row-cell .tgl {
    display: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl, .obj_tableau-beneficiaire .row-cell .tgl:after, .obj_tableau-beneficiaire .row-cell .tgl:before, .obj_tableau-beneficiaire .row-cell .tgl *, .obj_tableau-beneficiaire .row-cell .tgl *:after, .obj_tableau-beneficiaire .row-cell .tgl *:before, .obj_tableau-beneficiaire .row-cell .tgl + .obj_tableau-beneficiaire .row-cell .tgl-btn {
    box-sizing: border-box;
    }
.obj_tableau-beneficiaire .row-cell .tgl::selection, .obj_tableau-beneficiaire .row-cell .tgl:after::selection, .obj_tableau-beneficiaire .row-cell .tgl:before::selection, .obj_tableau-beneficiaire .row-cell .tgl *::selection, .obj_tableau-beneficiaire .row-cell .tgl *:after::selection, .obj_tableau-beneficiaire .row-cell .tgl *:before::selection, .obj_tableau-beneficiaire .row-cell .tgl + .obj_tableau-beneficiaire .row-cell .tgl-btn::selection {
    background: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: var(--aox-40px);
    height: var(--aox-22px);
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn:after, .obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    }
.obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn:after {
    left: 0;
    }
.obj_tableau-beneficiaire .row-cell .tgl + .tgl-btn:before {
    display: none;
    }
.obj_tableau-beneficiaire .row-cell .tgl:checked + .tgl-btn:after {
    left: 50%;
    }

.obj_tableau-beneficiaire .row-cell .tgl-light + .tgl-btn {
    background: #D9D9D9;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    }
.obj_tableau-beneficiaire .row-cell .tgl-light + .tgl-btn:after {
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
    }
.obj_tableau-beneficiaire .row-cell .tgl-light:checked + .tgl-btn {
    background: #000000;
    }
.obj_tableau-beneficiaire .row-cell .tgl-light:checked:disabled + .tgl-btn {
    background: #E7D5EF;
    cursor: not-allowed;
    }

/***/


    
.obj_tableau-beneficiaire .ctn_expand {
    background-color: #F7F7F7;
    padding: var(--aox-10px) var(--aox-70px) var(--aox-10px) var(--aox-68px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* transition: all 1s ease-out; */
    -webkit-animation: beneficiaire_effect 1.5s cubic-bezier(0.33,1,0.68,1) 0s both;
            animation: beneficiaire_effect 1.5s cubic-bezier(0.33,1,0.68,1) 0s both;
    flex-direction: column;
    flex-wrap: wrap;
    }
@-webkit-keyframes beneficiaire_effect{
    0% { opacity:0; }
    100% { opacity:1; }
    }
@keyframes beneficiaire_effect{
    0% { opacity:0; }
    100% { opacity:1; }
    }
    
.obj_tableau-beneficiaire .ctn_expand .ctn_expand-row {
    position: relative;
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-150);
    width: 100%;
    padding: var(--aox-10px) 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    }
.obj_tableau-beneficiaire .ctn_expand .ctn_expand-row + .ctn_expand-row {
    border-top: 1px solid #D9D9D9;
    }
.obj_tableau-beneficiaire .ctn_expand .ctn_expand-row > * + *{
    padding-left: var(--aox-50px);
    }
.obj_tableau-beneficiaire .ctn_expand .expand_date {
    width: var(--aox-90px);
    }
.obj_tableau-beneficiaire .ctn_expand .expand_marque {
    width: var(--aox-200px);
    text-transform: uppercase;
    }
.obj_tableau-beneficiaire .ctn_expand .expand_libelle {
    width: var(--aox-500px);
    }
.obj_tableau-beneficiaire .ctn_expand .expand_montant {
    margin-left: auto;
    font-size: var(--aox-fs-16px);
    }
.obj_tableau-beneficiaire .ctn_expand .expand_montant strong {
    font-weight: 700;
    }
   
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(1){ text-align: center; }

.obj_tableau-beneficiaire .table-row .row-cell:nth-child(5){ 
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    }
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(6){ 
    text-align: right; 
    font-size: var(--aox-fs-16px);
    }
.obj_tableau-beneficiaire .table-row .row-cell:nth-child(6) strong{ 
    padding-right: var(--aox-35px);
    }


.obj_tableau-beneficiaire .obj_btn_expend-cell{
    position:relative;
    z-index:999;
    width:var(--aox-24px); height:var(--aox-24px);
    background: #E5E5E5;
    border-radius: 50%;
    font-size: 0;
    cursor: pointer;
    transition:all .5s ease;
    }
.obj_tableau-beneficiaire .obj_btn_expend-cell::before{
    position: absolute;
    left: 50%; top: 55%;
    width: var(--aox-7px);
    height: var(--aox-7px);
    -webkit-transform: rotate(-225deg) translate(-80%,-20%);
            transform: rotate(-225deg) translate(-80%,-20%);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    content: '';
    transition: all .3s ease;
    }
.obj_tableau-beneficiaire .obj_btn_expend-cell:hover {
    background-color: #000;
    }
.obj_tableau-beneficiaire .obj_btn_expend-cell:hover::before {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    }
.obj_tableau-beneficiaire .table-row.active .obj_btn_expend-cell::before{
    -webkit-transform: rotate(-45deg) translate(-50%,-50%);
            transform: rotate(-45deg) translate(-50%,-50%);
    }






/***/

@media screen and (max-width:767px){

    .obj_tableau-beneficiaire .table-row{
        flex-direction: column;
        align-items: flex-start;
        padding: var(--aox-16px) 0;
        }
    .obj_tableau-beneficiaire .table-row .table-header{
        width: 100%;
        }
    .obj_tableau-beneficiaire .table-row .table-header .row-cell{ text-align: left; }
    .obj_tableau-beneficiaire .row-cell{
        padding: 0;
        }
    .obj_tableau-beneficiaire .table-row .table-ctn-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: var(--aox-2px) 0;
        }
    .obj_tableau-beneficiaire .table-row .table-ctn-row .header-cell{
        font-weight: 400;
        padding: 0;
        width: var(--aox-110px);
        flex: unset;
        color: rgba(0,0,0,0.5);
        }
    .obj_tableau-beneficiaire .table-row .table-ctn-row .row-cell{
        flex: unset;
        }

    /***/
    .obj_tableau-beneficiaire .obj_btn_expand-detail{
        position: absolute;
        top: var(--aox-130px);
        right: 0;
        font-weight: 700;
        font-size: var(--aox-fs-11px);
        line-height: var(--aox-lh-100);
        width: var(--aox-120px);
        height: var(--aox-24px);
        text-transform: uppercase;
        text-align: left;
        }
    .obj_tableau-beneficiaire .obj_btn_expand-detail:before {
        content: '';
        position: absolute;
        right: var(--aox-6px);
        top: calc(50% - var(--aox-2px));
        width: 6px;
        height: 6px;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        -webkit-transform: translateY(-50%) rotate(45deg) scale(1);
                transform: translateY(-50%) rotate(45deg) scale(1);
        transition: all .3s ease;
        }
    .obj_tableau-beneficiaire .obj_btn_expand-detail.is-expanded:before {
        -webkit-transform: translateY(-10%) rotate(45deg) scale(-1);
                transform: translateY(-10%) rotate(45deg) scale(-1);
        }
    .obj_tableau-beneficiaire .obj_table-gains-content {
        position: relative;
        opacity: 0;
        height: 0;
        transition: all .3s ease;
        }
    .obj_tableau-beneficiaire .table-row.is-expanded .obj_table-gains-content {
        opacity: 1;
        height: auto;
        }
    .obj_tableau-beneficiaire .table-row .obj_table-gains-content {
        position: relative;
        margin-top: var(--aox-10px);
        transition: all .3s ease;
        width: 100%;
        }
    .obj_tableau-beneficiaire .table-row .obj_table-gains-content .ctn_expand{
        position: relative;
        background-color: #F7F7F7;
        padding: var(--aox-10px) var(--aox-15px);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: all .8s ease;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        }
    .obj_tableau-beneficiaire .ctn_expand .ctn_expand-row{
        align-items: flex-start;
        }

    .obj_tableau-beneficiaire .ctn_expand .ctn_expand-row > * + * {
        padding-left: var(--aox-20px);
        }
    .obj_tableau-beneficiaire .ctn_expand .expand_date {
        width: var(--aox-90px);
        }
    .obj_tableau-beneficiaire .ctn_expand .expand_infos {
        width: var(--aox-200px);
        font-size: var(--aox-fs-14px);
        line-height: var(--aox-lh-140);
        }
    .obj_tableau-beneficiaire .ctn_expand .expand_marque {
        width: var(--aox-180px);
        }
    .obj_tableau-beneficiaire .ctn_expand .expand_libelle {
        width: var(--aox-180px);
        }

}
/* ======================================== */
/* .obj_progress-bar-challenge
/* ======================================== */
:root {
	--color-progressbar-lrp: 0,159,227;
	--color-progressbar-cerave: 37,112,184;
	--color-progressbar-sanoflore: 60,100,60;
	--color-progressbar-vichy: 29,29,27;
	--color-progressbar-skinc: 0,139,176;
	--color-progressbar-biotherm: 32,97,112;
  }
.obj_progress-bar-challenge{
	position: relative;
	width: var(--aox-200px);
	margin-top: var(--aox-10px);
	}
.obj_progress-bar-challenge .t_title{
	margin-bottom: var(--aox-10px);
    font-size:var(--aox-fs-12px); line-height: 1.2;
    font-weight: 700;
	text-transform: uppercase;
	display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
	}
.obj_progress-bar-challenge .t_title span{
	font-weight: 400;
	margin-left: var(--aox-10px);
	}
.obj_progress-bar-challenge .obj_progress-bar{
	position: relative;
    background-color: #F0F0F0;
    border-radius: var(--aox-16px);
    padding: 2px;
    height: 8px;
    width: 100%;
	}
.obj_progress-bar-challenge + .obj_progress-bar-challenge{
	margin-top: var(--aox-25px);
	}
.obj_progress-bar-challenge .obj_progress-bar .ctn_steps{
	display: flex;
    align-items: center;
    flex-direction: row;
	width: 100%;
	}
.obj_progress-bar-challenge .obj_progress-bar .step {
	position: relative;
	display: block;
	height: 4px;
    background: #000;
	border-radius: var(--aox-10px);
	}
.obj_progress-bar-challenge .obj_progress-bar .step.active ~ .step.step {
	color: #000;
	}

/***/
.obj_progress-bar-challenge .obj_progress-bar .step {
	background: rgb(240,240,240);
	}

.declarer .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(58, 170, 193); }
.repartir .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(137, 43, 173); }
.en_attente .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(246, 177, 95); }
.remporte .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(172, 222, 147); }
.echoue .obj_temps_restant .obj_progress-bar-challenge .step{ background-color: rgb(196, 196, 196); } 

.declarer .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(58, 170, 193,0.2); }
.repartir .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(137, 43, 173,0.2); }
.en_attente .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(246, 177, 95,0.2); }
.remporte .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(172, 222, 147,0.2); }
.echoue .obj_temps_restant .obj_progress-bar-challenge .obj_progress-bar{ background-color: rgba(196, 196, 196,0.2); } 


/* var_lrp */
.obj_progress-bar-challenge.var_lrp .obj_progress-bar{
	background: rgba(0,159,227, 0.15);
	background: rgba(var(--color-progressbar-lrp), 0.15);
	}
.obj_progress-bar-challenge.var_lrp .obj_progress-bar .step.is-complete {
	background: rgba(0,159,227, 1);
	background: rgba(var(--color-progressbar-lrp), 1);
	}
.obj_progress-bar-challenge.var_lrp .obj_progress-bar .ctn_cta{
	background: rgba(0,159,227, 1);
	background: rgba(var(--color-progressbar-lrp), 1);
	}
/* var_cerave */
.obj_progress-bar-challenge.var_cerave .obj_progress-bar{
	background: rgba(37,112,184, 0.15);
	background: rgba(var(--color-progressbar-cerave), 0.15);
	}
.obj_progress-bar-challenge.var_cerave .obj_progress-bar .step.is-complete {
	background: rgba(37,112,184, 1);
	background: rgba(var(--color-progressbar-cerave), 1);
	}
/* var_vichy */
.obj_progress-bar-challenge.var_vichy .obj_progress-bar{
	background: rgba(29,29,27, 0.15);
	background: rgba(var(--color-progressbar-vichy), 0.15);
	}
.obj_progress-bar-challenge.var_vichy .obj_progress-bar .step.is-complete {
	background: rgba(29,29,27, 1);
	background: rgba(var(--color-progressbar-vichy), 1);
	}
/* var_skinc */
.obj_progress-bar-challenge.var_skinc .obj_progress-bar{
	background: rgba(0,139,176, 0.15);
	background: rgba(var(--color-progressbar-skinc), 0.15);
	}
.obj_progress-bar-challenge.var_skinc .obj_progress-bar .step.is-complete {
	background: rgba(0,139,176, 1);
	background: rgba(var(--color-progressbar-skinc), 1);
	}
/* var_sanoflore */
.obj_progress-bar-challenge.var_sanoflore .obj_progress-bar{
	background: rgba(60,100,60, 0.15);
	background: rgba(var(--color-progressbar-sanoflore), 0.15);
	}
.obj_progress-bar-challenge.var_sanoflore .obj_progress-bar .step.is-complete {
	background: rgba(60,100,60, 1);
	background: rgba(var(--color-progressbar-sanoflore), 1);
	}	
/* var_biotherm */
.obj_progress-bar-challenge.var_biotherm .obj_progress-bar{
	background: rgba(32,97,112, 0.15);
	background: rgba(var(--color-progressbar-biotherm), 0.15);
	}
.obj_progress-bar-challenge.var_biotherm .obj_progress-bar .step.is-complete {
	background: rgba(32,97,112, 1);
	background: rgba(var(--color-progressbar-biotherm), 1);
	}


.obj_progress-bar-challenge .obj_progress-bar .active_profil{
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
    top: 50%; left: 0%;
    width: var(--aox-32px); height: var(--aox-32px);
    z-index: 2;
	}
.obj_progress-bar-challenge .obj_progress-bar .active_profil {
	width: var(--aox-32px); height: var(--aox-32px);
	border-radius: 50%;
	overflow: hidden;
	border: 2px solid white;
	}


/***/
.obj_progress-bar-challenge.var_attention  {
	-webkit-animation-duration: 3.5s;
	        animation-duration: 3.5s;
	-webkit-animation-name: clignoter;
	        animation-name: clignoter;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	transition: none;
	}
 @-webkit-keyframes clignoter {
	0%   { opacity:1; }
	40%   {opacity:0; }
	100% { opacity:1; }
	}
 @keyframes clignoter {
	0%   { opacity:1; }
	40%   {opacity:0; }
	100% { opacity:1; }
	}

 


@media screen and (max-width:767px){

}
/* ======================================== */
/* .obj_temps_restant
/* ======================================== */
.obj_temps_restant{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    }
.obj_temps_restant .tag_title{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    text-transform: uppercase;
    padding: var(--aox-8px) var(--aox-15px);
    }
.obj_temps_restant .tag_title span{
    color: #ffffff;
    }
.obj_temps_restant .obj_jours-restant{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_temps_restant .obj_jours-restant span{
    font-weight: 700;
    font-size: var(--aox-fs-42px);
    line-height: var(--aox-lh-90);
    }
.obj_temps_restant .obj_jours-restant p{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: var(--aox-fs-12px);
    margin-left: var(--aox-10px);
    }
.obj_temps_restant .obj_jours-restant p strong{
    font-weight: 700;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    }

/***/
.obj_temps_restant .obj_progressbar-challenge{
    margin-top: var(--aox-10px);
    }

/***/
.obj_temps_restant .obj_jours-restant-info{
    margin-top: var(--aox-6px);
    max-width: var(--aox-370px);
    }
.obj_temps_restant .obj_jours-restant-info p{
	font-size: var(--aox-fs-12px);
    line-height: 1;
    font-weight: 500;
    color: #ff4847;
    }

.en_attente .obj_temps_restant .obj_jours-restant-info{ margin-top: var(--aox-10px); }
.en_attente .obj_temps_restant .obj_jours-restant-info p{ font-size: var(--aox-fs-13px); line-height: var(--aox-lh-120); }

/***/
.declarer .obj_temps_restant .obj_jours-restant span,
.declarer .obj_temps_restant .obj_jours-restant p strong{ color: #3aaac1; }

.repartir .obj_temps_restant .obj_jours-restant span,
.repartir .obj_temps_restant .obj_jours-restant p strong{ color: #892bad; }

.en_attente .obj_temps_restant .obj_jours-restant span,
.en_attente .obj_temps_restant .obj_jours-restant p strong{ color: #F7B15E; }

.en_attente .obj_temps_restant .obj_jours-restant-info p{ color: #F8792D; }

.remporte .obj_temps_restant .obj_jours-restant span,
.remporte .obj_temps_restant .obj_jours-restant p strong{ color: #ACDE93; }

.echoue .obj_temps_restant .obj_jours-restant span,
.echoue .obj_temps_restant .obj_jours-restant p strong{ color: #C4C4C4; }   


@media screen and (max-width:767px){

}
.obj_list_participants_select .i_plus{
	position:absolute;
	right:var(--aox-20px); top: 50%;
	width:var(--aox-16px); height:var(--aox-16px);
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
.obj_list_participants_select .i_plus::before,
.obj_list_participants_select .i_plus::after{
	content:'';
	position:absolute;
	left:50%; top:50%;
	background:#000;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.obj_list_participants_select .i_plus::before{
	width:var(--aox-16px); height:var(--aox-3px);
	}
.obj_list_participants_select .i_plus::after{
	width:var(--aox-3px); height:var(--aox-16px);
	}
.obj_list_participants_select .bt_list_participants_select{
	position:relative;
	display:flex;
	align-items:center;
	width:100%;
	padding-left:var(--aox-20px);
	padding-right:var(--aox-60px);
	height:var(--aox-65px);
	font-size:var(--aox-fs-12px);
	text-transform:uppercase;
	font-weight:700;
	border:1px solid #000;
	}
.obj_list_participants_select .bt_list_participants_select:hover{
	background:#F2F2F2;
	}
.obj_list_participants_select .ctn_list_participants_select{
	display:flex;
	justify-content:center;
	align-items:center;
	position:fixed;
	left:0; top:0;
	z-index:9999;
	width:100%; height:100%;
	-webkit-animation-duration:.3s;
	        animation-duration:.3s;
	}
.obj_list_participants_select .wrapper_list_participants_select{
	position:relative;
	z-index:2;
	padding:var(--aox-60px) var(--aox-50px) var(--aox-60px) var(--aox-60px);
	width:var(--aox-500px);
	background:#fff;
	}
.obj_list_participants_select .wrapper_list_participants_select p{
	margin-bottom:var(--aox-20px);
	text-transform:uppercase;
	font-weight:700;
	text-align:center;
	}
.obj_list_participants_select .wrapper_list_participants_select ul{
	border-bottom:1px solid #E1E1E1;
	overflow-y: auto;
    max-height: 60vh;
    padding-right: var(--aox-10px);
	}
.obj_list_participants_select .wrapper_list_participants_select ul li{
	border-top:1px solid #E1E1E1;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button{
	position:relative;
	width:100%;
	padding:var(--aox-15px) var(--aox-105px) var(--aox-15px) var(--aox-7px);
	font-size:var(--aox-fs-14px);
	text-align:left;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:disabled{
	opacity:0.3;
	cursor:default;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:not(:disabled):hover{
	background:rgba(0,0,0,0.05);
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button .t_error{
	font-weight: 500;
	margin-bottom: 0;
	text-transform:unset;
	text-align:left;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button .t_add{
	display:flex;
	align-items:center;
	position:absolute;
	right:0; top:0;
	height:100%;
	padding:0 var(--aox-30px) 0 var(--aox-15px);
	font-size:var(--aox-fs-12px);
	text-transform:uppercase;
	font-weight:700;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:disabled.is_error{
	visibility:visible;
	opacity: 1;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:disabled.is_error .t_add{
	visibility:visible;
	opacity: 0.3;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button:disabled .t_add{
	visibility:hidden;
	}
.obj_list_participants_select .wrapper_list_participants_select ul li button .i_plus{
	right:var(--aox-7px);
	}
.obj_list_participants_select .ctn_list_participants_overlay{
	position:absolute;
	z-index:1;
	left:0; top:0;
	width:100%; height:100%;
	background:rgba(0,0,0,0.6);
	}
.obj_list_participants_select .bt_list_participants_close{
	position:absolute;
	z-index:3;
	right:0; top:0;
	width:var(--aox-60px); height:var(--aox-60px);
	background:#000;
	}
.obj_list_participants_select .bt_list_participants_close:hover{
	background:#777;
	}
.obj_list_participants_select .bt_list_participants_close::before,
.obj_list_participants_select .bt_list_participants_close::after{
	position:absolute;
	left:50%; top:50%;
	background-color:#fff;
	transition:all .3s ease;
	content:'';
	}
.obj_list_participants_select .bt_list_participants_close::before{
	width:2px; height:var(--aox-30px);
	-webkit-transform:translate(-50%,-50%) rotate(45deg);
	        transform:translate(-50%,-50%) rotate(45deg);
	}
.obj_list_participants_select .bt_list_participants_close::after{
	width:var(--aox-30px); height:2px;
	-webkit-transform:translate(-50%,-50%) rotate(45deg);
	        transform:translate(-50%,-50%) rotate(45deg);
	}


	



/***/

@media screen and (max-width:767px){

	.obj_list_participants_select .wrapper_list_participants_select{
		padding:var(--aox-60px) var(--aox-40px) var(--aox-60px) var(--aox-50px);
		}
	.obj_list_participants_select .wrapper_list_participants_select ul{
		padding-right: var(--aox-5px);
		}

	}
.obj_circle-progress-gains{
	position:relative;
	width:var(--aox-300px);
	height:var(--aox-150px);
	margin:0 auto;
	overflow:hidden;
	}
.obj_circle-progress-gains .CircularProgressbar{
	position:absolute;
	left:0; top:0;
	-webkit-transform:rotate(-90deg);
	        transform:rotate(-90deg);
	}
.obj_circle-progress-gains .CircularProgressbar .CircularProgressbar-trail{
	display:none;
	}
.obj_circle-progress-gains .CircularProgressbar .CircularProgressbar-path{
	transition:stroke-dashoffset 1s ease 0.5s;
	}
/* ======================================== */
/* .obj_formation
/* ======================================== */
.obj_formation{
    position: relative;
    transition: all 1s ease;
    margin-bottom: var(--aox-30px);
}
.obj_formation > .ctn_header-title{
    padding: var(--aox-12px) var(--aox-20px) var(--aox-8px) var(--aox-20px);
}
.obj_formation > .ctn_header-title span{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    color: #fff;
}

.obj_formation.var_lrp > .ctn_row{ border: 2px solid #009EE2; }
.obj_formation.var_cerave > .ctn_row{ border: 2px solid #2570B8; }
.obj_formation.var_vichy > .ctn_row{ border: 2px solid #C8102E; }
.obj_formation.var_skinc > .ctn_row{ border: 2px solid #000000; }
.obj_formation.var_derm-expertise > .ctn_row{ border: 2px solid #02ACC2; }
.obj_formation.var_comptoir-dermato > .ctn_row{ border: 2px solid #81C9D3; }

.obj_formation.var_lrp > .ctn_header-title{ background-color: #009EE2; }
.obj_formation.var_cerave > .ctn_header-title{ background-color: #2570B8; }
.obj_formation.var_vichy > .ctn_header-title{ background-color: #C8102E; }
.obj_formation.var_skinc > .ctn_header-title{ background-color: #000000; }
.obj_formation.var_derm-expertise > .ctn_header-title{ background-color: #02ACC2; }
.obj_formation.var_comptoir-dermato > .ctn_header-title{ background-color: #81C9D3; }

.obj_formation .ctn_text, 
.obj_formation > .ctn_row {
    display: flex;
    align-items: flex-start;
    }
.obj_formation > .ctn_row{
    position: relative;
    border: 1px solid rgba(112, 112, 112, 0.31);
    padding: var(--aox-10px) var(--aox-10px) 0 var(--aox-20px);
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    }
.obj_formation .ctn_text{
    width: var(--aox-380px);
    flex-direction: column;
    padding: var(--aox-10px) 0 0;
    min-height: var(--aox-300px);
    }
.obj_formation .ctn_text .obj_jours-restant {
    font-weight: 300;
    font-size: var(--aox-fs-30px);
    line-height: var(--aox-lh-120);
    position: relative;
    margin: 0 0 var(--aox-5px);
    }
.obj_formation .ctn_text .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    }
.obj_formation .ctn_text .list_infos{
    position: relative;
    margin: var(--aox-10px) 0 var(--aox-20px);
    }
.obj_formation .ctn_text .list_infos li{
    position: relative;
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-24px);
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: var(--aox-30px);
    }
.obj_formation .ctn_text .list_infos li:before {
    content: "";
    position: absolute;
    left: 0; top: 0;
    height: var(--aox-24px);
    width: var(--aox-24px);
    }
.obj_formation .ctn_text .list_infos li:first-child:before {
    background: url(/dist/formationcaf/static/media/i_calendar.42b64f26.svg) center center no-repeat;
    }
.obj_formation.webinaire .ctn_text .list_infos li:last-child:before {
    background: url(/dist/formationcaf/static/media/i_clock.863dc7d0.svg) center center no-repeat;
    }
.obj_formation .ctn_text .list_infos li:last-child:before {
    background: url(/dist/formationcaf/static/media/i_pin.4ea66f0d.svg) center center no-repeat;
    }
.obj_formation .ctn_text .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-14px);
    line-height: var(--aox-lh-100);
    margin-top: var(--aox-15px);
    }
.obj_formation .ctn_text .t_desc strong{
    font-weight: 700;
    font-size: var(--aox-fs-20px);
    line-height: var(--aox-lh-100);
    }

.obj_formation.is_registered .obj_btn_formation{
    border: 1px solid #000;
    background: transparent;
    color: #000;
    }
.obj_formation.is_registered .obj_btn_formation:hover{
    border: 1px solid #000;
    background: #000;
    color: #fff;
    }

.obj_formation.is_registered .ctn_text .t_statut{
    position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-24px);
    margin-top: var(--aox-20px);
    padding-left: var(--aox-30px);
    text-transform: uppercase;
    color: #8cc76f;
    }
.obj_formation.is_registered .ctn_text .t_statut:before {
    content: "";
    position: absolute;
    left: 0; top: 0;
    height: var(--aox-24px);
    width: var(--aox-24px);
    background: url(/dist/formationcaf/static/media/i_check_green.80b6d002.svg) center center / var(--aox-24px) auto no-repeat;
    }

.obj_formation.is_canceled .ctn_text .t_statut{ color: #FF4847; }
.obj_formation.is_canceled .ctn_text .t_statut:before {
    background: url(/dist/formationcaf/static/media/i_check_red.715c18a5.svg) center center / var(--aox-24px) auto no-repeat;
    }

.obj_formation.is_finished .ctn_text .t_statut{ color: #D1D1D0; }
.obj_formation.is_finished .ctn_text .t_statut:before {
    background: url(/dist/formationcaf/static/media/i_check_grey.82583bd9.svg) center center / var(--aox-24px) auto no-repeat;
    }
    
.obj_formation .ctn_image{
    width: var(--aox-170px);
    }
.obj_formation .ctn_image .ctn_visuel{
    width: var(--aox-170px);
    height: var(--aox-170px);
    overflow: hidden;
    }
.obj_formation .ctn_image .ctn_visuel img{
    width: 100%;
    }
.obj_formation .ctn_image .ctn_logo img{
    width: 100%;
    }


/***/

.obj_formation.is_empty{
    position: relative;
    }
.obj_formation.is_empty .ctn_text{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    padding: var(--aox-40px);
    z-index: 2;
    justify-content: center;
    }
.obj_formation.is_empty .t_title{
    font-weight: 400;
    font-size: var(--aox-fs-38px);
    line-height: var(--aox-lh-120);
    color: white;
    text-transform: none;
    margin: 0;
    }
.obj_formation.is_empty .ctn_background{
    display: block;
    height: var(--aox-550px);
    width: var(--aox-620px);
    }
.obj_formation.is_empty .ctn_background img{
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    }

/***/

@media screen and (max-width:767px){

    .obj_formation .ctn_row {
        padding: var(--aox-5px);
        }

    .obj_formation .ctn_row .ctn_text{
        width: calc(100% - var(--aox-120px));
        }
    .obj_formation .ctn_row .ctn_text > .obj_temps_restant,
    .obj_formation .ctn_row .ctn_text > .t_title,
    .obj_formation .ctn_row .ctn_text > .t_date,
    .obj_formation .ctn_row .ctn_text > .t_desc,
    .obj_formation .ctn_row .ctn_text > .obj_btn_formation {
        width: 100%;
        }
    .obj_formation .ctn_row .ctn_text {
        padding: var(--aox-15px) 0 var(--aox-15px) var(--aox-15px);
        }   
    .obj_formation .ctn_row .ctn_text > .obj_btn_formation {
        width: auto;
        padding: var(--aox-20px);
        }
    .obj_formation .ctn_image {
        width: var(--aox-110px);
        }
    .obj_formation .ctn_image .ctn_visuel{
        width: var(--aox-110px);
        height: var(--aox-110px);
        }

    .obj_formation .ctn_text .t_title{
        font-size: var(--aox-fs-17px);
        line-height: var(--aox-lh-100);
        }


    .obj_formation.is_empty .ctn_background {
        display: block;
        width: var(--aox-380px);
        height: var(--aox-340px);
        }   
    .obj_formation.is_empty .t_title{
        font-size: var(--aox-fs-20px);
        line-height: var(--aox-lh-120);
        }
    


}
/* ======================================== */
/* .obj_formation_detail
/* ======================================== */
.obj_formation_detail{
    /* position: relative; */
    transition: all 1s ease;
    width: var(--aox-col-9);
    }

.obj_formation_detail.var_lrp .ctn_text .t_tag{ background-color: #009EE2; color: #fff; }
.obj_formation_detail.var_cerave .ctn_text .t_tag{ background-color: #2570B8; color: #fff; }
.obj_formation_detail.var_vichy .ctn_text .t_tag{ background-color: #C8102E; color: #fff; }
.obj_formation_detail.var_skinc .ctn_text .t_tag{ background-color: #000000; color: #fff; }


.obj_formation_detail > .ctn_row{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    border: unset;
    padding: unset;
    }
        
.obj_formation_detail .ctn_image{
    width: var(--aox-col-3);
    }
.obj_formation_detail .ctn_image .ctn_visuel{
    width: var(--aox-col-3);
    height: var(--aox-290px);
    overflow: hidden;
    }
.obj_formation_detail .ctn_image .ctn_visuel img{
    width: 100%;
    }
.obj_formation_detail .ctn_image .ctn_logo img{
    width:100%;
    }

.obj_formation_detail .ctn_text{
    display: flex;
    width: var(--aox-col-6);
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    }

.obj_formation_detail .obj_jours-restant {
    font-weight: 300;
    font-size: var(--aox-fs-30px);
    line-height: var(--aox-lh-120);
    position: relative;
    margin: var(--aox-10px) 0 0;
    }

.obj_formation_detail .ctn_text .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-100);
    margin-bottom: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .t_tag{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-90);
    text-transform: uppercase;
    padding: var(--aox-10px) var(--aox-10px) var(--aox-9px) var(--aox-10px);
    }
.obj_formation_detail .ctn_text .t_date{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_formation_detail .ctn_text .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-140);
    margin-bottom: var(--aox-30px);
    }

/***/

.obj_formation_detail .ctn_text .list_infos{
    position: relative;
    margin: var(--aox-10px) 0 var(--aox-20px);
    }
.obj_formation_detail .ctn_text .list_infos li{
    position: relative;
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-24px);
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: var(--aox-30px);
    }
.obj_formation_detail .ctn_text .list_infos li:before {
    content: "";
    position: absolute;
    left: 0; top: 0;
    height: var(--aox-24px);
    width: var(--aox-24px);
    }
.obj_formation_detail .ctn_text .list_infos li:first-child:before {
    background: url(/dist/formationcaf/static/media/i_calendar.42b64f26.svg) center center no-repeat;
    }
.obj_formation_detail.webinaire .ctn_text .list_infos li:last-child:before {
    background: url(/dist/formationcaf/static/media/i_clock.863dc7d0.svg) center center no-repeat;
    }
.obj_formation_detail .ctn_text .list_infos li:last-child:before {
    background: url(/dist/formationcaf/static/media/i_pin.4ea66f0d.svg) center center no-repeat;
    }


/***/
.obj_formation_detail .ctn_text .obj_formation_detail_form{
    width: var(--aox-col-4);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .t_label{
    display: block;
    font-weight: 400;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    margin-bottom: var(--aox-10px);
    text-transform: uppercase;
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select{
    margin-bottom: var(--aox-30px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select .react-select__placeholder,
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select .react-select__value-container{
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-160);
    color: #000;
    text-transform: uppercase;
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select .react-select__value-container > .react-select__single-value{
    color: #000;
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .obj_form .ctn_select .error .react-select__placeholder {
    color: #ff4847;
    }

.obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_phone{
	margin-bottom: var(--aox-30px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_phone .t_desc{
	margin-bottom: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_phone .ctn_row{
	flex-wrap: nowrap;
	align-items: center;
	grid-gap: var(--aox-20px);
	gap: var(--aox-20px);
	margin-bottom: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_phone .ctn_row > div{
	margin: 0;
    }

.obj_formation_detail .ctn_text .obj_formation_detail_readonly{
	margin-bottom: var(--aox-30px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_readonly .t_desc{
	margin-bottom: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .obj_formation_detail_readonly .obj_form{
	width: var(--aox-250px);
    }

/***/

.obj_formation_detail.is_registered .ctn_text .list_infos li{
    font-weight: 700;
    font-size: var(--aox-fs-18px);
    line-height: var(--aox-32px);
    padding-left: var(--aox-35px);
    }
.obj_formation_detail.is_registered .ctn_text .list_infos li:before {
    height: var(--aox-32px);
    width: var(--aox-32px);
    }
.obj_formation_detail.is_registered .ctn_text .list_infos li:first-child:before {
    background: url(/dist/formationcaf/static/media/i_calendar.42b64f26.svg) center center / var(--aox-32px) auto no-repeat;
    }
.obj_formation_detail.is_registered.webinaire .ctn_text .list_infos li:last-child:before {
    background: url(/dist/formationcaf/static/media/i_clock.863dc7d0.svg) center center / var(--aox-32px) auto no-repeat;
    }
.obj_formation_detail.is_registered .ctn_text .list_infos li:last-child:before {
    background: url(/dist/formationcaf/static/media/i_pin.4ea66f0d.svg) center center / var(--aox-32px) auto no-repeat;
    }

.obj_formation_detail.is_registered .ctn_text .t_inscription {
    position: relative;
    font-weight: 700;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-24px);
    margin-bottom: var(--aox-20px);
    padding-left: var(--aox-30px);
    text-transform: uppercase;
    color: #8CC76F;
    }
.obj_formation_detail.is_registered .ctn_text .t_inscription:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: var(--aox-24px);
    width: var(--aox-24px);
    background: url(/dist/formationcaf/static/media/i_check_green.80b6d002.svg) center center / var(--aox-24px) auto no-repeat;
    }

.obj_formation_detail .ctn_text .ctn_cta{
    display: flex;
    flex-direction: row;
    align-items: center;
    }
.obj_formation_detail .ctn_text .ctn_cta .obj_cta{
    margin: 0;
    }
.obj_formation_detail .ctn_text .ctn_cta .obj_btn + .obj_btn{
    margin-left: var(--aox-10px);
    }
.obj_formation_detail .ctn_text .ctn_visuel_map{
    position: relative;
    margin-top: var(--aox-40px);
    }


/***/

@media screen and (max-width:767px){

    .obj_formation_detail {
        width: 100%;
        }
    
    .obj_formation_detail .ctn_image {
        width: var(--aox-col-2);
        position: absolute;
        right: 0; top: var(--aox-20px);
        }
    .obj_formation_detail .ctn_text {
        margin-top: var(--aox-10px);
        }
    .obj_formation_detail .ctn_text .list_infos{
        margin: var(--aox-10px) 0 var(--aox-10px);
        }
    .obj_formation_detail.is_registered .ctn_text .list_infos{
        margin: var(--aox-5px) 0 var(--aox-15px);
        }
    .obj_formation_detail.is_registered .ctn_text .list_infos li {
        font-size: var(--aox-fs-16px);
        }

    .obj_formation_detail .ctn_text .obj_formation_detail_form {
        width: 100%;
        }
    .obj_formation_detail .ctn_text .obj_formation_detail_form .ctn_native-select{
        margin-bottom: var(--aox-30px);
        }
        
    .obj_formation_detail .ctn_text .ctn_cta {
        flex-direction: column;
        align-items: flex-start;
        }
    .obj_formation_detail .ctn_text .ctn_cta .obj_btn + .obj_btn {
        margin-left: 0;
        margin-top: var(--aox-10px);
        }
    .obj_formation_detail .ctn_text .ctn_visuel_map img{
        width: 100%;
        height: auto;
        }
    .obj_formation_detail .ctn_text .ctn_visuel_map {
        margin-top: var(--aox-30px);
        }

    .obj_formation_detail .ctn_image .ctn_visuel {
        width: var(--aox-col-2);
        height: var(--aox-col-2);
        }
        

}
/* ======================================== */
/* .obj_entry_hero_home{
/* ======================================== */

.obj_entry_hero_home{
	position: relative;
	padding: var(--aox-10px) var(--aox-90px) var(--aox-50px);
	background-color: #fff;
	margin-top: var(--aox-10px);
	min-height: var(--aox-240px);
	-webkit-animation:effectHeroEntry 1s ease 2000ms forwards;
	        animation:effectHeroEntry 1s ease 2000ms forwards;
	opacity: 0;
	}
.obj_entry_hero_home .ctn_entry_hero_home{
	position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry{
	display: flex;
    position: relative;
    width: var(--aox-320px);
    text-align: center;
    flex-direction: column;
    align-items: center;
	opacity: 0;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry:first-child{
	-webkit-animation:effectHeroEntry .8s ease 2150ms forwards;
	        animation:effectHeroEntry .8s ease 2150ms forwards;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry:nth-child(2){
	-webkit-animation:effectHeroEntry .8s ease 2250ms forwards;
	        animation:effectHeroEntry .8s ease 2250ms forwards;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry:last-child{
	-webkit-animation:effectHeroEntry .8s ease 2350ms forwards;
	        animation:effectHeroEntry .8s ease 2350ms forwards;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry a{
	display: flex;
	position: relative;
	width: var(--aox-320px);
	text-align: center;
	flex-direction: column;
	align-items: center;
	}

.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_picto{
	position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: var(--aox-64px);
    width: var(--aox-64px);
    margin: var(--aox-40px) var(--aox-10px) var(--aox-10px);
	transition: all .3s ease;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_picto span{
	position: absolute;
    display: flex;
    right: calc(-1 * var(--aox-5px));
    top: calc(-1 * var(--aox-7px));
    color: white;
    font-size: var(--aox-fs-12px);
    font-weight: 700;
    background-color: #00ACC2;
    height: var(--aox-24px);
    width: var(--aox-24px);
    border-radius: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_picto img{
	-webkit-filter: drop-shadow(0 13px 21px rgba(10, 118, 132, .8));
	        filter: drop-shadow(0 13px 21px rgba(10, 118, 132, .8));
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry a:hover .obj_entry_picto{
	-webkit-transform: translateY(var(--aox-5px));
	        transform: translateY(var(--aox-5px));
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry a:hover .obj_entry_picto img{
	-webkit-filter: drop-shadow(0 0px 7px rgba(10, 118, 132, .3));
	        filter: drop-shadow(0 0px 7px rgba(10, 118, 132, .3));
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .t_title{
	font-size: var(--aox-fs-20px);
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	margin:var(--aox-10px);	
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info{
	position: relative;
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info li{
	position: relative;
	padding: var(--aox-3px) var(--aox-16px) var(--aox-5px);
	border-radius: var(--aox-16px);
	background-color: rgba(0, 172, 194, .2);
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info li.is_empty{
	background-color: rgba(242, 242, 242, 1);
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info li span{
	display: block;
	font-size: var(--aox-fs-13px);
	font-weight: 500;
	color: rgba(0, 0, 0, .5);
	}
.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info li + li{
	margin-top:var(--aox-2px);	
	}


@media screen and (max-width:767px){

	.obj_entry_hero_home{
		padding: var(--aox-10px) var(--aox-20px) var(--aox-40px) var(--aox-20px);
		left: calc(-1 * var(--aox-external-gutter));
		width: 100vw;
		margin-top: var(--aox-20px);
		min-height: unset;
		}
	.obj_entry_hero_home .ctn_entry_hero_home {
		justify-content: space-between;
		}
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry,
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry a {
		width: var(--aox-120px);
		}
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .t_title {
		font-size: var(--aox-fs-10px);
		margin: 0;
		}
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_list_info {
		display: none;
		}
	.obj_entry_hero_home .ctn_entry_hero_home .obj_entry .obj_entry_picto {
		height: var(--aox-56px);
		width: var(--aox-56px);
		margin: var(--aox-20px) 0 var(--aox-15px);
		}


	}
/* ======================================== */
/* .obj_brand_hero_home{
/* ======================================== */

.obj_brand_hero_home{
	position: relative;
	width: var(--aox-480px);
	}
.obj_brand_hero_home .ctn_brand_hero_home{
	position: relative;
	}
.obj_brand_hero_home .ctn_brand_hero_home ul{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	}
.obj_brand_hero_home .obj_item_brand_hero{
	position: relative;
	width: calc(var(--aox-230px) + var(--aox-9px));
	height: var(--aox-140px);
	overflow: hidden;
	margin-bottom: var(--aox-2px);
	display: block;
    background-color: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    opacity: 0;
	}
	
.obj_brand_hero_home .obj_item_brand_other_hero{
	width: var(--aox-480px);
    height: var(--aox-80px);
	transition: all .3s ease;
	opacity: 0;
	display: block;
    background-color: rgba(255, 255, 255, .8);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
	}
.obj_brand_hero_home .obj_item_brand_other_hero:hover{
	background-color: rgba(255, 255, 255, 1);
	}
.obj_brand_hero_home .obj_item_brand_hero.var_lrp{ -webkit-animation:effectHeroCaseBrands 1s ease 700ms forwards; animation:effectHeroCaseBrands 1s ease 700ms forwards; }
.obj_brand_hero_home .obj_item_brand_hero.var_vichy{ -webkit-animation:effectHeroCaseBrands 1s ease 800ms forwards; animation:effectHeroCaseBrands 1s ease 800ms forwards; }
.obj_brand_hero_home .obj_item_brand_hero.var_cerave{ -webkit-animation:effectHeroCaseBrands 1s ease 900ms forwards; animation:effectHeroCaseBrands 1s ease 900ms forwards; }
.obj_brand_hero_home .obj_item_brand_hero.var_skinc{ -webkit-animation:effectHeroCaseBrands 1s ease 1000ms forwards; animation:effectHeroCaseBrands 1s ease 1000ms forwards; }
.obj_brand_hero_home .obj_item_brand_other_hero{ -webkit-animation:effectHeroCaseBrands 1s ease 1100ms forwards; animation:effectHeroCaseBrands 1s ease 1100ms forwards; }
	
.obj_brand_hero_home .obj_item_brand_hero:nth-child(even){
	margin-left: var(--aox-2px);
	}
.obj_brand_hero_home .obj_item_brand_hero a{
	display: block;
	}
.obj_brand_hero_home .obj_item_brand_hero .ctn_text{
	position: relative;
    width: calc(var(--aox-230px) + var(--aox-9px));
    height: var(--aox-140px);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
	}

/* .obj_brand_hero_home .obj_item_brand_hero.var_lrp .ctn_logo{ padding-top: var(--aox-30px); }	 */
.obj_brand_hero_home .obj_item_brand_hero.var_lrp .ctn_logo img{ height: var(--aox-40px); }

/* .obj_brand_hero_home .obj_item_brand_hero.var_vichy .ctn_logo{ padding-top: var(--aox-35px); } */
.obj_brand_hero_home .obj_item_brand_hero.var_vichy .ctn_logo img{ height: var(--aox-32px); }

/* .obj_brand_hero_home .obj_item_brand_hero.var_cerave .ctn_logo{ padding-top: var(--aox-25px); } */
.obj_brand_hero_home .obj_item_brand_hero.var_cerave .ctn_logo img{ height: var(--aox-43px); }

/* .obj_brand_hero_home .obj_item_brand_hero.var_skinc .ctn_logo{ padding-top: var(--aox-36px); } */
.obj_brand_hero_home .obj_item_brand_hero.var_skinc .ctn_logo img{ height: var(--aox-25px); }

.obj_brand_hero_home .obj_item_brand_hero .ctn_logo{
	position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition-delay: .05s;
    transition-duration: .2s;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform;
    transition-timing-function: cubic-bezier(.645,.045,.355,1);
    z-index: 2;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
	}


.obj_brand_hero_home .obj_item_brand_hero .t_title {
	position: absolute;
	bottom: 35%;
	font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-100);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
	opacity: 0;
	-webkit-transform: translateY(var(--aox-25px));
	        transform: translateY(var(--aox-25px));
	transition-duration: .25s;
	transition-property: opacity,-webkit-transform;
	transition-property: transform,opacity;
	transition-property: transform,opacity,-webkit-transform;
	transition-timing-function: cubic-bezier(.645,.045,.355,1);
	z-index: 2;
	}


/***/
.obj_brand_hero_home .obj_item_brand_hero a:hover {
	background-color: rgba(255, 255, 255, 1);
	}
.obj_brand_hero_home .obj_item_brand_hero a:hover .ctn_logo {
	-webkit-transform: translateY(-10px);
	        transform: translateY(-10px);
	transition-delay: 0s;
	transition-duration: .25s;
	opacity: 0;
	}
.obj_brand_hero_home .obj_item_brand_hero a:hover .t_title {
	opacity: 1;
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
	transition-delay: .05s;
	transition-duration: .2s;
	}



@media screen and (max-width:767px){

	.obj_brand_hero_home {
		width: 100%;
		}
	.obj_brand_hero_home .obj_item_brand_hero {
		width: calc((50%) - 1px);
		height: var(--aox-90px);
		}
	.obj_brand_hero_home .obj_item_brand_hero:nth-child(even){
		margin-left: 2px;
		}
	.obj_brand_hero_home .obj_item_brand_hero .ctn_text{
		width: 100%;
		height: var(--aox-90px);
		justify-content: center;
		}
	/* .obj_brand_hero_home .obj_item_brand_hero .ctn_logo{
		padding-top: 0;
		width:auto;
		height: auto;
		top: unset;
		} */
	.obj_brand_hero_home .obj_item_brand_hero.var_lrp .ctn_logo,
	.obj_brand_hero_home .obj_item_brand_hero.var_skinc .ctn_logo,
	.obj_brand_hero_home .obj_item_brand_hero.var_vichy .ctn_logo,
	.obj_brand_hero_home .obj_item_brand_hero.var_cerave .ctn_logo{ 
		padding-top: 0;
	}	

	.obj_brand_hero_home .obj_item_brand_hero.var_lrp .ctn_logo img{ height: var(--aox-30px); }
	.obj_brand_hero_home .obj_item_brand_hero.var_vichy .ctn_logo img{ height: var(--aox-24px); }
	.obj_brand_hero_home .obj_item_brand_hero.var_cerave .ctn_logo img{ height: var(--aox-32px); }
	.obj_brand_hero_home .obj_item_brand_hero.var_skinc .ctn_logo img{ height: var(--aox-20px); }


	.obj_brand_hero_home .obj_item_brand_hero .t_title {
		bottom: 25%;
    	font-size: var(--aox-fs-13px);
		}

	.obj_brand_hero_home .obj_item_brand_other_hero {
		width: 100%;
		height: var(--aox-55px);
		background-color: transparent;
		}

}
/* ======================================== */
/* .obj_brands_carousel_hero
/* ======================================== */
.obj_brands_carousel_hero{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
	}
.obj_brands_carousel_hero > *{
	width: 50%;
	}
.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero{
	height: var(--aox-80px);
	}
.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero a{
	display: flex;
    height: var(--aox-80px);
    align-items: center;
    padding: 0 0 0 var(--aox-40px);
	}
.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero .t_title {
	font-size: var(--aox-fs-12px);
	}
.obj_brands_carousel_hero .carousel_other_hero .slick-arrow{
	display: none !important;
	}

.obj_brands_carousel_hero .carousel_other_hero{
	width: var(--aox-230px);
	height: var(--aox-80px);
	}
.obj_brands_carousel_hero .carousel_other_hero .ctn_logo{
	display: flex;
    width: var(--aox-230px);
    height: var(--aox-80px);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
	}
.obj_brands_carousel_hero .carousel_other_hero .ctn_logo img{
	max-width: var(--aox-230px);
	}


@media screen and (max-width: 767px){

	.obj_brands_carousel_hero { background-color: rgba(255, 255, 255, .7); }

	.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero {
		height: var(--aox-55px);
		}
	.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero a {
		height: var(--aox-55px);
		padding: 0 0 0 var(--aox-22px);
		}
	.obj_brands_carousel_hero .ctn_cta_brands_carousel_hero .t_title {
		font-size: var(--aox-fs-12px);
		line-height: var(--aox-lh-90);
		}
	.obj_brands_carousel_hero .carousel_other_hero {
		width: 50%;
		height: var(--aox-55px);
		}
	.obj_brands_carousel_hero .carousel_other_hero .ctn_logo {
		width: 100%;
		height: var(--aox-55px);
		}
	.obj_brands_carousel_hero .carousel_other_hero .ctn_logo img{
		max-width: var(--aox-115px);
		}
		

	}
/* ======================================== */
/* .obj_links_hero_home
/* ======================================== */
.obj_links_hero_home{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
	}

.obj_links_hero_home .ctn_left_hero_home{
	width: var(--aox-650px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
	}	
.obj_links_hero_home .ctn_left_hero_home .ctn_title{
	margin: 0;
    padding: 0 var(--aox-30px) var(--aox-30px) var(--aox-290px);
	}	
.obj_links_hero_home .ctn_left_hero_home .ctn_title .t_title{
	font-size: var(--aox-fs-40px);
	line-height: var(--aox-lh-100);
    font-weight: 300;
    text-transform: none;
	text-align: right;
	width: 100%;
	margin-bottom: 0;
	color: white;
	opacity:0;
	-webkit-animation: 0.5s forwards effectHeroTitle 0s;
	        animation: 0.5s forwards effectHeroTitle 0s;
    transition: opacity 0.5s,-webkit-transform 0.5s;
    transition: opacity 0.5s,transform 0.5s;
    transition: opacity 0.5s,transform 0.5s,-webkit-transform 0.5s;
	}

.obj_links_hero_home .ctn_right_hero_home{
	width: var(--aox-480px);
	padding-top: var(--aox-85px);
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
	}	


	
@media screen and (max-width: 767px){

	.obj_links_hero_home {
		align-items: stretch;
		justify-content: flex-start;
		}
	.obj_links_hero_home .ctn_left_hero_home {
		width: 100%;
		}
	.obj_links_hero_home .ctn_right_hero_home {
		width: 100%;
		padding-top: var(--aox-2px);
		}
	.obj_links_hero_home .ctn_left_hero_home .ctn_title {
		margin: 0 0 var(--aox-10px) 0;
		padding: 0 var(--aox-60px);
		height: auto;
		}
	.obj_links_hero_home .ctn_left_hero_home .ctn_title .t_title {
		font-size: var(--aox-fs-20px);
		line-height: var(--aox-lh-120);
		font-weight: 400;
		text-align: center;
		}


	}
/* ======================================== */
/* .obj_brand_highlighted_hero
/* ======================================== */
.obj_brand_highlighted_hero{
	width: var(--aox-650px);
    height: var(--aox-160px);
    display: block;
    background-color: rgba(255, 255, 255, .8);
	-webkit-backdrop-filter: blur(10px);
	        backdrop-filter: blur(10px);
	-webkit-animation:effectHeroHighlightedBrands 1s ease 1600ms forwards;
	        animation:effectHeroHighlightedBrands 1s ease 1600ms forwards;
	opacity: 0;
	}
.obj_brand_highlighted_hero a{
	display: block;
	width: var(--aox-650px);
    height: var(--aox-160px);
	}
.obj_brand_highlighted_hero .ctn_row{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	width: var(--aox-650px);
	height: var(--aox-160px);
	}
.obj_brand_highlighted_hero .ctn_logo{
	position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: var(--aox-240px);
    height: 100%;
    transition-delay: .05s;
    transition-duration: .2s;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform;
    transition-timing-function: cubic-bezier(.645,.045,.355,1);
    z-index: 2;
	}
.obj_brand_highlighted_hero .ctn_text{
	width: var(--aox-190px);
	}

.obj_brand_highlighted_hero .ctn_logo .t_title {
	position: absolute;
    bottom: 35%;
    width: 100%;
    text-align: center;
    font-size: var(--aox-fs-16px);
	line-height: var(--aox-lh-100);
    font-weight: 700;
	letter-spacing: 1.5px;
    text-transform: uppercase;
    opacity: 0;
    -webkit-transform: translateY(var(--aox-25px));
            transform: translateY(var(--aox-25px));
    transition-duration: .25s;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform;
    transition-timing-function: cubic-bezier(.645,.045,.355,1);
    z-index: 2;
	}
.obj_brand_highlighted_hero .ctn_logo img{
	transition: all .3s ease;
	width: 100%;
	}

/***/
.obj_brand_highlighted_hero a:hover {
	background-color: rgba(255, 255, 255, 1);
	}
.obj_brand_highlighted_hero a:hover .ctn_logo img{
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
	transition-delay: 0s;
	transition-duration: .25s;
	opacity: 0;
	}
.obj_brand_highlighted_hero a:hover .t_title {
	opacity: 1;
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
	transition-delay: .05s;
	transition-duration: .2s;
	}



@media screen and (max-width: 767px){

	.obj_brand_highlighted_hero {
		width: 100%;
		height: var(--aox-90px);
		-webkit-animation: effectHeroHighlightedBrands 1s ease 500ms forwards;
		        animation: effectHeroHighlightedBrands 1s ease 500ms forwards;
		}
	.obj_brand_highlighted_hero a {
		width: 100%;
		height: var(--aox-90px);
		}
	.obj_brand_highlighted_hero .ctn_row{
		width: 100%;
		height: var(--aox-90px);
		}
	.obj_brand_highlighted_hero .ctn_logo,
	.obj_brand_highlighted_hero .ctn_logo img {
		width: var(--aox-200px);
		}
	.obj_brand_highlighted_hero .ctn_text{
		display: none;
		}
	.obj_brand_highlighted_hero .ctn_logo .t_title{
		bottom: 25%;
    	font-size: var(--aox-fs-13px);
		}

	}
/* ======================================== */
/* .obj_overlay_tuto_home
/* ======================================== */

.overlay_tuto_is-opened{
    overflow: hidden;
    }

    
    
    
.obj_overlay_tuto_home{
    position: absolute;
    top: 0; left: 0;
    width: 100vw; 
    height: 0;
    z-index: 10000; 
    opacity: 0;
    /* transition: all .8s ease-in-out; */
    }
.overlay_tuto_is-opened .obj_overlay_tuto_home{
    -webkit-animation: FadeIn 1s both;
            animation: FadeIn 1s both;
    height: 100%;
    }
@-webkit-keyframes FadeIn{
    0% { opacity: 0; }
    100% { opacity: 1; }
    }
@keyframes FadeIn{
    0% { opacity: 0; }
    100% { opacity: 1; }
    }
.obj_overlay_tuto_home .ctn_overlay_rect{
    position: relative;
    width: 100vw; height: 100%;
    transition: all .75s ease-in-out;
    }
.obj_overlay_tuto_home .ctn_overlay_rect > *{
    position: absolute;
    background-color: rgba(0,0,0,0.7);
    transition: all .8s ease-in-out;
    }


/* step_0 */
.overlay_tuto_is-opened .obj_overlay_tuto_home.step_0 .ctn_overlay_rect{
    position: absolute;
    background-color: rgba(0,0,0,0.7);
    }


/* step_1 */

.obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0; 
    left: 0;
    width: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) +
        var(--aox-650px) +
        var(--aox-10px) -
        var(--aox-10px)
    ); 
    height: 100%;
    }
.obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_2{
    top: calc(var(--aox-105px)); 
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) +
        var(--aox-650px) +
        var(--aox-10px) -
        var(--aox-10px)
    );
    width: calc(
        var(--aox-480px) +
        var(--aox-20px)
    ); 
    height: calc(
        var(--aox-120px) -
        var(--aox-10px)
    );
    }
.obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc( 
        var(--aox-external-gutter) + 
        var(--aox-1140px) +        
        var(--aox-20px) +
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    width: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    height: 100%;
    }
.obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc(
        var(--aox-150px) +
        var(--aox-420px) +
        var(--aox-4px) +
        var(--aox-10px) +
        var(--aox-15px)
    );
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) +
        var(--aox-650px) +
        var(--aox-10px) -
        var(--aox-10px)
    );
    width: calc(
        var(--aox-480px) +
        var(--aox-20px)
    ); 
    height: calc(
        100% -
        (
            var(--aox-420px) +
            var(--aox-4px) +
            var(--aox-160px)
        )
    );
    }


/* step_2 */

.obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0; 
    left: 0;
    width: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) -
        var(--aox-10px)
    ); 
    height: 100%;
    }
.obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_2{
    top: var(--aox-40px); 
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) -
        var(--aox-10px)
    );
    width: calc(
        var(--aox-650px) +
        var(--aox-20px)
    ); 
    height: calc(
        var(--aox-240px) +
        var(--aox-4px) +
        var(--aox-150px) -
        var(--aox-10px)
    );
    }
.obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) +
        var(--aox-650px) +
        var(--aox-20px) -
        var(--aox-10px)
    );
    width: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) +
        var(--aox-480px) + 
        var(--aox-10px) 
    );
    height: 100%;
    }
.obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc( 
        var(--aox-150px) + 
        var(--aox-420px) + 
        var(--aox-4px) + 
        var(--aox-10px) + var(--aox-10px)
    );
    left: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    width: calc( 
        var(--aox-650px) + 
        var(--aox-20px) 
    );
    height: calc( 
        100% - 
        (
            var(--aox-420px) + 
            var(--aox-4px) + 
            var(--aox-160px) 
        )
    );
    }


/* step_3 */

.obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0; 
    left: 0;
    width: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) -
        var(--aox-10px)
    ); 
    height: 100%;
    }
.obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_2{
    top: var(--aox-20px); 
    left: calc(
        var(--aox-external-gutter) +
        var(--aox-col-1) -
        var(--aox-10px)
    );
    width: calc(        
        var(--aox-1140px) +        
        var(--aox-20px)    
    ); 
    height: calc(         
        var(--aox-150px) +         
        var(--aox-420px) +         
        var(--aox-4px)     
    );
    }
.obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc( 
        var(--aox-external-gutter) + 
        var(--aox-1140px) +        
        var(--aox-20px) +
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    width: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    height: 100%;
    }
.obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc(
        var(--aox-150px) +
        var(--aox-280px) +
        var(--aox-420px) +
        var(--aox-4px) +
        var(--aox-10px)
    );
    left: calc( 
        var(--aox-external-gutter) + 
        var(--aox-col-1) - 
        var(--aox-10px) 
    );
    width: calc(        
        var(--aox-1140px) +        
        var(--aox-20px)    
    ); 
    height: calc( 
        100% - 
        (
            var(--aox-420px) + 
            var(--aox-280px) +
            var(--aox-4px) + 
            var(--aox-170px)
        ) 
    );
    }


/* step_4 */

.obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0; 
    left: 0;
    width: calc(
        var(--aox-external-gutter) +
        var(--aox-col-9) -
        var(--aox-10px)
    ); 
    height: 100%;
    }
.obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_2{
    top: 0;
    height: 0;
    }
.obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc( var(--aox-external-gutter) + var(--aox-1140px) + var(--aox-col-1) + var(--aox-col-1) + var(--aox-10px) );
    width: calc( 
        var(--aox-external-gutter) - 
        var(--aox-10px) 
    );
    height: 100%;
    }
.obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc( var(--aox-50px) + var(--aox-10px) );
    left: calc( var(--aox-external-gutter) + var(--aox-col-9) - var(--aox-10px) );
    width: calc( var(--aox-330px) + var(--aox-20px) );
    height: calc( 100% - ( var(--aox-50px) + var(--aox-10px) ) );
    }


/* step_5 */

.obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_1{
    top: 0;
    left: 0;
    width: calc( var(--aox-external-gutter) + var(--aox-col-6) - var(--aox-5px) );
    height: 100%;
    }
.obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_2{
    top: var(--aox-15px);
    left: calc( var(--aox-external-gutter) + var(--aox-col-6) - var(--aox-5px) );
    width: calc( var(--aox-105px) );
    height: calc( var(--aox-1350px) + var(--aox-5px) );
    }
.obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_3{
    top: 0;
    left: calc( var(--aox-external-gutter) + var(--aox-col-6) + var(--aox-95px) + var(--aox-5px) );
    width: calc( var(--aox-external-gutter) + var(--aox-col-6) - var(--aox-60px) );
    height: 100%;
    }
.obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_4{
    top: calc( var(--aox-1000px) + var(--aox-310px) + var(--aox-105px));
    left: calc( var(--aox-external-gutter) + var(--aox-col-6) - var(--aox-5px) );
    width: calc( var(--aox-105px) );
    height: calc( 100% - ( var(--aox-1500px) ) );
    }


/***/

.obj_overlay_tuto_home .ctn_popin_tuto_home {
    position: fixed;
    z-index: 9999;
    width: auto; height: 0;
    top: calc(100vh - var(--aox-250px)); left: 50%;
    /* transform: translateX(-50%); */
    /* transform: translate(-50%, 50px); */
    opacity: 0;
    /* transition: all .3s ease-in-out; */
}
.overlay_tuto_is-opened .obj_overlay_tuto_home .ctn_popin_tuto_home{
    -webkit-animation: didac_popin_effect 1.5s both;
            animation: didac_popin_effect 1.5s both;
    height: auto;
    }
@-webkit-keyframes didac_popin_effect{
    0% { opacity:0; -webkit-transform: translate(-50%, 50px); transform: translate(-50%, 50px); }
    100% { opacity:1; -webkit-transform: translate(-50%, 0px); transform: translate(-50%, 0px); }
    }
@keyframes didac_popin_effect{
    0% { opacity:0; -webkit-transform: translate(-50%, 50px); transform: translate(-50%, 50px); }
    100% { opacity:1; -webkit-transform: translate(-50%, 0px); transform: translate(-50%, 0px); }
    }

/* .obj_overlay_tuto_home.step_1 .ctn_popin_tuto_home,
.obj_overlay_tuto_home.step_2 .ctn_popin_tuto_home,
.obj_overlay_tuto_home.step_3 .ctn_popin_tuto_home,
.obj_overlay_tuto_home.step_4 .ctn_popin_tuto_home {
    top: 0%;
    } */
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home{
    position: relative;
    z-index: 2;
    padding: var(--aox-40px) var(--aox-35px) var(--aox-50px);
    width: var(--aox-920px); height: var(--aox-250px);
    border-radius: var(--aox-20px) var(--aox-20px) 0 0;
    background: #fff;
    -webkit-filter: drop-shadow(0 30px 60px rgba(0, 0, 0, .16));
            filter: drop-shadow(0 30px 60px rgba(0, 0, 0, .16));
    transition: all .3s ease-in-out;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family:'Montserrat', sans-serif;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home > .ctn_text{
    display: none;
    transition:all .6s ease-in-out;
    -webkit-animation: textEffectSmooth 2s cubic-bezier(0.33,1,0.68,1) 0s both;
            animation: textEffectSmooth 2s cubic-bezier(0.33,1,0.68,1) 0s both;
    }

/***/
.obj_overlay_tuto_home.step_0 .obj_popin_tuto_home .ctn_text.step_0,
.obj_overlay_tuto_home.step_1 .obj_popin_tuto_home .ctn_text.step_1,
.obj_overlay_tuto_home.step_2 .obj_popin_tuto_home .ctn_text.step_2,
.obj_overlay_tuto_home.step_3 .obj_popin_tuto_home .ctn_text.step_3,
.obj_overlay_tuto_home.step_4 .obj_popin_tuto_home .ctn_text.step_4,
.obj_overlay_tuto_home.step_5 .obj_popin_tuto_home .ctn_text.step_5{
    display: block;
    }





.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_step{
    font-size: var(--aox-fs-14px);
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_title{
    font-size: var(--aox-fs-22px);
    line-height: var(--aox-lh-130);
    text-align: center;
    font-weight: 400;
    margin: 0 auto;
    max-width: var(--aox-660px);
    letter-spacing: 0;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_title strong,
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_desc strong{
    font-weight: 700;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_desc{
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-140);
    text-align: center;
    font-weight: 400;
    margin-top: var(--aox-10px);
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta {
    display: flex;
    margin-top: var(--aox-30px);
    justify-content: center;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta .obj_btn + .obj_btn{
    margin-left: var(--aox-10px);
    }

.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close{
    position:absolute;
    z-index:3;
    right:var(--aox-20px); top:var(--aox-20px);
    width:var(--aox-40px); height:var(--aox-40px);
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close::before,
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close::after{
    position:absolute;
    left:50%; top:50%;
    background-color:#000;
    transition:all .3s ease;
    content:'';
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close:hover{
    background:#000;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close:hover::before,
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close:hover::after{
    background:#fff;
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close::before{
    width:2px; height:var(--aox-20px);
    -webkit-transform:translate(-50%,-50%) rotate(45deg);
            transform:translate(-50%,-50%) rotate(45deg);
    }
.obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close::after{
    width:var(--aox-20px); height:2px;
    -webkit-transform:translate(-50%,-50%) rotate(45deg);
            transform:translate(-50%,-50%) rotate(45deg);
    }


/***/

@media screen and (max-width: 767px){

    .obj_overlay_tuto_home .ctn_popin_tuto_home {
        width: 90vw; height: 100%;
        margin: 0 auto;
        right: 0;
        top: calc(100vh - var(--aox-300px));
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home{
        width: 100%;
        padding: var(--aox-40px) var(--aox-20px) var(--aox-80px);
        height: var(--aox-300px);
        }
    .obj_overlay_tuto_home.step_1 .ctn_popin_tuto_home .wrapper_popin_tuto_home,
    .obj_overlay_tuto_home.step_2 .ctn_popin_tuto_home .wrapper_popin_tuto_home,
    .obj_overlay_tuto_home.step_3 .ctn_popin_tuto_home .wrapper_popin_tuto_home,
    .obj_overlay_tuto_home.step_4 .ctn_popin_tuto_home .wrapper_popin_tuto_home,
    .obj_overlay_tuto_home.step_5 .ctn_popin_tuto_home .wrapper_popin_tuto_home{
        height: var(--aox-250px);
        }

    .obj_overlay_tuto_home.step_1 .ctn_popin_tuto_home, 
    .obj_overlay_tuto_home.step_2 .ctn_popin_tuto_home, 
    .obj_overlay_tuto_home.step_3 .ctn_popin_tuto_home, 
    .obj_overlay_tuto_home.step_4 .ctn_popin_tuto_home,
    .obj_overlay_tuto_home.step_5 .ctn_popin_tuto_home{
        top: calc(100vh - var(--aox-250px));
        }

    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .btn_popin_tuto_home_close {
        right: var(--aox-10px);
        top: var(--aox-10px);
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_title {
        font-size: var(--aox-fs-18px);
        line-height: var(--aox-lh-120);
        margin-top: var(--aox-10px);
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .t_desc {
        font-size: var(--aox-fs-14px);
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta{
        flex-wrap: wrap;
        flex-direction: row;
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta .obj_btn + .obj_btn {
        margin-left: var(--aox-10px); margin-top: 0;
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .step_0 .ctn_cta .obj_btn:nth-child(2){
        margin-left: 0;
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .ctn_cta .obj_btn{
        justify-content: center;
        font-size: var(--aox-fs-10px);
        line-height: var(--aox-lh-120);
        padding: var(--aox-20px) var(--aox-15px);
        }
    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home .step_0 .ctn_cta > button:first-child{
        display: none;
        }

    .obj_overlay_tuto_home .ctn_popin_tuto_home .wrapper_popin_tuto_home .obj_popin_tuto_home > .ctn_text{
        text-align: center;
        }


    /* step_1 */
    .obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0; left: 0;
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_2{
        top: var(--aox-100px);
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( var(--aox-240px) - var(--aox-7px) );
        }
    .obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-5px) );
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_1 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-490px) + var(--aox-15px) + var(--aox-80px));
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( 100% - ( var(--aox-420px) + var(--aox-4px) + var(--aox-160px) ) );
        }

    /* step_2 */
    .obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0; left: 0;
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_2{
        top: var(--aox-90px);
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( var(--aox-170px) - var(--aox-18px) );
        }
    .obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-5px) );
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_2 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-250px) + var(--aox-5px) + var(--aox-85px));
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( 100% - ( var(--aox-420px) + var(--aox-4px) + var(--aox-160px) ) );
        }

    /* step_3 */
    .obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0;
        left: 0;
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_2{
        top: var(--aox-80px);
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( var(--aox-520px) - var(--aox-8px) );
        }
    .obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-5px) );
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_3 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-670px) + var(--aox-5px) + var(--aox-80px));
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( 100% - ( var(--aox-420px) + var(--aox-4px) + var(--aox-160px) ) );
        }

    /* step_4 */
    .obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0;
        left: 0;
        width: calc( var(--aox-external-gutter) + var(--aox-grid) - var(--aox-40px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_2{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) - var(--aox-40px) );
        width: var(--aox-50px);
        height: var(--aox-10px);
        }
    .obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-10px) );
        width: var(--aox-10px);
        height: 100%;
        }
    .obj_overlay_tuto_home.step_4 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-70px) - var(--aox-10px) );
        left: calc( var(--aox-external-gutter) + var(--aox-grid) - var(--aox-40px) );
        width: var(--aox-50px);
        height: 100%;
        }

    /* step_5 */
    .obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_1{
        top: 0;
        left: 0;
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_2{
        top: var(--aox-70px);
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( var(--aox-1300px) );
        }
    .obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_3{
        top: 0;
        left: calc( var(--aox-external-gutter) + var(--aox-grid) + var(--aox-5px) );
        width: calc( var(--aox-external-gutter) - var(--aox-5px) );
        height: 100%;
        }
    .obj_overlay_tuto_home.step_5 .ctn_overlay_rect .obj_overlay_rect_4{
        top: calc( var(--aox-1330px) + var(--aox-5px) + var(--aox-70px));
        left: calc( var(--aox-external-gutter) - var(--aox-5px) );
        width: calc( var(--aox-grid) + var(--aox-10px) );
        height: calc( 100% - ( var(--aox-420px) + var(--aox-4px) + var(--aox-160px) ) );
        }
    

}
/* ======================================== */
/* .obj_accordion
/* ======================================== */

.section_faq .ctn_accordion{
	position: relative;
	padding-top: var(--aox-60px);
	margin: 0 auto var(--aox-30px);
	width: var(--aox-920px);
	}
.section_faq .ctn_accordion .t_title{
	font-size: var(--aox-fs-24px);
	line-height: var(--aox-lh-100);
    font-weight: 700;
    text-transform: uppercase;
	text-align: left;
	width: 100%;
	max-width: var(--aox-730px);
	margin-bottom: var(--aox-25px);
	}
.section_faq .ctn_accordion .ctn_title,
.section_faq .ctn_accordion .ctn_title .t_title{
	margin-bottom: var(--aox-15px);
	}


.ctn_accordion .obj_accordion{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
	background-color: #ffffff;
	border-top: 1px solid #BBBBBE;
	border-right: 1px solid #BBBBBE;
	border-left: 1px solid #BBBBBE;
	}
.ctn_accordion > .obj_accordion:last-child{ border-bottom: 1px solid #BBBBBE; }

.obj_accordion .obj_accordion-header{
	position: relative;
    display: flex;
    background-color: #F8F8F9;
    width: 100%;
	cursor: pointer;
    flex-wrap: wrap;
    align-items: flex-start;
	justify-content: space-between;
    padding: var(--aox-18px) var(--aox-18px) var(--aox-18px) var(--aox-32px);
    flex-direction: row;
	}
.obj_accordion .obj_accordion-header > .t_title{
	position: relative;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    font-weight: 700;
    text-align: left;
    display: flex;
    width: calc(100% - var(--aox-57px));
    max-width: calc(var(--aox-800px) + var(--aox-5px));
    margin-bottom: 0;
    min-height: var(--aox-40px);
    flex-direction: column;
    justify-content: center;
    text-transform: unset;
	}
.obj_accordion .obj_accordion-header .obj_accordion_toggle-icon{
	position: relative;
	width: var(--aox-40px); height: var(--aox-40px);
	transition:all .3s ease;
	}
.obj_accordion .obj_accordion-header .obj_accordion_toggle-icon::before{
    content: "";
	position: absolute;
    display: inline-block;
    width: var(--aox-14px); height: var(--aox-14px);
    top: 50%; left: 50%;
    border-bottom: 2px solid; border-right: 2px solid;
    -webkit-transform: translate(-50%, -70%) rotateZ(45deg);
            transform: translate(-50%, -70%) rotateZ(45deg);
	}

.obj_accordion .obj_accordion-content{
	height: 0;
    position: relative;
    width: 100%;
    transition: all 0.8s ease;
	}
.obj_accordion.is_expanded .obj_accordion-header{
	background-color: #FFFFFF;
	}
.obj_accordion.is_expanded .obj_accordion-header .obj_accordion_toggle-icon{
	-webkit-transform: rotateZ(180deg);
	        transform: rotateZ(180deg);
	}
.obj_accordion.is_expanded .obj_accordion-content{
	display: block;
    height: auto;
	}
.obj_accordion.is_expanded .obj_accordion-content .ctn_text{
	opacity: 1;
	}

.obj_accordion .obj_accordion-content .ctn_text{
	opacity: 0;
    padding: 0 var(--aox-80px) var(--aox-18px) var(--aox-30px);
    transition: all 0.2s ease;
	}
.obj_accordion .obj_accordion-content .ctn_text p a{
	font-weight: 500;
	text-decoration: underline;
	}


	
@media screen and (max-width: 767px){

	.section_faq .ctn_accordion {
		padding-top: var(--aox-30px);
		margin: 0 auto var(--aox-20px);
		width: 100%;
		}
	.section_faq .ctn_accordion .ctn_title {
		margin-bottom: var(--aox-20px);
		}
	.section_faq .ctn_accordion .ctn_title .t_title {
		font-size: var(--aox-fs-16px);
		line-height: var(--aox-lh-120);
		max-width: unset;
		margin-bottom: var(--aox-20px);
		}
	.obj_accordion .obj_accordion-header {
		padding: var(--aox-18px) var(--aox-12px) var(--aox-18px) var(--aox-20px);
		}
	.obj_accordion .obj_accordion-header > .t_title {
		font-size: var(--aox-fs-16px);
		line-height: var(--aox-lh-140);
		width: calc(100% - var(--aox-48px));
		max-width: unset;
		/* min-height: var(--aox-40px); */
		}
	.obj_accordion .obj_accordion-content {
		display: none;
		position: relative;
		width: 100%;
		transition: all .8s ease;
		}

	.obj_accordion.is_expanded .obj_accordion-header {
		padding: var(--aox-18px) var(--aox-12px) var(--aox-15px) var(--aox-20px);
		}
	.obj_accordion.is_expanded .obj_accordion-content .ctn_text {
		padding: 0 var(--aox-30px) var(--aox-18px) var(--aox-20px);
		}
}
/* ======================================== */
/* .obj_news_carousel_hero_home
/* ======================================== */
.obj_news_carousel_hero_home{
	position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
	margin-bottom: var(--aox-10px);
	-webkit-animation: effectNewsCarouselHeroHome 1s ease 1400ms forwards;
	        animation: effectNewsCarouselHeroHome 1s ease 1400ms forwards;
    opacity: 0;
	}
.obj_news_carousel_hero_home > *{
	width: 100%;
	}

.obj_news_carousel_hero_home .obj_item{
	width: var(--aox-650px);
	height: var(--aox-150px);
	display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
	overflow: hidden;
	}
.obj_news_carousel_hero_home .obj_item a {
	display: block;
	}

.obj_news_carousel_hero_home .obj_item .ctn_image{
	position: relative;
    display: block;
    width: 100%;
	}
.obj_news_carousel_hero_home .obj_item .ctn_image img{
	display: block;
	height: 100%;
    width: 100%;
    object-fit: cover;
	}

.obj_news_carousel_hero_home .obj_carousel .slick-dots {
	left: auto;
	right: var(--aox-10px);
	bottom: var(--aox-10px);
	justify-content: flex-end;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-dots li {
	height: var(--aox-8px);
	width: var(--aox-8px);
	margin: 0 0 0 var(--aox-4px);
	border-radius: var(--aox-4px);
	background-color: rgba(255, 255, 255, .5);
	border: 1px solid transparent;
	transition: all .3s ease;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-dots li.slick-active {
	background-color: rgba(255, 255, 255, 1);
	width: var(--aox-32px);
	}

.obj_news_carousel_hero_home .obj_carousel .slick-arrow {
	top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: var(--aox-40px);
    width: var(--aox-40px);
    margin-top: unset;
	text-indent: unset;
	font-size: 0;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-next:before,
.obj_news_carousel_hero_home .obj_carousel .slick-prev:before {
	content: "";
	opacity: .75;
	display: block;
	height: var(--aox-40px);
    width: var(--aox-40px);
	background-size:var(--aox-40px) var(--aox-40px);
	background-repeat: no-repeat;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-next:hover:before,
.obj_news_carousel_hero_home .obj_carousel .slick-prev:hover:before {
	opacity: 1;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-prev {
	left: var(--aox-10px);
	border-top: none;
    border-left: none;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-next {
	right: var(--aox-10px);
	border-top: none;
    border-right: none;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-prev:before{
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBNSVQuIE1hZGUgYnkgbWljaGFlbGFtcHI6IGh0dHBzOi8vZ2l0aHViLmNvbS9taWNoYWVsYW1wci9qYW0gLS0+CjxzdmcgZmlsbD0iI2ZmZmZmZiIgd2lkdGg9IjgwMHB4IiBoZWlnaHQ9IjgwMHB4IiB2aWV3Qm94PSItOCAtNSAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiIgY2xhc3M9ImphbSBqYW0tY2hldnJvbi1sZWZ0Ij48cGF0aCBkPSdNMi43NTcgN2w0Ljk1IDQuOTVhMSAxIDAgMSAxLTEuNDE0IDEuNDE0TC42MzYgNy43MDdhMSAxIDAgMCAxIDAtMS40MTRMNi4yOTMuNjM2QTEgMSAwIDAgMSA3LjcwNyAyLjA1TDIuNzU3IDd6JyAvPjwvc3ZnPg==');
	}

.obj_news_carousel_hero_home .obj_carousel .slick-next:before{
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBMaWNlbnNlOiBNSVQuIE1hZGUgYnkgbWljaGFlbGFtcHI6IGh0dHBzOi8vZ2l0aHViLmNvbS9taWNoYWVsYW1wci9qYW0gLS0+CjxzdmcgZmlsbD0iI2ZmZmZmZiIgd2lkdGg9IjgwMHB4IiBoZWlnaHQ9IjgwMHB4IiB2aWV3Qm94PSItOCAtNSAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWluWU1pbiIgY2xhc3M9ImphbSBqYW0tY2hldnJvbi1yaWdodCI+PHBhdGggZD0nTTUuMzE0IDcuMDcxbC00Ljk1LTQuOTVBMSAxIDAgMCAxIDEuNzc4LjcwN2w1LjY1NyA1LjY1N2ExIDEgMCAwIDEgMCAxLjQxNGwtNS42NTcgNS42NTdhMSAxIDAgMCAxLTEuNDE0LTEuNDE0bDQuOTUtNC45NXonIC8+PC9zdmc+');
	}
.obj_news_carousel_hero_home .obj_carousel .slick-disabled{
    opacity: .2;
	}

.obj_news_carousel_hero_home .obj_carousel .slick-prev.slick-disabled:hover{
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%); left: var(--aox-10px) !important;
	}
.obj_news_carousel_hero_home .obj_carousel .slick-next.slick-disabled:hover{
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%); right: var(--aox-10px) !important;
	}

@media screen and (max-width: 767px){

	.obj_news_carousel_hero_home { 
		height: calc( (100vw - var(--aox-external-gutter) - var(--aox-external-gutter)) * (150/650) );
		margin-bottom: var(--aox-2px);
		-webkit-animation: effectNewsCarouselHeroHome 1s ease 400ms forwards;
		        animation: effectNewsCarouselHeroHome 1s ease 400ms forwards;
	}
	.obj_news_carousel_hero_home > *{
		overflow: visible;
		}
	.obj_news_carousel_hero_home .obj_item {
		height: calc( (100vw - var(--aox-external-gutter) - var(--aox-external-gutter)) * (150/650) );
		}
	.obj_news_carousel_hero_home .obj_item .ctn_image {
		width: 100%;
		position: static !important;
    	max-width: none !important;
		}
	.obj_news_carousel_hero_home .obj_item .ctn_image img{
		position: absolute;
        top: 50%;
        left: 50%;
        height: auto;
        /* max-height: 100px; */
        width: 100%;
        max-width: none;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
		}
		
	.obj_news_carousel_hero_home .obj_carousel .slick-prev, .obj_carousel .slick-prev.slick-disabled {
		left: calc(-1* var(--aox-12px)); 
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-next, .obj_carousel .slick-next.slick-disabled {
		right: calc(-1* var(--aox-12px));
		}

	.obj_news_carousel_hero_home .obj_carousel .slick-dots {
		display: flex !important;
        left: 50%;
        bottom: var(--aox-5px);
        width: calc(100% - var(--aox-10px));
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-dots li.slick-active {
		width: var(--aox-20px);
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-next,
	.obj_news_carousel_hero_home .obj_carousel .slick-prev {
		width: var(--aox-40px) !important; height: var(--aox-40px) !important;
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-next:before,
	.obj_news_carousel_hero_home .obj_carousel .slick-prev:before {
		background-size: var(--aox-30px);
        width: var(--aox-40px) !important;
        height: var(--aox-40px) !important;
        background-position: center;
		}
	.obj_news_carousel_hero_home .obj_carousel .slick-prev,
	.obj_news_carousel_hero_home .obj_carousel .slick-prev.slick-disabled:hover { left: calc(-1* var(--aox-10px)) !important; }
	.obj_news_carousel_hero_home .obj_carousel .slick-next,
	.obj_news_carousel_hero_home .obj_carousel .slick-next.slick-disabled:hover {right: calc(-1* var(--aox-10px)) !important;}

	}
/* ======================================== */
/* .obj_tabs-list-attestation
/* ======================================== */

.obj_tabs-list-attestation{
	position: relative;
    margin-top: var(--aox-30px);
    width: 100%;
    }
.obj_tabs-list-attestation .ctn_nav-tabs {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #c3c3c3;
    flex-direction: row;
    align-items: center;
    }
.obj_tabs-list-attestation .ctn_nav-tabs li {
    position: relative;
    width: 100%;
    }
.obj_tabs-list-attestation .ctn_nav-tabs li button {
    position: relative;
    width: 100%;
    padding: var(--aox-23px) 0 var(--aox-21px);
    font-size: var(--aox-fs-18px);
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 4px solid transparent;
    transition: all .3s ease;
    }
.obj_tabs-list-attestation .ctn_nav-tabs li.active button {
    border-bottom-color: #000;
    }

.obj_tabs-list-attestation .ctn_content-tabs{ display: none; }
.obj_tabs-list-attestation .ctn_content-tabs.active { display: block; }

/***/
.obj_tabs-list-attestation .obj_accordion{
    position: relative;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row{
    position: relative;
    border-bottom: 1px solid #E1E1E1;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row-header {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: var(--aox-12px) 0 var(--aox-12px) var(--aox-20px);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    cursor: pointer;
    width: 50%;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title:hover .obj_accordion_toggle-icon{
    background-color: #000;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title:hover .obj_accordion_toggle-icon::before {
    border-color: #fff;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title > .t_title {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-transform: uppercase;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    margin-left: var(--aox-20px);
    height: var(--aox-24px);
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    font-weight: 700;
    width: var(--aox-240px);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title .obj_accordion_toggle-icon{
    position: relative;
    z-index: 999;
    width: var(--aox-24px);
    height: var(--aox-24px);
    background: #E5E5E5;
    border-radius: 50%;
    font-size: 0;
    cursor: pointer;
    transition: all .3s ease;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-title .obj_accordion_toggle-icon::before{
    content: "";
    position: absolute;
    display: inline-block;
    width: var(--aox-6px); height: var(--aox-6px);
    top: 50%; left: 50%;
    border-bottom: 2px solid; border-right: 2px solid;
    -webkit-transform: translate(-50%, -70%) rotateZ(45deg);
            transform: translate(-50%, -70%) rotateZ(45deg);
    }


.obj_tabs-list-attestation .obj_accordion .obj_accordion-row .ctn_accordion-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 500ms;
    width: 100%;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row.is_expanded .ctn_accordion-content {
    grid-template-rows: 1fr;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row .ctn_accordion-content .obj_accordion-content{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: #f7f7f7;
    overflow: hidden;
    height: unset;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row .ctn_accordion-content .obj_accordion-content > div{
    padding: var(--aox-20px) var(--aox-90px) var(--aox-20px) var(--aox-64px);
    width: 100%;
    }


.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header{
    position: relative;
    font-size: var(--aox-fs-12px);
    font-weight: 500;
    line-height: var(--aox-lh-140);
    width: 100%;
    padding: var(--aox-10px) 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header > * + *,
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows > * + *{
    margin-left: var(--aox-20px);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(1),
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_date_hour{
    width: 20%;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(2),
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_event{
    width: 60%;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(3),
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_location{
    width: 20%;
    }

.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows{
    position: relative;
    font-size: var(--aox-fs-12px);
    font-weight: 500;
    line-height: var(--aox-lh-140);
    width: 100%;
    padding: var(--aox-10px) 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #E1E1E1;
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_date_hour{
    font-size: var(--aox-fs-14px);
    font-weight: 700;
    line-height: var(--aox-lh-140);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_event{
    font-size: var(--aox-fs-14px);
    font-weight: 700;
    line-height: var(--aox-lh-140);
    }
.obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_location{
    font-size: var(--aox-fs-14px);
    font-weight: 400;
    line-height: var(--aox-lh-140);
    }







/***/
.obj_tabs-list-attestation .obj_accordion .obj_accordion-row.is_expanded .obj_accordion_toggle-icon{
	-webkit-transform: rotateZ(180deg);
	        transform: rotateZ(180deg);
	}



@media screen and (max-width: 768px) {

    .section_challenges-detail .ctn_table_attestation{
        margin-left: calc(-1* var(--aox-gutter));
        width: calc(100% + var(--aox-gutter) + var(--aox-gutter));
        }
    .obj_tabs-list-attestation .ctn_nav-tabs li button {
        padding: var(--aox-18px) var(--aox-5px) var(--aox-15px);
        font-size: var(--aox-fs-15px);
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-row-header {
        padding: var(--aox-20px);
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-title,
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-cta{
        width: calc(50% - var(--aox-5px));
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-title > .t_title{
        margin-left: var(--aox-10px);
        width: calc(100% - var(--aox-34px));
        height: auto;
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-row-header .obj_accordion-cta .obj_cta {
        font-size: var(--aox-fs-10px);
        padding: var(--aox-12px) var(--aox-14px);
        }

    .obj_tabs-list-attestation .obj_accordion .obj_accordion-row .ctn_accordion-content .obj_accordion-content > div{
        padding: var(--aox-20px);
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header > * + *, .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows > * + * {
        margin-left: var(--aox-10px);
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header {
        flex-wrap: wrap;
        padding: 0 0 var(--aox-6px) 0;
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(1),
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_date_hour{
        width: calc(37% - var(--aox-5px));
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(2),
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_event{
        width: calc(63% - var(--aox-5px));
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .obj_table-header .header-cell:nth-child(3),
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows .expand_location{
        width: 100%;
        margin-left: 0;
        }
    .obj_tabs-list-attestation .obj_accordion .obj_accordion-content .ctn_rows {
        flex-wrap: wrap;
        }

    }
/* ======================================== */
/* .pg_login
/* ======================================== */
.pg_login .main_header-login{
	display:none;
	}
/* ======================================== */
/* .pg_password
/* ======================================== */
.pg_password .main_header-login{
	display:none;
	}
.pg_password .obj_form .obj_strength-level{
	background:#fff;
	}
/* ======================================== */
/* .pg_404
/* ======================================== */
.pg_404.is-not-logged .main_container{
	padding-top:var(--aox-80px);
	}
.pg_404 .ctn_cta{
	width:100%;
	padding-bottom:var(--aox-60px);
	text-align:center;
	}

@media screen and (min-width:768px){
	.pg_404 .main_header.mode_mini{
		-webkit-transform:translateY(0);
		        transform:translateY(0);
		}
}
/* ======================================== */
/* .pg_home-caf
/* ======================================== */
.pg_home-caf .section_push-article .str_container{
	max-width:var(--aox-1150px);
	}
.pg_home-caf .obj_push-article{
	margin:0;
	padding:var(--aox-60px) 0;
	border-top:1px solid #c3c3c3;
	}
.pg_home-caf .obj_push-article:first-of-type{
	border-top:none;
	}
.pg_home-caf .obj_push-article .ctn_push-article{
	border:none;
	}
.pg_home-caf .obj_push-article .ctn_text{
	padding:0 2% 0 8%;
	}
.pg_home-caf .obj_push-article .ctn_text h3{
	margin-top:var(--aox-15px);
	-webkit-transform:translate(calc(-1 * var(--aox-30px)));
	        transform:translate(calc(-1 * var(--aox-30px)));
	}
.pg_home-caf .obj_push-article .ctn_text h3::before{
	position:absolute;
	left:0; top:var(--aox-35px);
	width:var(--aox-4px); height:var(--aox-44px);
	content:'';
	}

@media screen and (min-width:768px){
	.pg_home-caf .main_header.mode_mini{
		-webkit-transform:translateY(0);
		        transform:translateY(0);
		}
}
@media screen and (max-width:767px){
	.pg_home-caf .main_container{
		max-width: unset;
		}
	.pg_home-caf .obj_push-article{
		/* width:calc(100% - var(--aox-30px));
		margin:0 var(--aox-15px); */
		width:100%;
		margin:0;
		padding:var(--aox-50px) 0 0 0;
		}
	.pg_home-caf .obj_push-article .ctn_text{
		padding:0 var(--aox-30px);
		}
	.pg_home-caf .obj_push-article .ctn_text h3{
		margin-top:var(--aox-30px);
		}
	.pg_home-caf .obj_push-article .ctn_text h3::before{
		top:var(--aox-30px);
		}
	.pg_home-caf .obj_push-article .ctn_text .obj_likes-comments{
		margin-bottom:var(--aox-40px);
		}
}
/* ======================================== */
/* .pg_inscription
/* ======================================== */
.pg_inscription .main_container{
	padding-top:var(--aox-80px);
	}
	
@media screen and (max-width:767px){
	.pg_inscription .main_container{
		padding-top:var(--aox-60px);
		}
}
/* ======================================== */
/* .pg_contact
/* ======================================== */
.pg_contact.is-not-logged .main_container{
	padding-top:var(--aox-80px);
	}

@media screen and (max-width:767px){
	.pg_contact.is-not-logged .main_container{
		padding-top:var(--aox-60px);
		}
}
/* ======================================== */
/* .pg_brand
/* ======================================== */
.pg_brand .main_container{
	padding-top:var(--aox-165px);
	}
.pg_brand .main_header .ctn_nav-marque{
	display:block;
	}
/***/
.pg_brand.pg_dermexpertise .main_container > .var_100.var_full.var_derm-expertise{
	margin-top: 0;
	}
.pg_brand.pg_dermexpertise .main_container > *:last-child{
	margin-bottom: var(--aox-90px);
	}
@media screen and (max-width:1280px){
	.pg_brand .main_container{
		padding-top:var(--aox-110px);
		}
	.pg_brand.menu-mobile_is-opened .main_header .ctn_nav-marque {
		display: none;
		}
}
/* ======================================== */
/* .pg_page-liste
/* ======================================== */
.pg_page-liste .obj_title{
	font-size:var(--aox-fs-46px);
	text-transform:none;
	}

@media screen and (max-width:767px){
	.pg_page-liste .obj_title{
		font-size:var(--aox-fs-42px);
		}
}
/* ======================================== */
/* .pg_mentions
/* ======================================== */
.pg_mentions.is-not-logged .main_container{
	padding-top:var(--aox-80px);
	}

@media screen and (max-width:767px){
	.pg_mentions.is-not-logged .main_container{
		padding-top:var(--aox-60px);
		}
}
/* ======================================== */
/* .pg_product-liste
/* ======================================== */
.pg_product-liste .main_container{
	position:relative;
	}
.pg_product-liste .obj_breadcrumb{
	position:absolute;
	left:var(--aox-60px); top:var(--aox-160px);
	padding-left:0;
	}
.pg_product-liste.var_banner-white .obj_banner-product .ctn_text h1,
.pg_product-liste.var_banner-white .obj_banner-product .ctn_text h2{
	color:#fff;
	}
.pg_product-liste.var_banner-white .obj_breadcrumb li{
	color:#fff;
	}
.pg_product-liste.var_banner-white .obj_breadcrumb li a::before{
	border-top-color:#fff;
	border-right-color:#fff;
	}

@media screen and (max-width:1280px){
	.pg_product-liste .obj_breadcrumb{
		left:var(--aox-30px); top:var(--aox-110px);
		}
}
@media screen and (max-width:767px){
	.pg_product-liste .obj_breadcrumb{
		left:var(--aox-20px); top:var(--aox-100px);
		}
}
/* ======================================== */
/* .pg_page-liste
/* ======================================== */
.pg_ingredients-liste .obj_title{
	font-size:var(--aox-fs-42px);
	text-transform:uppercase;
	}
.pg_ingredients-liste .obj_push-custom{
	margin-top:0;
	margin-bottom:var(--aox-60px);
	}
.pg_ingredients-liste .obj_push-custom.var_full.var_dark .ctn_text h2{
	color:#fff !important;
	}

@media screen and (max-width:767px){
	.pg_ingredients-liste .obj_title{
		font-size:var(--aox-fs-38px);
		}
	.pg_ingredients-liste .obj_push-custom{
		margin-bottom:var(--aox-40px);
		}
	.pg_ingredients-liste .obj_push-custom.var_full .ctn_text,
	.pg_ingredients-liste .obj_push-custom.var_full.var_100 .ctn_text{
		max-width:100%;
		}
	.pg_ingredients-liste .obj_push-custom.var_full .ctn_text h2,
	.pg_ingredients-liste .obj_push-custom.var_full .ctn_text h2 p{
		font-size:var(--aox-fs-20px);
		}
}
/* ======================================== */
/* .pg_routine-liste
/* ======================================== */
.pg_routine-liste .section_range-liste{
	margin-top:0;
	}
/* ======================================== */
/* .pg_infos-perso
/* ======================================== */
@media screen and (max-width:767px){
	.pg_infos-perso .section_form .ctn_form .ctn_contact{
		display:block;
		margin-bottom:var(--aox-20px);
		}

	.pg_infos-perso .section_form .ctn_form {
		padding: var(--aox-40px) var(--aox-20px);
		}
	.pg_infos-perso .section_form .ctn_form .ctn_contact {
		padding: var(--aox-50px) var(--aox-20px);
		}
	.pg_infos-perso .obj_form input[type="password"]{
		padding: var(--aox-10px) var(--aox-50px) var(--aox-10px) var(--aox-20px);
		font-size:var(--aox-fs-9px);
		}
}
/* ======================================== */
/* .pg_concours
/* ======================================== */
.pg_concours{
	overflow-x:hidden;
	}
.pg_concours .main_container{
	position:relative;
	padding-bottom:var(--aox-150px);
	}
.pg_concours .obj_title{
	padding-bottom:0;
	}
.pg_concours .obj_back-page{
	max-width:unset;
	margin-left:var(--aox-30px);
	}
@media screen and (max-width:767px){
	.pg_concours .main_container{
		padding-bottom:var(--aox-60px);
		}
}
@media screen and (max-width:1280px){
	.pg_concours .obj_back-page{
		margin-left:var(--aox-20px);
		padding:0;
		}
}
/**/
.pg_concours_home .obj_breadcrumb{
	position:relative;
	width:100%;
	padding:var(--aox-20px) var(--aox-30px) 0 var(--aox-30px);
	}
.pg_concours_home.var_privilege .obj_breadcrumb li{
	position:relative;
	z-index:2;
	color:#fff;
	}
.pg_concours_home.var_privilege .obj_breadcrumb li a::before{
	border-color:#fff;
	}
.pg_concours_home.var_privilege .obj_title-big{
	position:relative;
	color:#fff;
	}
.pg_concours_home .obj_title-big span{
	position:relative;
	z-index:2;
	}
.pg_concours_home .obj_push-article{
	position:relative;
	margin-top:0;
	padding-bottom:var(--aox-60px);
	}
.pg_concours_home .obj_push-article .ctn_push-article{
	position:relative;
	z-index:2;
	align-items:center;
	border:none;
	background:#fff;
	}
.pg_concours_home .obj_push-article .ctn_text{
	padding-bottom:var(--aox-60px);
	}
.pg_concours_home .obj_push-article .ctn_text h3{
	margin-top:var(--aox-60px);
	}
.pg_concours_home .obj_push-article .ctn_text .ctn_cta{
	margin-top:var(--aox-20px);
	}
.pg_concours_home.var_privilege .obj_breadcrumb::before,
.pg_concours_home.var_privilege .obj_title-big::before,
.pg_concours_home.var_privilege .obj_push-article::before{
	content:'';
	position:absolute;
	z-index:1;
	left:50%; top:0;
	width:100vw; height:100%;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	}
.pg_concours_home .section_page-liste{
	margin-top:var(--aox-60px);
	}
.pg_concours_home .section_page-liste .str_container{
	max-width:var(--aox-1280px);
	}
.pg_concours_home .obj_tabs-list .ctn_nav-tabs{
	max-width:var(--aox-1150px);
	margin-left:auto;
	margin-right:auto;
	}
.pg_concours_home .section_page-liste .ctn_row{
	margin-bottom:0;
	}
.pg_concours_home .section_page-liste .ctn_sub-row{
	padding:0;
	}
.pg_concours_home .obj_push-custom.var_100{
	margin:0;
	width:100%;
	}
.pg_concours_home .obj_push-custom + .obj_push-custom,
.pg_concours_home .section_page-liste .obj_push-custom + .obj_push-article,
.pg_concours_home .section_page-liste .obj_push-article + .obj_push-custom,
.pg_concours_home .section_page-liste .obj_push-article + .obj_push-article{
	margin-top:var(--aox-120px);
	}
.pg_concours_home .obj_push-custom + .obj_push-custom::before,
.pg_concours_home .section_page-liste .obj_push-custom + .obj_push-article::after,
.pg_concours_home .section_page-liste .obj_push-article + .obj_push-custom::after,
.pg_concours_home .section_page-liste .obj_push-article + .obj_push-article::after{
	content:'';
	position:absolute;
	left:0; top:calc(-1 * var(--aox-60px));
	width:100%; height:1px;
	background:#c3c3c3;
	}
.pg_concours_home .obj_push-custom.var_full .ctn_text h2,
.pg_concours_home .obj_push-custom.var_full .ctn_text h2 p{
	color:#fff !important;
	text-transform:uppercase;
	}
.pg_concours_home .section_page-liste .obj_push-article{
	padding-bottom:0;
	}
.pg_concours_home .section_page-liste .obj_push-article::before{
	display:none;
	}
.pg_concours_home .section_page-liste .obj_push-article .ctn_text h3{
	margin-top:0;
	}
.pg_concours_home .section_page-liste .obj_push-article .ctn_text{
	padding-bottom:0;
	}

@media screen and (min-width:768px){
	.pg_concours_home .obj_title-big{
		padding-left:12vw;
		padding-right:12vw;
		}
	.pg_concours_home .section_page-liste .obj_push-article .ctn_img{
		width:32%;
		max-width:var(--aox-400px);
		}
	.pg_concours_home .section_page-liste .obj_push-article .ctn_text{
		width:68%;
		}
}
@media screen and (min-width:768px) and (max-width:1340px){
	.pg_concours_home .obj_push-custom.var_100{
		height:35.3vw;
		}
}
@media screen and (max-width:1280px){
	.pg_concours_home .obj_breadcrumb::before,
	.pg_concours_home .obj_title-big::before,
	.pg_concours_home .obj_push-article::before{
		left:0;
		width:100%;
		-webkit-transform:none;
		        transform:none;
		}	
}
@media screen and (max-width:1024px){
	.pg_concours_home .obj_push-article{
		padding-left:var(--aox-20px);
		padding-right:var(--aox-20px);
		}
	.pg_concours_home .section_page-liste .obj_push-article{
		padding-left:0;
		padding-right:0;
		}
}
@media screen and (max-width:768px){
	.pg_concours_home .obj_push-article{
		margin-left:0;
		margin-right:0;
		}
	.pg_concours_home .obj_push-custom.var_full .ctn_text,
	.pg_concours_home .obj_push-custom.var_full.var_100 .ctn_text{
		max-width:50%;
		}
}
@media screen and (max-width:767px){
	.pg_concours_home .section_page-liste .ctn_row{
		margin-left:var(--aox-20px);
		margin-right:var(--aox-20px);
		}
	.pg_concours_home .obj_tabs-list .ctn_nav-tabs{
		margin-bottom:var(--aox-40px);
		}
	.pg_concours_home .obj_push-custom + .obj_push-custom,
	.pg_concours_home .section_page-liste .obj_push-custom + .obj_push-article,
	.pg_concours_home .section_page-liste .obj_push-article + .obj_push-custom,
	.pg_concours_home .section_page-liste .obj_push-article + .obj_push-article{
		margin-top:var(--aox-80px);
		}
	.pg_concours_home .obj_push-custom + .obj_push-custom::before,
	.pg_concours_home .section_page-liste .obj_push-custom + .obj_push-article::after,
	.pg_concours_home .section_page-liste .obj_push-article + .obj_push-custom::after,
	.pg_concours_home .section_page-liste .obj_push-article + .obj_push-article::after{
		top:calc(-1 * var(--aox-40px));
		}
	.pg_concours_home .section_page-liste .ctn_sub-row > .ctn_cta{
		margin-top:var(--aox-40px);
		padding-top:var(--aox-40px);
		}
	.pg_concours_home .obj_push-custom.var_full .ctn_text,
	.pg_concours_home .obj_push-custom.var_full.var_100 .ctn_text{
		max-width:75%;
		}
	.pg_concours_home .obj_push-article .ctn_text h3,
	.pg_concours_home .section_page-liste .obj_push-article .ctn_text h3{
		margin-top:var(--aox-30px);
		}
}
/**/
.pg_concours_conseil .main_container{
	padding-bottom:0;
	}
.pg_concours_conseil .obj_push-article{
	margin-top:0;
	}
.pg_concours_conseil .obj_push-article .ctn_push-article{
	align-items:center;
	}
.pg_concours_conseil .obj_push-article .ctn_text{
	padding-top:var(--aox-30px);
	padding-bottom:var(--aox-30px);
	}
.pg_concours_conseil .obj_push-article h2{
	margin-top:0;
	}
.pg_concours_conseil .section_article .str_container{
	padding-top:var(--aox-60px);
	}
/**/
.pg_concours_resultat .obj_push-custom{
	margin-top:0;
	}
.pg_concours_resultat .obj_push-custom.var_full .ctn_text{
	top:50%;
	-webkit-transform:translateY(-50%);
	        transform:translateY(-50%);
	}
.pg_concours_resultat .obj_push-custom.var_full .ctn_text h2,
.pg_concours_resultat .obj_push-custom.var_full .ctn_text h2 p{
	margin-top:0;
	font-size:var(--aox-fs-44px);
	color:#fff !important;
	text-transform:uppercase;
	}
.pg_concours_resultat .obj_push-custom.var_full .ctn_text h3,
.pg_concours_resultat .obj_push-custom.var_full .ctn_text h3 p{
	font-size:var(--aox-fs-30px);
	}
@media screen and (max-width:767px){
	.pg_concours_resultat .obj_push-custom.var_full .ctn_text h2,
	.pg_concours_resultat .obj_push-custom.var_full .ctn_text h2 p{
		font-size:var(--aox-fs-34px);
		}
	.pg_concours_resultat .obj_push-custom.var_full .ctn_text h3,
	.pg_concours_resultat .obj_push-custom.var_full .ctn_text h3 p{
		font-size:var(--aox-fs-22px);
		}
}
/**/
.pg_concours_galerie .obj_push-custom{
	margin-top:0;
	}
.pg_concours_galerie .obj_push-custom.var_full .ctn_text{
	top:50%;
	-webkit-transform:translate(-50%,-50%);
	        transform:translate(-50%,-50%);
	}
.pg_concours_galerie .obj_push-custom.var_full .ctn_text h2,
.pg_concours_galerie .obj_push-custom.var_full .ctn_text h2 p{
	margin:0;
	font-size:var(--aox-fs-44px);
	color:#fff !important;
	text-transform:uppercase;
	}
@media screen and (max-width:767px){
	.pg_concours_galerie .obj_push-custom.var_full .ctn_text{
		max-width:100%;
		width:100%;
		padding:0 var(--aox-20px);
		}
	.pg_concours_galerie .obj_push-custom.var_full .ctn_text h2,
	.pg_concours_galerie .obj_push-custom.var_full .ctn_text h2 p{
		font-size:7vw;
		}
}
/* ======================================== */
/* .pg_challenge
/* ======================================== */
@media screen and (max-width:767px){
	.pg_challenge .main_container {
		max-width: unset;
		width: 100%;
		}
	.pg_challenge .obj_breadcrumb{
		width: var(--aox-grid);
   		margin: 0 auto;
		}
	.pg_challenge .section_challenges.obj_section .ctn_section {
		width: 100%;
		}
	.pg_challenge .section_challenges.obj_section .ctn_section .ctn_title {
		max-width: unset;
		margin: 0px auto var(--aox-20px);
		width: var(--aox-grid);
		}
	.pg_challenge .section_challenges.obj_section .ctn_section .ctn_title .t_title{
		width: var(--aox-col-5);
		margin-bottom: 0;
		}
	.pg_challenge .section_challenges.obj_section .ctn_row > .ctn_filter {
		margin: 0 auto;
		}
	.pg_challenge .section_challenges.obj_section .ctn_row > .ctn_list_challenge {
		width: var(--aox-grid);
    	margin: var(--aox-30px) auto 0;
		}
	.section_challenges.obj_section .ctn_row > .ctn_aside{
		width: 100%;
		order: 2;
		margin: 0 auto;
		}
	
}
/* ======================================== */
/* .pg_challenge-detail
/* ======================================== */

.pg_challenge-detail .obj_back-page {
    width: 100%;
    max-width: var(--aox-grid);
    margin: var(--aox-30px) auto 0 auto;
	}
.pg_challenge-detail .obj_back-page .bt_back-page{
	font-weight: 700;
    text-transform: uppercase;
	}

.pg_challenge-detail .obj_back-page + .obj_section{
	padding: var(--aox-20px) 0 var(--aox-90px) 0;
	}




@media screen and (max-width:767px){
	
}
/* ======================================== */
/* .pg_formation
/* ======================================== */
@media screen and (max-width:767px){
	.pg_formation .main_container {
		max-width: unset;
		width: 100%;
		}
	.pg_formation .obj_breadcrumb{
		width: var(--aox-grid);
   		margin: 0 auto;
		}
	.pg_formation .section_formation.obj_section .ctn_section {
		width: 100%;
		}
	.pg_formation .section_formation.obj_section .ctn_section .ctn_title {
		max-width: unset;
		margin: 0px auto var(--aox-20px);
		width: var(--aox-grid);
		}
	.pg_formation .section_formation.obj_section .ctn_section .ctn_title .t_title{
		width: var(--aox-col-5);
		margin-bottom: 0;
		}
	.pg_formation .section_formation.obj_section .ctn_row > .ctn_filter {
		margin: 0 auto;
		}
	.pg_formation .section_formation.obj_section .ctn_row > .ctn_list_formation {
		width: var(--aox-grid);
    	margin: auto 0;
		}
	.section_formation.obj_section .ctn_row > .ctn_aside{
		width: 100%;
		order: 2;
		margin: 0 auto;
		}
	
}
/* ======================================== */
/* .pg_formation_detail
/* ======================================== */

.pg_formation_detail .obj_back-page {
    width: 100%;
    max-width: var(--aox-grid);
    margin: var(--aox-30px) auto 0 auto;
	}
.pg_formation_detail .obj_back-page .bt_back-page{
	font-weight: 700;
    text-transform: uppercase;
	}

.pg_formation_detail .obj_back-page + .obj_section{
	padding: var(--aox-20px) 0 var(--aox-90px) 0;
	}




@media screen and (max-width:767px){
	
	.pg_formation_detail .obj_back-page {
		padding: 0;
		}
}
/* ======================================== */
/* .pg_home2024
/* ======================================== */

.pg_home2024{
	background-color: #F2F2F2;
	}
.pg_home2024 .main_container{
	max-width:100%;
	margin-top: 0;
	transition:none;
	}
.pg_home2024 .main_footer {
	position: relative;
	z-index: 1;
	}

@media screen and (min-width:768px){
	.pg_home2024 .main_header.mode_mini{
		-webkit-transform:translateY(0);
		        transform:translateY(0);
		}
}
@media screen and (max-width:767px){
	.pg_home2024 .main_container{
		max-width: unset;
		}
		
}
/* ======================================== */
/* .pg_maintenance
/* ======================================== */

.pg_maintenance{
	background-color: #F2F2F2;
	}
.pg_maintenance .main_header, .pg_maintenance .main_header-login,
.pg_maintenance .main_footer, .pg_maintenance .main_footer-login{
	display:none;
	}


