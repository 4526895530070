/* ======================================== */
/* .obj_infobulle
/* ======================================== */
.obj_infobulle{
	position:absolute;
	right:var(--aox-20px); top:var(--aox-20px);
	}
.obj_infobulle .info_picto{
	width:var(--aox-22px); height:var(--aox-22px);
	padding-top:1px;
	padding-right:1px;
	font-size:var(--aox-fs-16px); line-height:var(--aox-22px);
	font-weight:700;
	color:#fff;
	text-align:center;
	background:#000;
	border-radius:50%;
	cursor:pointer;
	transition:all .3s ease;
	}
.obj_infobulle .info_text{
	position:absolute;
	z-index:7777;
	right:var(--aox-27px); top:-5px;
	width:var(--aox-350px); max-height:0;
	font-size:var(--aox-fs-12px); line-height:1.5;
	color:#fff;
	background:#000;
	overflow:hidden;
	opacity:0;
	transition:all .3s ease;
	}
.obj_infobulle .info_text span{
	display:block;
	padding:var(--aox-15px);
	}
.obj_infobulle .info_picto:hover + .info_text{
	max-height:var(--aox-105px);
	opacity:1;
	}

@media screen and (min-width:1025px){
	.obj_infobulle .info_picto:hover{
		background:#777;
		}
}
@media screen and (max-width:767px){
	.obj_infobulle .info_text{
		width:var(--aox-265px);
		}
}
@media screen and (max-width:320px){
	.obj_infobulle .info_text{
		width:var(--aox-230px);
		}
}