/* ======================================== */
/* .obj_challenge-detail
/* ======================================== */
.obj_challenge-detail{
    position: relative;
    transition: all 1s ease;
    width: var(--aox-770px);
    }

/* .obj_challenge-detail.declarer > .ctn_row{ border: 2px solid #7FD5E0; }
.obj_challenge-detail.repartir > .ctn_row{ border: 2px solid #CA93DE; }
.obj_challenge-detail.en_attente > .ctn_row{ border: 2px solid #F6B15F; }
.obj_challenge-detail.remporte > .ctn_row{ border: 2px solid #ACDE93; }
.obj_challenge-detail.echoue > .ctn_row{ border: 2px solid #C4C4C4; }

.obj_challenge-detail.declarer > .ctn_header-title{ background-color: #7FD5E0; }
.obj_challenge-detail.repartir > .ctn_header-title{ background-color: #CA93DE; }
.obj_challenge-detail.en_attente > .ctn_header-title{ background-color: #F6B15F; }
.obj_challenge-detail.remporte > .ctn_header-title{ background-color: #ACDE93; }
.obj_challenge-detail.echoue > .ctn_header-title{ background-color: #C4C4C4; } */

.obj_challenge-detail > .ctn_row{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    border: unset;
    padding: unset;
    }
        
.obj_challenge-detail .ctn_image{
    width: var(--aox-col-3);
    }
.obj_challenge-detail .ctn_image .ctn_visuel{
    width: var(--aox-col-3);
    height: var(--aox-290px);
    overflow: hidden;
    }
.obj_challenge-detail .ctn_image .ctn_visuel img{
    width: 100%;
    }
.obj_challenge-detail .ctn_image .ctn_logo img{
    width:100%;
    }

.obj_challenge-detail .ctn_text{
    display: flex;
    width: var(--aox-440px);
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    }
.obj_challenge-detail .ctn_text .t_title{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    text-transform: uppercase;
    margin-bottom: var(--aox-20px);
    }
.obj_challenge-detail .ctn_text .t_date{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    }
.obj_challenge-detail .ctn_text .t_desc{
    font-weight: 400;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-150);
    margin-top: var(--aox-10px);
    width: var(--aox-330px);
    }


/***/

.obj_challenge-detail .obj_condition_challenge{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: var(--aox-25px) var(--aox-30px);
    background-color: #F2F2F2;
    margin-top: var(--aox-40px);
    width: 100%;
    transition: all .3s ease;
    }
.obj_challenge-detail .obj_condition_challenge > div{
    width: 100%;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-bottom: var(--aox-6px);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item + .obj_condition_challenge-item{
    padding-top: var(--aox-10px);
    border-top: 1px solid rgba(0,0,0,0.1);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item + .obj_condition_challenge-item.var_condition{
    background-color: #d9d9d9;
    padding: var(--aox-6px) var(--aox-10px);
    margin-bottom: 0;
    margin-top: calc(-1* var(--aox-5px));
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item:nth-child(1){
    margin-bottom: var(--aox-5px);
    padding-bottom: var(--aox-6px);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item:nth-child(2){
    border-top: none;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item{
    position: relative;
    width: 35%;
    text-align: center;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1){
    width: 40%;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-price{
    font-size: var(--aox-fs-22px);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item .obj_condition_challenge-item-label{
    margin-bottom: var(--aox-6px);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) .obj_condition_challenge-item-label,
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(1) .obj_condition_challenge-item-price{
    text-align: left;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(2) .obj_condition_challenge-item-label,
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(2) .obj_condition_challenge-item-price{
    text-align: left;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(2) .obj_condition_challenge-item-price em{
    position: relative;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-120);
    top: calc(-1 * var(--aox-3px));
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .col-item:nth-child(3){
    text-align: right;
    width: 25%;
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item > .obj_condition_challenge-item-label{
    width: var(--aox-280px);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .obj_condition_challenge-item-label > span{
    font-weight: 400;
    font-size: var(--aox-fs-13px);
    line-height: var(--aox-lh-140);
    }
.obj_challenge-detail .obj_condition_challenge .obj_condition_challenge-item .obj_condition_challenge-item-price{
    font-weight: 700;
    font-size: var(--aox-fs-16px);
    line-height: var(--aox-lh-120);
    }
    


/***/

@media screen and (max-width:767px){

    .obj_challenge-detail {
        width: 100%;
        }
    
    .obj_challenge-detail .ctn_challenge-detail {
        position: relative;
        width: 100vw;
        margin-left: calc(-1 * var(--aox-gutter));
        padding: var(--aox-15px) var(--aox-20px);
        background-color: #F2F2F2;
        transition: all .8s ease;
        }
    .obj_challenge-detail .ctn_challenge-detail .obj_btn_challenge-detail{
        position: relative;
        font-weight: 700;
        font-size: var(--aox-fs-11px);
        line-height: var(--aox-lh-100);
        width: auto;
        height: var(--aox-24px);
        text-transform: uppercase;
        padding-right: var(--aox-30px);
        text-align: left;
        }
    .obj_challenge-detail .ctn_challenge-detail .obj_btn_challenge-detail:before{
        content: '';
        position: absolute;
        right: var(--aox-6px);
        top: calc(50% - var(--aox-2px));
        width: 6px;
        height: 6px;
        border-bottom: 2px solid #000;
        border-right: 2px solid #000;
        transform: translateY(-50%) rotate(45deg) scale(1);
        transition: all .3s ease;
        }
    .obj_challenge-detail .ctn_challenge-detail.is-expanded .obj_btn_challenge-detail:before{
        transform: translateY(-10%) rotate(45deg) scale(-1);
        }

    .obj_challenge-detail .ctn_challenge-detail .expend_challenge-detail{
        position: relative;
        opacity: 0;
        height: 0;
        transition: all .3s ease;
        }
    .obj_challenge-detail .ctn_challenge-detail.is-expanded .expend_challenge-detail{
        opacity: 1;
        height: auto;
        }

    .obj_challenge-detail .ctn_challenge-detail .expend_challenge-detail .ctn_row{
        flex-direction: row-reverse;
        padding-top: var(--aox-20px);
        }
    .obj_challenge-detail .expend_challenge-detail .ctn_image {
        width: var(--aox-120px);
        }
    .obj_challenge-detail .expend_challenge-detail .ctn_text {
        width: var(--aox-230px);
        }
    .obj_challenge-detail .expend_challenge-detail .ctn_text > .t_title{
        display: none;
        }
    .obj_challenge-detail .ctn_text .t_date {
        font-size: var(--aox-fs-14px);
        }
    .obj_challenge-detail .expend_challenge-detail .ctn_text .t_desc {
        width: 100%;
        }

    .obj_challenge-detail .obj_condition_challenge{
        padding: 0;
        margin-top: var(--aox-10px);
        }

    .obj_challenge-detail .ctn_image .ctn_visuel {
        width: var(--aox-col-2);
        height: var(--aox-col-2);
        }

}