/* ======================================== */
/* .obj_cta
/* ======================================== */
.obj_cta{
	position:relative;
	display:inline-block;
	padding:var(--aox-25px) var(--aox-50px);
	font-size:var(--aox-fs-14px); line-height:var(--aox-lh-120);
	font-weight:700;
	letter-spacing: 0;
	color:#fff;
	text-transform:uppercase;
	background:rgba(0,0,0,1);
	transition:all .3s ease;
	}
.obj_cta.reverse{
	color:#000;
	background:#fff;
	}
.obj_cta.grey{
	color:#777 !important;
	background:none !important;
	}
.obj_cta.light-grey{
	color:#000 !important;
	background:#f2f2f2 !important;
	}
.obj_cta-txt{
	display:inline-block;
	padding:var(--aox-10px) var(--aox-10px) var(--aox-10px) 0;
	font-size:var(--aox-fs-14px); line-height:var(--aox-lh-100);
	font-weight:500;
	text-decoration:underline;
	transition:all .3s ease;
	}
.obj_cta.var_picto svg{
	position:absolute;
	top:50%;
	fill:#fff;
	transform:translateY(-50%);
	transition:all .3s ease;
	}
.obj_cta.var_email{
	padding-left:var(--aox-60px);
	}
.obj_cta.var_email svg{
	left:var(--aox-20px);
	}
.obj_cta-download{
	position:relative;
	display:inline-block;
	padding:var(--aox-10px) var(--aox-10px) var(--aox-10px) var(--aox-30px);
	font-size:var(--aox-fs-13px); line-height:var(--aox-lh-100);
	font-weight:500;
	transition:all .3s ease;
	}
.obj_cta-download svg{
	position:absolute;
	left:0; top:50%;
	transform:translateY(-50%);
	transition:all .3s ease;
	}


/***/

.obj_btn-popin-detail{
    position: relative;
    display: flex;
    font-weight: 700;
    font-size: var(--aox-fs-12px);
    line-height: var(--aox-lh-100);
    height: var(--aox-24px);
    text-transform: uppercase;
    text-align: left;
    margin-top: var(--aox-5px);
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    }
.obj_btn-popin-detail:hover{
    text-decoration: underline;
    }



@media screen and (min-width:1025px){
	.obj_cta:hover{
		background-color: #777;
		}
	.obj_cta-txt:hover{
		color:#777;
		}
	.obj_cta.reverse:hover{
		color:#fff;
		background:#777;
		}
	.obj_cta.grey:hover{
		color:#000 !important;
		background:#fff !important;
		}
	.obj_cta.light-grey:hover{
		color:#f2f2f2 !important;
		background:#000 !important;
		}
	.obj_cta-download:hover{
		color:#777 !important;
		}
	.obj_cta-download:hover svg{
		fill:#777 !important;
		}
}
@media screen and (max-width:767px){
	.obj_cta{
		font-size:var(--aox-fs-12px);
		padding:var(--aox-20px) var(--aox-30px);
		}
}



/***/

.obj_btn{
	position:relative;
    display: flex;
    flex-direction: row;
    align-items: center;
	padding: var(--aox-20px) var(--aox-30px);
    height: var(--aox-55px);
	font-size:var(--aox-fs-12px); line-height:var(--aox-lh-100);
	font-weight:700;
	letter-spacing: 0;
	color:#fff;
	text-transform:uppercase;
	background: #000;
	border: 1px solid #000;
	}
.obj_btn:hover{
	color:#000;
	background:#fff;
	border: 1px solid #000;
	}
.obj_btn.light{
	color:#000;
	background:#fff;
	border: 1px solid #000;
	}
.obj_btn.light:hover{
	border: 1px solid #000;
	background: #000;
	color: #fff;
	}

.obj_btn.picto > svg{
	margin-right: var(--aox-10px);
	fill: white;
	}
.obj_btn.picto > span + svg{
	margin-left: var(--aox-10px);
	fill: white;
	}
.obj_btn.picto.light > svg{
	fill: black;
	}
.obj_btn.picto:hover > svg{
	fill: black;
	}
.obj_btn.picto.light:hover > svg{
	fill: white;
	}

/***/
.obj_btn.obj_btn-title_absolute{
	position: absolute;
    top: var(--aox-70px);
    right: 0;
    z-index: 1;
	}

@media screen and (max-width:767px){
	.obj_btn.obj_btn-title_absolute{
		padding:var(--aox-10px);
		height: auto;
		border: none;
		}
	.obj_btn.obj_btn-title_absolute span{
		display: none;
		}
	.obj_btn.obj_btn-title_absolute > svg {
		margin: 0 !important;
		}
}
	