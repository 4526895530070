/* ======================================== */
/* .obj_breadcrumb
/* ======================================== */
.obj_breadcrumb{
	position:relative;
	z-index:2;
	display:flex;
	flex-flow:row wrap;
	padding:var(--aox-20px) 0 0 0;
	}
.obj_breadcrumb li{
	font-size:var(--aox-fs-12px); line-height:1;
	}
.obj_breadcrumb li a,
.obj_breadcrumb li span{
	display:block;
	position:relative;
	padding:var(--aox-5px) var(--aox-25px) var(--aox-5px) 0;
	}
.obj_breadcrumb li a::before{
	content:'';
	position:absolute;
	right:var(--aox-10px); top:50%;
	width:6px; height:6px;
	border-top:solid 2px #c3c3c3;
	border-right:solid 2px #c3c3c3;
	transform:translateY(-50%) rotate(45deg);
  	transition:all .3s ease;
	}
.obj_breadcrumb li span{
	font-weight:700;
	}

@media screen and (max-width:1280px){
	/* .obj_breadcrumb{
		padding-left:var(--aox-20px);
		} */
}