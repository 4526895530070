/* ======================================== */
/* .is-loading
/* ======================================== */
body.is-loading::before{
	position:fixed;
	z-index:100000;
	left:0; top:0;
	width:100vw; height:100vh;
	background:rgba(255,255,255,0.8);
	animation:fadeIn .3s ease;
	content:'';
	}
body.is-loading::after{
	position:fixed;
	z-index:100001;
	left:50%; top:50%;
	width:var(--aox-50px); height:var(--aox-50px);
	margin:calc(var(--aox-25px) * -1) 0 0 calc(var(--aox-25px) * -1);
	border:5px solid #b3b3b3;
	border-top-color:#666;
	border-bottom-color:#666;
	border-radius:var(--aox-50px);
	animation:rotationLoop 1s ease infinite;
	content:'';
	}

@media screen and (max-width:767px){
	body.is-loading::after{
		width:var(--aox-40px); height:var(--aox-40px);
		margin: calc(var(--aox-20px) * -1) 0 0 calc(var(--aox-20px) * -1);
		border-width:4px;
		border-radius:var(--aox-40px);
		}
}